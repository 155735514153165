import Tooltip from '../../../ui/Tooltip'
import { freeModeDays } from '../../../../config/config'
import styles from '../../../../css/modehotes/freemode/freemode.module.css'
import { useCalcDiffTwoDate } from '../../../../hooks/useCalcDiffTwoDate'
import moment from 'moment'

const FreeMode = ({ annonce }) => {
  const { total_days } = useCalcDiffTwoDate(annonce?.gowifmi_publish_date, new Date())
  const restDays = freeModeDays - total_days

  const indicatorByDays = {
    width: `${total_days * 1.12}%`,
  }
  const aviableText = `Il vous reste ${restDays} jours de FreeMode pour cette annonce qui ${
    total_days >= 1 ? 'a été' : 'est'
  } mise en ligne le ${moment(annonce?.gowifmi_publish_date).format('ll')}`

  if (total_days >= freeModeDays) return null

  if (annonce?.gowifmi_publish_date) {
    return (
      <Tooltip title={total_days >= freeModeDays ? 'FreeMode expriré' : aviableText}>
        <div className={styles.indicat_block}>
          <div className={styles.indicator} style={indicatorByDays}></div>
        </div>
      </Tooltip>
    )
  } else {
    return (
      <Tooltip title="Cette annonce n'est pas encore éligible au FreeMode">
        <div className={styles.not_eligible}></div>
      </Tooltip>
    )
  }
}

export default FreeMode
