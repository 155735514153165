import { applyMiddleware, createStore, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
  userReducer,
  userExisteReducer,
  getFavorisReducer,
  search_by_map,
  totalHotelreducer,
  updateAnnonce,
} from './reducers'

export const store = createStore(
  combineReducers({
    userReducer,
    userExisteReducer,
    getFavorisReducer,
    search_by_map,
    totalHotelreducer,
    updateAnnonce,
  }),
  composeWithDevTools(applyMiddleware(thunk))
)
