import { useState, useContext } from 'react'
import styles from '../../../../css/traveler/inbox/detailMessage.module.css'
import axios from 'axios'
import { SocketContext } from '../../../../context/SocketContext'

const WriteMessageInput = ({ refetch, conversationId }) => {
  const [text, setText] = useState('')
  const [isLoadingSend, setIsLoadingSend] = useState(false)
  const { socket } = useContext(SocketContext)

  const handlemessage = () => {
    setIsLoadingSend(true)

    const data = {
      text: text,
    }
    axios
      .patch(`/message/addMessage/${conversationId}`, data)
      .then(res => {
        setText('')
        refetch()
        socket.emit('sendMessage', {
          receiverId: res.data.host,
          data: res.data,
        })
      })
      .catch(() => {})
      .finally(() => setIsLoadingSend(false))
  }

  const handleKeyDown = e => {
    e.target.style.height = 'inherit'
    e.target.style.height = `${e.target.scrollHeight}px`
  }

  return (
    <div className={styles.send}>
      <div className={styles.input_block}>
        <textarea
          type='text'
          value={text}
          onChange={e => setText(e.target.value)}
          onKeyUp={handleKeyDown}
          placeholder='Saisissez un message'
        ></textarea>
        {text.length > 0 && (
          <button onClick={handlemessage} disabled={isLoadingSend}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              style={{
                display: 'block',
                width: '32',
                height: '32',
                fill: 'none',
              }}
              viewBox='0 0 32 32'
            >
              <path
                fill='currentColor'
                fill-rule='evenodd'
                d='M15.192 8.906a1.143 1.143 0 0 1 1.616 0l5.143 5.143a1.143 1.143 0 0 1-1.616 1.616l-3.192-3.192v9.813a1.143 1.143 0 0 1-2.286 0v-9.813l-3.192 3.192a1.143 1.143 0 1 1-1.616-1.616z'
                clip-rule='evenodd'
              ></path>
            </svg>
          </button>
        )}
      </div>
    </div>
  )
}

export default WriteMessageInput
