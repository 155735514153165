import styles from '../../../../../../css/modehotes/annonce/filtres/filtre.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'

function Sall_bain() {
  const NumberBain = ['1', '2', '3', '4', '5', '6', '7', '8']
  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  const history = useHistory()
  const location = useLocation()
  const path = location.pathname
  const handleBain = e => {
    Params.set('number_of_bathrooms', e.target.value)
    history.push(`${path}?${Params}`)
  }
  const AllBain = () => {
    Params.delete('number_of_bathrooms')
    history.push(`${path}?${Params}`)
  }
  return (
    <div>
      <p>Salles de bain</p>
      <div className={styles.radio_container}>
        <label>
          <input checked={!Params.get('number_of_bathrooms')} type='radio' onChange={AllBain} />
          <span>Tout</span>
        </label>
        {NumberBain.map(value => (
          <label key={value}>
            <input
              type='radio'
              value={value}
              checked={Params.get('number_of_bathrooms') === value}
              onChange={handleBain}
            />
            <span>
              {value}
              {value >= 8 && '+'}
            </span>
          </label>
        ))}
      </div>
    </div>
  )
}

export default Sall_bain
