import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

function TooltipComponent({ title, children }) {
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: '#000',
      fontFamily: 'Inter',
      fontSize: 13,
      fontWeight: 500,
      padding: '15px',
      boxShadow: 'rgba(0, 0, 0, 0.28) 0px 8px 28px',
      borderRaduis: '15px',
    },
  }))

  // const BlackTooltip = styled(({ className, ...props }) => (
  //   <Tooltip {...props} arrow classes={{ popper: className }} />
  // ))(({ theme }) => ({
  //   [`& .${tooltipClasses.arrow}`]: {
  //     color: theme.palette.common.black,
  //   },
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     backgroundColor: theme.palette.common.black,
  //     color: '#fff',
  //     fontFamily: 'Inter',
  //     fontSize: 13,
  //     fontWeight: 600,
  //     padding: '15px',
  //     boxShadow: 'rgba(0, 0, 0, 0.28) 0px 8px 28px',
  //     borderRaduis: '15px',
  //   },
  // }))

  // const HtmlTooltip = styled(({ className, ...props }) => (
  //   <Tooltip {...props} classes={{ popper: className }} />
  // ))(({ theme }) => ({
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     backgroundColor: '#f5f5f9',
  //     color: 'rgba(0, 0, 0, 0.87)',
  //     maxWidth: 220,
  //     fontSize: theme.typography.pxToRem(12),
  //     border: '1px solid #dadde9',
  //   },
  // }))

  return <LightTooltip title={title}>{children}</LightTooltip>
}
export default TooltipComponent
