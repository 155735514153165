import { useContext } from 'react'
import styles from '../../../../../../css/booking/formulaire.module.css'
import moment from 'moment'
import AddPhoneNumber from './AddPhoneNumber'
import ErrorSvg from '../../../../../svgs/ErrorSvg'
import { User } from '../../../../../../context/UserContext'
import { isValidPhoneNumber } from 'react-phone-number-input'

const InputPhoneAndMessage = ({
  message,
  setMessage,
  telephone,
  setTelephone,
  messageError,
  hotel,
  phoneError,
}) => {
  const user = useContext(User)

  return (
    <div className={styles.container_modewrite}>
      {!user?.phone_number || !isValidPhoneNumber(user?.phone_number) ? (
        <div className={styles.addphone_block}>
          <div>
            <h6>Numéro de téléphone</h6>
            <p>
              De cette manière, vous ne passerez à côté d'aucune information importante sur votre
              voyage.
            </p>
          </div>
          <div>
            {/*------AddPhoneNumber------*/}
            <AddPhoneNumber
              valuePhone={telephone}
              setTelephone={setTelephone}
              phoneError={phoneError}
            />
          </div>
        </div>
      ) : null}
      <div
        className={
          messageError && message.length < 1
            ? `${styles.demande_block} ${styles.erreur_input}`
            : styles.demande_block
        }
      >
        <label htmlFor='message'>Envoyez un message à l'hôte</label>
        <p className={styles.demande_special}>
          Communiquez votre heure d'arrivée et le motif de votre voyage à votre hôte.
        </p>
        <textarea
          id='message'
          value={message}
          onChange={e => setMessage(e.target.value)}
        ></textarea>
        <div className={styles.error_message}>
          <div>
            <ErrorSvg />
          </div>
          <span>Communiquez votre heure d'arrivée et le motif de votre voyage à votre hôte.</span>
        </div>
      </div>
      <div className={styles.profil_block}>
        <div className={styles.img_block}>
          <div className={styles.profil_img}>
            {hotel?.user_id?.picture ? (
              <img src={hotel?.user_id?.picture} alt='' />
            ) : (
              <h2>{hotel?.user_id?.first_name?.slice(0, 1)}</h2>
            )}
          </div>
        </div>
        <div>
          <div className={styles.about_name}>
            <h6>{hotel.user_id?.first_name}</h6>
            {hotel.user_id?.isIdentityValid && (
              <span>
                <svg
                  viewBox='0 0 16 16'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'
                  role='presentation'
                  focusable='false'
                  style={{
                    height: '12px',
                    width: '12px',
                    fill: 'currentColor',
                  }}
                >
                  <path d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.159 4.869L6.67 9.355 4.42 7.105 3.289 8.236 6.67 11.62 12.291 6z'></path>
                </svg>
              </span>
            )}
          </div>
          <p>Membre depuis {moment(hotel?.user_id?.createdAt).format('LL').slice(2)}</p>
        </div>
      </div>
    </div>
  )
}

export default InputPhoneAndMessage
