import { useState, useEffect, useRef } from 'react'
import styles from '../../../../css/search_room_result/maps.module.css'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Navigation, Pagination } from 'swiper/modules'
import usePrice from '../../../../hooks/usePrice'
import useHotelRating from '../../../../hooks/useHotelRating'
import { TYPE_LOGEMENT } from '../../../../enums/logement/type_logement.enums'
import StarSvg from '../../../svgs/StarSvg'
import FavoriteCard from '../favorites/FavoriteCard'

const CardMaps = ({ childClicked, setChildClicked }) => {
  const [swiperKey, setSwiperKey] = useState(0)
  const [prev, setPrev] = useState(false)
  const [nex, setNex] = useState(true)

  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  const enfant = Params.get('children')
  const adulte = Params.get('adults')
  const bebe = Params.get('infants')
  const animal = Params.get('pets')
  const checkin = Params.get('checkin')
  const checkout = Params.get('checkout')

  const { reduction, pricePerNightWithoutPromo, pricePerNightWithPromo } = usePrice(
    childClicked ? childClicked : {}
  )
  const { commentLength, totalComment } = useHotelRating(childClicked)

  useEffect(() => {
    if (childClicked) {
      setSwiperKey(prevKey => prevKey + 1)
      setNex(true)
      setPrev(false)
    }
  }, [childClicked])

  const tailWindow = window.matchMedia('(min-width:743px)')

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  return (
    <>
      {childClicked && (
        <div className={styles.hotel_image}>
          <div className={styles.block_img}>
            <Swiper
              key={swiperKey}
              modules={[Navigation, Pagination]}
              pagination={{
                dynamicBullets: true,
              }}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onBeforeInit={swiper => {
                swiper.params.navigation.prevEl = navigationPrevRef.current
                swiper.params.navigation.nextEl = navigationNextRef.current
              }}
              onSlideNextTransitionStart={() => setPrev(true)}
              onReachBeginning={() => setPrev(false)}
              onSlidePrevTransitionStart={() => setNex(true)}
              onReachEnd={() => setNex(false)}
              className={styles.block_photo_result}
            >
              {childClicked.images.map((photo, i) => (
                <SwiperSlide key={i}>
                  <Link
                    target={tailWindow.matches ? '_blank' : null}
                    rel='noopener noreferrer'
                    to={`/rooms/${childClicked?._id}?adults=${adulte}&children=${enfant}&infants=${bebe}&pets=${animal}&checkin=${checkin}&checkout=${checkout}`}
                  >
                    <div
                      className={styles.imgslider}
                      style={{
                        backgroundImage: `linear-gradient(
                                                to bottom,
                                                transparent 80%,
                                                rgba(0, 0, 0, 0.200)
                                              ),url(${photo.url})`,
                      }}
                    ></div>
                  </Link>
                </SwiperSlide>
              ))}
              <div
                ref={navigationPrevRef}
                className={prev ? styles.prev : `${styles.prev} ${styles.disable}`}
              >
                <svg
                  viewBox='0 0 32 32'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'
                  role='presentation'
                  focusable='false'
                >
                  <g>
                    <path d='m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932'></path>
                  </g>
                </svg>
              </div>
              <div
                ref={navigationNextRef}
                className={nex ? styles.next : `${styles.next} ${styles.disable}`}
              >
                <svg
                  viewBox='0 0 32 32'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'
                  role='presentation'
                  focusable='false'
                >
                  <g>
                    <path d='m12 4 11.2928932 11.2928932c.3905243.3905243.3905243 1.0236893 0 1.4142136l-11.2928932 11.2928932'></path>
                  </g>
                </svg>
              </div>
            </Swiper>
            <button
              type='button'
              className={styles.close_card}
              onClick={() => setChildClicked(null)}
            >
              <svg
                className={styles.close_svg}
                viewBox='0 0 32 32'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
              >
                <path d='m6 6 20 20'></path>
                <path d='m26 6-20 20'></path>
              </svg>
            </button>
            <FavoriteCard item={childClicked} />
          </div>
          <Link
            target={tailWindow.matches ? '_blank' : null}
            rel='noopener noreferrer'
            to={`/rooms/${childClicked?._id}?adults=${adulte}&children=${enfant}&infants=${bebe}&pets=${animal}&checkin=${checkin}&checkout=${checkout}`}
          >
            <div className={styles.descript}>
              {childClicked.accommodation_type === TYPE_LOGEMENT.ENTIRE_HOME && (
                <h2>Logement entier - {childClicked.address?.city}</h2>
              )}
              {childClicked.accommodation_type === TYPE_LOGEMENT.PRIVATE_ROOM && (
                <h2>Chambre privée - {childClicked.address?.city}</h2>
              )}
              {childClicked.accommodation_type === TYPE_LOGEMENT.SHARED_ROOM && (
                <h2>Chambre partagée - {childClicked.address?.city}</h2>
              )}
              <div className={styles.hotel_evaluation}>
                {commentLength > 0 ? (
                  <div className={styles.block_eval}>
                    <StarSvg width={12} height={12} />
                    <p>
                      {totalComment} ({commentLength} Commentaire
                      {commentLength > 1 ? 's' : ''})
                    </p>
                  </div>
                ) : (
                  <div className={styles.block_eval}>
                    <StarSvg width={12} height={12} />
                    <p>Nouveau</p>
                  </div>
                )}
              </div>
              <div className={styles.price}>
                {reduction > 0 && <h3 className={styles.promo}>{pricePerNightWithoutPromo}</h3>}
                <h3>{pricePerNightWithPromo}</h3>
                <span>/nuit</span>
              </div>
            </div>
          </Link>
        </div>
      )}
    </>
  )
}

export default CardMaps
