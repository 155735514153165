import {
  GET_USER,
  USER_EXISTE,
  GET_FAVORIS,
  // UPDATE_USER,
  UPLOAD_PROFIL,
  UPLOAD_COVER_IMAGE,
  SEARCH_MAPS,
  TOTAL_HOTEL,
  GET_ANNONCE,
  UPDATE_ANNONCE,
} from './actions'
import { etatinitial } from './states'

export function userReducer(state = {}, action) {
  switch (action.type) {
    case GET_USER:
      return {
        picture: action.payload.picture,
        cover_picture: action.payload.cover_picture,
        id: action.payload.id,
      }
    // case UPDATE_USER:
    //   return action.payload
    case UPLOAD_PROFIL:
      return {
        ...state,
        picture: action.payload,
      }
    case UPLOAD_COVER_IMAGE:
      return {
        ...state,
        cover_picture: action.payload,
      }
    default:
      return state
  }
}
export function userExisteReducer(state = {}, action) {
  switch (action.type) {
    case USER_EXISTE:
      return {
        first_name: action.payload.first_name,
        email: action.payload.email,
        picture: action.payload.picture,
      }
    default:
      return state
  }
}
export function search_by_map(state = etatinitial, action) {
  switch (action.type) {
    case SEARCH_MAPS:
      return action.payload
    default:
      return state
  }
}
export function totalHotelreducer(state = etatinitial, action) {
  switch (action.type) {
    case TOTAL_HOTEL:
      return action.payload
    default:
      return state
  }
}
export function updateAnnonce(state = {}, action) {
  switch (action.type) {
    case GET_ANNONCE:
      return action.payload
    case UPDATE_ANNONCE:
      return action.payload
    default:
      return state
  }
}
export function getFavorisReducer(state = etatinitial, action) {
  switch (action.type) {
    case GET_FAVORIS:
      return action.payload
    default:
      return state
  }
}
