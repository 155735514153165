import styles from '../../../../css/search_room_result/card.module.css'
import { HOST_STATUS_ACTIVITY } from '../../../../enums/logement/host_status_activity.enums'
import { TYPE_LOGEMENT } from '../../../../enums/logement/type_logement.enums'
import useHotelRating from '../../../../hooks/useHotelRating'
import StarSvg from '../../../svgs/StarSvg'

const Header = ({ item }) => {
  const { commentLength, totalComment } = useHotelRating(item)

  return (
    <div className={styles.hotel_header}>
      <div className={styles.type}>
        {item.accommodation_type === TYPE_LOGEMENT.ENTIRE_HOME && (
          <h3>
            Logement entier ·{' '}
            {item.about?.host_activity_status === HOST_STATUS_ACTIVITY.PARTICULAR
              ? 'Particulier'
              : 'Professionnel'}{' '}
            · {item.address?.state || item.address?.city || item.address?.country}
          </h3>
        )}
        {item.accommodation_type === TYPE_LOGEMENT.PRIVATE_ROOM && (
          <h3>
            Chambre privée ·{' '}
            {item.about?.host_activity_status === HOST_STATUS_ACTIVITY.PARTICULAR
              ? 'Particulier'
              : 'Professionnel'}{' '}
            · {item.address?.state || item.address?.city || item.address?.country}
          </h3>
        )}
        {item.accommodation_type === TYPE_LOGEMENT.SHARED_ROOM && (
          <h3>
            Chambre partagée ·{' '}
            {item.about?.host_activity_status === HOST_STATUS_ACTIVITY.PARTICULAR
              ? 'Particulier'
              : 'Professionnel'}{' '}
            · {item.address?.state || item.address?.city || item.address?.country}
          </h3>
        )}
      </div>
      <div className={styles.hotel_evaluation}>
        {commentLength > 0 ? (
          <div className={styles.block_eval}>
            <StarSvg width={12} height={12} />
            <p>
              {totalComment} ({commentLength} Commentaire
              {commentLength > 1 ? 's' : ''})
            </p>
          </div>
        ) : (
          <div className={styles.block_eval}>
            <StarSvg width={12} height={12} />
            <p>Nouveau</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default Header
