const Options = () => {
  const Array = [
    { name: "Le jour même, jusqu'à 00:00", value: '0' },
    { name: "Au moins 1 jour à l'avance", value: '1' },
    { name: "Au moins 2 jours à l'avance", value: '2' },
    { name: "Au moins 3 jours à l'avance", value: '3' },
    { name: "Au moins 4 jours à l'avance", value: '4' },
    { name: "Au moins 5 jours à l'avance", value: '5' },
    { name: "Au moins 6 jours à l'avance", value: '6' },
    { name: "Au moins 7 jours à l'avance", value: '7' },
  ]
  return (
    <>
      {Array.map(item => (
        <option value={item.value} key={item.value}>
          {item.name}
        </option>
      ))}
    </>
  )
}

export default Options
