import { useEffect, useContext, useState } from 'react'
import styles from '../../../css/modehotes/facturation/facture.module.css'
import { useHistory, Link } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { User } from '../../../context/UserContext'
import LoadingPoint from '../../../components/LoadingPoint'
import BillStatus from '../../../components/hote_mode/default_components/BillStatus'
import Header from '../../../components/hote_mode/bills/Header'
import { addCurrencyComplet } from '../../../utils/addCurrency'
import HostModeLayout from '../../../layouts/HostModeLayout'
import ErrorServer from '../../errors/ErrorServer'
import { useQuery } from 'react-query'
import { Helmet } from 'react-helmet-async'
import Pagination from '../../../components/ui/Pagination'

const UnpayBill = () => {
  const [searchParams, setSearchParams] = useState(window.location.search)
  const params = new URLSearchParams(searchParams)

  const user = useContext(User)
  const history = useHistory()

  useEffect(() => {
    if (user === null) {
      history.push('/')
    }
    const unlisten = history.listen(location => {
      setSearchParams(location.search)
    })

    return () => {
      unlisten()
    }
  }, [history, user])

  const fetchBills = async () => {
    const { data } = await axios.get(`/bill/all_bill_unpaid?host=${user?.id}&&${params}`)
    return data
  }

  const { data = {}, isLoading, error } = useQuery([`all_bill_unpaid`, searchParams], fetchBills)

  if (error) return <ErrorServer />

  return (
    <HostModeLayout>
      <Helmet>
        <title>Facturations - Gowifmi</title>
      </Helmet>
      <div className={styles.voyage_container}>
        <Header data={data} />
        {isLoading ? (
          <div className={styles.container_load_liste}>
            <LoadingPoint />
          </div>
        ) : (
          <>
            {data?.totalRecords < 1 ? (
              <div className={styles.no_result}>
                <h2>Aucun résultat trouvé</h2>
                <p>Vous n'avez aucune facture à payer pour le moment.</p>
              </div>
            ) : (
              <>
                <div className={styles.container_arry}>
                  <table className={styles.customers}>
                    <thead>
                      <tr>
                        <th className={styles.image}>Annonce</th>
                        <th>Date d'arrivée</th>
                        <th>Date de départ</th>
                        <th>Montant</th>
                        <th>Statut</th>
                        <th>Référence</th>
                        <th>Date réservation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.reservationsWithFees.map(item => (
                        <tr key={item._id}>
                          <td className={styles.image}>
                            <Link to={`/hosting/reservations/details/${item._id}`}>
                              <div>
                                <div className={styles.card}>
                                  <img src={item.hotel?.images[0].url} alt='couverture' />
                                </div>
                              </div>
                              <p>{item.hotel?.title}</p>
                            </Link>
                          </td>
                          <td className={styles.rows}>
                            {moment(item.reservation_infos?.checkin).format('ll')}
                          </td>
                          <td className={styles.rows}>
                            {moment(item.reservation_infos?.checkout).format('ll')}
                          </td>
                          <td className={styles.rows}>
                            <strong>
                              {addCurrencyComplet(item.gowifmiServiceFee, item?.prices?.currency)}
                            </strong>
                          </td>
                          <td className={styles.process}>
                            <BillStatus reservation={item} />
                          </td>
                          <td className={styles.rows}>{item.reservation_infos?.reference}</td>
                          <td className={styles.rows}>{moment(item.createdAt).format('ll')}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination data={data} />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </HostModeLayout>
  )
}

export default UnpayBill
