import styles from '../../../css/affiliations/revenues/header.module.css'
import SelectDate from '../../ui/SelectDate'

const Header = ({ selectedDate, setSelectedDate }) => {
  return (
    <>
      <div className={styles.container}>
        <SelectDate selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
      </div>
    </>
  )
}

export default Header
