const Options = () => {
  const Array = [
    { name: '00:00', value: '00:00' },
    { name: '01:00', value: '01:00' },
    { name: '02:00', value: '02:00' },
    { name: '03:00', value: '03:00' },
    { name: '04:00', value: '04:00' },
    { name: '05:00', value: '05:00' },
    { name: '06:00', value: '06:00' },
    { name: '07:00', value: '07:00' },
    { name: '08:00', value: '08:00' },
    { name: '09:00', value: '09:00' },
    { name: '10:00', value: '10:00' },
    { name: '11:00', value: '11:00' },
    { name: '12:00', value: '12:00' },
    { name: '13:00', value: '13:00' },
    { name: '14:00', value: '14:00' },
    { name: '15:00', value: '15:00' },
    { name: '16:00', value: '16:00' },
    { name: '17:00', value: '17:00' },
    { name: '18:00', value: '18:00' },
    { name: '19:00', value: '19:00' },
    { name: '20:00', value: '20:00' },
    { name: '21:00', value: '21:00' },
    { name: '22:00', value: '22:00' },
    { name: '23:00', value: '23:00' },
  ]
  return (
    <>
      {Array.map(item => (
        <option value={item.value} key={item.value}>
          {item.name}
        </option>
      ))}
    </>
  )
}

export default Options
