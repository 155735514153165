import { useEffect, useContext } from 'react'
import { User } from '../../context/UserContext'
import NavbarSearchResulte from '../../components/navigations/NavbarSearchResulte'
import ListHotel from '../../components/search_room_result/ListHotel'
import DefaultLayout from '../../layouts/DefaultLayout'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorBoundaryFallback from '../../components/errors/ErrorBoundaryFallback'
import { useDispatch } from 'react-redux'
import { getFavoris } from '../../redux/actions'
import { ParamProvider } from '../../context/SearchParamContext'
import { Helmet } from 'react-helmet-async'

function Search() {
  const dispatch = useDispatch()
  const user = useContext(User)

  useEffect(() => {
    window.scrollTo(0, 0)
    if (user) {
      dispatch(getFavoris())
    }
  }, [])

  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  const region = Params.get('place')

  return (
    <ParamProvider>
      <DefaultLayout footer={true} mobileBottomNavigate={true}>
        <Helmet>
          <title>
            Gowifmi | {region?.split(',')[0] || 'Ajoutez une destination'} · Locations de vacances
            et logements · Garantie meilleur prix !
          </title>
        </Helmet>
        <NavbarSearchResulte />
        {/*--------les hotels--------*/}
        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
          <ListHotel />
        </ErrorBoundary>
      </DefaultLayout>
    </ParamProvider>
  )
}
export default Search
