import { Helmet } from 'react-helmet-async'
import styles from '../../../css/about_us/about.module.css'
import DefaultLayout from '../../../layouts/DefaultLayout'

function GetPayementHost() {
  return (
    <DefaultLayout footer={true}>
      <Helmet>
        <title>Recevoir votre versement suite à l'accueil de voyageurs - Gowifmi</title>
      </Helmet>
      <div className={styles.main}>
        <div className={styles.pourquoi_text}>
          <h1>Recevoir votre versement suite à l'accueil de voyageurs </h1>

          <h2>Apprenez les bases en matière de paiements : quand et comment vous les recevrez.</h2>
          <div>
            <a href='/account-settings/versement' className={styles.btn_action}>
              Ajouter un mode de versement
            </a>
          </div>

          <h3>Comment recevrai-je mes paiements ?</h3>
          <div>
            <p>
              C'est vous qui choisissez comment vous souhaitez recevoir les revenus de votre
              activité d'hôte. Les modes de versement comprennent le paiement rapide, les virements
              bancaires, PayPal, les cartes de débit Payoneer et Western Union, entre autres. Les
              options varient en fonction de votre emplacement.
            </p>
            <p>
              Pour recevoir vos paiements,{' '}
              <a href='/account-settings/versement'>sélectionnez un mode de versement</a> dans la
              section Paiements et versements de votre compte. Le mode de versement que vous
              sélectionnez s'appliquera à tous les futurs versements jusqu'à ce que vous le
              modifiez.
            </p>
            <p>
              Votre mode de versement sera vérifié pour que vos fonds soient bien envoyés au bon
              endroit. Le processus de vérification peut prendre entre 2 et 10 jours, sauf si vous
              avez choisi le paiement rapide, auquel cas la vérification est instantanée.
            </p>
          </div>
          <h3>Montant des versements</h3>
          <div>
            <p>
              Pour calculer votre versement, multipliez le prix par nuit par le nombre de nuits
              compris dans la réservation, puis ajoutez tous les frais supplémentaires facultatifs
              (comme les frais de ménage). Le montant obtenu correspond au sous-total de la
              réservation.
            </p>
            <p>
              Du sous-total de la réservation, soustrayez les taxes et frais applicables. Les taxes
              varient selon l'endroit où vous vivez, mais la plupart des hôtes paient des frais de
              service de 3 %.
            </p>
            <p>
              Si votre versement est retardé ou semble inférieur à vos attentes, il est possible que
              des réductions aient été appliquées ou qu'une réservation ait été annulée ou modifiée
              (entraînant un remboursement intégral ou partiel des voyageurs). De plus, des frais de
              transaction peuvent s'appliquer à certains modes de versement. De nombreux modes de
              versement ne s'accompagnent toutefois d'aucuns frais supplémentaires.
            </p>
          </div>
          <h3>Quand recevrez-vous vos versements ?</h3>
          <div>
            <p>
              Votre versement est envoyé environ 24 heures après l'heure d'arrivée prévue des
              voyageurs. Le moment exact où les fonds seront crédités sur votre compte dépend du
              mode de versement que vous avez choisi.
            </p>
            <p>
              Les modes de versement (lorsqu'ils sont disponibles) et leurs délais d'acheminement
              habituels sont les suivants :
            </p>
            <ul>
              <li>Virement bancaire : 3 à 5 jours ouvrés</li>
              <li>Virement international : 3 à 7 jours ouvrés</li>
              <li>Paiement rapide : 30 minutes ou moins</li>
              <li>Payoneer : 24 heures ou moins</li>
              <li>PayPal : 1 jour ouvré</li>
              <li>Western Union : 1 jour ouvré (variable selon le pays ou la zone géographique)</li>
            </ul>
            <p>
              S'il s'agit de séjours longue durée (28 nuits ou plus), Gowifmi envoie vos revenus
              sous forme de mensualités. Le premier versement est envoyé 24 heures après l'arrivée
              des voyageurs. Vous pouvez consulter le statut de vos versements dans votre
              <a href='/account-settings/versement'> historique des transactions</a> à tout moment.
            </p>
          </div>
          <h2>Points à retenir</h2>
          <div>
            <ul>
              <li>
                En ajoutant un <a href='/account-settings/versement'>mode de versement</a>, vous
                garantissez l'arrivée des fonds à bon port.
              </li>
              <li>
                Pour calculer un versement, tenez compte de votre tarif par nuit, des frais
                supplémentaires et des taxes.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}
export default GetPayementHost
