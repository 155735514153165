import { useEffect, useContext, useState } from 'react'
import styles from '../../../css/modehotes/statistics/evaluations/statistic.module.css'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { User } from '../../../context/UserContext'
import LoadingPoint from '../../../components/LoadingPoint'
import HostModeLayout from '../../../layouts/HostModeLayout'
import ErrorServer from '../../errors/ErrorServer'
import { useQuery } from 'react-query'
import Header from '../../../components/hote_mode/statistics/Header'
import GlobalEvaluation from '../../../components/hote_mode/statistics/evaluations/GlobalEvaluation'
import Comment from '../../../components/hote_mode/statistics/evaluations/Comment'
import ListingHotel from '../../../components/hote_mode/statistics/ListingHotel'
import { Helmet } from 'react-helmet-async'

const CommentListing = () => {
  const [selectedListing, setSelectedListing] = useState('')

  const user = useContext(User)
  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
    if (user === null) {
      history.push('/')
    }
  }, [history])

  const {
    data = {},
    isLoading,
    error,
  } = useQuery(['get-host_evaluation', selectedListing], () =>
    axios
      .get(
        `/statistics/host_evaluation?it_host_request=${true}&host_id=${
          user.id
        }&hotel_id=${selectedListing}`
      )
      .then(res => {
        return res.data
      })
  )
  if (error) return <ErrorServer />

  return (
    <HostModeLayout>
      <Helmet>
        <title>Évaluations - Gowifmi</title>
      </Helmet>
      <div className={styles.container}>
        <Header />
        <ListingHotel selectedListing={selectedListing} setSelectedListing={setSelectedListing} />
        {isLoading ? (
          <div className={styles.container_load_liste}>
            <LoadingPoint />
          </div>
        ) : (
          <div className={styles.main}>
            <GlobalEvaluation data={data} />
            <Comment commentLength={data.commentLength} comments={data.comments} />
          </div>
        )}
      </div>
    </HostModeLayout>
  )
}

export default CommentListing
