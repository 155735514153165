import { useRef } from 'react'
import styles from '../../../css/search_room_result/filtre_1.module.css'
import FilterModal from '../FilterModal'
import { useSearchParams } from '../../../context/SearchParamContext'

const ButtonHandleAllFilter = ({ tableau, setTableau }) => {
  const { params } = useSearchParams()
  const newParams = new URLSearchParams(params.toString())
  newParams.delete('place')
  newParams.delete('page')
  newParams.delete('children')
  newParams.delete('adults')
  newParams.delete('infants')
  newParams.delete('pets')
  newParams.delete('checkin')
  newParams.delete('checkout')
  newParams.delete('place_id')
  newParams.delete('zoom')
  newParams.delete('search_by_map')
  newParams.delete('lat')
  newParams.delete('lng')

  const Filters = Array.from(newParams).length
  const Allfilter = useRef()
  const Handlfilter = () => {
    Allfilter.current.OpenModal()
  }
  return (
    <>
      <div className={Filters > 0 ? `${styles.all_filter} ${styles.active}` : styles.all_filter}>
        <button onClick={Handlfilter}>
          <svg
            viewBox='0 0 16 16'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            role='presentation'
            focusable='false'
          >
            <path d='M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z'></path>
          </svg>
          <span>Filtres</span>
        </button>
        <span className={styles.length}>{Filters}</span>
      </div>

      <FilterModal ref={Allfilter} tableau={tableau} setTableau={setTableau} />
    </>
  )
}

export default ButtonHandleAllFilter
