import React, { useState } from 'react'
import styles from '../../../../../css/modehotes/reservation/politiqueCommente.module.css'
import Modal from '../../../../ui/Modal'
import CloseButton from '../../../../ui/CloseButton'

const Policy = React.forwardRef((props, ref) => {
  const [modal, setModal] = useState(false)

  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      setModal(true)
    },
  }))

  return (
    <>
      <Modal isOpen={modal} onClose={() => setModal(false)}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={() => setModal(false)} />
            <div></div>
          </div>
          <div className={styles.m_body}>
            <h2>Modération de Gowifmi relative à la contestation de commentaires</h2>
            <div>
              <p>
                Nous nous engageons à fournir une plateforme utile à la communauté, et nous
                continuerons à mettre à jour cette politique si nécessaire pour garantir que les
                avis sont honnêtes et amortir les expériences réelles des hôtes et des voyageurs.
              </p>
              <p>
                Nous pensons que l'authenticité d'un commentaire est cruciale pour une bonne
                communauté, c'est pourquoi nous prenons au sérieux toute action visant à supprimer
                de commentaire. Vous pouvez lire la Politique de Gowifmi en matière de commentaires
                dans son intégralité, mais en résumé, Un commentaire peut être supprimé si l'une des
                conditions suivantes est remplie :
              </p>
              <ul>
                <li>Le commentaire enfreint la Politique de Gowifmi en matière de contenus.</li>
                <li>Le commentaire est biaisé.</li>
                <li>
                  Le commentaire ne reflète pas l'expérience vécue par son auteur sur Gowifmi.
                </li>
              </ul>
            </div>
            <div>
              <h3>Modération relative à la partialité</h3>
              <p>
                Gowifmi supprime les commentaires lorsqu'ils font preuve d'un parti pris
                inapproprié, par exemple lorsque l'auteur du commentaire tente d'extorquer de
                l'argent à la personne qui le reçoit, ou se trouve dans une situation de concurrence
                avec cette personne.
              </p>
            </div>
            <div>
              <h3>Extorsion ou incitation</h3>
              <p>
                Nous n'autorisons aucune tentative d'utiliser les commentaires ou les réponses aux
                commentaires pour inciter quelqu'un à faire quelque chose qui n'entre pas dans le
                cadre de ses obligations.
              </p>
              <p>Cette politique interdit les cas suivants :</p>
              <ul>
                <li>
                  Les voyageurs qui menacent d'utiliser les commentaires ou les évaluations dans le
                  but de pousser un hôte à effectuer un remboursement, accorder un dédommagement
                  supplémentaire ou laisser un commentaire positif en retour.
                </li>
                <li>
                  Les hôtes qui demandent à un voyageur de laisser une évaluation ou un commentaire
                  positif, à ce qu'il revoit son commentaire en échange d'un remboursement, partiel
                  ou total, ou d'un commentaire en retour. De même, un hôte ne peut pas offrir un
                  séjour gratuit ou à prix réduit à un voyageur qui modifierait un commentaire
                  existant.
                </li>
                <li>
                  Les hôtes ou les voyageurs qui demandent à quelqu'un des actions spécifiques sur
                  un commentaire en échange de la résolution du problème.
                </li>
              </ul>
              <p>Cette politique n'interdit pas les cas suivants :</p>
              <ul>
                <li>
                  Les voyageurs qui prennent contact avec un hôte pour lui signaler un problème
                  avant de laisser un commentaire.
                </li>
                <li>
                  Les hôtes ou voyageurs qui demandent un remboursement ou un paiement
                  supplémentaire et laissent un commentaire : lorsque ce commentaire n'est pas
                  utilisé comme une menace pour influencer le résultat d'une résolution.
                </li>
                <li>
                  Les hôtes qui demandent aux voyageurs de laisser une note ou un commentaire
                  honnête et positif.
                </li>
                <li>
                  Les hôtes ou voyageurs qui modifient un commentaire, à condition de le faire dans
                  le délai imparti.
                </li>
              </ul>
            </div>
            <div>
              <h3>Conflit d'intérêts</h3>
              <p>
                Nous supprimons les commentaires s'il y a des indications que la réservation a été
                faite uniquement pour gonfler l'évaluation globale d'une personne ou si nous
                soupçonnons que le séjour n'a jamais eu lieu.
              </p>
              <p>
                Il est strictement interdit d'accepter de fausses réservations en échange d'un
                commentaire positif, d'avoir un deuxième compte pour publier un commentaire sur
                soi-même ou son logement, ou de proposer un bien ou service de valeur en échange de
                commentaires positifs. Le non-respect de ces règles peut entraîner la restriction de
                l'utilisation, la suspension ou la résiliation de votre compte Gowifmi.
              </p>
            </div>
            <div>
              <h3>Commentaires de concurrents</h3>
              <p>
                Les commentaires rédigés par des concurrents dans le but de décourager d'autres
                personnes de réserver ces logements ou d'attirer les utilisateurs vers d'autres
                logements ne sont pas autorisés sur Gowifmi. Nous pouvons restreindre, suspendre ou
                résilier votre compte si vous ne respectez pas ces règles.
              </p>
            </div>
            <div>
              <h3>Infractions à l'interdiction d'organiser des fêtes</h3>
              <p>
                Dans de rares cas, nous pouvons supprimer des commentaires dans certaines situations
                où le voyageur n'a pas répondu aux attentes énoncées dans notre politique en matière
                de fêtes et d'événements pendant son séjour. Nous comptons sur nos hôtes pour
                prendre des mesures afin d'essayer d’arrêter une fête avant qu'elle ne devienne un
                problème de sécurité ou ne perturbe le voisinage.
              </p>
            </div>
            <div>
              <h3>Modération relative à la pertinence</h3>
              <p>
                Les commentaires permettent aux membres de la communauté d'être mieux informés
                lorsqu'ils prennent une décision de réservation. Les commentaires sont plus utiles
                lorsqu'ils fournissent des informations impartiales et objectives.
              </p>
              <p>
                Votre commentaire ne sera pas publié immédiatement (il sera en phase d'étude par
                notre équipe), et vous n'êtes pas autorisée à le modifier après sa publication.
                Prenez donc votre temps et réfléchissez à ce que vous voulez dire.
              </p>
              <p>
                Si Gowifmi estime qu'un commentaire ne contient pas d'informations applicables et
                importantes sur un hôte, un voyageur ou un logement alors il peut être supprimé.
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
})
export default Policy
