import { useRef } from 'react'
import styles from '../../css/details/disponibilite.module.css'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import Summary from './Summary'
import moment from 'moment'
import DetailPrice from './DetailPrice'
import usePrice from '../../hooks/usePrice'
import useDisponibilite from '../../hooks/useDisponibilite'
import NavBarScroll from './availability/NavBarScroll'
import BottomBar from './availability/BottomBar'
import TravelerModal from './verif_availability/TravelerModal'
import CalendarModal from './verif_availability/CalendarModal'
import ErrorSvg from '../svgs/ErrorSvg'
import { SATATUS_ANNONCE } from '../../enums/logement/status.enums'

const Availability = ({
  hotel,
  photoRef,
  amenentieRef,
  descriptionRef,
  localisationRef,
  commentRef,
  ProfilHoteRef,
}) => {
  const location = useLocation()
  const id = location.pathname.split('/')[2]
  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  const checkin = Params.get('checkin')
  const checkout = Params.get('checkout')
  const adulte_search = Params.get('adults')
  const enfant_search = Params.get('children')
  const bebe = Params.get('infants')
  const animal = Params.get('pets')
  const adulte = parseInt(adulte_search)
  const enfant = parseInt(enfant_search)

  const voyageur = adulte + enfant || 1

  const { reduction, pricePerNightWithoutPromo, pricePerNightWithPromo, totalWithservice } =
    usePrice(hotel)
  const {
    numberOfTravelerIsInvalid,
    dateIsinvalid,
    dateIsinvalidBloquer,
    startDateInvalid,
    endDateInvalid,
    handleVerifDisponibilite,
  } = useDisponibilite(hotel)

  const avaibilityRef = useRef()

  const verifDate = useRef()
  const VerifDateFunction = () => {
    verifDate.current.OpenModal()
  }

  const verifVoyageur = useRef()
  const voyageurFunction = () => {
    verifVoyageur.current.OpenModal()
  }

  const detailPrice = useRef()
  const handleDetail = () => {
    detailPrice.current.OpenModal()
  }

  return (
    <>
      <NavBarScroll
        avaibilityRef={avaibilityRef}
        numberOfTravelerIsInvalid={numberOfTravelerIsInvalid}
        handleVerifDisponibilite={handleVerifDisponibilite}
        VerifDateFunction={VerifDateFunction}
        voyageurFunction={voyageurFunction}
        handleDetail={handleDetail}
        reduction={reduction}
        pricePerNightWithoutPromo={pricePerNightWithoutPromo}
        pricePerNightWithPromo={pricePerNightWithPromo}
        totalWithservice={totalWithservice}
        id={id}
        checkin={checkin}
        checkout={checkout}
        bebe={bebe}
        animal={animal}
        adulte={adulte}
        enfant={enfant}
        photoRef={photoRef}
        amenentieRef={amenentieRef}
        descriptionRef={descriptionRef}
        localisationRef={localisationRef}
        commentRef={commentRef}
      />
      {/*--------------------------recap-voyages---------------------------------------*/}
      <div className={styles.container}>
        <div className={styles.form} ref={avaibilityRef}>
          <div className={styles.form_action}>
            {dateIsinvalid || dateIsinvalidBloquer || startDateInvalid || endDateInvalid ? (
              <div className={styles.price_normal}>
                <h3>{pricePerNightWithoutPromo}</h3>
                <span>/ nuit</span>
              </div>
            ) : (
              <div className={styles.head}>
                <div className={styles.default_price}>
                  {reduction > 0 && <p className={styles.promo}>{pricePerNightWithoutPromo}</p>}
                  <h3>{pricePerNightWithPromo}</h3>
                  <span>/ nuit</span>
                </div>
                <div>
                  <button onClick={handleDetail}>{totalWithservice}</button>
                  <span>Total</span>
                </div>
              </div>
            )}
            <div className={styles.block_recap}>
              <button className={styles.dates}>
                <div
                  className={
                    startDateInvalid
                      ? `${styles.dates_start} ${styles.date_invalid}`
                      : styles.dates_start
                  }
                  onClick={VerifDateFunction}
                >
                  <h3>Arrivée</h3>
                  {checkin ? <p>{moment(checkin).format('ll')}</p> : <p>Ajouter une date</p>}
                  <div className={styles.block_error}>
                    <div>
                      <ErrorSvg />
                    </div>
                    <p className={styles.invalid}>Jour d'arrivée non autorisé</p>
                  </div>
                </div>
                <div
                  className={
                    endDateInvalid ? `${styles.dates_end} ${styles.date_invalid}` : styles.dates_end
                  }
                  onClick={VerifDateFunction}
                >
                  <h3>Départ</h3>
                  {checkout ? <p>{moment(checkout).format('ll')}</p> : <p>Ajouter une date</p>}
                  <div className={styles.block_error}>
                    <div>
                      <ErrorSvg />
                    </div>
                    <p className={styles.invalid}>Jour de départ non autorisé</p>
                  </div>
                </div>
              </button>
              <button className={styles.voyageur} onClick={voyageurFunction}>
                <p>
                  {voyageur} {voyageur > 1 ? 'voyageurs' : 'voyageur'}
                  {bebe > 0 && `, ${bebe} ${bebe > 1 ? 'Bébés' : 'Bébé'}`}{' '}
                  {animal > 0 && `, ${animal} ${animal > 1 ? 'Animaux' : 'Animal'}`}
                </p>
              </button>
            </div>
            {handleVerifDisponibilite() ? (
              <div className={styles.verif_button}>
                {numberOfTravelerIsInvalid ? (
                  <button className={styles.nav_button} onClick={voyageurFunction}>
                    Modifier le nombre de voyageurs
                  </button>
                ) : (
                  <button className={styles.nav_button} onClick={VerifDateFunction}>
                    Vérifier la disponibilité
                  </button>
                )}
              </div>
            ) : (
              <div className={styles.btn_block}>
                <Link
                  to={`/book/${id}?adults=${adulte}&children=${enfant}&infants=${bebe}&pets=${animal}&checkin=${checkin}&checkout=${checkout}`}
                  className={styles.nav_button}
                >
                  Réserver
                </Link>
              </div>
            )}
          </div>
        </div>
        {!hotel.gowifmi_published || hotel.status !== SATATUS_ANNONCE.PUBLISH ? (
          <div className={styles.desactive}>
            <ErrorSvg />
            <p>Annonce désactivée</p>
          </div>
        ) : null}
        {/*------------resumer bref ---------*/}
        <Summary
          hotel={hotel}
          reduction={reduction}
          localisationRef={localisationRef}
          commentRef={commentRef}
          ProfilHoteRef={ProfilHoteRef}
        />
      </div>
      {/*--------bottom bar--mobile---------------------------------------------------------*/}
      <BottomBar
        numberOfTravelerIsInvalid={numberOfTravelerIsInvalid}
        handleVerifDisponibilite={handleVerifDisponibilite}
        VerifDateFunction={VerifDateFunction}
        voyageurFunction={voyageurFunction}
        handleDetail={handleDetail}
        reduction={reduction}
        pricePerNightWithoutPromo={pricePerNightWithoutPromo}
        pricePerNightWithPromo={pricePerNightWithPromo}
        id={id}
        checkin={checkin}
        checkout={checkout}
        bebe={bebe}
        animal={animal}
        adulte={adulte}
        enfant={enfant}
      />
      <CalendarModal ref={verifDate} hotel={hotel} />
      <TravelerModal ref={verifVoyageur} hotel={hotel} />
      <DetailPrice ref={detailPrice} hotel={hotel} />
    </>
  )
}

export default Availability
