import { useState } from 'react'
import styles from '../../../css/account/profile/modif_profil.module.css'
import LoadingPoint from '../../LoadingPoint'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import ProfilCategory from './modifications/ProfilCategorie'

const ModifProfile = ({ getUser, setModifProfil, refetch }) => {
  const [categories, setCategories] = useState(getUser.profile_categories)
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [errorUpdate, setErrorUpdate] = useState(false)
  const { register, handleSubmit } = useForm({ mode: 'onChange' })
  const lengthMaxAboutMe = 500

  const onSubmit = data => {
    setLoadingUpdate(true)
    const formData = {
      ...data,
      profile_categories: categories,
    }
    axios
      .patch(`/user/${getUser.id}`, formData)
      .then(() => {
        refetch()
        setLoadingUpdate(false)
        window.scrollTo(0, 0)
      })
      .catch(() => {
        setErrorUpdate(true)
      })
      .finally(() => {
        setLoadingUpdate(false)
        setModifProfil(false)
      })
  }
  const handleClose = () => {
    setModifProfil(false)
    window.scrollTo(0, 0)
  }

  if (errorUpdate) alert('error server')

  return (
    <>
      <form className={styles.form_modif} onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
        <div>
          <label htmlFor='about_me'>À propos de vous</label>
          <textarea
            id='about_me'
            defaultValue={getUser.about_me}
            maxLength={lengthMaxAboutMe}
            {...register('about_me', { value: getUser.about_me })}
          ></textarea>
          <span>{lengthMaxAboutMe} caractères maximum</span>
        </div>
        <ProfilCategory categories={categories} setCategories={setCategories} />
        <div className={styles.modif_button}>
          <button type='button' onClick={handleClose}>
            Annuler
          </button>
          {loadingUpdate ? (
            <button disabled className={styles.loading}>
              <LoadingPoint />
            </button>
          ) : (
            <button type='submit'>Enregistrer</button>
          )}
        </div>
      </form>
    </>
  )
}

export default ModifProfile
