import { PROFIL_CATEGORY } from '../../../enums/user/category.enums'

export const categoryOptions = [
  {
    name: 'Guide touristique',
    description:
      'Expert local qui partage des connaissances approfondies sur les lieux, les histoires et les traditions.',
    value: PROFIL_CATEGORY.TOURIST_GUIDE,
    icon: (
      <svg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='16' cy='10' r='4' fill='none' stroke='black' strokeWidth='2'></circle>
        <line x1='16' y1='14' x2='16' y2='22' stroke='black' strokeWidth='2'></line>
        <line x1='16' y1='16' x2='12' y2='20' stroke='black' strokeWidth='2'></line>
        <line x1='16' y1='16' x2='20' y2='20' stroke='black' strokeWidth='2'></line>
        <line x1='16' y1='22' x2='12' y2='26' stroke='black' strokeWidth='2'></line>
        <line x1='16' y1='22' x2='20' y2='26' stroke='black' strokeWidth='2'></line>
        <line x1='14' y1='18' x2='10' y2='22' stroke='black' strokeWidth='2'></line>
      </svg>
    ),
  },
  {
    name: 'Photographe ',
    description:
      'Capture les moments inoubliables des voyageurs, immortalise les paysages et les cultures locales.',
    value: PROFIL_CATEGORY.PHOTOGRAPHER,
    icon: (
      <svg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
        <rect
          x='6'
          y='10'
          width='20'
          height='16'
          rx='2'
          ry='2'
          fill='none'
          stroke='black'
          strokeWidth='2'
        ></rect>
        <circle cx='16' cy='18' r='5' fill='none' stroke='black' strokeWidth='2'></circle>
        <rect
          x='10'
          y='6'
          width='12'
          height='4'
          rx='2'
          ry='2'
          fill='none'
          stroke='black'
          strokeWidth='2'
        ></rect>
      </svg>
    ),
  },
  {
    name: 'Chef cuisinier local',
    description:
      'Propose des expériences culinaires authentiques, met en avant la gastronomie locale.',
    value: PROFIL_CATEGORY.LOCAL_CHEF,
    icon: (
      <svg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
        <rect
          x='10'
          y='16'
          width='12'
          height='8'
          rx='2'
          ry='2'
          fill='none'
          stroke='black'
          strokeWidth='2'
        ></rect>
        <path
          d='M16 16 Q20 12 16 8 Q12 12 16 16 Z'
          fill='none'
          stroke='black'
          strokeWidth='2'
        ></path>
        <line x1='12' y1='22' x2='20' y2='22' stroke='black' strokeWidth='2'></line>
      </svg>
    ),
  },
  {
    name: 'Artisan local',
    description: "Fabrique et vend des souvenirs authentiques, représentant l'artisanat local.",
    value: PROFIL_CATEGORY.LOCAL_CRAFTSMAN,
    icon: (
      <svg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='16' cy='16' r='6' fill='none' stroke='black' strokeWidth='2'></circle>
        <line x1='10' y1='10' x2='22' y2='22' stroke='black' strokeWidth='2'></line>
        <line x1='22' y1='10' x2='10' y2='22' stroke='black' strokeWidth='2'></line>
      </svg>
    ),
  },
  {
    name: 'Traducteur/interprète',
    description:
      'Facilite la communication entre les voyageurs et les locaux dans des pays étrangers.',
    value: PROFIL_CATEGORY.TRANSLATOR_INTERPRETER,
    icon: (
      <svg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
        <rect
          x='6'
          y='8'
          width='20'
          height='16'
          rx='2'
          ry='2'
          fill='none'
          stroke='black'
          strokeWidth='2'
        ></rect>
        <line x1='6' y1='12' x2='26' y2='12' stroke='black' strokeWidth='2'></line>
        <line x1='6' y1='16' x2='26' y2='16' stroke='black' strokeWidth='2'></line>
        <line x1='6' y1='20' x2='26' y2='20' stroke='black' strokeWidth='2'></line>
        <path d='M10 12 L12 14 L10 16' fill='none' stroke='black' strokeWidth='2'></path>
        <path d='M22 12 L20 14 L22 16' fill='none' stroke='black' strokeWidth='2'></path>
      </svg>
    ),
  },
  {
    name: 'Historien local ',
    description: 'Partage des récits et des faits historiques sur des lieux emblématiques.',
    value: PROFIL_CATEGORY.LOCAL_HISTORIAN,
    icon: (
      <svg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
        <rect
          x='6'
          y='6'
          width='20'
          height='20'
          rx='2'
          ry='2'
          fill='none'
          stroke='black'
          strokeWidth='2'
        ></rect>
        <line x1='6' y1='12' x2='26' y2='12' stroke='black' strokeWidth='2'></line>
        <line x1='6' y1='18' x2='26' y2='18' stroke='black' strokeWidth='2'></line>
        <line x1='16' y1='6' x2='16' y2='26' stroke='black' strokeWidth='2'></line>
      </svg>
    ),
  },
  {
    name: 'Spécialiste en voyages pour familles',
    description: 'Crée des itinéraires adaptés aux besoins des familles avec enfants.',
    value: PROFIL_CATEGORY.FAMILY_TRAVEL_SPECIALIST,
    icon: (
      <svg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='10' cy='12' r='3' fill='none' stroke='black' strokeWidth='2'></circle>
        <circle cx='22' cy='12' r='3' fill='none' stroke='black' strokeWidth='2'></circle>
        <rect
          x='8'
          y='16'
          width='16'
          height='10'
          rx='2'
          ry='2'
          fill='none'
          stroke='black'
          strokeWidth='2'
        ></rect>
        <path d='M6 20 L16 10 L26 20' fill='none' stroke='black' strokeWidth='2'></path>
      </svg>
    ),
  },
  {
    name: "Organisateur d'excursions",
    description:
      'Propose des activités telles que des randonnées, des safaris, ou des visites de vignobles.',
    value: PROFIL_CATEGORY.EXCURSION_ORGANIZER,
    icon: (
      <svg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='16' cy='16' r='10' fill='none' stroke='black' strokeWidth='2'></circle>
        <line x1='16' y1='6' x2='16' y2='26' stroke='black' strokeWidth='2'></line>
        <line x1='6' y1='16' x2='26' y2='16' stroke='black' strokeWidth='2'></line>
        <line x1='10' y1='10' x2='22' y2='22' stroke='black' strokeWidth='2'></line>
        <line x1='22' y1='10' x2='10' y2='22' stroke='black' strokeWidth='2'></line>
      </svg>
    ),
  },
  {
    name: 'Influenceur voyage',
    description:
      'Partage des expériences de voyage et inspire les autres à explorer de nouvelles destinations.',
    value: PROFIL_CATEGORY.TRAVEL_INFLUENCER,
    icon: (
      <svg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
        <rect
          x='6'
          y='8'
          width='20'
          height='16'
          rx='2'
          ry='2'
          fill='none'
          stroke='black'
          strokeWidth='2'
        ></rect>
        <circle cx='16' cy='16' r='4' fill='none' stroke='black' strokeWidth='2'></circle>
        <line x1='10' y1='12' x2='22' y2='20' stroke='black' strokeWidth='2'></line>
        <line x1='22' y1='12' x2='10' y2='20' stroke='black' strokeWidth='2'></line>
      </svg>
    ),
  },
]
