import styles from '../../css/details/commentaire.module.css'
import moment from 'moment'
import Modal from '../ui/Modal'
import CloseButton from '../ui/CloseButton'
import useModalByUrl from '../../hooks/useModalByUrl'
import ButtonTertiary from '../ui/ButtonTertiary'

const Commentaire = ({ hotel, commentRef }) => {
  const modalValue = 'COMMENTER'
  const { modalState, handleOpenModal, handleCloseModal } = useModalByUrl(modalValue)

  const commentLength = hotel.comments?.filter(el => el.published === true).length

  return (
    <>
      <div ref={commentRef} className={`${styles.commentaire_avis} ${styles.section}`}>
        {commentLength > 0 ? (
          <>
            <div className={styles.block}>
              <div className={styles.colone} aria-hidden={true} style={{ flex: '0 0 14px' }}></div>
              {[...hotel?.comments]
                .reverse()
                .filter(el => el.published === true)
                .slice(0, 6)
                .map((item, i) => (
                  <div className={styles.content} key={i}>
                    <div className={styles.profil_block_avis}>
                      <a
                        href={`/profile/${item.user_id?.id}`}
                        target='_blank'
                        className={styles.image_block}
                        rel='noreferrer'
                      >
                        {item.user_id?.picture ? (
                          <img src={item.user_id.picture} alt='' />
                        ) : (
                          <h2>{item.user_id?.first_name?.slice(0, 1)}</h2>
                        )}
                      </a>
                      <div>
                        {item.user_id?.first_name ? (
                          <h4>{item.user_id?.first_name}</h4>
                        ) : (
                          <h4>{item.user_id?.first_name.split(0, 1)}</h4>
                        )}
                        <p>{moment(item.createdAt).format('LL').slice(2)}</p>
                      </div>
                    </div>
                    <div>
                      <p>{item.comment}</p>
                    </div>
                  </div>
                ))}
              <div className={styles.voir_plus} onClick={handleOpenModal}>
                <div className={styles.voir_plus_icon}>
                  <svg
                    viewBox='0 0 32 32'
                    xmlns='http://www.w3.org/2000/svg'
                    aria-hidden='true'
                    role='presentation'
                    focusable='false'
                    style={{
                      display: 'block',
                      height: '16px',
                      width: '16px',
                      stroke: 'currentColor',
                      strokeWidth: '4',
                      overflow: 'visible',
                      fill: 'none',
                    }}
                  >
                    <g>
                      <path d='m12 4 11.2928932 11.2928932c.3905243.3905243.3905243 1.0236893 0 1.4142136l-11.2928932 11.2928932'></path>
                    </g>
                  </svg>
                </div>
                <h5>Afficher tous les commentaires</h5>
              </div>
              <div className={styles.colone} aria-hidden={true} style={{ flex: '0 0 14px' }}></div>
            </div>
            {commentLength > 1 && (
              <div className={styles.more}>
                <ButtonTertiary type='button' onClick={handleOpenModal} className={styles.button}>
                  Afficher tous les commentaires
                </ButtonTertiary>
              </div>
            )}
          </>
        ) : (
          <>
            <h3>Aucun commentaire (pour l'instant)</h3>
            <p>Nous sommes là pour vous aider à passer un agréable voyage.</p>
          </>
        )}
      </div>

      <Modal isOpen={modalState} onClose={handleCloseModal}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={handleCloseModal} />
            <div></div>
          </div>
          <div className={styles.m_body}>
            {[...hotel?.comments]
              ?.reverse()
              .filter(el => el.published === true)
              .map((item, i) => (
                <div className='' key={i}>
                  <div className={styles.profil_block_avis}>
                    <a
                      href={`/profile/${item.user_id?.id}`}
                      target='_blank'
                      className={styles.image_block}
                      rel='noreferrer'
                    >
                      {item.user_id?.picture ? (
                        <img src={item.user_id?.picture} alt='' />
                      ) : (
                        <h2>{item.user_id?.first_name?.slice(0, 1)}</h2>
                      )}
                    </a>
                    <div>
                      <h4>{item.user_id?.first_name}</h4>
                      <p>{moment(item.createdAt).format('LL').slice(2)}</p>
                    </div>
                  </div>
                  <div>
                    <p>{item.comment}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Commentaire
