import styles from '../../../../../../../css/modehotes/annonce/details/detail_head.module.css'
import Tooltip from '../../../../../../ui/Tooltip'

const Preview = ({ annonce }) => {
  const origin = window.location.origin

  return (
    <Tooltip title="Prévisualiser l'annonce">
      <div>
        <a href={`${origin}/rooms/${annonce._id}?preview=true`} rel='noreferrer' target='_blanck'>
          <svg
            className={styles.preview}
            viewBox='0 0 32 32'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            role='presentation'
            focusable='false'
          >
            <path d='m16 5a15.008 15.008 0 0 1 14.495 11.127l.133.52-.11.295a15.504 15.504 0 0 1 -29.036 0l-.11-.295.136-.53a15.005 15.005 0 0 1 14.492-11.117zm0 5.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11zm0 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5z'></path>
          </svg>
        </a>
      </div>
    </Tooltip>
  )
}

export default Preview
