import { useEffect, useContext } from 'react'
import styles from '../../../css/becomeHostes/globalStyle.module.css'
import { User } from '../../../context/UserContext'
import { Crisp } from 'crisp-sdk-web'
import { crispWebsiteId } from '../../../config/config'

const Description = ({ title, desc }) => {
  const user = useContext(User)

  useEffect(() => {
    if (user) {
      Crisp.configure(crispWebsiteId, { autoload: false })
      Crisp.user.setEmail(user?.email)
      Crisp.user.setNickname(`${user?.first_name} ${user?.name}`)
      Crisp.session.setData({
        gowifmi_user_id: user?.id,
      })
    }

    return () => {
      Crisp.chat.hide()
    }
  }, [])

  const handleChat = () => {
    Crisp.chat.show()
    Crisp.chat.open()
  }

  Crisp.chat.onChatClosed(() => {
    Crisp.chat.hide()
  })

  return (
    <div className={styles.right_block}>
      <div className={styles.right_head}>
        <div className={styles.right_head_buttons}>
          <button type='button' className={styles.btn_action} onClick={handleChat}>
            Des questions ?
          </button>
          <a href='/hosting' className={styles.btn_action}>
            Quitter
          </a>
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.description_text}>
          {title && <h1>{title}</h1>}
          {desc && <p>{desc}</p>}
        </div>
      </div>
    </div>
  )
}

export default Description
