import styles from '../../css/add_to_favoris/favoris.module.css'
import { useSelector } from 'react-redux'
import axios from 'axios'
import moment from 'moment'
import CloseButton from '../ui/CloseButton'

const ViewList = ({
  annonce,
  setModal,
  setCreateList,
  setPushFavori,
  setsupFavori,
  getAllFavoris,
}) => {
  const favoris = useSelector(state => state.getFavorisReducer)

  const handleAddToliste = list_id => {
    const datas = {
      hotelId: annonce._id,
    }
    axios
      .patch(`/favoris/addTlist/${list_id}`, datas)
      .then(() => {
        setModal(false)
        pupupModal()
      })
      .catch(() => {})
  }
  const pupupModal = () => {
    getAllFavoris()
    setPushFavori(true)
    setsupFavori(false)
    setTimeout(() => {
      setPushFavori(false)
    }, 3000)
  }

  return (
    <>
      <div className={styles.modal_content}>
        <div className={styles.m_head}>
          <CloseButton onClick={() => setModal(false)} />
          <h3>Vos favoris</h3>
          <div></div>
        </div>
        <div className={styles.m_body}>
          {favoris?.length < 1 && (
            <div className={styles.no_list}>
              <h4>Vous n'avez aucune liste</h4>
              <p>Les listes vous permettent d'organiser vos voyages par ville ou par nom.</p>
            </div>
          )}
          <button className={styles.creatList} onClick={() => setCreateList(true)}>
            <div>
              <svg
                viewBox='0 0 32 32'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
              >
                <path d='m2 16h28m-14-14v28'></path>
              </svg>
            </div>
            <p>Créer une nouvelle liste de favoris</p>
          </button>
          {favoris?.length > 0 && (
            <div className={styles.all_list}>
              {favoris?.map(el => (
                <button
                  key={el._id}
                  className={styles.addTolist}
                  onClick={() => handleAddToliste(el._id)}
                >
                  <div className={styles.lists_card}>
                    {el.lists[0] && <img src={el.lists[0]?.hotel?.images[0]?.url} alt='' />}
                  </div>
                  <div>
                    <h4>{el.name}</h4>
                    <p>{moment(el.createdAt).format('ll')}</p>
                  </div>
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ViewList
