import styles from '../../../css/becomeHostes/home/section_3.module.css'
import Accordion from '../../ui/Accordion'

const Section3 = () => {
  const questions = [
    {
      title: 'Mon logement convient-il à la location sur Gowifmi ?',
      content:
        "Absolument ! Gowifmi accueille et accepte tous les types de logements. Que votre propriété soit une simple chambre, une maison, un appartement, une villa, une cabane au bord de la mer, ou même une location de vacances plus unique, notre plateforme est conçue pour s'adapter à une variété de logements.",
    },
    {
      title: 'Ai-je le temps nécessaires pour gérer mon logement sur Gowifmi ?',
      content:
        'Gowifmi offre des fonctionnalités automatisées, mais vous pourriez également préférer une implication plus directe dans certaines étapes du processus.',
    },
    {
      title: "Est-ce que je suis obligé d'accueillir des voyageurs fréquemment ?",
      content:
        "Non. C'est vous qui contrôlez votre calendrier. Vous pouvez accueillir des voyageurs quelques nuits par mois , une fois par an ou plus fréquemment.",
    },
    {
      title: 'Quelles sont les options de paiement disponibles pour les voyageurs sur Gowifmi ?',
      content:
        "Sur Gowifmi, nous offrons aux hôtes la flexibilité de choisir entre deux options de paiement pour leurs voyageurs. Vous avez la possibilité de permettre aux voyageurs de régler leur séjour en ligne directement via la plateforme Gowifmi, offrant ainsi une solution de paiement sécurisée et pratique. D'autre part, si vous préférez gérer les paiements sur place, cette option est également disponible. Gowifmi vous donne la liberté de décider de la méthode de paiement qui correspond le mieux à vos préférences, offrant ainsi une expérience de gestion d'annonces personnalisée et adaptée à vos besoins.",
    },
    {
      title: 'Quels sont les frais appliqués par Gowifmi ?',
      content:
        "Gowifmi applique généralement des frais de service fixes s'élevant à 3 % du sous-total de la réservation sur les versements que vous recevez.",
    },
  ]

  return (
    <div className={styles.container}>
      <div className={styles.block_left}>
        <h3>
          Des réponses <br /> à vos questions
        </h3>
      </div>
      <div>
        {questions.map((item, i) => (
          <div className={styles.accordion_block} key={i}>
            <Accordion title={item.title} content={item.content} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Section3
