import styles from '../../../css/becomeHostes/question.module.css'
import { HOST_STATUS_ACTIVITY } from '../../../enums/logement/host_status_activity.enums'

const StatusHoteActivite = ({ hoteStatusActivity, setHoteStatusActivity }) => {
  const data = [
    {
      label: "J'accueille des voyageurs en tant que particulier",
      value: HOST_STATUS_ACTIVITY.PARTICULAR,
    },
    {
      label: " J'accueille des voyageurs en tant que professionnel",
      value: HOST_STATUS_ACTIVITY.PROFESSIONAL,
    },
  ]
  return (
    <div>
      <h2>Quel type d'activité d'hôte exercez-vous sur Gowifmi ?</h2>
      <div>
        <div className={styles.grid_system}>
          {data.map(item => (
            <div className={styles.checkox_contener} key={item.value}>
              <label htmlFor={item.value}>{item.label}</label>
              <div>
                <input
                  type='radio'
                  id={item.value}
                  value={item.value}
                  checked={hoteStatusActivity === item.value}
                  onChange={e => setHoteStatusActivity(e.target.value)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default StatusHoteActivite
