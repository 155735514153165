import styles from '../../../../../css/traveler/travel/detailReservation.module.css'
import moment from 'moment-timezone'
import StatutModif from './components/StatutModif'
import CommentHost from './components/CommentHost'
import DateVoyage from './components/DateVoyage'
import Voyageur from './components/Voyageur'
import ValidSvg from '../../../../../components/svgs/ValidSvg'
import { getDaysDif } from '../../../../../utils/difTwoDate'
import { STATUS_BOOKING } from '../../../../../enums/booking/status.enums'
import Cancelation from './components/Cancelation'
import ReservationStatus from '../../../../hote_mode/default_components/ReservationStatus'
import RefundTotal from '../../../../hote_mode/bookings/details/reservations/components/RefundTotal'
import DetailPrice from './components/DetailPrice'
import DownloadReceipt from './components/DownloadReceipt'

const Reservation = ({ id, reservation, calculatedPrices, refetch }) => {
  const checkOut = reservation.reservation_infos?.checkin
  const dateNow = new Date()
  const dateLimit = 14

  const userTimezone = moment.tz.guess()

  return (
    <>
      <div className={styles.block}>
        <h2>Les informations de la réservation</h2>
        <div className={styles.content}>
          <h3>Statut de la réservation</h3>
          <ReservationStatus reservation={reservation} />
        </div>

        <div className={styles.content}>
          <h3>Paiement en ligne</h3>
          <div className={styles.demande}>
            <ValidSvg state={reservation.pay_online} />
            <span>{reservation.pay_online ? 'Oui' : 'Non'}</span>
          </div>
        </div>

        {reservation.status === STATUS_BOOKING.COMPLETE &&
        getDaysDif(checkOut, dateNow) <= dateLimit &&
        !reservation.comment ? (
          <CommentHost id={id} reservation={reservation} refetch={refetch} />
        ) : null}
        <DateVoyage reservation={reservation} refetch={refetch} id={id} />
        <Voyageur reservation={reservation} refetch={refetch} id={id} />
        <DetailPrice reservation={reservation} calculatedPrices={calculatedPrices} />
        <div className={styles.content}>
          <h3>Informations relatives à l'arrivée</h3>
          <p>
            Arrivée :{' '}
            {!reservation.conditions?.check_in_1 ? 'Flexibles' : reservation.conditions?.check_in_1}{' '}
            -{' '}
            {!reservation.conditions?.check_in_2 ? 'Flexibles' : reservation.conditions?.check_in_2}
          </p>
          <p>
            Départ :{' '}
            {!reservation.conditions?.check_out ? 'Flexibles' : reservation.conditions?.check_out}
          </p>
        </div>
        <div className={styles.content}>
          <h3>Fumeurs</h3>
          {reservation.conditions?.smoking_allowed ? (
            <p>Les fumeurs sont admis</p>
          ) : (
            <p>Non-fumeurs</p>
          )}
        </div>
        <div className={styles.content}>
          <h3>Les animaux</h3>
          {reservation.conditions?.pets_allowed ? (
            <p>Les animaux domestiques sont admis gratuitement</p>
          ) : (
            <p>Pas d'animaux</p>
          )}
        </div>
        <div className={styles.content}>
          <h3>Référence</h3>
          <p>{reservation.reservation_infos?.reference}</p>
        </div>
        {reservation.status === STATUS_BOOKING.FUTURE ||
        reservation.status === STATUS_BOOKING.IN_PROGRESS ? (
          <StatutModif reservation={reservation} refetch={refetch} id={id} />
        ) : null}
        <div className={styles.content}>
          <h3>La date de création</h3>
          <p>{moment.utc(reservation?.createdAt).tz(userTimezone).calendar()}</p>
        </div>
        <div className={styles.content}>
          <h3>La date de la dernière modification</h3>
          <p>{moment.utc(reservation?.updatedAt).tz(userTimezone).calendar()}</p>
        </div>
        <Cancelation id={id} reservation={reservation} refetch={refetch} />
        <RefundTotal reservation={reservation} />
        {reservation.status === STATUS_BOOKING.FUTURE ||
        reservation.status === STATUS_BOOKING.IN_PROGRESS ||
        reservation.status === STATUS_BOOKING.COMPLETE ? (
          <DownloadReceipt reservation={reservation} />
        ) : null}
      </div>
    </>
  )
}

export default Reservation
