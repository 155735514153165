import styles from '../../css/svgs/svgStyle.module.css'

const CheckSvg = () => {
  return (
    <svg
      className={styles.check}
      aria-hidden='true'
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.297 13.213.293 8.255c-.39-.394-.39-1.033 0-1.426s1.024-.394 1.414 0l4.294 4.224 8.288-8.258c.39-.393 1.024-.393 1.414 0s.39 1.033 0 1.426L6.7 13.208a.994.994 0 0 1-1.402.005z'
        fillRule='evenodd'
      ></path>
    </svg>
  )
}

export default CheckSvg
