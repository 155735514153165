import { useEffect, useState, useContext } from 'react'
import styleGlobal from '../../../../css/modehotes/annonce/details/detail.module.css'
import styles from '../../../../css/modehotes/annonce/details/securiteVoyageur.module.css'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { getAnnonce } from '../../../../redux/actions'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { User } from '../../../../context/UserContext'
import useUpdateHotel from '../../../../hooks/useUpdateHotel'
import LoadingPoint from '../../../../components/LoadingPoint'
import ListAnnonce from '../../../../components/hote_mode/annonce/details/defaults/ListAnnonce'
import HeadDetail from '../../../../components/hote_mode/annonce/details/defaults/HeadDetail'
import KitchenJacuzzi from '../../../../components/hote_mode/annonce/details/traveler_securitys/KitchenJacuzzi'
import WaterNearby from '../../../../components/hote_mode/annonce/details/traveler_securitys/WaterNearby'
import Playground from '../../../../components/hote_mode/annonce/details/traveler_securitys/Playground'
import HauteurSansProtection from '../../../../components/hote_mode/annonce/details/traveler_securitys/HauteurSansProtection'
import DangerousAnimal from '../../../../components/hote_mode/annonce/details/traveler_securitys/DangerousAnimal'
import Camera from '../../../../components/hote_mode/annonce/details/traveler_securitys/Camera'
import Weapons from '../../../../components/hote_mode/annonce/details/traveler_securitys/Weapons'

import StepOrStaircase from '../../../../components/hote_mode/annonce/details/traveler_securitys/StepOrStaircase'
import NoiseNuisance from '../../../../components/hote_mode/annonce/details/traveler_securitys/NoiseNuisance'
import CompanionAnimal from '../../../../components/hote_mode/annonce/details/traveler_securitys/CompanionAnimal'
import NoParking from '../../../../components/hote_mode/annonce/details/traveler_securitys/NoParking'
import ShareSpace from '../../../../components/hote_mode/annonce/details/traveler_securitys/ShareSpace'
import AmenitieRestruction from '../../../../components/hote_mode/annonce/details/traveler_securitys/AmenitieRestruction'
import HostModeLayout from '../../../../layouts/HostModeLayout'
import HeadPage from '../../../../components/hote_mode/annonce/details/defaults/HeadPage'
import Toast from '../../../../components/infos/toast'

const SecurityTraveler = () => {
  const [loadingPage, setLoadingPage] = useState(true)
  const [picineInsecure, setPicineInsecure] = useState(false)
  const [eauAproximite, setEauAproximite] = useState(false)
  const [airdeJuex, setAirdeJuex] = useState(false)
  const [hauteurSansProtection, setHauteurSansProtection] = useState(false)
  const [animauDangereux, setAnimauDangereux] = useState(false)
  const [cameras, setCameras] = useState(false)
  const [armes, setArmes] = useState(false)
  const [marchesOuEscaliers, setMarchesOuEscaliers] = useState(false)
  const [nuisancesSonores, setNuisancesSonores] = useState(false)
  const [animauxCompagnie, setAnimauxCompagnie] = useState(false)
  const [pasStationnement, setPasStationnement] = useState(false)
  const [certainsEspacesPartages, setCertainsEspacesPartages] = useState(false)
  const [restrictionsApplicablesEquipements, setRestrictionsApplicablesEquipements] =
    useState(false)
  const user = useContext(User)
  const history = useHistory()
  const location = useLocation()
  const path = location.pathname.split('/')[4]
  const { loading, updateData } = useUpdateHotel()
  const dispatch = useDispatch()
  const Query = window.location.search

  const annonce = useSelector(state => state.updateAnnonce)
  useEffect(() => {
    axios
      .get(`/hotel/oneAnnonce/${path}`)
      .then(res => {
        dispatch(getAnnonce(res.data))
        setPicineInsecure(res.data.traveler_safety?.pool_jacuzzi_unfenced_unlocked)
        setEauAproximite(res.data.traveler_safety?.lake_river_other_water_body_nearby)
        setAirdeJuex(res.data.traveler_safety?.playground_climbing_structure)
        setHauteurSansProtection(res.data.traveler_safety?.height_unbarriered_unprotected)
        setAnimauDangereux(res.data.traveler_safety?.dangerous_animals)
        setCameras(res.data.traveler_safety?.cameras)
        setArmes(res.data.traveler_safety?.weapon)
        setMarchesOuEscaliers(res.data.traveler_safety?.dwelling_includes_stairs_or_staircases)
        setNuisancesSonores(res.data.traveler_safety?.possible_noise_nuisances)
        setAnimauxCompagnie(res.data.traveler_safety?.pets_on_property)
        setPasStationnement(res.data.traveler_safety?.no_on_site_parking)
        setCertainsEspacesPartages(res.data.traveler_safety?.some_spaces_shared)
        setRestrictionsApplicablesEquipements(
          res.data.traveler_safety?.applicable_equipment_restrictions
        )
        setLoadingPage(false)
      })
      .catch(() => {
        setLoadingPage(true)
        alert("une erruer s'est produite")
      })
    return () => {
      setLoadingPage(false)
    }
  }, [dispatch, path])

  const handleSubmit = async () => {
    const datas = {
      traveler_safety: {
        ...annonce.traveler_safety,
        pool_jacuzzi_unfenced_unlocked: picineInsecure,
        lake_river_other_water_body_nearby: eauAproximite,
        playground_climbing_structure: airdeJuex,
        height_unbarriered_unprotected: hauteurSansProtection,
        dangerous_animals: animauDangereux,
        cameras: cameras,
        weapon: armes,
        dwelling_includes_stairs_or_staircases: marchesOuEscaliers,
        possible_noise_nuisances: nuisancesSonores,
        pets_on_property: animauxCompagnie,
        no_on_site_parking: pasStationnement,
        some_spaces_shared: certainsEspacesPartages,
        applicable_equipment_restrictions: restrictionsApplicablesEquipements,
      },
    }
    await updateData(datas)
    Toast.fire({
      icon: 'success',
      title: 'Modification est efféctuée avec succès',
    })
  }
  if (user === null) {
    history.push('/')
  }
  return (
    <HostModeLayout>
      <Helmet>
        <title>Modifier l'annonce : {annonce?.title ?? ''} - Gowifmi</title>
      </Helmet>
      <div className={styleGlobal.voyage_container}>
        <ListAnnonce />
        <div className={styleGlobal.block_details}>
          <HeadDetail annonce={annonce} />
          {loadingPage ? (
            <div className={styleGlobal.loading}>
              <LoadingPoint />
            </div>
          ) : (
            <div>
              <div className={styles.page}>
                <HeadPage annonce={annonce} />
                <div className={styles.dispositif}>
                  <h3>Informations sur le logement et consignes de sécurité</h3>
                  <KitchenJacuzzi
                    picineInsecure={picineInsecure}
                    setPicineInsecure={setPicineInsecure}
                  />
                  <WaterNearby eauAproximite={eauAproximite} setEauAproximite={setEauAproximite} />
                  <Playground airdeJuex={airdeJuex} setAirdeJuex={setAirdeJuex} />
                  <HauteurSansProtection
                    hauteurSansProtection={hauteurSansProtection}
                    setHauteurSansProtection={setHauteurSansProtection}
                  />
                  <DangerousAnimal
                    animauDangereux={animauDangereux}
                    setAnimauDangereux={setAnimauDangereux}
                  />
                  <Camera cameras={cameras} setCameras={setCameras} />
                  <Weapons armes={armes} setArmes={setArmes} />
                  <StepOrStaircase
                    marchesOuEscaliers={marchesOuEscaliers}
                    setMarchesOuEscaliers={setMarchesOuEscaliers}
                  />
                  <NoiseNuisance
                    nuisancesSonores={nuisancesSonores}
                    setNuisancesSonores={setNuisancesSonores}
                  />
                  <CompanionAnimal
                    animauxCompagnie={animauxCompagnie}
                    setAnimauxCompagnie={setAnimauxCompagnie}
                  />
                  <NoParking
                    pasStationnement={pasStationnement}
                    setPasStationnement={setPasStationnement}
                  />
                  <ShareSpace
                    certainsEspacesPartages={certainsEspacesPartages}
                    setCertainsEspacesPartages={setCertainsEspacesPartages}
                  />
                  <AmenitieRestruction
                    restrictionsApplicablesEquipements={restrictionsApplicablesEquipements}
                    setRestrictionsApplicablesEquipements={setRestrictionsApplicablesEquipements}
                  />
                </div>
                <div className={styles.footer}>
                  <div className={styles.footer_block}>
                    <button
                      type='button'
                      onClick={() =>
                        history.push(`/hosting/listings/details/${annonce._id}${Query}`)
                      }
                      className={styles.retour}
                    >
                      Retour
                    </button>
                    {loading ? (
                      <button disabled className={styles.btn_loading}>
                        <LoadingPoint />
                      </button>
                    ) : (
                      <button type='button' onClick={handleSubmit} className={styles.next}>
                        Enregistrer
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </HostModeLayout>
  )
}

export default SecurityTraveler
