import { useState, useEffect, useContext } from 'react'
import styles from '../../../../css/traveler/travel/voyage.module.css'
import { useHistory } from 'react-router-dom'
import { User } from '../../../../context/UserContext'
import axios from 'axios'
import Header from '../../../../components/traveler/travel/Header'
import DefaultLayout from '../../../../layouts/DefaultLayout'
import ErrorServer from '../../../errors/ErrorServer'
import { useQuery } from 'react-query'
import ListReservation from '../../../../components/traveler/travel/ListReservation'
import { Helmet } from 'react-helmet-async'

const ListInProgress = () => {
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)

  const user = useContext(User)
  const history = useHistory()
  useEffect(() => {
    window.scrollTo(0, 0)
    if (user === null) {
      history.push('/')
    }
  }, [page])

  const fetchReservations = (page = 1, reference) =>
    axios
      .get(`/user_reservations/all_reservation_inprogress?page=${page}&reference=${reference}`)
      .then(res => {
        return res.data
      })

  const {
    data: reservation = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ['get-user-allreservationEncour', page, search],
    queryFn: () => fetchReservations(page, search),
  })

  if (error) return <ErrorServer />

  return (
    <DefaultLayout mobileBottomNavigate={true}>
      <Helmet>
        <title>Vos réservations - Gowifmi</title>
      </Helmet>

      <div className={styles.voyage_container}>
        <Header setSearch={setSearch} setPage={setPage} />
        <ListReservation reservation={reservation} loading={isLoading} setPage={setPage} />
      </div>
    </DefaultLayout>
  )
}

export default ListInProgress
