import { useState } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const LocalLaw = () => {
  const [open, setOpen] = useState(false)

  const openFuction = () => {
    setOpen(true)
  }
  const reset = () => {
    setOpen(false)
  }

  return (
    <div className={styles.block}>
      <h2>Lois et réglementations</h2>
      {!open ? (
        <Section title='Législation locale' handleOpen={openFuction}></Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title='Votre législation locale' handleReset={reset}></ContenHead>
          <div className={styles.body}>
            <div className={styles.loi_block}>
              <p>
                Prenez un moment pour vérifier les réglementations locales qui s'appliquent à votre
                logement. Notre objectif est de nous assurer que vous disposez de toutes les
                informations nécessaires pour bien démarrer votre activité d'hôte.
              </p>
              <p>
                Dans la plupart des villes, le partage de logement est réglementé, et les lois ou
                arrêtés correspondants peuvent être consultés à différents endroits (tels que la loi
                d'urbanisme, le plan d'occupation des sols, le code de la construction, le règlement
                de zonage, etc.). en génerale, vous devez vous enregistrer et obtenir une
                autorisation avant de pouvoir mettre votre propriété en location ou accueillir des
                voyageurs. Vous pourriez également être responsable de la collecte et du versement
                de certaines taxes. Dans certaines localités, les locations de courte durée sont
                même totalement interdites.
              </p>
              <p>
                En tant que propriétaire ou gestionnaire de logement, il est de votre responsabilité
                de connaître et de respecter les règles en vigueur avant d'utiliser Gowifmi. Pour
                vous aider à démarrer, notre équipe est là pour vous soutenir 24/24 pour aider à
                démarrer et à repondre à vos questions.
              </p>
              <p>
                En acceptant nos Conditions générales et en publiant une annonce, vous vous engagez
                à respecter les lois et réglementations locales.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default LocalLaw
