import { useState } from 'react'
import styles from '../../../css/logins/content.module.css'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import LoadingPoint from '../../../components/LoadingPoint'
import AuthReseaux from '../reseaux/AuthReseaux'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ErrorSvg from '../../svgs/ErrorSvg'
import Checkbox from '../../ui/Checkbox'
import { CONNECTION_TYPE } from '../../../enums/user/connectionScreen'

const Inscription = ({ changeState, setGetUser, scrollToTop }) => {
  const [view, setView] = useState(false)
  const [accountStatus, setAccountStatus] = useState(false)
  const [loading, setLoading] = useState(false)
  const [echecConnexion, setEchecConnexion] = useState(false)

  const formSchema = yup.object().shape({
    first_name: yup.string().required('Veuillez entrer votre prénom'),
    email: yup
      .string()
      .email('Adresse e-mail non valide')
      .required('Veuillez entrer une adresse e-mail valide'),
    password: yup
      .string()
      .required('Veuillez entrer un mot de passe valide.')
      .min(8, 'Votre mot de passe doit contenir au moins 8 caractères'),
    password_2: yup
      .string()
      .required('Confirmer le mot de passe est requis')
      .oneOf([yup.ref('password')], 'Les mots de passe ne correspondent pas !'),
  })

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onChange', resolver: yupResolver(formSchema) })
  const onSubmit = data => {
    setLoading(true)
    setEchecConnexion(false)
    const first_name = data.first_name
    const newfirst_name = `${first_name[0].toUpperCase()}${first_name.slice(1)}`
    const datas = {
      first_name: newfirst_name,
      email: data.email,
      password: data.password,
      isCompany_account: accountStatus,
    }
    axios
      .post(`/auth/register-site`, datas)
      .then(async res => {
        setEchecConnexion(false)
        setLoading(false)
        reset()
        localStorage.setItem('jwt', res.data.jwt.token)
        axios.defaults.headers['authorization'] = 'Bearer ' + res.data.jwt.token
        scrollToTop()
        setGetUser(res.data.user)
        changeState(CONNECTION_TYPE.PROFIL_CATEGORY)
      })
      .catch(() => {
        setLoading(false)
        setEchecConnexion(true)
      })
  }

  return (
    <>
      <div className={styles.head}>
        <h4>S’inscrire sur Gowifmi</h4>
        <p>
          Créez un compte pour ajouter des logements à vos favoris et avoir accès à vos réservations
          depuis tous vos appareils.
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.connexion_form}>
          {echecConnexion && (
            <div className={styles.connexion_echec}>
              <div className={styles.icon}>
                <ErrorSvg width={24} height={24} />
              </div>
              <div className={styles.notif}>
                <h1>Cette adresse e-mail est déjà utilisée</h1>
                <p>Veuillez réessayer avec une autre</p>
              </div>
            </div>
          )}
          <div className={errors.first_name ? styles.erreur : ''}>
            <div className={styles.content_input}>
              <label htmlFor='first_name'>Prénom</label>
              <input
                type='first_name'
                id='first_name'
                autoComplete='off'
                {...register('first_name')}
              />
              {errors.first_name && (
                <span className={styles.erreur}>{errors.first_name.message}</span>
              )}
              <p>Assurez-vous qu'il correspond au prénom figurant sur votre pièce d'identité.</p>
            </div>
          </div>
          <div className={errors.email ? styles.erreur : ''}>
            <div className={styles.content_input}>
              <label htmlFor='email'>E-mail</label>
              <input
                type='email'
                id='email'
                placeholder='ex: john@gmail.com'
                autoComplete='off'
                {...register('email')}
              />
              {errors.email && <span className={styles.erreur}>{errors.email.message}</span>}
              <p>Nous vous enverrons les confirmations et les reçus de voyage par e-mail.</p>
            </div>
          </div>
          <div
            className={errors.password ? `${styles.password} ${styles.erreur}` : styles.password}
          >
            <label htmlFor='password'>Mot de Passe</label>
            <input
              type={view ? 'text' : 'password'}
              id='password'
              autoComplete='new-password'
              aria-autocomplete='list'
              {...register('password')}
            />
            {errors.password && <span className={styles.erreur}>{errors.password.message}</span>}
            {view ? (
              <ion-icon name='eye-off-outline' onClick={() => setView(false)}></ion-icon>
            ) : (
              <ion-icon name='eye-outline' onClick={() => setView(true)}></ion-icon>
            )}
          </div>
          <div className={errors.password_2 ? styles.erreur : ''}>
            <label htmlFor='password2'>Confirmer le mot de passe</label>
            <input type='password' id='password2' {...register('password_2')} />
            {errors.password_2 && (
              <span className={styles.erreur}>{errors.password_2.message}</span>
            )}
          </div>
        </div>
        <div className={styles.checkbox_container}>
          <div>
            <label htmlFor='account_type'>
              <p> Compte entreprise</p>
              <span>
                Cochez cette case si vous êtes en train de créer un compte au nom de votre
                entreprise.
              </span>
            </label>
          </div>
          <div>
            <Checkbox
              id='account_type'
              value={accountStatus}
              checked={accountStatus}
              onChange={() => setAccountStatus(!accountStatus)}
            />
          </div>
        </div>
        <div className={styles.contrat}>
          <p>
            En cliquant sur <strong>Créer le compte</strong>, j'accepte les{' '}
            <a href='/terms-and-condition' target='_blanck'>
              Conditions générales
            </a>
            , la{' '}
            <a href='/non-discrimination-policy' target='_blanck'>
              Politique de non-discrimination
            </a>{' '}
            et je reconnais avoir pris connaissance de la{' '}
            <a href='/privacy-policy' target='_blanck'>
              Politique de confidentialité
            </a>{' '}
            de Gowifmi.
          </p>
        </div>
        <div className={styles.btn_submit}>
          {loading ? (
            <button type='button' className={styles.disable} disabled>
              <LoadingPoint />
            </button>
          ) : (
            <button type='submit'>Créer le compte</button>
          )}
          <p className={styles.creer}>
            Vous avez déjà un compte ?{' '}
            <span
              onClick={() => {
                scrollToTop()
                changeState(CONNECTION_TYPE.CONNECTION)
              }}
            >
              Se connecter
            </span>
          </p>
        </div>
        <AuthReseaux changeState={changeState} setGetUser={setGetUser} />
      </form>
    </>
  )
}

export default Inscription
