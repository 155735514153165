import { useEffect, useState, useMemo } from 'react'
import styles from '../../css/ui/modal.module.css'
import ReactDOM from 'react-dom'

let timeout

const Modal = ({ isOpen, onClose, useStoragescrollbarWidth, children }) => {
  const [opened, setOpened] = useState(false)

  const scrollbarWidthStorege = useMemo(() => {
    return window.innerWidth - document.documentElement.clientWidth
  }, [])

  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout)
    }
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
    const handleOpen = () => {
      setOpened(true)
      document.body.style.overflow = 'hidden'
      document.body.style.insetInlineEnd = `${
        useStoragescrollbarWidth ? scrollbarWidthStorege : scrollbarWidth
      }px`
      document.body.style.paddingRight = `${
        useStoragescrollbarWidth ? scrollbarWidthStorege : scrollbarWidth
      }px`
    }

    const handleClose = () => {
      timeout = setTimeout(() => {
        setOpened(false)
        document.body.style.overflow = null
        document.body.style.insetInlineEnd = null
        document.body.style.paddingRight = null
      }, 500)
    }

    if (isOpen) {
      handleOpen()
    } else {
      handleClose()
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [isOpen])

  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  if (!opened) return null
  return ReactDOM.createPortal(
    <div className={isOpen ? `${styles.modal} ${styles.active}` : styles.modal}>
      <div className={styles.modal_overlay}></div>
      <div className={styles.container} onClick={handleOverlayClick}>
        <div className={styles.modal_content}>{children}</div>
      </div>
    </div>,
    document.getElementById('modal-root')
  )
}

export default Modal
