import { useEffect, useContext } from 'react'
import styles from '../../../../css/modehotes/reservation/detail.module.css'
import axios from 'axios'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { User } from '../../../../context/UserContext'
import LoadingPoint from '../../../../components/LoadingPoint'
import Navigation from '../../../../components/hote_mode/bookings/details/navigations/Navigation'
import Reglement from '../../../../components/hote_mode/bookings/details/reglementations/Reglement'
import ErrorServer from '../../../errors/ErrorServer'
import TitleHead from '../../../../components/hote_mode/bookings/details/navigations/TitleHead'
import HostModeLayout from '../../../../layouts/HostModeLayout'
import { Helmet } from 'react-helmet-async'

const Reglementation = () => {
  const user = useContext(User)
  const history = useHistory()
  const location = useLocation()
  const path = location.pathname.split('/')[4]

  useEffect(() => {
    window.scrollTo(0, 0)
    if (user === null) {
      history.push('/')
    }
  }, [history, path, user])
  const {
    data = {},
    isLoading,
    error,
  } = useQuery(`get-host_reservations-details-${path}}`, () =>
    axios.get(`/host_reservations/${path}`).then(res => {
      return res.data
    })
  )
  if (error) return <ErrorServer />

  return (
    <HostModeLayout footer={true}>
      <Helmet>
        <title>Réservation : {data?.reservation?.hotel?.title ?? ''} - Gowifmi</title>
      </Helmet>
      <div className={styles.main}>
        <TitleHead reservation={data.reservation} />
        <div className={styles.container}>
          <Navigation reservation={data.reservation} />
          {isLoading ? (
            <div>
              <LoadingPoint />
            </div>
          ) : (
            <div className={styles.block_rigth}>
              <Reglement reservation={data.reservation} />
            </div>
          )}
        </div>
      </div>
    </HostModeLayout>
  )
}

export default Reglementation
