import styles from '../../../../../css/booking/errorInfo.module.css'

const ErrorInfos = ({ messageError, phoneError }) => {
  return (
    <>
      <div>
        <div className={styles.block}>{/* <h4>err fgjfgruguygr</h4> */}</div>
      </div>
    </>
  )
}

export default ErrorInfos
