import { useState } from 'react'
import styles from '../../../css/logins/login.module.css'
import LoadingPoint from '../../LoadingPoint'
import axios from 'axios'
import { CONNECTION_TYPE } from '../../../enums/user/connectionScreen'

const FooterLocation = ({
  setActive,
  coords,
  fullAddress,
  appartement,
  city,
  state,
  postalCode,
  country,
  countryAbbreviation,
  getUser,
  handleClose,
  isValid,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = () => {
    setIsLoading(true)
    const formData = {
      location: {
        coordinates: [coords.lng, coords.lat],
      },
      address: {
        full_address: fullAddress,
        apartment_name: appartement,
        city: city,
        state: state,
        postal_code: postalCode,
        country: country,
        country_code: countryAbbreviation,
      },
    }
    axios
      .patch(`/user/${getUser?.id}`, formData)
      .then(() => {
        setIsLoading(false)
      })
      .catch(() => {
        alert('error')
      })
      .finally(() => {
        handleClose()
        setIsLoading(false)
      })
  }

  return (
    <div className={styles.button_action}>
      <button className={styles.retour} onClick={() => setActive(CONNECTION_TYPE.PROFIL_CATEGORY)}>
        Retour
      </button>
      {isLoading ? (
        <button className={`${styles.next} ${styles.disabled}`} disabled>
          <LoadingPoint />
        </button>
      ) : (
        <button
          className={!isValid ? `${styles.next} ${styles.disabled}` : styles.next}
          disabled={!isValid}
          onClick={handleSubmit}
        >
          Enregistrer
        </button>
      )}
    </div>
  )
}

export default FooterLocation
