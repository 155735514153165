import styles from '../../../css/details/equipement.module.css'
import CheckAmenitieSvg from './components/CheckAmenitieSvg'

const Service = ({ hotel }) => {
  const { amenities } = hotel

  return (
    <div className={styles.container_equip}>
      <h4>Services</h4>
      {hotel.traveler_safety?.pets_on_property && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Animaux acceptés : Les animaux d'assistance sont toujours autorisés</p>
        </div>
      )}
      {amenities?.includes('17') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Petit déjeuner</p>
        </div>
      )}
      {amenities?.includes('52') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>
            Dépôt de bagages autorisé : Pour le confort des voyageurs en cas d'arrivée anticipée ou
            de départ tardif
          </p>
        </div>
      )}
      {hotel.regulations.smoking_allowed && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Logement fumeur</p>
        </div>
      )}
      {amenities?.includes('53') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Séjours longue durée autorisés : Séjours de 28 jours ou plus autorisés</p>
        </div>
      )}
      {!hotel.regulations?.check_in_1 && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Arrivée autonome</p>
        </div>
      )}
      {amenities?.includes('54') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Boîte à clé sécurisée</p>
        </div>
      )}
      {amenities?.includes('118') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Ménage avant le départ</p>
        </div>
      )}
    </div>
  )
}

export default Service
