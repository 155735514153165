import styles from '../../../../../css/search_room_result/more_actions/description_profil.module.css'
import moment from 'moment'
import { Photo } from './profile_descr/Photo'
import About from './profile_descr/About'
import Category from '../../../../accounts/profile/profile_descr/Category'

const ProfilDescription = ({ getUser }) => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.profil_block_img}>
          <Photo getUser={getUser} />
          <div className={styles.about_block}>
            <div className={styles.about_name}>
              <h2>{getUser.first_name}</h2>
              {getUser.isIdentityValid && (
                <span>
                  <svg
                    viewBox='0 0 16 16'
                    xmlns='http://www.w3.org/2000/svg'
                    aria-hidden='true'
                    role='presentation'
                    focusable='false'
                    style={{
                      height: '14px',
                      width: '14px',
                      fill: 'currentColor',
                    }}
                  >
                    <path d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.159 4.869L6.67 9.355 4.42 7.105 3.289 8.236 6.67 11.62 12.291 6z'></path>
                  </svg>
                </span>
              )}
            </div>
            <h3>Membre depuis {moment(getUser.createdAt).format('LL').slice(2)}</h3>
            <Category getUser={getUser} />
            <div className={styles.about_pc}>
              <About getUser={getUser} lengthMax={120} />
            </div>
          </div>
        </div>
        <div className={styles.about_mobile}>
          <About getUser={getUser} lengthMax={80} />
        </div>
      </div>
    </>
  )
}

export default ProfilDescription
