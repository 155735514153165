import { useState } from 'react'
import styles from '../../../../../../css/modehotes/reservation/detail.module.css'
import moment from 'moment'
import momentTimeZone from 'moment-timezone'
import axios from 'axios'
import Swal from 'sweetalert2'
import LoadingPoint from '../../../../../LoadingPoint'
import Toast from '../../../../../infos/toast'
import { UPDATE_BOOKING } from '../../../../../../enums/booking/bookingUpdate.enums'

const tostMessage = (icon, title) => {
  Toast.fire({
    icon: icon,
    title: title,
  })
}

const Reservation = ({ reservation, path, refetch }) => {
  const [loading, setLoading] = useState(false)
  const modifications = reservation?.modifications[reservation?.modifications.length - 1]

  const userTimezone = momentTimeZone.tz.guess()

  const handleAcceptDemande = () => {
    setLoading(true)
    axios
      .patch(`/host_reservations/update/${path}/${modifications?._id}`)
      .then(async () => {
        await refetch()
        tostMessage('success', 'La demande de modification a été confirmée avec succès.')
      })
      .catch(err => {
        if (err.response?.data?.message === 'payement_reject') {
          tostMessage(
            'error',
            `Une erreur s'est produite, Motif : le moyen de paiement du voyageur semble ne pas valide.`
          )
        } else if (err.response?.data?.message === 'demande_invalid') {
          tostMessage(
            'error',
            `Vous ne pouvez plus accepter cette demande, car le voyageur a déjà annulé la demande de modification.`
          )
        } else {
          tostMessage('error', `Une erreur s'est produite, veuillez réssayer plus tard.`)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleReject = () => {
    Swal.fire({
      title: 'Refuser cette demande de modification ?',
      text: 'Une fois la demande refusée, vous ne pourrez plus la récupérer.',
      showCancelButton: true,
      confirmButtonText: 'Refuser la demande',
      cancelButtonText: 'Annuler',
      reverseButtons: true,
      customClass: {
        actions: 'actions_class',
        confirmButton: 'confirm_button_class',
        cancelButton: 'cancel_button_class',
      },
    }).then(result => {
      if (result.isConfirmed) {
        setLoading(true)
        axios
          .patch(`/host_reservations/reject_update/${path}/${modifications?._id}`)
          .then(async () => {
            await refetch()
            tostMessage('success', `La demande de modification est annulée avec succès.`)
          })
          .catch(err => {
            if (err.response?.data?.message === 'demande_invalid') {
              tostMessage(
                'error',
                `Le voyageur a déjà annulé cette demande de modification veuillez actualiser la page.`
              )
            } else {
              tostMessage('error', `Un problème est survenu au niveau de serveur.`)
            }
          })
          .finally(() => {
            setLoading(false)
          })
      }
    })
  }

  return (
    <>
      <div className={styles.content}>
        <h3>La date de la dernière modification</h3>
        <p>{moment.utc(reservation?.updatedAt).tz(userTimezone).calendar()}</p>
      </div>
      <div className={styles.content}>
        <h3>Modification de la réservation</h3>
        {modifications?.modification_status === UPDATE_BOOKING.REQUEST ? (
          <div>
            {loading ? (
              <LoadingPoint />
            ) : (
              <div>
                <p>Le voyageur vous a envoyé une demande de modification</p>
                <div>
                  <h4>Les dates</h4>
                  <p>Date d'arrivée : {moment(modifications?.checkin).format('ll')}</p>
                  <p>Date de départ : {moment(modifications?.checkout).format('ll')}</p>
                  <p>
                    Séjour de : {modifications?.total_nights}{' '}
                    {modifications?.total_nights > 1 ? 'nuits' : 'nuit'}
                  </p>
                </div>
                <div className={styles.btn_block}>
                  <button onClick={handleAcceptDemande}>Appliquer les modifications</button>
                  <button className={styles.delete} onClick={handleReject}>
                    Refuser la demande de modification
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            <p>Pas de demande de modification pour le moment</p>
          </div>
        )}
      </div>
    </>
  )
}

export default Reservation
