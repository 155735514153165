import { useRef } from 'react'
import styles from '../../../../css/traveler/inbox/inbox.module.css'
import Login from '../../../../components/login'

const Conneted = () => {
  const connexion = useRef()
  const handleConnection = params => {
    connexion.current.OpenModal(params)
  }

  return (
    <>
      <div className={styles.no_connecte}>
        <h1>Boîte de réception</h1>
        <div className={styles.connexion}>
          <h2>Connectez-vous pour consulter les messages</h2>
          <p>Une fois connecté, les messages des hôtes apparaîtront ici.</p>
          <button onClick={handleConnection}>Connectez-vous</button>
        </div>
      </div>

      <Login ref={connexion} />
    </>
  )
}

export default Conneted
