import styles from '../../../css/navigations/bottomBar.module.css'
import { Link } from 'react-router-dom'

function Search({ path }) {
  return (
    <Link
      to='/'
      className={
        path === 'homes' || path === ''
          ? `${styles.navigation} ${styles.active}`
          : styles.navigation
      }
    >
      <svg
        className={styles.search}
        viewBox='0 0 32 32'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'
        role='presentation'
        focusable='false'
      >
        <g fill='none'>
          <path d='m13 24c6.0751322 0 11-4.9248678 11-11 0-6.07513225-4.9248678-11-11-11-6.07513225 0-11 4.92486775-11 11 0 6.0751322 4.92486775 11 11 11zm8-3 9 9'></path>
        </g>
      </svg>
      <div>
        <p>Rechercher</p>
      </div>
    </Link>
  )
}

export default Search
