import { useEffect, useState, useContext } from 'react'
import styles from '../../css/account/accountMobile.module.css'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'
import { User, Logout } from '../../context/UserContext'
import LoadingPoint from '../../components/LoadingPoint'
import DefaultLayout from '../../layouts/DefaultLayout'
import GoProfile from '../../components/accounts/account_mobile/GoProfile'
import Notification from '../../components/accounts/account_mobile/Notification'
import AccountParams from '../../components/accounts/account_mobile/AccountParams'
import PersonalInfo from '../../components/accounts/account_mobile/PersonalInfo'
import Travel from '../../components/accounts/account_mobile/Travel'
import HoteMode from '../../components/accounts/account_mobile/HoteMode'
import AddHome from '../../components/accounts/account_mobile/AddHome'
import Help from '../../components/accounts/account_mobile/Help'

const AccountMobile = () => {
  const [loading, setLoading] = useState(true)

  const user = useContext(User)
  const logout = useContext(Logout)
  const history = useHistory()
  useEffect(() => {
    window.scrollTo(0, 0)
    if (user === null) {
      history.push('/')
    } else {
      setLoading(false)
    }
  }, [history, user])

  return (
    <DefaultLayout footer={false} mobileBottomNavigate={true}>
      <Helmet>
        <title>Paramètre du compte - Gowifmi</title>
      </Helmet>
      {loading ? (
        <div className={styles.container_load}>
          <LoadingPoint />
        </div>
      ) : (
        <div className={styles.account_container}>
          <GoProfile user={user} />
          <div className={styles.account_block}>
            {!user?.isEmailVerified && <Notification />}
            <Travel />
            <AccountParams />
            <PersonalInfo />
          </div>
          <div className={styles.account_block}>
            <h2>Accueil de voyageurs</h2>
            <AddHome />
            {user?.isHost || user?.profile_categories.length > 0 ? <HoteMode /> : null}
          </div>
          <div className={styles.account_block}>
            <h2>Aide</h2>
            <Help />
          </div>
          <div className={styles.footer}>
            <div>
              <button onClick={logout}>Se déconnecter</button>
            </div>
          </div>
        </div>
      )}
    </DefaultLayout>
  )
}

export default AccountMobile
