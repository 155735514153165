import { useState, useEffect } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import ReviewMpas from './ReviewMpas'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const Maps = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [coords, setCoords] = useState(null)
  const { loading, updateData } = useUpdateHotel(setOpen)

  const handleSubmit = () => {
    const datas = {
      location: {
        coordinates: [coords.lng, coords.lat],
      },
    }
    updateData(datas)
  }
  useEffect(() => {
    setCoords({ lat: annonce.location?.coordinates[1], lng: annonce.location?.coordinates[0] })
  }, [annonce])
  const openFuction = () => {
    setCoords({ lat: annonce.location?.coordinates[1], lng: annonce.location?.coordinates[0] })
    setOpen(true)
  }
  const reset = () => {
    setCoords(null)
    setOpen(false)
  }
  return (
    <div className={styles.block}>
      {!open ? (
        <Section title="Voir l'emplacement" handleOpen={openFuction}>
          <p>Emplacement général</p>
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title="Voir l'emplacement" handleReset={reset}>
            <p>
              Le cercle apparaîtra sur votre annonce. Seuls les voyageurs disposant d'une
              réservation confirmée pourront voir le repère. Si nécessaire, vous pouvez faire
              glisser le repère pour ajuster son emplacement.
            </p>
          </ContenHead>
          <ReviewMpas coords={coords} setCoords={setCoords} />
          <BottomForm handleSubmit={handleSubmit} loading={loading} handleReset={reset} />
        </div>
      )}
    </div>
  )
}

export default Maps
