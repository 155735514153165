import { Link } from 'react-router-dom'
import styles from '../../../../../../css/modehotes/annonce/details/securiteVoyageur.module.css'
import SvgIconRightDirector from '../../../../../svgs/SvgIconRightDirector'

const SecurityTraveler = ({ annonce }) => {
  const Query = window.location.search

  return (
    <div className={styles.block}>
      <div>
        <div className={styles.head}>
          <h3>Sécurité des voyageurs</h3>
          <Link to={`/hosting/listings/details/${annonce._id}/safety-info${Query}`}>
            <span>Modifier</span>
            <SvgIconRightDirector />
          </Link>
        </div>
        <div className={styles.main}>
          {annonce.traveler_safety?.pool_jacuzzi_unfenced_unlocked && (
            <p>Piscine/jacuzzi sans clôture ni verrou</p>
          )}
          {annonce.traveler_safety?.lake_river_other_water_body_nearby && (
            <p>Lac, rivière ou autre étendue d'eau à proximité</p>
          )}
          {annonce.traveler_safety?.dwelling_includes_stairs_or_staircases && (
            <p>Le logement comprend des marches ou escaliers</p>
          )}
          {annonce.traveler_safety?.playground_climbing_structure && (
            <p>Aire de jeux ou structure d'escalade</p>
          )}
          {annonce.traveler_safety?.dangerous_animals && <p>Animal potentiellement dangereux</p>}
          {annonce.traveler_safety?.cameras && (
            <p>Caméras de surveillance ou appareils d'enregistrement audio</p>
          )}
          {annonce.traveler_safety?.weapon && <p>Armes sur la propriété</p>}
          {annonce.traveler_safety?.possible_noise_nuisances && <p>Nuisances sonores possibles</p>}
          {annonce.traveler_safety?.pets_on_property && (
            <p>Présence d'animaux de compagnie sur place</p>
          )}
          {annonce.traveler_safety?.no_on_site_parking && (
            <p>Pas d'espace de stationnement sur place</p>
          )}
          {annonce.traveler_safety?.some_spaces_shared && <p>Certains espaces sont partagés</p>}
          {annonce.traveler_safety?.applicable_equipment_restrictions && (
            <p>Restrictions applicables aux équipements</p>
          )}
          {annonce.traveler_safety?.height_unbarriered_unprotected && (
            <p>Zones en hauteur sans barrières ni protections</p>
          )}
        </div>
      </div>
    </div>
  )
}

export default SecurityTraveler
