import { useState } from 'react'
import styles from '../../../../css/traveler/travel/cancellation_policy.module.css'
import PolicyModal from './PolicyModal'

const CancellationPolicy = ({ cancellation_policy }) => {
  const [modal, setModal] = useState(false)

  return (
    <>
      <div className={styles.asavoir_block}>
        <h4>Conditions d'annulation</h4>
        <div>
          <p>
            Avant d'annuler cette réservation, rassurez-vous de lire les conditions d'annulation de
            cette réservation, qui s'appliquent même si vous annulez pour cause de maladie.
          </p>
          <div className={styles.savoir_btn}>
            <button type='button' onClick={() => setModal(true)}>
              En savoir plus
            </button>
          </div>
        </div>
      </div>

      {/*----modal---------*/}
      <PolicyModal cancellation_policy={cancellation_policy} modal={modal} setModal={setModal} />
    </>
  )
}

export default CancellationPolicy
