import styles from '../../../../css/modehotes/navbar/dropdown_link.module.css'
import { Link } from 'react-router-dom'
import SvgIconRightDirector from '../../../svgs/SvgIconRightDirector'

const Help = () => {
  return (
    <Link to='/help/feedback' className={styles.block_link}>
      <div className={styles.left_block}>
        <svg
          className={styles.gerer}
          viewBox='0 0 32 32'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'
          role='presentation'
          focusable='false'
        >
          <g>
            <path d='m16 1a15 15 0 1 0 15 15 15.017 15.017 0 0 0 -15-15zm0 28a13 13 0 1 1 13-13 13.01474 13.01474 0 0 1 -13 13zm1.5-5.5a1.5 1.5 0 1 1 -1.5-1.5 1.5 1.5 0 0 1 1.5 1.5zm4.0791-11.5c0 2.52832-1.69336 4.57764-4.57226 5.58984l-.00684 2.41309-2-.00586.01025-3.90723.73487-.20068c1.15283-.31445 3.834-1.32324 3.834-3.88916a3.189 3.189 0 0 0 -3.44775-3.16846 3.6753 3.6753 0 0 0 -3.53516 2.72071l-1.92285-.55079a5.65909 5.65909 0 0 1 5.458-4.16992 5.19687 5.19687 0 0 1 5.44774 5.16846z'></path>
          </g>
        </svg>
        <h3>Obtenir de l'aide</h3>
      </div>
      <div className={styles.rigth_block}>
        <SvgIconRightDirector />
      </div>
    </Link>
  )
}

export default Help
