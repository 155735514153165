import { useState, useRef, useContext } from 'react'
import styles from '../../css/helps/feedback.module.css'
import { useHistory } from 'react-router-dom'
import { User } from '../../context/UserContext'
import Login from '../../components/login'
import Swal from 'sweetalert2'
import axios from 'axios'
import LoadingPoint from '../../components/LoadingPoint'
import TravelOptions from '../../components/help/TravelOptions'
import HebergementOptions from '../../components/help/HebergementOptions'
import DefaultLayout from '../../layouts/DefaultLayout'
import { Helmet } from 'react-helmet-async'

function Feedback() {
  const [info_1, setInfo_1] = useState('')
  const [info_2, setInfo_2] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoaging] = useState(false)

  const user = useContext(User)

  const history = useHistory()

  const handleSubmit = () => {
    setLoaging(true)
    const data = {
      type: info_1,
      fonctionnalite: info_2,
      message: message,
    }
    axios
      .post(`/contact`, data)
      .then(() => {
        history.push('/help/confirmation')
      })
      .catch(() => {
        Swal('error', 'Un probleme est sur venu au nuveau de server', 'error')
        setLoaging(false)
        reset()
      })
  }

  const connexion = useRef()
  const handleConnection = params => {
    connexion.current.OpenModal(params)
  }

  return (
    <DefaultLayout footer={true} mobileBottomNavigate={true}>
      <Helmet>
        <title>Remarques Centre d'aide | Gowifmi</title>
      </Helmet>
      <div className={styles.container}>
        <div className={styles.block_1}>
          <h1>Faites-nous part de vos remarques</h1>
          <p>
            Merci de nous avoir fait part de vos idées, problèmes ou remerciements. Nous les
            transmettons aux équipes qui travaillent à l'amélioration de Gowifmi pour tout le monde.
          </p>
          {!user ? (
            <div>
              <p>
                Pour envoyer des remarques ou nous faire part de vos idées, vous devez vous
                connecter à votre compte Gowifmi.
              </p>
              <button className={styles.connexion} onClick={handleConnection}>
                Connexion ou inscription
              </button>
            </div>
          ) : (
            <form>
              <div>
                <label htmlFor='remarque'>Sur quoi portent vos remarques ?</label>
                <select id='remarque' onChange={e => setInfo_1(e.target.value)}>
                  <option disabled selected defaultValue='Veulliez séléctionner'>
                    Veuillez sélectionner
                  </option>
                  <option value='accomodation'>Hébergement</option>
                  <option value='travel'>Voyage</option>
                </select>
              </div>
              <div>
                <label htmlFor='sujet'>Quel sujet ou fonctionnalité ?</label>
                <select id='sujet' onChange={e => setInfo_2(e.target.value)}>
                  <option disabled selected defaultValue='Veulliez séléctionner'>
                    Choisissez une réponse
                  </option>
                  {info_1 === 'accomodation' ? <HebergementOptions /> : <TravelOptions />}
                </select>
              </div>
              <div>
                <label htmlFor='infos'>Ajouter des informations</label>
                <textarea id='infos' onChange={e => setMessage(e.target.value)}></textarea>
              </div>
              <div>
                {loading ? (
                  <button type='button' className={styles.btn_loading} disabled>
                    <LoadingPoint />
                  </button>
                ) : (
                  <button
                    type='button'
                    className={message ? styles.next : `${styles.next} ${styles.disable}`}
                    disabled={!message}
                    onClick={handleSubmit}
                  >
                    Envoyer
                  </button>
                )}
              </div>
            </form>
          )}
        </div>
        {user && (
          <div>
            <div className={styles.block_2}>
              <h2>Nous contacter par e-mail ?</h2>
              <p>Commençons par quelques questions afin de mieux vous orienter.</p>
              <a href='mailto:contact@gowifmi.com'>Contactez-nous</a>
            </div>
          </div>
        )}
      </div>
      <Login ref={connexion} />
    </DefaultLayout>
  )
}
export default Feedback
