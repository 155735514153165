import { useContext } from 'react'
import { User } from '../context/UserContext'
import ConfirmEmail from '../components/infos/ConfirmEmail.info'
import CookieConsentModal from '../components/cookies/CookieConsentModal'

function AppLayout({ children }) {
  const user = useContext(User)

  return (
    <>
      <CookieConsentModal />
      {user && !user.isEmailVerified ? <ConfirmEmail /> : null}
      {children}
    </>
  )
}
export default AppLayout
