import styles from '../../css/details/avis.module.css'
import useHotelRating from '../../hooks/useHotelRating'
import StarSvg from '../svgs/StarSvg'

const Review = ({ hotel }) => {
  const {
    commentLength,
    totalComment,
    totalCleanlinessPourcentage,
    cleanlinessOn5,
    totalCommunicationPourcentage,
    communicationOn5,
    totalAmenitiePourcentage,
    amenitieOn5,
    totalArrivalPourcentage,
    arrivalOn5,
    totalLocationPourcentage,
    locationOn5,
    totalQualityPricePourcentage,
    qualityPriceOn5,
  } = useHotelRating(hotel)

  return (
    <>
      {commentLength > 0 && (
        <div className={styles.section}>
          <h3 className={styles.total_note}>
            <StarSvg width={20} height={20} />
            {totalComment} ({commentLength} commentaire
            {commentLength > 1 ? 's' : ''})
          </h3>
          <div className={styles.avis_section}>
            <div>
              <div className={styles.bar_avis}>
                <p>Propreté</p>
                <div className={styles.bar_flex}>
                  <div className={styles.pourcentage}>
                    <span style={{ width: totalCleanlinessPourcentage + '%' }}></span>
                  </div>
                  <span className={styles.chiffre}>{cleanlinessOn5}</span>
                </div>
              </div>
              <div className={styles.bar_avis}>
                <p>Communication</p>
                <div className={styles.bar_flex}>
                  <div className={styles.pourcentage}>
                    <span style={{ width: totalCommunicationPourcentage + '%' }}></span>
                  </div>
                  <span className={styles.chiffre}>{communicationOn5}</span>
                </div>
              </div>
              <div className={styles.bar_avis}>
                <p>Équipements</p>
                <div className={styles.bar_flex}>
                  <div className={styles.pourcentage}>
                    <span style={{ width: totalAmenitiePourcentage + '%' }}></span>
                  </div>
                  <span className={styles.chiffre}>{amenitieOn5}</span>
                </div>
              </div>
            </div>
            <div>
              <div className={styles.bar_avis}>
                <p>Arrivée</p>
                <div className={styles.bar_flex}>
                  <div className={styles.pourcentage}>
                    <span style={{ width: totalArrivalPourcentage + '%' }}></span>
                  </div>
                  <span className={styles.chiffre}>{arrivalOn5}</span>
                </div>
              </div>
              <div className={styles.bar_avis}>
                <p>Emplacement</p>
                <div className={styles.bar_flex}>
                  <div className={styles.pourcentage}>
                    <span style={{ width: totalLocationPourcentage + '%' }}></span>
                  </div>
                  <span className={styles.chiffre}>{locationOn5}</span>
                </div>
              </div>
              <div className={styles.bar_avis}>
                <p>Qualité-prix</p>
                <div className={styles.bar_flex}>
                  <div className={styles.pourcentage}>
                    <span style={{ width: totalQualityPricePourcentage + '%' }}></span>
                  </div>
                  <span className={styles.chiffre}>{qualityPriceOn5}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Review
