import { useEffect, useContext, useRef } from 'react'
import styles from '../../../css/traveler/favoris/favori.module.css'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import Login from '../../../components/login'
import { User } from '../../../context/UserContext'
import CardHotel from '../../../components/traveler/favoris/CardHotel'
import axios from 'axios'
import LoadingPoint from '../../../components/LoadingPoint'
import DefaultLayout from '../../../layouts/DefaultLayout'
import { useQuery } from 'react-query'
import { Helmet } from 'react-helmet-async'

const AllFavorite = () => {
  const user = useContext(User)
  const location = useLocation()

  const { isLoading, data } = useQuery(`get-favorites`, () => {
    if (user) {
      return axios.get(`/favoris`).then(res => {
        return res.data
      })
    }
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  const connexion = useRef()
  const handleConnection = params => {
    connexion.current.OpenModal(params)
  }

  return (
    <DefaultLayout footer={true} mobileBottomNavigate={true}>
      <Helmet>
        <title>Votre liste de favoris - Gowifmi</title>
      </Helmet>
      {user ? (
        <div className={styles.favori_container}>
          <h1>Favoris</h1>
          {isLoading ? (
            <div className={styles.loading_favori}>
              <LoadingPoint />
            </div>
          ) : (
            <>
              {data?.length > 0 ? (
                <CardHotel favoris={data} />
              ) : (
                <div className={styles.no_favori}>
                  <div className={styles.no_fav_1}>
                    <div className={styles.card}></div>
                    <div>
                      <p></p>
                      <p></p>
                      <p></p>
                    </div>
                  </div>
                  <div className={styles.no_fav_2}>
                    <h2>Aucun favori</h2>
                    <p>
                      Lorsque vous repérez un logement qui vous plaît, utilisez le cœur pour le
                      sauvegarder dans vos favoris et le retrouver plus tard.
                    </p>
                    <Link to='/'>Commencez votre recherche</Link>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <div className={styles.favori_container}>
          <h1>Favoris</h1>
          <div className={styles.connexion}>
            <h2>Connectez-vous pour consulter vos favoris</h2>
            <p>
              Vous pouvez créer, consulter ou modifier les favoris en vous connectant à votre
              compte.
            </p>
            <button onClick={handleConnection}>Connectez-vous</button>
          </div>
        </div>
      )}

      {/*----------Bottom--Navigation---------*/}
      <Login ref={connexion} />
    </DefaultLayout>
  )
}

export default AllFavorite
