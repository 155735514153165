import styles from '../../../../../css/modehotes/annonce/details/equipement.module.css'
import useUpdateHotel from '../../../../../hooks/useUpdateHotel'
import Checkbox from '../../../../ui/Checkbox'

const Exterior = ({ equipements, setEquipements }) => {
  const { updateData } = useUpdateHotel()

  const array = [
    { name: 'Bord de mer', value: '5' },
    { name: 'Arrière-cour', value: '45' },
    { name: 'Barbecue', value: '48' },
    { name: 'Vélos', value: '109' },
    { name: 'Brasero', value: '63' },
    { name: 'Hamac', value: '108' },
    { name: 'Kayak', value: '107' },
    { name: 'Espace repas en plein air', value: '47' },
    { name: "Mobilier d'extérieur", value: '46' },
    { name: 'Cuisine extérieure', value: '106' },
    { name: 'Patio ou balcon', value: '44' },
  ]
  const onchangeFilters = e => {
    if (e.target.checked === true) {
      setEquipements([...equipements, e.target.value])
      const datas = {
        amenities: [...equipements, e.target.value],
      }
      updateData(datas)
    } else {
      const newArr = [...equipements].filter(item => item !== e.target.value)
      setEquipements(newArr)
      const datas = {
        amenities: newArr,
      }
      updateData(datas)
    }
  }
  return (
    <div className={styles.container_equip}>
      <h4>Extérieur</h4>
      {array.map(item => (
        <div key={item.name} className={styles.checkbox_container}>
          <label htmlFor={item.name}>{item.name}</label>
          <div>
            <Checkbox
              id={item.name}
              value={item.value}
              checked={equipements.includes(item.value)}
              onChange={onchangeFilters}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default Exterior
