import styles from '../../css/search_profiles/list_profile.module.css'
import moment from 'moment'
import { Link } from 'react-router-dom'
import LoadingPoint from '../LoadingPoint'
import Pagination from '../../components/ui/Pagination'
import Category from '../accounts/profile/profile_descr/Category'

const ListProfil = ({ isLoading, error, data }) => {
  return (
    <div className={styles.container}>
      {isLoading ? (
        <LoadingPoint />
      ) : (
        <>
          <div className={styles.flex_container}>
            {data?.users?.map(item => (
              <div key={item.id} className={styles.sponsor_card}>
                <a
                  href={`/profile/${item?.id}`}
                  target='_blanck'
                  className={styles.wrapper_img_profile}
                >
                  <div>
                    <div className={styles.image_block}>
                      {item?.picture ? (
                        <img src={item?.picture} alt='' />
                      ) : (
                        <svg
                          viewBox='0 0 32 32'
                          xmlns='http://www.w3.org/2000/svg'
                          aria-hidden='true'
                          role='presentation'
                          focusable='false'
                        >
                          <path d='m16 .7c-8.437 0-15.3 6.863-15.3 15.3s6.863 15.3 15.3 15.3 15.3-6.863 15.3-15.3-6.863-15.3-15.3-15.3zm0 28c-4.021 0-7.605-1.884-9.933-4.81a12.425 12.425 0 0 1 6.451-4.4 6.507 6.507 0 0 1 -3.018-5.49c0-3.584 2.916-6.5 6.5-6.5s6.5 2.916 6.5 6.5a6.513 6.513 0 0 1 -3.019 5.491 12.42 12.42 0 0 1 6.452 4.4c-2.328 2.925-5.912 4.809-9.933 4.809z'></path>
                        </svg>
                      )}
                    </div>
                  </div>
                  <div className={styles.wrapper_name}>
                    <div className={styles.about_name}>
                      <h2>{item?.first_name}</h2>
                      {item?.isIdentityValid && (
                        <span>
                          <svg
                            viewBox='0 0 16 16'
                            xmlns='http://www.w3.org/2000/svg'
                            aria-hidden='true'
                            role='presentation'
                            focusable='false'
                            style={{
                              height: '10px',
                              width: '10px',
                              fill: 'currentColor',
                            }}
                          >
                            <path d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.159 4.869L6.67 9.355 4.42 7.105 3.289 8.236 6.67 11.62 12.291 6z'></path>
                          </svg>
                        </span>
                      )}
                    </div>
                    <p>Membre depuis {moment(item?.createdAt).format('LL').slice(2)}</p>
                  </div>
                </a>
                <Category getUser={item} />
                <div className={styles.body_block}>
                  {item.about_me && <p className={styles.about}>{item.about_me}</p>}
                </div>
                <div className={styles.footer_block}>
                  <p>{item.address?.city}</p>
                  <div>
                    <Link
                      to={`/inbox/detail/${item?.id}?filter=all`}
                      className={styles.send_message}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 32 32'
                        aria-hidden='true'
                        role='presentation'
                        focusable='false'
                        style={{
                          display: 'block',
                          height: '20px',
                          width: '20px',
                          fill: 'currentcolor',
                        }}
                      >
                        <path d='M26 1a5 5 0 0 1 5 4.78v10.9a5 5 0 0 1-4.78 5H26a5 5 0 0 1-4.78 5h-4l-3.72 4.36-3.72-4.36H6a5 5 0 0 1-4.98-4.56L1 21.9 1 21.68V11a5 5 0 0 1 4.78-5H6a5 5 0 0 1 4.78-5H26zm-5 7H6a3 3 0 0 0-3 2.82v10.86a3 3 0 0 0 2.82 3h4.88l2.8 3.28 2.8-3.28H21a3 3 0 0 0 3-2.82V11a3 3 0 0 0-3-3zm-1 10v2H6v-2h14zm6-15H11a3 3 0 0 0-3 2.82V6h13a5 5 0 0 1 5 4.78v8.9a3 3 0 0 0 3-2.82V6a3 3 0 0 0-2.82-3H26zM15 13v2H6v-2h9z'></path>
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Pagination data={data} />
          {data?.totalRecords < 1 && <p>Aucun resultat trouvé pour le moment</p>}
        </>
      )}
      {error && <p>Une erreur s'est produite veuillez réessayer</p>}
    </div>
  )
}

export default ListProfil
