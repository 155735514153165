/* eslint-disable no-unused-vars */
import { useEffect, useState, useContext } from 'react'
import styles from '../../css/search_room_result/hotel.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { search_by_map } from '../../redux/actions'
import Card from './Card'
import LoadingCard from './components/loadings/LoadingCard'
import Maps from './Maps'
import { useHistory } from 'react-router-dom'
import Pagination from './components/Pagination'
import NotFound from './components/NotFound'
import getSearchHotet from '../../api/user_search_hotels/getSearchHotel'
import { User } from '../../context/UserContext'

function ListHost() {
  const [hotel, setHotel] = useState([])
  const [loading, setLoading] = useState(true)
  const [dateIsinvalid, setDateIsinvalid] = useState(false)
  const [coords, setCoords] = useState(null)
  const [childhover, setChildchildhover] = useState(null)
  const query = window.location.search
  const Params = new URLSearchParams(query)
  const address = Params.get('place')
  const checkin = Params.get('checkin')
  const checkout = Params.get('checkout')
  const search_lat = Params.get('lat')
  const search_lng = Params.get('lng')
  const date_start = new Date(checkin).setHours(0, 0, 0, 0)
  const date_end = new Date(checkout).setHours(0, 0, 0, 0)
  const today = new Date()
  const Datenow = today.setHours(0, 0, 0, 0)
  const dispatch = useDispatch()

  const history = useHistory()

  const user = useContext(User)
  const mapOnchange = useSelector(state => state.search_by_map)

  const sizeViewToFixMapModal = window.matchMedia('(max-width:1150px)')
  const { getCoords } = getSearchHotet(setLoading, setHotel, setCoords, dispatch, user)

  const [scrollToTop, setScrollToTop] = useState(true)

  useEffect(() => {
    setScrollToTop(mapOnchange.scroll_to_top)
  }, [mapOnchange.scroll_to_top])

  useEffect(() => {
    getCoords(address, query, search_lat, search_lng)
  }, [])

  useEffect(() => {
    const unlisten = history.listen(location => {
      setDateIsinvalid()
      const query = location.search
      const Params = new URLSearchParams(query)
      const address = Params.get('place')
      const search_lat = Params.get('lat')
      const search_lng = Params.get('lng')

      if (sizeViewToFixMapModal.matches && scrollToTop) {
        getCoords(address, query, search_lat, search_lng)
      } else {
        window.scrollTo(0, 0)
        getCoords(address, query, search_lat, search_lng)
      }
    })

    return () => unlisten()
  }, [history, scrollToTop, sizeViewToFixMapModal.matches])

  useEffect(() => {
    if (date_start >= Datenow && date_end > date_start) {
      setDateIsinvalid(false)
    } else {
      setDateIsinvalid(true)
    }
  }, [Datenow, dateIsinvalid, date_end, date_start])

  return (
    <>
      <div className={styles.hotel_bloc}>
        <div className={styles.caerd_container}>
          {loading ? (
            <LoadingCard />
          ) : (
            <div className={styles.hotel}>
              <NotFound dateIsinvalid={dateIsinvalid} hotel={hotel} />
              {!dateIsinvalid && hotel?.total_hotels > 0 ? (
                <div className={styles.liste_hotel}>
                  {hotel?.results?.map(item => (
                    <Card item={item} key={item._id} setChildchildhover={setChildchildhover} />
                  ))}
                </div>
              ) : null}
              {!dateIsinvalid && <Pagination hotel={hotel} />}
            </div>
          )}
        </div>
        <Maps
          loading={loading}
          coords={coords}
          hotel={!dateIsinvalid ? hotel?.results : []}
          childhover={childhover}
        />
      </div>
    </>
  )
}
export default ListHost
