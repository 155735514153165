import styles from '../../../css/details/photoDetail.module.css'
import AllPhotoSroll from './AllPhotoSroll'
import PhotoMobile from './PhotoMobile'
import useModalByUrl from '../../../hooks/useModalByUrl'

const HomeSvg = () => {
  return (
    <svg
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='presentation'
      focusable='false'
      style={{
        display: 'block',
        height: '34px',
        width: '34px',
        fill: '#fff',
      }}
    >
      <path d='M8.602 1.147l.093.08 7.153 6.914-.696.718L14 7.745V14.5a.5.5 0 0 1-.41.492L13.5 15H10V9.5a.5.5 0 0 0-.41-.492L9.5 9h-3a.5.5 0 0 0-.492.41L6 9.5V15H2.5a.5.5 0 0 1-.492-.41L2 14.5V7.745L.847 8.86l-.696-.718 7.153-6.915a1 1 0 0 1 1.297-.08z'></path>
    </svg>
  )
}

const Photo = ({ getAllFavoris, hotel, photoRef }) => {
  const modalValue = 'PHOTO_SCROL'
  const { modalState, handleOpenModal, handleCloseModal } = useModalByUrl(modalValue)

  return (
    <>
      <div className={styles.block_image_hotel}>
        <div ref={photoRef} className={styles.pc_detail_image}>
          <div className={styles.box1} onClick={handleOpenModal}>
            <div className={styles.box}>
              {hotel.images[1]?.url ? <img src={hotel.images[1]?.url} alt='photo2' /> : <HomeSvg />}
            </div>
          </div>
          <div className={styles.box2} onClick={handleOpenModal}>
            <div className={styles.box}>
              {hotel.images[2]?.url ? <img src={hotel.images[2]?.url} alt='photo3' /> : <HomeSvg />}
            </div>
          </div>
          <div className={styles.box3} onClick={handleOpenModal}>
            <div className={styles.box}>
              {hotel.images[0]?.url ? <img src={hotel.images[0]?.url} alt='photo1' /> : <HomeSvg />}
            </div>
          </div>
          <div className={styles.box4} onClick={handleOpenModal}>
            <div className={styles.box}>
              {hotel.images[3]?.url ? <img src={hotel.images[3]?.url} alt='photo4' /> : <HomeSvg />}
            </div>
          </div>
          <div className={styles.box5} onClick={handleOpenModal}>
            <div className={styles.box}>
              {hotel.images[4]?.url ? <img src={hotel.images[4]?.url} alt='photo5' /> : <HomeSvg />}
            </div>
          </div>
          <button className={styles.all_image_pc} onClick={handleOpenModal}>
            Afficher toutes les photos
          </button>
        </div>
        <PhotoMobile
          getAllFavoris={getAllFavoris}
          hotel={hotel}
          handleOpenModal={handleOpenModal}
        />
      </div>

      {/*-----------modal images---------*/}
      <AllPhotoSroll hotel={hotel} modalState={modalState} handleCloseModal={handleCloseModal} />
    </>
  )
}

export default Photo
