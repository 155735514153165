import { useContext } from 'react'
import styles from '../../css/confirmations/confirmation.module.css'
import { useLocation } from 'react-router'
import { useQuery } from 'react-query'
import { User } from '../../context/UserContext'
import axios from 'axios'
import Loading from '../../components/booking/Loading'
import ErrorServer from '../errors/ErrorServer'
import NavConfirm from '../../components/confirmation_booking/NavConfirm'
import Block2 from '../../components/confirmation_booking/Block2'
import Block1NopayOnlone from '../../components/confirmation_booking/Block1NopayOnlone'
import Connexion from '../../components/confirmation_booking/Connexion'
import StatusReservation from '../../components/confirmation_booking/StatusReservation'
import DefaultLayout from '../../layouts/DefaultLayout'
import { Helmet } from 'react-helmet-async'

const ConfirmationReservation = () => {
  const location = useLocation()
  const bookingID = location.pathname.split('/')[3]
  const user = useContext(User)

  const {
    data = {},
    error,
    isLoading,
  } = useQuery(`get-reservation-${bookingID}`, () =>
    axios.get(`/user_reservations/${bookingID}`).then(res => {
      return res.data
    })
  )

  if (error) return <ErrorServer />

  if (!user) return <Connexion />

  return (
    <>
      <Helmet>
        <title>Confirmation de la réservation : {data?.reservation?.hotel?.title ?? ''}</title>
      </Helmet>
      <NavConfirm reservation={data.reservation} />

      {isLoading ? (
        <Loading />
      ) : (
        <DefaultLayout footer={true} mobileBottomNavigate={true}>
          <div className={styles.hotel_confirmation}>
            <StatusReservation user={user} reservation={data.reservation} />
            <div className={styles.recap_block}>
              <Block1NopayOnlone reservation={data.reservation} />
              {/*---------block recap et image-----*/}
              <Block2 reservation={data.reservation} />
            </div>
          </div>
        </DefaultLayout>
      )}
    </>
  )
}
export default ConfirmationReservation
