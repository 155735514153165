import { useState } from 'react'
import styles from '../../../../../../css/traveler/travel/detailReservation.module.css'
import { Link } from 'react-router-dom'
import axios from 'axios'
import LoadingPoint from '../../../../../LoadingPoint'
import { STATUS_BOOKING } from '../../../../../../enums/booking/status.enums'
import Swal from 'sweetalert2'
import Toast from '../../../../../infos/toast'

const Cancelation = ({ id, reservation, refetch }) => {
  const [loading, setLoading] = useState(false)

  const handleDelete = () => {
    Swal.fire({
      title: 'Supprimer cette demande ?',
      text: 'Une fois la demande supprimée, vous ne pourrez plus la récupérer.',
      showCancelButton: true,
      confirmButtonText: 'Supprimer la demande',
      cancelButtonText: 'Annuler',
      reverseButtons: true,
      customClass: {
        actions: 'actions_class',
        confirmButton: 'confirm_button_class',
        cancelButton: 'cancel_button_class',
      },
    }).then(result => {
      if (result.isConfirmed) {
        setLoading(true)
        axios
          .patch(`/user_reservations/cancel_request_reservation/${id}`)
          .then(async () => {
            await refetch()
            Toast.fire({
              icon: 'success',
              title: 'La demande de réservation est supprimée avec succès.',
            })
          })
          .catch(() =>
            Toast.fire({ icon: 'error', title: 'Un problème est survenu au niveau de serveur' })
          )
          .finally(() => {
            setLoading(false)
          })
      }
    })
  }
  return (
    <>
      {reservation?.status === STATUS_BOOKING.REQUEST ? (
        <div className={styles.content}>
          <h3>Annulation</h3>
          {loading ? (
            <LoadingPoint />
          ) : (
            <div className={styles.sup}>
              <button type='button' onClick={handleDelete}>
                Annuler la demande
              </button>
            </div>
          )}
        </div>
      ) : null}

      {reservation.status === STATUS_BOOKING.FUTURE ||
      reservation.status === STATUS_BOOKING.IN_PROGRESS ? (
        <div className={styles.content}>
          <h3>Annulation</h3>
          <div className={styles.sup}>
            <Link to={`/reservations/cancellation/${id}`}>Annuler la réservation</Link>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default Cancelation
