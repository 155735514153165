import { useState } from 'react'
import styles from '../../css/infos/confirmEmail.module.css'
import axios from 'axios'
import LoadingPoint from '../../components/LoadingPoint'
import CloseSvg from '../svgs/CloseSvg'

const ConfirmEmail = () => {
  const [active, setActive] = useState(true)
  const [loading, setLoading] = useState(false)

  const handleSendVerifMail = () => {
    setLoading(true)
    axios
      .patch(`/user/send_verif_email`)
      .then(() => {
        alert(
          'Veuillez consulter votre boîte de réception, un e-mail de confirmation doit s’y trouver. Cliquez sur le button pour confirmer votre adresse e-mail.'
        )
      })
      .catch(error => {
        if (error.response?.data?.emailVerfified) {
          alert('Cette adresse e-mail est déjà vérifiée actualiser la page.')
        } else {
          alert('Error server')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      {active && (
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.left}>
              <svg
                viewBox='0 0 32 32'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
              >
                <path d='m26 2c2.6887547 0 4.8818181 2.12230671 4.9953805 4.78311038l.0046195.21688962v14c0 2.6887547-2.1223067 4.8818181-4.7831104 4.9953805l-.2168896.0046195h-5.845l-4.1548949 5.0792674-4.1561051-5.0792674h-5.844c-2.61406707 0-4.75959878-2.0060384-4.98113724-4.5626572l-.01424325-.2204532-.00461951-.2168896v-14c0-2.6887547 2.12230671-4.88181811 4.78311038-4.99538049l.21688962-.00461951zm0 2h-20c-1.59768088 0-2.90366088 1.24891996-2.99490731 2.82372721l-.00509269.17627279v14c0 1.5976809 1.24891996 2.9036609 2.82372721 2.9949073l.17627279.0050927h6.7918643l3.2071357 3.92 3.2080542-3.92h6.7929458c1.5976809 0 2.9036609-1.24892 2.9949073-2.8237272l.0050927-.1762728v-14c0-1.59768088-1.24892-2.90366088-2.8237272-2.99490731z'></path>
              </svg>
            </div>
            <div className={styles.notif_message}>
              <h1>Confirmer votre adresse e-mail</h1>
              <p>
                Veuillez consulter votre boîte de réception, un e-mail de confirmation doit s’y
                trouver. Cliquez sur le bouton pour confirmer votre adresse e-mail. Si vous ne le
                retrouvez pas, vous pouvez{' '}
                <button type='button' disabled={loading} onClick={handleSendVerifMail}>
                  {loading ? <LoadingPoint /> : 'demander'}
                </button>{' '}
                un nouvel e-mail de confirmation ou{' '}
                <a href='/account-settings/info-personal#email' target='_blanck'>
                  modifier votre adresse e-mail.
                </a>
              </p>
            </div>
            <div className={styles.close_btn}>
              <button onClick={() => setActive(null)}>
                <CloseSvg />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ConfirmEmail
