import styles from '../../css/home/apropo.module.css'

function About() {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <h2 className={styles.heading}>
            Voyagez en toute sérénité lorsque vous réservez sur Gowifmi
          </h2>
          <div className={styles.poisition}>
            <div className={styles.block}>
              <div>
                <svg
                  viewBox='0 0 48 48'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'
                  role='presentation'
                  focusable='false'
                >
                  <g stroke='none'>
                    <path
                      d='M24 5c-4.462 0-9.204.909-14.23 2.743L9 8.029v27.372a1 1 0 0 0 .478.853l.116.06L24 42.716l14.406-6.402a1 1 0 0 0 .586-.783L39 35.4V8.03l-.082-.032c-5.062-1.921-9.839-2.913-14.333-2.993L24 5z'
                      fillOpacity='.2'
                    ></path>
                    <path d='M24 1C18.401 1 12.483 2.275 6.245 4.816A2 2 0 0 0 5 6.67V35.4a5 5 0 0 0 2.97 4.569L24 47.094l16.03-7.124A5 5 0 0 0 43 35.4V6.67a2 2 0 0 0-1.245-1.853C35.518 2.275 29.599 1 24 1zm0 2c5.121 0 10.557 1.128 16.308 3.391L41 6.67V35.4l-.006.196a3 3 0 0 1-1.776 2.545L24 44.905 8.782 38.142A3 3 0 0 1 7 35.4V6.669C13.008 4.22 18.674 3 24 3z'></path>
                  </g>
                </svg>
              </div>
              <div>
                <h3>Réservez maintenant et Payez sur place</h3>
                <p>
                  Découvrez des chambres privées ou des logements entiers, parfaitement adaptés à
                  tout type de voyage.
                </p>
              </div>
            </div>
            <div className={styles.block}>
              <div>
                <svg
                  viewBox='0 0 48 48'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'
                  role='presentation'
                  focusable='false'
                >
                  <g stroke='none'>
                    <path
                      d='M40 29.295V8H8v31a1 1 0 0 0 .883.993L9 40h20.295L40 29.295z'
                      fillOpacity='.2'
                    ></path>
                    <path d='M19 2v2h10V2h2v2h11a2 2 0 0 1 1.995 1.85L44 6v24.953L30.953 44H9a5 5 0 0 1-4.995-4.783L4 39V6a2 2 0 0 1 1.85-1.995L6 4h11V2h2zm10 4H19v2h-2V6H6v33a3 3 0 0 0 2.824 2.995L9 42h20v-8a5 5 0 0 1 4.783-4.995L34 29h8V6H31v2h-2V6zm12.123 25H34a3 3 0 0 0-2.995 2.824L31 34v7.123L41.123 31z'></path>
                  </g>
                </svg>
              </div>
              <div>
                <h3>Annulation Gratuite sur la majorité des logements</h3>
                <p>
                  Avec les options d'annulation, il est plus facile d'effectuer une nouvelle
                  réservation si vos projets doivent changer.
                </p>
              </div>
            </div>
            <div className={styles.block}>
              <div>
                <svg
                  viewBox='0 0 48 48'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'
                  role='presentation'
                  focusable='false'
                >
                  <g stroke='none'>
                    <path
                      d='m39 6h-32c-.51283584 0-.93550716.38604019-.99327227.88337887l-.00672773.11662113v26c0 .5128358.38604019.9355072.88337887.9932723l.11662113.0067277h20.7082039l12.2917961 6.146v-33.146c0-.51283584-.3860402-.93550716-.8833789-.99327227z'
                      fillOpacity='.2'
                    ></path>
                    <path d='m39 2c2.7614237 0 5 2.23857625 5 5v39.618034l-17.235-8.618034h-19.765c-2.76142375 0-5-2.2385763-5-5v-26c0-2.76142375 2.23857625-5 5-5zm0 2h-32c-1.65685425 0-3 1.34314575-3 3v26c0 1.6568542 1.34314575 3 3 3h20.236068l14.763932 7.381v-36.381c0-1.65685425-1.3431458-3-3-3zm-16 5c3.7279108 0 6.8602692 2.5498628 7.7481308 6.000644l.2518692-.000644c2.209139 0 4 1.790861 4 4 0 2.1421954-1.6839685 3.8910789-3.8003597 3.9951047l-.2616532.0058504c-.4161305 3.3338366-2.8836225 6.0304515-6.0993864 6.7867552-.3049792.7125636-1.0134313 1.2122897-1.8386007 1.2122897-1.1045695 0-2-.8954305-2-2s.8954305-2 2-2c.6480939 0 1.2241892.3082632 1.5896601.7861641 2.4731183-.6769664 4.3080187-2.8972905 4.4062014-5.5612258l.0041385-.2249383v-5c0-3.3137085-2.6862915-6-6-6-3.2383969 0-5.8775718 2.5655749-5.9958615 5.7750617l-.0041385.2249383v6h-2c-2.209139 0-4-1.790861-4-4 0-2.1421954 1.6839685-3.8910789 3.8003597-3.9951047l.1996403-.0048953.2518692.000644c.8878616-3.4507812 4.02022-6.000644 7.7481308-6.000644zm8 8v4c1.1045695 0 2-.8954305 2-2 0-1.0543618-.8158778-1.9181651-1.8507377-1.9945143zm-16 0c-1.1045695 0-2 .8954305-2 2 0 1.0543618.8158778 1.9181651 1.8507377 1.9945143l.1492623.0054857z'></path>
                  </g>
                </svg>
              </div>
              <div>
                <h3>Assistance utilisateurs 24h/24</h3>
                <p>Parlez à notre équipe d'assistance où que vous soyez et à toute heure.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default About
