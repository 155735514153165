import styles from '../../../css/details/other_hotel.module.css'
import useHotelRating from '../../../hooks/useHotelRating'
import StarSvg from '../../svgs/StarSvg'

const CardComment = ({ item }) => {
  const { commentLength, totalComment } = useHotelRating(item)

  return (
    <div className={styles.hotel_evaluation}>
      {commentLength > 0 ? (
        <div className={styles.block_eval}>
          <StarSvg width={12} height={12} />
          <p>
            {totalComment} ({commentLength} Commentaire
            {commentLength > 1 ? 's' : ''})
          </p>
        </div>
      ) : (
        <div className={styles.block_eval}>
          <StarSvg width={12} height={12} />
          <p>Nouveau</p>
        </div>
      )}
    </div>
  )
}

export default CardComment
