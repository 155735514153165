import styles from '../../../../../css/traveler/travel/detailReservation.module.css'
import { STATUS_BOOKING } from '../../../../../enums/booking/status.enums'

const Annonce = ({ reservation }) => {
  return (
    <div className={styles.block}>
      <h2>Détails de l'annonce et informations de base sur le logement</h2>
      <div className={styles.content}>
        <h3>Titre de l'annonce</h3>
        <p>{reservation?.hotel?.title}</p>
      </div>
      <div className={styles.content}>
        <h3>Nombre de voyageurs</h3>
        <p>Voyageurs : {reservation.hotel?.details?.traveler} maximum</p>
      </div>
      {reservation.hotel?.custom_url && (
        <div className={styles.content}>
          <h3>Lien personnalisé de l'annonce</h3>
          <a href={`/h/${reservation.hotel?.custom_url}`} target='_blank' rel='noreferrer'>
            https://gowifmi.com/h/{reservation.hotel?.custom_url}
          </a>
        </div>
      )}
      {reservation.status !== STATUS_BOOKING.CANCELLED && reservation.host_accept_reservation ? (
        <div className={styles.content}>
          <h3>Adresse</h3>
          <p>{reservation.hotel?.address?.full_address}</p>
        </div>
      ) : null}
      <div className={styles.content}>
        <h3>Description du quartier</h3>
        <p>{reservation.hotel?.traveler_information.neighborhood_description || 'Non défini'}</p>
      </div>
      <div className={styles.content}>
        <h3>Se déplacer</h3>
        <p>{reservation.hotel?.traveler_information.getting_around || 'Non défini'}</p>
      </div>
      <div className={styles.content}>
        <h3>Pièces et Espaces</h3>
        <p>Chambres : {reservation.hotel?.details?.number_of_rooms}</p>
        <p>Lit : {reservation.hotel?.details?.number_of_beds}</p>
        <p>Salles de bain : {reservation.hotel?.details?.number_of_bathrooms}</p>
      </div>
      <div className={styles.secure}>
        <h3>Sécurité des voyageurs</h3>
        {reservation.hotel?.traveler_safety?.pool_jacuzzi_unfenced_unlocked && (
          <p>Piscine/jacuzzi sans clôture ni verrou</p>
        )}
        {reservation.hotel?.traveler_safety?.lake_river_other_water_body_nearby && (
          <p>Lac, rivière ou autre étendue d'eau à proximité</p>
        )}
        {reservation.hotel?.traveler_safety?.dwelling_includes_stairs_or_staircases && (
          <p>Le logement comprend des marches ou escaliers</p>
        )}
        {reservation.hotel?.traveler_safety?.playground_climbing_structure && (
          <p>Aire de jeux ou structure d'escalade</p>
        )}
        {reservation.hotel?.traveler_safety?.dangerous_animals && (
          <p>Animal potentiellement dangereux</p>
        )}
        {reservation.hotel?.traveler_safety?.cameras && (
          <p>Caméras de surveillance ou appareils d'enregistrement audio</p>
        )}
        {reservation.hotel?.traveler_safety?.weapon && <p>Armes sur la propriété</p>}
        {reservation.hotel?.traveler_safety?.possible_noise_nuisances && (
          <p>Nuisances sonores possibles</p>
        )}
        {reservation.hotel?.traveler_safety?.pets_on_property && (
          <p>Présence d'animaux de compagnie sur place</p>
        )}
        {reservation.hotel?.traveler_safety?.no_on_site_parking && (
          <p>Pas d'espace de stationnement sur place</p>
        )}
        {reservation.hotel?.traveler_safety?.some_spaces_shared && (
          <p>Certains espaces sont partagés</p>
        )}
        {reservation.hotel?.traveler_safety?.applicable_equipment_restrictions && (
          <p>Restrictions applicables aux équipements</p>
        )}
        {reservation.hotel.traveler_safety?.height_unbarriered_unprotected && (
          <p>Zones en hauteur sans barrières ni protections</p>
        )}
      </div>
    </div>
  )
}

export default Annonce
