import styles from '../../../../css/modehotes/navbar/dropdown_link.module.css'
import { Link } from 'react-router-dom'
import SvgIconRightDirector from '../../../svgs/SvgIconRightDirector'

const CalendarParams = () => {
  return (
    <Link to='/hosting/calendar' className={styles.block_link}>
      <div className={styles.left_block}>
        <svg
          className={styles.calendar}
          viewBox='0 0 48 48'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'
          role='presentation'
          focusable='false'
        >
          <g stroke='none'>
            <path
              d='M40 29.295V8H8v31a1 1 0 0 0 .883.993L9 40h20.295L40 29.295z'
              fillOpacity='.2'
            ></path>
            <path d='M19 2v2h10V2h2v2h11a2 2 0 0 1 1.995 1.85L44 6v24.953L30.953 44H9a5 5 0 0 1-4.995-4.783L4 39V6a2 2 0 0 1 1.85-1.995L6 4h11V2h2zm10 4H19v2h-2V6H6v33a3 3 0 0 0 2.824 2.995L9 42h20v-8a5 5 0 0 1 4.783-4.995L34 29h8V6H31v2h-2V6zm12.123 25H34a3 3 0 0 0-2.995 2.824L31 34v7.123L41.123 31z'></path>
          </g>
        </svg>
        <h3>Calendrier</h3>
      </div>
      <div className={styles.rigth_block}>
        <SvgIconRightDirector />
      </div>
    </Link>
  )
}

export default CalendarParams
