import { useState } from 'react'
import styles from '../../../../css/account/profile/profile_picture.module.css'
import { useLocation } from 'react-router'
import LoadingPoint from '../../../LoadingPoint'
import { useDispatch, useSelector } from 'react-redux'
import { uploadProfile } from '../../../../redux/actions'
// import { compressorImageProfil } from '../../../../utils/compressorImage'
import axios from 'axios'

const ImageComponent = ({ picture }) => {
  return (
    <div className={styles.image}>
      {picture ? (
        <img src={picture} alt='' />
      ) : (
        <svg
          viewBox='0 0 32 32'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'
          role='presentation'
          focusable='false'
        >
          <path d='m16 .7c-8.437 0-15.3 6.863-15.3 15.3s6.863 15.3 15.3 15.3 15.3-6.863 15.3-15.3-6.863-15.3-15.3-15.3zm0 28c-4.021 0-7.605-1.884-9.933-4.81a12.425 12.425 0 0 1 6.451-4.4 6.507 6.507 0 0 1 -3.018-5.49c0-3.584 2.916-6.5 6.5-6.5s6.5 2.916 6.5 6.5a6.513 6.513 0 0 1 -3.019 5.491 12.42 12.42 0 0 1 6.452 4.4c-2.328 2.925-5.912 4.809-9.933 4.809z'></path>
        </svg>
      )}
    </div>
  )
}

export const Photo = ({ userConnected, getUser }) => {
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const userReducestore = useSelector(state => state.userReducer)

  const location = useLocation()
  const path = location.pathname.split('/')[2]

  const handlePicture = async e => {
    e.preventDefault()
    setLoading(true)
    // const file = await compressorImageProfil(e.target.files[0])
    const file = e.target.files[0]
    const data = new FormData()
    data.append('name', userConnected?.name)
    data.append('user_Id', userConnected?.id)
    data.append('photo', file)

    axios
      .post(`/user/upload`, data)
      .then(res => {
        dispatch(uploadProfile(res.data.file))
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div>
      <div className={styles.wrapper_img_profile}>
        {path === userConnected?.id ? (
          <ImageComponent picture={userReducestore?.picture} />
        ) : (
          <ImageComponent picture={getUser?.picture} />
        )}

        {path === userConnected?.id && (
          <form onSubmit={handlePicture} className={styles.btn_update}>
            <input
              type='file'
              id='photo_profile'
              accept='.jpg, .jpeg, .png'
              disabled={loading}
              onChange={handlePicture}
            />

            <label htmlFor='photo_profile'>
              {loading ? (
                <LoadingPoint />
              ) : (
                <>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 32 32'
                    aria-hidden='true'
                    role='presentation'
                    focusable='false'
                  >
                    <path d='M17.59 2a2.01 2.01 0 0 1 1.41.59L21.41 5H25a5 5 0 0 1 5 5v14a5 5 0 0 1-5 5H7a5 5 0 0 1-5-5V10a5 5 0 0 1 5-5h3.62L13 2.6a2.01 2.01 0 0 1 1.42-.6zM16 8.5a8.51 8.51 0 0 0 0 17 8.51 8.51 0 0 0 0-17zm0 3a5.5 5.5 0 1 1 0 11 5.5 5.5 0 0 1 0-11zm-10-3a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z'></path>
                  </svg>
                </>
              )}
            </label>
          </form>
        )}
      </div>
    </div>
  )
}
