import { useEffect, useContext, useState } from 'react'
import styles from '../../../css/modehotes/statistics/reservations/statistic.module.css'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { User } from '../../../context/UserContext'
import LoadingPoint from '../../../components/LoadingPoint'
import HostModeLayout from '../../../layouts/HostModeLayout'
import ErrorServer from '../../errors/ErrorServer'
import { useQuery } from 'react-query'
import SelectDate from '../../../components/hote_mode/statistics/SelectDate'
import Header from '../../../components/hote_mode/statistics/Header'
import ListingHotel from '../../../components/hote_mode/statistics/ListingHotel'
import { Helmet } from 'react-helmet-async'

const ReservationListing = () => {
  const [selectedListing, setSelectedListing] = useState('')
  const [selectedDate, setSelectedDate] = useState(() => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1 // Les mois sont indexés de 0 à 11, donc ajoutez 1
    return `${year}-${month.toString().padStart(2, '0')}` // 'YYYY-MM'
  })

  const user = useContext(User)
  const history = useHistory()

  const yearMonthDate = moment(selectedDate, 'YYYY-MM').format('MMMM YYYY')
  const yearDate = selectedDate.split('-')[0]

  useEffect(() => {
    window.scrollTo(0, 0)
    if (user === null) {
      history.push('/')
    }
  }, [history])

  const {
    data = {},
    isLoading,
    error,
  } = useQuery(['get-host_reservation_rate', selectedListing, selectedDate], () =>
    axios
      .get(
        `/statistics/host_reservation?host_id=${user?.id}&hotel_id=${selectedListing}&date=${selectedDate}`
      )
      .then(res => {
        return res.data
      })
  )

  if (error) return <ErrorServer />

  return (
    <HostModeLayout>
      <Helmet>
        <title>Réservations - Gowifmi</title>
      </Helmet>
      <div className={styles.container}>
        <Header />
        <ListingHotel selectedListing={selectedListing} setSelectedListing={setSelectedListing} />

        {isLoading ? (
          <div className={styles.container_load_liste}>
            <LoadingPoint />
          </div>
        ) : (
          <div className={styles.main}>
            <SelectDate selectedDate={selectedDate} setSelectedDate={setSelectedDate} />

            <div className={styles.infos_block}>
              <div className={styles.infos_block_content}>
                <h2>{data.monthlyReservations}</h2>
                <p>Réservations en {yearMonthDate}</p>
              </div>
              <div className={styles.infos_block_content}>
                <h2>{data.monthlyReservationFutureRate}%</h2>
                <p>Taux de réservations à venir</p>
              </div>
              <div className={styles.infos_block_content}>
                <h2>{data.monthlyReservationCancelRate}%</h2>
                <p>Taux de réservations annulées</p>
              </div>
              <div className={styles.infos_block_content}>
                <h2>{data.monthlyReservationCompleteRate}%</h2>
                <p>Taux de réservations terminées</p>
              </div>
            </div>

            <div className={styles.detail_block}>
              <div className={styles.detail_block_content}>
                <h2>{data.yearReservations}</h2>
                <p>Réservations pour {yearDate}</p>
              </div>
              <div className={styles.detail_block_content}>
                <h2>{data.annualReservationFutureRate}%</h2>
                <p>Taux de réservations à venir {yearDate}</p>
              </div>
              <div className={styles.detail_block_content}>
                <h2>{data.annualReservationCancelRate}%</h2>
                <p>Taux de réservations annulées {yearDate}</p>
              </div>
              <div className={styles.detail_block_content}>
                <h2>{data.annualReservationCompleteRate}%</h2>
                <p>Taux de réservations terminées {yearDate}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </HostModeLayout>
  )
}

export default ReservationListing
