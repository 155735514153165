import styles from '../../../../css/modehotes/statistics/evaluations/global_evaluation.module.css'
import StarSvg from '../../../svgs/StarSvg'

const GlobalEvaluation = ({ data }) => {
  return (
    <>
      {data?.commentLength > 0 && (
        <div className={styles.section}>
          <h3 className={styles.total_note}>
            <StarSvg width={20} height={20} />
            {data?.totalComment} ({data?.commentLength} commentaire
            {data?.commentLength > 1 ? 's' : ''})
          </h3>
          <div className={styles.avis_section}>
            <div>
              <div className={styles.bar_avis}>
                <p>Propreté</p>
                <div className={styles.bar_flex}>
                  <div className={styles.pourcentage}>
                    <span style={{ width: data?.totalCleanlinessPourcentage + '%' }}></span>
                  </div>
                  <span className={styles.chiffre}>{data?.cleanlinessOn5}</span>
                </div>
              </div>
              <div className={styles.bar_avis}>
                <p>Communication</p>
                <div className={styles.bar_flex}>
                  <div className={styles.pourcentage}>
                    <span style={{ width: data?.totalCommunicationPourcentage + '%' }}></span>
                  </div>
                  <span className={styles.chiffre}>{data?.communicationOn5}</span>
                </div>
              </div>
              <div className={styles.bar_avis}>
                <p>Équipements</p>
                <div className={styles.bar_flex}>
                  <div className={styles.pourcentage}>
                    <span style={{ width: data?.totalAmenitiePourcentage + '%' }}></span>
                  </div>
                  <span className={styles.chiffre}>{data?.amenitieOn5}</span>
                </div>
              </div>
            </div>
            <div>
              <div className={styles.bar_avis}>
                <p>Arrivée</p>
                <div className={styles.bar_flex}>
                  <div className={styles.pourcentage}>
                    <span style={{ width: data?.totalArrivalPourcentage + '%' }}></span>
                  </div>
                  <span className={styles.chiffre}>{data?.arrivalOn5}</span>
                </div>
              </div>
              <div className={styles.bar_avis}>
                <p>Emplacement</p>
                <div className={styles.bar_flex}>
                  <div className={styles.pourcentage}>
                    <span style={{ width: data?.totalLocationPourcentage + '%' }}></span>
                  </div>
                  <span className={styles.chiffre}>{data?.locationOn5}</span>
                </div>
              </div>
              <div className={styles.bar_avis}>
                <p>Qualité-prix</p>
                <div className={styles.bar_flex}>
                  <div className={styles.pourcentage}>
                    <span style={{ width: data?.totalQualityPricePourcentage + '%' }}></span>
                  </div>
                  <span className={styles.chiffre}>{data?.qualityPriceOn5}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default GlobalEvaluation
