import { useState, useEffect, useRef } from 'react'
import styles from '../../../css/booking/detailPrice.module.css'
import useHotelRating from '../../../hooks/useHotelRating'
import usePrice from '../../../hooks/usePrice'
import StarSvg from '../../svgs/StarSvg'
import CloseSvg from '../../svgs/CloseSvg'

const DetailPrice = ({ hotel }) => {
  const [menage, setMenage] = useState(false)
  const [promo, setPromo] = useState(false)
  const [service, setService] = useState(false)
  const {
    fullPricePerNight,
    fullTotalPricePerNight,
    cleaningFeeORG,
    fullCleaningFee,
    reduction,
    reductionTime,
    fullTotalWithservice,
    fullPromoFee,
    fullTravelerServiceFee,
    numberOfNight,
  } = usePrice(hotel)
  const { commentLength, totalComment } = useHotelRating(hotel)

  const contentMenage = useRef(null)
  const contentPromo = useRef(null)
  const contentService = useRef(null)
  useEffect(() => {
    const handleClickOutside = event => {
      if (contentMenage.current && !contentMenage.current.contains(event.target)) {
        setMenage(false)
      }
      if (contentPromo.current && !contentPromo.current.contains(event.target)) {
        setPromo(false)
      }
      if (contentService.current && !contentService.current.contains(event.target)) {
        setService(false)
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return (
    <>
      <div className={styles.rigth}>
        <div className={styles.info_image}>
          <div className={styles.head}>
            <div>
              <h5>{hotel?.title}</h5>
              {commentLength > 0 ? (
                <div className={styles.avis}>
                  <StarSvg width={12} height={12} />
                  <p>{totalComment}</p>
                  <span>
                    ({commentLength} Commentaire{commentLength > 1 ? 's' : ''})
                  </span>
                </div>
              ) : (
                <div className={styles.avis}>
                  <StarSvg width={12} height={12} />
                  <span>Nouveau</span>
                </div>
              )}
            </div>
            <div>
              <div className={styles.image}>
                {hotel?.images[0]?.url && <img src={hotel?.images[0]?.url} alt='couverture' />}
              </div>
            </div>
          </div>
          {/*---------Détails du prix----------------------------------------------*/}
          <div className={styles.dispositif}>
            <h4>Détails du prix</h4>
            <div className={styles.detail_price}>
              <div className={styles.details}>
                <button className={styles.left}>
                  <p className={styles.price}>
                    {fullPricePerNight} x {numberOfNight} {numberOfNight > 1 ? 'nuits' : 'nuit'}
                  </p>
                </button>
                <p>{fullTotalPricePerNight}</p>
              </div>
              {cleaningFeeORG > 0 && (
                <div className={styles.details} ref={contentMenage}>
                  <div className={styles.left}>
                    <p onClick={() => setMenage(!menage)}>Frais de ménage</p>
                    <div
                      className={menage ? `${styles.descript} ${styles.active}` : styles.descript}
                    >
                      <p>
                        Frais facturés une seule fois par l'hôte pour couvrir les dépenses de
                        nettoyage de son logement.
                      </p>
                      <div>
                        <button onClick={() => setMenage(false)} className={styles.close}>
                          <CloseSvg />
                        </button>
                      </div>
                    </div>
                  </div>
                  <p>{fullCleaningFee}</p>
                </div>
              )}
              {reduction > 0 && (
                <div className={styles.details} ref={contentPromo}>
                  <div className={styles.left}>
                    <p onClick={() => setPromo(!promo)}>{reductionTime}</p>
                    <div
                      className={promo ? `${styles.descript} ${styles.active}` : styles.descript}
                    >
                      <p>À vos dates, le tarif est inférieur de {reduction} %</p>
                      <div>
                        <button onClick={() => setPromo(false)} className={styles.close}>
                          <CloseSvg />
                        </button>
                      </div>
                    </div>
                  </div>
                  <p className={styles.promo}>-{fullPromoFee}</p>
                </div>
              )}
              <div className={styles.details} ref={contentService}>
                <div className={styles.left}>
                  <p onClick={() => setService(!service)}>Frais de service</p>
                  <div
                    className={service ? `${styles.descript} ${styles.active}` : styles.descript}
                  >
                    <p>
                      Ces frais participent au fonctionnement de notre plate-forme et nous
                      permettent d'offrir des services comme l'assistance 24h/24 pendant votre
                      voyage.
                    </p>
                    <div>
                      <button onClick={() => setService(false)} className={styles.close}>
                        <CloseSvg />
                      </button>
                    </div>
                  </div>
                </div>
                <p>{fullTravelerServiceFee}</p>
              </div>
              <div className={styles.total}>
                <p>Total</p>
                <p>{fullTotalWithservice}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DetailPrice
