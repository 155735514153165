import styles from '../../../../../css/modehotes/reservation/detail.module.css'
import moment from 'moment'
import momentTimeZone from 'moment-timezone'
import Modification from './components/Modification'
import StatusReservation from './components/StatusReservation'
import DetailPrice from './components/DetailPrice'
import ValidSvg from '../../../../svgs/ValidSvg'
import BillReservation from './components/BillReservation'
import { STATUS_BOOKING } from '../../../../../enums/booking/status.enums'
import { Link } from 'react-router-dom'
import { getDaysDif } from '../../../../../utils/difTwoDate'
import CommentTraveler from './components/CommentTraveler'
import RefundTotal from './components/RefundTotal'

const Reservation = ({ reservation, calculatedPrices, path, refetch }) => {
  const checkOut = reservation.reservation_infos?.checkin
  const dateNow = new Date()
  const dateLimit = 14

  const userTimezone = momentTimeZone.tz.guess()

  return (
    <>
      <div className={styles.block}>
        <h2>Les informations de la réservation</h2>
        <div className={styles.content}>
          <h3>Paiement en ligne</h3>
          <div className={styles.demande}>
            <ValidSvg state={reservation.pay_online} />
            <span>{reservation.pay_online ? 'Oui' : 'Non'}</span>
          </div>
        </div>
        {reservation?.status === STATUS_BOOKING.REQUEST && (
          <div className={styles.content}>
            <h3>Réservation acceptée</h3>
            <div className={styles.demande}>
              <ValidSvg state={reservation.host_accept_reservation} />
              <span>{reservation.host_accept_reservation ? 'Oui' : 'Non'}</span>
            </div>
          </div>
        )}
        <StatusReservation reservation={reservation} path={path} refetch={refetch} />
        {reservation.status === STATUS_BOOKING.COMPLETE &&
        getDaysDif(checkOut, dateNow) <= dateLimit &&
        !reservation.traveler_comment ? (
          <CommentTraveler id={reservation._id} reservation={reservation} refetch={refetch} />
        ) : null}
        <div className={styles.content}>
          <h3>Dates</h3>
          <div className={styles.dates_block}>
            <div>
              <h5>Arrivée</h5>
              <p>{moment(reservation.reservation_infos?.checkin).format('ll')}</p>
            </div>
            <div>
              <h5>Départ</h5>
              <p>{moment(reservation.reservation_infos?.checkout).format('ll')}</p>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <h3>Durée totale du séjour</h3>
          <p>
            {reservation?.reservation_infos?.number_of_nights}{' '}
            {reservation?.reservation_infos?.number_of_nights > 1 ? 'Nuits' : 'Nuit'}
          </p>
        </div>
        <div className={styles.content}>
          <h3>Les voyageurs</h3>
          <p>Adultes : {reservation?.reservation_infos?.total_adults}</p>
          <p>Enfants : {reservation?.reservation_infos?.total_children}</p>
          <p>Bébés : {reservation?.reservation_infos?.total_babies}</p>
          <p>Animaux : {reservation?.reservation_infos?.total_pets}</p>
        </div>
        <DetailPrice reservation={reservation} calculatedPrices={calculatedPrices} />
        {(!reservation.pay_online && reservation?.status === STATUS_BOOKING.COMPLETE) ||
        (!reservation.pay_online && reservation?.status === STATUS_BOOKING.CANCELLED) ? (
          <BillReservation reservation={reservation} calculatedPrices={calculatedPrices} />
        ) : null}
        <div className={styles.content}>
          <h3>Informations relatives à l'arrivée</h3>
          <p>
            Arrviée :{' '}
            {!reservation?.conditions?.check_in_1
              ? 'Flexibles'
              : reservation?.conditions?.check_in_1}{' '}
            -{' '}
            {!reservation?.conditions?.check_in_2
              ? 'Flexibles'
              : reservation?.conditions?.check_in_2}
          </p>
          <p>
            Départ :{' '}
            {!reservation?.conditions?.check_out ? 'Flexibles' : reservation?.conditions?.check_out}
          </p>
        </div>
        <div className={styles.content}>
          <h3>Fumeurs</h3>
          {reservation?.conditions?.smoking_allowed ? (
            <p>Les fumeurs sont admis</p>
          ) : (
            <p>Non-fumeurs</p>
          )}
        </div>
        <div className={styles.content}>
          <h3>Les animaux</h3>
          {reservation?.conditions?.pets_allowed ? (
            <p>Les animaux domestiques sont admis gratuitement</p>
          ) : (
            <p>Pas d'animaux</p>
          )}
        </div>
        <div className={styles.content}>
          <h3>Référence</h3>
          <p>{reservation?.reservation_infos?.reference}</p>
        </div>

        <div className={styles.content}>
          <h3>La date de la création</h3>
          <p>{momentTimeZone.utc(reservation?.createdAt).tz(userTimezone).calendar()}</p>
        </div>

        {reservation.status === STATUS_BOOKING.FUTURE ||
        reservation.status === STATUS_BOOKING.IN_PROGRESS ? (
          <Modification reservation={reservation} path={path} refetch={refetch} />
        ) : null}

        {reservation.status === STATUS_BOOKING.FUTURE ||
        reservation.status === STATUS_BOOKING.IN_PROGRESS ? (
          <div className={styles.content}>
            <h3>Annulation</h3>
            <div className={styles.sup}>
              <Link to={`/hosting/reservations/cancellation/${path}`}>Annuler la réservation</Link>
            </div>
          </div>
        ) : null}
        {reservation.status === STATUS_BOOKING.FUTURE ||
        reservation.status === STATUS_BOOKING.IN_PROGRESS ||
        reservation.status === STATUS_BOOKING.COMPLETE ? (
          <RefundTotal reservation={reservation} />
        ) : null}
      </div>
    </>
  )
}

export default Reservation
