import { useState } from 'react'
import style from '../css/hooks/usephoto.module.css'
import axios from 'axios'
import Swal from 'sweetalert2'
import { arrayMove } from '@dnd-kit/sortable'

function usePhoto(path, images, setImages) {
  const [activeUrl, setActiveUrl] = useState(null)
  const [open, setOpen] = useState(null)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [imagesNotCompatible, setImagesNotCompatible] = useState([])

  const handleUploadInput = async files => {
    setLoadingUpload(true)

    const maxLength = 100 - images.length
    if (files.length > maxLength) {
      alert(`Vous n'êtes autorisé à télécharger qu'un maximum de ${maxLength} fichiers`)
      return
    }
    const formData = new FormData()
    for (const file of files) {
      if (file.size >= 50000) {
        const fileCompression = file
        formData.append('photos', fileCompression)
      } else {
        const localImagePath = URL.createObjectURL(file)
        setImagesNotCompatible(prev => [...prev, localImagePath])
      }
    }
    axios
      .post(`/hotel/upload/${path}`, formData)
      .then(res => {
        setImages(res.data.images)
      })
      .catch(err => {
        if (err.response?.data?.message) {
          alert(err.response?.data?.message)
        } else {
          alert(`Une erreur serveur est survenue contactez notre service d'assistance`)
        }
      })
      .finally(() => {
        setLoadingUpload(false)
      })
  }

  const deleteImage = photo => {
    Swal.fire({
      title: 'Supprimer cette photo ?',
      text: 'Une fois la photo supprimée, vous ne pourrez plus la récupérer.',
      showCancelButton: true,
      confirmButtonText: 'Supprimer la photo',
      cancelButtonText: 'Annuler',
      reverseButtons: true,
      customClass: {
        actions: style.actions_class,
        confirmButton: style.confirm_button_class,
        cancelButton: style.cancel_button_class,
      },
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .patch(`/hotel/Deletephoto/${path}/${photo._id}`, photo)
          .then(res => {
            setImages(res.data.images)
          })
          .catch(() => {
            alert(`Une erreur est survenue veuillez réessayer`)
          })
      }
    })
  }

  const updateDatabase = data => {
    setOpen(null)
    axios
      .patch(`/hotel/reorganize_image/${path}`, data)
      .then(res => {
        setImages(res.data.images)
      })
      .catch(err => {
        alert(`Une erreur est survenue veuillez réessayer`)
      })
  }

  const changePosition = (arr, from, to) => {
    arr.splice(to, 0, arr.splice(from, 1)[0])
    return arr
  }

  const handleDragStart = event => {
    const img = images.find(image => image._id === event.active.id)
    setActiveUrl(img.url)
  }

  const handleDragEnd = event => {
    const { active, over } = event
    if (active.id !== over.id) {
      setImages(items => {
        const oldIndex = items.findIndex(image => image._id === active.id)
        const newIndex = items.findIndex(image => image._id === over.id)
        const newArrayImages = arrayMove(items, oldIndex, newIndex)
        const data = {
          images: newArrayImages,
        }
        updateDatabase(data)
        return newArrayImages
      })
    }
    setActiveUrl(null)
  }

  function handleDragCancel() {
    setActiveUrl(null)
  }

  const handleChangeTocoverImage = async id => {
    const index = images.findIndex(el => el._id === id)
    const newArrayImages = await changePosition(images, index, 0)
    const data = {
      images: newArrayImages,
    }
    updateDatabase(data)
  }

  const handleGoForward = async id => {
    const index = images.findIndex(el => el._id === id)
    const to = index + 1
    const newArrayImages = await changePosition(images, index, to)
    const data = {
      images: newArrayImages,
    }
    updateDatabase(data)
  }

  const handleGoBack = async id => {
    const index = images.findIndex(el => el._id === id)
    const to = index - 1
    const newArrayImages = await changePosition(images, index, to)
    const data = {
      images: newArrayImages,
    }
    updateDatabase(data)
  }

  return {
    activeUrl,
    open,
    setOpen,
    handleDragStart,
    handleDragEnd,
    handleDragCancel,
    handleUploadInput,
    imagesNotCompatible,
    setImagesNotCompatible,
    loadingUpload,
    deleteImage,
    handleChangeTocoverImage,
    handleGoForward,
    handleGoBack,
  }
}

export default usePhoto
