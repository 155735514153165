import { useRef, useContext } from 'react'
import styles from '../../css/add_to_favoris/AddRemove.module.css'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { User } from '../../context/UserContext'
import Login from '../login'

const AddRemoveDetailRoom = ({
  annonce,
  setModal,
  setCreateList,
  setPushFavori,
  setsupFavori,
  getAllFavoris,
}) => {
  const user = useContext(User)
  const favoris = useSelector(state => state.getFavorisReducer)

  const filteredArray = favoris
    ?.filter(el => el.lists.some(lists => lists.hotel?._id === annonce._id))
    .map(el => {
      let n = Object.assign({}, el, {
        lists: el.lists.filter(lists => lists.hotel?._id === annonce._id),
      })
      return n
    })

  const handleModal = () => {
    if (user === null) {
      handleConnection('connexion')
    } else {
      setCreateList(false)
      setModal(true)
    }
  }

  const handleDeleteToliste = () => {
    axios
      .put(`/favoris/delete/${filteredArray[0]._id}/${annonce._id}`)
      .then(() => {
        getAllFavoris()
      })
      .catch(() => {})
    setsupFavori(true)
    setPushFavori(false)
    setTimeout(() => {
      setsupFavori(false)
    }, 3000)
  }

  const connexion = useRef()
  const handleConnection = params => {
    connexion.current.OpenModal(params)
  }
  return (
    <>
      {filteredArray.length > 0 ? (
        <button onClick={handleDeleteToliste}>
          <svg
            className={styles.fav_svg}
            viewBox='0 0 32 32'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            role='presentation'
            focusable='false'
          >
            <path d='m16 28c7-4.733 14-10 14-17 0-1.792-.683-3.583-2.05-4.95-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05l-2.051 2.051-2.05-2.051c-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05-1.367 1.367-2.051 3.158-2.051 4.95 0 7 7 12.267 14 17z'></path>
          </svg>
          <span>Enregistré</span>
        </button>
      ) : (
        <button onClick={handleModal}>
          <svg
            className={styles.no_fav_svg}
            viewBox='0 0 32 32'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            role='presentation'
            focusable='false'
          >
            <path d='m16 28c7-4.733 14-10 14-17 0-1.792-.683-3.583-2.05-4.95-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05l-2.051 2.051-2.05-2.051c-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05-1.367 1.367-2.051 3.158-2.051 4.95 0 7 7 12.267 14 17z'></path>
          </svg>
          <span>Enregistrer</span>
        </button>
      )}
      <Login ref={connexion} />
    </>
  )
}

export default AddRemoveDetailRoom
