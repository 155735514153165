import styles from '../../css/search_room_result/filtre_1.module.css'
import ButtonHandleAllFilter from './components/ButtonHandleAllFilter'
import OptionReservationFilterInline from './components/OptionReservationFilterInline'
import { useSearchParams } from '../../context/SearchParamContext'

const FilterInline = () => {
  const array = [
    { name: 'Wifi', value: '1' },
    { name: 'Climatisation', value: '8' },
    { name: 'Piscine', value: '3' },
    { name: 'Parking gratuit', value: '2' },
    { name: 'Cuisine', value: '4' },
    { name: 'Lave-linge', value: '6' },
    { name: 'Jacuzzi', value: '14' },
    { name: 'Bord de mer', value: '5' },
  ]

  const { params, updateParams } = useSearchParams()
  const newParams = new URLSearchParams(params.toString())

  URLSearchParams.prototype.remove = function (key, value) {
    const entries = this.getAll(key)
    const newEntries = entries.filter(entry => entry !== value)
    this.delete(key)
    newEntries.forEach(newEntry => this.append(key, newEntry))
  }

  const onchangeFilters = e => {
    if (!e.target.checked === true) {
      newParams.delete('page')
      newParams.remove('amenities', e.target.value)
    } else {
      newParams.delete('page')
      newParams.append('amenities', e.target.value)
    }
    updateParams(newParams)
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.block_checkbox}>
          <OptionReservationFilterInline />
          {array.map(item => (
            <label key={item.value}>
              <input
                type='checkbox'
                value={item.value}
                checked={newParams.getAll('amenities').includes(item.value)}
                onChange={onchangeFilters}
              />
              <span>{item.name}</span>
            </label>
          ))}
        </div>
      </div>
      {/*-------ButtonHandleAllFilter--------*/}
      <ButtonHandleAllFilter />
    </div>
  )
}

export default FilterInline
