import styles from '../../../../../../css/search_room_result/more_actions/description_profil.module.css'
import ParagrapheTronque from '../../../../../ui/ParagrapheTronque'

const About = ({ getUser, lengthMax }) => {
  return (
    <div>
      <div className={styles.about_me_desc}>
        {getUser?.about_me && <ParagrapheTronque text={getUser?.about_me} lengthMax={lengthMax} />}
      </div>
    </div>
  )
}

export default About
