import styles from '../../../../../../../css/modehotes/annonce/details/section_title.module.css'

const Section = ({ title, handleOpen, children }) => {
  return (
    <div>
      <div className={styles.head}>
        <h3>{title}</h3>
        <button onClick={handleOpen}>Modifier</button>
      </div>
      <div className={styles.main}>{children}</div>
    </div>
  )
}

export default Section
