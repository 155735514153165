const Category = () => {
  const array = [
    {
      name: 'Hôtel',
      value: 'hotel',
      descr:
        'Une entreprise qui propose aux voyageurs des chambres privées, des suites ou des logements uniques.',
    },
    {
      name: 'Complexe hôtelier',
      value: 'complexe_hotelier',
      descr:
        "Une entreprise du secteur de l'hébergement avec plus d'équipements et de services qu'un hôtel.",
    },
    {
      name: 'Boutique-hôtel',
      value: 'boutique_hotel',
      descr:
        "Une entreprise du secteur de l'hébergement avec un style ou un thème unique qui lui confère son identité.",
    },
    {
      name: "Appart'hôtel",
      value: 'appart_hotel',
      descr:
        'Un logement avec des services hôteliers et des chambres qui ressemblent à des appartements.',
    },
    {
      name: 'Hôtel historique',
      value: 'hotel_historique',
      descr: "Un bâtiment historique converti en chambres d'hôtes en Inde.",
    },
    {
      name: "Chambre d'hôtes",
      value: "chambre_d'hete",
      descr:
        "Une entreprise du secteur de l'hébergement proposant aux voyageurs un petit déjeuner avec un hôte sur place.",
    },
    {
      name: 'Gîte',
      value: 'gîte',
      descr:
        'Une entreprise qui propose des séjours près de milieux naturels, notamment en forêt ou en montagne.',
    },
    {
      name: 'Minsu',
      value: 'minsu',
      descr:
        "Une entreprise du secteur de l'hébergement proposant aux voyageurs des chambres privées à Taïwan.",
    },
    {
      name: 'Gîte à la ferme',
      value: 'gite_a_la_ferme',
      descr:
        'Un appartement à aménagement ouvert ou en résidence, qui peut comporter des murets intérieurs.',
    },
    {
      name: 'Casa particular',
      value: 'casa_particular',
      descr:
        "Une chambre privée dans un logement à Cuba, à l'atmosphère évoquant une chambre d'hôtes.",
    },
    {
      name: 'Ryokan',
      value: 'ryokan',
      descr: 'Une petite auberge offrant aux voyageurs une expérience culturelle unique au Japon.',
    },
  ]
  return (
    <>
      {array.map(item => (
        <option value={item.value} key={item.value}>
          {item.name}{' '}
        </option>
      ))}
    </>
  )
}

export default Category
