import styles from '../../../../../css/modehotes/annonce/details/securiteVoyageur.module.css'
import Checkbox from '../../../../ui/Checkbox'

const StepOrStaircase = ({ marchesOuEscaliers, setMarchesOuEscaliers }) => {
  return (
    <div className={styles.checkbox_container}>
      <div>
        <label htmlFor='marchesOuEscaliers'>Le logement comprend des marches ou escaliers</label>
        <p>Les voyageurs doivent s'attendre à emprunter des escaliers pendant leur séjour.</p>
      </div>
      <div>
        <Checkbox
          id='marchesOuEscaliers'
          value={marchesOuEscaliers}
          checked={marchesOuEscaliers}
          onChange={() => setMarchesOuEscaliers(!marchesOuEscaliers)}
        />
      </div>
    </div>
  )
}

export default StepOrStaircase
