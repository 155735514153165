const Category = () => {
  const array = [
    {
      name: 'Appartement',
      value: 'appartement',
      descr:
        'Un logement loué dans un immeuble résidentiel comprenant plusieurs logements, ou dans un complexe',
    },
    {
      name: 'Appartement en résidence',
      value: 'appartement_en_residence',
      descr:
        'Un logement dans un immeuble comprenant plusieurs logements ou dans un complexe appartenant aux résidents.',
    },
    {
      name: 'Loft',
      value: 'loft',
      descr:
        'Un appartement à aménagement ouvert ou en résidence, qui peut comporter des murets intérieurs.',
    },
    {
      name: 'Résidence de tourisme',
      value: 'residence_de_tourisme',
      descr:
        'Un appartement avec des services hôteliers proposés par une entreprise de gestion professionnelle.',
    },
    {
      name: 'Logement de vacances',
      value: 'logement_de_vacance',
      descr:
        'Une location meublée avec une cuisine et une salle de bain pouvant offrir certains services aux voyageurs, comme un service de réception.',
    },
  ]

  return (
    <>
      {array.map(item => (
        <option value={item.value} key={item.value}>
          {item.name}{' '}
        </option>
      ))}
    </>
  )
}

export default Category
