import styles from '../../../css/signals/signal.module.css'

const Succes = ({ handleclose }) => {
  return (
    <>
      <div className={styles.m_body}>
        <div>
          <h3>Nous avons reçu votre signalement</h3>
          <p>
            Merci de vous être donné la peine de nous expliquer ce qui se passe. Les rapports comme
            levôtre sont très importants pour assurer la sécurité au sein de la communauté Gowifmi.
          </p>
        </div>
      </div>
      <div className={styles.button_action}>
        <div></div>
        <button className={styles.next} onClick={handleclose}>
          Ok
        </button>
      </div>
    </>
  )
}

export default Succes
