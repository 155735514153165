import { useRef } from 'react'
import styles from '../../css/details/header.module.css'
import Favoritedetail from './photos/Favoritedetail'
import useHotelRating from '../../hooks/useHotelRating'
import ShareHotel from './ShareHotel'
import { ParamProvider } from '../../context/SearchParamContext'
import ModalSearch from '../research_modal/ModalSearch'
import { TYPE_LOGEMENT } from '../../enums/logement/type_logement.enums'
import { HOST_STATUS_ACTIVITY } from '../../enums/logement/host_status_activity.enums'
import StarSvg from '../svgs/StarSvg'

const Header = ({ getAllFavoris, annonce, localisationRef, commentRef }) => {
  const { commentaireLength, TotalCommente } = useHotelRating(annonce)

  const modalValue = 'CHAR_HOME_PC'
  const sharingRef = useRef()
  const handleShare = () => {
    sharingRef.current.OpenModal()
  }
  const handleButtonClick = elementRef => {
    if (elementRef.current) {
      window.scrollTo({
        top: elementRef.current.offsetTop - 70,
        behavior: 'smooth',
      })
    }
  }

  const search = useRef()
  const openSearch = () => {
    search.current.OpenModal()
  }

  return (
    <ParamProvider>
      <div className={styles.container}>
        <div className={styles.block_1}>
          <div className={styles.button_searchResult} onClick={openSearch}>
            <p>Rechercher...</p>
            <div className={styles.icon_search}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 32 32'
                aria-hidden='true'
                role='presentation'
                focusable='false'
                style={{
                  display: 'block',
                  fill: 'none',
                  height: '12px',
                  width: '12px',
                  stroke: 'currentcolor',
                  strokeWidth: '5.333333333333333',
                  overflow: 'visible',
                }}
              >
                <path fill='none' d='M13 24a11 11 0 1 0 0-22 11 11 0 0 0 0 22zm8-3 9 9'></path>
              </svg>
            </div>
          </div>
          <div className={styles.buttonms}>
            <button onClick={handleShare}>
              <svg
                className={styles.partage}
                viewBox='0 0 32 32'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
              >
                <g fill='none'>
                  <path d='M27 18v9a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-9'></path>
                  <path d='M16 3v23V3z'></path>
                  <path d='M6 13l9.293-9.293a1 1 0 0 1 1.414 0L26 13'></path>
                </g>
              </svg>
              <span>Partager</span>
            </button>
            <Favoritedetail getAllFavoris={getAllFavoris} annonce={annonce} />
          </div>
        </div>
        <div className={styles.block_2}>
          {annonce.accommodation_type === TYPE_LOGEMENT.ENTIRE_HOME && (
            <h2>
              Logement entier :{' '}
              {annonce.about?.host_activity_status === HOST_STATUS_ACTIVITY.PARTICULAR
                ? 'particulier'
                : 'professionnel'}
            </h2>
          )}
          {annonce.accommodation_type === TYPE_LOGEMENT.PRIVATE_ROOM && (
            <h2>
              Chambre privée :{' '}
              {annonce.about?.host_activity_status === HOST_STATUS_ACTIVITY.PARTICULAR
                ? 'particulier'
                : 'professionnel'}
            </h2>
          )}
          {annonce.accommodation_type === TYPE_LOGEMENT.SHARED_ROOM && (
            <h2>
              Chambre partagée :{' '}
              {annonce.about?.host_activity_status === HOST_STATUS_ACTIVITY.PARTICULAR
                ? 'particulier'
                : 'professionnel'}
            </h2>
          )}
          <div className={styles.block_3}>
            <div className={styles.titre_hotel}>
              {commentaireLength > 0 && (
                <div className={styles.avis}>
                  <StarSvg width={14} height={14} />
                  <p>{TotalCommente}</p>
                  <button onClick={() => handleButtonClick(commentRef)}>
                    ({commentaireLength} Commentaire{commentaireLength > 1 ? 's' : ''})
                  </button>
                </div>
              )}
              <p id='booking-assistant'>
                <button onClick={() => handleButtonClick(localisationRef)}>
                  {annonce.address?.city}, {annonce.address?.country} - Voir sur la carte
                </button>
              </p>
            </div>
          </div>
        </div>
        {/*-----------modal de partage---------*/}
        <ModalSearch ref={search} />
        <ShareHotel hotel={annonce} ref={sharingRef} modalValue={modalValue} />
      </div>
    </ParamProvider>
  )
}

export default Header
