const HebergementOptions = () => {
  const options = [
    { value: 'Définition du prix' },
    { value: 'Commentaires' },
    { value: "Demande d'aide" },
    { value: 'Devenir hôte' },
    { value: 'Gestion de mes réservations' },
    { value: 'Gestion de mon calendrier' },
    { value: 'Gestion de mon annonce' },
    { value: 'Mes versements' },
    { value: 'Messages' },
    { value: 'Mon compte ou mon profil' },
    { value: 'Standards hôtes' },
    { value: 'Superhost' },
    { value: 'Autres' },
  ]
  return (
    <>
      {options.map((item, i) => (
        <option value={item.value} key={i}>
          {item.value}
        </option>
      ))}
    </>
  )
}

export default HebergementOptions
