import { useState } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import Options from './Options'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const DayUnauthorized = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [joursDepartNonAutorisers, setJoursDepartNonAutorisers] = useState([])
  const { loading, updateData } = useUpdateHotel(setOpen)

  const delai = annonce.availability_calendar?.prohibited_checkout_day
  const handleSubmit = () => {
    const datas = {
      availability_calendar: {
        ...annonce.availability_calendar,
        prohibited_checkout_day: joursDepartNonAutorisers,
      },
    }
    updateData(datas)
  }
  const openFuction = () => {
    setJoursDepartNonAutorisers(annonce.availability_calendar?.prohibited_checkout_day)
    setOpen(true)
  }
  const reset = () => {
    setJoursDepartNonAutorisers([])
    setOpen(false)
  }
  return (
    <div className={styles.block}>
      {!open ? (
        <Section title='Jours de départ non autorisés' handleOpen={openFuction}>
          {delai?.length > 0 ? (
            <>
              {delai.includes('0') && <p>Dimanche,</p>}
              {delai.includes('1') && <p>Lundi,</p>}
              {delai.includes('2') && <p>Mardi,</p>}
              {delai.includes('3') && <p>Mercredi,</p>}
              {delai.includes('4') && <p>Juedi,</p>}
              {delai.includes('5') && <p>Vendredi,</p>}
              {delai.includes('6') && <p>Samedi</p>}
            </>
          ) : (
            <p>Non défini</p>
          )}
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title='Jours de départ non autorisés' handleReset={reset}>
            <p>
              Les voyageurs ne pourront pas réserver votre logement si leur séjour prend fin à ces
              dates.
            </p>
          </ContenHead>
          <div className={styles.body}>
            <div className={styles.input_block}>
              <Options
                annonce={annonce}
                joursDepartNonAutorisers={joursDepartNonAutorisers}
                setJoursDepartNonAutorisers={setJoursDepartNonAutorisers}
              />
            </div>
          </div>
          <BottomForm handleSubmit={handleSubmit} loading={loading} handleReset={reset} />
        </div>
      )}
    </div>
  )
}

export default DayUnauthorized
