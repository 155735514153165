import styles from '../../../../../css/modehotes/annonce/details/securiteVoyageur.module.css'
import Checkbox from '../../../../ui/Checkbox'

const DangerousAnimal = ({ animauDangereux, setAnimauDangereux }) => {
  return (
    <div className={styles.checkbox_container}>
      <div>
        <label htmlFor='animal'>Animal potentiellement dangereux</label>
        <p>
          Les voyageurs (et leurs animaux de compagnie) seront en contact ou à proximité d'animaux,
          sauvages ou domestiqués, qui pourraient représenter une source de danger, de par leur
          taille ou leur comportement. Ex. : cheval, puma ou chien susceptible de grogner ou de
          mordre.
        </p>
      </div>
      <div>
        <Checkbox
          id='animal'
          value={animauDangereux}
          checked={animauDangereux}
          onChange={() => setAnimauDangereux(!animauDangereux)}
        />
      </div>
    </div>
  )
}

export default DangerousAnimal
