import { useState, useEffect, useRef, useMemo } from 'react'
import styles from '../../css/search_room_result/maps.module.css'
import { GoogleMap } from '@react-google-maps/api'
import LoadingPoint from '../../components/LoadingPoint'
import CardMaps from './components/maps/CardMaps'
import CardSmile from './components/maps/CardSmile'
import ButtonHandleMaps from './components/maps/ButtonHandleMaps'
import { useSearchParams } from '../../context/SearchParamContext'
import mapStyles from '../../utils/mapStyles'

const mapContainerStyle = {
  width: '100%',
  height: '100%',
}

const defaultCenter = {
  lat: 4.35213,
  lng: 50.845926,
}

const mapOptions = {
  disableDefaultUI: true,
  zoomControl: true,
  gestureHandling: 'greedy',
  zoomControlOptions: {
    position: window.google.maps.ControlPosition.RIGHT_TOP,
    style: window.google.maps.ZoomControlStyle.SMALL,
  },
  minZoom: 2,
  clickableIcons: false,
  styles: mapStyles,
}

function Maps({ loading, coords, hotel, childhover }) {
  const [openMap, setOpenMap] = useState(false)
  const [map, setMap] = useState(null)
  const [listVisited, setListVisited] = useState([])
  const [childClicked, setChildClicked] = useState(null)
  const { params, updateParams } = useSearchParams()
  const newParams = new URLSearchParams(params.toString())

  const search_by_zoom = useMemo(() => newParams.get('zoom'), [newParams])
  const search_by_map_query = useMemo(() => newParams.get('search_by_map'), [newParams])
  const search_lat = useMemo(() => newParams.get('lat'), [newParams])
  const search_lng = useMemo(() => newParams.get('lng'), [newParams])

  const coordsSearch = useMemo(
    () => ({ lat: Number(search_lat), lng: Number(search_lng) }),
    [search_lat, search_lng]
  )

  const dragEndTimeoutRef = useRef(null)

  useEffect(() => {
    const interval = setInterval(() => {
      const mapElement = document.querySelector('.gm-style')
      if (mapElement) {
        mapElement.setAttribute('tabindex', '-1')
        clearInterval(interval)
      }
    }, 100)

    return () => clearInterval(interval)
  }, [])

  const onMapInteraction = () => {
    if (dragEndTimeoutRef.current) {
      clearTimeout(dragEndTimeoutRef.current)
    }

    dragEndTimeoutRef.current = setTimeout(() => {
      if (map) {
        newParams.delete('page')
        newParams.set('zoom', map.getZoom())
        newParams.set('search_by_map', true)
        newParams.set('lat', map.getCenter().lat())
        newParams.set('lng', map.getCenter().lng())
        updateParams(newParams)
      }
    }, 500)
  }

  useEffect(() => {
    return () => {
      setListVisited([])
      setChildClicked(null)
      if (dragEndTimeoutRef.current) {
        clearTimeout(dragEndTimeoutRef.current)
      }
    }
  }, [])

  return (
    <>
      <ButtonHandleMaps childClicked={childClicked} openMap={openMap} setOpenMap={setOpenMap} />
      <div className={openMap ? `${styles.container} ${styles.active}` : styles.container}>
        <div className={styles.map_block}>
          <div className={loading ? `${styles.loading} ${styles.active}` : styles.loading}>
            <LoadingPoint />
          </div>
          <CardMaps childClicked={childClicked} setChildClicked={setChildClicked} />
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={search_by_map_query ? coordsSearch : coords || defaultCenter}
            zoom={search_by_zoom ? Number(search_by_zoom) : 12}
            options={mapOptions}
            onClick={() => setChildClicked(null)}
            onLoad={setMap}
            onDragEnd={onMapInteraction}
            onZoomChanged={onMapInteraction}
          >
            {hotel?.map(item => (
              <CardSmile
                key={item._id}
                item={item}
                childhover={childhover}
                childClicked={childClicked}
                setChildClicked={setChildClicked}
                listVisited={listVisited}
                setListVisited={setListVisited}
              />
            ))}
          </GoogleMap>
        </div>
      </div>
    </>
  )
}

export default Maps
