import { useRef } from 'react'
import styles from '../../../../css/search_room_result/cards/profile.module.css'
import ShowMore from '../more_actions/ShowMore'
import moment from 'moment'
import ShowProfile from '../more_actions/ShowProfile'

const HostProfileAndMore = ({ item, handleDeleteAnnonceInlist }) => {
  const showMoreRef = useRef(null)
  const handleShowMore = () => {
    showMoreRef.current.OpenModal()
  }

  const showProfilRef = useRef(null)
  const handleShowProfil = () => {
    showProfilRef.current.OpenModal()
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper_img_profile}>
          <div>
            <button type='button' className={styles.image_block} onClick={handleShowProfil}>
              {item?.user_id?.picture ? (
                <img src={item?.user_id?.picture} alt='' />
              ) : (
                <svg
                  viewBox='0 0 32 32'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'
                  role='presentation'
                  focusable='false'
                >
                  <path d='m16 .7c-8.437 0-15.3 6.863-15.3 15.3s6.863 15.3 15.3 15.3 15.3-6.863 15.3-15.3-6.863-15.3-15.3-15.3zm0 28c-4.021 0-7.605-1.884-9.933-4.81a12.425 12.425 0 0 1 6.451-4.4 6.507 6.507 0 0 1 -3.018-5.49c0-3.584 2.916-6.5 6.5-6.5s6.5 2.916 6.5 6.5a6.513 6.513 0 0 1 -3.019 5.491 12.42 12.42 0 0 1 6.452 4.4c-2.328 2.925-5.912 4.809-9.933 4.809z'></path>
                </svg>
              )}
            </button>
          </div>
          <div className={styles.wrapper_name}>
            <div className={styles.about_name}>
              <h2>{item?.user_id?.first_name}</h2>
              {item?.user_id?.isIdentityValid && (
                <span>
                  <svg
                    viewBox='0 0 16 16'
                    xmlns='http://www.w3.org/2000/svg'
                    aria-hidden='true'
                    role='presentation'
                    focusable='false'
                    style={{
                      height: '10px',
                      width: '10px',
                      fill: 'currentColor',
                    }}
                  >
                    <path d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.159 4.869L6.67 9.355 4.42 7.105 3.289 8.236 6.67 11.62 12.291 6z'></path>
                  </svg>
                </span>
              )}
            </div>
            <p>Membre depuis {moment(item?.user_id?.createdAt).format('LL').slice(2)}</p>
          </div>
        </div>
        <div>
          <button type='button' className={styles.btn_more_info} onClick={handleShowMore}>
            <svg
              aria-label='Plus d’options'
              fill='#333'
              height='24'
              role='img'
              viewBox='0 0 24 24'
              width='24'
            >
              <circle cx='12' cy='12' r='1.5'></circle>
              <circle cx='6' cy='12' r='1.5'></circle>
              <circle cx='18' cy='12' r='1.5'></circle>
            </svg>
          </button>
        </div>
      </div>
      <ShowMore
        ref={showMoreRef}
        item={item}
        handleDeleteAnnonceInlist={handleDeleteAnnonceInlist}
      />
      <ShowProfile ref={showProfilRef} item={item} />
    </>
  )
}

export default HostProfileAndMore
