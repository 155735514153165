const Category = () => {
  const array = [
    {
      name: 'Logement',
      value: 'logement',
      descr: 'Un logement qui peut être indépendant ou avoir des murs mitoyens.',
    },
    {
      name: 'Cabane',
      value: 'cabane',
      descr:
        'Une maison construite avec des matériaux naturels comme le bois et située dans un cadre naturel.',
    },
    {
      name: 'Villa',
      value: 'villa',
      descr:
        'Un logement de luxe qui peut avoir des espaces intérieurs et extérieurs, des jardins et des piscines.',
    },
    {
      name: 'Maison de ville',
      value: 'maison_de_ville',
      descr:
        'Une maison en rangée ou en terrasse sur plusieurs niveaux avec des murs mitoyens et éventuellement des espaces extérieurs.',
    },
    {
      name: 'Cottage',
      value: 'cottage',
      descr: "Une maison chaleureuse construite en région rurale, ou près d'un lac ou d'une plage.",
    },
    {
      name: 'Bungalow',
      value: 'bungalow',
      descr: 'Une maison de plain-pied avec un grand porche et un toit en pente.',
    },
    {
      name: 'Maison en terre',
      value: 'maison_en_terre',
      descr: 'Un logement construit dans la terre ou fabriqué à partir de matériaux comme le pisé.',
    },
    {
      name: 'Péniche',
      value: 'peniche',
      descr:
        "Une maison flottante construite à partir de matériaux similaires à ceux d'une maison sur la terre ferme.",
    },
    {
      name: 'Hutte',
      value: 'hutte',
      descr: 'Une maison faite de bois ou de boue qui peut avoir un toit de chaume.',
    },
    {
      name: 'Gîte à la ferme',
      value: 'gite_a_la_ferme',
      descr:
        'Un logement à la campagne où les voyageurs peuvent côtoyer des animaux et la vie agricole.',
    },
    {
      name: 'Dôme',
      value: 'dome',
      descr: "Une maison avec un toit en dôme ou de forme sphérique, telle qu'une maison-bulle.",
    },
    {
      name: 'Maison cycladique',
      value: 'maison_cycladique',
      descr: 'Une maison blanchie à la chaux avec un toit plat située dans les Cyclades, en Grèce.',
    },
    {
      name: 'Chalet',
      value: 'chalet',
      descr:
        'Une maison en bois avec un toit en pente, populaire pour le ski ou les séjours estivaux.',
    },
    {
      name: 'Dammuso',
      value: 'dammuso',
      descr: "Une maison en pierres avec un toit en forme de dôme sur l'île de Pantelleria.",
    },
    {
      name: 'Tiny house',
      value: 'tiny_house',
      descr: "Une maison individuelle d'une superficie généralement inférieure à 37 mètres carrés.",
    },
    {
      name: 'Trullo',
      value: 'trullo',
      descr: "Une maison circulaire en pierre avec un toit en forme de cône, originaire d'Italie.",
    },
    {
      name: 'Casa particular',
      value: 'casa_particular',
      descr:
        "Une chambre privée dans un logement à Cuba, à l'atmosphère évoquant une chambre d'hôtes.",
    },
    {
      name: 'Pension',
      value: 'pension',
      descr: 'Une maison avec barbecue et espace commun dans la campagne coréenne.',
    },
    {
      name: 'Logement de vacances',
      value: 'logement_de_vacance',
      descr:
        'Une location meublée avec une cuisine et une salle de bain pouvant offrir certains services aux voyageurs, comme un service de réception.',
    },
  ]
  return (
    <>
      {array.map(item => (
        <option value={item.value} key={item.value}>
          {item.name}{' '}
        </option>
      ))}
    </>
  )
}

export default Category
