import { useState, useRef } from 'react'
import styles from '../../../css/account/profile/profilAnnonce.module.css'
import axios from 'axios'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import LoadingPoint from '../../LoadingPoint'
import CardComment from './annonce/CardComment'
import { TYPE_LOGEMENT } from '../../../enums/logement/type_logement.enums'

const Annonces = ({ getUser }) => {
  const [prev, setPrev] = useState(false)
  const [nex, setNex] = useState(true)

  const location = useLocation()
  const path = location.pathname.split('/')[2]

  const {
    data: hotels,
    isLoading,
    error,
  } = useQuery(`get-hotel-host-${path}`, () =>
    axios.get(`/hotel_user/host/${path}`).then(res => {
      return res.data
    })
  )

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <LoadingPoint />
      </div>
    )
  }

  if (error) {
    return <div>Les annonces n'arrivent pas à charger veuillez reséyer</div>
  }

  return (
    <>
      {hotels.length > 0 && (
        <div className={styles.Hotel_recomande}>
          <h2 className={styles.heading}>
            Annonce{hotels.length > 1 ? 's' : ''} de {getUser.first_name}
          </h2>
          <Swiper
            modules={[Navigation, Pagination]}
            speed={500}
            breakpoints={{
              59: {
                slidesPerView: 1.5,
                spaceBetween: 20,
              },
              560: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              912: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 25,
              },
            }}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onBeforeInit={swiper => {
              swiper.params.navigation.prevEl = navigationPrevRef.current
              swiper.params.navigation.nextEl = navigationNextRef.current
            }}
            onSlideNextTransitionStart={() => setPrev(true)}
            onReachBeginning={() => setPrev(false)}
            onSlidePrevTransitionStart={() => setNex(true)}
            onReachEnd={() => setNex(false)}
            className={styles.mySwiper_block}
          >
            {hotels.map(item => (
              <SwiperSlide key={item._id} className={styles.box_image}>
                <Link to={`/rooms/${item._id}`} className={styles.image}>
                  <img src={item.images[0].url} alt='couverture' />
                </Link>
                <Link to={`/rooms/${item._id}`}>
                  <div className={styles.recomande_desc}>
                    <div>
                      <CardComment item={item} />
                      <div className={styles.descrip_detail}>
                        {item.accommodation_type === TYPE_LOGEMENT.ENTIRE_HOME && (
                          <p>
                            Logement entier ·{' '}
                            {item.address?.state || item.address?.city || item.address?.country}
                          </p>
                        )}
                        {item.accommodation_type === TYPE_LOGEMENT.PRIVATE_ROOM && (
                          <p>
                            Chambre privée ·{' '}
                            {item.address?.state || item.address?.city || item.address?.country}
                          </p>
                        )}
                        {item.accommodation_type === TYPE_LOGEMENT.SHARED_ROOM && (
                          <p>
                            Chambre partagée ·{' '}
                            {item.address?.state || item.address?.city || item.address?.country}
                          </p>
                        )}
                      </div>
                      <h5>{item.title}</h5>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
            <div
              ref={navigationPrevRef}
              className={prev ? styles.prev : `${styles.prev} ${styles.disable}`}
            >
              <svg
                viewBox='0 0 32 32'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
                style={{
                  display: 'block',
                  fill: 'none',
                  height: '12px',
                  width: '12px',
                  stroke: 'currentcolor',
                  strokeWidth: '4',
                  overflow: 'visible',
                }}
              >
                <g>
                  <path d='m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932'></path>
                </g>
              </svg>
            </div>
            <div
              ref={navigationNextRef}
              className={nex ? styles.next : `${styles.next} ${styles.disable}`}
            >
              <svg
                viewBox='0 0 32 32'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
                style={{
                  display: 'block',
                  fill: 'none',
                  height: '12px',
                  width: '12px',
                  stroke: 'currentcolor',
                  strokeWidth: '4',
                  overflow: 'visible',
                }}
              >
                <g>
                  <path d='m12 4 11.2928932 11.2928932c.3905243.3905243.3905243 1.0236893 0 1.4142136l-11.2928932 11.2928932'></path>
                </g>
              </svg>
            </div>
          </Swiper>
        </div>
      )}
    </>
  )
}

export default Annonces
