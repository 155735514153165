import { useEffect, useState, useContext } from 'react'
import styles from '../../../css/account/loginPage.module.css'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'
import { User } from '../../../context/UserContext'
import ContentLogin from '../../../components/login/contents/ContentLogin'
import DefaultLayout from '../../../layouts/DefaultLayout'
import FooterCategory from '../../../components/login/contents/FooterCategory'
import { CONNECTION_TYPE } from '../../../enums/user/connectionScreen'
import FooterLocation from '../../../components/login/contents/FooterLocation'

const LoginPage = () => {
  const [active, setActive] = useState(CONNECTION_TYPE.CONNECTION)
  const [getUser, setGetUser] = useState(null)
  const [categories, setCategories] = useState([])
  const [fullAddress, setFullAddress] = useState('')
  const [appartement, setAppartement] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [country, setCountry] = useState('')
  const [countryAbbreviation, setCountryAbbreviation] = useState('')
  const [coords, setCoords] = useState({})
  const [isValid, setIsValid] = useState(false)

  const user = useContext(User)
  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
    if (user) history.push('/')
  }, [history, user])

  const handleClose = () => {
    if (active === CONNECTION_TYPE.PROFIL_CATEGORY || active === CONNECTION_TYPE.PROFIL_LOCATION) {
      window.location.reload()
    }
  }

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    <DefaultLayout footer={true} mobileBottomNavigate={true}>
      <Helmet>
        <title>Connectez-vous / Gowifmi</title>
      </Helmet>
      <div className={styles.main}>
        <div className={styles.container}>
          <ContentLogin
            active={active}
            setActive={setActive}
            setGetUser={setGetUser}
            scrollToTop={scrollToTop}
            categories={categories}
            setCategories={setCategories}
            fullAddress={fullAddress}
            setFullAddress={setFullAddress}
            appartement={appartement}
            setAppartement={setAppartement}
            city={city}
            setCity={setCity}
            state={state}
            setState={setState}
            postalCode={postalCode}
            setPostalCode={setPostalCode}
            country={country}
            setCountry={setCountry}
            setCountryAbbreviation={setCountryAbbreviation}
            setCoords={setCoords}
            setIsValid={setIsValid}
          />
        </div>
        {active === CONNECTION_TYPE.PROFIL_CATEGORY && (
          <FooterCategory setActive={setActive} categories={categories} getUser={getUser} />
        )}
        {active === CONNECTION_TYPE.PROFIL_LOCATION && (
          <FooterLocation
            setActive={setActive}
            fullAddress={fullAddress}
            appartement={appartement}
            city={city}
            state={state}
            countryAbbreviation={countryAbbreviation}
            postalCode={postalCode}
            country={country}
            coords={coords}
            getUser={getUser}
            handleClose={handleClose}
            isValid={isValid}
          />
        )}
      </div>
    </DefaultLayout>
  )
}

export default LoginPage
