import styles from '../../../css/details/other_hotel.module.css'
import SliderMobile from './mobiles/SliderMobile'

const AnnonceMobile = ({ hotel }) => {
  return (
    <div className={styles.swiper_mobile}>
      <div className={styles.colone} aria-hidden={true} style={{ flex: '0 0 10px' }}></div>
      {hotel.map(item => (
        <SliderMobile key={item._id} item={item} />
      ))}
      <div className={styles.colone} aria-hidden={true} style={{ flex: '0 0 10px' }}></div>
    </div>
  )
}

export default AnnonceMobile
