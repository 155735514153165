import styles from '../../../../../css/search_room_result/more_actions/show_more.module.css'
import moment from 'moment'
import { addDays } from 'date-fns'
import { Link } from 'react-router-dom'

const ButtonBlock = ({ item }) => {
  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  const enfant = Number(Params.get('children')) || 0
  const adulte = Number(Params.get('adults')) || 1
  const bebe = Number(Params.get('infants')) || 0
  const animal = Number(Params.get('pets')) || 0
  const checkin = Params.get('checkin') ?? moment(new Date()).format('YYYY-MM-DD')
  const checkout = Params.get('checkout') ?? moment(addDays(new Date(), 1)).format('YYYY-MM-DD')

  const tailWindow = window.matchMedia('(min-width:743px)')

  return (
    <div className={styles.block_btn}>
      <Link
        target={tailWindow.matches ? '_blank' : null}
        rel='noopener noreferrer'
        to={`/book/${item._id}?adults=${adulte}&children=${enfant}&infants=${bebe}&pets=${animal}&checkin=${checkin}&checkout=${checkout}`}
      >
        <p>Réserver</p>
      </Link>
      <Link
        target={tailWindow.matches ? '_blank' : null}
        rel='noopener noreferrer'
        to={`/rooms/${item._id}?adults=${adulte}&children=${enfant}&infants=${bebe}&pets=${animal}&checkin=${checkin}&checkout=${checkout}`}
      >
        <p>Détails de l'annonce</p>
      </Link>
    </div>
  )
}

export default ButtonBlock
