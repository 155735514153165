import { useState } from 'react'
import globalStyle from '../../../css/becomeHostes/globalStyle.module.css'
import styles from '../../../css/becomeHostes/title.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import axios from 'axios'
import LoadingPoint from '../../LoadingPoint'
import ErrorServer from '../errorServer/ErrorServer'
import useAddHome from '../../../hooks/useAddHome'
import { SATATUS_ANNONCE } from '../../../enums/logement/status.enums'
import HeaderForm from '../globals/HeaderForm'
import { useQuery } from 'react-query'

const Form = () => {
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState('')
  const [avantage, setAvantage] = useState('')
  const [serverError, setServerError] = useState(false)
  const { updateData } = useAddHome(setLoading, setServerError)

  const history = useHistory()
  const location = useLocation()
  const page = location.pathname.split('/')[2]
  const id = location.pathname.split('/')[3]

  const { isLoading, error } = useQuery(`get-hotel-on-creation-${id}}`, () =>
    axios.get(`/hotel/oneAnnonce/${id}`).then(res => {
      setTitle(res.data.title || 'Donnez un titre à votre annonce')
      setAvantage(res.data.advantage_marketing || '')
      if (res.data.status !== SATATUS_ANNONCE.IN_PROGRESS) {
        history.push('/hosting')
      }
    })
  )

  const handleSubmit = () => {
    setLoading(true)
    const datas = {
      redirect_url: page,
      title: title,
      advantage_marketing: avantage,
    }
    updateData(datas, '/become-a-host/description')
  }

  if (error || serverError) {
    return <ErrorServer />
  }

  return (
    <div className={globalStyle.left}>
      <HeaderForm step={8} />
      <div className={globalStyle.block_center}>
        <div className={globalStyle.block_form}>
          {isLoading ? (
            <LoadingPoint />
          ) : (
            <div className={globalStyle.annimcontent}>
              <div className={styles.title}>
                <h1>Donnez-lui un titre</h1>
                <textarea
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  placeholder='Paris : superbe appartement en résidence'
                  maxLength={50}
                ></textarea>
                <span>{title.length}/50</span>
              </div>
              <div className={styles.title}>
                <h1>Attirer les voyageurs</h1>
                <p>Pourquoi un voyageur devrait-il choisir de séjourner dans votre logement ?</p>
                <textarea
                  value={avantage}
                  onChange={e => setAvantage(e.target.value)}
                  placeholder='Example : Hébergement spacieux avec superbe vue et lumière naturelle'
                  maxLength={80}
                ></textarea>
                <span>{avantage.length}/80</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={globalStyle.step_buttons}>
        <button
          type='button'
          onClick={() => history.push(`/become-a-host/photos/${id}`)}
          className={globalStyle.retour}
        >
          Retour
        </button>
        {loading ? (
          <button disabled className={globalStyle.loading}>
            <LoadingPoint />
          </button>
        ) : (
          <button
            type='button'
            onClick={handleSubmit}
            disabled={!title}
            className={!title ? `${globalStyle.next} ${globalStyle.disable}` : globalStyle.next}
          >
            Suivant
          </button>
        )}
      </div>
    </div>
  )
}

export default Form
