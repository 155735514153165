import { useState } from 'react'
import globalStyle from '../../../css/becomeHostes/globalStyle.module.css'
import styles from '../../../css/becomeHostes/preview.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import axios from 'axios'
import LoadingPoint from '../../../components/LoadingPoint'
import ErrorServer from '../errorServer/ErrorServer'
import useAddHome from '../../../hooks/useAddHome'
import { SATATUS_ANNONCE } from '../../../enums/logement/status.enums'
import { HOST_STATUS_ACTIVITY } from '../../../enums/logement/host_status_activity.enums'
import { TYPE_LOGEMENT } from '../../../enums/logement/type_logement.enums'
import HeaderForm from '../globals/HeaderForm'
import { useQuery } from 'react-query'

const Card = () => {
  const [loading, setLoading] = useState(false)
  const [serverError, setServerError] = useState(false)
  const { updateToSaveStatus } = useAddHome(setLoading, setServerError)

  const history = useHistory()
  const location = useLocation()
  const page = location.pathname.split('/')[2]
  const id = location.pathname.split('/')[3]

  const {
    data = {},
    isLoading,
    error,
  } = useQuery(`get-hotel-on-creation-${id}}`, () => {
    return axios.get(`/hotel/oneAnnonce/${id}`).then(res => {
      if (res.data.status !== SATATUS_ANNONCE.IN_PROGRESS) {
        history.push('/hosting')
      }
      return res.data
    })
  })

  const handleSubmit = () => {
    setLoading(true)
    const datas = {
      redirect_url: page,
      status: SATATUS_ANNONCE.SAVE,
    }
    updateToSaveStatus(datas, '/become-a-host/publish-celebration')
  }

  if (error || serverError) {
    return <ErrorServer />
  }

  return (
    <div className={globalStyle.left}>
      <HeaderForm />
      <div className={styles.block_center}>
        <div className={styles.block_form}>
          {isLoading ? (
            <LoadingPoint />
          ) : (
            <div className={globalStyle.annimcontent}>
              <div className={styles.container}>
                <div className={styles.card}>
                  <div className={styles.card_content}>
                    <div className={styles.photo}>
                      {data?.images?.length > 0 && <img src={data?.images[0]?.url} alt='' />}
                    </div>
                    <div className={styles.descr}>
                      <div className={styles.title}>
                        <h1>{data.title || 'Donnez un titre à votre annonce'}</h1>
                        <p>{data.advantage_marketing}</p>
                      </div>
                      <div className={styles.profile}>
                        {data.accommodation_type === TYPE_LOGEMENT.ENTIRE_HOME && (
                          <h2>
                            Logement entier :{' '}
                            {data.about?.host_activity_status === HOST_STATUS_ACTIVITY.PARTICULAR
                              ? 'particulier'
                              : 'professionnel'}{' '}
                            ⸱ Hôte {data.user_id?.first_name}
                          </h2>
                        )}
                        {data.accommodation_type === TYPE_LOGEMENT.PRIVATE_ROOM && (
                          <h2>
                            Chambre privée :{' '}
                            {data.about?.host_activity_status === HOST_STATUS_ACTIVITY.PARTICULAR
                              ? 'particulier'
                              : 'professionnel'}{' '}
                            ⸱ Hôte {data.user_id?.first_name}
                          </h2>
                        )}
                        {data.accommodation_type === TYPE_LOGEMENT.SHARED_ROOM && (
                          <h2>
                            Chambre partagée :{' '}
                            {data.about?.host_activity_status === HOST_STATUS_ACTIVITY.PARTICULAR
                              ? 'particulier'
                              : 'professionnel'}{' '}
                            ⸱ Hôte {data.user_id?.first_name}
                          </h2>
                        )}
                        <div className={styles.pro_img}>
                          {data.user_id?.picture ? (
                            <img src={data.user_id?.picture} alt='' />
                          ) : (
                            <h3>{data.user_id?.first_name?.slice(0, 1)}</h3>
                          )}
                        </div>
                      </div>
                      <div className={styles.voyageur}>
                        <p>
                          {data.details?.traveler} voyageur · {data.details?.number_of_rooms}{' '}
                          chambre · {data.details?.number_of_beds} lit ·{' '}
                          {data.details?.number_of_bathrooms} salle de bain partagée
                        </p>
                      </div>
                      <div className={styles.aprops}>
                        {data.about?.presentation ? (
                          <p>{data.about?.presentation}</p>
                        ) : (
                          <p>
                            Décrivez votre logement aux voyageurs Indiquez les meilleurs atouts de
                            votre logement, ses équipements spéciaux tels qu'un wifi rapide ou une
                            place de stationnement, et ce qui vous plaît dans le quartier.
                          </p>
                        )}
                      </div>
                      <div className={styles.equipement}>
                        <h3>Équipements</h3>
                        {data.amenities?.includes('3') && <p>Piscine</p>}
                        {data.amenities?.includes('1') && <p>Wifi</p>}
                        {data.amenities?.includes('10') && <p>Télévision</p>}
                      </div>
                      <div className={styles.emplacement}>
                        <h3>Emplacement</h3>
                        <h4>{data.address?.full_address}</h4>
                        <p>
                          Votre adresse est uniquement communiquée aux voyageurs qui ont réservé,
                          comme indiqué dans notre Politique de confidentialité.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={globalStyle.step_buttons}>
        <button
          type='button'
          onClick={() => history.push(`/become-a-host/question/${id}`)}
          className={globalStyle.retour}
        >
          Retour
        </button>
        <button
          type='button'
          disabled={loading}
          onClick={handleSubmit}
          className={globalStyle.publish}
        >
          Enregistrer mon annonce
        </button>
      </div>
    </div>
  )
}

export default Card
