import styles from '../../../css/account/info_perso.module.css'
import LoadingPoint from '../../LoadingPoint'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import useUserHook from '../../../hooks/useUserHook'

const DateOfBirth = ({ active, chageActive }) => {
  const { userHook, loadingUpdate, updateUser, errorUpdate } = useUserHook(chageActive)
  const { register, handleSubmit } = useForm({ mode: 'onChange' })
  const onSubmit = data => {
    updateUser(data)
  }
  if (errorUpdate) alert('error')
  return (
    <>
      <div
        className={
          active && active !== 3 ? `${styles.form_content} ${styles.disable}` : styles.form_content
        }
      >
        <div className={styles.text}>
          <h3>Date de naissance</h3>
          {active === 3 ? (
            <button type='button' onClick={() => chageActive()}>
              Annuler
            </button>
          ) : (
            <button type='button' disabled={active && active !== 3} onClick={() => chageActive(3)}>
              Modifier
            </button>
          )}
        </div>

        <div className={styles.modif_block}>
          {active !== 3 ? (
            <p>
              {userHook.date_of_birth
                ? moment(userHook.date_of_birth).format('LL')
                : 'Information non fournie'}
            </p>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
              <div className={styles.input_box_sexe}>
                <input
                  type='date'
                  {...register('date_of_birth', { value: userHook.date_of_birth })}
                />
              </div>
              <div className={styles.footer_btn_block}>
                {loadingUpdate ? (
                  <button disabled className={styles.loading}>
                    <LoadingPoint />
                  </button>
                ) : (
                  <button type='submit'>Enregistrer</button>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  )
}

export default DateOfBirth
