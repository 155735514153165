import { useEffect, useContext, useState } from 'react'
import styles from '../../../css/modehotes/statistics/views_listings/statistic.module.css'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { User } from '../../../context/UserContext'
import LoadingPoint from '../../../components/LoadingPoint'
import HostModeLayout from '../../../layouts/HostModeLayout'
import ErrorServer from '../../errors/ErrorServer'
import { useQuery } from 'react-query'
import SelectDate from '../../../components/hote_mode/statistics/SelectDate'
import Header from '../../../components/hote_mode/statistics/Header'
import ListingHotel from '../../../components/hote_mode/statistics/ListingHotel'
import Description from '../../../components/hote_mode/statistics/Description'
import { Helmet } from 'react-helmet-async'

const ViewListing = () => {
  const [selectedListing, setSelectedListing] = useState('')
  const [selectedDate, setSelectedDate] = useState(() => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1 // Les mois sont indexés de 0 à 11, donc ajoutez 1
    return `${year}-${month.toString().padStart(2, '0')}` // 'YYYY-MM'
  })

  const user = useContext(User)
  const history = useHistory()

  const yearMonthDate = moment(selectedDate, 'YYYY-MM').format('MMMM YYYY')
  const yearDate = selectedDate.split('-')[0]

  useEffect(() => {
    window.scrollTo(0, 0)
    if (user === null) {
      history.push('/')
    }
  }, [history])

  const {
    data = {},
    isLoading,
    error,
  } = useQuery(['get-host_visit', selectedListing, selectedDate], () =>
    axios
      .get(
        `/statistics/host_visit?host_id=${user?.id}&hotel_id=${selectedListing}&date=${selectedDate}`
      )
      .then(res => {
        return res.data
      })
  )

  if (error) return <ErrorServer />

  return (
    <HostModeLayout>
      <Helmet>
        <title>Consultations - Gowifmi</title>
      </Helmet>
      <div className={styles.container}>
        <Header />
        <ListingHotel selectedListing={selectedListing} setSelectedListing={setSelectedListing} />

        {isLoading ? (
          <div className={styles.container_load_liste}>
            <LoadingPoint />
          </div>
        ) : (
          <div className={styles.main}>
            <SelectDate selectedDate={selectedDate} setSelectedDate={setSelectedDate} />

            <div className={styles.infos_block}>
              <div className={styles.infos_block_content}>
                <h2>{data.monthyTotalVisits}</h2>
                <p>Consultations {yearMonthDate}</p>
              </div>
              <div className={styles.infos_block_content}>
                <h2>{data.monthlyReservations}</h2>
                <p>Nouvelles réservations en {yearMonthDate}</p>
              </div>
              <div className={styles.infos_block_content}>
                <h2>{data.monthyReservationRate}%</h2>
                <div className={styles.infos_block_notice}>
                  <p>Taux de réservation {yearMonthDate}</p>
                  <Description
                    title={`Taux de réservation ${yearMonthDate}`}
                    description={`Le pourcentage de voyageurs qui réservent après avoir consulté votre annonce`}
                  />
                </div>
              </div>
            </div>

            <div className={styles.detail_block}>
              <div className={styles.detail_block_content}>
                <h2>{data.annualTotalVisits}</h2>
                <p>Consultations pour {yearDate}</p>
              </div>
              <div className={styles.detail_block_content}>
                <h2>{data.yearReservations}</h2>
                <p>Réservations effectuées en {yearDate}</p>
              </div>
              <div className={styles.detail_block_content}>
                <h2>{data.annualReservationRate}%</h2>
                <div className={styles.infos_block_notice}>
                  <p>Taux de réservation {yearDate}</p>
                  <Description
                    title={`Taux de réservation ${yearDate}`}
                    description={`Le pourcentage de voyageurs qui réservent après avoir consulté votre annonce`}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </HostModeLayout>
  )
}

export default ViewListing
