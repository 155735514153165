import styles from '../../../../css/search_room_result/filtre_2.module.css'
import { useSearchParams } from '../../../../context/SearchParamContext'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

const FilterPrice = ({ priceValue, setPriceValue, minPrice, maxPrice }) => {
  const { params, updateParams } = useSearchParams()

  const newParams = new URLSearchParams(params.toString())

  const handleChange = newValue => {
    setPriceValue([newValue[0], newValue[1]])
  }

  const handleSearchByPrice = newValue => {
    if (newValue[0] !== minPrice || newValue[1] !== maxPrice) {
      newParams.delete('page')
      newParams.set('min_price', newValue[0])
      newParams.set('max_price', newValue[1])
      updateParams(newParams)
    } else if (newParams.has('min_price') && newParams.has('max_price')) {
      newParams.delete('page')
      newParams.set('min_price', newValue[0])
      newParams.set('max_price', newValue[1])
      updateParams(newParams)
    } else {
      return
    }
  }

  return (
    <div className={styles.block_section_filter}>
      <div className={styles.block_top}>
        <h2>Fourchette de prix</h2>
        <p>Prix par nuit hors frais et taxes</p>
      </div>
      <div className={styles.filterPrix}>
        <div className={styles.slider_block}>
          <Slider
            range
            allowCross={false}
            draggableTrack
            value={priceValue}
            step={1}
            min={minPrice}
            max={maxPrice}
            onChange={handleChange}
            onChangeComplete={handleSearchByPrice}
          />
        </div>
        <div className={styles.price_min_max}>
          <div className={styles.prix_block}>
            <p>Prix minimum</p>
            <div className={styles.prix}>
              <p>$</p>
              <p>{priceValue[0]}</p>
            </div>
          </div>
          <div className={styles.prix_block}>
            <p>Prix maximum</p>
            <div className={styles.prix}>
              <p>$</p>
              <p>{priceValue[1] >= maxPrice ? `${priceValue[1]}+` : priceValue[1]}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FilterPrice
