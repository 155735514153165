import styles from '../../../css/becomeHostes/globalStyle.module.css'
import GowifmiLogoSvg from '../../svgs/GowifmiLogoSvg'
import { useSpring, animated } from '@react-spring/web'

const HeaderForm = ({ step }) => {
  const from = step - 1
  const spring = useSpring({
    from: { width: from === 0 ? '0%' : from + '0%' },
    to: { width: step + '0%' },
    config: { duration: 300 },
    // delay: 500,
  })

  return (
    <div className={styles.good_logo}>
      <a href='/'>
        <GowifmiLogoSvg />
      </a>
      {step && (
        <div>
          <h1>Étape</h1>
          <div className={styles.progress_bar_container}>
            <animated.span style={{ ...spring }} />
          </div>
        </div>
      )}
    </div>
  )
}

export default HeaderForm
