import { useCallback, useState } from 'react'
import styles from '../../../css/traveler/travel/header.module.css'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'

let timeoutId

const Header = ({ setSearch, setPage }) => {
  const [value, setValue] = useState()
  const location = useLocation()
  const path = location.pathname.split('/')[2]

  const handleSearch = useCallback(event => {
    const resultFormated = event.target.value.replace(/\D/g, '')
    setValue(resultFormated)
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      const resultFormated = event.target.value.replace(/\D/g, '')
      setSearch(resultFormated)
      setPage(1)
    }, 700)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  return (
    <div>
      <div className={styles.navigation}>
        <h1>Vos réservations</h1>
        <div className={styles.block_link_search}>
          <div className={styles.link_reservation}>
            <Link
              to='/reservations/upcoming'
              className={path === 'upcoming' ? styles.active : null}
            >
              À venir
            </Link>
            <Link
              to='/reservations/inprogress'
              className={path === 'inprogress' ? styles.active : null}
            >
              En cours
            </Link>
            <Link
              to='/reservations/completed'
              className={path === 'completed' ? styles.active : null}
            >
              Terminées
            </Link>
            <Link
              to='/reservations/canceled'
              className={path === 'canceled' ? styles.active : null}
            >
              Annulées
            </Link>
            <Link to='/reservations/all' className={path === 'all' ? styles.active : null}>
              Tout
            </Link>
          </div>
          <div className={styles.search}>
            <input
              type='tel'
              value={value}
              placeholder='Rechercher par référence'
              onChange={e => handleSearch(e)}
            />
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 32 32'
              aria-hidden='true'
              role='presentation'
              focusable='false'
              style={{
                display: 'block',
                fill: 'none',
                height: '16px',
                width: '16px',
                stroke: 'currentcolor',
                strokeWidth: '4',
                overflow: 'visible',
              }}
            >
              <path fill='none' d='M13 24a11 11 0 1 0 0-22 11 11 0 0 0 0 22zm8-3 9 9'></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
