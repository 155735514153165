import { Page, Text, View, Document, Image, Font, StyleSheet } from '@react-pdf/renderer'
import moment from 'moment'
import { addCurrencyComplet } from '../../../utils/addCurrency'

Font.register({
  family: 'Inter',
  fonts: [
    { src: '/fonts/Inter-Regular.ttf' },
    { src: '/fonts/Inter-Medium.ttf', fontWeight: 'medium' },
    { src: '/fonts/Inter-Bold.ttf', fontWeight: 'bold' },
  ],
})

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontFamily: 'Inter',
    fontSize: 12,
    position: 'relative',
    minHeight: '100vh',
  },
  headerLogo: {
    width: 150,
    height: 37,
    marginBottom: 30,
  },
  headerTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  flex_section: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
  },
  flex_item: {
    minWidth: 100,
  },
  section: {
    paddingTop: 20,
    paddingBottom: 20,
    borderTop: '1px solid #ebebeb',
  },
  section_item: {
    paddingTop: 15,
    paddingBottom: 20,
  },
  title: {
    fontSize: 10,
    marginTop: 2,
    marginBottom: 2,
    fontWeight: 'medium',
  },
  detail: {
    marginTop: 3,
    fontWeight: 'normal',
    fontSize: 10,
    color: '#555555',
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 30,
    right: 30,
    borderTop: '1px solid #ebebeb',
    paddingTop: 15,
  },
})

const SucceedTemplatePDF = ({ reservation, user, hotel }) => (
  <Document>
    <Page size='A4' style={styles.page}>
      <View>
        <Image src='/img/gowifmiLogoPng.png' style={styles.headerLogo} />
        <Text style={styles.headerTitle}>Reçu de réservation</Text>
      </View>

      <View>
        <Text style={styles.title}>
          ID de la réservation{'   '}
          {reservation._id}
        </Text>
        <Text style={styles.title}>
          Référence{'   '}
          {reservation.reservation_infos.reference}
        </Text>
        <Text style={styles.title}>
          Date du paiement{'   '}
          {moment(reservation.createdAt).format('ll')}
        </Text>
        <View style={styles.section_item}>
          <Text style={styles.title}>Reçus de</Text>
          <Text style={styles.detail}>{user.first_name}</Text>
          <Text style={styles.detail}>{user.phone_number}</Text>
          <Text style={styles.detail}>{user.email}</Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={styles.flex_section}>
          <View style={styles.flex_item}>
            <Text style={styles.title}>Voyageurs</Text>
            <Text style={styles.detail}>
              Adultes : {reservation.reservation_infos.total_adults}
            </Text>
            <Text style={styles.detail}>
              Enfants : {reservation.reservation_infos.total_children}
            </Text>
            <Text style={styles.detail}>Bébés : {reservation.reservation_infos.total_babies}</Text>
            <Text style={styles.detail}>Animaux : {reservation.reservation_infos.total_pets}</Text>
          </View>
          <View style={styles.flex_item}>
            <Text style={styles.title}>Dates</Text>
            <Text style={styles.detail}>
              Arrivée : {moment(reservation.reservation_infos.checkin).format('ll')}
            </Text>
            <Text style={styles.detail}>
              Départ : {moment(reservation.reservation_infos.checkout).format('ll')}
            </Text>
            <Text style={styles.detail}>
              Durée du séjour : {reservation.reservation_infos.number_of_nights} nuits
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.title}>Détails du prix</Text>
        <Text style={styles.detail}>
          Total :{' '}
          {addCurrencyComplet(reservation.prices.total_stay_fee, reservation.prices.currency)}
        </Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.title}>Adresse de l'hébergement</Text>
        <Text style={styles.detail}>{hotel.address.full_address}</Text>
      </View>

      <View style={styles.footer}>
        <Text style={styles.detail}>Gowifmi</Text>
        <Text style={styles.detail}>2201 MENAUL BLVD NE STE A</Text>
        <Text style={styles.detail}>Albuquerque, New Mexico 87107</Text>
        <Text style={styles.detail}>United States</Text>
        <Text style={styles.detail}>contact@gowifmi.com</Text>
      </View>
    </Page>
  </Document>
)

export default SucceedTemplatePDF
