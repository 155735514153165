import styles from '../../css/home/section1.module.css'
import photo from '../../assets/profile/home.png'

const Section1 = () => {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.img_block}>
          <img src={photo} alt='' />
        </div>
        <div className={styles.droit}>
          <h3>Explorer un nouveau monde.</h3>
          <p>
            Peu importe où vous voulez aller dans le monde, nous pouvons vous aider à y arriver.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Section1
