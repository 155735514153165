import { useState } from 'react'
import styles from '../../../../../css/account/locations/address.module.css'
import Geocode from 'react-geocode'
import { googleGeocodingApiKey } from '../../../../../config/config'
import LoadingPoint from '../../../../LoadingPoint'

const CurrentAdresse = ({
  setFullAddress,
  setCity,
  setPostalCode,
  setState,
  setCountry,
  setCountryAbbreviation,
  setCoords,
  setIsValid,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  Geocode.setApiKey(googleGeocodingApiKey)
  Geocode.setLanguage('fr')
  Geocode.setLocationType('ROOFTOP')

  const currentPotion = () => {
    setIsLoading(true)
    navigator.geolocation.getCurrentPosition(
      position => {
        setCoords({ lat: position.coords.latitude, lng: position.coords.longitude })
        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude)
          .then(res => {
            setIsValid(true)
            setFullAddress(res.results[0].formatted_address)
            for (let i = 0; i < res.results[0].address_components.length; i++) {
              for (let j = 0; j < res.results[0].address_components[i].types.length; j++) {
                switch (res.results[0].address_components[i].types[j]) {
                  case 'locality':
                    setCity(res.results[0].address_components[i].long_name)
                    break
                  case 'postal_code':
                    setPostalCode(res.results[0].address_components[i].long_name)
                    break
                  case 'administrative_area_level_1':
                    setState(res.results[0].address_components[i].long_name)
                    break
                  case 'country':
                    setCountry(res.results[0].address_components[i].long_name)
                    setCountryAbbreviation(res.results[0].address_components[i].short_name)
                    break
                  default:
                }
              }
            }
          })
          .catch(() => {
            alert("La position n'a pas pu être déterminée.")
          })
          .finally(() => {
            setIsLoading(false)
          })
      },
      () => {
        alert(
          'Nous ne parvenons pas à détecter votre emplacement. Essayez de nouveau ou saisissez votre adresse.'
        )
        setIsLoading(false)
      }
    )
  }

  return (
    <div className={styles.current_location}>
      {isLoading ? (
        <LoadingPoint />
      ) : (
        <button type='button' onClick={currentPotion}>
          <div className={styles.position}>
            <svg
              viewBox='0 0 18 18'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'
              role='presentation'
              focusable='false'
            >
              <path d='m10.8431856 16.3486078c-.1735846.4112215-.6145289.7245188-1.05402708.6364803-.43949821-.0880384-.78907341-.5390778-.78915852-.9850881v-7l-7.05847799-.00695887c-.44831896-.00008467-.83440826-.31461578-.92290244-.75185074-.08849417-.43723497.14515025-.87590858.55850035-1.04859923l13.11043368-5.11686739c.3538762-.15082918.7644025-.07215022 1.0366284.19867383.272226.27082405.3513122.67923635.1997023 1.03129017z'></path>
            </svg>
          </div>
          <p>Utiliser mon emplacement actuel</p>
        </button>
      )}
    </div>
  )
}

export default CurrentAdresse
