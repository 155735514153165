import { useState } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import Options from './Options'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const AvailabileRange = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [plageDisponibilite, setPlageDisponibilite] = useState(Number())
  const { loading, updateData } = useUpdateHotel(setOpen)

  const delai = annonce.availability_calendar?.availability_range
  const handleSubmit = () => {
    const datas = {
      availability_calendar: {
        ...annonce.availability_calendar,
        availability_range: Number(plageDisponibilite),
      },
    }
    updateData(datas)
  }
  const openFuction = () => {
    setPlageDisponibilite(annonce.availability_calendar?.availability_range)
    setOpen(true)
  }
  const reset = () => {
    setPlageDisponibilite()
    setOpen(false)
  }
  return (
    <div className={styles.block}>
      {!open ? (
        <Section title='Plage de disponibilité' handleOpen={openFuction}>
          {delai === 36 && <p>Toutes les dates à venirs</p>}
          {delai === 12 && <p>12 mois à l'avance</p>}
          {delai === 9 && <p>9 mois à l'avance</p>}
          {delai === 6 && <p>6 mois à l'avance</p>}
          {delai === 3 && <p>3 mois à l'avance</p>}
          {delai === 0 && <p>Dates indisponible par défaut</p>}
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title='Plage de disponibilité' handleReset={reset}>
            {Number(plageDisponibilite) === 36 && (
              <p>Les voyageurs potentiels peuvent réserver toutes les dates à venir.</p>
            )}
            {Number(plageDisponibilite) === 12 && (
              <p>Votre logement sera indisponible dans 1 an à compter de ce mois-ci.</p>
            )}
            {Number(plageDisponibilite) === 9 && (
              <p>Votre logement sera indisponible dans 9 mois à compter de ce mois-ci.</p>
            )}
            {Number(plageDisponibilite) === 6 && (
              <p>Votre logement sera indisponible dans 6 mois à compter de ce mois-ci.</p>
            )}
            {Number(plageDisponibilite) === 3 && (
              <p>Votre logement sera indisponible dans 3 mois à compter de ce mois-ci.</p>
            )}
            {Number(plageDisponibilite) === 0 && (
              <p>
                Toutes les dates de votre calendrier seront indisponibles jusqu'à ce que vous les
                débloquiez.
              </p>
            )}
          </ContenHead>
          <div className={styles.body}>
            <div className={styles.input_block}>
              <select
                defaultValue={plageDisponibilite}
                onChange={e => setPlageDisponibilite(e.target.value)}
              >
                <Options />
              </select>
            </div>
          </div>
          <BottomForm
            handleSubmit={handleSubmit}
            disabled={plageDisponibilite === annonce.availability_calendar?.availability_range}
            loading={loading}
            handleReset={reset}
          />
        </div>
      )}
    </div>
  )
}

export default AvailabileRange
