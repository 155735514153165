import React, { useState } from 'react'
import styles from '../../../../../../../css/traveler/travel/modifs/voyageur.module.css'
import axios from 'axios'
import Toast from '../../../../../../infos/toast'
import LoadingPoint from '../../../../../../LoadingPoint'
import Modal from '../../../../../../ui/Modal'
import CloseButton from '../../../../../../ui/CloseButton'
import { DecrementCounter, IncrementCounter } from '../../../../../../svgs/TravelerCounter'

const VoyageurModal = React.forwardRef(({ reservation, refetch, id }, ref) => {
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [adulte, setAdulte] = useState(parseInt(reservation.reservation_infos?.total_adults))
  const [enfant, setEnfant] = useState(parseInt(reservation.reservation_infos?.total_children))
  const [bebe, setBebe] = useState(parseInt(reservation.reservation_infos?.total_babies))
  const [animal, setAnimal] = useState(parseInt(reservation.reservation_infos?.total_pets))

  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      setModal(true)
    },
  }))

  const hotel = reservation?.hotel
  const limiteMax = hotel?.details?.traveler

  const incrementeAdulte = () => {
    setAdulte(c => c + 1)
  }
  const decrementeAdulte = () => {
    setAdulte(c => c - 1)
  }
  const incrementeEnfant = () => {
    setEnfant(c => c + 1)
  }
  const decrementeEnfant = () => {
    setEnfant(c => c - 1)
  }
  const incrementeBebe = () => {
    setBebe(c => c + 1)
  }
  const decrementeBebe = () => {
    setBebe(c => c - 1)
  }
  const incrementeAnimal = () => {
    setAnimal(c => c + 1)
  }
  const decrementeAnimal = () => {
    setAnimal(c => c - 1)
  }

  const updateVoyageur = () => {
    const data = {
      reservation_infos: {
        ...reservation.reservation_infos,
        total_adults: adulte,
        total_children: enfant,
        total_babies: bebe,
        total_pets: animal,
      },
    }
    axios
      .patch(`/user_reservations/update_traveler/${id}`, data)
      .then(async () => {
        Toast.fire({
          icon: 'success',
          title: 'Votre modification a été effectuée avec succès.',
        })
        await refetch()
      })
      .catch(() => {
        Toast.fire({ icon: 'error', title: 'Un problème est survenu au nuveau de serveur' })
      })
      .finally(() => {
        setModal(false)
        setLoading(false)
      })
  }

  const closeModal = () => {
    setModal(false)
    setAdulte(reservation.reservation_infos?.total_adults)
    setEnfant(reservation.reservation_infos?.total_children)
    setBebe(reservation.reservation_infos?.total_babies)
    setAnimal(reservation.reservation_infos?.total_pets)
  }

  return (
    <>
      <Modal isOpen={modal} onClose={closeModal}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={() => setModal(false)} />
            <h3>Modifier les voyageurs</h3>
            <div></div>
          </div>
          <div className={styles.m_body}>
            <p className={styles.notice}>
              La capacité d'accueil de ce logement est limitée à {limiteMax}{' '}
              {limiteMax > 1 ? 'voyageurs' : 'voyageur'}, sans compter les bébés.
            </p>
            <div className={styles.block_flex}>
              <div>
                <h3>Adultes</h3>
              </div>
              <div className={styles.occup_count}>
                <button
                  type='button'
                  disabled={adulte === 1 ? true : false}
                  className={adulte === 1 ? styles.disable : null}
                  onClick={decrementeAdulte}
                >
                  <DecrementCounter />
                </button>
                <span>{adulte}</span>
                <button
                  type='button'
                  disabled={adulte >= limiteMax - enfant}
                  className={adulte >= limiteMax - enfant ? styles.disable : null}
                  onClick={incrementeAdulte}
                >
                  <IncrementCounter />
                </button>
              </div>
            </div>
            <div className={styles.block_flex}>
              <div>
                <h3>Enfants</h3>
                <p>De 2 à 12 ans</p>
              </div>
              <div className={styles.occup_count}>
                <button
                  type='button'
                  disabled={enfant === 0 ? true : false}
                  className={enfant === 0 ? styles.disable : null}
                  onClick={decrementeEnfant}
                >
                  <DecrementCounter />
                </button>
                <span>{enfant}</span>
                <button
                  type='button'
                  disabled={
                    enfant >= limiteMax - adulte ||
                    enfant === 10 ||
                    !hotel.regulations?.accept_children
                  }
                  className={
                    enfant >= limiteMax - adulte ||
                    enfant === 10 ||
                    !hotel.regulations?.accept_children
                      ? styles.disable
                      : null
                  }
                  onClick={incrementeEnfant}
                >
                  <IncrementCounter />
                </button>
              </div>
            </div>
            <div className={styles.block_flex}>
              <div>
                <h3>Bébés</h3>
                <p>- de 2 ans</p>
              </div>
              <div className={styles.occup_count}>
                <button
                  type='button'
                  disabled={bebe === 0 ? true : false}
                  className={bebe === 0 ? styles.disable : null}
                  onClick={decrementeBebe}
                >
                  <DecrementCounter />
                </button>
                <span>{bebe}</span>
                <button
                  type='button'
                  disabled={bebe >= 5 || !hotel.regulations?.accept_babies ? true : false}
                  className={
                    bebe === 5 || !hotel.regulations?.accept_babies ? styles.disable : null
                  }
                  onClick={incrementeBebe}
                >
                  <IncrementCounter />
                </button>
              </div>
            </div>
            <div className={styles.block_flex}>
              <div>
                <h3>Animaux domestiques</h3>
              </div>
              <div className={styles.occup_count}>
                <button
                  type='button'
                  disabled={animal === 0 ? true : false}
                  className={animal === 0 ? styles.disable : null}
                  onClick={decrementeAnimal}
                >
                  <DecrementCounter />
                </button>
                <span>{animal}</span>
                <button
                  type='button'
                  disabled={animal >= 5 || !hotel.regulations?.accept_pets ? true : false}
                  className={
                    animal === 5 || !hotel.regulations?.accept_pets ? styles.disable : null
                  }
                  onClick={incrementeAnimal}
                >
                  <IncrementCounter />
                </button>
              </div>
            </div>
          </div>
          <div className={styles.btn_action}>
            <button onClick={closeModal}>Fermer</button>
            {loading ? (
              <button disabled className={styles.loading}>
                <LoadingPoint />
              </button>
            ) : (
              <button onClick={updateVoyageur}>Appliquer</button>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
})
export default VoyageurModal
