const Options = () => {
  const Array = [
    { name: 'Toutes les dates à venirs', value: '36' },
    { name: "12 mois à l'avance", value: '12' },
    { name: "9 mois à l'avance", value: '9' },
    { name: "6 mois à l'avance", value: '6' },
    { name: "3 mois à l'avance", value: '3' },
    { name: 'Dates indisponible par défaut', value: '0' },
  ]
  return (
    <>
      {Array.map(item => (
        <option value={item.value} key={item.value}>
          {item.name}
        </option>
      ))}
    </>
  )
}

export default Options
