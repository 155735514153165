import styles from '../../../css/account/info_perso.module.css'
import LoadingPoint from '../../LoadingPoint'
import { useForm } from 'react-hook-form'
import useUserHook from '../../../hooks/useUserHook'

const Gender = ({ active, chageActive }) => {
  const { userHook, loadingUpdate, updateUser, errorUpdate } = useUserHook(chageActive)
  const { register, handleSubmit } = useForm({ mode: 'onChange' })
  const onSubmit = data => {
    updateUser(data)
  }
  if (errorUpdate) alert('error')
  return (
    <>
      <div
        className={
          active && active !== 2 ? `${styles.form_content} ${styles.disable}` : styles.form_content
        }
      >
        <div className={styles.text}>
          <h3>Sexe</h3>
          {active === 2 ? (
            <button type='button' onClick={() => chageActive()}>
              Annuler
            </button>
          ) : (
            <button type='button' disabled={active && active !== 2} onClick={() => chageActive(2)}>
              Modifier
            </button>
          )}
        </div>

        <div className={styles.modif_block}>
          {active !== 2 ? (
            <>
              {userHook.gender ? (
                <>
                  {userHook.gender === 'man' && <p>Homme</p>}
                  {userHook.gender === 'woman' && <p>Woman</p>}
                  {userHook.gender === 'other' && <p>Autre</p>}
                </>
              ) : (
                <p>Information non fournie</p>
              )}
            </>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.input_box_sexe}>
                <select {...register('gender', { value: userHook.gender })}>
                  <option value='man'>Homme</option>
                  <option value='woman'>Femme</option>
                  <option value='other'>Autre</option>
                </select>
              </div>
              <div className={styles.footer_btn_block}>
                {loadingUpdate ? (
                  <button disabled className={styles.loading}>
                    <LoadingPoint />
                  </button>
                ) : (
                  <button type='submit'>Enregistrer</button>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  )
}

export default Gender
