import { useContext } from 'react'
import { useLocation } from 'react-router'
import moment from 'moment'
import axios from 'axios'
import usePrice from './usePrice'
import { User } from '../context/UserContext'
import { useCalcDiffTwoDate } from './useCalcDiffTwoDate'
import { freeModeDays } from '../config/config'
import { STATUS_BOOKING } from '../enums/booking/status.enums'
import { PROCESSING_BOOKING } from '../enums/booking/processing.enums'
import { isValidPhoneNumber } from 'react-phone-number-input'

function useCreateReservation({
  hotel,
  payOnline,
  paymentIntent_id = '',
  setupIntent_id = '',
  HandleLoading = () => {},
  errorHandle = () => {},
}) {
  const { numberOfNight } = usePrice(hotel)

  const user = useContext(User)
  const location = useLocation()
  const hotel_id = location.pathname.split('/')[2]
  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  const adulte = Number(Params.get('adults')) || 0
  const enfant = Number(Params.get('children')) || 0
  const bebe = Number(Params.get('infants')) || 0
  const animal = Number(Params.get('pets')) || 0
  const checkin = Params.get('checkin')
  const checkout = Params.get('checkout')

  const idunique = Math.floor(Math.random() * 899999 + 100000)
  const { total_days } = useCalcDiffTwoDate(hotel?.gowifmi_publish_date, new Date())

  const createReservation = async (
    statut,
    telephone,
    message,
    setMessageError,
    setPhoneError,
    setLoading
  ) => {
    errorHandle(null)
    setPhoneError(false)
    setMessageError(false)
    if (!user?.isEmailVerified) {
      errorHandle('Veuillez confirmer votre adresse e-mail pour continuer.')
      return false
    } else if (!telephone || !isValidPhoneNumber(telephone)) {
      errorHandle('Ajoutez votre numéro de téléphone pour continuer.')
      setPhoneError(true)
      return false
    } else if (message.trim().length === 0) {
      errorHandle("Envoyez un message à l'hôte pour continuer.")
      setMessageError(true)
      return false
    } else {
      HandleLoading(true)
      setLoading(true)
      const data = {
        paymentIntent_id: paymentIntent_id,
        setupIntent_id: setupIntent_id,
        pay_online: payOnline,
        booking_processing: payOnline ? PROCESSING_BOOKING.INCOMPLETE : PROCESSING_BOOKING.COMPLETE,
        message: message,
        free_mode: total_days < freeModeDays ? true : false,
        status: statut,
        client_infos: {
          phone: telephone,
          client_country: user?.country,
        },
        current_date: moment(new Date()).format('YYYY-MM-DD'),
        reservation_infos: {
          total_adults: adulte,
          total_children: enfant,
          total_babies: bebe,
          total_pets: animal,
          checkin: moment(checkin).format('YYYY-MM-DD'),
          checkout: moment(checkout).format('YYYY-MM-DD'),
          number_of_nights: numberOfNight,
          reference: idunique.toString(),
        },
        conditions: {
          check_in_1: hotel.regulations?.check_in_1,
          check_in_2: hotel.regulations?.check_in_2,
          check_out: hotel.regulations?.check_out,
          smoking_allowed: hotel.regulations?.smoking_allowed || false,
          pets_allowed: hotel.regulations?.accept_pets || false,
          cancellation_policy: hotel.regulations?.cancellation_policy,
          accept_children: hotel.regulations?.accept_children,
          accept_babies: hotel.regulations?.accept_babies,
          event_allowed: hotel.regulations?.accept_events,
          accommodation_rules: hotel.regulations?.accommodation_rules,
        },

        availability_calendar: {
          preparation_time: hotel.availability_calendar?.preparation_time,
        },
        images: hotel.images,
      }
      const url =
        statut === STATUS_BOOKING.REQUEST ? 'user_reservations/request' : 'user_reservations/direct'
      return axios
        .post(`/${url}/${user.id}/${hotel.user_id.id}/${hotel_id}`, data)
        .then(res => {
          return res.data._id
        })
        .catch(err => {
          HandleLoading(false)
          setLoading(false)
          if (err.response?.data?.message === 'dates_exist') {
            errorHandle(`Ce logement semble déjà réservé aux dates demandées. Modifier vos dates pour obtenir des${' '}
              prix actualisés ou recherchez un autre hébergement.`)
          } else {
            errorHandle('Veuillez réessayer.')
          }
          return false
        })
    }
  }
  return { createReservation }
}
export default useCreateReservation
