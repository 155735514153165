import { useState, useEffect } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import Options from './Options'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const PanoramicView = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [vuePanoramique, setVuePanoramique] = useState([])
  const { loading, updateData } = useUpdateHotel(setOpen)

  const views = annonce.details?.panoramic_views
  const handleSubmit = () => {
    const datas = {
      details: {
        ...annonce.details,
        panoramic_views: vuePanoramique,
      },
    }
    updateData(datas)
  }
  useEffect(() => {
    setVuePanoramique(annonce.details?.panoramic_views)
  }, [annonce])
  const openFuction = () => {
    setVuePanoramique(annonce.details?.panoramic_views)
    setOpen(true)
  }
  const reset = () => {
    setVuePanoramique()
    setOpen(false)
  }
  return (
    <div className={styles.block}>
      {!open ? (
        <Section title='Vues panoramiques' handleOpen={openFuction}>
          {views?.length > 0 ? (
            <>
              {views.includes('1') && <p>Vue sur la baie,</p>}
              {views.includes('2') && <p>Vue sur la plage,</p>}
              {views.includes('3') && <p>Vue sur le canal,</p>}
              {views.includes('4') && <p>Vue panoramique sur la ville,</p>}
              {views.includes('5') && <p>Vue sur la cour,</p>}
              {views.includes('6') && <p>Vue sur le désert,</p>}
              {views.includes('7') && <p>Vue sur le jardin,</p>}
              {views.includes('8') && <p>Vue sur un parcours de golf,</p>}
              {views.includes('9') && <p>Vue sur le port,</p>}
              {views.includes('10') && <p>Vue sur le lac,</p>}
              {views.includes('11') && <p>Vue sur le port de plaisance,</p>}
              {views.includes('12') && <p>Vue sur la montagne,</p>}
              {views.includes('13') && <p>Vue sur l'océan,</p>}
              {views.includes('14') && <p>Vue sur le parc,</p>}
              {views.includes('15') && <p>Vue sur la piscine,</p>}
              {views.includes('16') && <p>Vue sur le complexe hôtelier,</p>}
              {views.includes('17') && <p>Vue sur la rivière,</p>}
              {views.includes('18') && <p>Vue sur la mer,</p>}
              {views.includes('19') && <p>Vue sur la vallée,</p>}
              {views.includes('20') && <p>Vue sur le vignoble,</p>}
            </>
          ) : (
            <p>Non défini</p>
          )}
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title='Vues panoramiques' handleReset={reset}>
            <p>Des vues qui ne manqueront pas d'impressionner tous les voyageurs.</p>
          </ContenHead>
          <div className={styles.body}>
            <div className={styles.input_block}>
              <Options vuePanoramique={vuePanoramique} setVuePanoramique={setVuePanoramique} />
            </div>
          </div>
          <BottomForm handleSubmit={handleSubmit} loading={loading} handleReset={reset} />
        </div>
      )}
    </div>
  )
}

export default PanoramicView
