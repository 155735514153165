import styles from '../../css/ui/button_tertiary.module.css'

const ButtonTertiary = ({ children, className, onClick, ...rest }) => {
  return (
    <button onClick={onClick} className={`${styles.button} ${className}`} {...rest}>
      {children}
    </button>
  )
}

export default ButtonTertiary
