import { useEffect, useState, useContext } from 'react'
import styles from '../../../css/account/info_perso.module.css'
import { useHistory } from 'react-router-dom'
import { User } from '../../../context/UserContext'
import LegaleName from '../../../components/accounts/info_perso/LegaleName'
import Gender from '../../../components/accounts/info_perso/Gender'
import DateOfBirth from '../../../components/accounts/info_perso/DateOfBirth'
import Email from '../../../components/accounts/info_perso/Email'
import Telephone from '../../../components/accounts/info_perso/Telephone'
import Address from '../../../components/accounts/info_perso/Address'
import DefaultLayout from '../../../layouts/DefaultLayout'
import AllAccountSetings from '../../../components/accounts/AllAccountSetings'
import IsCompanyStatus from '../../../components/accounts/info_perso/IsCompanyStatus'
import { Helmet } from 'react-helmet-async'

const Info_perso = () => {
  const [active, setActive] = useState()

  const user = useContext(User)
  const history = useHistory()
  useEffect(() => {
    window.scrollTo(0, 0)
    if (user === null) {
      history.push('/')
    }
  }, [])

  return (
    <DefaultLayout footer={true} navTopMobile={true}>
      <Helmet>
        <title>Informations personnelles paramètre Gowifmi</title>
      </Helmet>
      <div className={styles.seting_container}>
        <div className={styles.seting_box}>
          <div className={styles.AllAccount_setings}>
            <AllAccountSetings />
          </div>
          <div className={styles.separate}> </div>
          <div className={styles.seting_box_1}>
            <div>
              <h1>Informations personnelles</h1>
            </div>
            <LegaleName active={active} chageActive={setActive} />
            <Gender active={active} chageActive={setActive} />
            <DateOfBirth active={active} chageActive={setActive} />
            <Email active={active} chageActive={setActive} />
            <Telephone active={active} chageActive={setActive} />
            <Address active={active} chageActive={setActive} />
            <IsCompanyStatus />
          </div>
        </div>
        <div>
          <div className={styles.seting_box_2}>
            <h2>Quelles informations peuvent être modifiées ?</h2>
            <p className={styles.p_1}>
              Les informations utilisées par Gowifmi pour vérifier votre identité ne peuvent être
              modifiées. Les coordonnées et certaines données personnelles peuvent être modifiées,
              mais nous pourrions vous demander de vérifier votre identité la prochaine fois que
              vous réservez un logement ou créez une annonce.{' '}
              <a href='/verif-identity-policy' target='_blanck'>
                En savoir plus
              </a>
            </p>
            <h2>Lesquelles de mes informations sont communiquées à des tiers ?</h2>
            <p>
              Gowifmi ne communique les coordonnées aux hôtes et aux voyageurs qu'après la
              confirmation d'une réservation.
            </p>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}

export default Info_perso
