import styles from '../../../../../css/modehotes/annonce/details/securiteVoyageur.module.css'
import Checkbox from '../../../../ui/Checkbox'

const ShareSpace = ({ certainsEspacesPartages, setCertainsEspacesPartages }) => {
  return (
    <div className={styles.checkbox_container}>
      <div>
        <label htmlFor='certainsEspacesPartages'>Certains espaces sont partagés</label>
        <p>
          Les voyageurs doivent s'attendre à partager des espaces avec d'autres personnes pendant
          leur séjour. Par exemple : cuisine, salle de bain, patio.
        </p>
      </div>
      <div>
        <Checkbox
          id='certainsEspacesPartages'
          value={certainsEspacesPartages}
          checked={certainsEspacesPartages}
          onChange={() => setCertainsEspacesPartages(!certainsEspacesPartages)}
        />
      </div>
    </div>
  )
}

export default ShareSpace
