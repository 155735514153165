import { useRef } from 'react'
import styles from '../../../../../../css/traveler/travel/detailReservation.module.css'
import DateModal from './modifDisponibilites/DateModal'
import moment from 'moment'
import { STATUS_BOOKING } from '../../../../../../enums/booking/status.enums'
import { ONLINE_PAYMENT_STATUS } from '../../../../../../enums/booking/onlineStatus'

const DateVoyage = ({ reservation, refetch }) => {
  const { status } = reservation
  const modifications = reservation?.modifications[reservation?.modifications.length - 1]

  const modifierDate = useRef()
  const modifDateFunction = () => {
    if (
      reservation.pay_online &&
      reservation.payment_intent?.status !== ONLINE_PAYMENT_STATUS.SUCCEEDED
    ) {
      alert('Vous ne pouvez pas modifier la réservation.')
      return
    }
    modifierDate.current.OpenModal()
  }

  return (
    <>
      <div className={styles.content}>
        <div className={styles.modification}>
          <h3>Dates</h3>
          {status === STATUS_BOOKING.FUTURE || status === STATUS_BOOKING.IN_PROGRESS ? (
            <>
              {reservation.modifications?.length < 3 &&
              modifications?.modification_status !== STATUS_BOOKING.REQUEST ? (
                <button onClick={modifDateFunction}>Modifier</button>
              ) : null}
            </>
          ) : null}
        </div>
        <div className={styles.dates_block}>
          <div>
            <h5>Arrivée</h5>
            <p>{moment(reservation.reservation_infos?.checkin).format('ll')}</p>
          </div>
          <div>
            <h5>Départ</h5>
            <p>{moment(reservation.reservation_infos?.checkout).format('ll')}</p>
          </div>
        </div>
      </div>

      <div className={styles.content}>
        <h3>Durée totale du séjour</h3>
        <p>
          {reservation.reservation_infos?.number_of_nights}{' '}
          {reservation.reservation_infos?.number_of_nights > 1 ? 'Nuits' : 'Nuit'}
        </p>
      </div>
      <DateModal ref={modifierDate} reservation={reservation} refetch={refetch} />
    </>
  )
}

export default DateVoyage
