import { useRef, useState } from 'react'
import styles from '../../css/ui/accordion.module.css'

const DecrementCounter = () => {
  return (
    <svg
      viewBox='0 0 32 32'
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='presentation'
      focusable='false'
      style={{
        display: 'block',
        fill: 'none',
        height: '14px',
        width: '14px',
        stroke: 'currentcolor',
        strokeWidth: '5.33333',
        overflow: 'visible',
      }}
    >
      <path d='m2 16h28'></path>
    </svg>
  )
}
const IncrementCounter = () => {
  return (
    <svg
      viewBox='0 0 12 12'
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='presentation'
      focusable='false'
      style={{ display: 'block', height: '14px', width: '14px', fill: 'currentcolor' }}
    >
      <path d='m6.75.75v4.5h4.5v1.5h-4.5v4.5h-1.5v-4.5h-4.5v-1.5h4.5v-4.5z'></path>
    </svg>
  )
}

function Accordion({ title, content }) {
  const [active, setActive] = useState(false)
  const contentRef = useRef(null)
  const [height, setHeight] = useState('0px')

  function toggleAccordion() {
    setActive(!active)
    setHeight(active ? '0px' : `${contentRef.current.scrollHeight}px`)
  }

  return (
    <div
      className={
        active ? `${styles.accordion__section} ${styles.active}` : styles.accordion__section
      }
    >
      <div className={styles.accordion} onClick={toggleAccordion}>
        <h4 className={styles.accordion__title}>{title}</h4>
        <span>{active ? <DecrementCounter /> : <IncrementCounter />} </span>
      </div>
      <div
        ref={contentRef}
        style={{ maxHeight: `${height}` }}
        className={styles.accordion__content}
      >
        <p className={styles.accordion__text}>{content}</p>
      </div>
    </div>
  )
}

export default Accordion
