import React, { useState } from 'react'
import styles from '../../../css/signals/signal.module.css'
import Block1 from './Block1'
import Block2 from './Block2'
import Succes from './Succes'
import Modal from '../../ui/Modal'
import CloseButton from '../../ui/CloseButton'
import useModalByUrl from '../../../hooks/useModalByUrl'

const Login = React.forwardRef(({}, ref) => {
  const [active, setActive] = useState(1)
  const [raison1, setRaison1] = useState('')
  const [raison2, setRaison2] = useState('')

  const modalValue = 'REPPORT_HOTEL'
  const { modalState, handleOpenModal, handleCloseModal } = useModalByUrl(modalValue)
  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      handleOpenModal()
      setActive(1)
      setRaison1('')
      setRaison2('')
    },
  }))

  const handleclose = () => {
    handleCloseModal()
  }

  return (
    <>
      <Modal isOpen={modalState} onClose={handleclose}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={handleclose} />
            <div></div>
          </div>
          {active === 1 && (
            <Block1
              handleclose={handleclose}
              setActive={setActive}
              raison1={raison1}
              setRaison1={setRaison1}
            />
          )}
          {active === 2 && (
            <Block2
              setActive={setActive}
              raison1={raison1}
              raison2={raison2}
              setRaison2={setRaison2}
            />
          )}
          {active === 3 && <Succes handleclose={handleclose} />}
        </div>
      </Modal>
    </>
  )
})

export default Login
