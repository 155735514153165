import { useState } from 'react'
import styles from '../../css/ui/paragraphe_tronque.module.css'

const ParagrapheTronque = ({ text, lengthMax }) => {
  const [tronque, setTronque] = useState(true)
  const [longueur, setLongueur] = useState(lengthMax)

  const inferorLengthMax = text.length <= lengthMax

  const toggleTronquer = () => {
    setTronque(!tronque)
    setLongueur(tronque ? text.length : lengthMax)
  }

  return (
    <div className={styles.container}>
      {inferorLengthMax ? (
        <p>{text}</p>
      ) : (
        <p>
          {tronque ? `${text.slice(0, longueur)}...` : text}{' '}
          {!inferorLengthMax && (
            <span role='button' tabIndex={0} onClick={toggleTronquer}>
              {tronque ? 'Voir plus' : 'Voir moins'}
            </span>
          )}
        </p>
      )}
    </div>
  )
}

export default ParagrapheTronque
