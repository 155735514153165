import { Helmet } from 'react-helmet-async'
import styles from '../../css/about_us/about.module.css'
import DefaultLayout from '../../layouts/DefaultLayout'

function AffiliationPolicy() {
  return (
    <DefaultLayout footer={true}>
      <Helmet>
        <title>Politique d'affialition</title>
      </Helmet>

      <div className={styles.main}>
        <div className={styles.pourquoi_text}>
          <h2>Politique de parrainage des hôtes sur Gowifmi</h2>
          <h3>Introduction</h3>
          <p>
            Chez Gowifmi, nous valorisons votre engagement à faire grandir notre communauté d'hôtes.
            Pour vous remercier, nous avons mis en place un programme de parrainage qui vous permet
            de gagner 35% de notre commission sur chaque nouvel hôte que vous parrainez. Cette
            politique détaille les conditions et les bénéfices du programme de parrainage.
          </p>

          <div>
            <h3>Comment fonctionne le programme de parrainage ?</h3>
            <ol>
              <li>
                <strong>Invitation et publication :</strong> Invitez une personne à proposer son
                logement sur Gowifmi. Vous gagnerez de l'argent lorsqu'elle aura publié son annonce
                et honoré son premier séjour.
              </li>
              <li>
                <strong>Commission de parrainage :</strong> Vous recevrez 35% de notre commission
                sur chaque réservation effectuée par le nouvel hôte que vous avez parrainé.
              </li>
              <li>
                <strong>Exclusion des hôtes existants :</strong> Vous ne pouvez pas parrainer une
                personne qui est déjà un hôte sur Gowifmi ou vous auto-parrainer.
              </li>
            </ol>
          </div>

          <div>
            <h3>Conditions de paiement</h3>
            <ol>
              <li>
                <strong>Seuil de paiement :</strong> Gowifmi envoie le crédit gagné lorsque le
                montant atteint au minimum 15$.
              </li>
              <li>
                <strong>Limite de gain par parrainage :</strong> Le gain maximal par parrainage est
                de 100$. Pour les ambassadeurs de Gowifmi, cette limite est portée à 500$.
              </li>
              <li>
                <strong>Nombre illimité de parrainages :</strong> Vous pouvez parrainer un nombre
                illimité d'hôtes.
              </li>
              <li>
                <strong>Limite totale de gain :</strong> Le gain total pour tous les parrainages est
                limité à 5000$. Pour les ambassadeurs de Gowifmi, cette limite est de 100000$.
              </li>
            </ol>
          </div>

          <div>
            <h3>Exemple de scénario</h3>
            <p>
              Monsieur Dupont parraine Madame Leroy pour qu'elle propose son logement sur Gowifmi.
            </p>
            <ol>
              <li>
                <strong>Inscription et publication :</strong> Madame Leroy s'inscrit sur Gowifmi et
                publie son annonce.
              </li>
              <li>
                <strong>Premier séjour :</strong> Madame Leroy accueille son premier voyageur et
                complète le séjour.
              </li>
              <li>
                <strong>Commission de Gowifmi :</strong> Sur chaque réservation, Gowifmi prélève 14%
                du sous-total chez le voyageur et 3% chez l'hôte.
              </li>
              <li>
                <strong>Gains de parrainage :</strong> Monsieur Dupont reçoit 35% de la commission
                totale prélevée par Gowifmi sur les réservations de Madame Leroy, jusqu'à un maximum
                de 100$ pour ce parrainage.
              </li>
            </ol>
          </div>

          <div>
            <h3>Modalités de modification</h3>
            <p>
              <strong>Révisions de la politique :</strong> Gowifmi se réserve le droit de modifier
              cette politique à tout moment. Toute modification sera communiquée aux participants du
              programme de parrainage.
            </p>
          </div>

          <div>
            <h3>Conclusion</h3>
            <p>
              Le programme de parrainage de Gowifmi est conçu pour récompenser votre aide précieuse
              à agrandir notre communauté d'hôtes. En invitant de nouveaux hôtes à rejoindre
              Gowifmi, vous bénéficiez d'une partie de notre commission, tout en contribuant au
              développement de notre plateforme.
            </p>
            <p>
              Pour toute question ou assistance supplémentaire, n'hésitez pas à contacter notre
              service client.
            </p>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}
export default AffiliationPolicy
