import { useEffect, useContext } from 'react'
import styles from '../../../../css/traveler/travel/cancellation.module.css'
import axios from 'axios'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import { User } from '../../../../context/UserContext'
import LoadingPoint from '../../../../components/LoadingPoint'
import DefaultLayout from '../../../../layouts/DefaultLayout'
import ErrorServer from '../../../errors/ErrorServer'
import TitleHead from '../../../../components/traveler/travel/cancellation/TitleHead'
import WhyCancellation from '../../../../components/traveler/travel/cancellation/WhyCancellation'
import CancellationPolicy from '../../../../components/traveler/travel/cancellation/CancellationPolicy'
import { Helmet } from 'react-helmet-async'

const TravelerGoCancel = () => {
  const location = useLocation()
  const history = useHistory()
  const id = location.pathname.split('/')[3]
  const user = useContext(User)
  useEffect(() => {
    window.scrollTo(0, 0)
    if (user === null) {
      history.push('/')
    }
  }, [])

  const {
    data = {},
    isLoading,
    error,
  } = useQuery(`get-user_reservations-details-${id}`, () =>
    axios.get(`/user_reservations/${id}`).then(res => {
      return res.data
    })
  )

  if (error || !id) return <ErrorServer />

  return (
    <DefaultLayout footer={true} mobileBottomNavigate={true}>
      <Helmet>
        <title>Annuler votre réservation - Gowifmi</title>
      </Helmet>
      {isLoading ? (
        <div className={styles.container_load}>
          <LoadingPoint />
        </div>
      ) : (
        <div className={styles.voyage_container}>
          <TitleHead reservation={data.reservation} />
          <div className={styles.main}>
            <CancellationPolicy
              cancellation_policy={data?.reservation?.conditions?.cancellation_policy}
            />
            <WhyCancellation id={id} />
          </div>
        </div>
      )}
    </DefaultLayout>
  )
}

export default TravelerGoCancel
