import { useState, useContext } from 'react'
import styles from '../../../css/details/message.module.css'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import LoadingPoint from '../../LoadingPoint'
import Swal from 'sweetalert2'
import Succes from './Succes'
import useHotelRating from '../../../hooks/useHotelRating'
import Modal from '../../ui/Modal'
import CloseButton from '../../ui/CloseButton'
import { STATUS_MESSAGE } from '../../../enums/message/status.enums'
import { SocketContext } from '../../../context/SocketContext'

const SendMessage = ({ hotel, modalState, handleCloseModal, succes, setsucces }) => {
  const [loading, setLoading] = useState(false)
  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  const adulte_search = Number(Params.get('adults') || 0)
  const enfant_search = Number(Params.get('children') || 0)
  const bebe_search = Number(Params.get('infants') || 0)
  const animaux = Number(Params.get('pets') || 0)
  const checkin = Params.get('checkin')
  const checkout = Params.get('checkout')

  const { TotalCommunicationPourcentage } = useHotelRating(hotel)
  const { socket } = useContext(SocketContext)

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onChange' })
  const onSubmit = data => {
    setLoading(true)
    const datas = {
      text: data.text,
      status: STATUS_MESSAGE.REQUEST_INFO,
      adults: adulte_search,
      children: enfant_search,
      babies: bebe_search,
      pets: animaux,
      arrival: new Date(checkin),
      departure: new Date(checkout),
    }
    axios
      .post(`/message/${hotel._id}/${hotel.user_id?.id}`, datas)
      .then(() => {
        setsucces(true)
        reset()
        setLoading(false)
        socket.emit('sendMessage', {
          receiverId: hotel.user_id?.id,
          data: {},
        })
      })
      .catch(() => {
        Swal.fire('error', 'un probleme est sur venu au nuveau de server', 'error')
        reset()
        setLoading(false)
      })
  }

  return (
    <div>
      <Modal isOpen={modalState} onClose={handleCloseModal}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={handleCloseModal} />
            <div></div>
          </div>
          {!succes ? (
            <div className={styles.m_body}>
              <div className={styles.header}>
                <h3>Contactez {hotel.user_id?.first_name}</h3>
                <ul>
                  {hotel.user_id?.language_1 && <li>Langue: {hotel.user_id?.language_1}</li>}
                  {TotalCommunicationPourcentage > 0 && (
                    <li>Taux de réponse: {TotalCommunicationPourcentage}%</li>
                  )}
                </ul>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <label htmlFor='message_text'>Votre message</label>
                <textarea
                  className={errors.text && styles.erreur_text}
                  id='message_text'
                  {...register('text', {
                    required: 'Veuillez ajouter un message.',
                  })}
                ></textarea>
                {errors.text && <p className={styles.error}>{errors.text.message}</p>}

                {loading ? (
                  <button disabled className={`${styles.send} ${styles.loading}`}>
                    <LoadingPoint />
                  </button>
                ) : (
                  <button type='submit' className={styles.send}>
                    Envoyer le message
                  </button>
                )}
              </form>
            </div>
          ) : (
            <Succes handleCloseModal={handleCloseModal} />
          )}
        </div>
      </Modal>
    </div>
  )
}

export default SendMessage
