import { useState, useRef } from 'react'
import styles from '../../../../css/traveler/favoris/maps.module.css'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Navigation, Pagination } from 'swiper/modules'
import Swal from 'sweetalert2'
import useHotelRating from '../../../../hooks/useHotelRating'
import usePrice from '../../../../hooks/usePrice'
import { TYPE_LOGEMENT } from '../../../../enums/logement/type_logement.enums'
import StarSvg from '../../../svgs/StarSvg'

const CardMaps = ({ favoris, user, refetch, childClicked, setChildClicked }) => {
  const [prev, setPrev] = useState(false)
  const [nex, setNex] = useState(true)
  const location = useLocation()
  const id = location.pathname.split('/')[2]

  const { commentLength, totalComment } = useHotelRating(childClicked)
  const { pricePerNightWithoutPromo } = usePrice(childClicked)

  const tailWindow = window.matchMedia('(min-width:743px)')

  const deleteAnnonceInlist = () => {
    Swal.fire({
      title: 'Supprimer cette annonce ?',
      text: "Une fois l'annonce supprimée de la liste, vous ne pourrez plus la récupérer.",
      showCancelButton: true,
      confirmButtonText: "Supprimer l'annonce",
      cancelButtonText: 'Annuler',
      reverseButtons: true,
      customClass: {
        actions: styles.actions_class,
        confirmButton: styles.confirm_button_class,
        cancelButton: styles.cancel_button_class,
      },
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .put(`/favoris/delete/${id}/${childClicked._id}`)
          .then(() => {
            setChildClicked(null)
            refetch()
          })
          .catch(() => {})
      }
    })
  }

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  return (
    <>
      {childClicked && (
        <div className={styles.hotel_image}>
          <div className={styles.block_img}>
            <Swiper
              modules={[Navigation, Pagination]}
              pagination={{
                dynamicBullets: true,
              }}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onBeforeInit={swiper => {
                swiper.params.navigation.prevEl = navigationPrevRef.current
                swiper.params.navigation.nextEl = navigationNextRef.current
              }}
              onSlideNextTransitionStart={() => setPrev(true)}
              onReachBeginning={() => setPrev(false)}
              onSlidePrevTransitionStart={() => setNex(true)}
              onReachEnd={() => setNex(false)}
              className={styles.imgslider}
            >
              {childClicked.images.map((photo, i) => (
                <SwiperSlide key={i}>
                  <Link
                    target={tailWindow.matches ? '_blank' : null}
                    rel='noopener noreferrer'
                    to={`/rooms/${childClicked?._id}`}
                  >
                    <div>
                      <img src={photo.url} alt='photos' />
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
              <div
                ref={navigationPrevRef}
                className={prev ? styles.prev : `${styles.prev} ${styles.disable}`}
              >
                <svg
                  viewBox='0 0 32 32'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'
                  role='presentation'
                  focusable='false'
                >
                  <g>
                    <path d='m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932'></path>
                  </g>
                </svg>
              </div>
              <div
                ref={navigationNextRef}
                className={nex ? styles.next : `${styles.next} ${styles.disable}`}
              >
                <svg
                  viewBox='0 0 32 32'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'
                  role='presentation'
                  focusable='false'
                >
                  <g>
                    <path d='m12 4 11.2928932 11.2928932c.3905243.3905243.3905243 1.0236893 0 1.4142136l-11.2928932 11.2928932'></path>
                  </g>
                </svg>
              </div>
            </Swiper>
            <button
              type='button'
              className={styles.close_card}
              onClick={() => setChildClicked(null)}
            >
              <svg
                className={styles.close_svg}
                viewBox='0 0 32 32'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
              >
                <path d='m6 6 20 20'></path>
                <path d='m26 6-20 20'></path>
              </svg>
            </button>
            {user?.id === favoris?.user_id && (
              <svg
                onClick={deleteAnnonceInlist}
                className={styles.fav_svg}
                viewBox='0 0 32 32'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
              >
                <path d='m16 28c7-4.733 14-10 14-17 0-1.792-.683-3.583-2.05-4.95-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05l-2.051 2.051-2.05-2.051c-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05-1.367 1.367-2.051 3.158-2.051 4.95 0 7 7 12.267 14 17z'></path>
              </svg>
            )}
          </div>
          <Link
            target={tailWindow.matches ? '_blank' : null}
            rel='noopener noreferrer'
            to={`/rooms/${childClicked?._id}`}
          >
            <div className={styles.descript}>
              {childClicked.accommodation_type === TYPE_LOGEMENT.ENTIRE_HOME && (
                <h2>Logement entier - {childClicked.address?.city}</h2>
              )}
              {childClicked.accommodation_type === TYPE_LOGEMENT.PRIVATE_ROOM && (
                <h2>Chambre privée - {childClicked.address?.city}</h2>
              )}
              {childClicked.accommodation_type === TYPE_LOGEMENT.SHARED_ROOM && (
                <h2>Chambre partagée - {childClicked.address?.city}</h2>
              )}
              <div className={styles.hotel_evaluation}>
                {commentLength > 0 ? (
                  <div className={styles.block_eval}>
                    <StarSvg width={12} height={12} />
                    <p>
                      {totalComment} ({commentLength} Commentaire
                      {commentLength > 1 ? 's' : ''})
                    </p>
                  </div>
                ) : (
                  <div className={styles.block_eval}>
                    <StarSvg width={12} height={12} />
                    <p>Nouveau</p>
                  </div>
                )}
              </div>
              <div className={styles.price}>
                <h3>{pricePerNightWithoutPromo}</h3>
                <span>/nuit</span>
              </div>
            </div>
          </Link>
        </div>
      )}
    </>
  )
}

export default CardMaps
