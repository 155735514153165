import { Link } from 'react-router-dom'
import styles from '../../../css/traveler/favoris/favori.module.css'

const Carte = ({ favoris }) => {
  return (
    <>
      <div className={styles.container_list}>
        {favoris.map(item => (
          <Link to={`/wishlists/${item._id}`} key={item._id}>
            <div className={styles.card_block}>
              {item.lists[0] && (
                <>
                  <div className={styles.content_img}>
                    <img src={item.lists[0]?.hotel?.images[0]?.url} alt='' />
                  </div>

                  <div className={styles.content_img}>
                    {item.lists[1] ? (
                      <img src={item.lists[1]?.hotel?.images[0]?.url} alt='' />
                    ) : (
                      <img src={item.lists[0]?.hotel?.images[1]?.url} alt='' />
                    )}
                  </div>
                </>
              )}
            </div>
            <h3>{item.name}</h3>
          </Link>
        ))}
      </div>
    </>
  )
}

export default Carte
