import { useEffect, useContext } from 'react'
import { SocketContext } from '../context/SocketContext'

export const useWebSocket = callback => {
  const { socket } = useContext(SocketContext)

  useEffect(() => {
    if (!socket) return
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && document.hasFocus()) {
        if (callback) {
          callback()
        }
      }
    }

    const handleMessageCallback = () => {
      if (callback) {
        callback()
      }
    }

    socket?.on('getMessage', handleMessageCallback)
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      socket?.off('getMessage', handleMessageCallback)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [socket])
}
