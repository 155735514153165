import styles from '../../../../../css/modehotes/annonce/details/equipement.module.css'
import useUpdateHotel from '../../../../../hooks/useUpdateHotel'
import Checkbox from '../../../../ui/Checkbox'

const Kitchen = ({ equipements, setEquipements }) => {
  const { updateData } = useUpdateHotel()

  const array = [
    { name: 'Plaques de cuisson', value: '93' },
    { name: 'Ustensiles de barbecue', value: '94' },
    { name: 'Machine à pain', value: '95' },
    { name: 'Blender', value: '96' },
    { name: 'Café', value: '43' },
    { name: 'Cafetière', value: '42' },
    { name: 'Équipements de cuisine de base', value: '36' },
    { name: 'Table à manger', value: '97' },
    { name: 'Vaisselle et couverts', value: '98' },
    { name: 'Lave-vaisselle', value: '99' },
    { name: 'Congélateur', value: '37' },
    { name: 'Bouilloire électrique', value: '100' },
    { name: 'Four à micro-ondes', value: '35' },
    { name: 'Mini réfrigérateur', value: '101' },
    { name: 'Four', value: '41' },
    { name: 'Réfrigérateur', value: '34' },
    { name: 'Cuiseur à riz', value: '102' },
    { name: 'Cuisinière', value: '103' },
    { name: 'Cuisinière à gaz', value: '38' },
    { name: 'Grille-pain', value: '104' },
    { name: 'Compacteur de déchets', value: '105' },
    { name: 'Verres à vin', value: '57' },
  ]
  const onchangeFilters = e => {
    if (e.target.checked === true) {
      setEquipements([...equipements, e.target.value])
      const datas = {
        amenities: [...equipements, e.target.value],
      }
      updateData(datas)
    } else {
      const newArr = [...equipements].filter(item => item !== e.target.value)
      setEquipements(newArr)
      const datas = {
        amenities: newArr,
      }
      updateData(datas)
    }
  }
  return (
    <div className={styles.container_equip}>
      <h4>Cuisine et salle à manger</h4>
      {array.map(item => (
        <div key={item.name} className={styles.checkbox_container}>
          <label htmlFor={item.name}>{item.name}</label>
          <div>
            <Checkbox
              id={item.name}
              value={item.value}
              checked={equipements.includes(item.value)}
              onChange={onchangeFilters}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default Kitchen
