import { useEffect, useState, useContext } from 'react'
import styles from '../../../css/account/forgotPasswordPage.module.css'
import { User } from '../../../context/UserContext'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import LoadingPoint from '../../../components/LoadingPoint'
import DefaultLayout from '../../../layouts/DefaultLayout'
import { Helmet } from 'react-helmet-async'
import ErrorSvg from '../../../components/svgs/ErrorSvg'

const ForgotPasswordPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [loading, setLoading] = useState(false)
  const [echecConnexion, setEchecConnexion] = useState(false)
  const [reussit, setReussit] = useState(false)

  const user = useContext(User)
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onChange' })
  const onSubmit = data => {
    setLoading(true)
    setEchecConnexion(false)
    setReussit(false)
    axios
      .post(`/reset_password`, data)
      .then(() => {
        setEchecConnexion(false)
        setLoading(false)
        setReussit(true)
      })
      .catch(() => {
        setLoading(false)
        setEchecConnexion(true)
        setReussit(false)
      })
  }
  return (
    <DefaultLayout footer={true} mobileBottomNavigate={true}>
      <Helmet>
        <title>Réinitialisation du mot de passe/ Gowifmi</title>
      </Helmet>
      <div className={styles.main}>
        <div className={styles.container}>
          <div className={styles.head}>
            <h4>Mot de passe oublié ? </h4>
            <p>
              Saisissez votre adresse e-mail associée à votre compte et nous vous enverrons un lien
              par e-mail pour réinitialiser votre mot de passe.
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.connexion_form}>
              {echecConnexion && (
                <div className={styles.connexion_echec}>
                  <div className={styles.icon}>
                    <ErrorSvg width={24} height={24} />
                  </div>
                  <div className={styles.notif}>
                    <h1>Aucun utilisateur n'a été trouvé pour cette adresse e-mail</h1>
                  </div>
                </div>
              )}
              {reussit && (
                <div className={styles.reussit_conexion}>
                  <div className={styles.notif}>
                    <p>
                      Un lien pour réinitialiser votre mot de passe a été envoyé par e-mail{' '}
                      {user?.email ? `à ${user?.email}` : ''}
                    </p>
                  </div>
                </div>
              )}
              <div className={errors.email && styles.erreur}>
                <label htmlFor='email'>Adresse e-mail</label>
                <input
                  type='email'
                  id='email'
                  placeholder='ex: jonh@gmail.com'
                  {...register('email', { value: user?.email, required: true })}
                />
                {errors.email && (
                  <span className={styles.erreur}>Veuiller renseigner l'adresse e-mail</span>
                )}
              </div>
            </div>
            <div className={styles.btn_submit}>
              {loading ? (
                <button type='button' className={styles.disable} disabled>
                  <LoadingPoint />
                </button>
              ) : (
                <button type='submit'>Envoyer le lien de réinitialisation</button>
              )}
            </div>
          </form>
        </div>
      </div>
    </DefaultLayout>
  )
}

export default ForgotPasswordPage
