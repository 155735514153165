import { useEffect, useContext } from 'react'
import globalStyle from '../../css/becomeHostes/globalStyle.module.css'
import { User } from '../../context/UserContext'
import { useHistory } from 'react-router-dom'
import Form from '../../components/become_host/intros/Form'
import DescriptionText from '../../components/become_host/globals/DescriptionText'
import { Helmet } from 'react-helmet-async'

const Intro = () => {
  const user = useContext(User)
  const history = useHistory()
  useEffect(() => {
    if (user === null) {
      history.push('/host/homes')
    }
  }, [history, user])
  return (
    <>
      <Helmet>
        <title>Devenir hôte - Gowifmi</title>
      </Helmet>
      <div className={globalStyle.container}>
        <Form />
        <DescriptionText title={`Nous sommes ravis de vous revoir, ${user?.first_name}`} desc='' />
      </div>
    </>
  )
}

export default Intro
