import styles from '../../../css/details/equipement.module.css'
import CheckAmenitieSvg from './components/CheckAmenitieSvg'

const Security = ({ hotel }) => {
  const { amenities } = hotel

  return (
    <div className={styles.container_equip}>
      <h4>Sécurité à la maison</h4>
      {amenities?.includes('19') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Détecteur de fumée</p>
        </div>
      )}
      {amenities?.includes('18') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Détecteur de monoxyde de carbone</p>
        </div>
      )}
      {amenities?.includes('59') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Extincteur</p>
        </div>
      )}
      {amenities?.includes('61') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Kit de premiers secours</p>
        </div>
      )}
      {amenities?.includes('60') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Porte de la chambre avec verrou</p>
        </div>
      )}
    </div>
  )
}

export default Security
