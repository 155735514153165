import { addCurrency, addCurrencyComplet } from '../utils/addCurrency'
import { getDaysDif } from '../utils/difTwoDate'

function usePrice(annonce, numberOfNightModif) {
  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  const checkin = Params.get('checkin')
  const checkout = Params.get('checkout')

  const numberOfNight = getDaysDif(checkin, checkout) || numberOfNightModif

  const pricePerNightORG = annonce?.prices?.nightly_price || 0
  const cleaningFeeORG = annonce?.prices?.cleaning_fee || 0
  const totalAdditionalFee = annonce?.prices?.stay_fee || 0
  const gowifmiPourcentage = 14.3

  //appliquer la reducion
  const reductionFonction = () => {
    if (numberOfNight < 7) {
      return annonce?.discounts?.nightly || 0
    }
    if (numberOfNight >= 7 && numberOfNight < 28) {
      return annonce?.discounts?.weekly || 0
    } else if (numberOfNight >= 28) {
      return annonce?.discounts?.monthly || 0
    } else {
      return 0
    }
  }
  //le temps qu'est appliquer la reduction
  const reductionTimeFonction = () => {
    if (numberOfNight < 7) {
      return 'Réduction à la nuit'
    }
    if (numberOfNight >= 7 && numberOfNight < 28) {
      return 'Réduction à la semaine'
    } else if (numberOfNight >= 28) {
      return 'Réduction au mois'
    } else {
      return 0
    }
  }
  const reduction = reductionFonction()
  const reductionTime = reductionTimeFonction()

  const totalPricePerNightORG = pricePerNightORG * numberOfNight
  //le prix a la numberOfNight sans propomotion
  const pricePerNightWithoutPromoORG = pricePerNightORG + cleaningFeeORG + totalAdditionalFee

  //le prix a la numberOfNight avec propomotion
  const pricePerNightWithPromoORG = pricePerNightWithoutPromoORG * (1 - reduction / 100)
  //ici on applique la reduction en %
  const total = pricePerNightORG * numberOfNight + cleaningFeeORG + totalAdditionalFee
  const totalWithReductionORG = total * (1 - reduction / 100)

  //ici on calcul la reduction avec le frais de service voyageur a -14.3%
  const totalWithserviceTravelerORG = totalWithReductionORG * (1 + gowifmiPourcentage / 100)

  //pour savoir le montant de reduction on calcule le total moins total avec reduction
  const promoFeeORG = total - totalWithReductionORG

  //le montant exacte frais service voyageur
  const travelerServiceFeeORG = totalWithserviceTravelerORG - totalWithReductionORG

  //les prix avec devise sans virgule
  const pricePerNight = addCurrency(pricePerNightORG)
  const totalPricePerNight = addCurrency(totalPricePerNightORG)
  const cleaningFee = addCurrency(cleaningFeeORG)
  const pricePerNightWithoutPromo = addCurrency(pricePerNightWithoutPromoORG)
  const pricePerNightWithPromo = addCurrency(pricePerNightWithPromoORG)
  const totalWithReduction = addCurrency(totalWithReductionORG)
  const totalWithservice = addCurrency(totalWithserviceTravelerORG)
  const promoFee = addCurrency(promoFeeORG)
  const travelerServiceFee = addCurrency(travelerServiceFeeORG)

  //les prix avec devise qui affiche les nombre apres la virgule
  const fullPricePerNight = addCurrencyComplet(pricePerNightORG)
  const fullTotalPricePerNight = addCurrencyComplet(totalPricePerNightORG)
  const fullCleaningFee = addCurrencyComplet(cleaningFeeORG)
  const fullTotalWithservice = addCurrencyComplet(totalWithserviceTravelerORG)
  const fullPromoFee = addCurrencyComplet(promoFeeORG)
  const fullTravelerServiceFee = addCurrencyComplet(travelerServiceFeeORG)

  //les prix qui seront envoyer dans la base de donnéé
  const totalWithserviceRounded = Math.round(totalWithserviceTravelerORG)
  const promoFeeRounded = Math.round(promoFeeORG)
  const totalBeforeReduction = Math.round(total)

  //frais de service total voyageur et hote
  const serviceFeeRounded = Math.round(travelerServiceFeeORG)

  return {
    totalWithserviceRounded,
    promoFeeRounded,
    totalBeforeReduction,
    serviceFeeRounded,
    cleaningFeeORG,

    pricePerNight,
    totalPricePerNight,
    cleaningFee,
    reduction,
    reductionTime,
    pricePerNightWithoutPromo,
    pricePerNightWithPromo,
    totalWithReduction,
    totalWithservice,
    promoFee,
    travelerServiceFee,
    numberOfNight,

    fullPricePerNight,
    fullTotalPricePerNight,
    fullCleaningFee,
    fullTotalWithservice,
    fullPromoFee,
    fullTravelerServiceFee,
  }
}

export default usePrice
