import { Link } from 'react-router-dom'
import Tooltip from '../../../../../../ui/Tooltip'
import { PROFIL_CATEGORY } from '../../../../../../../enums/user/category.enums'

const SearchProfile = ({ annonce }) => {
  const id = annonce?._id
  const region = annonce?.address?.city
  const lat = annonce?.location?.coordinates[1]
  const lng = annonce?.location?.coordinates[0]
  const category = PROFIL_CATEGORY.PHOTOGRAPHER

  return (
    <Tooltip title='Vous cherchez un photographe ou tout autre profil utile si besoin ?'>
      <div>
        <Link
          to={`/profiles/search?&hotel=${id}&place=${region}&lat=${lat}&lng=${lng}&category=${category}`}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 32 32'
            aria-hidden='true'
            role='presentation'
            focusable='false'
            style={{
              display: 'block',
              fill: 'none',
              height: '16px',
              width: '16px',
              stroke: '#333',
              strokeWidth: '4',
              overflow: 'visible',
            }}
          >
            <path fill='none' d='M13 24a11 11 0 1 0 0-22 11 11 0 0 0 0 22zm8-3 9 9'></path>
          </svg>
        </Link>
      </div>
    </Tooltip>
  )
}

export default SearchProfile
