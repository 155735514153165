import { forwardRef } from 'react'

export const PhotoDrag = forwardRef(({ url, style, ...props }, ref) => {
  const inlineStyles = {
    transformOrigin: '0 0',
    height: 200,
    backgroundImage: `url("${url}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: 'grey',
    cursor: 'move',
    boxShadow: '0 0 7px 2px rgb(0 0 0 / 20%)',
    ...style,
  }

  return <div ref={ref} style={inlineStyles} {...props} />
})
