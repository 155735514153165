import BigErrorSvg from '../svgs/BigErrorSvg'
import styles from '../../css/confirmations/statut.module.css'
import NextAction from './next_action_link/NextAction'

const StatusPayment = ({ paymentObject, user, id }) => {
  return (
    <div className={styles.statut}>
      {paymentObject?.status === 'succeeded' && (
        <div className={styles.succeeded}>
          <div>
            <i className='far fa-check-circle'></i>
          </div>
          <h2>
            {user && <strong>{user.first_name},</strong>} votre réservation est confirmée avec
            succès
          </h2>
        </div>
      )}
      {paymentObject?.status === 'processing' && (
        <div className={styles.processing}>
          <div>
            <h2>
              {user && <strong>{user.first_name},</strong>} votre paiement est en cours de
              traitement.
            </h2>
            <p>
              Nous vous enverron l'état de votre voyage par cette adresse e-mail ( {user?.email} )
            </p>
          </div>
        </div>
      )}
      {paymentObject?.status === 'requires_payment_method' && (
        <div className={styles.echec}>
          <div>
            <BigErrorSvg />
          </div>
          <div>
            <h2>{user && <strong>{user.first_name},</strong>} votre paiement n'a pas abouti.</h2>
            <a href={`/rooms/${id}`}>Veuillez réessayer</a>
          </div>
        </div>
      )}
      {paymentObject?.status === 'requires_action' && (
        <div className={styles.processing}>
          <div>
            <BigErrorSvg />
          </div>
          <div>
            <h2>
              {user && <strong>{user.first_name},</strong>} Le paiement requiert une action de votre
              part pour être finalisé
            </h2>
            <NextAction nextActionObj={paymentObject?.next_action} />
          </div>
        </div>
      )}
    </div>
  )
}
export default StatusPayment
