import React, { useRef } from 'react'
import styles from '../../css/filter_booking/filter.module.css'
import { useLocation } from 'react-router'
import CalendarFilter from './CalendarFilter'

const Filter = React.forwardRef(({}, ref) => {
  const location = useLocation()
  const Query = location.search
  const params = new URLSearchParams(Query)
  params.delete('page')

  const dateRef = useRef()
  const handleCalendarfilter = () => {
    dateRef.current.OpenModal()
  }

  const allFiltersSelected = Array.from(params).length

  return (
    <>
      <div className={styles.block_filter}>
        <button
          title='Filfrer'
          className={allFiltersSelected > 0 ? styles.active : null}
          onClick={handleCalendarfilter}
        >
          <svg
            viewBox='0 0 16 16'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            role='presentation'
            focusable='false'
          >
            <path d='M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z'></path>
          </svg>
          <span className={styles.text}>Filtrer</span>
          <span className={styles.length}>1</span>
        </button>
      </div>
      <CalendarFilter ref={dateRef} />
    </>
  )
})

export default Filter
