import { useEffect, useState } from 'react'
import styles from '../../../css/account/info_perso.module.css'
import LoadingPoint from '../../LoadingPoint'
import { useForm } from 'react-hook-form'
// import { CountryNameOption } from '../../country_options/CountryName'
import useUserHook from '../../../hooks/useUserHook'
import FormTemplate from './locations/FormTemplate'

const Addresse = ({ active, chageActive }) => {
  const { userHook, loadingUpdate, updateUser, errorUpdate } = useUserHook(chageActive)
  const [fullAddress, setFullAddress] = useState('')
  const [appartement, setAppartement] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [country, setCountry] = useState('')
  const [countryAbbreviation, setCountryAbbreviation] = useState('')
  const [coords, setCoords] = useState({})
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    setFullAddress(userHook.address?.full_address)
    setAppartement(userHook.address?.apartment_name)
    setCity(userHook.address?.city)
    setState(userHook.address?.state)
    setPostalCode(userHook.address?.postal_code)
    setCountry(userHook.address?.country)
    setCountryAbbreviation(userHook.address?.country_abbreviation)
    setCoords({
      lat: userHook.location?.coordinates[1],
      lng: userHook.location?.coordinates[0],
    })
  }, [userHook])

  const { handleSubmit } = useForm({ mode: 'onChange' })
  const onSubmit = () => {
    const newData = {
      location: {
        coordinates: [coords.lng, coords.lat],
      },
      address: {
        full_address: fullAddress,
        apartment_name: appartement,
        city: city,
        state: state,
        postal_code: postalCode,
        country: country,
        country_code: countryAbbreviation,
      },
    }
    updateUser(newData)
  }

  if (errorUpdate) alert('error connexion retry')

  return (
    <>
      <div
        className={
          active && active !== 6 ? `${styles.form_content} ${styles.disable}` : styles.form_content
        }
      >
        <div className={styles.text}>
          <h3>Adresse</h3>
          {active === 6 ? (
            <button type='button' onClick={() => chageActive()}>
              Annuler
            </button>
          ) : (
            <button type='button' disabled={active && active !== 6} onClick={() => chageActive(6)}>
              Modifier
            </button>
          )}
        </div>

        <div className={styles.modif_block}>
          {active !== 6 ? (
            <p>
              {userHook.address?.full_address
                ? ` ${userHook.address?.full_address}`
                : 'Information non fournie'}
            </p>
          ) : (
            <>
              <p>
                Veuillez fournir une adresse fixe pour recevoir du courrier et vous situer
                géographiquement pour vos prestations sur Gowifmi.
              </p>
              <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                <FormTemplate
                  fullAddress={fullAddress}
                  setFullAddress={setFullAddress}
                  appartement={appartement}
                  setAppartement={setAppartement}
                  city={city}
                  setCity={setCity}
                  state={state}
                  setState={setState}
                  postalCode={postalCode}
                  setPostalCode={setPostalCode}
                  country={country}
                  setCountry={setCountry}
                  setCountryAbbreviation={setCountryAbbreviation}
                  setCoords={setCoords}
                  setIsValid={setIsValid}
                />
                <div className={styles.footer_btn_block}>
                  {loadingUpdate ? (
                    <button disabled className={styles.loading}>
                      <LoadingPoint />
                    </button>
                  ) : (
                    <button type='submit' disabled={!isValid}>
                      Enregistrer
                    </button>
                  )}
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Addresse
