import React, { useState } from 'react'
import styles from '../../css/searchModal/searchModal.module.css'
import moment from 'moment'
import { addDays } from 'date-fns'
import ModalCustomisable from '../ui/ModalCustomisable'
import CloseButton from '../ui/CloseButton'
import Calendar from './Calendar'
import Traveler from './Traveler'
import Destination from './Destination'
import { useSearchParams } from '../../context/SearchParamContext'
import { search_by_map } from '../../redux/actions'
import { useDispatch } from 'react-redux'

const ModalSearch = React.forwardRef((props, ref) => {
  const { params, updateParams } = useSearchParams()
  const newParams = new URLSearchParams(params.toString())

  const region = newParams.get('place')
  const adulte_search = newParams.get('adults')
  const enfant_search = newParams.get('children')
  const bebe_search = newParams.get('infants')
  const animal_search = newParams.get('pets')
  const checkin = newParams.get('checkin')
  const checkout = newParams.get('checkout')
  const placeId = newParams.get('place_id')
  const [modal, setModal] = useState(false)
  const [city, setCity] = useState(region || '')
  const [place_id, setPlace_id] = useState(placeId || '')
  const [citemodal, setCitemodal] = useState(false)
  const [datepickerModal, setDatepickerModal] = useState(false)
  const [voyageurModal, setVoyageurModal] = useState(false)
  const [adulte, setAdulte] = useState(parseInt(adulte_search || 1))
  const [enfant, setEnfant] = useState(parseInt(enfant_search || 0))
  const [bebe, setBebe] = useState(parseInt(bebe_search || 0))
  const [animal, setAnimal] = useState(parseInt(animal_search || 0))

  const [dateRange, setDateRange] = useState([
    checkin ? new Date(checkin) : new Date(),
    checkout ? new Date(checkout) : addDays(new Date(), 1),
  ])
  const [startDate, endDate] = dateRange

  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      setModal(true)
    },
  }))

  const dispatch = useDispatch()

  const handleSearch = () => {
    if (city) {
      dispatch(search_by_map(false))
      newParams.delete('search_by_map')
      newParams.delete('lat')
      newParams.delete('lng')
      newParams.delete('page')
      newParams.set('place', city)
      newParams.set('place_id', place_id)
      newParams.set('zoom', 12)
    }
    newParams.set('children', enfant)
    newParams.set('adults', adulte)
    newParams.set('infants', bebe)
    newParams.set('pets', animal)
    newParams.set('checkin', moment(startDate).format('YYYY-MM-DD'))
    newParams.set('checkout', moment(endDate).format('YYYY-MM-DD'))
    updateParams(newParams)
    setModal(false)
  }

  return (
    <>
      <ModalCustomisable isOpen={modal} onClose={() => setModal(false)}>
        <div className={modal ? `${styles.modal_content} ${styles.active}` : styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={() => setModal(false)} />
            <div></div>
          </div>
          <div>
            <div className={styles.m_body}>
              <h3 className={styles.head}>
                Recherchez des chambres, des logements entiers ou tout type d'hébergement.
              </h3>
              <div className={styles.search_block_nav}>
                <Destination
                  city={city}
                  setCity={setCity}
                  setPlace_id={setPlace_id}
                  citemodal={citemodal}
                  setCitemodal={setCitemodal}
                  setDatepickerModal={setDatepickerModal}
                />
                <Calendar
                  startDate={startDate}
                  endDate={endDate}
                  datepickerModal={datepickerModal}
                  setDatepickerModal={setDatepickerModal}
                  setDateRange={setDateRange}
                  dateRange={dateRange}
                  setVoyageurModal={setVoyageurModal}
                />
                <Traveler
                  voyageurModal={voyageurModal}
                  setVoyageurModal={setVoyageurModal}
                  adulte={adulte}
                  setAdulte={setAdulte}
                  enfant={enfant}
                  setEnfant={setEnfant}
                  bebe={bebe}
                  setBebe={setBebe}
                  animal={animal}
                  setAnimal={setAnimal}
                  handleSearch={handleSearch}
                />
                <div className={styles.submit_btn}>
                  <button type='submit' onClick={handleSearch}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 32 32'
                      aria-hidden='true'
                      role='presentation'
                      focusable='false'
                      style={{
                        display: 'block',
                        fill: 'none',
                        height: '16px',
                        width: '16px',
                        stroke: 'currentcolor',
                        strokeWidth: '4',
                        overflow: 'visible',
                      }}
                    >
                      <path
                        fill='none'
                        d='M13 24a11 11 0 1 0 0-22 11 11 0 0 0 0 22zm8-3 9 9'
                      ></path>
                    </svg>
                    <span>Rechercher</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalCustomisable>
    </>
  )
})
export default ModalSearch
