import { useState } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const Arrival = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [instruArriver, setInstruArriver] = useState('')
  const [instruArriverMessage, setInstruArriverMessage] = useState('')
  const { loading, updateData } = useUpdateHotel(setOpen)

  const handleSubmit = () => {
    const datas = {
      traveler_information: {
        ...annonce.traveler_information,
        arrival_instructions: instruArriver,
        arrival_instructions_message: instruArriverMessage,
      },
    }
    updateData(datas)
  }
  const openFuction = () => {
    setInstruArriver(annonce.traveler_information?.arrival_instructions)
    setInstruArriverMessage(annonce.traveler_information?.arrival_instructions_message)
    setOpen(true)
  }
  const reset = () => {
    setInstruArriver()
    setInstruArriverMessage()
    setOpen(false)
  }
  const array = [
    {
      name: "Clés remises par l'hôte",
      value: 'clés_remises_par_hôte',
      descr: 'Un hôte ou co-hôte remettra les clés aux voyageurs en personne',
    },
    {
      name: "Personnel de l'immeuble",
      value: 'personnel_de_immeuble',
      descr: "Une personne est sur place 24h/24 pour permettre l'accès aux voyageurs.",
    },
    {
      name: 'Boîte à clé sécurisée',
      value: 'boîte_à_clé_sécurisée',
      descr:
        "La clé est conservée dans un petit boîtier que les voyageurs peuvent ouvrir à l'aide d'un code.",
    },
    {
      name: 'Serrure connectée',
      value: 'serrure_connectée',
      descr:
        "Les voyageurs peuvent ouvrir la porte à l'aide d'une serrure numérique ou d'une application mobile.",
    },
    {
      name: 'Serrure numérique',
      value: 'serrure_numérique',
      descr: "Les voyageurs peuvent ouvrir la porte à l'aide d'un code.",
    },
    {
      name: 'Autres',
      value: 'autre',
      descr: 'Les voyageurs peuvent utiliser une autre méthode non énumérée ci-dessus',
    },
  ]
  return (
    <div className={styles.block}>
      <h2>Informations relatives à l'arrivée</h2>
      {!open ? (
        <Section title="Instructions d'arrivée" handleOpen={openFuction}>
          {array
            .filter(item => item.value === annonce.traveler_information?.arrival_instructions)
            .map(item => (
              <p key={item.value}>
                {item.name} : {item.descr}
              </p>
            ))}
          <p>{annonce.traveler_information?.arrival_instructions_message}</p>
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title="Instructions d'arrivée" handleReset={reset}>
            <p>Procédure d'arrivée</p>
          </ContenHead>
          <div className={styles.body}>
            <div className={styles.input_block}>
              {array.map(item => (
                <div className={styles.checkox_block} key={item.value}>
                  <div>
                    <input
                      type='radio'
                      id={item.value}
                      value={item.value}
                      checked={instruArriver === item.value}
                      onChange={e => setInstruArriver(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor={item.value}>
                      <div className={styles.statut}>
                        <h5>{item.name}</h5>
                      </div>
                      <p>{item.descr}</p>
                    </label>
                  </div>
                </div>
              ))}
              <div className={styles.textarea_block}>
                <label htmlFor='message'>Instructions d'arrivée</label>
                <textarea
                  id='message'
                  value={instruArriverMessage}
                  onChange={e => setInstruArriverMessage(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
          <BottomForm handleSubmit={handleSubmit} loading={loading} handleReset={reset} />
        </div>
      )}
    </div>
  )
}

export default Arrival
