export function getDaysDif(date1, date2) {
  const day_start = new Date(date1).setHours(0, 0, 0, 0)
  const day_end = new Date(date2).setHours(0, 0, 0, 0)
  const days = (day_end - day_start) / (1000 * 60 * 60 * 24)
  const night = Math.round(days)
  return night
}

export function getDaysDifWithHours(date1, date2) {
  //new date instance
  const dt_date1 = new Date(date1)
  const dt_date2 = new Date(date2)

  //Get the Timestamp
  const date1_time_stamp = dt_date1.getTime()
  const date2_time_stamp = dt_date2.getTime()

  let calc

  //Check which timestamp is greater
  if (date1_time_stamp > date2_time_stamp) {
    calc = new Date(date1_time_stamp - date2_time_stamp)
  } else {
    calc = new Date(date2_time_stamp - date1_time_stamp)
  }
  //Retrieve the date, month and year
  const calcFormatTmp = calc.getDate() + '-' + (calc.getMonth() + 1) + '-' + calc.getFullYear()
  //Convert to an array and store
  const calcFormat = calcFormatTmp.split('-')
  //Subtract each member of our array from the default date
  const days_passed = Number(Math.abs(calcFormat[0]) - 1)
  const months_passed = Number(Math.abs(calcFormat[1]) - 1)
  const years_passed = Number(Math.abs(calcFormat[2]) - 1970)

  //Convert to days and sum together
  const total_days = years_passed * 365 + months_passed * 30.417 + days_passed

  return total_days
}
