import styles from '../../../../css/account/locations/address.module.css'
import Autocomplete from './components/Autocomplete'
import CurrentAdresse from './components/CurrentAdresse'
const FormTemplate = ({
  fullAddress,
  appartement,
  city,
  state,
  postalCode,
  country,
  setFullAddress,
  setAppartement,
  setCity,
  setState,
  setPostalCode,
  setCountry,
  setCountryAbbreviation,
  setCoords,
  setIsValid,
}) => {
  return (
    <div className={styles.form_block}>
      <CurrentAdresse
        setFullAddress={setFullAddress}
        setCity={setCity}
        setPostalCode={setPostalCode}
        setState={setState}
        setCountry={setCountry}
        setCountryAbbreviation={setCountryAbbreviation}
        setCoords={setCoords}
        setIsValid={setIsValid}
      />

      <div className={styles.block}>
        <Autocomplete
          fullAddress={fullAddress}
          setFullAddress={setFullAddress}
          setCity={setCity}
          setPostalCode={setPostalCode}
          setState={setState}
          setCountry={setCountry}
          setCountryAbbreviation={setCountryAbbreviation}
          setCoords={setCoords}
          setIsValid={setIsValid}
        />
      </div>
      <div className={styles.block}>
        <label htmlFor='appartement'>Appartement</label>
        <input
          id='appartement'
          type='text'
          autoComplete='off'
          placeholder='Appartement, bâtiment, ect. (facultatif)'
          value={appartement}
          onChange={e => setAppartement(e.target.value)}
        />
      </div>
      <div className={styles.inputs}>
        <div className={styles.block}>
          <label htmlFor='city'>Ville</label>
          <input
            id='city'
            type='text'
            placeholder='La ville'
            autoComplete='off'
            value={city}
            onChange={e => setCity(e.target.value)}
          />
        </div>
        <div className={styles.block}>
          <label htmlFor='state'>État</label>
          <input
            id='state'
            type='text'
            autoComplete='off'
            placeholder='État (facultatif)'
            value={state}
            onChange={e => setState(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.block}>
        <label htmlFor='code_postal'>Code postal</label>
        <input
          id='code_postal'
          type='text'
          autoComplete='off'
          placeholder='Code postal (facultatif)'
          value={postalCode}
          onChange={e => setPostalCode(e.target.value)}
        />
      </div>
      <div className={styles.block}>
        <label htmlFor='country'>Pays/région</label>
        <select id='country' autoComplete='off' value={country} disabled>
          <option>{country}</option>
        </select>
      </div>
    </div>
  )
}

export default FormTemplate
