import styles from '../../../../css/becomeHostes/photos.module.css'
import { useRef } from 'react'
import UpdatePhoto from '../UpdatePhoto'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import DropdownMenu from './DropdownMenu'

const ImageCard = ({ photo, path, images, setImages, refetch }) => {
  const Updated = useRef()
  const HandleModalUpdated = (id, photo, caption) => {
    Updated.current.OpenModal(id, photo, caption)
  }

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: photo._id,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.3 : 1,
  }

  return (
    <>
      <div ref={setNodeRef} style={style}>
        <div className={styles.item_list}>
          <div className={styles.item_img}>
            <DropdownMenu
              photo={photo}
              path={path}
              images={images}
              setImages={setImages}
              HandleModalUpdated={HandleModalUpdated}
            />
            <img src={photo.url} alt='' {...listeners} {...attributes} />
          </div>
          <div className={styles.addLegende}>
            {photo.caption && <p>{photo.caption}</p>}
            <button onClick={() => HandleModalUpdated(photo._id, photo.url, photo.caption)}>
              {photo.caption ? 'Modifier' : 'Ajouter une légende'}
            </button>
          </div>
        </div>
      </div>
      <UpdatePhoto refetch={refetch} ref={Updated} />
    </>
  )
}

export default ImageCard
