import styles from '../../../../../css/modehotes/annonce/details/securiteVoyageur.module.css'
import Checkbox from '../../../../ui/Checkbox'

const WaterNearby = ({ eauAproximite, setEauAproximite }) => {
  return (
    <div className={styles.checkbox_container}>
      <div>
        <label htmlFor='eau'>Lac, rivière ou autre étendue d'eau à proximité</label>
        <p>
          Les voyageurs auront un accès direct à un plan d'eau naturel ou artificiel, sur ou en
          dehors de l'établissement. Ex. : un océan, une plage, un étang, un cours d'eau ou des
          zones humides.
        </p>
      </div>
      <div>
        <Checkbox
          id='eau'
          value={eauAproximite}
          checked={eauAproximite}
          onChange={() => setEauAproximite(!eauAproximite)}
        />
      </div>
    </div>
  )
}

export default WaterNearby
