import { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { User } from '../../context/UserContext'
import HostModeLayout from '../../layouts/HostModeLayout'
import Dashboard from '../../components/hote_mode/hostings/Dashboard'
import { Crisp } from 'crisp-sdk-web'
import { crispWebsiteId } from '../../config/config'
import { Helmet } from 'react-helmet-async'

const Hosting = () => {
  const user = useContext(User)
  const history = useHistory()
  useEffect(() => {
    window.scrollTo(0, 0)
    if (user === null) {
      history.push('/')
    } else {
      Crisp.configure(crispWebsiteId)
      Crisp.setZIndex(999)
      Crisp.user.setEmail(user?.email)
      Crisp.user.setNickname(`${user?.first_name} ${user?.name}`)
      Crisp.session.setData({
        gowifmi_user_id: user?.id,
      })
      Crisp.chat.show()
    }
    if (!user?.isHost && user?.profile_categories.length < 1) {
      history.push('/host/homes')
    }

    return () => {
      Crisp.chat.hide()
    }
  }, [history])

  return (
    <HostModeLayout footer={true}>
      <Helmet>
        <title>Tableau de bord de l'hôte - Gowifmi</title>
      </Helmet>
      <Dashboard />
    </HostModeLayout>
  )
}

export default Hosting
