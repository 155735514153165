import { useState } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const DurationMax = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [durreMax, setDurreMax] = useState()
  const { loading, updateData } = useUpdateHotel(setOpen)

  const min = annonce.stay_duration?.minimum
  const max = 1125
  const handleSubmit = e => {
    e.preventDefault()
    const datas = {
      stay_duration: {
        ...annonce.stay_duration,
        maximum: durreMax,
      },
    }
    updateData(datas)
  }
  const openFuction = () => {
    setDurreMax(annonce.stay_duration?.maximum)
    setOpen(true)
  }
  const reset = () => {
    setDurreMax()
    setOpen(false)
  }
  const handleChange = event => {
    const result = event.target.value.replace(/\D/g, '')
    setDurreMax(result)
  }
  return (
    <div className={styles.block}>
      {!open ? (
        <Section title='Durée maximale de séjour' handleOpen={openFuction}>
          <p>
            {annonce.stay_duration?.maximum > 1
              ? `${annonce.stay_duration?.maximum} nuits`
              : `${annonce.stay_duration?.maximum} nuit`}
          </p>
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title='Durée maximale de séjour' handleReset={reset}>
            <p>Nombre minimal de nuits</p>
          </ContenHead>
          <form onSubmit={handleSubmit}>
            <div className={styles.body}>
              <div
                className={
                  durreMax < min || durreMax > max
                    ? `${styles.input_block} ${styles.error}`
                    : styles.input_block
                }
              >
                <input type='tel' value={durreMax} onChange={handleChange} />
                <div className={styles.price}>
                  <h5>
                    {durreMax && durreMax} {durreMax > 1 ? 'nuits' : 'nuit'}
                  </h5>
                  {durreMax < min || durreMax > max ? (
                    <p>La durée de séjour maximum doit être inférieure ou égale à 1125</p>
                  ) : null}
                </div>
              </div>
            </div>
            <BottomForm
              disabled={durreMax < min || durreMax > max}
              loading={loading}
              handleReset={reset}
            />
          </form>
        </div>
      )}
    </div>
  )
}

export default DurationMax
