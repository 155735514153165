import styles from '../../../../css/search_room_result/card.module.css'
import usePrice from '../../../../hooks/usePrice'

const DetailsPrice = ({ item }) => {
  const {
    pricePerNight,
    totalPricePerNight,
    cleaningFeeORG,
    cleaningFee,
    reduction,
    reductionTime,
    pricePerNightWithoutPromo,
    pricePerNightWithPromo,
    totalWithservice,
    promoFee,
    travelerServiceFee,
    numberOfNight,
  } = usePrice(item)
  return (
    <div className={styles.descrip_droite}>
      <div className={styles.prix_bloc}>
        {reduction > 0 && <p className={styles.prix_barer}>{pricePerNightWithoutPromo}</p>}
        {reduction > 0 && (
          <p className={`${styles.prix_barer} ${styles.mobile}`}>{pricePerNightWithoutPromo}</p>
        )}
        <p className={styles.descrip_prix}>
          {pricePerNightWithPromo} <span>/ nuit</span>
        </p>
        <div className={styles.total_content}>
          <p className={styles.total}>{totalWithservice} au total</p>
          <div className={styles.decompte_price}>
            <h2>Décompte du prix</h2>
            <div className={styles.dec_container}>
              <div className={styles.dec_block}>
                <p>
                  {pricePerNight} x {numberOfNight} {numberOfNight > 1 ? 'nuits' : 'nuit'}
                </p>
                <p>{totalPricePerNight}</p>
              </div>
              {cleaningFeeORG > 0 && (
                <div className={styles.dec_block}>
                  <p>Frais de ménage</p>
                  <p>{cleaningFee}</p>
                </div>
              )}
              {reduction > 0 && (
                <div className={styles.dec_block}>
                  <p>{reductionTime}</p>
                  <p className={styles.reduction}>-{promoFee}</p>
                </div>
              )}
              <div className={styles.dec_block}>
                <p>Frais de service</p>
                <p>{travelerServiceFee}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailsPrice
