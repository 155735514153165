import { useRef, useContext } from 'react'
import styles from '../../../css/account/profile/signale.module.css'
import { User } from '../../../context/UserContext'
import ModalSignal from '../../signals/users'
import Login from '../../login'

const SignalerUser = ({ userSignal }) => {
  const user = useContext(User)

  const signal = useRef()
  const Handlesignal = () => {
    signal.current.OpenModal()
  }
  const connexion = useRef()
  const handleConnection = params => {
    connexion.current.OpenModal(params)
  }
  const handlesendmessage = () => {
    if (user === null) {
      handleConnection('connexion')
    } else {
      Handlesignal()
    }
  }
  return (
    <>
      <div className={styles.container}>
        <button onClick={handlesendmessage}>
          <span>
            <svg
              viewBox='0 0 32 32'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'
              role='presentation'
              focusable='false'
            >
              <path d='M28 6H17V4a2 2 0 0 0-2-2H3v28h2V18h10v2a2 2 0 0 0 2 2h11l.115-.006a1 1 0 0 0 .847-1.269L27.039 14l1.923-6.724A1 1 0 0 0 28 6z'></path>
            </svg>
          </span>
          <span className={styles.text}>Signaler ce profil</span>
        </button>
      </div>
      <ModalSignal ref={signal} userSignal={userSignal} />
      <Login ref={connexion} />
    </>
  )
}

export default SignalerUser
