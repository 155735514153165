import { useEffect, useState, useContext } from 'react'
import styles from '../../../css/modehotes/annonce/listings/listeLogement.module.css'
import { Helmet } from 'react-helmet-async'
import { useHistory, Link } from 'react-router-dom'
import axios from 'axios'
import momentTimeZone from 'moment-timezone'
import { User } from '../../../context/UserContext'
import HeaderListing from '../../../components/hote_mode/annonce/defaults/HeaderListing'
import Loading from '../../../components/hote_mode/annonce/defaults/LoadingListing'
import FreeModeAnnonce from '../../../components/hote_mode/default_components/freemode/FreeModeAnnonce'
import HostModeLayout from '../../../layouts/HostModeLayout'
import { SATATUS_ANNONCE } from '../../../enums/logement/status.enums'
import { useQuery } from 'react-query'
import Pagination from '../../../components/hote_mode/annonce/defaults/Pagination'

const Liste = () => {
  const [searchParams, setSearchParams] = useState(window.location.search)

  const user = useContext(User)
  const history = useHistory()

  const userTimezone = momentTimeZone.tz.guess()

  useEffect(() => {
    if (user === null) {
      history.push('/')
    }
    const unlisten = history.listen(location => {
      setSearchParams(location.search)
    })

    return () => {
      unlisten()
    }
  }, [history, user])

  const fetchData = async () => {
    const { data } = await axios.get(`/hotel${searchParams}`)
    return data
  }

  const { data = [], isLoading } = useQuery([`get_all_hotels`, searchParams], fetchData)

  const handleDetail = (status, redirect_url, id) => {
    if (status === SATATUS_ANNONCE.IN_PROGRESS) {
      history.push(`/become-a-host/${redirect_url}/${id}`)
    } else if (status === SATATUS_ANNONCE.SAVE) {
      history.push(`/verify-listing/${id}`)
    } else {
      history.push(`/hosting/listings/details/${id}${searchParams}`)
    }
  }

  return (
    <HostModeLayout>
      <Helmet>
        <title>Gérez vos annonces - Gowifmi</title>
      </Helmet>
      <div className={styles.voyage_container}>
        <div className={styles.navigation}>
          <HeaderListing loading={isLoading} annonce={data} />
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {data?.total_data < 1 ? (
                <div className={styles.no_result}>
                  <svg
                    viewBox='0 0 16 16'
                    xmlns='http://www.w3.org/2000/svg'
                    aria-hidden='true'
                    role='presentation'
                    focusable='false'
                  >
                    <path d='M8.602 1.147l.093.08 7.153 6.914-.696.718L14 7.745V14.5a.5.5 0 0 1-.41.492L13.5 15H10V9.5a.5.5 0 0 0-.41-.492L9.5 9h-3a.5.5 0 0 0-.492.41L6 9.5V15H2.5a.5.5 0 0 1-.492-.41L2 14.5V7.745L.847 8.86l-.696-.718 7.153-6.915a1 1 0 0 1 1.297-.08z'></path>
                  </svg>
                  <h2>Vous n'avez pas des annonces pour l'instant</h2>
                  <Link to='/host/homes'>Ajouter un logement</Link>
                </div>
              ) : (
                <div className={styles.container_arry}>
                  <table className={styles.customers}>
                    <thead>
                      <tr>
                        <th className={styles.image}>annonce</th>
                        <th>Statut</th>
                        <th>à faire</th>
                        <th>réservation instantannée</th>
                        <th>freemode</th>
                        <th>chambres</th>
                        <th>lits</th>
                        <th>salles de bains</th>
                        <th>emplacement</th>
                        <th>dernière modification</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.results?.map(item => (
                        <tr key={item._id}>
                          <td className={styles.image}>
                            <button
                              onClick={() => handleDetail(item.status, item.redirect_url, item._id)}
                            >
                              <div>
                                <div className={styles.card}>
                                  {item.images[0]?.url ? (
                                    <img src={item.images[0]?.url} alt='' />
                                  ) : (
                                    <svg
                                      viewBox='0 0 16 16'
                                      xmlns='http://www.w3.org/2000/svg'
                                      aria-hidden='true'
                                      role='presentation'
                                      focusable='false'
                                    >
                                      <path d='M8.602 1.147l.093.08 7.153 6.914-.696.718L14 7.745V14.5a.5.5 0 0 1-.41.492L13.5 15H10V9.5a.5.5 0 0 0-.41-.492L9.5 9h-3a.5.5 0 0 0-.492.41L6 9.5V15H2.5a.5.5 0 0 1-.492-.41L2 14.5V7.745L.847 8.86l-.696-.718 7.153-6.915a1 1 0 0 1 1.297-.08z'></path>
                                    </svg>
                                  )}
                                </div>
                              </div>
                              <p>{item.title || 'Donnez un titre à votre annonce'}</p>
                            </button>
                          </td>
                          {item.status === SATATUS_ANNONCE.IN_PROGRESS && (
                            <td className={styles.statut}>
                              <span className={styles.status_inprogress}></span>En cours
                            </td>
                          )}
                          {item.status === SATATUS_ANNONCE.SAVE && (
                            <td className={styles.statut}>
                              <span className={styles.status_save}></span>Enregistrée
                            </td>
                          )}
                          {item.status === SATATUS_ANNONCE.PUBLISH && (
                            <td className={styles.statut}>
                              <span className={styles.status_publish}></span>Publiée
                            </td>
                          )}
                          {item.status === SATATUS_ANNONCE.HIDE && (
                            <td className={styles.statut}>
                              <span className={styles.status_hide}></span>Masquée
                            </td>
                          )}
                          <td className={styles.todo}>
                            {item.status === SATATUS_ANNONCE.IN_PROGRESS && (
                              <button
                                onClick={() =>
                                  handleDetail(item.status, item.redirect_url, item._id)
                                }
                              >
                                Terminer
                              </button>
                            )}
                            {item.status === SATATUS_ANNONCE.SAVE && (
                              <button
                                onClick={() =>
                                  handleDetail(item.status, item.redirect_url, item._id)
                                }
                              >
                                Publier
                              </button>
                            )}
                            {item.status === SATATUS_ANNONCE.PUBLISH ||
                            item.status === SATATUS_ANNONCE.HIDE ? (
                              <button
                                onClick={() =>
                                  handleDetail(item.status, item.redirect_url, item._id)
                                }
                              >
                                Modifier
                              </button>
                            ) : null}
                          </td>
                          <td className={styles.rows}>
                            <div className={styles.instabook}>
                              <svg
                                className={
                                  item.regulations?.instant_booking ? styles.yes : styles.no
                                }
                                viewBox='0 0 16 16'
                                xmlns='http://www.w3.org/2000/svg'
                                aria-hidden='true'
                                role='presentation'
                                focusable='false'
                              >
                                <path d='M8.903 1.037A.23.23 0 0 1 9 1.225V7h3.556a.23.23 0 0 1 .188.363l-5.326 7.545A.23.23 0 0 1 7 14.775V9H3.444a.23.23 0 0 1-.188-.363l5.326-7.545a.23.23 0 0 1 .32-.055z'></path>
                              </svg>
                              <span>{item.regulations?.instant_booking ? 'On' : 'Off'}</span>
                            </div>
                          </td>
                          <td className={styles.rows}>
                            <FreeModeAnnonce annonce={item} />
                          </td>
                          <td className={styles.rows}>{item.details?.number_of_rooms}</td>
                          <td className={styles.rows}>{item.details?.number_of_beds}</td>
                          <td className={styles.rows}>{item.details?.number_of_bathrooms}</td>
                          <td className={styles.rows}>
                            <p>
                              {item.address?.city || 'Ville'},{item.address?.country || 'Pays'}
                            </p>
                          </td>
                          <td className={styles.rows}>
                            {momentTimeZone.utc(item.updatedAt).tz(userTimezone).calendar()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination data={data} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </HostModeLayout>
  )
}

export default Liste
