const HotelLanguage = ({ hotel }) => {
  const { languages } = hotel

  return (
    <ul>
      {languages.includes('1') && <li>Anglais</li>}
      {languages.includes('2') && <li>Français</li>}
      {languages.includes('3') && <li>Allemand</li>}
      {languages.includes('4') && <li>Japonais</li>}
      {languages.includes('5') && <li>Italien</li>}
      {languages.includes('6') && <li>Russe</li>}
      {languages.includes('7') && <li>Espagnol</li>}
      {languages.includes('8') && <li>Chinois (simplifié)</li>}
      {languages.includes('9') && <li>Arabe</li>}
      {languages.includes('10') && <li>Hindi</li>}
      {languages.includes('11') && <li>Portugais</li>}
      {languages.includes('12') && <li>Turc</li>}
      {languages.includes('13') && <li>Indonésien</li>}
      {languages.includes('14') && <li>Néerlandais</li>}
      {languages.includes('15') && <li>Thaï</li>}
      {languages.includes('16') && <li>Grec</li>}
      {languages.includes('17') && <li>Polonais</li>}
      {languages.includes('18') && <li>Malais</li>}
      {languages.includes('19') && <li>Tagalog</li>}
      {languages.includes('20') && <li>Danois</li>}
      {languages.includes('21') && <li>Suédois</li>}
      {languages.includes('22') && <li>Norvégien</li>}
      {languages.includes('23') && <li>Finnois</li>}
      {languages.includes('24') && <li>Tchèque</li>}
      {languages.includes('25') && <li>Hongrois</li>}
    </ul>
  )
}

export default HotelLanguage
