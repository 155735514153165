import { useEffect, useState, useRef, useContext } from 'react'
import styles from '../../../../css/modehotes/inbox/detailMessageHote.module.css'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import axios from 'axios'
import { User } from '../../../../context/UserContext'
import LoadingPoint from '../../../../components/LoadingPoint'
import Allmessage from '../../../../components/hote_mode/inboxs/details/Allmessage'
import Header from '../../../../components/hote_mode/inboxs/details/Header'
import TypeMessage from '../../../../components/hote_mode/inboxs/details/TypeMessage'
import SignalerUser from '../../../../components/hote_mode/inboxs/details/SignalerUser'
import HostModeLayout from '../../../../layouts/HostModeLayout'
import WriteMessageInput from '../../../../components/hote_mode/inboxs/details/WriteMessageInput'
import { Helmet } from 'react-helmet-async'
import { useWebSocket } from '../../../../hooks/useWebSocket'
import SideNavigation from '../../../../components/hote_mode/inboxs/SideNavigation'
import momentTimeZone from 'moment-timezone'

const DetailAllmessage = () => {
  const [modalLeftbar, setModalLeftbar] = useState(false)
  const user = useContext(User)
  const history = useHistory()
  const location = useLocation()
  const path = location.pathname.split('/')[4]
  const Query = location.search
  const Params = new URLSearchParams(Query)
  const filter = Params.get('filter')

  const userTimezone = momentTimeZone.tz.guess()

  const { refetch: updateConversation } = useQuery(
    `updateStatut-hote-${path}}`,
    () =>
      axios.patch(`/hotemessage/updateStatut/${path}`).then(res => {
        return res.data
      }),
    { refetchOnWindowFocus: false }
  )

  useEffect(() => {
    window.scrollTo(0, 0)
    if (user === null) {
      history.push('/')
    }
    updateConversation()
  }, [history, location, updateConversation, user])

  const {
    refetch,
    data: conversation = null,
    isLoading,
  } = useQuery(
    `get-conversation-hote-${path}}`,
    () =>
      axios.get(`/hotemessage/${path}`).then(res => {
        return res.data
      }),
    { refetchOnWindowFocus: false }
  )

  useWebSocket(() => {
    refetch()
    updateConversation()
  })

  const chatParent = useRef(null)
  useEffect(() => {
    const domNode = chatParent.current
    if (domNode) {
      domNode.scrollTop = domNode.scrollHeight
    }
  })

  const annonce_id = conversation?.hotel?._id

  return (
    <HostModeLayout>
      <Helmet>
        <title>Tous messages - Gowifmi</title>
      </Helmet>
      <div className={styles.liste_container}>
        <div className={styles.block_grid}>
          <SideNavigation
            filter={filter}
            modalLeftbar={modalLeftbar}
            setModalLeftbar={setModalLeftbar}
          />
          <div className={styles.block_grid_2}>
            <Header
              path={path}
              filter={filter}
              setModalLeftbar={setModalLeftbar}
              conversation={conversation}
            />

            <div className={styles.block_liste_and_detail}>
              <Allmessage filter={filter} path={path} user={user} />
              <div className={styles.conversation_block}>
                {isLoading ? (
                  <div className={styles.loading_container}>
                    <LoadingPoint />
                  </div>
                ) : (
                  <>
                    {conversation ? (
                      <>
                        <div className={styles.detail_block} ref={chatParent}>
                          <div className={styles.message}>
                            {[...(conversation?.message_box || [])]?.reverse().map(item => (
                              <div key={item._id} className={styles.detail}>
                                <TypeMessage
                                  item={item}
                                  adulte={item.adults}
                                  enfant={item.children}
                                  bebe={item.babies}
                                  animaux={item.pets}
                                  checkin={item.arrival}
                                  checkout={item.departure}
                                  annonce_id={annonce_id}
                                />
                                <div className={styles.message_block}>
                                  <div>
                                    <a
                                      href={`/profile/${item.userId?.id}`}
                                      target='_blank'
                                      className={styles.card}
                                      rel='noreferrer'
                                    >
                                      {item.userId?.picture ? (
                                        <img src={item.userId?.picture} alt='' />
                                      ) : (
                                        <h2>{item.userId?.first_name?.slice(0, 1)}</h2>
                                      )}
                                    </a>
                                  </div>
                                  <div className={styles.message_text}>
                                    <div>
                                      <h4>
                                        {item.userId?.id === user?.id
                                          ? 'Moi'
                                          : item.userId?.first_name}
                                        <span>
                                          {momentTimeZone(item.timestamp)
                                            .tz(userTimezone)
                                            .format('HH:mm')}
                                        </span>
                                      </h4>
                                      <p>{item.text}</p>
                                    </div>
                                    {item.userId?.id === conversation?.user?.id && (
                                      <SignalerUser userSignal={item.userId?.id} />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <WriteMessageInput refetch={refetch} path={path} />
                      </>
                    ) : (
                      <div className={styles.no_result}>
                        <p>Aucun resultat</p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </HostModeLayout>
  )
}

export default DetailAllmessage
