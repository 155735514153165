import { useState, useEffect, useRef } from 'react'
import styles from '../../../../css/traveler/favoris/maps.module.css'
import { GoogleMap } from '@react-google-maps/api'
import CardMaps from './CardMaps'
import CardSmile from './CardSmile'
import ButtonHandleMaps from './ButtonHandleMaps'
import mapStyles from '../../../../utils/mapStyles'

function Maps({ hotel, user, favoris, refetch, childhover, childClicked, setChildClicked }) {
  const [openMap, setOpenMap] = useState(false)
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false)
  const [mapLoaded, setMapLoaded] = useState(false)
  const [showMap, setShowMap] = useState(false) // État pour contrôler l'affichage de la carte
  const mapRef = useRef(null)

  useEffect(() => {
    const checkGoogleMapsLoaded = setInterval(() => {
      if (window.google && window.google.maps) {
        setGoogleMapsLoaded(true)
        clearInterval(checkGoogleMapsLoaded)
      }
    }, 100)

    return () => clearInterval(checkGoogleMapsLoaded)
  }, [])

  useEffect(() => {
    if (googleMapsLoaded) {
      setShowMap(true)
    }
  }, [googleMapsLoaded])

  const handleMapLoad = map => {
    mapRef.current = map
    setMapLoaded(true)
  }

  useEffect(() => {
    if (mapLoaded && googleMapsLoaded && mapRef.current && hotel?.lists?.length > 0) {
      const bounds = new window.google.maps.LatLngBounds()
      hotel.lists.forEach(({ hotel }) => {
        if (hotel?.location?.coordinates) {
          bounds.extend({
            lat: hotel.location.coordinates[1],
            lng: hotel.location.coordinates[0],
          })
        }
      })
      mapRef.current.fitBounds(bounds)
    }
  }, [hotel, mapLoaded, googleMapsLoaded])

  const mapContainerStyle = {
    width: '100%',
    height: '100%',
  }

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    gestureHandling: 'greedy',
    zoomControlOptions: {
      position: window.google.maps.ControlPosition.RIGHT_TOP,
      style: window.google.maps.ZoomControlStyle.SMALL,
    },
    minZoom: 2,
    maxZoom: 17,
    clickableIcons: false,
    styles: mapStyles,
  }

  return (
    <>
      <ButtonHandleMaps childClicked={childClicked} openMap={openMap} setOpenMap={setOpenMap} />
      <div className={openMap ? `${styles.container} ${styles.active}` : styles.container}>
        <div className={styles.map_block}>
          <CardMaps
            user={user}
            favoris={favoris}
            refetch={refetch}
            childClicked={childClicked}
            setChildClicked={setChildClicked}
          />
          {showMap && (
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              zoom={10}
              options={mapOptions}
              onClick={() => setChildClicked(null)}
              onLoad={handleMapLoad}
            >
              {hotel?.lists
                ?.filter(i => i.hotel !== null)
                .map(item => (
                  <CardSmile
                    key={item._id}
                    item={item.hotel || {}}
                    childhover={childhover}
                    childClicked={childClicked}
                    setChildClicked={setChildClicked}
                  />
                ))}
            </GoogleMap>
          )}
        </div>
      </div>
    </>
  )
}

export default Maps
