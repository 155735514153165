import { useState } from 'react'
import styles from '../../../css/account/profile/profil_description.module.css'
import moment from 'moment'
import { Photo } from './profile_descr/Photo'
import VerifiedIdentity from './profile_descr/VerifiedIdentity'
import About from './profile_descr/About'
import ModifProfil from './ModifProfile'
import ActionButtonBlock from './profile_descr/ActionButtonBlock'
import Category from './profile_descr/Category'

const ProfilDescription = ({ userConnected, getUser, refetch }) => {
  const [modifProfil, setModifProfil] = useState(false)

  return (
    <>
      <div className={styles.container}>
        <div>
          <div className={styles.profil_block_img}>
            <Photo userConnected={userConnected} getUser={getUser} />
            <div className={styles.about_block}>
              <div className={styles.about_name}>
                <h2>{getUser.first_name}</h2>
                {getUser.isIdentityValid && (
                  <span>
                    <svg
                      viewBox='0 0 16 16'
                      xmlns='http://www.w3.org/2000/svg'
                      aria-hidden='true'
                      role='presentation'
                      focusable='false'
                      style={{
                        height: '14px',
                        width: '14px',
                        fill: 'currentColor',
                      }}
                    >
                      <path d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.159 4.869L6.67 9.355 4.42 7.105 3.289 8.236 6.67 11.62 12.291 6z'></path>
                    </svg>
                  </span>
                )}
              </div>
              <h3>Membre depuis {moment(getUser.createdAt).format('LL').slice(2)}</h3>
              <Category getUser={getUser} />
              <div className={styles.about_pc}>
                <About userConnected={userConnected} getUser={getUser} lengthMax={120} />
                <ActionButtonBlock
                  userConnected={userConnected}
                  getUser={getUser}
                  modifProfil={modifProfil}
                  setModifProfil={setModifProfil}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.about_mobile}>
          <About userConnected={userConnected} getUser={getUser} lengthMax={80} />
        </div>
        <div>
          <VerifiedIdentity getUser={getUser} />
        </div>
      </div>
      <div className={styles.about_mobile}>
        <ActionButtonBlock
          userConnected={userConnected}
          getUser={getUser}
          modifProfil={modifProfil}
          setModifProfil={setModifProfil}
        />
      </div>
      {modifProfil && (
        <ModifProfil getUser={getUser} setModifProfil={setModifProfil} refetch={refetch} />
      )}
    </>
  )
}

export default ProfilDescription
