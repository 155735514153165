import styles from '../../../css/affiliations/home/section_steps.module.css'

const SectionStep = () => {
  return (
    <div className={styles.container}>
      <h3>Comment fonctionnent les parrainages d'hôtes</h3>
      <div className={styles.container_block}>
        <div className={styles.block_content}>
          <h4>1</h4>
          <div>
            <h5>Parrainez des amis</h5>
            <p>
              Envoyer des invitations de parrainage à vos amis ou à toute personne n'ayant jamais
              accueilli de voyageurs sur Gowifmi.
            </p>
          </div>
        </div>
        <div className={styles.block_content}>
          <h4>2</h4>
          <div>
            <h5>Suivez leur progression</h5>
            <p>Suivez leur progression et envoyez-leur des messages d'encouragement.</p>
          </div>
        </div>
        <div className={styles.block_content}>
          <h4>3</h4>
          <div>
            <h5>Recevez vos paiements</h5>
            <p>
              Une fois votre filleul aura commencer son activité d'hôte, vous recevrez votre
              paiement après le départ du premier voyageur.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionStep
