import { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { User } from '../../../../context/UserContext'
import Header from '../../../../components/traveler/travel/Header'
import ErrorServer from '../../../errors/ErrorServer'
import { useQuery } from 'react-query'
import ListReservation from '../../../../components/traveler/travel/ListReservation'

const ListUpcoming = () => {
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)

  const user = useContext(User)
  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
    if (user === null) {
      history.push('/')
    }
  }, [page])

  const fetchReservations = (page = 1, reference) =>
    axios
      .get(`/user_reservations/all_reservation_future?page=${page}&reference=${reference}`)
      .then(res => {
        return res.data
      })

  const {
    data: reservation = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ['get-user-allreservationAvenir', page, search],
    queryFn: () => fetchReservations(page, search),
  })

  if (error) return <ErrorServer />

  return (
    <>
      <Header setSearch={setSearch} setPage={setPage} />
      <ListReservation reservation={reservation} loading={isLoading} setPage={setPage} />
    </>
  )
}

export default ListUpcoming
