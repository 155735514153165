import styles from '../../../../../css/modehotes/reservation/detail.module.css'
import { STATUS_BOOKING } from '../../../../../enums/booking/status.enums'

const TravelerInformation = ({ reservation }) => {
  return (
    <div className={styles.block}>
      <h2>Informations personnelles</h2>
      <div className={styles.content}>
        <h3>Prénom</h3>
        <p>{reservation?.user_id?.first_name || 'Non défini'}</p>
      </div>
      {reservation.status === STATUS_BOOKING.DELETED ||
      reservation.status === STATUS_BOOKING.CANCELLED ||
      reservation.status === STATUS_BOOKING.REQUEST ? null : (
        <div>
          <div className={styles.content}>
            <h3>E-mail</h3>
            <p>{reservation?.user_id?.email || 'Non défini'}</p>
          </div>
          <div className={styles.content}>
            <h3>Téléphone</h3>
            <p>{reservation?.client_infos?.phone || 'Non défini'}</p>
          </div>
        </div>
      )}
      <div className={styles.content}>
        <h3>Pays</h3>
        <p>{reservation?.client_infos?.client_country || 'Non défini'}</p>
      </div>
    </div>
  )
}

export default TravelerInformation
