import { useState } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const Pricing = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [tarif, setTarif] = useState()
  const { loading, updateData } = useUpdateHotel(setOpen)

  const minmum = 10
  const maximum = 98661
  const handleSubmit = e => {
    e.preventDefault()
    const datas = {
      prices: {
        ...annonce.prices,
        nightly_price: tarif,
      },
    }
    updateData(datas)
  }
  const openFuction = () => {
    setTarif(annonce.prices?.nightly_price)
    setOpen(true)
  }
  const reset = () => {
    setTarif()
    setOpen(false)
  }
  const handleChange = event => {
    const result = event.target.value.replace(/\D/g, '')
    setTarif(result)
  }
  return (
    <div className={styles.block}>
      {!open ? (
        <Section title='Prix par nuit' handleOpen={openFuction}>
          <p>Vous choisissez le prix qui vous convient pour votre logement.</p>
          <p>Prix de base : {annonce.prices?.nightly_price}$</p>
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title='Prix par nuit' handleReset={reset}></ContenHead>
          <form onSubmit={handleSubmit}>
            <div className={styles.body}>
              <div
                className={
                  tarif < minmum || tarif > maximum
                    ? `${styles.input_block} ${styles.error}`
                    : styles.input_block
                }
              >
                <input type='tel' value={tarif} onChange={handleChange} />
                <div className={styles.price}>
                  <h5>{tarif >= minmum && `${tarif}$`} par nuit</h5>
                  {tarif < minmum || tarif > maximum ? (
                    <p>
                      Veuillez saisir un prix de base compris entre {minmum}$ et {maximum}$.
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <BottomForm
              disabled={tarif < minmum || tarif > maximum}
              loading={loading}
              handleReset={reset}
            />
          </form>
        </div>
      )}
    </div>
  )
}

export default Pricing
