import { useState, useEffect } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import Options from './Options'
import Actives from './Actives'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const Language = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [langues, setLangues] = useState(annonce.languages || [])
  const { loading, updateData } = useUpdateHotel(setOpen)

  const handleSubmit = e => {
    e.preventDefault()
    const datas = {
      languages: langues,
    }
    updateData(datas)
  }
  useEffect(() => {
    setLangues(annonce.languages || [])
  }, [annonce])
  const openFuction = () => {
    setLangues(annonce.languages || [])
    setOpen(true)
  }
  const reset = () => {
    setLangues([])
    setOpen(false)
  }
  return (
    <div className={styles.block}>
      {!open ? (
        <Section title='Langues parlées' handleOpen={openFuction}>
          {annonce.languages?.length > 0 ? <Actives annonce={annonce} /> : <p>Nom défini</p>}
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title='Langues parlées' handleReset={reset}>
            <p>
              Sélectionnez les langues que vous parlées ou celles parlées par les personnel
              travaillant dans l'établissement car les voyageurs peuvent filtrer les résultats de
              recherche, en fonction des langues parlées dans l'établissement.
            </p>
          </ContenHead>
          <form onSubmit={handleSubmit}>
            <div className={styles.body}>
              <div className={styles.input_block}>
                <Options annonce={annonce} langues={langues} setLangues={setLangues} />
              </div>
            </div>
            <BottomForm disabled={langues.length < 1} loading={loading} handleReset={reset} />
          </form>
        </div>
      )}
    </div>
  )
}

export default Language
