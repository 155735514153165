import styles from '../../../../../css/modehotes/annonce/details/securiteVoyageur.module.css'
import Checkbox from '../../../../ui/Checkbox'

const Weapons = ({ armes, setArmes }) => {
  return (
    <div className={styles.checkbox_container}>
      <div>
        <label htmlFor='armes'>Armes sur la propriété</label>
        <p>
          Les voyageurs doivent savoir qu'il y a au moins une arme entreposée dans ce logement.
          Gowifmi exige que toutes les armes soient stockées en toute sécurité.
        </p>
      </div>
      <div>
        <Checkbox id='armes' value={armes} checked={armes} onChange={() => setArmes(!armes)} />
      </div>
    </div>
  )
}

export default Weapons
