import { useState } from 'react'
import styles from '../../../css/account/info_perso.module.css'
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import LoadingPoint from '../../LoadingPoint'
import useUserHook from '../../../hooks/useUserHook'
import { getMsakedString } from '../../../utils/maskString'

const Telephone = ({ active, chageActive }) => {
  const [value, setValue] = useState('')
  const { userHook, loadingUpdate, updateUser, errorUpdate } = useUserHook(chageActive)
  const handleSubmit = e => {
    e.preventDefault()
    const data = {
      phone_number: formatPhoneNumberIntl(value),
    }
    updateUser(data)
  }

  if (errorUpdate) alert('error')

  return (
    <>
      <div
        className={
          active && active !== 5 ? `${styles.form_content} ${styles.disable}` : styles.form_content
        }
      >
        <div className={styles.text}>
          <h3>Numéro de téléphone</h3>
          {active === 5 ? (
            <button type='button' onClick={() => chageActive()}>
              Annuler
            </button>
          ) : (
            <button type='button' disabled={active && active !== 5} onClick={() => chageActive(5)}>
              Ajouter
            </button>
          )}
        </div>

        <div className={styles.modif_block}>
          {active !== 5 ? (
            <>
              <p>
                {userHook.phone_number
                  ? getMsakedString(userHook.phone_number, 4)
                  : 'Information non fournie'}
              </p>
              <p>Saisissez un nouveau numéro de téléphone</p>
            </>
          ) : (
            <form onSubmit={handleSubmit} autoComplete='off'>
              <div className={styles.input_box_phone}>
                <PhoneInput
                  international
                  placeholder='Numéro de téléphone'
                  value={value}
                  onChange={setValue}
                />
                {value && !isValidPhoneNumber(value) && (
                  <span className={styles.erreur}>Saisissez un numéro de téléphone valide</span>
                )}
              </div>
              <div className={styles.footer_btn_block}>
                {loadingUpdate ? (
                  <button disabled className={styles.loading}>
                    <LoadingPoint />
                  </button>
                ) : (
                  <button
                    type='submit'
                    disabled={!value || (value && !isValidPhoneNumber(value))}
                    className={
                      !value || (value && !isValidPhoneNumber(value)) ? styles.loading : null
                    }
                  >
                    Enregistrer
                  </button>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  )
}

export default Telephone
