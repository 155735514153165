import styles from '../../../css/becomeHostes/question.module.css'
import { TYPE_USAGE_ANNONCE } from '../../../enums/logement/type_usage_annonce.enums'

const CategorieHome = ({ typeAnnonce, setTypeAnnonce }) => {
  const data = [
    {
      label: 'Résidence principale',
      value: TYPE_USAGE_ANNONCE.PRIMARY_RESIDENCE,
      desc: `Votre résidence principale est l'endroit où vous vivez la majeure partie de l'année, 
      au moins 8 mois pour être exact (sauf exception)`,
    },
    {
      label: 'Résidence secondaire',
      value: TYPE_USAGE_ANNONCE.SECONDARY_RESIDENCE,
      desc: `Par exemple, un logement de vacances ou un pied-à-terre. Dans certaines villes, l'hébergement de voyageurs dans votre résidence secondaire peut nécessiter deprocéder à un
      « changement d'usage » préalable ou une simple déclaration.`,
    },

    {
      label: 'Non-résidentiel',
      value: TYPE_USAGE_ANNONCE.NON_RESIDENTIAL,
      desc: `une chambre d’hôtel ou une chambre d’hôtes.`,
    },
  ]

  return (
    <div>
      <h2>Indiquez votre type d'annonce</h2>
      <p className={styles.head_p}>
        Dans la plupart des pays, les autorités locales demandent aux hôtes d'effectuer certaines
        tâches avant de commencer à accueillir des voyageurs.
      </p>
      <div>
        <div className={styles.grid_system}>
          {data.map(item => (
            <div className={styles.checkox_contener} key={item.value}>
              <div>
                <label htmlFor={item.value}>{item.label}</label>
                <span className={styles.description}>{item.desc}</span>
              </div>
              <div>
                <input
                  type='radio'
                  id={item.value}
                  value={item.value}
                  checked={typeAnnonce === item.value}
                  onChange={e => setTypeAnnonce(e.target.value)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CategorieHome
