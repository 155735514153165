import { useState, useContext } from 'react'
import { User } from '../context/UserContext'
import axios from 'axios'
import { useQuery } from 'react-query'

const useNotification = () => {
  const [listemessagesUser, setListeMessagesUser] = useState([])
  const [listemessagesHote, setListeMessagesHote] = useState([])
  const [annoncesEnregistrer, setAnnoncesEnregistrer] = useState([])
  const user = useContext(User)

  const {} = useQuery(
    `get-notification`,
    () =>
      axios.get(`/notification-site`).then(res => {
        setListeMessagesUser(res.data?.userMessage)
        setListeMessagesHote(res.data?.hoteMessage)
        setAnnoncesEnregistrer(res.data?.annonceEnregistrer)
      }),
    {
      enabled: !!user,
    }
  )

  const filteredArrayUser = listemessagesUser
    ?.filter(el => el.message_box.some(lists => lists.user_read === false))
    .map(el => {
      let n = Object.assign({}, el, {
        message_box: el.message_box.filter(lists => lists.user_read === false),
      })
      return n
    })

  //filter les messages non lue de l'hote hote
  const filteredArrayHote = listemessagesHote
    ?.filter(el => el.message_box.some(lists => lists.host_read === false))
    .map(el => {
      let n = Object.assign({}, el, {
        message_box: el.message_box.filter(lists => lists.host_read === false),
      })
      return n
    })

  const userMessage = filteredArrayUser.length
  const hoteMessage = filteredArrayHote.length
  const allAnnonce = annoncesEnregistrer > 0 ? 1 : 0
  const totalNotification = userMessage + hoteMessage + allAnnonce
  const totalLengthHote = hoteMessage + allAnnonce
  const arrayNonlueUser = userMessage
  const arrayNonlueHote = hoteMessage
  const allMessagesNonlue = filteredArrayUser.reduce(
    (prev, curr) => curr.message_box.length + prev,
    0
  )
  const allMessagesNonlueHote = filteredArrayHote.reduce(
    (prev, curr) => curr.message_box.length + prev,
    0
  )

  return {
    totalNotification,
    totalLengthHote,
    arrayNonlueUser,
    arrayNonlueHote,
    allMessagesNonlue,
    allMessagesNonlueHote,
  }
}

export default useNotification
