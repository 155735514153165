import { useState } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/apercuPrix.module.css'
import usePrice from './usePrice'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const Overview = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const {
    pricePerNight,
    cleaningFee,
    fraisSejour,
    reductionTime,
    promoFee,
    serviceFee,
    Total,
    revenus,
    numberOfNight,
  } = usePrice(annonce)

  const handleSubmit = () => {
    setOpen(false)
  }
  const openFuction = () => {
    setOpen(true)
  }
  const reset = () => {
    setOpen(false)
  }

  return (
    <div className={styles.block}>
      {!open ? (
        <Section title='Aperçu du prix payé par les voyageurs' handleOpen={openFuction}>
          <p className={styles.allFrais}>{Total}$ au total</p>
          <p>1 nuit · 1 voyageur</p>
          <p>Tous frais compris</p>
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead
            title='Aperçu du prix payé par les voyageurs'
            handleReset={reset}
          ></ContenHead>
          <div className={styles.body}>
            <div className={styles.block_body}>
              <div className={styles.frais}>
                <p>
                  {pricePerNight} $ x {numberOfNight} nuit
                </p>
                <p>{pricePerNight * numberOfNight} $</p>
              </div>
              {fraisSejour > 0 && (
                <div className={styles.frais}>
                  <p>Frais de séjour</p>
                  <p>{fraisSejour} $</p>
                </div>
              )}
              {cleaningFee > 0 && (
                <div className={styles.frais}>
                  <p>Frais de ménage</p>
                  <p>{cleaningFee} $</p>
                </div>
              )}
              {promoFee > 0 && (
                <div className={styles.reduction}>
                  <p>{reductionTime}</p>
                  <p>-{promoFee} $</p>
                </div>
              )}
              <div className={styles.frais}>
                <p>Frais de service voyageur</p>
                <p>{serviceFee} $</p>
              </div>
            </div>
            <div className={styles.total_block}>
              <div className={styles.total}>
                <p>Total</p>
                <p>{Total} $</p>
              </div>
              <div className={styles.revenu}>
                <p>Vos revenus</p>
                <p>{revenus} $</p>
              </div>
            </div>
          </div>
          <BottomForm handleSubmit={handleSubmit} handleReset={reset} />
        </div>
      )}
    </div>
  )
}

export default Overview
