import styles from '../../../css/affiliations/home/question_section.module.css'
import Accordion from '../../ui/Accordion'

const QuestionSection = () => {
  const questions = [
    {
      title: 'Où puis-je trouver le lien de parrainage ?',
      content:
        'Vous pouvez retrouver votre lien de parrainage sur cette page. Connectez-vous et cliquez sur “Partager le lien” ou cliquez sur le lien pour le copier.',
    },
    {
      title: 'Quand vais-je recevoir ma récompense ?',
      content:
        "Votre récompense sera directement versée sur votre compte environ 14 jours après la fin du premier séjour éligible honoré par la personne parrainée. Selon votre mode de versement, il peut s'écouler entre 1 et 7 jours supplémentaires avant que les fonds ne soient crédités sur votre compte.",
    },
    {
      title: 'Peut-on inviter des amis qui ont déjà utilisé Gowifmi ?',
      content:
        "Vous pouvez parrainer quasiment toute personne n'ayant jamais accueilli de voyageurs, même si elle est déjà utilisateur Gowifmi.",
    },
    {
      title: 'Comment puis-je parrainer mes amis ?',
      content:
        'Vous pouvez copier le lien de parrainage et l’envoyer à vos amis par email ou par message. Vous pouvez aussi l’envoyer directement sur Facebook, Twitter, Messenger, WhatsApp ou Instagram.',
    },
    {
      title: 'Combien de personnes puis-je parrainer au maximum ?',
      content:
        "Il n'y a aucune limite au nombre de parrainages que vous pouvez proposer à votre réseau, mais vous ne pouvez obtenir une récompense financière que pour un maximum de 5000$.",
    },
  ]

  return (
    <div className={styles.container}>
      <div className={styles.block_left}>
        <h3>Questions fréquentes</h3>
      </div>
      <div>
        {questions.map((item, i) => (
          <div className={styles.accordion_block} key={i}>
            <Accordion title={item.title} content={item.content} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default QuestionSection
