import { useState, useEffect, useContext } from 'react'
import styles from '../../css/becomeHostes/verifyListing.module.css'
import { User } from '../../context/UserContext'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import axios from 'axios'
import GowifmiLogoSvg from '../../components/svgs/GowifmiLogoSvg'
import LoadingPoint from '../../components/LoadingPoint'
import ErrorServer from '../../components/become_host/errorServer/ErrorServer'
import Information from '../../components/become_host/verify_listings/Information'
import { SATATUS_ANNONCE } from '../../enums/logement/status.enums'
import { Helmet } from 'react-helmet-async'
import { useQuery } from 'react-query'

const VerifyListing = () => {
  const [loading, setLoading] = useState(false)
  const [hotel, setHotel] = useState({})
  const [serverError, setServerError] = useState(false)

  const user = useContext(User)
  const history = useHistory()
  const location = useLocation()
  const id = location.pathname.split('/')[2]

  useEffect(() => {
    if (user === null) {
      history.push('/')
    }
  }, [history, user])

  const { isLoading, error } = useQuery(`get-hotel-on-creation-${id}}`, () =>
    axios.get(`/hotel/oneAnnonce/${id}`).then(res => {
      setHotel(res.data)
      if (res.data.status !== SATATUS_ANNONCE.SAVE) {
        history.push('/hosting')
      }
    })
  )

  const handleSubmit = () => {
    if (user?.identitySubmitted) {
      setLoading(true)
      const datas = {
        status: SATATUS_ANNONCE.PUBLISH,
      }
      axios
        .patch(`/hotel/${id}`, datas)
        .then(() => {
          history.push(`/hosting/listings`)
        })
        .catch(() => {
          setLoading(false)
          setServerError(true)
        })
    } else {
      alert('Attention! vous devez vérifier votre identité')
    }
  }

  if (error || serverError) {
    return <ErrorServer />
  }

  return (
    <>
      <Helmet>
        <title>Comfirmez les informations de l'annonce - Gowifmi</title>
      </Helmet>
      <div>
        <div className={styles.navbar}>
          <div className={styles.good_logo}>
            <a href='/hosting'>
              <GowifmiLogoSvg />
            </a>
          </div>
        </div>
        {isLoading ? (
          <div className={styles.loading}>
            <LoadingPoint />
          </div>
        ) : (
          <div className={styles.container}>
            <div>
              <div className={styles.block_2}>
                <div className={styles.photo}>
                  {hotel?.images?.length > 0 && <img src={hotel.images[0]?.url} alt='' />}
                </div>
                <div className={styles.content}>
                  <h4>{hotel.title}</h4>
                  <p>{hotel.address?.full_address}</p>
                </div>
              </div>
            </div>
            <div>
              <Information user={user} />
            </div>
          </div>
        )}
        <div className={styles.step_buttons}>
          <div className={styles.step_container}>
            {loading ? (
              <button disabled className={styles.loading}>
                <LoadingPoint />
              </button>
            ) : (
              <button
                type='button'
                onClick={handleSubmit}
                disabled={!user?.phone_number || !user?.identitySubmitted}
                className={
                  !user?.phone_number || !user?.identitySubmitted
                    ? `${styles.next} ${styles.disable}`
                    : styles.next
                }
              >
                Publier l'annonce
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default VerifyListing
