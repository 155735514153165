import { useSearchParams } from '../../../context/SearchParamContext'

const OptionReservationFilterInline = () => {
  const array = [
    { name: 'Réservation instantanée', value: 'instant_booking' },
    { name: 'Annulation gratuite', value: 'cancellation_policy' },
  ]

  const { params, updateParams } = useSearchParams()

  const onchangeFilters = e => {
    const newParams = new URLSearchParams(params.toString())
    if (!e.target.checked) {
      newParams.delete('page')
      newParams.delete(e.target.value)
    } else {
      newParams.delete('page')
      newParams.append(e.target.value, 'true')
    }
    updateParams(newParams)
  }

  return (
    <>
      {array.map(item => (
        <label key={item.value}>
          <input
            type='checkbox'
            value={item.value}
            checked={params.has(item.value)}
            onChange={onchangeFilters}
          />
          <span>{item.name}</span>
        </label>
      ))}
    </>
  )
}

export default OptionReservationFilterInline
