import styles from '../../../../../../../../css/modehotes/annonce/details/desactivate.module.css'

const Succes = ({ handleCloseRedirect }) => {
  return (
    <>
      <div className={styles.m_body}>
        <div>
          <h3>Désactivation éffectuée avec succès</h3>
          <p>
            Veuillez noter que la désactivation de votre annonce est une action irréversible jusqu'à
            ce que vous choisissiez de la réactiver. Si vous décidez de réactiver votre annonce,
            veuillez contacter notre service d'assistance dans les 30 jours suivant la
            désactivation. Passé ce délai, votre annonce sera définitivement supprimée de notre
            plateforme.
          </p>
        </div>
      </div>
      <div className={styles.button_action}>
        <div></div>
        <button className={styles.next} onClick={handleCloseRedirect}>
          Ok
        </button>
      </div>
    </>
  )
}

export default Succes
