import styles from '../../../../../css/modehotes/reservation/commentaire.module.css'

const Rating = ({ state, setState }) => {
  const SVGIconEmpty = () => (
    <svg
      style={{ width: '26px', height: '26px' }}
      viewBox='0 0 1024 1024'
      xmlns='http://www.w3.org/2000/svg'
      pointerEvents='none'
    >
      <path d='M1024 397.050l-353.78-51.408-158.22-320.582-158.216 320.582-353.784 51.408 256 249.538-60.432 352.352 316.432-166.358 316.432 166.358-60.434-352.352 256.002-249.538zM512 753.498l-223.462 117.48 42.676-248.83-180.786-176.222 249.84-36.304 111.732-226.396 111.736 226.396 249.836 36.304-180.788 176.222 42.678 248.83-223.462-117.48z'></path>
    </svg>
  )

  const SVGIconFull = () => (
    <svg
      style={{ width: '26px', height: '26px' }}
      viewBox='0 0 1024 1024'
      xmlns='http://www.w3.org/2000/svg'
      pointerEvents='none'
    >
      <path d='M1024 397.050l-353.78-51.408-158.22-320.582-158.216 320.582-353.784 51.408 256 249.538-60.432 352.352 316.432-166.358 316.432 166.358-60.434-352.352 256.002-249.538z'></path>
    </svg>
  )

  return (
    <div className={styles.star}>
      {state >= 1 ? (
        <button type='button' onClick={() => setState(1)}>
          <SVGIconFull />
        </button>
      ) : (
        <button type='button' onClick={() => setState(1)}>
          <SVGIconEmpty />
        </button>
      )}
      {state >= 2 ? (
        <button type='button' onClick={() => setState(2)}>
          <SVGIconFull />
        </button>
      ) : (
        <button type='button' onClick={() => setState(2)}>
          <SVGIconEmpty />
        </button>
      )}
      {state >= 3 ? (
        <button type='button' onClick={() => setState(3)}>
          <SVGIconFull />
        </button>
      ) : (
        <button type='button' onClick={() => setState(3)}>
          <SVGIconEmpty />
        </button>
      )}
      {state >= 4 ? (
        <button type='button' onClick={() => setState(4)}>
          <SVGIconFull />
        </button>
      ) : (
        <button type='button' onClick={() => setState(4)}>
          <SVGIconEmpty />
        </button>
      )}
      {state >= 5 ? (
        <button type='button' onClick={() => setState(5)}>
          <SVGIconFull />
        </button>
      ) : (
        <button type='button' onClick={() => setState(5)}>
          <SVGIconEmpty />
        </button>
      )}
    </div>
  )
}
export default Rating
