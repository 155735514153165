import { useState } from 'react'
import globalStyle from '../../../css/becomeHostes/globalStyle.module.css'
import styles from '../../../css/becomeHostes/location.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import { GoogleMap } from '@react-google-maps/api'
import LoadingPoint from '../../LoadingPoint'
import Autocomplete from './Autocomplete'
import Formulaire from './Formulaire'
import ReviewMpas from './ReviewMpas'
import ErrorServer from '../errorServer/ErrorServer'
import useAddHome from '../../../hooks/useAddHome'
import HeaderForm from '../globals/HeaderForm'
import mapStyles from '../../../utils/mapStyles'

const Maps = ({ reviewMap, setReviewMap }) => {
  const [loading, setLoading] = useState(false)
  const [manuelAdress, setManuelAdress] = useState(false)
  const [fullAddress, setFullAddress] = useState('')
  const [appartement, setAppartement] = useState('')
  const [ville, setVille] = useState('')
  const [etat, setEtat] = useState('')
  const [codePostal, setCodePostal] = useState('')
  const [pays, setPays] = useState('')
  const [countryAbbreviation, setCountryAbbreviation] = useState('')
  const [coords, setCoords] = useState(null)
  const [serverError, setServerError] = useState(false)
  const { updateData } = useAddHome(setLoading, setServerError)

  const history = useHistory()
  const location = useLocation()
  const page = location.pathname.split('/')[2]
  const id = location.pathname.split('/')[3]
  const handleSubmit = () => {
    setLoading(true)
    const datas = {
      redirect_url: page,
      location: {
        coordinates: [coords.lng, coords.lat],
      },
      address: {
        full_address: fullAddress,
        apartment_name: appartement,
        city: ville,
        state: etat,
        postal_code: codePostal,
        country: pays,
        country_code: countryAbbreviation,
      },
    }
    updateData(datas, '/become-a-host/traveler-infos')
  }

  const intialCoords = {
    lat: 40.712776,
    lng: -74.005974,
  }

  if (serverError) {
    return <ErrorServer />
  }

  return (
    <div className={globalStyle.left}>
      <HeaderForm step={4} />
      {!reviewMap ? (
        <div className={styles.block_center}>
          {!manuelAdress ? (
            <Autocomplete
              setManuelAdress={setManuelAdress}
              setFullAddress={setFullAddress}
              setVille={setVille}
              setCodePostal={setCodePostal}
              setEtat={setEtat}
              setPays={setPays}
              setCountryAbbreviation={setCountryAbbreviation}
              setCoords={setCoords}
            />
          ) : (
            <Formulaire
              setManuelAdress={setManuelAdress}
              fullAddress={fullAddress}
              setFullAddress={setFullAddress}
              appartement={appartement}
              setAppartement={setAppartement}
              ville={ville}
              setVille={setVille}
              etat={etat}
              setEtat={setEtat}
              codePostal={codePostal}
              setCodePostal={setCodePostal}
              pays={pays}
              setPays={setPays}
              countryAbbreviation={countryAbbreviation}
              setCountryAbbreviation={setCountryAbbreviation}
              setCoords={setCoords}
              setReviewMap={setReviewMap}
            />
          )}
          <div className={styles.map_block}>
            <GoogleMap
              mapContainerStyle={{ width: '100%', height: '100%' }}
              center={coords || intialCoords}
              zoom={14}
              options={{
                disableDefaultUI: true,
                zoomControl: false,
                minZoom: 1,
                gestureHandling: 'greedy',
                streetViewControl: false,
                fullscreenControl: false,
                scrollwheel: false,
                clickableIcons: false,
                styles: mapStyles,
              }}
            ></GoogleMap>
          </div>
        </div>
      ) : (
        <ReviewMpas fullAddress={fullAddress} coords={coords} setCoords={setCoords} />
      )}
      <div className={globalStyle.step_buttons}>
        <button
          type='button'
          onClick={() => {
            reviewMap ? setReviewMap(false) : history.push(`/become-a-host/type-accomodation/${id}`)
          }}
          className={globalStyle.retour}
        >
          Retour
        </button>
        {loading ? (
          <button disabled className={globalStyle.loading}>
            <LoadingPoint />
          </button>
        ) : (
          <button
            type='button'
            disabled={!reviewMap}
            onClick={handleSubmit}
            className={!reviewMap ? `${globalStyle.next} ${globalStyle.disable}` : globalStyle.next}
          >
            Suivant
          </button>
        )}
      </div>
    </div>
  )
}

export default Maps
