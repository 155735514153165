const ErrorSvg = ({ height = 14, width = 14 }) => {
  return (
    <>
      <svg
        viewBox='0 0 16 16'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'
        role='presentation'
        focusable='false'
        style={{ width: `${width}px`, height: `${height}px`, display: 'block', fill: ' #c13515' }}
      >
        <g>
          <path d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm0 10.2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm.8-6.6H7.2v5.2h1.6z'></path>
        </g>
      </svg>
    </>
  )
}

export default ErrorSvg
