import { useEffect, useContext, useRef } from 'react'
import styles from '../../css/affiliations/sponsor_container.module.css'
import ListSponsor from '../../components/affiliations/sponsors/ListSponsor'
import { Helmet } from 'react-helmet-async'
import Navigation from '../../components/affiliations/global_components/Navigation'
import { Link } from 'react-router-dom'
import { User } from '../../context/UserContext'
import Login from '../../components/login'
import DefaultLayout from '../../layouts/DefaultLayout'

const Sponsored = () => {
  const user = useContext(User)

  const connexionRef = useRef()
  const handleConnection = params => {
    connexionRef.current.OpenModal(params)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <DefaultLayout footer={true} mobileBottomNavigate={true}>
      <Helmet>
        <title>Vos filleuls | Gowifmi</title>
      </Helmet>
      <Navigation />
      <div className={styles.main_home}>
        <div className={styles.header}>
          <Link to='/sponsorship/home'>
            <svg
              viewBox='0 0 32 32'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'
              role='presentation'
              focusable='false'
              style={{
                display: 'block',
                height: '16px',
                width: '16px',
                stroke: 'currentcolor',
                strokeWidth: '3',
                fill: 'none',
                overflow: 'visible',
              }}
            >
              <g>
                <path d='m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932'></path>
              </g>
            </svg>
          </Link>
        </div>

        {user ? (
          <ListSponsor />
        ) : (
          <div className={styles.connexion_block}>
            <h2>Connectez-vous pour consulter vos filleuls</h2>
            <button type='button' className={styles.connexion_btn} onClick={handleConnection}>
              Connectez-vous
            </button>
          </div>
        )}
      </div>

      <Login ref={connexionRef} />
    </DefaultLayout>
  )
}

export default Sponsored
