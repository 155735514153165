import React, { useState } from 'react'
import styles from '../../../../../css/booking/confirmLoad.module.css'
import LoadingPoint from '../../../../LoadingPoint'
import Modal from '../../../../ui/Modal'
import GowifmiIconSvg from '../../../../svgs/GowifmiIconSvg'

const ConfirmLoad = React.forwardRef(({}, ref) => {
  const [modal, setModal] = useState(false)
  React.useImperativeHandle(ref, () => ({
    OpenModal: params => {
      setModal(params)
    },
  }))

  return (
    <>
      <Modal isOpen={modal} onClose={() => {}}>
        <div className={styles.detail_content}>
          <div className={styles.dispositif}>
            <div className={styles.img_block}>
              <GowifmiIconSvg />
            </div>
            <h4>Nous préparons votre voyage</h4>
            <LoadingPoint />
          </div>
        </div>
      </Modal>
    </>
  )
})

export default ConfirmLoad
