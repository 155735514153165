import { useState, useRef, useEffect } from 'react'
import styles from '../../../../css/traveler/inbox/navigation.module.css'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { useQuery } from 'react-query'
import axios from 'axios'
import useNotification from '../../../../hooks/useNotification'

const Head = ({ filter }) => {
  const [togle, setTogle] = useState(false)
  const { arrayNonlueUser } = useNotification()
  const location = useLocation()
  const path = location.pathname.split('/')[1]

  const closeTogle = () => {
    setTogle(false)
  }

  const { refetch, data: listemessages = [] } = useQuery(`get-allmessage-user}`, () =>
    axios.get(`/message`).then(res => {
      return res.data
    })
  )

  const contenttogle = useRef(null)
  useEffect(() => {
    refetch()
    const handleClickOutside = event => {
      if (contenttogle.current && !contenttogle.current.contains(event.target)) {
        setTogle(false)
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [refetch])

  return (
    <div className={styles.navigation}>
      <div className={styles.links}>
        <Link to='/inbox' className={path === 'inbox' ? styles.active : null}>
          {filter === 'all' && 'Messages'}
          {filter === 'hidden' && 'Archivés'}
          {filter === 'unread' && 'Non lues'}
          {filter === 'all' ? <>{arrayNonlueUser > 0 && <span>{arrayNonlueUser}</span>}</> : null}
        </Link>
        <Link to='/notifications' className={path === 'notifications' ? styles.active : null}>
          Notifications
        </Link>
      </div>
      {path === 'inbox' && (
        <div ref={contenttogle} className={styles.icon_block}>
          <button
            type='button'
            className={togle ? `${styles.icon} ${styles.active}` : styles.icon}
            onClick={() => setTogle(!togle)}
          >
            <svg
              viewBox='0 0 16 16'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'
              role='presentation'
              focusable='false'
              style={{
                display: 'block',
                height: '18px',
                width: '18px',
                fill: 'currentcolor',
              }}
            >
              <path d='M3 6a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm5 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm5 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4z'></path>
            </svg>
          </button>
          <div className={togle ? `${styles.modal} ${styles.active}` : styles.modal}>
            <Link
              to='/inbox?filter=all'
              className={filter === 'all' || filter === null ? styles.active : ''}
              onClick={closeTogle}
            >
              Toutes les conversations
            </Link>
            <Link
              to='/inbox?filter=hidden'
              className={filter === 'hidden' ? styles.active : ''}
              onClick={closeTogle}
            >
              Conversations archivées
              <span>({listemessages.filter(mes => mes.user_archived === true).length})</span>
            </Link>
            <Link
              to='/inbox?filter=unread'
              className={filter === 'unread' ? styles.active : ''}
              onClick={closeTogle}
            >
              Conversations non lues<span>({arrayNonlueUser})</span>
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}

export default Head
