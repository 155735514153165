import { useState } from 'react'
import globalStyle from '../../../css/becomeHostes/globalStyle.module.css'
import styles from '../../../css/becomeHostes/voyageur.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import axios from 'axios'
import LoadingPoint from '../../LoadingPoint'
import ErrorServer from '../errorServer/ErrorServer'
import useAddHome from '../../../hooks/useAddHome'
import { SATATUS_ANNONCE } from '../../../enums/logement/status.enums'
import HeaderForm from '../globals/HeaderForm'
import { useQuery } from 'react-query'

const Form = () => {
  const [loading, setLoading] = useState(false)
  const [voyageur, setVoyageur] = useState()
  const [chambre, setChambre] = useState()
  const [lit, setLit] = useState()
  const [salleBain, setSalleBain] = useState()
  const [serverError, setServerError] = useState(false)
  const { updateData } = useAddHome(setLoading, setServerError)

  const history = useHistory()
  const location = useLocation()
  const page = location.pathname.split('/')[2]
  const id = location.pathname.split('/')[3]

  const { isLoading, error } = useQuery(`get-hotel-on-creation-${id}}`, () =>
    axios.get(`/hotel/oneAnnonce/${id}`).then(res => {
      setVoyageur(res.data.details?.traveler)
      setChambre(res.data.details?.number_of_rooms)
      setLit(res.data.details?.number_of_beds)
      setSalleBain(res.data.details?.number_of_bathrooms)
      if (res.data.status !== SATATUS_ANNONCE.IN_PROGRESS) {
        history.push('/hosting')
      }
    })
  )

  const handleSubmit = () => {
    setLoading(true)
    const datas = {
      redirect_url: page,
      details: {
        traveler: voyageur,
        number_of_rooms: chambre,
        number_of_beds: lit,
        number_of_bathrooms: salleBain,
      },
    }
    updateData(datas, '/become-a-host/amenities')
  }
  const incrementeVoyageur = () => {
    setVoyageur(c => c + 1)
  }
  const decrementeVoyageur = () => {
    setVoyageur(c => c - 1)
  }
  const incrementeChambre = () => {
    setChambre(c => c + 1)
  }
  const decrementeChambre = () => {
    setChambre(c => c - 1)
  }
  const incrementeLit = () => {
    setLit(c => c + 1)
  }
  const decrementeLit = () => {
    setLit(c => c - 1)
  }
  const incrementeSalleBain = () => {
    setSalleBain(salleBain + 0.5)
  }
  const decrementeSalleBain = () => {
    setSalleBain(salleBain - 0.5)
  }

  if (error || serverError) {
    return <ErrorServer />
  }

  return (
    <div className={globalStyle.left}>
      <HeaderForm step={5} />
      <div className={globalStyle.block_center}>
        <div className={globalStyle.block_form}>
          {isLoading ? (
            <LoadingPoint />
          ) : (
            <div className={globalStyle.annimcontent}>
              <div className={styles.info_search}>
                <div className={styles.block_flex}>
                  <div>
                    <h3>Voyageurs</h3>
                  </div>
                  <div className={styles.occup_count}>
                    <button
                      type='button'
                      disabled={voyageur === 1 ? true : false}
                      className={voyageur === 1 ? styles.disable : null}
                      onClick={decrementeVoyageur}
                    >
                      <svg
                        className={styles.decr}
                        viewBox='0 0 32 32'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                        role='presentation'
                        focusable='false'
                      >
                        <path d='m2 16h28'></path>
                      </svg>
                    </button>
                    <span>{voyageur}</span>
                    <button
                      type='button'
                      disabled={voyageur >= 30 ? true : false}
                      className={voyageur === 30 ? styles.disable : null}
                      onClick={incrementeVoyageur}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className={styles.block_flex}>
                  <div>
                    <h3>Chambres</h3>
                  </div>
                  <div className={styles.occup_count}>
                    <button
                      type='button'
                      disabled={chambre === 1 ? true : false}
                      className={chambre === 1 ? styles.disable : null}
                      onClick={decrementeChambre}
                    >
                      <svg
                        className={styles.decr}
                        viewBox='0 0 32 32'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                        role='presentation'
                        focusable='false'
                      >
                        <path d='m2 16h28'></path>
                      </svg>
                    </button>
                    <span>{chambre}</span>
                    <button
                      type='button'
                      disabled={chambre >= 50 ? true : false}
                      className={chambre === 50 ? styles.disable : null}
                      onClick={incrementeChambre}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className={styles.block_flex}>
                  <div>
                    <h3>Lits</h3>
                  </div>
                  <div className={styles.occup_count}>
                    <button
                      type='button'
                      disabled={lit === 1 ? true : false}
                      className={lit === 1 ? styles.disable : null}
                      onClick={decrementeLit}
                    >
                      <svg
                        className={styles.decr}
                        viewBox='0 0 32 32'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                        role='presentation'
                        focusable='false'
                      >
                        <path d='m2 16h28'></path>
                      </svg>
                    </button>
                    <span>{lit}</span>
                    <button
                      type='button'
                      disabled={lit >= 50 ? true : false}
                      className={lit === 50 ? styles.disable : null}
                      onClick={incrementeLit}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className={styles.block_flex}>
                  <div>
                    <h3>Salles de bain</h3>
                  </div>
                  <div className={styles.occup_count}>
                    <button
                      type='button'
                      disabled={salleBain === 0.5 ? true : false}
                      className={salleBain === 0.5 ? styles.disable : null}
                      onClick={decrementeSalleBain}
                    >
                      <svg
                        className={styles.decr}
                        viewBox='0 0 32 32'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                        role='presentation'
                        focusable='false'
                      >
                        <path d='m2 16h28'></path>
                      </svg>
                    </button>
                    <span>{salleBain}</span>
                    <button
                      type='button'
                      disabled={salleBain >= 50 ? true : false}
                      className={salleBain === 50 ? styles.disable : null}
                      onClick={incrementeSalleBain}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={globalStyle.step_buttons}>
        <button
          type='button'
          onClick={() => history.push(`/become-a-host/location/${id}`)}
          className={globalStyle.retour}
        >
          Retour
        </button>
        {loading ? (
          <button disabled className={globalStyle.loading}>
            <LoadingPoint />
          </button>
        ) : (
          <button type='button' onClick={handleSubmit} className={globalStyle.next}>
            Suivant
          </button>
        )}
      </div>
    </div>
  )
}

export default Form
