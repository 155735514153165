import { useEffect, useContext } from 'react'
import styles from '../../../css/account/securite.module.css'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { User } from '../../../context/UserContext'
import LoadingPoint from '../../../components/LoadingPoint'
import useUserHook from '../../../hooks/useUserHook'
import DefaultLayout from '../../../layouts/DefaultLayout'
import AllAccountSetings from '../../../components/accounts/AllAccountSetings'

const WorkTravel = () => {
  const { register, handleSubmit } = useForm()
  const { userHook, loadingUpdate, updateUser } = useUserHook()

  const user = useContext(User)
  const history = useHistory()
  useEffect(() => {
    window.scrollTo(0, 0)
    if (user === null) {
      history.push('/')
    }
  }, [])

  const onSubmit = data => {
    updateUser(data)
  }

  return (
    <DefaultLayout footer={true} navTopMobile={true}>
      <Helmet>
        <title>Rejoignez Gowifmi for Work</title>
      </Helmet>
      <div className={styles.seting_container}>
        <div className={styles.seting_box}>
          <div className={styles.AllAccount_setings}>
            <AllAccountSetings />
          </div>
          <div className={styles.separate}> </div>
          <div className={styles.securite_1}>
            <div>
              <h1>Voyage d'affaires</h1>
            </div>
            <p>Rejoignez Gowifmi for Work</p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label htmlFor='email'>
                  Ajoutez votre adresse e-mail professionnelle pour bénéficier d'une gestion
                  simplifiée des notes de frais et d'offres exclusives sur vos voyages d'affaires
                </label>
                <input
                  type='email'
                  id='email'
                  defaultValue={userHook.work_email}
                  {...register('work_email', { required: true })}
                />
              </div>
              <div className={styles.buttoms}>
                {loadingUpdate ? (
                  <button disabled className={styles.loading}>
                    <LoadingPoint />
                  </button>
                ) : (
                  <button type='submit'>Ajouter l'adresse e-mail</button>
                )}
              </div>
            </form>
          </div>
        </div>
        <div className={styles.seting_box_2}>
          <h2>Défraiement simplifié</h2>
          <p className={styles.p_1}>
            Vous recevrez par e-mail les reçus des voyages d'affaires dans votre boîte de réception
            professionnelle pour faciliter la gestion des notes de frais.
          </p>
          <h2>Description du voyage</h2>
          <p>
            Ajoutez un code à des fins de défraiement et un motif professionnel à vos voyages
            d'affaires.
          </p>
        </div>
      </div>
    </DefaultLayout>
  )
}

export default WorkTravel
