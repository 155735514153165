import styles from '../../../css/details/equipement.module.css'
import CheckAmenitieSvg from './components/CheckAmenitieSvg'

const AirConditioner = ({ hotel }) => {
  const { amenities } = hotel

  return (
    <div className={styles.container_equip}>
      <h4>Chauffage et climatisation</h4>
      {amenities?.includes('8') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Climatisation centrale</p>
        </div>
      )}
      {amenities?.includes('9') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Chauffage central</p>
        </div>
      )}
      {amenities?.includes('91') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Ventilateur de plafond</p>
        </div>
      )}
      {amenities?.includes('16') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Cheminée</p>
        </div>
      )}
      {amenities?.includes('92') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Ventilateurs portables</p>
        </div>
      )}
    </div>
  )
}

export default AirConditioner
