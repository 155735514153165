import { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import styles from '../../../../css/traveler/favoris/header.module.css'
import Partage from '../partager/Partage'
import Parameter from './Parameter'

const Header = ({ favoris, refetch, user }) => {
  const partage = useRef()
  const ModalPartage = () => {
    partage.current.OpenModal()
  }
  const parameter = useRef()
  const ModalParameter = () => {
    parameter.current.OpenModal()
  }
  const history = useHistory()

  const handleRedirect = () => {
    if (history.length > 1) {
      history.goBack()
    } else {
      const Url = `/wishlists`
      history.push(Url)
    }
  }

  return (
    <>
      <div className={styles.head}>
        <button type='button' className={styles.go_back} onClick={handleRedirect}>
          <svg
            viewBox='0 0 32 32'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            role='presentation'
            focusable='false'
          >
            <g>
              <path d='m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932'></path>
            </g>
          </svg>
        </button>
        {user?.id === favoris?.user_id && (
          <div className={styles.block_right}>
            <button onClick={ModalPartage}>
              <svg
                className={styles.partage}
                viewBox='0 0 32 32'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
              >
                <g>
                  <path d='M27 18v9a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-9'></path>
                  <path d='M16 3v23V3z'></path>
                  <path d='M6 13l9.293-9.293a1 1 0 0 1 1.414 0L26 13'></path>
                </g>
              </svg>
            </button>
            <button onClick={ModalParameter}>
              <svg
                className={styles.plus}
                viewBox='0 0 16 16'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
              >
                <path d='M3 6a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm5 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm5 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4z'></path>
              </svg>
            </button>
          </div>
        )}
      </div>
      <div className={styles.titre_block}>
        <div className={styles.titre}>
          <h1>{favoris?.name}</h1>
        </div>
      </div>
      <Partage ref={partage} favoris={favoris} />
      <Parameter ref={parameter} favoris={favoris} refetch={refetch} />
    </>
  )
}

export default Header
