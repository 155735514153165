import React, { useState } from 'react'
import styles from '../../../../../css/search_room_result/more_actions/share_hotel.module.css'

import {
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share'

import { CopyToClipboard } from 'react-copy-to-clipboard'
import Modal from '../../../../ui/Modal'
import Snackbar from '../../../../ui/Snackbar'
import CloseButton from '../../../../ui/CloseButton'

const ShareHotel = React.forwardRef(({ item }, ref) => {
  const [modal, setModal] = useState(false)
  const [copy, setCopy] = useState(false)

  const origin = window.location.origin
  const Query = window.location.search
  const Params = new URLSearchParams(Query)

  const url = `${origin}/rooms/${item._id}?${Params}`

  const title = 'Découvrer ce logement chez Gowifmi'
  const imageAnnonce = item?.images[0]?.url

  const sizeView = window.matchMedia('(max-width:743px)')

  React.useImperativeHandle(ref, () => ({
    openModal: () => {
      if (sizeView.matches) {
        handleShareNative()
      } else {
        setModal(true)
      }
    },
  }))

  const handleclose = () => {
    setModal(false)
  }

  const handleClickcopy = () => {
    setCopy(true)
  }

  const handleClosecopy = () => {
    setCopy(false)
  }

  const handleShareNative = async () => {
    if ('share' in navigator) {
      try {
        const shareData = {
          title: 'Partager cette annonce',
          url,
        }
        await navigator.share(shareData)
      } catch (error) {
        console.log("Une erreur s'est produite. veuillez réessayer")
      }
    } else {
      alert("Une erreur s'est produite. veuillez réessayer")
    }
  }

  return (
    <>
      <Modal isOpen={modal} onClose={handleclose} useStoragescrollbarWidth={true}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={handleclose} />
            <h3>Partager cette annonce</h3>
            <div></div>
          </div>
          <div className={styles.m_body}>
            <div className={styles.image_partage}>
              <div className={styles.image_couv}>
                <img src={imageAnnonce} alt='' />
              </div>
              <div>
                <p>{item.title}</p>
              </div>
            </div>
            <div className={styles.reseaux_partage}>
              <div className={styles.icon_block}>
                <CopyToClipboard text={url} onCopy={handleClickcopy}>
                  <button className={styles.copy_lien}>
                    <svg
                      viewBox='0 0 32 32'
                      xmlns='http://www.w3.org/2000/svg'
                      aria-hidden='true'
                      role='presentation'
                      focusable='false'
                      style={{
                        display: 'block',
                        height: '24px',
                        width: '24px',
                        fill: 'currentcolor',
                      }}
                    >
                      <path d='m19.0744181 13.9994653.1730353.1672949-1.4142136 1.4142136c-1.9012367-1.9012367-4.9526642-1.9512693-6.9144372-.1500977l-.1566306.1500977-3.95456016 3.9545601c-1.56209716 1.5620972-1.56209716 4.0947571 0 5.6568543 1.51476089 1.5147608 3.94215376 1.5606627 5.51222566.1377055l.1446286-.1377055 3.7974253-3.7974254 1.4142136 1.4142136-3.7974253 3.7974253c-2.3431458 2.3431458-6.14213567 2.3431458-8.48528142 0-2.28989244-2.2898924-2.34193545-5.9702062-.15612903-8.3232996l.15612903-.1619818 3.95456015-3.9545601c2.67671857-2.6767186 6.98188897-2.7324836 9.72645967-.1672949zm7.5321836-8.60606702c2.2898925 2.28989244 2.3419355 5.97020622.156129 8.32329962l-.156129.1619818-3.9545601 3.9545601c-2.6767186 2.6767186-6.981889 2.7324836-9.7264597.1672949l-.1730353-.1672949 1.4142136-1.4142136c1.9012367 1.9012367 4.9526642 1.9512693 6.9144372.1500977l.1566306-.1500977 3.9545602-3.9545601c1.5620971-1.5620972 1.5620971-4.09475709 0-5.65685426-1.5147609-1.51476088-3.9421538-1.56066273-5.5122257-.13770553l-.1446286.13770553-3.7974253 3.79742536-1.4142136-1.41421361 3.7974253-3.79742531c2.3431458-2.34314575 6.1421357-2.34314575 8.4852814 0z'></path>
                    </svg>
                    <span>Copier le lien</span>
                  </button>
                </CopyToClipboard>
                <EmailShareButton className={styles.icon} url={url} quote={title}>
                  <EmailIcon size={40} round={true} />
                  <h4>Adresse e-mail</h4>
                </EmailShareButton>
                <WhatsappShareButton className={styles.icon} url={url}>
                  <WhatsappIcon size={40} round={true} />
                  <h4>WhatsApp</h4>
                </WhatsappShareButton>
                <FacebookMessengerShareButton className={styles.icon} url={url}>
                  <FacebookMessengerIcon size={40} round={true} />
                  <h4>Messenger</h4>
                </FacebookMessengerShareButton>
                <FacebookShareButton className={styles.icon} url={url} quote={title}>
                  <FacebookIcon size={40} round={true} />
                  <h4>Facebook</h4>
                </FacebookShareButton>
                <TwitterShareButton className={styles.icon} url={url}>
                  <TwitterIcon size={40} round={true} />
                  <h4>Twitter</h4>
                </TwitterShareButton>
                <button className={styles.copy_lien} onClick={handleShareNative}>
                  <svg
                    viewBox='0 0 16 16'
                    xmlns='http://www.w3.org/2000/svg'
                    aria-hidden='true'
                    role='presentation'
                    focusable='false'
                    style={{
                      display: 'block',
                      height: '18px',
                      width: '18px',
                      fill: 'currentColor',
                    }}
                  >
                    <path d='M3 6a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm5 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm5 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4z'></path>
                  </svg>
                  <span>Plus d'options</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/***----modal de copie */}
      <Snackbar
        isOpen={copy}
        onClose={handleClosecopy}
        autoHideDuration={1000}
        message='Lien copié'
      />
    </>
  )
})
export default ShareHotel
