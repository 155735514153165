import { Link } from 'react-router-dom'
import { useState } from 'react'
import styles from '../../css/confirmations/connexion.module.css'
import ContentLogin from '../login/contents/ContentLogin'

const Connexion = () => {
  const [active, setActive] = useState('connexion')

  return (
    <>
      <title>Connectez-vous / Gowifmi</title>
      <div className={styles.navbar}>
        <Link to='/'>
          <img src='/gowifmi.png' alt='logo' />
        </Link>
      </div>
      <div className={styles.main}>
        <div className={styles.container}>
          <ContentLogin active={active} setActive={setActive} />
        </div>
      </div>
    </>
  )
}

export default Connexion
