import { useState, useContext } from 'react'
import styles from '../../../css/traveler/favoris/favoriListe.module.css'
import { User } from '../../../context/UserContext'
import axios from 'axios'
import { useLocation } from 'react-router'
import CardList from '../../../components/traveler/favoris/listFavorites/CardList'
import Maps from '../../../components/traveler/favoris/maps/Maps'
import LoadingPoint from '../../../components/LoadingPoint'
import Header from '../../../components/traveler/favoris/listFavorites/Header'
import { Link } from 'react-router-dom'
import Connexion from '../../../components/traveler/favoris/Connexion'
import DefaultLayout from '../../../layouts/DefaultLayout'
import { useQuery } from 'react-query'
import { Helmet } from 'react-helmet-async'

function ListFavoriteById() {
  const [childhover, setChildchildhover] = useState(null)
  const [childClicked, setChildClicked] = useState(null)
  const user = useContext(User)
  const location = useLocation()
  const id = location.pathname.split('/')[2]

  const Query = location.search
  const Params = new URLSearchParams(Query)
  const share = Params.get('share')

  const {
    data: favoris = null,
    isLoading,
    refetch,
  } = useQuery(`get-favoris-${id}`, () =>
    axios.get(`/favoris/${id}`).then(res => {
      return res.data
    })
  )

  if (!share && !user) {
    return <Connexion />
  }

  return (
    <DefaultLayout mobileBottomNavigate={true}>
      <Helmet>
        <title>{favoris?.name ?? ''} - Favoris - Gowifmi</title>
      </Helmet>
      {isLoading ? (
        <div className={styles.loading_page}>
          <LoadingPoint />
        </div>
      ) : (
        <>
          <div className={styles.hotel_bloc}>
            <div className={styles.card_container}>
              <Header user={user} favoris={favoris} refetch={refetch} />
              <div className={styles.hotel}>
                {favoris?.lists.length < 1 ? (
                  <div className={styles.array_vide}>
                    <h2>Liste vide</h2>
                    <p>
                      Chaque fois qu'un logement vous plaît, cliquez sur le cœur pour l'enregistrer.
                      Et si vous voyagez à plusieurs, invitez vos amis à ajouter et choisir leurs
                      lieux préférés.
                    </p>
                    <Link to='/'>Commencer à explorer</Link>
                  </div>
                ) : (
                  <div className={styles.liste_hotel}>
                    {favoris?.lists
                      ?.filter(i => i.hotel !== null)
                      .map(item => (
                        <CardList
                          item={item?.hotel || {}}
                          key={item._id}
                          user={user}
                          favoris={favoris}
                          refetch={refetch}
                          setChildchildhover={setChildchildhover}
                          setChildClicked={setChildClicked}
                        />
                      ))}
                  </div>
                )}
              </div>
            </div>
            <Maps
              hotel={favoris}
              user={user}
              favoris={favoris}
              refetch={refetch}
              childhover={childhover}
              childClicked={childClicked}
              setChildClicked={setChildClicked}
            />
          </div>
        </>
      )}
    </DefaultLayout>
  )
}
export default ListFavoriteById
