import styles from '../../../../../css/modehotes/annonce/filtres/filtre.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import Checkbox from '../../../../ui/Checkbox'

const OptionReservation = () => {
  const array = [
    { name: 'Payer sur Gowifmi', value: 'pay_online' },
    { name: "Payer chez l'hôte", value: 'pay_at_host' },
    { name: 'Réservation instantanée', value: 'instant_booking' },
    { name: 'Annulation gratuite', value: 'cancellation_policy' },
    { name: 'Arrivée autonome', value: 'arrival_flexible' },
  ]
  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  const history = useHistory()
  const location = useLocation()
  const path = location.pathname
  const onchangeFilters = e => {
    if (e.target.checked === true) {
      Params.append(e.target.value, 'true')
      history.push(`${path}?${Params}`)
    } else {
      Params.delete(e.target.value)
      history.push(`${path}?${Params}`)
    }
  }
  return (
    <div className={styles.block_section_filter}>
      <h3>Options de réservation</h3>
      <div className={styles.grid_system}>
        {array.map(item => (
          <div key={item.name} className={styles.checkbox_container}>
            <div>
              <Checkbox
                id={item.name}
                value={item.value}
                checked={Params.has(item.value)}
                onChange={onchangeFilters}
              />
            </div>
            <label htmlFor={item.name}>{item.name}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default OptionReservation
