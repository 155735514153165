import styles from '../../../css/modehotes/facturation/header.module.css'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import Filter from '../../filter_booking/Filter'

const Header = ({ data, noShowFilter }) => {
  const location = useLocation()
  const path = location.pathname.split('/')[3]

  return (
    <div>
      <div className={styles.header}>
        <h1>
          {data?.totalRecords} {data?.totalRecords > 1 ? 'Factures' : 'Facture'}
        </h1>
        <div className={styles.block_link_search}>
          <div>
            <div className={styles.link_reservation}>
              <Link to='/hosting/invoice/all' className={path === 'all' ? styles.active : null}>
                Toutes
              </Link>
              <Link to='/hosting/invoice/pay' className={path === 'pay' ? styles.active : null}>
                Payées
              </Link>
              <Link to='/hosting/invoice/unpay' className={path === 'unpay' ? styles.active : null}>
                Non payées
              </Link>
              <Link
                to='/hosting/infos/earnings'
                className={path === 'earnings' ? styles.active : null}
              >
                Rapport
              </Link>
            </div>
          </div>
          {!noShowFilter && <Filter />}
        </div>
      </div>
    </div>
  )
}

export default Header
