import styles from '../../css/svgs/svgStyle.module.css'

const BigErrorSvg = () => {
  return (
    <div className={styles.block_svg}>
      <svg
        viewBox='0 0 16 16'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'
        role='presentation'
        focusable='false'
      >
        <path d='m8 12c1.1045695 0 2 .8954305 2 2s-.8954305 2-2 2-2-.8954305-2-2 .8954305-2 2-2zm1.6-12v9.6h-3.2v-9.6z'></path>
      </svg>
    </div>
  )
}

export default BigErrorSvg
