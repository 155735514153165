import styles from '../../../../../css/traveler/travel/payment_error_status.module.css'
import { ONLINE_PAYMENT_STATUS } from '../../../../../enums/booking/onlineStatus'
import BigErrorSvg from '../../../../svgs/BigErrorSvg'

const StatusPaymentIntent = ({ payOnline, paymentIntentObject, id }) => {
  if (
    !payOnline ||
    !paymentIntentObject ||
    paymentIntentObject?.status === ONLINE_PAYMENT_STATUS.SUCCEEDED
  )
    return null

  return (
    <div className={styles.container}>
      {paymentIntentObject?.status === ONLINE_PAYMENT_STATUS.PROCESSING && (
        <div className={styles.processing}>
          <h2>Votre paiement est en cours de traitement</h2>
          <p>Nous vous enverron l'état de votre voyage par e-mail</p>
        </div>
      )}
      {paymentIntentObject?.status === ONLINE_PAYMENT_STATUS.REJECTED && (
        <div className={styles.echec}>
          <div>
            <BigErrorSvg />
          </div>
          <div>
            <h2>Votre paiement n'a pas abouti</h2>
            <p>
              Une erreur s'est produite lors de paiement en ligne veuillez,{' '}
              <a href={`/rooms/${id}`}>réessayer avec un autre mode de paiement.</a>
            </p>
          </div>
        </div>
      )}
    </div>
  )
}
export default StatusPaymentIntent
