import { useState, useRef } from 'react'
import styles from '../../css/home/search_acceuil.module.css'
import { Link, useHistory } from 'react-router-dom'
import 'react-calendar/dist/Calendar.css'
import { addDays } from 'date-fns'
import moment from 'moment'
import Destination from './searchs/Destination'
import Calendar from './searchs/Calendar'
import Traveler from './searchs/Traveler'

function Search() {
  const [city, setCity] = useState('')
  const [place_id, setPlace_id] = useState('')
  const [citemodal, setCitemodal] = useState(false)
  const [datepickerModal, setDatepickerModal] = useState(false)
  const [voyageurModal, setVoyageurModal] = useState(false)
  const [adulte, setAdulte] = useState(1)
  const [enfant, setEnfant] = useState(0)
  const [bebe, setBebe] = useState(0)
  const [animal, setAnimal] = useState(0)

  const [dateRange, setDateRange] = useState([new Date(), addDays(new Date(), 1)])
  const [startDate, endDate] = dateRange

  const inputDestinationPcRef = useRef(null)
  const history = useHistory()
  const handleSearch = () => {
    if (city === '') {
      inputDestinationPcRef.current && inputDestinationPcRef.current.focus()
      setCitemodal(true)
    } else {
      history.push(
        `/homes/search?&place=${city}&children=${enfant}&adults=${adulte}&infants=${bebe}&pets=${animal}&checkin=${moment(
          startDate
        ).format('YYYY-MM-DD')}&checkout=${moment(endDate).format(
          'YYYY-MM-DD'
        )}&place_id=${place_id}`
      )
    }
  }

  return (
    <>
      <div className={styles.search_block}>
        <Destination
          inputDestinationPcRef={inputDestinationPcRef}
          city={city}
          setCity={setCity}
          setPlace_id={setPlace_id}
          citemodal={citemodal}
          setCitemodal={setCitemodal}
          setDatepickerModal={setDatepickerModal}
        />
        <Calendar
          startDate={startDate}
          endDate={endDate}
          datepickerModal={datepickerModal}
          setDatepickerModal={setDatepickerModal}
          setDateRange={setDateRange}
          dateRange={dateRange}
          setVoyageurModal={setVoyageurModal}
        />
        <Traveler
          voyageurModal={voyageurModal}
          setVoyageurModal={setVoyageurModal}
          adulte={adulte}
          setAdulte={setAdulte}
          enfant={enfant}
          setEnfant={setEnfant}
          bebe={bebe}
          setBebe={setBebe}
          animal={animal}
          setAnimal={setAnimal}
          handleSearch={handleSearch}
        />
        <div className={styles.submit_btn}>
          <button type='submit' onClick={handleSearch}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 32 32'
              aria-hidden='true'
              role='presentation'
              focusable='false'
              style={{
                display: 'block',
                fill: 'none',
                height: '16px',
                width: '16px',
                stroke: 'currentcolor',
                strokeWidth: '4',
                overflow: 'visible',
              }}
            >
              <path fill='none' d='M13 24a11 11 0 1 0 0-22 11 11 0 0 0 0 22zm8-3 9 9'></path>
            </svg>
            <span>Rechercher</span>
          </button>
        </div>
      </div>
      <div className={styles.home_block_2}>
        <div>
          <h2>
            Vous aussi devenez hôte
            <br /> sur Gowifmi
          </h2>
          <Link to='/host/homes'>Ajouter un logement</Link>
        </div>
      </div>
    </>
  )
}
export default Search
