import { useContext } from 'react'
import styles from '../../../../css/modehotes/annonce/details/detail.module.css'
import { Helmet } from 'react-helmet-async'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { getAnnonce } from '../../../../redux/actions'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { User } from '../../../../context/UserContext'
import LoadingPoint from '../../../../components/LoadingPoint'
import ListAnnonce from '../../../../components/hote_mode/annonce/details/defaults/ListAnnonce'
import HeadDetail from '../../../../components/hote_mode/annonce/details/defaults/HeadDetail'
import Navigation from '../../../../components/hote_mode/annonce/details/defaults/Navigation'
import Overview from '../../../../components/hote_mode/annonce/details/prices/overviews/Overview'
import Pricing from '../../../../components/hote_mode/annonce/details/prices/pricings/Pricing'
import Currency from '../../../../components/hote_mode/annonce/details/prices/currencys/Currency'
import NightlyDiscount from '../../../../components/hote_mode/annonce/details/prices/nightly_discounts/NightlyDiscount'
import WeeklyDiscount from '../../../../components/hote_mode/annonce/details/prices/weekly_discounts/WeeklyDiscount'
import MonthlyDiscount from '../../../../components/hote_mode/annonce/details/prices/monthly_discounts/MonthlyDiscount'
import FeeCleaning from '../../../../components/hote_mode/annonce/details/prices/fee_cleanings/FeeCleaning'
import FeeStay from '../../../../components/hote_mode/annonce/details/prices/fee_stays/FeeStay'
import DurationMin from '../../../../components/hote_mode/annonce/details/prices/minimum_durations/DurationMin'
import DurationMax from '../../../../components/hote_mode/annonce/details/prices/maximum_durations/DurationMax'
import DelaiReservation from '../../../../components/hote_mode/annonce/details/prices/booking_deadline_before_arrivals/DelaiReservation'
import PreparationTime from '../../../../components/hote_mode/annonce/details/prices/preparation_times/PreparationTime'
import AvailabileRange from '../../../../components/hote_mode/annonce/details/prices/availabile_ranges/AvailabileRange'
import ArrivelDayUnauthorized from '../../../../components/hote_mode/annonce/details/prices/unauthorized_day_arrivals/DayUnauthorized'
import DepartureDayUnauthorized from '../../../../components/hote_mode/annonce/details/prices/unauthorized_day_departures/DayUnauthorized'
import ErrorServer from '../../../errors/ErrorServer'
import HostModeLayout from '../../../../layouts/HostModeLayout'
import { SATATUS_ANNONCE } from '../../../../enums/logement/status.enums'

const PriceAndDisponibilite = () => {
  const user = useContext(User)
  const history = useHistory()
  const location = useLocation()
  const path = location.pathname.split('/')[4]
  const dispatch = useDispatch()
  const annonce = useSelector(state => state.updateAnnonce)

  const { isLoading, error } = useQuery(`hote-get-annonce-details-${path}`, () =>
    axios.get(`/hotel/oneAnnonce/${path}`).then(res => {
      dispatch(getAnnonce(res.data))
      if (res.data.status === SATATUS_ANNONCE.IN_PROGRESS) {
        history.push(`/become-a-host/${res.data.redirect_url}/${res.data._id}`)
      }
      if (res.data.status === SATATUS_ANNONCE.SAVE) {
        history.push(`/verify-listing/${res.data._id}`)
      }
    })
  )

  if (error) {
    return <ErrorServer />
  }

  if (user === null) {
    history.push('/')
  }
  return (
    <HostModeLayout>
      <Helmet>
        <title>Modifier l'annonce : {annonce?.title ?? ''} - Gowifmi</title>
      </Helmet>
      <div className={styles.voyage_container}>
        <ListAnnonce />
        <div className={styles.block_details}>
          <HeadDetail annonce={annonce} />
          <Navigation />
          {isLoading ? (
            <div className={styles.loading}>
              <LoadingPoint />
            </div>
          ) : (
            <div>
              <Overview annonce={annonce} />
              <Pricing annonce={annonce} />
              <Currency annonce={annonce} />
              <NightlyDiscount annonce={annonce} />
              <WeeklyDiscount annonce={annonce} />
              <MonthlyDiscount annonce={annonce} />
              <FeeCleaning annonce={annonce} />
              <FeeStay annonce={annonce} />
              <DurationMin annonce={annonce} />
              <DurationMax annonce={annonce} />
              <DelaiReservation annonce={annonce} />
              <PreparationTime annonce={annonce} />
              {/*---tache non pris en charge temps de preparation avant et apres une reservation*/}
              <AvailabileRange annonce={annonce} />
              <ArrivelDayUnauthorized annonce={annonce} />
              <DepartureDayUnauthorized annonce={annonce} />
            </div>
          )}
        </div>
      </div>
    </HostModeLayout>
  )
}

export default PriceAndDisponibilite
