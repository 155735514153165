import { useState } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const WeeklyDiscount = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [reductionSemaine, setReductionSemain] = useState()
  const { loading, updateData } = useUpdateHotel(setOpen)

  const minmum = 0
  const maximum = 99
  const handleSubmit = e => {
    e.preventDefault()
    const datas = {
      discounts: {
        ...annonce.discounts,
        weekly: reductionSemaine,
      },
    }
    updateData(datas)
  }
  const openFuction = () => {
    setReductionSemain(annonce.discounts?.weekly)
    setOpen(true)
  }
  const reset = () => {
    setReductionSemain()
    setOpen(false)
  }
  const handleChange = event => {
    const result = event.target.value.replace(/\D/g, '')
    setReductionSemain(result)
  }
  return (
    <div className={styles.block}>
      {!open ? (
        <Section title='Réduction à la semaine' handleOpen={openFuction}>
          <p>{annonce.discounts?.weekly ? `${annonce.discounts?.weekly}%` : 'Non défini'}</p>
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title='Réduction à la semaine' handleReset={reset}>
            <p>Les réductions à la semaine s'appliquent aux réservations de 7 jours ou plus.</p>
          </ContenHead>
          <form onSubmit={handleSubmit}>
            <div className={styles.body}>
              <div
                className={
                  reductionSemaine < minmum || reductionSemaine > maximum
                    ? `${styles.input_block} ${styles.error}`
                    : styles.input_block
                }
              >
                <input
                  type='tel'
                  autoComplete='off'
                  value={reductionSemaine}
                  onChange={handleChange}
                />
                <div className={styles.price}>
                  <h5>{reductionSemaine}%</h5>
                  {reductionSemaine > maximum ? (
                    <p>Les réductions doivent être inférieures ou égales à {maximum}%.</p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <BottomForm
              disabled={reductionSemaine < minmum || reductionSemaine > maximum}
              loading={loading}
              handleReset={reset}
            />
          </form>
        </div>
      )}
    </div>
  )
}

export default WeeklyDiscount
