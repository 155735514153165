import styles from '../../../css/becomeHostes/typeProperty.module.css'

const Category = ({ typeproperty, setTypeproperty }) => {
  const array = [
    {
      name: 'Grange',
      value: 'grange',
      descr:
        "Un logement transformé, situé dans un bâtiment conçu à l'origine pour stocker des céréales, abriter du bétail ou être utilisé en agriculture.",
    },
    {
      name: 'Bateau',
      value: 'bateau',
      descr:
        "Un bateau, voilier ou yacht amarré lors des séjours des voyageurs. Ce n'est pas une péniche.",
    },
    {
      name: 'Bus',
      value: 'bus',
      descr: "Un véhicule multi-passagers dont l'intérieur a été réaménagé avec créativité.",
    },
    {
      name: 'Camping-car/caravane',
      value: 'camping_car/caravane',
      descr: 'Un camping-car ou une caravane : un logement mobile.',
    },
    {
      name: 'Cabane perchée',
      value: 'cabane_perche',
      descr:
        "Une habitation construite dans le tronc ou les branches d'un arbre, ou montée sur des pilotis parmi les arbres.",
    },
    {
      name: 'Place de camping',
      value: 'place_de_camping',
      descr:
        'Une parcelle de terre où les voyageurs peuvent installer une tente, une yourte, un camping-car ou une tiny house.',
    },
    {
      name: 'Château',
      value: 'chateau',
      descr:
        'Un bâtiment majestueux, peut-être historique, qui peut comporter des tours et des douves.',
    },
    {
      name: 'Grotte',
      value: 'grotte',
      descr: 'Une habitation naturelle ou sculptée dans une colline ou une falaise.',
    },
    {
      name: 'Hutte',
      value: 'hutte',
      descr: 'Une maison faite de bois ou de boue qui peut avoir un toit de chaume.',
    },
    {
      name: 'Gîte à la ferme',
      value: 'gite_a_la_ferme',
      descr:
        'Un logement à la campagne où les voyageurs peuvent côtoyer des animaux et la vie agricole.',
    },
    {
      name: 'Dôme',
      value: 'dome',
      descr: "Une maison avec un toit en dôme ou de forme sphérique, telle qu'une maison-bulle.",
    },
    {
      name: 'Tente',
      value: 'tente',
      descr: "Une structure généralement portative et pliable constituée de tiges et d'une toile.",
    },
    { name: 'Avion', value: 'avion', descr: 'Un avion qui a été converti en hébergement.' },
    { name: 'Île', value: 'ile', descr: "Une étendue de terre entourée d'eau." },
    {
      name: 'Parc de vacances',
      value: 'parc_de_vacance',
      descr:
        'Une parcelle de terrain avec cabanes ou campings en Australie ou en Nouvelle-Zélande.',
    },
    {
      name: 'Tiny house',
      value: 'tiny_house',
      descr: "Une maison individuelle d'une superficie généralement inférieure à 37 mètres carrés.",
    },
    {
      name: 'Trullo',
      value: 'trullo',
      descr: "Une maison circulaire en pierre avec un toit en forme de cône, originaire d'Italie.",
    },
    {
      name: 'Casa particular',
      value: 'casa_particular',
      descr:
        "Une chambre privée dans un logement à Cuba, à l'atmosphère évoquant une chambre d'hôtes.",
    },
    {
      name: 'Pension',
      value: 'pension',
      descr: 'Une maison avec barbecue et espace commun dans la campagne coréenne.',
    },
    {
      name: 'Logement de vacances',
      value: 'logement_de_vacance',
      descr:
        'Une location meublée avec une cuisine et une salle de bain pouvant offrir certains services aux voyageurs, comme un service de réception.',
    },
    { name: 'Camping et glamping', value: 'camping_et_glamping', descr: '' },
    { name: 'Logement dans la nature', value: 'logement_dans_la_nature', descr: '' },
    { name: 'Logements minimaliste', value: 'logement_minimaliste', descr: '' },
    { name: "Logements sur l'eau", value: 'Logement_sur_leau', descr: '' },
    { name: 'Autre', value: 'autre', descr: '' },
  ]
  return (
    <div className={styles.block_checkbox}>
      {array.map((item, i) => (
        <label key={i}>
          <input
            type='radio'
            value={item.value}
            checked={typeproperty === item.value}
            onChange={e => setTypeproperty(e.target.value)}
          />
          <div className={styles.block}>
            <div className={styles.block_content}>
              <h2>{item.name}</h2>
              <p>{item.descr}</p>
            </div>
          </div>
        </label>
      ))}
    </div>
  )
}

export default Category
