import styles from '../../../../../../css/modehotes/annonce/details/photo_head.module.css'

const AddPhoto = ({ images, handleUploadInput }) => {
  return (
    <>
      <div className={styles.block_button}>
        <div>
          {images.length < 5 ? (
            <h4>Ajoutez au moins 5 photos</h4>
          ) : (
            <div>
              <h4>Est-ce que tout semble en ordre ?</h4>
              <p>Faites glisser pour réorganiser</p>
            </div>
          )}
        </div>
        <form encType='multipart/form-data'>
          <input
            type='file'
            multiple
            id='addphoto'
            accept='.jpg, .jpeg, .png'
            onChange={e => handleUploadInput(e.target.files)}
          />
          <label htmlFor='addphoto'>
            <span>
              <svg
                viewBox='0 0 32 32'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
              >
                <path d='m17.2869988 6.88316725.1272148.11683275 9.2928932 9.2928932-1.4142136 1.4142136-8.293-8.29289324.0001068 20.58578644h-2l-.0001068-20.58578644-8.29278642 8.29289324-1.41421356-1.4142136 9.29289318-9.2928932c.7399408-.73994076 1.915425-.77888501 2.7012124-.11683275zm10.7130012-4.88316725v2h-24v-2z'></path>
              </svg>
            </span>
            <span>Ajouter des photos</span>
          </label>
        </form>
      </div>
    </>
  )
}

export default AddPhoto
