import styles from '../../../../css/search_room_result/filtre_2.module.css'
import { useSearchParams } from '../../../../context/SearchParamContext'
import Checkbox from '../../../ui/Checkbox'

const HotelLanguage = () => {
  const array = [
    { name: 'Anglais', value: '1' },
    { name: 'Français', value: '2' },
    { name: 'Allemand', value: '3' },
    { name: 'Japonais', value: '4' },
    { name: 'Italien', value: '5' },
    { name: 'Russe', value: '6' },
    { name: 'Espagnol', value: '7' },
    { name: 'Chinois (simplifié)', value: '8' },
    { name: 'Arabe', value: '9' },
    { name: 'Hindi', value: '10' },
    { name: 'Portugais', value: '11' },
    { name: 'Turc', value: '12' },
    { name: 'Indonésien', value: '13' },
    { name: 'Néerlandais', value: '14' },
    { name: 'Thaï', value: '15' },
    { name: 'Grec', value: '16' },
    { name: 'Polonais', value: '17' },
    { name: 'Malais', value: '18' },
    { name: 'Tagalog', value: '19' },
    { name: 'Danois', value: '20' },
    { name: 'Suédois', value: '21' },
    { name: 'Norvégien', value: '22' },
    { name: 'Finnois', value: '23' },
    { name: 'Tchèque', value: '24' },
    { name: 'Hongrois', value: '25' },
  ]
  const { params, updateParams } = useSearchParams()
  const newParams = new URLSearchParams(params.toString())

  URLSearchParams.prototype.remove = function (key, value) {
    const entries = this.getAll(key)
    const newEntries = entries.filter(entry => entry !== value)
    this.delete(key)
    newEntries.forEach(newEntry => this.append(key, newEntry))
  }

  const onchangeFilters = e => {
    if (!e.target.checked === true) {
      newParams.delete('page')
      newParams.remove('languages', e.target.value)
    } else {
      newParams.delete('page')
      newParams.append('languages', e.target.value)
    }
    updateParams(newParams)
  }
  return (
    <div className={styles.block_section_filter}>
      <h3>Langue de l'hôte</h3>
      <div className={styles.grid_system}>
        {array.map(item => (
          <div key={item.name} className={styles.checkbox_container}>
            <div>
              <Checkbox
                id={item.name}
                value={item.value}
                checked={params.getAll('languages').includes(item.value)}
                onChange={onchangeFilters}
              />
            </div>
            <label htmlFor={item.name}>{item.name}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default HotelLanguage
