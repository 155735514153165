import { useState, useEffect, useRef, useContext } from 'react'
import styles from '../../css/details/message.module.css'
import { User } from '../../context/UserContext'
import Login from '../login'
import axios from 'axios'
import { Link } from 'react-router-dom'
import moment from 'moment'
import SendMessage from './messages/SendMessage'
import useModalByUrl from '../../hooks/useModalByUrl'
import StarSvg from '../svgs/StarSvg'
import ParagrapheTronque from '../ui/ParagrapheTronque'
import ButtonTertiary from '../ui/ButtonTertiary'

const HostProfile = ({ hotel, ProfilHoteRef }) => {
  const [hoteComment, setHoteComment] = useState([])
  const [succes, setsucces] = useState(false)
  const user = useContext(User)

  const modalValue = 'SEND_MESSAGE'
  const { modalState, handleOpenModal, handleCloseModal } = useModalByUrl(modalValue)

  useEffect(() => {
    axios
      .get(`/hotel_comment/hostComment/${hotel?.user_id?.id}`)
      .then(res => {
        setHoteComment(res.data)
      })
      .catch(() => {
        setHoteComment([])
      })
  }, [])

  const handlesendmessage = () => {
    if (user === null) {
      handleConnection('connexion')
    } else {
      setsucces(false)
      handleOpenModal()
    }
  }

  const connexion = useRef()
  const handleConnection = params => {
    connexion.current.OpenModal(params)
  }

  return (
    <>
      <div ref={ProfilHoteRef} className={`${styles.send_message} ${styles.section}`}>
        <div className={styles.profil_container}>
          <div className={styles.profil}>
            <div className={styles.profil_block}>
              <div>
                <Link to={`/profile/${hotel.user_id?.id}`} className={styles.profil_img}>
                  {hotel.user_id?.picture ? (
                    <img src={hotel.user_id?.picture} alt='' />
                  ) : (
                    <h2>{hotel.user_id?.first_name?.slice(0, 1)}</h2>
                  )}
                </Link>
              </div>
              <div>
                <h4>{hotel.user_id?.first_name}</h4>
                <p>Membre depuis {moment(hotel.user_id?.createdAt).format('LL').slice(2)}</p>
              </div>
            </div>
            <div className={styles.status}>
              <div className={styles.comment}>
                <StarSvg width={14} height={14} />
                {hoteComment?.length === 0 ? (
                  <p>Nouveau</p>
                ) : (
                  <p>
                    {hoteComment?.length} commentaire{hoteComment?.length > 1 && 's'}
                  </p>
                )}
              </div>
              {hotel.user_id?.isIdentityValid && (
                <div className={styles.identity}>
                  <svg
                    viewBox='0 0 16 16'
                    className={styles.yes}
                    xmlns='http://www.w3.org/2000/svg'
                    aria-hidden='true'
                    role='presentation'
                    focusable='false'
                  >
                    <path d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.159 4.869L6.67 9.355 4.42 7.105 3.289 8.236 6.67 11.62 12.291 6z'></path>
                  </svg>
                  <p>Identité vérifiée</p>
                </div>
              )}
            </div>
            {hotel.user_id?.about_me && (
              <div className={styles.profil_about}>
                <h4>À propos de {hotel.user_id?.first_name}</h4>
                {hotel.user_id?.about_me && (
                  <ParagrapheTronque text={hotel.user_id?.about_me} lengthMax={150} />
                )}
              </div>
            )}
          </div>
          <div className={styles.profil_about}>
            <h4>Vous avez des questions concernant ce logement ?</h4>
            <ButtonTertiary type='button' onClick={handlesendmessage} className={styles.button}>
              Contacter l'hôte
            </ButtonTertiary>
          </div>
        </div>
      </div>
      <SendMessage
        hotel={hotel}
        modalState={modalState}
        handleCloseModal={handleCloseModal}
        succes={succes}
        setsucces={setsucces}
      />
      <Login ref={connexion} />
    </>
  )
}
export default HostProfile
