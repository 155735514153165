import { useEffect, useContext, useState } from 'react'
import styles from '../../../css/modehotes/facturation/report.module.css'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { User } from '../../../context/UserContext'
import LoadingPoint from '../../../components/LoadingPoint'
import Header from '../../../components/hote_mode/bills/Header'
import { addCurrencyComplet } from '../../../utils/addCurrency'
import PendingSvg from '../../../components/svgs/PendingSvg'
import CheckSvg from '../../../components/svgs/CheckSvg'
import HostModeLayout from '../../../layouts/HostModeLayout'
import ErrorServer from '../../errors/ErrorServer'
import { useQuery } from 'react-query'
import { Helmet } from 'react-helmet-async'
import Invoice from '../../../components/hote_mode/bills/Invoice'

const BillReport = () => {
  const [searchParams, setSearchParams] = useState(window.location.search)
  const params = new URLSearchParams(searchParams)

  const user = useContext(User)
  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
    if (user === null) {
      history.push('/')
    }
    const unlisten = history.listen(location => {
      setSearchParams(location.search)
    })

    return () => {
      unlisten()
    }
  }, [history, user])

  const fetchBills = async () => {
    const { data } = await axios.get(`/bill/report?host=${user?.id}&&${params}`)
    return data
  }

  const {
    data: report = {},
    isLoading,
    error,
  } = useQuery([`bill_report`, searchParams], fetchBills)

  if (error) return <ErrorServer />

  return (
    <HostModeLayout>
      <Helmet>
        <title>Rapport facturations - Gowifmi</title>
      </Helmet>
      <div className={styles.container}>
        <Header noShowFilter={true} />
        {isLoading ? (
          <div className={styles.container_load_liste}>
            <LoadingPoint />
          </div>
        ) : (
          <div className={styles.main}>
            <div className={styles.net}>
              <h1>Vos revenus</h1>
              <h2>{addCurrencyComplet(report.netRevenue, 'usd')}</h2>
              <p>Revenus net</p>
            </div>
            <div className={styles.detail}>
              <div>
                <h2>{addCurrencyComplet(report.netRevenue + report.totalServiceFee, 'usd')}</h2>
                <div className={styles.detail_block}>
                  <span className={styles.black}></span>
                  <p>Revenus brut</p>
                </div>
              </div>
              <div>
                <h2>{addCurrencyComplet(report.totalServiceFee, 'usd')}</h2>
                <div className={styles.detail_block}>
                  <span className={styles.gray}></span>
                  <p>Frais de service</p>
                </div>
              </div>
            </div>
            <div className={styles.infos}>
              <h3>Paiements</h3>
              <div className={styles.infos_block}>
                <p>Total factures payées</p>
                <div>
                  <p>{addCurrencyComplet(report.totalServiceFeePaid, 'usd')}</p>
                  <p className={styles.complet}>
                    <span>Réglé</span> <CheckSvg />
                  </p>
                </div>
              </div>
              <div className={styles.infos_block}>
                <p>Total factures non payées</p>
                <div>
                  <p>{addCurrencyComplet(report.totalServiceFeeUnpaid, 'usd')}</p>
                  <p className={styles.incomplet}>
                    <span>En attente</span> <PendingSvg />
                  </p>
                </div>
              </div>
            </div>
            <Invoice />
          </div>
        )}
      </div>
    </HostModeLayout>
  )
}

export default BillReport
