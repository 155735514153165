import { useState, useContext } from 'react'
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk'
import LoadingPoint from '../../LoadingPoint'
import axios from 'axios'
import { User } from '../../../context/UserContext'

const VeriffButton = ({ className, disabled, children }) => {
  const [loading, setLoading] = useState(false)
  const currentUrl = window.location.href
  const user = useContext(User)

  const handleUpdateUser = () => {
    const data = {
      identitySubmitted: true,
    }
    axios
      .patch(`/user/${user?.id}`, data)
      .then(res => {
        window.location.reload()
      })
      .catch(() => {})
  }

  const startVerification = async () => {
    try {
      setLoading(true)
      const response = await axios.post('/user/create_verification_session', { currentUrl })
      const VERIFF_SESSION_URL = response.data.sessionUrl

      createVeriffFrame({
        url: VERIFF_SESSION_URL,
        onEvent: function (msg) {
          switch (msg) {
            case MESSAGES.CANCELED:
              setLoading(false)
              break
            case MESSAGES.FINISHED:
              handleUpdateUser()
              break
            default:
              setLoading(false)
              break
          }
        },
      })
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <button disabled={loading || disabled} className={className} onClick={startVerification}>
      {loading ? <LoadingPoint /> : children}
    </button>
  )
}

export default VeriffButton
