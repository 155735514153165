import styles from '../../../css/home/searchs/calendrier.module.css'
import { useEffect, useRef } from 'react'
import Calendar from 'react-calendar'
import moment from 'moment'
import CloseBtnSearch from '../../ui/CloseBtnSearch'

const Calendrier = ({
  startDate,
  endDate,
  datepickerModal,
  setDatepickerModal,
  setVoyageurModal,
  setDateRange,
  dateRange,
}) => {
  const Maxdate = moment(new Date()).add(24, 'month').format('YYYY-MM-DD')
  const viewPort = window.matchMedia('(min-width:1018px)')

  const contentDate = useRef(null)
  useEffect(() => {
    const handleClickOutside = event => {
      if (contentDate.current && !contentDate.current.contains(event.target)) {
        setDatepickerModal(false)
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])
  const handleNext = () => {
    setDatepickerModal(false)
    setVoyageurModal(true)
  }

  return (
    <div
      className={datepickerModal ? `${styles.date} ${styles.active}` : styles.date}
      ref={contentDate}
    >
      <div className={styles.selecte} onClick={() => setDatepickerModal(!datepickerModal)}>
        <div>
          <svg
            className={styles.calendar}
            viewBox='0 0 48 48'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            role='presentation'
            focusable='false'
          >
            <g stroke='none'>
              <path
                d='M40 29.295V8H8v31a1 1 0 0 0 .883.993L9 40h20.295L40 29.295z'
                fillOpacity='.2'
              ></path>
              <path d='M19 2v2h10V2h2v2h11a2 2 0 0 1 1.995 1.85L44 6v24.953L30.953 44H9a5 5 0 0 1-4.995-4.783L4 39V6a2 2 0 0 1 1.85-1.995L6 4h11V2h2zm10 4H19v2h-2V6H6v33a3 3 0 0 0 2.824 2.995L9 42h20v-8a5 5 0 0 1 4.783-4.995L34 29h8V6H31v2h-2V6zm12.123 25H34a3 3 0 0 0-2.995 2.824L31 34v7.123L41.123 31z'></path>
            </g>
          </svg>
        </div>
        <p>
          {moment(startDate).format('ll')} – {moment(endDate).format('ll')}
        </p>
      </div>
      <div className={styles.dateModal}>
        <CloseBtnSearch onClick={() => setDatepickerModal(false)} />
        <div className={styles.dateContent}>
          <h3>Quand arriverez-vous ?</h3>
          <Calendar
            onChange={setDateRange}
            value={dateRange}
            minDate={new Date()}
            maxDate={new Date(Maxdate)}
            selectRange={true}
            showNeighboringMonth={false}
            showDoubleView={viewPort.matches}
            view='month'
          />
          <div className={styles.m_footer}>
            <button type='button' onClick={() => setDatepickerModal(false)}>
              Passer
            </button>
            <button type='button' onClick={handleNext}>
              Continuer
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Calendrier
