import { useRef } from 'react'
import styles from '../../../../../css/booking/connexion.module.css'
import Login from '../../../../login'
import AuthReseaux from '../../../../login/reseaux/AuthReseaux'

const Connexion = () => {
  const connexion = useRef()
  const handleConnection = params => {
    connexion.current.OpenModal(params)
  }

  return (
    <>
      <div className={styles.connexion_button_block}>
        <h3>Connectez-vous ou inscrivez-vous pour réserver</h3>
        <button type='button' onClick={handleConnection} className={styles.cotunue_button}>
          Continuer avec une adresse e-mail
        </button>
        <AuthReseaux />
      </div>
      {/*------login or incription------*/}
      <Login ref={connexion} />
    </>
  )
}

export default Connexion
