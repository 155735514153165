import styles from '../../../css/logins/reseaux.module.css'
// import FacebookAuth from './components/FacebookAuth'
import GoogleAuth from './components/GoogleAuth'

const AuthReseaux = ({ changeState, setGetUser }) => {
  return (
    <div className={styles.container}>
      <div className={styles.line_block}>
        <div className={styles.line}></div>
        <span>ou</span>
        <div className={styles.line}></div>
      </div>
      <div className={styles.content}>
        <GoogleAuth changeState={changeState} setGetUser={setGetUser} />
        {/* <FacebookAuth changeState={changeState} /> */}
      </div>
    </div>
  )
}

export default AuthReseaux
