const NextAction = ({ nextActionObj }) => {
  return (
    <>
      {nextActionObj?.type === 'cashapp_handle_redirect_or_display_qr_code' && (
        <a
          href={nextActionObj?.cashapp_handle_redirect_or_display_qr_code?.hosted_instructions_url}
        >
          Veuillez terminer
        </a>
      )}
      {nextActionObj?.type === 'alipay_handle_redirect' && (
        <a href={nextActionObj?.alipay_handle_redirect?.url}>Veuillez terminer</a>
      )}
      {nextActionObj?.type === 'boleto_display_details' && (
        <a href={nextActionObj?.boleto_display_details?.hosted_voucher_url}>Veuillez terminer</a>
      )}
      {nextActionObj?.type === 'card_await_notification' && (
        <p>
          Pour les paiements d'un montant supérieur à 15 000 INR, le vous devez obtenir l'accord
          explicite de votre banque. Pour les paiements d'un montant inférieur, aucune action n'est
          requise de votre part.
        </p>
      )}
      {nextActionObj?.type === 'display_bank_transfer_instructions' && (
        <a href={nextActionObj?.display_bank_transfer_instructions?.hosted_instructions_url}>
          Veuillez terminer
        </a>
      )}
      {nextActionObj?.type === 'konbini_display_details' && (
        <a href={nextActionObj?.konbini_display_details?.hosted_voucher_url}>Veuillez terminer</a>
      )}
      {nextActionObj?.type === 'oxxo_display_details' && (
        <a href={nextActionObj?.oxxo_display_details?.hosted_voucher_url}>Veuillez terminer</a>
      )}
      {nextActionObj?.type === 'paynow_display_qr_code' && (
        <a href={nextActionObj?.paynow_display_qr_code?.hosted_instructions_url}>
          Veuillez terminer
        </a>
      )}
      {nextActionObj?.type === 'pix_display_qr_code' && (
        <a href={nextActionObj?.pix_display_qr_code?.hosted_instructions_url}>Veuillez terminer</a>
      )}
      {nextActionObj?.type === 'redirect_to_url' && (
        <a href={nextActionObj?.redirect_to_url?.url}>Veuillez terminer</a>
      )}
      {nextActionObj?.type === 'swish_handle_redirect_or_display_qr_code' && (
        <a href={nextActionObj?.swish_handle_redirect_or_display_qr_code?.hosted_instructions_url}>
          Veuillez terminer
        </a>
      )}
      {nextActionObj?.type === 'verify_with_microdeposits' && (
        <a href={nextActionObj?.verify_with_microdeposits?.hosted_verification_url}>
          Veuillez terminer
        </a>
      )}
      {nextActionObj?.type === 'wechat_pay_display_qr_code' && (
        <a href={nextActionObj?.wechat_pay_display_qr_code?.hosted_instructions_url}>
          Veuillez terminer
        </a>
      )}
      {nextActionObj?.type === 'use_stripe_sdk' && <p>Veuillez nous contactez</p>}
    </>
  )
}
export default NextAction
