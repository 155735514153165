import React, { useState } from 'react'
import styles from '../../../../../../css/booking/politiqueHote.module.css'
import Modal from '../../../../../ui/Modal'
import CloseButton from '../../../../../ui/CloseButton'

const ReplacementPolicy = React.forwardRef((props, ref) => {
  const [modal, setModal] = useState(false)

  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      setModal(true)
    },
  }))

  return (
    <>
      <Modal isOpen={modal} onClose={() => setModal(false)}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={() => setModal(false)} />
            <div></div>
          </div>
          <div className={styles.m_body}>
            <div className={styles.asavoir}>
              <h2>Politique de remplacement d'hébergement</h2>
              <p>
                Si l'hôte annule une réservation avant l'arrivée et le voyageur{' '}
                <a href='/help/feedback' target='_blank' rel='noreferrer'>
                  nous contacte
                </a>
                , nous lui offrons notre aide pour trouver un hébergement comparable ou supérieur.
              </p>
              <p>
                Si vous rencontrez un problème lors de votre voyage, veuillez-nous en informer dans
                les 72 heures suivant la découverte. Si nous constatons que le problème rencontré
                lors d'un voyage a perturbé le séjour, la politique (conditions d'annulations)
                sélectionnée par l'hôte s'appliquera automatiquement, l’hôte vous remboursera en
                totalité ou partiel suivant le mode de politique qu’il a eu à sélectionner avant la
                réservation. Nous pourrons vous aider à trouver un hébergement comparable ou
                supérieur. Si le voyageur décide de quitter son logement suite à un problème
                rencontré lors de son voyage et{' '}
                <a href='/help/feedback' target='_blank' rel='noreferrer'>
                  nous contacte
                </a>
                , nous lui proposons notre aide et notre assistance pour trouver un hébergement
                équivalent ou supérieur pour les nuits restantes du séjour.
              </p>
              <a href='/replacement-gowifmi-policy' target='_blank' rel='noreferrer'>
                En savoir plus
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
})
export default ReplacementPolicy
