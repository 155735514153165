import React, { useState, useRef } from 'react'
import styles from '../../../../css/search_room_result/more_actions/show_more.module.css'
import FavoriteResult from '../favorites/FavoriteResult'
import Modal from '../../../ui/Modal'
import CloseButton from '../../../ui/CloseButton'
import FavoriteShowMore from '../favorites/FavoriteShowMore'
import { useSelector } from 'react-redux'
import ButtonBlock from './show_mores/ButtonBlock'
import ShareHotel from './show_mores/ShareHotel'

const ShowMore = React.forwardRef(({ item, handleDeleteAnnonceInlist }, ref) => {
  const [modal, setModal] = useState(false)

  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      setModal(true)
    },
  }))

  const handleclose = () => {
    setModal(false)
  }

  const favoris = useSelector(state => state.getFavorisReducer)

  const filteredArray = favoris
    ?.filter(el => el.lists.some(lists => lists.hotel?._id === item._id))
    .map(el => {
      let n = Object.assign({}, el, {
        lists: el.lists.filter(lists => lists.hotel?._id === item._id),
      })
      return n
    })

  const favoritreRef = useRef(null)
  const handleAdd = () => {
    setModal(false)
    favoritreRef.current.openModal()
  }
  const handleRemove = () => {
    setModal(false)
    favoritreRef.current.removeFavorite(filteredArray[0]._id)
  }

  const shareRef = useRef(null)
  const handleShare = () => {
    setModal(false)
    shareRef.current.openModal()
  }

  const handleRemoveFavoritePage = () => {
    setModal(false)
    handleDeleteAnnonceInlist()
  }

  return (
    <>
      <Modal isOpen={modal} onClose={handleclose}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={handleclose} />
            <div></div>
          </div>
          <div className={styles.m_body}>
            <div className={styles.body_wrapper}>
              <div className={styles.head_btn}>
                {handleDeleteAnnonceInlist ? (
                  <button type='button' onClick={handleRemoveFavoritePage}>
                    <svg
                      viewBox='0 0 32 32'
                      xmlns='http://www.w3.org/2000/svg'
                      aria-hidden='true'
                      role='presentation'
                      focusable='false'
                      style={{
                        display: 'block',
                        height: '16px',
                        width: '16px',
                        strokeWidth: '4',
                        overflow: 'visible',
                        fill: '#ff385c',
                        stroke: '#ff385c',
                      }}
                    >
                      <path d='m16 28c7-4.733 14-10 14-17 0-1.792-.683-3.583-2.05-4.95-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05l-2.051 2.051-2.05-2.051c-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05-1.367 1.367-2.051 3.158-2.051 4.95 0 7 7 12.267 14 17z'></path>
                    </svg>
                    <p>Enregistrée</p>
                  </button>
                ) : (
                  <FavoriteShowMore
                    filteredArray={filteredArray}
                    item={item}
                    handleAdd={handleAdd}
                    handleRemove={handleRemove}
                  />
                )}
                <button type='button' onClick={handleShare}>
                  <svg
                    viewBox='0 0 32 32'
                    xmlns='http://www.w3.org/2000/svg'
                    aria-hidden='true'
                    role='presentation'
                    focusable='false'
                    style={{
                      display: 'block',
                      height: '16px',
                      width: '16px',
                      strokeWidth: '4',
                      stroke: 'currentColor',
                      overflow: 'visible',
                    }}
                  >
                    <g fill='none'>
                      <path d='M27 18v9a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-9'></path>
                      <path d='M16 3v23V3z'></path>
                      <path d='M6 13l9.293-9.293a1 1 0 0 1 1.414 0L26 13'></path>
                    </g>
                  </svg>
                  <p>Partager</p>
                </button>
              </div>
              <ButtonBlock item={item} />
            </div>
          </div>
        </div>
      </Modal>
      <FavoriteResult ref={favoritreRef} item={item} />
      <ShareHotel ref={shareRef} item={item} />
    </>
  )
})

export default ShowMore
