import { useState, useEffect } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'
import Checkbox from '../../../../../ui/Checkbox'

const PayementMode = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [payOnline, setPayOnline] = useState()
  const [payAtHost, setPayAtHost] = useState()
  const { loading, updateData } = useUpdateHotel(setOpen)

  useEffect(() => {
    setPayOnline(annonce.regulations?.pay_online)
    setPayAtHost(annonce.regulations?.pay_at_host)
  }, [annonce])

  const handleSubmit = e => {
    e.preventDefault()
    const datas = {
      regulations: {
        ...annonce.regulations,
        pay_online: payOnline,
        pay_at_host: payAtHost,
      },
    }
    updateData(datas)
  }
  const openFuction = () => {
    setPayOnline(annonce.regulations?.pay_online)
    setPayAtHost(annonce.regulations?.pay_at_host)
    setOpen(true)
  }
  const reset = () => {
    setPayOnline()
    setPayAtHost()
    setOpen(false)
  }
  return (
    <div className={styles.block}>
      {!open ? (
        <Section title='Mode de paiement' handleOpen={openFuction}>
          <div className={styles.section_head}>
            {annonce.regulations?.pay_online && (
              <p>
                Paiement en ligne activé - Les voyageurs qui remplissent toutes vos conditions
                peuvent réserver et payer directement sur notre site.
              </p>
            )}
            {annonce.regulations?.pay_at_host && (
              <p>
                Paiement chez l'hôte activé - Les voyageurs qui remplissent toutes vos conditions
                peuvent réserver et payer sur place chez vous et c'est vous qui nous payer le frais
                de service Gowifmi.{' '}
                <a
                  href='/payement-policy-for-host'
                  target='_blank'
                  rel='noreferrer'
                  className={styles.btn_link}
                >
                  En savoir plus
                </a>
              </p>
            )}
          </div>
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title='Mode de paiement' handleReset={reset}>
            <p>Choisissez le mode de paiement.</p>
          </ContenHead>

          <form onSubmit={handleSubmit}>
            <div className={styles.body}>
              <div className={styles.input_block}>
                <div className={styles.checkox_container_payment}>
                  <div>
                    <label htmlFor='active_payment'>
                      <h5>Paiement en ligne activé</h5>
                      <p>Les voyageurs doivent payer sur Gowifmi.</p>
                    </label>
                  </div>
                  <div>
                    <Checkbox
                      id='active_payment'
                      value={payOnline}
                      checked={payOnline}
                      onChange={() => setPayOnline(!payOnline)}
                    />
                  </div>
                </div>
                <div className={styles.checkox_container_payment}>
                  <div>
                    <label htmlFor='desactive_payment'>
                      <h5>Activé le paiement chez l'hôte</h5>
                      <p>Les voyageurs doivent payer chez vous.</p>
                    </label>
                  </div>
                  <div>
                    <Checkbox
                      id='desactive_payment'
                      value={payAtHost}
                      checked={payAtHost}
                      onChange={() => setPayAtHost(!payAtHost)}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.block_btn_link_payment}>
                <a
                  href='/payement-policy-for-host'
                  target='_blank'
                  rel='noreferrer'
                  className={styles.btn_link}
                >
                  Consulter les conditions pour le paiement chez l'hôte
                </a>
              </div>
            </div>
            <BottomForm disabled={!payOnline && !payAtHost} loading={loading} handleReset={reset} />
          </form>
        </div>
      )}
    </div>
  )
}

export default PayementMode
