import styles from '../../../../../../css/modehotes/reservation/detail.module.css'
import DownloadReceiptButton from '../../../../../pdf/reservations/DownloadReceiptButton'

const DownloadReceipt = ({ reservation }) => {
  return (
    <div className={styles.content}>
      <h3>Télécharger le reçu</h3>
      <DownloadReceiptButton
        reservation={reservation}
        user={reservation?.user_id}
        hotel={reservation?.hotel}
      />
    </div>
  )
}

export default DownloadReceipt
