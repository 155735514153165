import styles from '../../../css/account/accountMobile.module.css'
import { Link } from 'react-router-dom'
import SvgIconRightDirector from '../../svgs/SvgIconRightDirector'

const PersonalInfo = () => {
  return (
    <Link to='/account-settings/info-personal'>
      <div className={styles.left_block}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 32 32'
          aria-hidden='true'
          role='presentation'
          focusable='false'
          style={{ display: 'block', height: '24px', width: '24px', fill: 'currentcolor' }}
        >
          <path d='M29 5a2 2 0 0 1 2 1.85V25a2 2 0 0 1-1.85 2H3a2 2 0 0 1-2-1.85V7a2 2 0 0 1 1.85-2H3zm0 2H3v18h26zm-3 12v2h-8v-2zm-16-8a3 3 0 0 1 2.5 4.67A5 5 0 0 1 15 20h-2a3 3 0 0 0-2-2.83V14a1 1 0 0 0-2-.12v3.29A3 3 0 0 0 7 20H5a5 5 0 0 1 2.5-4.33A3 3 0 0 1 10 11zm16 4v2h-8v-2zm0-4v2h-8v-2z'></path>
        </svg>
        <h3>Informations personnelles</h3>
      </div>
      <SvgIconRightDirector />
    </Link>
  )
}

export default PersonalInfo
