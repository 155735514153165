import styles from '../../../../../../css/modehotes/annonce/details/reglementInterieur.module.css'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import Checkbox from '../../../../../ui/Checkbox'

const ChildrenRegulation = ({ annonce }) => {
  const { updateData } = useUpdateHotel()

  const onchangeFilters = e => {
    if (e.target.checked === true) {
      const datas = {
        regulations: {
          ...annonce.regulations,
          accept_children: true,
        },
      }
      updateData(datas)
    } else {
      const datas = {
        regulations: {
          ...annonce.regulations,
          accept_children: false,
        },
      }
      updateData(datas)
    }
  }
  return (
    <div className={styles.block}>
      <h2>Règlement intérieur</h2>
      <p>Les voyageurs doivent accepter votre règlement intérieur avant de réserver</p>
      <div>
        <div className={styles.checkbox_container}>
          <div className={styles.text}>
            <label htmlFor='enfant'>Convient aux enfants (2-12 ans)</label>
            {annonce.regulations?.accept_children ? (
              <p>Votre logement convient aux enfants.</p>
            ) : (
              <p>Votre logement ne convient pas aux enfants.</p>
            )}
          </div>
          <div>
            <Checkbox
              id='enfant'
              value={true}
              checked={annonce.regulations?.accept_children}
              onChange={onchangeFilters}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChildrenRegulation
