import styles from '../../../../../css/modehotes/annonce/details/securiteVoyageur.module.css'
import Checkbox from '../../../../ui/Checkbox'

const KitchenJacuzzi = ({ picineInsecure, setPicineInsecure }) => {
  return (
    <div className={styles.container_equip}>
      <h4>Consignes de sécurité</h4>
      <div className={styles.checkbox_container}>
        <div>
          <label htmlFor='Piscine'>Piscine/jacuzzi sans clôture ni verrou</label>
          <p>
            Les voyageurs auront accès directement à une piscine souterraine ou hors sol ou un
            jacuzzi, sans clôture ou déverrouillé.
          </p>
        </div>
        <div>
          <Checkbox
            id='Piscine'
            value={picineInsecure}
            checked={picineInsecure}
            onChange={() => setPicineInsecure(!picineInsecure)}
          />
        </div>
      </div>
    </div>
  )
}

export default KitchenJacuzzi
