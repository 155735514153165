import { useState } from 'react'
import styles from '../../../css/logins/content.module.css'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import LoadingPoint from '../../LoadingPoint'
import ErrorSvg from '../../svgs/ErrorSvg'
import { CONNECTION_TYPE } from '../../../enums/user/connectionScreen'

const Connexion = ({ changeState }) => {
  const [loading, setLoading] = useState(false)
  const [echecConnexion, setEchecConnexion] = useState(false)
  const [reussit, setReussit] = useState(false)
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onChange' })
  const onSubmit = data => {
    setLoading(true)
    setEchecConnexion(false)
    axios
      .post(`/reset_password`, data)
      .then(() => {
        setEchecConnexion(false)
        setLoading(false)
        setReussit(true)
        reset()
      })
      .catch(() => {
        setLoading(false)
        setEchecConnexion(true)
        setReussit(false)
      })
  }

  return (
    <>
      <div className={styles.head}>
        <h4>Mot de passe oublié ? </h4>
        <p>
          Saisissez votre adresse e-mail associée à votre compte et nous vous enverrons un lien par
          e-mail pour réinitialiser votre mot de passe.
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.connexion_form}>
          {echecConnexion && (
            <div className={styles.connexion_echec}>
              <div className={styles.icon}>
                <ErrorSvg width={24} height={24} />
              </div>
              <div className={styles.notif}>
                <h1>Aucun utilisateur n'a été trouvé pour cette adresse e-mail</h1>
              </div>
            </div>
          )}
          {reussit && (
            <div className={styles.reussit_conexion}>
              <div className={styles.notif}>
                <p>
                  Veuillez consulter votre boîte de réception, un e-mail de confirmation doit s’y
                  trouver. Cliquez sur le lien dans l’e-mail pour réinitialiser votre mot de passe
                </p>
              </div>
            </div>
          )}
          <div className={errors.email && styles.erreur}>
            <label htmlFor='email'>Adresse e-mail</label>
            <input
              type='email'
              id='email'
              placeholder='ex: john@gmail.com'
              {...register('email', { required: true })}
            />
            {errors.email && (
              <span className={styles.erreur}>Veuiller renseigner l'adresse email</span>
            )}
          </div>
        </div>
        <div className={styles.btn_submit}>
          {loading ? (
            <button type='button' className={styles.disable} disabled>
              <LoadingPoint />
            </button>
          ) : (
            <button type='submit'>Réinitialiser le mot de passe</button>
          )}
          <p className={styles.creer}>
            <span onClick={() => changeState(CONNECTION_TYPE.CONNECTION)}>
              Je me suis souvenu de mon mot de passe
            </span>
          </p>
        </div>
      </form>
    </>
  )
}

export default Connexion
