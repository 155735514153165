import styles from '../../../../../../../css/modehotes/annonce/details/detail_head.module.css'
import Tooltip from '../../../../../../ui/Tooltip'

const ResInstantane = ({ annonce }) => {
  return (
    <div>
      {annonce.regulations?.instant_booking ? (
        <Tooltip title='Réservation instantanée activée'>
          <div>
            <button>
              <svg
                className={styles.book_now}
                viewBox='0 0 16 16'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
              >
                <path d='M8.903 1.037A.23.23 0 0 1 9 1.225V7h3.556a.23.23 0 0 1 .188.363l-5.326 7.545A.23.23 0 0 1 7 14.775V9H3.444a.23.23 0 0 1-.188-.363l5.326-7.545a.23.23 0 0 1 .32-.055z'></path>
              </svg>
            </button>
          </div>
        </Tooltip>
      ) : (
        <Tooltip title='Réservation instantanée désactivée'>
          <div>
            <button>
              <svg
                className={styles.not_book_now}
                viewBox='0 0 32 32'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
              >
                <path d='m8.9997576 13.242 8.81 8.81-3.9259697 7.4158877c-.4690921.8860628-1.7777518.5995837-1.877724-.3507l-.0060639-.1171877-.001-11h-1.999c-.47338693 0-.86994729-.3289337-.97358929-.7707092l-.01968298-.1126697-.00672773-.1166211zm-5.02057288-10.67702884 25.45584408 25.45584414-1.4142135 1.4142135-25.45584414-25.45584408zm16.02081528-.56497116c.6508561 0 1.1168104.60686837.9754623 1.22121228l-.0337228.11513075-2.7377395 7.66265697 3.7961918.001c.6741758.0001293 1.1315327.642041.9678226 1.2541717l-.0354193.1075772-.0488072.1061388-2.1370303 4.0361123-11.747-11.747.0002424-1.757c0-.51283584.38604019-.93550716.88337887-.99327227l.11662113-.00672773z'></path>
              </svg>
            </button>
          </div>
        </Tooltip>
      )}
    </div>
  )
}

export default ResInstantane
