import styles from '../../../css/modehotes/reservation/reservation_status.module.css'
import PendingSvg from '../../svgs/PendingSvg'
import CheckSvg from '../../svgs/CheckSvg'
import { ONLINE_PAYMENT_STATUS } from '../../../enums/booking/onlineStatus'

const PaymentStatus = ({ state }) => {
  return (
    <>
      {state === ONLINE_PAYMENT_STATUS.SUCCEEDED && (
        <div className={styles.block}>
          <p className={styles.complet}>
            <span>Réussi</span> <CheckSvg />
          </p>
        </div>
      )}
      {state === ONLINE_PAYMENT_STATUS.PROCESSING && (
        <div className={styles.block}>
          <p className={styles.traitement}>
            <span>Traitement</span> <PendingSvg />
          </p>
        </div>
      )}
      {state === ONLINE_PAYMENT_STATUS.REJECTED && (
        <div className={styles.block}>
          <p className={styles.try}>
            <span>Réessayer</span> <PendingSvg />
          </p>
        </div>
      )}
      {!state && (
        <div className={styles.block}>
          <p className={styles.mal_passe}>
            <span>---</span>
          </p>
        </div>
      )}
    </>
  )
}

export default PaymentStatus
