import { useState } from 'react'
import globalStyle from '../../../css/becomeHostes/globalStyle.module.css'
import styles from '../../../css/becomeHostes/question.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import axios from 'axios'
import LoadingPoint from '../../../components/LoadingPoint'
import ErrorServer from '../errorServer/ErrorServer'
import useAddHome from '../../../hooks/useAddHome'
import StatusHoteActivite from './StatusHoteActivite'
import SecurityTraveler from './SecurityTraveler'
import CategorieHome from './CategorieHome'
import Infos from './Infos'
import { SATATUS_ANNONCE } from '../../../enums/logement/status.enums'
import HeaderForm from '../globals/HeaderForm'
import { useQuery } from 'react-query'

const Form = () => {
  const [loading, setLoading] = useState(false)
  const [hotel, setHotel] = useState('')
  const [hoteStatusActivity, setHoteStatusActivity] = useState()
  const [cameras, setCameras] = useState(false)
  const [armes, setArmes] = useState(false)
  const [animauDangereux, setAnimauDangereux] = useState(false)
  const [typeAnnonce, setTypeAnnonce] = useState('')
  const [serverError, setServerError] = useState(false)
  const { updateData } = useAddHome(setLoading, setServerError)

  const history = useHistory()
  const location = useLocation()
  const page = location.pathname.split('/')[2]
  const id = location.pathname.split('/')[3]

  const { isLoading, error } = useQuery(`get-hotel-on-creation-${id}}`, () =>
    axios.get(`/hotel/oneAnnonce/${id}`).then(res => {
      setHotel(res.data)
      setHoteStatusActivity(res.data.about?.host_activity_status)
      setCameras(res.data.traveler_safety?.cameras || false)
      setArmes(res.data.traveler_safety?.weapon || false)
      setAnimauDangereux(res.data.traveler_safety?.dangerous_animals || false)
      setTypeAnnonce(res.data.usage_home_type || '')
      if (res.data.status !== SATATUS_ANNONCE.IN_PROGRESS) {
        history.push('/hosting')
      }
    })
  )

  const handleSubmit = () => {
    setLoading(true)
    const datas = {
      redirect_url: page,
      usage_home_type: typeAnnonce,
      about: {
        ...hotel.about,
        host_activity_status: hoteStatusActivity,
      },
      traveler_safety: {
        ...hotel.traveler_safety,
        cameras: cameras,
        weapon: armes,
        dangerous_animals: animauDangereux,
      },
    }
    updateData(datas, '/become-a-host/preview')
  }

  if (error || serverError) {
    return <ErrorServer />
  }

  return (
    <div className={globalStyle.left}>
      <HeaderForm />
      <div className={globalStyle.block_center}>
        <div className={globalStyle.block_form}>
          {isLoading ? (
            <LoadingPoint />
          ) : (
            <div className={globalStyle.annimcontent}>
              <div className={styles.question}>
                <StatusHoteActivite
                  hoteStatusActivity={hoteStatusActivity}
                  setHoteStatusActivity={setHoteStatusActivity}
                />
                <SecurityTraveler
                  cameras={cameras}
                  setCameras={setCameras}
                  armes={armes}
                  setArmes={setArmes}
                  animauDangereux={animauDangereux}
                  setAnimauDangereux={setAnimauDangereux}
                />
                <CategorieHome typeAnnonce={typeAnnonce} setTypeAnnonce={setTypeAnnonce} />
                <Infos />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={globalStyle.step_buttons}>
        <button
          type='button'
          onClick={() => history.push(`/become-a-host/price/${id}`)}
          className={globalStyle.retour}
        >
          Retour
        </button>
        {loading ? (
          <button disabled className={globalStyle.loading}>
            <LoadingPoint />
          </button>
        ) : (
          <button
            type='button'
            onClick={handleSubmit}
            disabled={typeAnnonce === ''}
            className={
              typeAnnonce === ''
                ? `${globalStyle.publish} ${globalStyle.disable}`
                : globalStyle.publish
            }
          >
            Vérifier mon annonce
          </button>
        )}
      </div>
    </div>
  )
}

export default Form
