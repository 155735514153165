import styles from '../../../css/account/disabledAccount.module.css'

const Block1 = ({ handleclose, setActive, raison1, setRaison1 }) => {
  const raisons = [
    { value: "J'ai des préoccupations concernant la sécurité et la confidentialité." },
    { value: 'Je ne peux plus accueillir de voyageurs.' },
    {
      value:
        "Je ne suis pas en mesure de respecter les conditions générales ou l'engagement de la communauté de Gowifmi.",
    },
    { value: 'Autre chose' },
  ]

  return (
    <>
      <div className={styles.m_body}>
        <div>
          <h3>Quelle raison vous a poussé à désactiver le compte ?</h3>
        </div>
        <div className={styles.grid_system}>
          {raisons.map((item, i) => (
            <label htmlFor={item.value} className={styles.radio_container} key={i}>
              <p>{item.value}</p>
              <div>
                <input
                  type='radio'
                  id={item.value}
                  value={item.value}
                  checked={raison1 === item.value}
                  onChange={e => setRaison1(e.target.value)}
                />
              </div>
            </label>
          ))}
        </div>
      </div>
      <div className={styles.button_action}>
        <button className={styles.retour} onClick={handleclose}>
          Annuler
        </button>
        <button
          className={!raison1 ? `${styles.next} ${styles.disabled}` : styles.next}
          disabled={!raison1}
          onClick={() => setActive(2)}
        >
          Suivant
        </button>
      </div>
    </>
  )
}

export default Block1
