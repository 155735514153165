import { useState } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import Options from './Options'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const Currency = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [devise, setDevise] = useState()
  const { loading, updateData } = useUpdateHotel(setOpen)

  const handleSubmit = () => {
    const datas = {
      prices: {
        ...annonce.prices,
        currency: devise,
      },
    }
    updateData(datas)
  }
  const openFuction = () => {
    setDevise(annonce.prices?.currency)
    setOpen(true)
  }
  const reset = () => {
    setDevise()
    setOpen(false)
  }
  return (
    <div className={styles.block}>
      {!open ? (
        <Section title="Devise de l'annonce" handleOpen={openFuction}>
          <p>{annonce.prices?.currency}</p>
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title="Devise de l'annonce" handleReset={reset}>
            <p>
              C'est la devise que vous utiliserez pour définir vos prix et vos réductions.
              {/* Les prix sont indiqués aux voyageurs dans leur propre devise. */}
            </p>
          </ContenHead>
          <div className={styles.body}>
            <div className={styles.input_block}>
              <select defaultValue={devise} onChange={e => setDevise(e.target.value)}>
                <Options />
              </select>
            </div>
          </div>
          <BottomForm
            handleSubmit={handleSubmit}
            disabled={devise === annonce.prices?.currency}
            loading={loading}
            handleReset={reset}
          />
        </div>
      )}
    </div>
  )
}

export default Currency
