import { useRef, useState } from 'react'
import styles from '../../../../css/search_room_result/card.module.css'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Navigation, Pagination } from 'swiper/modules'
import FavoriteCard from '../favorites/FavoriteCard'

const Photo = ({ item }) => {
  const [prev, setPrev] = useState(false)
  const [nex, setNex] = useState(true)

  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  const enfant = Params.get('children')
  const adulte = Params.get('adults')
  const bebe = Params.get('infants')
  const animal = Params.get('pets')
  const checkin = Params.get('checkin')
  const checkout = Params.get('checkout')

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  const tailWindow = window.matchMedia('(min-width:743px)')

  return (
    <>
      <div className={styles.container}>
        <Swiper
          modules={[Navigation, Pagination]}
          pagination={{
            dynamicBullets: true,
          }}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={swiper => {
            swiper.params.navigation.prevEl = navigationPrevRef.current
            swiper.params.navigation.nextEl = navigationNextRef.current
          }}
          onSlideNextTransitionStart={() => setPrev(true)}
          onReachBeginning={() => setPrev(false)}
          onSlidePrevTransitionStart={() => setNex(true)}
          onReachEnd={() => setNex(false)}
          className={styles.block_photo_result}
        >
          {item.images?.map((photo, i) => (
            <SwiperSlide key={i}>
              <Link
                target={tailWindow.matches ? '_blank' : null}
                rel='noopener noreferrer'
                to={`/rooms/${item._id}?adults=${adulte}&children=${enfant}&infants=${bebe}&pets=${animal}&checkin=${checkin}&checkout=${checkout}`}
              >
                <div
                  className={styles.imgslider}
                  style={{
                    backgroundImage: `linear-gradient(
                        to bottom,
                        transparent 80%,
                        rgba(0, 0, 0, 0.200)
                        ),url(${photo.url})`,
                  }}
                ></div>
              </Link>
            </SwiperSlide>
          ))}
          <div
            ref={navigationPrevRef}
            className={prev ? styles.prev : `${styles.prev} ${styles.disable}`}
          >
            <svg
              viewBox='0 0 32 32'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'
              role='presentation'
              focusable='false'
            >
              <g>
                <path d='m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932'></path>
              </g>
            </svg>
          </div>
          <div
            ref={navigationNextRef}
            className={nex ? styles.next : `${styles.next} ${styles.disable}`}
          >
            <svg
              viewBox='0 0 32 32'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'
              role='presentation'
              focusable='false'
            >
              <g>
                <path d='m12 4 11.2928932 11.2928932c.3905243.3905243.3905243 1.0236893 0 1.4142136l-11.2928932 11.2928932'></path>
              </g>
            </svg>
          </div>
        </Swiper>
        <FavoriteCard item={item} />
      </div>
    </>
  )
}

export default Photo
