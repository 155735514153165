import { useState } from 'react'
import styles from '../../css/confirmations/block_1.module.css'
import { Link } from 'react-router-dom'
import RequestNotif from './RequestNotif'
import CopyToClipboard from 'react-copy-to-clipboard'
import Snackbar from '../ui/Snackbar'
import { STATUS_BOOKING } from '../../enums/booking/status.enums'

const CheckEdSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 32 32'
      aria-hidden='true'
      role='presentation'
      focusable='false'
      style={{
        display: 'block',
        fill: 'none',
        height: '20px',
        width: '20px',
        stroke: 'currentcolor',
        strokeWidth: '2.66667',
        overflow: 'visible',
      }}
    >
      <path fill='none' d='m4 16.5 8 8 16-16'></path>
    </svg>
  )
}

const Block1NopayOnlone = ({ reservation }) => {
  const [copy, setCopy] = useState(false)

  const statut = reservation?.status
  const handleClickcopy = () => {
    setCopy(true)
  }

  const handleClosecopy = () => {
    setCopy(false)
  }
  return (
    <>
      <div className={styles.left_block}>
        <div className={styles.content}>
          <h4>Merci !</h4>
          <div>
            {statut === STATUS_BOOKING.REQUEST ? (
              <div className={styles.block}>
                <CheckEdSvg />
                <p>
                  Vous pouvez maintenant{' '}
                  <Link to='/inbox' className={styles.modif}>
                    envoyer un message
                  </Link>{' '}
                  à votre hôte concernant votre voyage jusqu'à votre arrivée.
                </p>
              </div>
            ) : (
              <div className={styles.block}>
                <CheckEdSvg />

                <p>
                  Vous pouvez maintenant{' '}
                  <Link to='/reservations/upcoming' className={styles.modif}>
                    Gérer votre réservation
                  </Link>{' '}
                  jusqu'à votre arrivée.
                </p>
              </div>
            )}
          </div>

          <div>
            <div className={styles.block}>
              <CheckEdSvg />

              <p>
                Copier la référence de votre {statut === STATUS_BOOKING.REQUEST && 'demande de'}{' '}
                réservation <strong>{reservation?.reservation_infos?.reference}</strong>
                <CopyToClipboard
                  text={reservation?.reservation_infos?.reference}
                  onCopy={handleClickcopy}
                >
                  <button className={styles.button_copy_text}>Copier</button>
                </CopyToClipboard>
              </p>
            </div>
          </div>
        </div>
        {statut === STATUS_BOOKING.REQUEST && <RequestNotif reservation={reservation} />}
        <div className={styles.contact_etab}>
          <h3>Votre logement peut vous aider à payer votre prochain voyage.</h3>
          <div className={styles.contact_etab_block}>
            <div>
              <h4>Mettez en avant votre logement en devenant un hôte Gowifmi</h4>
              <p>
                Créez une opportunité de gagner de l'argent en partageant votre logement ou en
                invitant une personne à proposer son logement et gagnez de l'argent.
              </p>
              <div className={styles.block_link}>
                <Link to='/host/homes'>Ajouter votre logement</Link>
                <Link to='/sponsorship/home'>Parrainez un hôte</Link>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.asavoir}>
          <h4>Besoin d'aide ?</h4>
          <p>
            Nous sommes là pour répondre à vos questions et vous aider à gérer votre réservation.
          </p>
          <a href='/help/feedback'>Contactez le service client</a>
        </div>
      </div>
      {/***----modal de copie */}
      <Snackbar
        isOpen={copy}
        onClose={handleClosecopy}
        autoHideDuration={1000}
        message='Lien copié'
      />
    </>
  )
}
export default Block1NopayOnlone
