import { useState } from 'react'
import styles from '../../../../css/search_room_result/filtre_2.module.css'
import ServiceDeBase from './amenities/Service'
import Feature from './amenities/Feature'
import Security from './amenities/Security'
import { useSearchParams } from '../../../../context/SearchParamContext'
import Checkbox from '../../../ui/Checkbox'

const Amenitie = () => {
  const [voirPlus, setVoirPlus] = useState(false)

  const array = [
    { name: 'Bord de mer', value: '5' },
    { name: 'Wifi', value: '1' },
    { name: 'Parking gratuit sur place', value: '2' },
    { name: 'Parking payant sur place', value: '112' },
    { name: 'Lave-linge', value: '6' },
    { name: 'Piscine', value: '3' },
    { name: 'Cuisine', value: '4' },
  ]
  const { params, updateParams } = useSearchParams()
  const newParams = new URLSearchParams(params.toString())

  URLSearchParams.prototype.remove = function (key, value) {
    const entries = this.getAll(key)
    const newEntries = entries.filter(entry => entry !== value)
    this.delete(key)
    newEntries.forEach(newEntry => this.append(key, newEntry))
  }

  const onchangeFilters = e => {
    if (!e.target.checked === true) {
      newParams.delete('page')
      newParams.remove('amenities', e.target.value)
    } else {
      newParams.delete('page')
      newParams.append('amenities', e.target.value)
    }
    updateParams(newParams)
  }
  return (
    <div className={styles.block_section_filter}>
      <h3>Équipements</h3>
      <div className={styles.equipement}>
        <div>
          <p>Équipements les plus recherchés</p>
          <div className={styles.grid_system}>
            {array.map(item => (
              <div key={item.name} className={styles.checkbox_container}>
                <div>
                  <Checkbox
                    id={item.name}
                    value={item.value}
                    checked={params.getAll('amenities').includes(item.value)}
                    onChange={onchangeFilters}
                  />
                </div>
                <label htmlFor={item.name}>{item.name}</label>
              </div>
            ))}
          </div>
        </div>
        {voirPlus && (
          <>
            <ServiceDeBase />
            <Feature />
            <Security />
          </>
        )}
        <div>
          <button onClick={() => setVoirPlus(!voirPlus)}>
            {voirPlus ? 'Afficher moins' : 'Afficher plus'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Amenitie
