import { useState, useEffect } from 'react'
import styles from '../../../css/becomeHostes/home/header.module.css'
import { Link } from 'react-router-dom'
import GowifmiLogoSvg from '../../svgs/GowifmiLogoSvg'
import LoadingPoint from '../../LoadingPoint'

const Header = ({ user, isLoading, referrer, elementRef, handleConnection }) => {
  const [navbar, setNavbar] = useState(false)

  const handleScroll = () => {
    if (window.scrollY > 10) {
      setNavbar(true)
    } else setNavbar(false)
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  const handleButtonClick = () => {
    if (elementRef.current) {
      window.scrollTo({
        top: elementRef.current.offsetTop - 80,
        behavior: 'smooth',
      })
    }
  }

  return (
    <div className={styles.home_head} id='become-a-host'>
      <div className={navbar ? `${styles.home_nav} ${styles.active}` : styles.home_nav}>
        <div className={styles.home_logo}>
          <a href='/'>
            <GowifmiLogoSvg />
          </a>
        </div>
      </div>
      <div className={styles.hero_section}>
        <div className={styles.left_block}>
          {referrer && (
            <div className={styles.wrapper_img_profile}>
              <div className={styles.image_block}>
                {referrer?.picture ? (
                  <img src={referrer?.picture} alt='' />
                ) : (
                  <svg
                    viewBox='0 0 32 32'
                    xmlns='http://www.w3.org/2000/svg'
                    aria-hidden='true'
                    role='presentation'
                    focusable='false'
                    style={{
                      display: 'block',
                      height: '100%',
                      width: '100%',
                      fill: '#999',
                      background: '#fff',
                      strokeWidth: '3',
                    }}
                  >
                    <path d='m16 .7c-8.437 0-15.3 6.863-15.3 15.3s6.863 15.3 15.3 15.3 15.3-6.863 15.3-15.3-6.863-15.3-15.3-15.3zm0 28c-4.021 0-7.605-1.884-9.933-4.81a12.425 12.425 0 0 1 6.451-4.4 6.507 6.507 0 0 1 -3.018-5.49c0-3.584 2.916-6.5 6.5-6.5s6.5 2.916 6.5 6.5a6.513 6.513 0 0 1 -3.019 5.491 12.42 12.42 0 0 1 6.452 4.4c-2.328 2.925-5.912 4.809-9.933 4.809z'></path>
                  </svg>
                )}
              </div>
              <div className={styles.wrapper_name}>
                <h2>Invité par</h2>
                <p>{referrer?.first_name}</p>
              </div>
            </div>
          )}
          <div className={styles.title_block}>
            <h1>
              Ajouter votre <br /> logement sur Gowifmi c'est gratuit.
            </h1>
            <p className={styles.wrapper_name}>
              Boostez vos revenus
              <br /> sur chaque réservation grâce à{' '}
              <span onClick={handleButtonClick}>FreeMode</span>
            </p>
          </div>
        </div>
        <div className={styles.form_block}>
          <div className={styles.form_head}>
            <h2>Gagnez plus en mettant votre logement sur Gowifmi</h2>
            <p>Nous allons vous aider pour chacune de ces étapes.</p>
          </div>
          <div>
            {user ? (
              <div>
                {isLoading ? (
                  <button type='button'>
                    <LoadingPoint />
                  </button>
                ) : (
                  <Link
                    to={
                      user.isHost
                        ? `/become-a-host`
                        : `/become-a-host/type-property-group${
                            referrer ? `?referrer=${referrer?.id}` : ''
                          }`
                    }
                  >
                    Commencer
                  </Link>
                )}
              </div>
            ) : (
              <button type='button' onClick={handleConnection}>
                Contnuer
              </button>
            )}
          </div>
          {referrer && (
            <div className={styles.condition_text}>
              <p>
                En cliquant sur Commencer, j'accepte que le parrain soit informé lorsque je créerai
                une annonce et lorsque j'accueillerai mes premiers voyageurs.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header
