import React, { useState } from 'react'
import styles from '../../../../css/traveler/favoris/partage.module.css'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Modal from '../../../ui/Modal'
import Snackbar from '../../../ui/Snackbar'
import CloseButton from '../../../ui/CloseButton'

const Partage = React.forwardRef(({ favoris }, ref) => {
  const [modal, setModal] = useState(false)
  const [copy, setCopy] = useState(false)

  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      setModal(true)
    },
  }))
  const handleClickcopy = () => {
    setCopy(true)
  }

  const handleClosecopy = () => {
    setCopy(false)
  }
  const origin = window.location.origin
  const pathname = window.location.pathname

  return (
    <>
      <Modal isOpen={modal} onClose={() => setModal(false)}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={() => setModal(false)} />
            <div></div>
          </div>
          <div className={styles.m_body}>
            <h3>Partagez cette liste avec des proches</h3>
            <div>
              <CopyToClipboard
                text={`${origin}${pathname}?share=invitation`}
                onCopy={handleClickcopy}
              >
                <button>
                  <div className={styles.block}>
                    <div>
                      <svg
                        viewBox='0 0 32 32'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                        role='presentation'
                        focusable='false'
                        style={{
                          display: 'block',
                          height: '32px',
                          width: '32px',
                          fill: 'currentcolor',
                        }}
                      >
                        <path d='m19.0744181 13.9994653.1730353.1672949-1.4142136 1.4142136c-1.9012367-1.9012367-4.9526642-1.9512693-6.9144372-.1500977l-.1566306.1500977-3.95456016 3.9545601c-1.56209716 1.5620972-1.56209716 4.0947571 0 5.6568543 1.51476089 1.5147608 3.94215376 1.5606627 5.51222566.1377055l.1446286-.1377055 3.7974253-3.7974254 1.4142136 1.4142136-3.7974253 3.7974253c-2.3431458 2.3431458-6.14213567 2.3431458-8.48528142 0-2.28989244-2.2898924-2.34193545-5.9702062-.15612903-8.3232996l.15612903-.1619818 3.95456015-3.9545601c2.67671857-2.6767186 6.98188897-2.7324836 9.72645967-.1672949zm7.5321836-8.60606702c2.2898925 2.28989244 2.3419355 5.97020622.156129 8.32329962l-.156129.1619818-3.9545601 3.9545601c-2.6767186 2.6767186-6.981889 2.7324836-9.7264597.1672949l-.1730353-.1672949 1.4142136-1.4142136c1.9012367 1.9012367 4.9526642 1.9512693 6.9144372.1500977l.1566306-.1500977 3.9545602-3.9545601c1.5620971-1.5620972 1.5620971-4.09475709 0-5.65685426-1.5147609-1.51476088-3.9421538-1.56066273-5.5122257-.13770553l-.1446286.13770553-3.7974253 3.79742536-1.4142136-1.41421361 3.7974253-3.79742531c2.3431458-2.34314575 6.1421357-2.34314575 8.4852814 0z'></path>
                      </svg>
                    </div>
                    <div>
                      <h4>Copier le lien</h4>
                      <p>Toute personne qui aura accès au lien pourra consulter vos favoris</p>
                    </div>
                  </div>
                </button>
              </CopyToClipboard>
            </div>
            <a
              href={`mailto:?subject=Découvrez mes favoris : ${favoris?.name}&body=J'ai créé une liste de favoris : ${favoris?.name}! Découvrez mes coups de cœur sur Gowifmi. ${origin}${pathname}?share=invitation`}
            >
              <div className={styles.block}>
                <div>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 32 32'
                    aria-hidden='true'
                    role='presentation'
                    focusable='false'
                    style={{
                      display: 'block',
                      height: '32px',
                      width: '32px',
                      fill: 'currentcolor',
                    }}
                  >
                    <path d='M22 5a6 6 0 0 1 3.64 10.77A9 9 0 0 1 31 23.74V24h-2a7 7 0 0 0-6-6.93v-2.2A4 4 0 0 0 22 7a4 4 0 0 0-3.68 5.57A5 5 0 0 1 21 17a4.99 4.99 0 0 1-1.6 3.67 9 9 0 0 1 5.6 8.06V29h-2a7 7 0 0 0-6-6.93v-2.24a3 3 0 1 0-2 0v2.24a7 7 0 0 0-6 6.69V29H7a9 9 0 0 1 5.6-8.34 5 5 0 0 1 1.08-8.09A4 4 0 1 0 9 14.87v2.2a7 7 0 0 0-6 6.69V24H1a9 9 0 0 1 5.36-8.23A6 6 0 1 1 15.92 10h.16A6 6 0 0 1 22 5z'></path>
                  </svg>
                </div>
                <div>
                  <h4>E-mail</h4>
                  <p>Toute personne qui aura accès au lien pourra consulter vos favoris</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </Modal>
      {/***----modal de copie */}
      <Snackbar
        isOpen={copy}
        onClose={handleClosecopy}
        autoHideDuration={1000}
        message='Lien copié'
      />
    </>
  )
})

export default Partage
