import { useState } from 'react'
import styles from '../../css/add_to_favoris/favoris.module.css'
import axios from 'axios'
import LoadingPoint from '../LoadingPoint'
import CloseButton from '../ui/CloseButton'

const CreateList = ({
  annonce,
  setModal,
  setCreateList,
  setPushFavori,
  setsupFavori,
  getAllFavoris,
}) => {
  const [name, setNam] = useState('')
  const [loading, setLoading] = useState(false)

  // creer une nouvelle liste et ajouter aux favori liste
  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    const datas = {
      name: name,
      lists: {
        hotel: annonce._id,
      },
    }
    axios
      .post(`/favoris`, datas)
      .then(() => {
        setLoading(false)
        setModal(false)
        pupupModal()
      })
      .catch(() => {
        setLoading(false)
      })
  }
  const pupupModal = () => {
    getAllFavoris()
    setPushFavori(true)
    setsupFavori(false)
    setTimeout(() => {
      setPushFavori(false)
    }, 3000)
  }

  return (
    <div className={styles.modal_content}>
      <div className={styles.m_head}>
        <CloseButton onClick={() => setCreateList(false)} />
        <h3>Nommer cette liste de favoris</h3>
        <div></div>
      </div>
      <form onSubmit={handleSubmit} className={styles.m_body}>
        <div>
          <input
            type='text'
            placeholder='Nom'
            maxLength={50}
            onChange={e => setNam(e.target.value)}
          />
          <span>50 caractères maximum</span>
        </div>
        <div>
          {loading ? (
            <button disabled className={styles.loading}>
              <LoadingPoint />
            </button>
          ) : (
            <button
              type='submit'
              disabled={!name.trim()}
              className={!name.trim() ? `${styles.send} ${styles.disable}` : styles.send}
            >
              Créer
            </button>
          )}
        </div>
      </form>
    </div>
  )
}

export default CreateList
