function useHotelRating(annonce) {
  const comments = annonce?.comments?.filter(el => el.published === true)
  const commentLength = comments?.length
  const totalLength = 5 * commentLength //le nombre des etoils dans chaque avis fois la taille de tout les comments
  const totalRatingList = 6 //le nombre des avis a soumettre

  const totalArrival = comments?.reduce((prev, current) => prev + current.arrival, 0)
  const totalArrivalPourcentage = Math.floor((totalArrival / totalLength) * 100)
  const arrivalOn5 = (totalArrival / commentLength).toFixed(1)

  const totalcleanliness = comments?.reduce((prev, current) => prev + current.cleanliness, 0)
  const totalCleanlinessPourcentage = Math.floor((totalcleanliness / totalLength) * 100)
  const cleanlinessOn5 = (totalcleanliness / commentLength).toFixed(1)

  const totalCommunication = comments?.reduce((prev, current) => prev + current.communication, 0)
  const totalCommunicationPourcentage = Math.floor((totalCommunication / totalLength) * 100)
  const communicationOn5 = (totalCommunication / commentLength).toFixed(1)

  const totalAmenitie = comments?.reduce((prev, current) => prev + current.amenities, 0)
  const totalAmenitiePourcentage = Math.floor((totalAmenitie / totalLength) * 100)
  const amenitieOn5 = (totalAmenitie / commentLength).toFixed(1)

  const totalLocation = comments?.reduce((prev, current) => prev + current.location, 0)
  const totalLocationPourcentage = Math.floor((totalLocation / totalLength) * 100)
  const locationOn5 = (totalLocation / commentLength).toFixed(1)

  const totalQualityPrice = comments?.reduce((prev, current) => prev + current.value_for_money, 0)
  const totalQualityPricePourcentage = Math.floor((totalQualityPrice / totalLength) * 100)
  const qualityPriceOn5 = (totalQualityPrice / commentLength).toFixed(1) //total de tout avis sur 5

  const Total =
    totalArrival +
    totalcleanliness +
    totalCommunication +
    totalAmenitie +
    totalLocation +
    totalQualityPrice
  const totalComment = (Total / totalRatingList / commentLength).toFixed(1)

  return {
    commentLength,
    totalArrivalPourcentage,
    arrivalOn5,
    totalCleanlinessPourcentage,
    cleanlinessOn5,
    totalCommunicationPourcentage,
    communicationOn5,
    totalAmenitiePourcentage,
    amenitieOn5,
    totalLocationPourcentage,
    locationOn5,
    totalQualityPricePourcentage,
    qualityPriceOn5,
    totalComment,
  }
}

export default useHotelRating
