import React, { useState } from 'react'
import styles from '../../../../../../css/booking/politiqueHote.module.css'
import Modal from '../../../../../ui/Modal'
import CloseButton from '../../../../../ui/CloseButton'

const DomagePolicy = React.forwardRef((props, ref) => {
  const [modal, setModal] = useState(false)

  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      setModal(true)
    },
  }))

  return (
    <>
      <Modal isOpen={modal} onClose={() => setModal(false)}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={() => setModal(false)} />
            <div></div>
          </div>
          <div className={styles.m_body}>
            <div className={styles.asavoir}>
              <div>
                <h2>En cas de facturation pour des dommages</h2>
                <div className={styles.infos}>
                  <p>
                    Bien que les accidents sont rares mais peuvent toute fois survenir. En cas des
                    dommages causés par vous, une personne que vous avez invité ou un animal de
                    compagnie. Votre mode de paiment peut être débitée.
                  </p>
                </div>
              </div>
              <div>
                <h2>Qu'est-ce qui peut m'être facturé ?</h2>
                <div className={styles.infos}>
                  <p>
                    Il se peut que vous soyez facturé pour tout dommage, tout objet manquant à votre
                    hôte, ou tout frais de nettoyage inattendu dû à votre séjour.
                  </p>
                </div>
              </div>
              <div>
                <h2>Quel est le processus ?</h2>
                <div className={styles.infos}>
                  <p>
                    Si vous et votre hôte ne parvenez pas à résoudre le problème entre vous, nous
                    interviendrons alors pour déterminer qui est responsable. Nous ne débiterons
                    votre mode de paiement que si nous avons des raisons de croire que la
                    responsabilité vous revient.
                  </p>
                </div>
              </div>
              <div>
                <h2>Que se passe-t-il si je ne suis pas d'accord ?</h2>
                <div className={styles.infos}>
                  <p>
                    Sachez que vous aurez la possibilité de faire un recours, si vous estimez que
                    nous avons commis une erreur.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
})
export default DomagePolicy
