import { Helmet } from 'react-helmet-async'
import styles from '../../css/about_us/about.module.css'
import DefaultLayout from '../../layouts/DefaultLayout'

function ReplacementPolicy() {
  return (
    <DefaultLayout footer={true}>
      <Helmet>
        <title>Politique de remplacement d'hébergement et de remboursement | Gowifmi</title>
      </Helmet>
      <div className={styles.main}>
        <div className={styles.pourquoi_text}>
          <h1>Politique relative à la communauté Gowifmi</h1>

          <h2>Politique de remplacement d'hébergement et de remboursement</h2>
          <div>
            <p>Date d'entrée en vigueur : 14 octobre 2022</p>
            <p>
              La présente Politique de remplacement d'hébergement et de remboursement a pour but de
              vous informer des procédures requises pour effectuer une nouvelle réservation en cas
              de résiliation du contrat de location de produits et services souscrits.
            </p>
          </div>

          <h2>Que se passe-t-il en cas d'annulation par l'hôte avant l'arrivée ?</h2>
          <div>
            <p>
              Si l'hôte annule une réservation avant l'arrivée et le voyageur{' '}
              <a href='/help/feedback'>nous contacte</a>, nous lui offrons notre aide pour trouver
              un hébergement comparable ou supérieur.
            </p>
          </div>
          <h2>
            Que se passe-t-il si un autre problème rencontré lors d'un voyage perturbe un séjour
          </h2>
          <div>
            <p>
              Si vous rencontrez un problème lors de votre voyage, veuillez-nous en informer dans
              les 72 heures suivant la découverte. Si nous constatons que le problème rencontré lors
              d'un voyage a perturbé le séjour, la politique (conditions d'annulations) sélectionnée
              par l'hôte s'appliquera automatiquement, l’hôte vous remboursera en totalité ou
              partiel suivant le mode de politique qu’il a eu à sélectionner avant la réservation.
              Nous pourrons vous aider à trouver un hébergement comparable ou supérieur. Si le
              voyageur décide de quitter son logement suite à un problème rencontré lors de son
              voyage et <a href='/help/feedback'>nous contacte</a>, nous lui proposons notre aide et
              notre assistance pour trouver un hébergement équivalent ou supérieur pour les nuits
              restantes du séjour.
            </p>
          </div>
          <h3>Problèmes rencontrés lors d’un voyage couvert</h3>
          <div className={styles.list}>
            <p>Le terme « problème rencontré lors d'un voyage » vise les situations suivantes :</p>
            <ul>
              <li>L'hôte annule la réservation avant l'arrivée.</li>
              <li>L'hôte ne fournit pas l'accès à l'hébergement.</li>
              <li>
                L'hôte omet d'indiquer dans l'annonce que l'hôte, une autre personne ou un animal de
                compagnie sera présent pendant le séjour.
              </li>
              <li>
                Les logements ne sont pas habitables à l'arrivée pour l'une des raisons suivantes :
              </li>
              <ol>
                <li>
                  Ils ne sont pas raisonnablement propres, y compris la literie et les serviettes.
                </li>
                <li>Ils comportent des risques pour la sécurité ou la santé.</li>
                <li>Ils abritent des animaux nuisibles.</li>
              </ol>
              <li>L'annonce contient une inexactitude matérielle telle que :</li>
              <ol>
                <li>
                  Le type de logement est incorrect (par exemple, logement entier, chambre privée ou
                  chambre partagée).
                </li>
                <li>
                  Le type ou nombre de pièces est incorrect (par exemple, chambres, salles de bains
                  et cuisines).
                </li>
                <li>L'emplacement de l'hébergement est incorrect.</li>
                <li>
                  Un équipement ou une caractéristique spécifique décrit dans l'annonce est
                  inexistant ou ne fonctionne pas. Par exemple, piscine, jacuzzi, salle de bain
                  (toilettes, douche ou baignoire), cuisine (évier, cuisinière, réfrigérateur ou
                  autre appareil ménager), systèmes électriques, de chauffage ou de climatisation.
                </li>
              </ol>
            </ul>
          </div>

          <h2>Fonctionnement des réclamations</h2>
          <div>
            <p>
              Pour obtenir un remboursement ou une nouvelle réservation, le voyageur qui a effectué
              la réservation peut envoyer une réclamation en{' '}
              <a href='/help/feedback'>nous contactant</a>. Les réclamations doivent nous être
              adressées au plus tard dans les 72 heures suivant la découverte du problème lors du
              voyage et inclure des preuves à l'appui telles que des photos ou une confirmation des
              conditions par l'hôte. Nous déterminerons l'existence d'un problème rencontré lors
              d'un voyage en examinant les preuves dont nous disposons.
            </p>
          </div>

          <h2>Conséquences de la présente Politique pour les hôtes</h2>
          <div>
            <p>
              Si un hôte annule un séjour ou si l'un des problèmes rencontrés pendant le voyage
              vient troubler un séjour, l’hôte sera directement soumis à la politique sélectionner
              avant la réservation.
            </p>
            <p>
              Dans la majorité des cas, nous tenterons de résoudre la réclamation d'un voyageur
              auprès de son hôte. Les hôtes peuvent également contester un problème qu'ils
              rencontrent pendant le voyage en <a href='/help/feedback'>nous contactant</a>.
            </p>
          </div>

          <h2>Autres éléments à prendre en compte</h2>
          <div>
            <p>
              La présente politique s'applique à toutes les réservations effectuées à partir de la
              date d'entrée en vigueur de ce document. Lorsque la présente Politique s'applique,
              elle prévaut sur les conditions d'annulation de la réservation. Avant d'envoyer une
              réclamation, il est recommandé que le voyageur contacte directement l'hôte afin de
              résoudre les problèmes rencontrés lors de son voyage. En vertu de la présente
              Politique, nous pouvons réduire le montant du remboursement ou ajuster l'aide proposée
              pour effectuer une nouvelle réservation afin de refléter tout remboursement ou
              assistance fournis directement par un hôte. Dans le souci de vous aider pour effectuer
              une nouvelle réservation, nous pouvons, sans y être obligés, payer ou contribuer aux
              frais d'une nouvelle réservation. Nous pouvons également offrir aux voyageurs la
              possibilité d'appliquer la valeur d'une réservation déjà annulée à de nouveaux
              hébergements ou de recevoir un crédit voyage, au lieu d'obtenir un remboursement.
            </p>
            <p>
              Lorsqu'un voyageur démontre qu'il n'a pas été possible de signaler en temps opportun
              un problème rencontré pendant le voyage, nous pouvons autoriser le signalement tardif
              de ce problème conformément à la présente Politique. Si les problèmes rencontrés lors
              d'un voyage, sont causés par le voyageur, leurs invités, les co-voyageurs, ou leurs
              animaux de compagnie ne sont pas couverts par la présente Politique.
            </p>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}
export default ReplacementPolicy
