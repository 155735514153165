import { useState } from 'react'
import styles from '../../../../css/search_room_result/filtre_2.module.css'
import { useSearchParams } from '../../../../context/SearchParamContext'
import Bed from './bed_bathrooms/Bed'
import Bathroom from './bed_bathrooms/Bathroom'

const Room = () => {
  const [voirPlus, setVoirPlus] = useState(false)
  const NumberChambre = ['1', '2', '3', '4', '5', '6', '7', '8']
  const { params, updateParams } = useSearchParams()
  const newParams = new URLSearchParams(params.toString())

  const handleChambre = e => {
    newParams.delete('page')
    newParams.set('number_of_rooms', e.target.value)
    updateParams(newParams)
  }
  const AllChambre = () => {
    newParams.delete('page')
    newParams.delete('number_of_rooms')
    updateParams(newParams)
  }

  return (
    <div className={styles.block_section_filter}>
      <h3>Chambres et lits</h3>
      <div className={styles.chambre_lit}>
        <div>
          <p>Chambres</p>
          <div className={styles.radio_container}>
            <label>
              <input checked={!params.get('number_of_rooms')} type='radio' onChange={AllChambre} />
              <span>Tout</span>
            </label>
            {NumberChambre.map(value => (
              <label key={value}>
                <input
                  type='radio'
                  value={value}
                  checked={params.get('number_of_rooms') === value}
                  onChange={handleChambre}
                />
                <span>
                  {value}
                  {value >= 8 && '+'}
                </span>
              </label>
            ))}
          </div>
        </div>
        <Bed />
        {voirPlus && <Bathroom />}
        <div>
          <button onClick={() => setVoirPlus(!voirPlus)}>
            {voirPlus ? 'Afficher moins' : 'Afficher plus'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Room
