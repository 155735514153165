const Options = () => {
  const Pays = [
    // { currency: 'AED', locale: 'ar-AE' },
    // { currency: 'ARS', locale: 'es-AR' },
    // { currency: 'AUD', locale: 'en-AU' },
    // { currency: 'BGN', locale: 'bg-BG' },
    // { currency: 'BRL', locale: 'pt-BR' },
    // { currency: 'CAD', locale: 'en-CA' },
    // { currency: 'CHF', locale: 'de-CH' },
    // { currency: 'CLP', locale: 'es-CL' },
    // { currency: 'CNY', locale: 'zh-CN' },
    // { currency: 'COP', locale: 'es-CO' },
    // { currency: 'CRC', locale: 'es-CR' },
    // { currency: 'CZK', locale: 'cs-CZ' },
    // { currency: 'DKK', locale: 'da-DK' },
    // { currency: 'EUR', locale: 'en-IE' }, // L'exemple suppose en-IE, mais cela pourrait être différent en fonction du pays spécifique de l'UE
    // { currency: 'GBP', locale: 'en-GB' },
    // { currency: 'HKD', locale: 'zh-HK' },
    // { currency: 'HRK', locale: 'hr-HR' },
    // { currency: 'HUF', locale: 'hu-HU' },
    // { currency: 'IDR', locale: 'id-ID' },
    // { currency: 'ILS', locale: 'he-IL' },
    // { currency: 'JPY', locale: 'ja-JP' },
    // { currency: 'KRW', locale: 'ko-KR' },
    // { currency: 'MAD', locale: 'ar-MA' },
    // { currency: 'MXN', locale: 'es-MX' },
    // { currency: 'MYR', locale: 'ms-MY' },
    // { currency: 'NOK', locale: 'nb-NO' },
    // { currency: 'NZD', locale: 'en-NZ' },
    // { currency: 'PEN', locale: 'es-PE' },
    // { currency: 'PHP', locale: 'fil-PH' },
    // { currency: 'PLN', locale: 'pl-PL' },
    // { currency: 'RON', locale: 'ro-RO' },
    // { currency: 'RUB', locale: 'ru-RU' },
    // { currency: 'SAR', locale: 'ar-SA' },
    // { currency: 'SEK', locale: 'sv-SE' },
    // { currency: 'SGD', locale: 'en-SG' },
    // { currency: 'THB', locale: 'th-TH' },
    // { currency: 'TRY', locale: 'tr-TR' },
    // { currency: 'TWD', locale: 'zh-TW' },
    // { currency: 'UAH', locale: 'uk-UA' },
    { currency: 'USD', locale: 'en-US' },
    // { currency: 'UYU', locale: 'es-UY' },
    // { currency: 'VND', locale: 'vi-VN' },
    // { currency: 'ZAR', locale: 'en-ZA' },
  ]

  return (
    <>
      {Pays.map(item => (
        <option value={item.currency} key={item.currency}>
          {item.currency}{' '}
        </option>
      ))}
    </>
  )
}

export default Options
