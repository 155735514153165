import styles from '../../../css/modehotes/calendar/loading.module.css'

const Loading = () => {
  const annonce = [1, 2, 3, 4]
  return (
    <>
      <div className={styles.content_list}>
        {annonce.map(e => (
          <div key={e} className={styles.block}>
            <div>
              <div className={`${styles.card} ${styles.skeleton}`}></div>
            </div>
            <div className={styles.content}>
              <h3 className={styles.skeleton}>{''}</h3>
              <p className={styles.skeleton}>{''}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default Loading
