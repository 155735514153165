import styles from '../../../css/navigations/sideBar.module.css'
import { Link } from 'react-router-dom'
import Tooltip from './Tooltip'

const Reservation = ({ user, path }) => {
  if (user === null) return null

  return (
    <Link to='/reservations/upcoming' className={path === 'reservations' ? styles.active : null}>
      <div className={styles.icon_content}>
        <svg
          className={styles.reservation}
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'
          role='presentation'
          focusable='false'
        >
          <path d='M19,4H17.9A5.009,5.009,0,0,0,13,0H11A5.009,5.009,0,0,0,6.1,4H5A5.006,5.006,0,0,0,0,9V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V9A5.006,5.006,0,0,0,19,4ZM11,2h2a3,3,0,0,1,2.816,2H8.184A3,3,0,0,1,11,2ZM5,6H19a3,3,0,0,1,3,3v3H2V9A3,3,0,0,1,5,6ZM19,22H5a3,3,0,0,1-3-3V14h9v1a1,1,0,0,0,2,0V14h9v5A3,3,0,0,1,19,22Z'></path>
        </svg>
      </div>
      <p>Voyages</p>
      <Tooltip title='Voyages' />
    </Link>
  )
}

export default Reservation
