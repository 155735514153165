import { Link } from 'react-router-dom'
import styles from '../../css/errors/errorServer.module.css'

const ErrorServer = () => {
  const url = window.location.href
  return (
    <>
      <div className={styles.container}>
        <div className={styles.main}>
          <div className={styles.block}>
            <div>
              <svg
                viewBox='0 0 16 16'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
              >
                <g>
                  <path d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm0 10.2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm.8-6.6H7.2v5.2h1.6z'></path>
                </g>
              </svg>
            </div>
            <div>
              <p>Un problème est survenu. Merci de réessayer.</p>
            </div>
          </div>
          <Link to={`/help/feedback?path=${url}`}>Contactez le service client</Link>
        </div>
        <div className={styles.actions}>
          <Link to='/'>Accueil</Link>
        </div>
      </div>
    </>
  )
}

export default ErrorServer
