import styles from '../../../../css/traveler/travel/cancellation.module.css'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

const TitleHead = ({ reservation }) => {
  const history = useHistory()

  const handleRedirect = () => {
    if (history.length > 1) {
      history.goBack()
    } else {
      const Url = `/reservations/details/${reservation._id}`

      history.push(Url)
    }
  }

  return (
    <div className={styles.header}>
      <div>
        <button onClick={handleRedirect}>
          <svg
            viewBox='0 0 32 32'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            role='presentation'
            focusable='false'
            style={{
              display: 'block',
              height: '16px',
              width: '16px',
              stroke: 'currentcolor',
              strokeWidth: '3',
              fill: 'none',
              overflow: 'visible',
            }}
          >
            <g>
              <path d='m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932'></path>
            </g>
          </svg>
        </button>
      </div>
      <h1>
        Annuler la réservation · {reservation?.hotel?.title} ·{' '}
        <span>
          {moment(reservation.reservation_infos?.checkin).format('ll')} -{' '}
          {moment(reservation.reservation_infos?.checkout).format('ll')}
        </span>
      </h1>
    </div>
  )
}

export default TitleHead
