import { useEffect, useContext, useState } from 'react'
import styles from '../../css/booking/reservation.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import { Helmet } from 'react-helmet-async'
import { useQuery } from 'react-query'
import { User } from '../../context/UserContext'
import axios from 'axios'
import moment from 'moment'
import Loading from '../../components/booking/Loading'
import NavbarResevation from '../../components/booking/NavbarResevation'
import Head from '../../components/booking/block_1/Head'
import CreatePaymentIntent from '../../components/booking/block_1/forms/CreatePaymentIntent'
import DetailPrice from '../../components/booking/block_2/DetailPrice'
import useDisponibilite from '../../hooks/useDisponibilite'
import Connexion from '../../components/booking/block_1/forms/components/Connexion'
import CreateSetupIntent from '../../components/booking/block_1/forms/CreateSetupIntent'
import { confirmBooking } from '../../utils/booking'
import Formulaire from '../../components/booking/block_1/forms/Formulaire'
import DefaultLayout from '../../layouts/DefaultLayout'
import Header from '../../components/booking/Header'
import ChoosePaymentMethod from '../../components/booking/block_1/ChoosePaymentMethod'

let timeOutId

const Reserver = () => {
  const [isLoadingPathChange, setIsLoadingPathChange] = useState(false)
  const [payOnline, setPayOnline] = useState(true)
  const location = useLocation()
  const history = useHistory()
  const id = location.pathname.split('/')[2]
  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  const adulte = Number(Params.get('adults')) || 1
  const enfant = Number(Params.get('children')) || 0
  const bebe = Number(Params.get('infants')) || 0
  const animal = Number(Params.get('pets')) || 0
  const voyageur = parseInt(adulte) + parseInt(enfant)
  const checkin_search = Params.get('checkin')
  const checkout_search = Params.get('checkout')

  const checkin = moment(new Date(checkin_search)).format('ll')
  const checkout = moment(new Date(checkout_search)).format('ll')

  const user = useContext(User)

  const {
    data = null,
    isLoading,
    error,
  } = useQuery(`get-annonce-details-${id}`, () =>
    axios.get(`/hotel_user/reservation/${id}`).then(res => {
      return res.data
    })
  )

  const {
    numberOfTravelerIsInvalid,
    handleVerifDisponibilite,
    startDateInvalid,
    endDateInvalid,
    dateIsinvalid,
    dateIsinvalidBloquer,
  } = useDisponibilite(data)

  const payonLine = data?.regulations?.pay_online
  const payatHost = data?.regulations?.pay_at_host
  const handlePaymentMethod = () => {
    if (payonLine && !payatHost) {
      setPayOnline(true)
    } else if (!payonLine && payatHost) {
      setPayOnline(false)
    } else {
      setPayOnline(true)
    }
  }

  useEffect(() => {
    handlePaymentMethod()
  }, [data])

  useEffect(() => {
    window.scrollTo(0, 0)
    handlePaymentMethod()
    return history.listen(location => {
      setIsLoadingPathChange(true)
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
      timeOutId = setTimeout(() => {
        setIsLoadingPathChange(false)
      }, 1000)
    })
  }, [])

  const handleRedirect = () => {
    if (history.length > 1) {
      history.goBack()
    } else {
      const Url = `/rooms/${id}?adults=${adulte || 2}&children=${enfant || 0}&infants=${
        bebe || 0
      }&pets=${animal || 0}&checkin=${checkin_search}&checkout=${checkout_search}`

      history.push(Url)
    }
  }

  if (error || (!isLoading && dateIsinvalid) || (!isLoading && dateIsinvalidBloquer)) {
    handleRedirect()
  }

  return (
    <>
      <Helmet>
        <title>
          {confirmBooking(data) ? 'Confirmer votre réservation' : 'Demande de réservation'}
        </title>
      </Helmet>

      <NavbarResevation confirmBooking={confirmBooking(data)} handleRedirect={handleRedirect} />

      <DefaultLayout footer={true}>
        <div className={styles.container}>
          <Header
            user={user}
            handleRedirect={handleRedirect}
            confirmBooking={confirmBooking(data)}
          />

          <div className={styles.recap_block}>
            <div className={styles.left}>
              <Head
                bebe={bebe}
                animal={animal}
                hotel={data}
                voyageur={voyageur}
                checkin={checkin}
                checkout={checkout}
              />
              {/*---------formulaire-----*/}
              {isLoading || isLoadingPathChange ? (
                <Loading />
              ) : (
                <div>
                  {user ? (
                    <div>
                      {payonLine && payatHost ? (
                        <ChoosePaymentMethod payOnline={payOnline} setPayOnline={setPayOnline} />
                      ) : null}
                      {payOnline ? (
                        <div>
                          {confirmBooking(data) ? (
                            <CreatePaymentIntent
                              hotel={data}
                              payOnline={payOnline}
                              numberOfTravelerIsInvalid={numberOfTravelerIsInvalid}
                              handleVerifDisponibilite={handleVerifDisponibilite}
                              startDateInvalid={startDateInvalid}
                              endDateInvalid={endDateInvalid}
                            />
                          ) : (
                            <CreateSetupIntent
                              hotel={data}
                              payOnline={payOnline}
                              numberOfTravelerIsInvalid={numberOfTravelerIsInvalid}
                              handleVerifDisponibilite={handleVerifDisponibilite}
                              startDateInvalid={startDateInvalid}
                              endDateInvalid={endDateInvalid}
                            />
                          )}
                        </div>
                      ) : (
                        <div>
                          <Formulaire
                            hotel={data}
                            payOnline={payOnline}
                            numberOfTravelerIsInvalid={numberOfTravelerIsInvalid}
                            handleVerifDisponibilite={handleVerifDisponibilite}
                            startDateInvalid={startDateInvalid}
                            endDateInvalid={endDateInvalid}
                            confirmBooking={confirmBooking(data)}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <Connexion />
                  )}
                </div>
              )}
            </div>
            {/*---------block recap et image-----*/}
            <DetailPrice hotel={data} />
          </div>
        </div>
      </DefaultLayout>
    </>
  )
}

export default Reserver
