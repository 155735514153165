import { useEffect, useState, useContext } from 'react'
import { User } from '../../context/UserContext'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import styles from '../../css/becomeHostes/celebration.module.css'
import axios from 'axios'
import ErrorServer from '../../components/become_host/errorServer/ErrorServer'
import Confetti from '../../components/ui/Confetti'
import { SATATUS_ANNONCE } from '../../enums/logement/status.enums'
import { Helmet } from 'react-helmet-async'

const Celebration = () => {
  const [serverError, setServerError] = useState(false)

  const user = useContext(User)
  const history = useHistory()
  const location = useLocation()
  const id = location.pathname.split('/')[3]

  useEffect(() => {
    axios
      .get(`/hotel/oneAnnonce/${id}`)
      .then(res => {
        if (res.data.status !== SATATUS_ANNONCE.SAVE) {
          history.push('/hosting')
        }
      })
      .catch(() => {
        setServerError(true)
      })
  }, [history, id])

  if (serverError) {
    return <ErrorServer />
  }

  if (user === null) {
    history.push('/host/homes')
  }

  return (
    <>
      <Helmet>
        <title>Bienvenue parmi les hôtes - Gowifmi</title>
      </Helmet>
      <div className={styles.celebrate}>
        <div className={styles.block}>
          <h1>Bienvenue, {user?.first_name} !</h1>
          <p>
            Les hôtes sont au coeur de toutes nos activités, et nous avons hâte de vous voir
            profiter des avantages liés à l'accueil de voyageurs.
          </p>
          <button className={styles.publish} onClick={() => history.push(`/verify-listing/${id}`)}>
            Suivant
          </button>
        </div>
      </div>
      <Confetti />
    </>
  )
}

export default Celebration
