import { Helmet } from 'react-helmet-async'
import styles from '../../css/about_us/about.module.css'
import DefaultLayout from '../../layouts/DefaultLayout'

function CookiePolicy() {
  return (
    <DefaultLayout footer={true}>
      <Helmet>
        <title>Politique de Gowifmi en matière de cookies</title>
      </Helmet>

      <div className={styles.main}>
        <div className={styles.pourquoi_text}>
          <h2>Politique de Gowifmi en matière de cookies</h2>
          <div>
            <p>
              Gowifmi utilise des cookies, des identificateurs de services mobiles, des URL de
              suivi, des journaux de données, et des technologies analogues afin de participer à la
              fourniture, à la protection et à l'amélioration de la Plateforme Gowifmi. Cette
              Politique relative aux cookies s'ajoute à la{' '}
              <a href='/privacy-policy'>Politique de confidentialité</a> de Gowifmi et détaille les
              raisons et les méthodes de notre utilisation de ces technologies, tout en exposant les
              options dont vous disposez.
            </p>
          </div>

          <h3>Pourquoi Gowifmi utilise ces technologies</h3>
          <div className={styles.list}>
            <p>Nous utilisons ces technologies pour diverses raisons, notamment pour :</p>
            <ul>
              <li>
                Vous permettre d'accéder à et d'utiliser la Plateforme Gowifmi et les Services de
                paiement.
              </li>
              <li>
                Faciliter et simplifier le fonctionnement de la Plateforme Gowifmi ainsi que votre
                accès à celle-ci.
              </li>
              <li>
                Mieux comprendre comment vous naviguez et interagissez avec la Plateforme Gowifmi
                afin de l'améliorer.
              </li>
              <li>
                Vous présentez des publicités personnalisées sur la Plateforme Gowifmi, par e-mail
                et sur des sites web tiers.
              </li>
              <li>
                Vous fournir des contenus, tels que des publicités, qui sont plus pertinents pour
                vous.
              </li>
              <li>
                Surveiller et analyser les performances, le fonctionnement et l'efficacité de la
                Plateforme Gowifmi et des publicités.
              </li>
              <li>
                Faire respecter les accords juridiques régissant l'utilisation de la Plateforme
                Gowifmi.
              </li>
              <li>
                Détecter et prévenir les fraudes, assurer la confiance et la sécurité, ainsi que
                mener les enquêtes nécessaires.
              </li>
              <li>
                Répondre aux besoins de notre service d'assistance aux utilisateurs, de nos
                analyses, de nos études, de notre développement de produit et pour assurer le
                respect de la législation.
              </li>
            </ul>
          </div>
          <div>
            <h3>Cookies</h3>
            <p>
              Lorsque vous accédez à la Plateforme Gowifmi, nous avons la possibilité d'installer
              des cookies sur votre appareil. Les cookies sont de petits fichiers texte que les
              sites web envoient à votre ordinateur ou autre appareil connecté à Internet pour
              identifier de manière unique votre navigateur ou stocker des informations et des
              paramètres spécifiques dans votre navigateur. Ces cookies nous permettent de vous
              reconnaître lors de vos visites ultérieures sur notre site, contribuant ainsi à offrir
              une expérience personnalisée et à détecter certains types de fraudes. Dans de nombreux
              cas, les informations collectées à l'aide des cookies et d'autres outils sont
              utilisées de manière anonyme, sans faire référence à des données personnelles
              identifiables. Par exemple, nous pouvons utiliser ces informations pour analyser les
              tendances de trafic sur notre site web et améliorer l'expérience en ligne que nous
              proposons. Il est à noter que dans certains cas, nous pourrions associer les données
              obtenues grâce aux cookies et à d'autres technologies à vos informations personnelles.
              Nos partenaires commerciaux ont également la possibilité d'utiliser ces technologies
              de suivi sur la Plateforme Gowifmi ou de recourir à des services tiers pour suivre
              votre comportement en notre nom.
            </p>
            <p>
              Deux types de cookies sont utilisés sur la Plateforme Gowifmi : (1) les « cookies de
              session » et (2) les « cookies permanents ». Les cookies de session ont généralement
              une durée de vie limitée et expirent lorsque vous fermez votre navigateur, tandis que
              les cookies permanents demeurent sur votre appareil même après la fermeture du
              navigateur et peuvent être réutilisés lors de vos futures visites sur la Plateforme
              Gowifmi.
            </p>
            <p>
              Dans de nombreux cas, vous avez la possibilité de gérer vos préférences en matière de
              cookies et de refuser l'utilisation de cookies ainsi que d'autres technologies de
              collecte de données en ajustant les paramètres de votre navigateur. Étant donné que
              chaque navigateur est unique, vous pouvez consulter la section « Confidentialité » de
              votre navigateur pour comprendre comment configurer vos préférences en matière de
              cookies et explorer les autres options de confidentialité disponibles. Il est
              important de noter que si vous choisissez de supprimer ou de refuser les cookies, ou
              de vider le stockage local, cela pourrait avoir un impact sur les fonctionnalités, la
              disponibilité et le bon fonctionnement de la Plateforme Gowifmi.
            </p>
          </div>
          <div>
            <h3>Pixels espions, balises web et URL de suivi</h3>
            <p>
              Les pixels espions, les balises web et les URL de suivi sont de petites images
              graphiques et/ou de petits blocs de code intégrés dans les pages web, les annonces ou
              nos e-mails. Ils servent à vérifier si vous avez effectué une action spécifique.
              Lorsque vous visualisez ces pages ou ouvrez un e-mail, ils nous envoient un signal.
              Ces outils nous offrent la possibilité de mesurer la réponse à nos communications et
              d'améliorer nos pages web et nos promotions.
            </p>
          </div>
          <div>
            <h3>Journaux de serveur et autres technologies</h3>
            <p>
              Nous collectons diverses informations à partir des journaux de serveur et d'autres
              technologies lors de votre utilisation de la Plateforme Gowifmi. Cela inclut des
              détails sur l'appareil que vous utilisez pour accéder à la plateforme, tels que le
              type d'appareil, le système d'exploitation, le navigateur, le domaine, et d'autres
              paramètres système. De plus, nous enregistrons des informations sur la langue utilisée
              ainsi que sur le pays et le fuseau horaire où se trouve votre appareil. Les journaux
              de serveur consignent également l'adresse IP de l'appareil que vous utilisez pour vous
              connecter à Internet, une identification unique nécessaire pour l'identification et la
              communication entre les appareils en ligne. Nous pouvons également recueillir des
              données sur le site web que vous avez consulté avant d'arriver sur la Plateforme
              Gowifmi, ainsi que sur le site web auquel vous avez accédé après avoir quitté la
              plateforme.
            </p>
            <p>
              En outre, des informations peuvent être collectées sur votre utilisation spécifique de
              la Plateforme Gowifmi, notamment lorsque nous fournissons des outils d'accessibilité.
              Ces outils contribuent à améliorer l'expérience utilisateur et les services que nous
              proposons.
            </p>
          </div>
          <div>
            <h3>Informations sur les appareils</h3>
            <p>
              Nous avons la capacité d'utiliser des informations relatives à un appareil afin
              d'identifier les utilisateurs. Par exemple, nous pouvons utiliser votre adresse IP,
              les données de votre navigateur, ou d'autres informations fournies par votre
              navigateur ou votre appareil pour identifier spécifiquement l'appareil utilisé pour
              accéder à notre Plateforme. De plus, nous sommes en mesure d'utiliser ces techniques
              liées aux appareils pour établir des liens entre vous et différents appareils que vous
              pourriez utiliser pour accéder à notre contenu, ce qui vise à renforcer la sécurité
              contre les fraudes et à améliorer la précision de ciblage publicitaire.
            </p>
          </div>
          <div>
            <h3>Tiers</h3>
            <p>
              Gowifmi autorise des tiers à recueillir les informations énoncées ci-dessus à travers
              notre service et à partager ces données avec des tiers à des fins commerciales,
              conformément à notre Politique de confidentialité. Cela inclut, sans s'y limiter, la
              diffusion de publicités sur notre service et d'autres plateformes en fonction des
              activités en ligne des utilisateurs au fil du temps, sur divers sites, services et
              appareils.
            </p>
            <p>
              Certains tiers, dont Facebook, peuvent mettre en place des technologies telles que des
              pixels et des SDK sur la Plateforme Gowifmi. Ces technologies sont utilisées pour (1)
              analyser votre utilisation de la Plateforme Gowifmi, y compris le suivi des services
              tiers par lesquels vous accédez à la Plateforme, (2) promouvoir et annoncer les
              services Gowifmi sur la Plateforme et sur des sites web tiers, (3) contribuer à la
              détection et à la prévention de fraudes ou à l'évaluation des risques, ou (4)
              recueillir des informations sur vos activités sur la Plateforme Gowifmi ainsi que sur
              d'autres sites, y compris les publicités sur lesquelles vous pourriez avoir cliqué.
            </p>
            <p>
              Par exemple, dans le but de mieux comprendre l'utilisation de la Plateforme Gowifmi,
              nous collaborons avec plusieurs partenaires analytiques, dont Google Analytics. Si
              vous souhaitez empêcher Google Analytics d'utiliser vos informations à des fins
              d'analyse, vous pouvez installer le module complémentaire de navigateur pour la
              désactivation de Google Analytics disponible ici. Dans certains cas, des cookies
              peuvent être placés lorsque certains critères sont remplis, tels que la connexion au
              service tiers sur le même navigateur.
            </p>
          </div>
          <div>
            <h3>Plug-ins tiers de réseaux sociaux</h3>
            <p>
              La Plateforme Gowifmi peut utiliser des plug-ins de réseaux sociaux fournis et gérés
              par des tiers, tels que le bouton « J'aime » de Facebook. En conséquence, le tiers en
              question pourrait accéder aux informations que vous consultez sur une partie
              spécifique de la Plateforme Gowifmi. Si vous n'êtes pas connecté à votre compte
              associé au tiers, celui-ci ne pourra pas vous identifier. Cependant, si vous êtes
              connecté à votre compte auprès du tiers, ce dernier pourrait associer les informations
              ou actions liées à vos interactions avec la Plateforme Gowifmi à votre compte tiers.
              Nous vous recommandons de consulter la politique de confidentialité du tiers concerné
              pour comprendre ses pratiques en matière de données.
            </p>
          </div>
          <div>
            <h3>Vos choix</h3>
            <p>
              La plupart des navigateurs acceptent automatiquement les cookies, mais vous avez la
              possibilité de personnaliser les paramètres de votre navigateur pour refuser les
              cookies en accédant à la section d'aide de la barre d'outils. Bien que vous puissiez
              désactiver les cookies dans les paramètres de votre navigateur, il est essentiel de
              noter que la Plateforme Gowifmi ne modifie pas ses pratiques en réponse à un signal «
              Ne pas suivre » présent dans l'en-tête HTTP de votre navigateur ou de votre
              application mobile. Cette décision découle du manque de standardisation concernant
              l'interprétation de ce signal.
            </p>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}
export default CookiePolicy
