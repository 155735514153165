import styles from '../../../../css/modehotes/navbar/dropdown_link.module.css'
import { Link } from 'react-router-dom'
import SvgIconRightDirector from '../../../svgs/SvgIconRightDirector'

const Buil = () => {
  return (
    <Link to='/hosting/invoice/all' className={styles.block_link}>
      <div className={styles.left_block}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 32 32'
          aria-hidden='true'
          role='presentation'
          focusable='false'
          fill='currentcolor'
        >
          <path d='M28.41 10 20 1.59A2.01 2.01 0 0 0 18.59 1H8a5 5 0 0 0-5 5v20a5 5 0 0 0 5 5h16a5 5 0 0 0 5-5V11.41a1.99 1.99 0 0 0-.59-1.41ZM19 3.41 26.59 11H22a3 3 0 0 1-3-3ZM27 26a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h9v5a5 5 0 0 0 5 5h5Z'></path>
        </svg>
        <h3>Factures</h3>
      </div>
      <div className={styles.rigth_block}>
        <SvgIconRightDirector />
      </div>
    </Link>
  )
}

export default Buil
