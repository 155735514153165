const CONNECTION_TYPE = {
  CONNECTION: 'connection',
  ENTER_PASSWORD: 'password',
  REGISTRATION: 'registration',
  FORGOT_PASSWORD: 'forgot_password',
  PROFIL_CATEGORY: 'profil_category',
  PROFIL_LOCATION: 'profil_location',
}

export { CONNECTION_TYPE }
