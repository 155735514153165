import styles from '../../../css/about_us/about.module.css'
import { hostServiceFee } from '../../../config/config'
import DefaultLayout from '../../../layouts/DefaultLayout'
import { Helmet } from 'react-helmet-async'

function ServiceFeeHost() {
  return (
    <DefaultLayout footer={true}>
      <Helmet>
        <title>Combien Gowifmi facture-t-il aux hôtes ?</title>
      </Helmet>
      <div className={styles.main}>
        <div className={styles.pourquoi_text}>
          <h1>Les frais de service </h1>

          <h2>Combien Gowifmi facture-t-il aux hôtes ?</h2>
          <div>
            <p>
              Beaucoup de personnes considèrent l'accueil de voyageurs comme une opportunité de
              gagner de l'argent tout en bénéficiant d'échanges enrichissants avec des voyageurs du
              monde entier. Cependant, il est légitime de se demander quelles sont les frais
              appliqués par Gowifmi et comment cela peut impacter les hôtes et les voyageurs.
            </p>
            <p>
              En comprenant précisément en quoi consistent les frais de service de Gowifmi et
              pourquoi ils existent, vous pouvez définir une stratégie de tarification qui a du sens
              pour vous et votre logement.
            </p>
          </div>

          <h3>Combien Gowifmi facture-t-il ?</h3>
          <div>
            <p>
              La majorité des hôtes sont tenus de payer des frais de service fixes, équivalant à{' '}
              {hostServiceFee} du sous-total de la réservation. Le sous-total est calculé en prenant
              en compte votre tarif par nuit, ainsi que tous les frais supplémentaires optionnels
              que vous facturez aux voyageurs, tels que les frais de ménage, mais n'inclut pas les
              frais ou les taxes Gowifmi. De leur côté, les voyageurs doivent généralement
              s'acquitter de frais de service qui représentent environ 14 % du sous-total de la
              réservation.
            </p>
            <p>
              Par conséquent, si vous facturez 100 $ US pour un séjour de 3 nuits, plus 60 $ US pour
              les frais de ménage, le sous-total de votre réservation est de 360 $ US. Les frais de
              service hôte, qui représentent généralement {hostServiceFee} du sous-total de votre
              réservation (10,80 $ US), sont déduits de vos revenus, et des frais de service de 14 %
              (50,40 $ US) sont facturés aux voyageurs et inclus dans le prix total qu'ils paient.
              Dans cet exemple
            </p>
            <ul>
              <li>Vous gagneriez 349,20 $ US.</li>
              <li>Votre voyageur paierait 410,40 $ US.</li>
            </ul>
          </div>
          <h3>Pourquoi Gowifmi facture-t-il des frais de service ?</h3>
          <div>
            <p>
              Les frais que nous appliquons jouent un rôle essentiel dans le maintien de nos
              activités et la prise en charge des coûts liés aux produits et services qui facilitent
              le partage de votre logement, notamment
            </p>
            <ul>
              <li>Une assistance utilisateurs disponible 24h/24</li>
              <li>
                Des activités marketing menées auprès des voyageurs par l'intermédiaire de Google,
                des réseaux sociaux et plus encore
              </li>
              <li>Une protection pour vous et votre logement</li>
              <li>Des ressources pédagogiques pour les hôtes</li>
            </ul>
          </div>
          <h2>Points à retenir</h2>
          <div>
            <ul>
              <li>
                La plupart des hôtes paient des frais de service s'élevant à {hostServiceFee} du
                sous-total de la réservation.
              </li>
              <li>
                Les voyageurs paient généralement des frais de service s'élevant à environ 14 % du
                sous-total de la réservation.
              </li>
              <li>
                Ces frais permettent à Gowifmi de venir en aide aux hôtes et de gérer ses
                opérations.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}
export default ServiceFeeHost
