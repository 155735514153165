const Options = () => {
  const Array = [
    { name: 'Aucun', value: '0' },
    { name: 'Bloquer 1 nuit avant et après chaque réservation', value: '1' },
    { name: 'Bloquer 2 nuits avant et après chaque réservation', value: '2' },
  ]
  return (
    <>
      {Array.map(item => (
        <option value={item.value} key={item.value}>
          {item.name}
        </option>
      ))}
    </>
  )
}

export default Options
