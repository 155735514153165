import styles from '../../../css/details/equipement.module.css'
import CheckAmenitieSvg from './components/CheckAmenitieSvg'

const Internet = ({ hotel }) => {
  const { amenities } = hotel

  return (
    <div className={styles.container_equip}>
      <h4>Internet et bureau</h4>
      {amenities?.includes('1') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Wifi</p>
        </div>
      )}
      {amenities?.includes('13') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Espace de travail dédié Dans une pièce avec une porte</p>
        </div>
      )}
      {amenities?.includes('40') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Wi-Fi portable</p>
        </div>
      )}
    </div>
  )
}

export default Internet
