const Category = () => {
  const array = [
    {
      name: 'Auberge de jeunesse',
      value: 'auberge_de_jeunesse',
      descr:
        "Une entreprise du secteur de l'hébergement qui propose des lits en dortoirs partagés et des chambres privées.",
    },
    {
      name: 'Gîte',
      value: 'gite',
      descr:
        'Une entreprise qui propose des séjours près de milieux naturels, notamment en forêt ou en montagne.',
    },
    {
      name: 'Résidence de tourisme',
      value: 'residence_de_tourisme',
      descr:
        'Un appartement avec des services hôteliers proposés par une entreprise de gestion professionnelle.',
    },
    {
      name: 'Kezhan',
      value: 'kezhan',
      descr: 'Un hébergement de caractère local avec des équipements sophistiqués situé en Chine.',
    },
  ]
  return (
    <>
      {array.map(item => (
        <option value={item.value} key={item.value}>
          {item.name}{' '}
        </option>
      ))}
    </>
  )
}

export default Category
