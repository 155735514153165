import styles from '../../../css/navigations/sideBar.module.css'
import { Link } from 'react-router-dom'
import Tooltip from './Tooltip'

const BecomeHost = ({ user, totalLengthHote }) => {
  return (
    <>
      {user?.isHost || user?.profile_categories.length > 0 ? (
        <Link to='/hosting'>
          <div className={styles.icon_content}>
            <svg className={styles.host_mode} aria-label='Mode hote' role='img' viewBox='0 0 24 24'>
              <path
                d='M9.005 16.545a2.997 2.997 0 0 1 2.997-2.997A2.997 2.997 0 0 1 15 16.545V22h7V11.543L12 2 2 11.543V22h7.005Z'
                strokeLinejoin='round'
              ></path>
            </svg>
            {totalLengthHote > 0 && <span className={styles.icon_notif}></span>}
          </div>
          <p>Mode hôte</p>
          <Tooltip title='Mode hôte' />
        </Link>
      ) : (
        <Link to='/host/homes'>
          <div className={styles.icon_content}>
            <svg
              aria-label='Nouvelle publication'
              className={styles.add_home}
              role='img'
              viewBox='0 0 24 24'
            >
              <path
                d='M2 12v3.45c0 2.849.698 4.005 1.606 4.944.94.909 2.098 1.608 4.946 1.608h6.896c2.848 0 4.006-.7 4.946-1.608C21.302 19.455 22 18.3 22 15.45V8.552c0-2.849-.698-4.006-1.606-4.945C19.454 2.7 18.296 2 15.448 2H8.552c-2.848 0-4.006.699-4.946 1.607C2.698 4.547 2 5.703 2 8.552Z'
                fill='none'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
              ></path>
              <line
                fill='none'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                x1='6.545'
                x2='17.455'
                y1='12.001'
                y2='12.001'
              ></line>
              <line
                fill='none'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                x1='12.003'
                x2='12.003'
                y1='6.545'
                y2='17.455'
              ></line>
            </svg>
          </div>
          <p>Ajouter un logement</p>
          <Tooltip title='Ajouter un logement' />
        </Link>
      )}
    </>
  )
}

export default BecomeHost
