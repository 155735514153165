import styles from '../../../../css/modehotes/annonce/listings/loading.module.css'

const Loading = () => {
  const annonce = [1, 2, 3, 4, 5]
  return (
    <div className={styles.container_arry}>
      <table className={styles.customers}>
        <thead>
          <tr>
            <th className={styles.image}>
              <p></p>
            </th>
            <th>
              <p></p>
            </th>
            <th>
              <p></p>
            </th>
            <th>
              <p></p>
            </th>
            <th>
              <p></p>
            </th>
            <th>
              <p></p>
            </th>
            <th>
              <p></p>
            </th>
            <th>
              <p></p>
            </th>
            <th>
              <p></p>
            </th>
          </tr>
        </thead>
        <tbody>
          {annonce.map((e, k) => (
            <tr key={k}>
              <td className={styles.image}>
                <div className={styles.card}></div>
                <p></p>
              </td>
              <td className={styles.rows}>
                <p className={styles.statut}></p>
              </td>
              <td className={styles.rows}>
                <p className={styles.todo}></p>
              </td>
              <td className={styles.rows}>
                <p></p>
              </td>
              <td className={styles.rows}>
                <p></p>
              </td>
              <td className={styles.rows}>
                <p></p>
              </td>
              <td className={styles.rows}>
                <p></p>
              </td>
              <td className={styles.rows}>
                <p></p>
              </td>
              <td className={styles.rows}>
                <p></p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Loading
