export const DecrementCounter = () => {
  return (
    <svg
      viewBox='0 0 32 32'
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='presentation'
      focusable='false'
      style={{
        display: 'block',
        fill: 'none',
        height: '12px',
        width: '12px',
        stroke: 'currentcolor',
        strokeWidth: '5.33333',
        overflow: 'visible',
      }}
    >
      <path d='m2 16h28'></path>
    </svg>
  )
}

export const IncrementCounter = () => {
  return (
    <svg
      viewBox='0 0 12 12'
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='presentation'
      focusable='false'
      style={{ display: 'block', height: '12px', width: '12px', fill: 'currentcolor' }}
    >
      <path d='m6.75.75v4.5h4.5v1.5h-4.5v4.5h-1.5v-4.5h-4.5v-1.5h4.5v-4.5z'></path>
    </svg>
  )
}
