import { useState, useRef } from 'react'
import styles from '../../../css/details/photoDetailMobile.module.css'
import { useHistory } from 'react-router-dom'
import Favoritedetail from './Favoritedetail'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Navigation, Pagination, Scrollbar } from 'swiper/modules'
import ShareHotel from '../ShareHotel'

const PhotoMobile = ({ getAllFavoris, hotel, handleOpenModal }) => {
  const [imgIndex, setImgIndex] = useState(1)
  const history = useHistory()

  const modalValue = 'CHAR_HOME_MOBILE'
  const partage = useRef()
  const ModalPartage = () => {
    partage.current.OpenModal()
  }

  const handleRedirect = () => {
    if (history.length > 1) {
      history.goBack()
    } else {
      const Url = `/`
      history.push(Url)
    }
  }

  return (
    <>
      <div className={styles.detail_images}>
        <div className={styles.buttonms_mobile}>
          <button onClick={handleRedirect}>
            <svg className={styles.go_back} viewBox='0 0 24 24' aria-hidden='true'>
              <g>
                <path d='M7.414 13l5.043 5.04-1.414 1.42L3.586 12l7.457-7.46 1.414 1.42L7.414 11H21v2H7.414z'></path>
              </g>
            </svg>
          </button>
          <div className={styles.bottoms_droit}>
            <button onClick={ModalPartage}>
              <svg
                className={styles.share_hotel}
                viewBox='0 0 32 32'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
              >
                <g fill='none'>
                  <path d='M27 18v9a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-9'></path>
                  <path d='M16 3v23V3z'></path>
                  <path d='M6 13l9.293-9.293a1 1 0 0 1 1.414 0L26 13'></path>
                </g>
              </svg>
            </button>
            <Favoritedetail getAllFavoris={getAllFavoris} annonce={hotel} />
          </div>
        </div>
        <div className={styles.image_slide}>
          <Swiper
            modules={[Navigation, Pagination, Scrollbar]}
            spaceBetween={0}
            className={styles.swiper_1}
            onSlideChange={swiper => {
              setImgIndex(swiper.activeIndex + 1)
            }}
          >
            {hotel?.images?.map((img, index) => (
              <SwiperSlide key={index} className={styles.swiper_image_1}>
                <img src={img.url} onClick={handleOpenModal} alt='photos liste' />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <p className={styles.count} onClick={handleOpenModal}>
          {imgIndex} / {hotel.images?.length}
        </p>
      </div>
      {/*-----------modal de partage---------*/}
      <ShareHotel hotel={hotel} ref={partage} modalValue={modalValue} />
    </>
  )
}

export default PhotoMobile
