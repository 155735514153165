import { Helmet } from 'react-helmet-async'
import styles from '../../css/about_us/about.module.css'
import DefaultLayout from '../../layouts/DefaultLayout'

function VerifyIdentityPolicy() {
  return (
    <DefaultLayout footer={true}>
      <Helmet>
        <title>Vérification de votre identité | Gowifmi</title>
      </Helmet>
      <div className={styles.main}>
        <div className={styles.pourquoi_text}>
          <h1>Vérification de votre identité</h1>

          <div>
            <p>
              Gowifmi s'est fixé comme mission de rendre sa communauté aussi sûre que possible pour
              tout le monde. Afin de mettre en œuvre cette mission, nous exigeons des identités
              vérifiées, de confirmer votre nom légal ou et d'ajouter votre adresse lorsque vous
              réservez un séjour ou avant de devenir hôte.
            </p>
            <p>Pour plus d'informations, consultez notre politique de confidentialité.</p>
            <p>Dans cet article</p>
            <ol>
              <li>
                <a href='#comment_marche'>Comment ça marche</a>
              </li>
              <li>
                <a href='#confirmez_votre_identité'>Confirmez votre identité</a>
              </li>
              <li>
                <a href='#donner_hote'>Données communiquées à votre hôte</a>
              </li>
              <li>
                <a href='#stockage'>
                  Stockage et suppression de la photo de votre pièce d'identité
                </a>
              </li>
              <li>
                <a href='#delai'>Délai nécessaire à la vérification</a>
              </li>
            </ol>
          </div>

          <h3 id='comment_marche'>Comment ça marche</h3>
          <div>
            <p>
              Les hôtes peuvent demander à leurs voyageurs de fournir une pièce d'identité avant de
              pouvoir réserver leur logement. Nous ne divulguerons jamais votre pièce d'identité à
              d'autres utilisateurs de Gowifmi.
            </p>
          </div>

          <h3 id='confirmez_votre_identité'>Confirmez votre identité</h3>
          <div>
            <p>
              Lorsque nous vous demanderons de confirmer votre identité, vous devez fournir soit
              votre nom légal et votre adresse, soit une pièce d'identité avec photo. Vous pouvez
              utiliser un permis de conduire, un passeport, un certificat de naissance ou un visa.
              Vous avez la possibilité de quitter et de revenir plus tard pour terminer la procédure
              en reprenant là où vous en étiez.
            </p>
            <p>
              Parfois, nous vous demandons de prendre une nouvelle photo de vous-même. Il ne s'agit
              pas d'une photo de profil que nous pourrions également vous demander d'ajouter.
            </p>
          </div>

          <h3 id='donner_hote'>Données communiquées à votre hôte</h3>
          <div>
            <p>
              Gowifmi ne partage pas votre pièce d'identité à d'autres utilisateurs de la
              plateforme. Nous ne fournissons pas aux hôtes votre pièce d'identité, la photo que
              vous avez prise de vous pour la vérification de votre pièce d'identité, ni votre
              d'adresse.
            </p>
            <p>En l'occurrence, nous transmettons :</p>
            <ul>
              <li>Le prénom figurant sur votre pièce d'identité</li>
              <li>Le fait que vous ayez plus ou moins de 25 ans</li>
              <li>Le fait que votre pièce d'identité ait bien été ajoutée</li>
              <li>Votre photo de profil et le nom figurant sur votre profil</li>
            </ul>
          </div>

          <h3 id='stockage'>Stockage et suppression de la photo de votre pièce d'identité</h3>
          <div>
            <p>
              Le stockage de votre photo d'identité est régi par notre{' '}
              <a target='_blanck' href='/privacy-policy'>
                politique de confidentialité
              </a>
              , qui témoigne de notre engagement à protéger vos informations.
            </p>
            <p>
              Si vous décidez de retirer votre photo de pièce d'identité, nous ne serons pas en
              mesure de vérifier votre identité pour toute réservation future. Cependant, vous
              pouvez demander à ce que votre photo soit supprimée de votre compte 90 jours après la
              fin de la dernière réservation. Pour ce faire, allez dans{' '}
              <a target='_blanck' href='/account-settings/info-personal'>
                Informations personnelles
              </a>{' '}
              à partir des <strong>paramètres du compte</strong>, cliquez sur{' '}
              <strong>Photo d'identité et supprimer</strong>.
            </p>
          </div>

          <h3 id='#delai'>Délai nécessaire à la vérification</h3>
          <div>
            <p>
              Lorsque vous créez votre compte, vous pouvez vérifier l'état de votre vérification
              dans les{' '}
              <a target='_blanck' href='/account-settings'>
                paramètres du compte
              </a>
              . Les vérifications prennent généralement jusqu'à 24 heures mais peuvent être
              effectuées en quelques heures.
            </p>
          </div>

          <h2>Comment envoyer votre pièce d'identité ?</h2>
          <div>
            <p>
              En fournissant vos informations d'identité, vous contribuez à renforcer la confiance
              au sein de la communauté Gowifmi.
            </p>
            <p>
              Lorsque vous confirmez votre identité, nous avons besoin soit d'une photo de votre
              pièce d'identité (permis de conduire, passeport, ou carte d'identité nationale), soit
              de votre nom légal et de votre adresse.
            </p>
            <p>
              Dans le cas où vous voulez revenir plus tard pour terminer la confirmation de votre
              identité, rendez-vous à la section Vérification de l'identité pour reprendre là où
              vous êtes arrêté.
            </p>
            <h3>Vous rencontrez des difficultés ? Essayez ces solutions :</h3>
            <p>
              <strong>Consultez vos messages :</strong> Consultez votre boîte de messagerie, votre
              boîte email ou téléphone vous avez peut-être reçu des informations.
            </p>
            <p>
              <strong>Utilisez des photos de bonne qualité :</strong> assurez-vous que votre visage
              est entièrement exposé et visible (prière d'être dans une pièce bien éclairée). Cadrez
              la photo à l'intérieur des lignes blanches désignées, en gardant l'image centrée dans
              chaque cadre. Vous pouvez vous couvrir la tête pour une raison religieuse, mais
              veillez à ce que vos yeux et votre bouche soient visibles.
            </p>
          </div>

          <h2>Types de pièces d'identité acceptées pour la vérification</h2>
          <div>
            <p>
              Il peut vous être demandé de confirmer votre identité en envoyant une photo de vous et
              de votre pièce d'identité, Pour confirmer que vous êtes bien la personne que vous
              prétendez être. Votre pièce d'identité doit être un document officiel (pas une carte
              de bibliothèque, une carte scolaire ou une carte de sport) et comporter une photo de
              vous. En fonction de votre lieu de résidence et de votre pays d'origine, vous pouvez
              fournir l'une des pièces d'identité suivantes :
            </p>
            <ul>
              <li>
                <strong>Permis de conduire :</strong> Nous vous demandons de soumettre deux photos ;
                le recto et le verso.
              </li>
              <li>
                <strong>Passeport :</strong> Vérifiez-vous que les chiffres au bas de la page où
                votre photo est affichée sont visibles, car ils peuvent parfois être trop petits
                pour être vus lors du défilement.
              </li>
              <li>
                <strong>Carte nationale d'identité</strong>
              </li>
            </ul>
          </div>
          <h2>Que se passe-t-il lorsque votre pièce d'identité est vérifiée ?</h2>
          <div>
            <p>
              Nous voulons nous assurer que les personnes qui utilisent Gowifmi sont bien celles
              qu'elles prétendent être. L'une des façons d'y parvenir est de vérifier l'identité. Il
              peut s'agir d'un permis de conduire, d'un passeport, d'une carte d'identité ou d'un
              visa.
            </p>
            <p>
              Certains hôtes demandent à leurs voyageurs de fournir une pièce d'identité avant de
              pouvoir réserver leur logement. Nous prenons au sérieux le respect de votre vie privée
              et ne partageons jamais votre pièce d'identité à d'autres utilisateurs de Gowifmi.
            </p>
            <h3>Comment les pièces d'identité sont-elles vérifiées ?</h3>
            <p>
              Nous demandons au voyageur ou à l'hôte d'ajouter une pièce d'identité avec photo avant
              de pouvoir effectuer une réservation (pour un voyageur) ou de publier une annonce.
              Cela permet de s'assurer que vous êtes bien la personne que vous prétendez être. Nous
              respectons les règles lors du traitement de votre identité. Par exemple, nous pouvons
              demander au voyageur ou à l'hôte de prendre une photo de lui-même, et nous comparons
              cette photo à sa pièce d'identité.
            </p>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}
export default VerifyIdentityPolicy
