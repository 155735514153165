import { useRef } from 'react'
import ErrorSvg from '../../../../../../components/svgs/ErrorSvg'
import styles from '../../../../../../css/booking/dateInvalid.module.css'
import CalendrierModal from '../../../../verif_availability/CalendarModal'
import VoyageurModal from '../../../../verif_availability/TravelerModal'

const DateInvalid = ({ numberOfTravelerIsInvalid, startDateInvalid, endDateInvalid, hotel }) => {
  const dateRef = useRef()
  const VerifDateFunction = () => {
    dateRef.current.OpenModal()
  }

  const verifVoyageur = useRef()
  const voyageurFunction = () => {
    verifVoyageur.current.OpenModal()
  }

  return (
    <>
      <div className={styles.invalid_date}>
        {startDateInvalid && (
          <div className={styles.block_error}>
            <div>
              <ErrorSvg />
            </div>
            <p>Jour d'arrivée non autorisé</p>
          </div>
        )}
        {endDateInvalid && (
          <div className={styles.block_error}>
            <div>
              <ErrorSvg />
            </div>
            <p> Jour de départ non autorisé</p>
          </div>
        )}
        {numberOfTravelerIsInvalid ? (
          <button type='button' onClick={voyageurFunction}>
            Modifier le nombre de voyageurs
          </button>
        ) : (
          <button type='button' onClick={VerifDateFunction}>
            Modifier les dates
          </button>
        )}
      </div>
      {/*------modif reservations------*/}
      <VoyageurModal ref={verifVoyageur} hotel={hotel} />
      <CalendrierModal ref={dateRef} hotel={hotel} />
    </>
  )
}

export default DateInvalid
