import { useState, memo } from 'react'
import styles from '../../../../css/traveler/inbox/detailMessage.module.css'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import Tooltip from '../../../ui/Tooltip'

const DeleteSvg = () => {
  return (
    <svg
      style={{
        display: 'block',
        height: '16px',
        width: '16px',
      }}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 16'
      aria-hidden='true'
      role='presentation'
      focusable='false'
      fill='currentcolor'
    >
      <path d='M10 .38c.31 0 .57.23.62.53V2.5H14v2h-1V14a1 1 0 0 1-.88 1H4a1 1 0 0 1-1-.88V4.5H2v-2h3.38V1c0-.31.23-.57.53-.62H10zM6.12 4.5H4.88v9h1.25v-9zm2.5 0H7.38v9h1.25v-9zm2.5 0H9.88v9h1.24v-9zM9.38 1.62H6.62v.88h2.75v-.87z'></path>
    </svg>
  )
}

const Archive = ({ conversation }) => {
  const [addToarchive, setAddToarchive] = useState(false)
  const [deleteToarchive, setDeleteToarchive] = useState(false)
  const history = useHistory()

  const ArchiveMessage = () => {
    axios
      .patch(`/message/archiveMessage/${conversation._id}`)
      .then(() => {
        setAddToarchive(true)
        setDeleteToarchive(false)
      })
      .catch(() => {})
    setTimeout(() => {
      setAddToarchive(false)
      history.push('/inbox?filter=all')
    }, 2000)
  }

  const RetirerarchiveMessage = () => {
    axios
      .patch(`/message/retirearchiveMessage/${conversation._id}`)
      .then(() => {
        setDeleteToarchive(true)
        setAddToarchive(false)
      })
      .catch()
    setTimeout(() => {
      setDeleteToarchive(false)
      history.push('/inbox?filter=hidden')
    }, 2000)
  }

  return (
    <>
      <div className={styles.head}>
        <div className={styles.name}>
          <button onClick={() => history.push('/inbox')}>
            <svg
              viewBox='0 0 32 32'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'
              role='presentation'
              focusable='false'
              style={{
                fill: 'none',
                height: '18px',
                width: '18px',
                stroke: 'currentColor',
                strokeWidth: '2',
                overflow: 'visible',
              }}
            >
              <g>
                <path d='m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932'></path>
              </g>
            </svg>
          </button>
          <a href={`/profile/${conversation?.host.id}`} target='_blank' rel='noreferrer'>
            {conversation?.host?.first_name}
          </a>
        </div>
        <div className={styles.buttons}>
          {conversation?.user_archived === false ? (
            <Tooltip title='Archiver'>
              <button onClick={ArchiveMessage} className={styles.delete}>
                <DeleteSvg />
              </button>
            </Tooltip>
          ) : (
            <Tooltip title='Retirer des archives'>
              <button onClick={RetirerarchiveMessage} className={styles.delete}>
                <DeleteSvg />
              </button>
            </Tooltip>
          )}
        </div>
      </div>
      {/*********------modal archive message----- */}
      <div
        className={
          addToarchive ? `${styles.modal_Snackbar} ${styles.active}` : styles.modal_Snackbar
        }
      >
        <DeleteSvg />
        <p>Conversation archivée</p>
      </div>
      <div
        className={
          deleteToarchive ? `${styles.modal_Snackbar} ${styles.active}` : styles.modal_Snackbar
        }
      >
        <DeleteSvg />
        <p>Conversation retirée de l'archive</p>
      </div>
    </>
  )
}

export default memo(Archive)
