import BigErrorSvg from '../svgs/BigErrorSvg'
import styles from '../../css/confirmations/statut.module.css'
import NextAction from './next_action_link/NextAction'

const StatusSetupIntent = ({ setupIntentObject, user, id }) => {
  return (
    <div className={styles.statut}>
      {setupIntentObject?.status === 'succeeded' && (
        <div className={styles.succeeded}>
          <div>
            <i className='far fa-check-circle'></i>
          </div>
          <h2>
            {user && <strong>{user.first_name},</strong>} votre demande de réservation est effectuée
            avec succès
          </h2>
        </div>
      )}
      {setupIntentObject?.status === 'processing' && (
        <div className={styles.processing}>
          <div>
            <h2>
              {user && <strong>{user.first_name},</strong>} le traitement des détails de paiement
              est en cours.
            </h2>
            <p>
              Nous vous informerons par cette adresse e-mail ( {user?.email} ) lorsque le traitement
              sera terminé
            </p>
          </div>
        </div>
      )}
      {setupIntentObject?.status === 'requires_payment_method' && (
        <div className={styles.echec}>
          <div>
            <BigErrorSvg />
          </div>
          <div>
            <h2>
              {user && <strong>{user.first_name},</strong>} échec du traitement des détails de
              paiement.
            </h2>
            <a href={`/rooms/${id}`}>Veuillez essayer un autre mode de paiement</a>
          </div>
        </div>
      )}
      {setupIntentObject?.status === 'requires_action' && (
        <div className={styles.processing}>
          <div>
            <BigErrorSvg />
          </div>
          <div>
            <h2>
              {user && <strong>{user.first_name},</strong>} le traitement des détails de paiement
              requiert une action de votre part pour être finalisé
            </h2>
            <NextAction nextActionObj={setupIntentObject?.next_action} />
          </div>
        </div>
      )}
    </div>
  )
}
export default StatusSetupIntent
