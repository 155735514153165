import styles from '../../../../../css/search_room_result/more_actions/comment.module.css'
import axios from 'axios'
import moment from 'moment'
import { useQuery } from 'react-query'
import LoadingPoint from '../../../../LoadingPoint'
import SvgIconRightDirector from '../../../../svgs/SvgIconRightDirector'

const Comment = ({ getUser }) => {
  const {
    data: annonceCommenter = [],
    isLoading,
    error,
  } = useQuery(`get-hostComment-${getUser?.id}`, () =>
    axios.get(`/hotel_comment/hostComment/${getUser?.id}`).then(res => {
      return res.data
    })
  )

  return (
    <div className={styles.container}>
      {isLoading ? (
        <LoadingPoint />
      ) : (
        <>
          {error && <div>Les annonces n'arrivent pas à charger veuillez reséyer</div>}

          <div>
            <div className={styles.profil_comment}>
              {annonceCommenter.length > 0 ? (
                <h3>
                  {annonceCommenter.length} commentaire{annonceCommenter.length > 1 ? 's' : ''} de
                  la part de voyageurs
                </h3>
              ) : (
                <h3>Aucun commentaire (pour l'instant)</h3>
              )}
              <div className={styles.comment_list}>
                {annonceCommenter.slice(0, 8).map(item => (
                  <div key={item._id}>
                    <div className={styles.profil_block_img}>
                      <a
                        href={`/profile/${item.user_id?.id}`}
                        target='_blank'
                        className={styles.image_block}
                        rel='noreferrer'
                      >
                        {item.user_id?.picture ? (
                          <img src={item.user_id?.picture} alt='' />
                        ) : (
                          <h2>{item.user_id?.first_name.slice(0, 1)}</h2>
                        )}
                      </a>
                      <div className={styles.pro_date}>
                        <h4>
                          {item.user_id?.first_name}, {item.user_id?.city}
                        </h4>
                        <p>Membre depuis {moment(item.user_id?.createdAt).format('LL').slice(2)}</p>
                      </div>
                    </div>
                    <div className={styles.message}>
                      <div className={styles.hotel_img}>
                        <h4>{item.hotel?.title}</h4>
                        <div className={styles.hotel_img_card}>
                          <img src={item.hotel?.images[0].url} alt='couverture' />
                        </div>
                      </div>
                      <p>{item.comment}</p>
                      <h5>{moment(item.createdAt).format('LL').slice(2)}</h5>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {annonceCommenter.length > 8 && (
              <div className={styles.message_block_footer}>
                <a href={`/profile/${getUser?.id}`} target='_blank' rel='noreferrer'>
                  <span> Afficher les {annonceCommenter?.length} commentaires</span>
                  <SvgIconRightDirector />
                </a>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default Comment
