import { useEffect } from 'react'
import styles from '../css/home/search_acceuil.module.css'
import Search from '../components/home/Search'
import About from '../components/home/About'
import Section1 from '../components/home/Section1'
import Section2 from '../components/home/Section2'
import DefaultLayout from '../layouts/DefaultLayout'
import { Helmet } from 'react-helmet-async'

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <DefaultLayout footer={true} mobileBottomNavigate={true}>
      <Helmet>
        <title>Gowifmi - Locations saisonnières, hôtels et locations en résidence</title>
      </Helmet>
      <div className={styles.header}>
        <div className={styles.image_text}>
          <div className={styles.image_text_content}>
            <h1>Réservez des chambres ou tout type d'hébergement</h1>
            <Search />
          </div>
        </div>
      </div>

      <div className=''>
        <Section1 />
        <About />
        <Section2 />
      </div>
    </DefaultLayout>
  )
}
export default Home
