import { useContext } from 'react'
import styles from '../../css/navigations/sideBar.module.css'
import { useLocation } from 'react-router'
import { User, Logout } from '../../context/UserContext'
import useNotification from '../../hooks/useNotification'
import GowifmiLogoSvg from '../svgs/GowifmiLogoSvg'
import GowifmiIconSvg from '../svgs/GowifmiIconSvg'
import { isPagePath } from '../../utils/PagePath'
import SFooter from './sidebar/SFooter'
import Search from './sidebar/Search'
import Message from './sidebar/Message'
import Notification from './sidebar/Notification'
import Reservation from './sidebar/Reservation'
import Wishlist from './sidebar/Wishlist'
import BecomeHost from './sidebar/BecomeHost'
import Account from './sidebar/Account'
import LogoutUser from './sidebar/LogoutUser'

const SideBar = () => {
  const user = useContext(User)
  const logout = useContext(Logout)
  const { arrayNonlueUser, totalLengthHote } = useNotification()
  const location = useLocation()
  const path = location.pathname.split('/')[1]

  return (
    <>
      <div className={isPagePath(path) ? `${styles.container} ${styles.hidded}` : styles.container}>
        <div className={styles.s_head}>
          <div className={styles.logo_block}>
            <a href='/' className={styles.logo}>
              <GowifmiLogoSvg />
            </a>
            <a href='/' className={styles.icon}>
              <GowifmiIconSvg />
            </a>
          </div>
        </div>

        <div className={styles.s_body}>
          <Search path={path} />
          <Message arrayNonlueUser={arrayNonlueUser} path={path} />
          <Notification user={user} path={path} />
          <Reservation user={user} path={path} />
          <Wishlist path={path} />
          <BecomeHost user={user} totalLengthHote={totalLengthHote} />
          <Account user={user} path={path} />
          <LogoutUser user={user} logout={logout} />
        </div>

        <SFooter user={user} path={path} />
      </div>
    </>
  )
}

export default SideBar
