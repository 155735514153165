import { useEffect, useContext, useRef } from 'react'
import styles from '../../../css/traveler/travel/voyage.module.css'
import { useLocation } from 'react-router'
import axios from 'axios'
import Login from '../../../components/login'
import { User } from '../../../context/UserContext'
import LoadingPoint from '../../../components/LoadingPoint'
import ListUpcoming from './upcoming/ListUpcoming'
import DefaultLayout from '../../../layouts/DefaultLayout'
import { useQuery } from 'react-query'
import ErrorServer from '../../errors/ErrorServer'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

const Travel = () => {
  const user = useContext(User)
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  const {
    data: voyage = [],
    isLoading,
    error,
  } = useQuery(`get-user-all_reservation`, () => {
    if (user) {
      return axios.get(`/user_reservations/all_reservation`).then(res => {
        return res.data
      })
    }
  })

  const connexion = useRef()
  const handleConnection = params => {
    connexion.current.OpenModal(params)
  }

  if (error) return <ErrorServer />

  return (
    <DefaultLayout mobileBottomNavigate={true}>
      <Helmet>
        <title>Vos réservations - Gowifmi</title>
      </Helmet>

      {user ? (
        <div>
          {isLoading ? (
            <div className={styles.container_load}>
              <LoadingPoint />
            </div>
          ) : (
            <div className={styles.voyage_container}>
              {voyage.total_data > 0 ? (
                <ListUpcoming voyage={voyage} />
              ) : (
                <>
                  <div className={styles.no_container}>
                    <div className={styles.no_voyage}>
                      <h2>Aucun voyage réservé pour le moment</h2>
                      <p>
                        Il est temps de préparer vos bagages et de commencer à organiser votre
                        prochaine aventure.
                      </p>
                      <Link to='/'>Effectuer une recherche</Link>
                    </div>
                    <div className={styles.centre_aide}>
                      <p>
                        Si vous ne trouvez pas votre réservation ?{' '}
                        <Link to='/help/feedback'>Contactez le service client</Link>
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      ) : (
        <>
          <div className={styles.no_connecte}>
            <h1>Voyages</h1>
            <div className={styles.connexion}>
              <h2>Connectez-vous pour consulter vos réservations</h2>
              <p>
                Lorsque vous souhaiterez commencer à organiser votre prochain voyage, nous serons là
                pour vous aider
              </p>
              <button onClick={handleConnection}>Connectez-vous</button>
            </div>
          </div>
        </>
      )}
      <Login ref={connexion} />
    </DefaultLayout>
  )
}

export default Travel
