import styles from '../../../css/booking/choose_payment_method.module.css'
import RadioInput from '../../ui/RadioInput'

const ChoosePaymentMethod = ({ payOnline, setPayOnline }) => {
  return (
    <div className={styles.container}>
      <h2>Comment souhaitez-vous payer</h2>
      <div className={styles.grid_system}>
        <label htmlFor='pay_online' className={styles.radio_container}>
          <div>
            <h3>Payez sur Gowifmi</h3>
            <p>Payez en toute confiance sur Gowifmi pour une expérience sans souci.</p>
          </div>
          <div>
            <RadioInput
              id='pay_online'
              checked={payOnline === true}
              onChange={() => setPayOnline(true)}
            />
          </div>
        </label>
        <label htmlFor='pay_at_host' className={styles.radio_container}>
          <div>
            <h3>Payez chez l'hôte</h3>
            <p>Optez pour un paiement direct chez l'hôte pour une expérience personnalisée.</p>
          </div>
          <div>
            <RadioInput
              id='pay_at_host'
              checked={payOnline === false}
              onChange={() => setPayOnline(false)}
            />
          </div>
        </label>
      </div>
    </div>
  )
}

export default ChoosePaymentMethod
