import styles from '../../../../../css/modehotes/annonce/details/securiteVoyageur.module.css'
import Checkbox from '../../../../ui/Checkbox'

const Playground = ({ airdeJuex, setAirdeJuex }) => {
  return (
    <div className={styles.checkbox_container}>
      <div>
        <label htmlFor='juex'>Aire de jeux ou structure d'escalade</label>
        <p>
          Les voyageurs (y compris les enfants) disposeront de structures d'escalade ou de terrains
          de jeux. Ex. : une balançoire, un toboggan, une aire de jeux ou des cordes d'escalade.
        </p>
      </div>
      <div>
        <Checkbox
          id='juex'
          value={airdeJuex}
          checked={airdeJuex}
          onChange={() => setAirdeJuex(!airdeJuex)}
        />
      </div>
    </div>
  )
}

export default Playground
