import { useState, useContext } from 'react'
import styles from '../../../../css/logins/reseaux.module.css'
import axios from 'axios'
import { Refresh } from '../../../../context/UserContext'
import { useGoogleLogin } from '@react-oauth/google'
import GoogleIconSvg from '../../../svgs/GoogleIconSvg'
import { useDispatch } from 'react-redux'
import { userExiste } from '../../../../redux/actions'
import { CONNECTION_TYPE } from '../../../../enums/user/connectionScreen'

const GoogleAuth = ({ changeState, setGetUser }) => {
  const [loading, setLoading] = useState(false)
  const refresh = useContext(Refresh)

  const dispatch = useDispatch()

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async ({ code }) => {
      setLoading(true)
      const data = {
        code,
      }
      axios
        .post(`/auth/login-site/googleAuth`, data)
        .then(async res => {
          if (res.data.user.password_submit) {
            dispatch(userExiste(res.data.user))
            changeState(CONNECTION_TYPE.ENTER_PASSWORD)
            return
          } else {
            localStorage.setItem('jwt', res.data.jwt.token)
            axios.defaults.headers['authorization'] = 'Bearer ' + res.data.jwt.token
            await refresh()
            if (res.data.registration) {
              setGetUser(res.data.user)
              changeState(CONNECTION_TYPE.PROFIL_CATEGORY)
              return
            }
            window.location.reload()
          }
        })
        .catch(() => {
          setLoading(false)
          alert('Error server véuillez réessayer ou choisir un autre moyen de connexion')
        })
    },
    onError: errorResponse => console.log(errorResponse),
  })

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className={styles.block}>
          <button type='button' onClick={() => googleLogin()}>
            <GoogleIconSvg />
            <span>Continuer avec Google</span>
            <div></div>
          </button>
        </div>
      )}
    </div>
  )
}

export default GoogleAuth
