import { useRef } from 'react'
import styles from '../../../../../../css/traveler/travel/detailReservation.module.css'
import CommentaireModal from '../../../comments/CommentModal'
import Politique from '../../../comments/Politique'

const CommentHost = ({ id, reservation, refetch }) => {
  const commente = useRef(null)
  const handlecommente = () => {
    commente.current.OpenModal()
  }
  const politique = useRef(null)
  const handlePolitique = () => {
    politique.current.OpenModal(id)
  }
  return (
    <>
      <div className={styles.content}>
        <h3>Qu'avez-vous pensé de la qualité de ce logement</h3>
        <div className={styles.commentes}>
          <button className={styles.open_commente} onClick={handlecommente}>
            Laissez un commentaire
          </button>
          <button className={styles.icon} onClick={handlePolitique}>
            <svg
              viewBox='0 0 24 24'
              role='presentation'
              aria-hidden='true'
              focusable='false'
              style={{
                display: 'block',
                height: '16px',
                width: '16px',
                fill: 'rgb(72, 72, 72)',
              }}
            >
              <path
                d='m12 0c-6.63 0-12 5.37-12 12s5.37 12 12 12 12-5.37 12-12-5.37-12-12-12zm0 23c-6.07 0-11-4.92-11-11s4.93-11 11-11 11 4.93 11 11-4.93 11-11 11zm4.75-14c0 1.8-.82 2.93-2.35 3.89-.23.14-1 .59-1.14.67-.4.25-.51.38-.51.44v2a .75.75 0 0 1 -1.5 0v-2c0-.74.42-1.22 1.22-1.72.17-.11.94-.55 1.14-.67 1.13-.71 1.64-1.41 1.64-2.61a3.25 3.25 0 0 0 -6.5 0 .75.75 0 0 1 -1.5 0 4.75 4.75 0 0 1 9.5 0zm-3.75 10a1 1 0 1 1 -2 0 1 1 0 0 1 2 0z'
                fillRule='evenodd'
              ></path>
            </svg>
          </button>
        </div>
      </div>
      {/* modal commente */}
      <CommentaireModal ref={commente} reservation={reservation} refetch={refetch} />
      <Politique ref={politique} />
    </>
  )
}

export default CommentHost
