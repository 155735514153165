import NavbarHost from '../components/hote_mode/navigation/NavbarHost'
import Footer from '../components/navigations/Footer'

function HostModeLayout({ footer, children }) {
  return (
    <div>
      <NavbarHost />
      {children}
      {footer && <Footer />}
    </div>
  )
}
export default HostModeLayout
