import styles from '../../../../css/modehotes/hosting/header_Hosting.module.css'
import { Link } from 'react-router-dom'
import axios from 'axios'
import LoadingPoint from '../../../LoadingPoint'
import { useQuery } from 'react-query'

const HeaderHosting = () => {
  const { data: annonce = [], isLoading } = useQuery(`hosting-annonce}`, () =>
    axios.get(`/hotel/today`).then(res => {
      return res.data
    })
  )

  return (
    <div>
      <div className={styles.wrapper}>
        {isLoading ? (
          <div>
            <LoadingPoint />
          </div>
        ) : (
          <div className={styles.welcom}>
            <h1>Aujourd'hui</h1>
            {annonce.length > 0 && (
              <Link to='/become-a-host'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 32 32'
                  aria-hidden='true'
                  role='presentation'
                  focusable='false'
                >
                  <g fill='none'>
                    <path d='M6.83 20.55 7 20.3V13a9 9 0 1 1 18 0v7.3l.17.25 2.68 4.02a.92.92 0 0 1-.77 1.43H4.92a.92.92 0 0 1-.77-1.43zm6.18 5.82-.01.83a3 3 0 1 0 6 0l-.01-.77M16 1v3'></path>
                  </g>
                </svg>
                <p>
                  {annonce.length > 1 ? 'Finaliser mes annonces' : 'Finaliser mon annonce'} (
                  {annonce.length})
                </p>
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default HeaderHosting
