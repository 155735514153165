import styles from '../../../../../css/traveler/travel/detailReservation.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import { STATUS_BOOKING } from '../../../../../enums/booking/status.enums'

const Navigation = ({ reservation }) => {
  const location = useLocation()
  const history = useHistory()

  const id = location.pathname.split('/')[3]
  const path = location.pathname.split('/')[4]

  return (
    <div>
      <div className={styles.navigations}>
        <div className={styles.left_block}>
          <button
            type='button'
            onClick={() => history.replace(`/reservations/details/${id}`)}
            className={!path ? styles.active : null}
          >
            La réservation
          </button>
          <button
            type='button'
            onClick={() => history.replace(`/reservations/details/${id}/annonce`)}
            className={path === 'annonce' ? styles.active : null}
          >
            Détails de l'annonce
          </button>
          <button
            type='button'
            onClick={() => history.replace(`/reservations/details/${id}/policies-and-rules`)}
            className={path === 'policies-and-rules' ? styles.active : null}
          >
            Conditions et règlement
          </button>
          {reservation?.status !== STATUS_BOOKING.CANCELLED &&
          reservation?.host_accept_reservation ? (
            <button
              type='button'
              onClick={() => history.replace(`/reservations/details/${id}/info-for-guests`)}
              className={path === 'info-for-guests' ? styles.active : null}
            >
              Autres informations
            </button>
          ) : null}
        </div>
        <div className={styles.image}>
          {reservation?.hotel?.images[0].url && (
            <a href={`/rooms/${reservation?.hotel?._id}`} target='_blank' rel='noreferrer'>
              <img src={reservation?.hotel?.images[0].url} alt='' />
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default Navigation
