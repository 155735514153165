import { useRef, useContext } from 'react'
import styles from '../../../../../css/booking/formulaire.module.css'
import { User } from '../../../../../context/UserContext'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import useCreateReservation from '../../../../../hooks/useCreateReservation'
import ErrorServer from '../components/errors/ErrorServer'
import ConfirmLoad from '../components/ConfirmLoad'
import useUpdateReservation from '../../../../../hooks/useUpdateReservation'
import StripeInfos from '../components/StripeInfos'
import { STATUS_BOOKING } from '../../../../../enums/booking/status.enums'

export default function SetupForm({
  hotel,
  payOnline,
  setupIntent_id,
  setLoading,
  telephone,
  message,
  setMessageError,
  setPhoneError,
  children,
}) {
  const user = useContext(User)

  const stripe = useStripe()
  const elements = useElements()

  const origin = window.location.origin

  const loadingConfirm = useRef()
  const HandleLoading = params => {
    loadingConfirm.current.OpenModal(params)
  }
  const errorServer = useRef()
  const errorHandle = message => {
    errorServer.current.OpenError(message)
  }

  const { createReservation } = useCreateReservation({
    hotel,
    payOnline,
    setupIntent_id,
    HandleLoading,
    errorHandle,
  })
  const { updateReservation } = useUpdateReservation()

  const handleSubmit = async e => {
    e.preventDefault()

    if (!stripe || !elements) {
      return
    }

    const reservationId = await createReservation(
      STATUS_BOOKING.REQUEST,
      telephone,
      message,
      setMessageError,
      setPhoneError,
      setLoading
    )

    if (reservationId) {
      setLoading(true)
      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${origin}/book/confirmation-setup/${reservationId}`,
        },
      })
      if (error.type === 'card_error' || error.type === 'validation_error') {
        HandleLoading(false)
        errorHandle(error.message)
        updateReservation(reservationId, error)
      } else {
        HandleLoading(false)
        errorHandle('Une erreur inattendue est apparue.')
      }
      setLoading(false)
    }
  }

  const paymentElementOptions = {
    layout: 'tabs',
    defaultValues: {
      billingDetails: {
        email: user?.email,
        name: user?.first_name,
        phone: user?.phone_number,
      },
    },
  }

  return (
    <>
      <form className={styles.container} onSubmit={handleSubmit}>
        <div className={styles.payment_content}>
          <h4>Payer avec</h4>
          <PaymentElement id='payment-element' options={paymentElementOptions} />
          <StripeInfos />
        </div>
        {children}
      </form>
      {/*------loading after confirm button------*/}
      <ConfirmLoad ref={loadingConfirm} />
      {/*------erreur server------*/}
      <ErrorServer ref={errorServer} />
    </>
  )
}
