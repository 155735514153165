import { useState } from 'react'
import styles from '../../../css/details/photoScroll.module.css'
import ModalCustomisable from '../../ui/ModalCustomisable'
import DetailAllPhoto from './details/DetailAllPhoto'
import CloseSvg from '../../svgs/CloseSvg'
import { useHistory } from 'react-router-dom'

const AllPhotoSroll = ({ hotel, modalState, handleCloseModal }) => {
  const [swiperRef, setSwiperRef] = useState(null)
  const history = useHistory()
  const id = window.location.pathname.split('/')[2]
  const Query = window.location.search
  const Params = new URLSearchParams(Query)

  const modalValueItem = 'PHOTO_ITEM'
  const modalStateItem = Params.get('modalItem') === modalValueItem

  const goToImage = index => {
    Params.append('modalItem', modalValueItem)
    history.push(`/rooms/${id}?${Params}`)
    swiperRef.slideTo(index - 1, 0)
  }

  const handleCloseModalItem = () => {
    if (history.length > 1) {
      history.goBack()
    } else {
      Params.delete('modalItem', modalValueItem)
      history.push(`/rooms/${id}?${Params}`)
    }
  }

  return (
    <>
      <ModalCustomisable isOpen={modalState} onClose={handleCloseModal}>
        <div
          className={modalState ? `${styles.modal_content} ${styles.active}` : styles.modal_content}
        >
          <div className={styles.image_titre}>
            <div>
              <button className={styles.close} onClick={handleCloseModal}>
                <CloseSvg />
              </button>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.block_photo}>
              {hotel?.images?.map((img, index) => (
                <div key={index} className={styles.card_block} onClick={() => goToImage(index + 1)}>
                  <img src={img.url} alt='photos liste' />
                </div>
              ))}
            </div>
          </div>
        </div>
      </ModalCustomisable>
      <DetailAllPhoto
        modalStateItem={modalStateItem}
        handleCloseModalItem={handleCloseModalItem}
        setSwiperRef={setSwiperRef}
        hotel={hotel}
      />
    </>
  )
}

export default AllPhotoSroll
