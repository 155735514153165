import styles from '../css/loading/loading.module.css'

const LoadingPoint = () => {
  return (
    <div className={styles.content_point}>
      <div className={styles.point}></div>
      <div className={styles.point}></div>
      <div className={styles.point}></div>
    </div>
  )
}

export default LoadingPoint
