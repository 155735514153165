import styles from '../../../css/about_us/about.module.css'
import { freeModeMonths, hostServiceFee } from '../../../config/config'
import DefaultLayout from '../../../layouts/DefaultLayout'
import { Helmet } from 'react-helmet-async'

function FreeModeHostPage() {
  return (
    <DefaultLayout footer={true}>
      <Helmet>
        <title>Free-mode | Gowifmi</title>
      </Helmet>

      <div className={styles.main}>
        <div className={styles.pourquoi_text}>
          <h1>FreeMode Gowifmi</h1>

          <h2>Qu'est ce que FreeMode de Gowifmi ?</h2>
          <div>
            <p>
              Gowifmi se distingue par sa politique transparente et avantageuse en matière de frais
              de service. Contrairement à de nombreux autres sites,{' '}
              <strong>
                Gowifmi ne prélève aucun frais de service d'hôte fixe, qui s'élève habituellement à{' '}
                {hostServiceFee} du sous-total de la réservation
              </strong>{' '}
              pendant une période de 3 mois à compter de la mise en ligne de votre annonce.
            </p>
          </div>
          <div>
            <p>
              Par conséquent, si vous facturez 100 $ US pour un séjour de 3 nuits, plus 60 $ US pour
              les frais de ménage, le sous-total de votre réservation est de 360 $ US. Les frais de
              service hôte, qui représentent généralement {hostServiceFee} du sous-total de votre
              réservation (0 $ US), sont déduits de vos revenus, et des frais de service de 14 %
              (50,40 $ US) sont facturés aux voyageurs et inclus dans le prix total qu'ils paient.
              Dans cet exemple
            </p>
            <ul>
              <li>Vous gagneriez 360 $ US.</li>
              <li>Votre voyageur paierait 410,40 $ US.</li>
            </ul>
          </div>
          <div>
            <p>
              Grâce à cette offre spéciale, vous pouvez bénéficier d'une période de 3 mois de
              réservations sans frais fixes sur Gowifmi. Pendant cette période, vous pourrez
              profiter de tous les avantages de la plateforme, attirer de nouveaux clients et
              développer votre activité sans avoir à payer ces frais de service fixes. Cela vous
              permet de préserver une part plus importante de vos revenus, renforçant ainsi votre
              rentabilité globale.
            </p>
            <p>
              Toutefois, pendant les {freeModeMonths} premiers mois suivant la mise en ligne de
              votre annonce sur Gowifmi, vous serez exempté de ces frais de service fixes de{' '}
              {hostServiceFee} du sous-total de la réservation selon notre{' '}
              <a href='/host-service-fee'>Politique relative aux frais de service</a>.<br /> Cette
              période promotionnelle vous offre une occasion unique de profiter pleinement de la
              plateforme et d'évaluer son potentiel sans avoir à vous soucier de ces frais
              supplémentaires.
            </p>
          </div>
          <h2>Conclusion</h2>
          <div>
            <p>
              En résumé, Gowifmi propose une offre exceptionnelle en ne prélevant aucun frais de
              service d'hôte fixe s'élevant habituellement à {hostServiceFee} du sous-total de la
              réservation. Cette politique avantageuse est valable pendant {freeModeMonths} mois à
              partir de la mise en ligne de votre annonce. Profitez de cette opportunité pour
              augmenter vos revenus, attirer de nouveaux clients et développer votre activité sur la
              plateforme Gowifmi, tout en préservant une plus grande part de vos gains.
            </p>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}
export default FreeModeHostPage
