import styles from '../../../css/account/accountMobile.module.css'
import { Link } from 'react-router-dom'
import SvgIconRightDirector from '../../svgs/SvgIconRightDirector'
import useNotification from '../../../hooks/useNotification'

const HoteMode = () => {
  const { totalLengthHote } = useNotification()

  return (
    <Link to='/hosting'>
      <div className={styles.left_block}>
        <svg
          className={styles.host_mode}
          aria-label='Mode hote'
          role='img'
          viewBox='0 0 24 24'
          strokeWidth='2'
        >
          <path
            d='M9.005 16.545a2.997 2.997 0 0 1 2.997-2.997A2.997 2.997 0 0 1 15 16.545V22h7V11.543L12 2 2 11.543V22h7.005Z'
            strokeLinejoin='round'
          ></path>
        </svg>
        <h3>Mode hôte{totalLengthHote > 0 && <span></span>}</h3>
      </div>
      <SvgIconRightDirector />
    </Link>
  )
}

export default HoteMode
