import styles from '../../../css/logins/content.module.css'
import Connexion from './Connexion'
import Inscription from './Inscription'
import ForgetPassword from './ForgetPassword'
import Password from './Password'
import ProfilCategory from './ProfilCategory'
import { CONNECTION_TYPE } from '../../../enums/user/connectionScreen'
import Location from './Location'

const ContentLogin = ({
  active,
  setActive,
  categories,
  setCategories,
  setGetUser,
  scrollToTop,
  fullAddress,
  setFullAddress,
  appartement,
  setAppartement,
  city,
  setCity,
  state,
  setState,
  postalCode,
  setPostalCode,
  country,
  setCountry,
  setCountryAbbreviation,
  setCoords,
  setIsValid,
}) => {
  return (
    <div className={styles.container}>
      {active === CONNECTION_TYPE.CONNECTION && (
        <Connexion changeState={active => setActive(active)} setGetUser={setGetUser} />
      )}
      {active === CONNECTION_TYPE.ENTER_PASSWORD && (
        <Password changeState={active => setActive(active)} />
      )}
      {active === CONNECTION_TYPE.REGISTRATION && (
        <Inscription
          changeState={active => setActive(active)}
          setGetUser={setGetUser}
          scrollToTop={scrollToTop}
        />
      )}
      {active === CONNECTION_TYPE.FORGOT_PASSWORD && (
        <ForgetPassword changeState={active => setActive(active)} />
      )}
      {active === CONNECTION_TYPE.PROFIL_CATEGORY && (
        <ProfilCategory categories={categories} setCategories={setCategories} />
      )}
      {active === CONNECTION_TYPE.PROFIL_LOCATION && (
        <Location
          fullAddress={fullAddress}
          setFullAddress={setFullAddress}
          appartement={appartement}
          setAppartement={setAppartement}
          city={city}
          setCity={setCity}
          state={state}
          setState={setState}
          postalCode={postalCode}
          setPostalCode={setPostalCode}
          country={country}
          setCountry={setCountry}
          setCountryAbbreviation={setCountryAbbreviation}
          setCoords={setCoords}
          setIsValid={setIsValid}
        />
      )}
    </div>
  )
}

export default ContentLogin
