import { useState, useEffect } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'
import RadioInput from '../../../../../ui/RadioInput'

const InstantBooking = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [reservationInstantaner, setReservationInstantaner] = useState()
  const { loading, updateData } = useUpdateHotel(setOpen)

  useEffect(() => {
    setReservationInstantaner(annonce.regulations?.instant_booking)
  }, [annonce])

  const handleSubmit = e => {
    e.preventDefault()
    const datas = {
      regulations: {
        ...annonce.regulations,
        instant_booking: reservationInstantaner,
      },
    }
    updateData(datas)
  }
  const openFuction = () => {
    setReservationInstantaner(annonce.regulations?.instant_booking)
    setOpen(true)
  }
  const reset = () => {
    setReservationInstantaner()
    setOpen(false)
  }
  return (
    <div className={styles.block}>
      {!open ? (
        <Section title='Réservation instantanée' handleOpen={openFuction}>
          {annonce.regulations?.instant_booking ? (
            <p>
              Réservation instantanée activée - Les voyageurs qui remplissent toutes vos conditions
              peuvent réserver instantanément. Les autres devront envoyer une demande de réservation
            </p>
          ) : (
            <p>
              Réservation instantanée désactivée - Tous les voyageurs doivent envoyer une demande de
              réservation
            </p>
          )}
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title='Réservation instantanée' handleReset={reset}>
            <p>Choisissez le mode de réservation de votre logement.</p>
          </ContenHead>
          <form onSubmit={handleSubmit}>
            <div className={styles.body}>
              <div className={styles.input_block}>
                <div className={styles.checkox_block}>
                  <div>
                    <RadioInput
                      id='active_instantane'
                      value={true}
                      checked={reservationInstantaner.toString() === 'true'}
                      onChange={e => setReservationInstantaner(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor='active_instantane'>
                      <div className={styles.statut}>
                        <h5>Réservation instantanée activée</h5>
                      </div>
                      <p>
                        Les voyageurs qui remplissent toutes vos conditions peuvent réserver
                        instantanément. Les autres devront envoyer une demande de réservation. Si
                        une réservation vous met mal à l'aise, vous pouvez l'annuler sans aucune
                        pénalité.
                      </p>
                    </label>
                  </div>
                </div>
                <div className={styles.checkox_block}>
                  <div>
                    <RadioInput
                      id='desactive_instantane'
                      value={false}
                      checked={reservationInstantaner.toString() === 'false'}
                      onChange={e => setReservationInstantaner(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor='desactive_instantane'>
                      <div className={styles.statut}>
                        <h5>Réservation instantanée désactivée</h5>
                      </div>
                      <p>Tous les voyageurs doivent envoyer une demande de réservation</p>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <BottomForm
              disabled={
                reservationInstantaner.toString() ===
                annonce.regulations?.instant_booking.toString()
              }
              loading={loading}
              handleReset={reset}
            />
          </form>
        </div>
      )}
    </div>
  )
}

export default InstantBooking
