import styles from '../../../css/details/equipement.module.css'
import CheckAmenitieSvg from './components/CheckAmenitieSvg'

const Family = ({ hotel }) => {
  const { amenities } = hotel

  return (
    <div className={styles.container_equip}>
      <h4>Famille</h4>
      {amenities?.includes('32') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Protections sur les fenêtres</p>
        </div>
      )}
      {amenities?.includes('33') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Recommandations de baby-sitters</p>
        </div>
      )}
      {amenities?.includes('78') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Baignoire pour bébés</p>
        </div>
      )}
      {amenities?.includes('79') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Babyphone</p>
        </div>
      )}
      {amenities?.includes('80') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Barrières de sécurité pour bébé</p>
        </div>
      )}
      {amenities?.includes('81') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Jeux de plateau</p>
        </div>
      )}
      {amenities?.includes('82') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Table à manger</p>
        </div>
      )}
      {amenities?.includes('83') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Livres et jouets pour enfants</p>
        </div>
      )}
      {amenities?.includes('84') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Vaisselle pour enfants</p>
        </div>
      )}
      {amenities?.includes('85') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Lit pour bébé</p>
        </div>
      )}
      {amenities?.includes('86') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Pare-feu pour la cheminée</p>
        </div>
      )}
      {amenities?.includes('87') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Chaise haute</p>
        </div>
      )}
      {amenities?.includes('88') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Caches-prises</p>
        </div>
      )}
      {amenities?.includes('89') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Lit parapluie</p>
        </div>
      )}
      {amenities?.includes('90') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Protections sur les coins de tables</p>
        </div>
      )}
    </div>
  )
}

export default Family
