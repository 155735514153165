export const Motif = [
  { value: "J'ai trouvé un autre hébergement" },
  {
    value:
      'Je ne peux pas voyager à cause des restrictions de déplacement liées au coronavirus (COVID-19)',
  },
  { value: 'Modification du nombre de voyageurs ou de leurs besoins' },
  { value: "J'ai effectué plusieurs réservations aux mêmes dates" },
  { value: 'Raisons personnelles/Mon séjour a été annulé' },
  { value: 'Changement de dates ou de destination' },
  { value: 'Aucune des propositions ci-dessus' },
]

export function TrouveUnAutreHebergement() {
  const autrehotel = [
    { value: "J'ai trouvé un hébergement dans un autre établissement" },
    { value: "J'ai trouvé un meilleur tarif dans le même établissement" },
    { value: "J'ai décidé de séjourner chez des amis ou la famille" },
    { value: 'Autre' },
  ]
  return (
    <>
      {autrehotel.map((item, i) => (
        <option defaultValue={item.value} key={i}>
          {item.value}
        </option>
      ))}
    </>
  )
}

export function Raison() {
  const RaisondechoisirAutreHote = [
    { value: 'Ses tarifs sont plus avantageux' },
    { value: "Il bénéficie d'un meuilleur emplacement" },
  ]
  return (
    <>
      {RaisondechoisirAutreHote.map((item, i) => (
        <option defaultValue={item.value} key={i}>
          {item.value}
        </option>
      ))}
    </>
  )
}

export function Surquelsite() {
  const Androit = [{ value: 'Sur Gowifmi' }, { value: 'Ailleurs' }]
  return (
    <>
      {Androit.map((item, i) => (
        <option defaultValue={item.value} key={i}>
          {item.value}
        </option>
      ))}
    </>
  )
}
