import { useState, useRef, useEffect } from 'react'
import styles from '../../../../../../../css/traveler/travel/modifs/date.module.css'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import moment from 'moment'

const CheckoutCalendrier = ({ hotel, setEndDate, endDate }) => {
  const [datepicker, setDatepicker] = useState(false)

  const Maxdate = moment(new Date())
    .add(hotel.availability_calendar?.availability_range, 'month')
    .format('YYYY-MM-DD')

  const minNuit = hotel.stay_duration?.minimum
  const maxNuit = hotel.stay_duration?.maximum

  const contentDate = useRef(null)
  useEffect(() => {
    const handleClickOutside = event => {
      if (contentDate.current && !contentDate.current.contains(event.target)) {
        setDatepicker(false)
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])
  return (
    <div className={styles.date_content} ref={contentDate}>
      <h2>Date de départ</h2>
      <div className={styles.selecte} onClick={() => setDatepicker(!datepicker)}>
        <svg
          className={styles.calendar}
          viewBox='0 0 48 48'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'
          role='presentation'
          focusable='false'
        >
          <g stroke='none'>
            <path
              d='M40 29.295V8H8v31a1 1 0 0 0 .883.993L9 40h20.295L40 29.295z'
              fillOpacity='.2'
            ></path>
            <path d='M19 2v2h10V2h2v2h11a2 2 0 0 1 1.995 1.85L44 6v24.953L30.953 44H9a5 5 0 0 1-4.995-4.783L4 39V6a2 2 0 0 1 1.85-1.995L6 4h11V2h2zm10 4H19v2h-2V6H6v33a3 3 0 0 0 2.824 2.995L9 42h20v-8a5 5 0 0 1 4.783-4.995L34 29h8V6H31v2h-2V6zm12.123 25H34a3 3 0 0 0-2.995 2.824L31 34v7.123L41.123 31z'></path>
          </g>
        </svg>
        <p>{moment(endDate).format('ll')}</p>
      </div>
      <div
        className={datepicker ? `${styles.date_checkout} ${styles.active}` : styles.date_checkout}
      >
        <div className={styles.calendar_head}>
          <h3>Sélectionner une date de départ</h3>
          <div className={styles.max_and_min}>
            <p>
              Durée minimale du séjour · {minNuit} nuit{minNuit > 1 && 's'}
            </p>
            <p>
              Durée maximale du séjour · {maxNuit} nuit{maxNuit > 1 && 's'}
            </p>
          </div>
        </div>
        <Calendar
          onChange={setEndDate}
          value={endDate}
          minDate={new Date()}
          maxDate={new Date(Maxdate)}
          selectRange={false}
          showNeighboringMonth={false}
          view='month'
          tileClassName={styles.tileClassName}
        />
        <div className={styles.close}>
          <button className={styles.close_only} onClick={() => setDatepicker(false)}>
            Fermer
          </button>
        </div>
      </div>
    </div>
  )
}

export default CheckoutCalendrier
