import styles from '../../css/details/asavoir.module.css'
import Reglement from './knowledge_base/Reglement'
import Cancellation from './knowledge_base/Cancellation'
import HealthAndSecurity from './knowledge_base/HealthAndSecurity'

const KnowledgeBase = ({ hotel }) => {
  return (
    <>
      <div className={`${styles.asavoir} ${styles.section}`}>
        <h3>À savoir</h3>
        <div className={styles.asavoir_block}>
          <Reglement hotel={hotel} />
          <HealthAndSecurity hotel={hotel} />
          <Cancellation hotel={hotel} />
        </div>
      </div>
    </>
  )
}

export default KnowledgeBase
