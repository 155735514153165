import { useState } from 'react'
import styles from '../../../css/modehotes/hosting/dashboard.module.css'
import { useQuery } from 'react-query'
import ListReservation from './today/ListReservation'
import LoadingPoint from '../../LoadingPoint'
import axios from 'axios'
import HeaderHosting from './today/HeaderHosting'
import ResourceHost from './today/ResourceHost'
import momentTimeZone from 'moment-timezone'

const Dashboard = () => {
  const [active, setActive] = useState('arriver')

  const userTimezone = momentTimeZone.tz.guess()

  const { isLoading, data = {} } = useQuery(
    `hosting_reservations_hotel}`,
    () =>
      axios.get(`/host_reservations/today?timezone=${userTimezone}`).then(res => {
        return res.data
      }),
    { refetchOnWindowFocus: false }
  )

  const arriverLength = data?.arriving?.length
  const encourLength = data?.inProgress?.length
  const departLength = data?.departing?.length
  const demandetLength = data?.requests?.length

  return (
    <>
      {isLoading ? (
        <div className={styles.container_load}>
          <LoadingPoint />
        </div>
      ) : (
        <div className={styles.container}>
          <HeaderHosting />
          <div className={styles.navigation}>
            <button
              className={active === 'arriver' ? styles.active : ''}
              onClick={() => setActive('arriver')}
            >
              <p>Arrivées prochaines</p>
              <p>{arriverLength}</p>
            </button>
            <button
              className={active === 'encours' ? styles.active : ''}
              onClick={() => setActive('encours')}
            >
              <p>Séjours en cours</p>
              <p>{encourLength}</p>
            </button>
            <button
              className={active === 'depart' ? styles.active : ''}
              onClick={() => setActive('depart')}
            >
              <p>Départs prochains</p>
              <p>{departLength}</p>
            </button>
            <button
              className={active === 'demande' ? styles.active : ''}
              onClick={() => setActive('demande')}
            >
              <p>Demandes de réservations</p>
              <p>{demandetLength}</p>
            </button>
          </div>
          {active === 'arriver' && (
            <ListReservation active='arriver' loading={isLoading} reservation={data?.arriving} />
          )}
          {active === 'encours' && (
            <ListReservation active='encours' loading={isLoading} reservation={data?.inProgress} />
          )}
          {active === 'depart' && (
            <ListReservation active='depart' loading={isLoading} reservation={data?.departing} />
          )}
          {active === 'demande' && (
            <ListReservation active='demande' loading={isLoading} reservation={data?.requests} />
          )}

          <ResourceHost />
        </div>
      )}
    </>
  )
}

export default Dashboard
