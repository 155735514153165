import React, { useState } from 'react'
import styles from '../../../../css/search_room_result/more_actions/show_profile.module.css'
import Modal from '../../../ui/Modal'
import CloseButton from '../../../ui/CloseButton'
import CoverImage from './show_profils/CoverImage'
import ProfilDescription from './show_profils/ProfileDescription'
import AllHostAnnonce from './show_profils/AllHostAnnonce'
import Comment from './show_profils/Comment'

const ShowProfil = React.forwardRef(({ item }, ref) => {
  const [modal, setModal] = useState(false)

  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      setModal(true)
    },
  }))

  const handleclose = () => {
    setModal(false)
  }

  return (
    <>
      <Modal isOpen={modal} onClose={handleclose}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={handleclose} />
            <div></div>
          </div>
          <div className={styles.m_body}>
            <CoverImage getUser={item?.user_id} />
            <ProfilDescription getUser={item?.user_id} />
            <AllHostAnnonce getUser={item?.user_id} />
            <Comment getUser={item?.user_id} />
          </div>
        </div>
      </Modal>
    </>
  )
})

export default ShowProfil
