import { useState } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import { CANCELLATION_POLICY } from '../../../../../../enums/logement/cancellation_policy.enums'
import RadioInput from '../../../../../ui/RadioInput'

const CancellationCondition = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [conditionAnnulation, setConditionAnnulation] = useState(
    annonce.regulations?.cancellation_policy
  )
  const { loading, updateData } = useUpdateHotel(setOpen)

  const handleSubmit = e => {
    e.preventDefault()
    const datas = {
      regulations: {
        ...annonce.regulations,
        cancellation_policy: conditionAnnulation,
      },
    }
    updateData(datas)
  }
  const openFuction = () => {
    setConditionAnnulation(annonce.regulations?.cancellation_policy)
    setOpen(true)
  }
  const reset = () => {
    setConditionAnnulation()
    setOpen(false)
  }
  const array = [
    {
      name: 'Flexibles',
      value: CANCELLATION_POLICY[0],
      descr: "Remboursement intégral jusqu'à 1 jour avant l'arrivée",
    },
    {
      name: 'Modérées',
      value: CANCELLATION_POLICY[1],
      descr: "Remboursement intégral jusqu'à 5 jours avant l'arrivée",
    },
    {
      name: 'Strictes',
      value: CANCELLATION_POLICY[2],
      descr:
        "Remboursement intégral pour les annulations effectuées dans les 48 heures suivant la réservation, si la date d'arrivée intervient dans 14 jours ou plus. Remboursement à hauteur de 50 % pour les annulations effectuées au moins 7 jours avant la date d'arrivée. Aucun remboursement pour les annulations effectuées dans les 7 jours précédant la date d'arrivée.",
    },
  ]
  return (
    <div className={styles.block}>
      {!open ? (
        <Section title="Conditions d'annulation" handleOpen={openFuction}>
          {annonce.regulations?.cancellation_policy === CANCELLATION_POLICY[0] && (
            <p>Flexibles : Remboursement intégral jusqu'à 1 jour avant l'arrivée.</p>
          )}
          {annonce.regulations?.cancellation_policy === CANCELLATION_POLICY[1] && (
            <p>Modérées : Remboursement intégral jusqu'à 5 jours avant l'arrivée.</p>
          )}
          {annonce.regulations?.cancellation_policy === CANCELLATION_POLICY[2] && (
            <p>
              Strictes : Remboursement intégral pour les annulations effectuées dans les 48 heures
              suivant la réservation, si la date d'arrivée intervient dans 14 jours ou plus.
              Remboursement à hauteur de 50 % pour les annulations effectuées au moins 7 jours avant
              la date d'arrivée. Aucun remboursement pour les annulations effectuées dans les 7
              jours précédant la date d'arrivée.
            </p>
          )}
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title="Conditions d'annulation" handleReset={reset}></ContenHead>
          <form onSubmit={handleSubmit}>
            <div className={styles.body}>
              <div className={styles.input_block}>
                {array.map(item => (
                  <div className={styles.checkox_block} key={item.value}>
                    <div>
                      <RadioInput
                        id={item.value}
                        value={item.value}
                        checked={conditionAnnulation === item.value}
                        onChange={e => setConditionAnnulation(e.target.value)}
                      />
                    </div>
                    <div>
                      <label htmlFor={item.value}>
                        <div className={styles.statut}>
                          <h5>{item.name}</h5>
                        </div>
                        <p>{item.descr}</p>
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <BottomForm
              disabled={conditionAnnulation === annonce.regulations?.cancellation_policy}
              loading={loading}
              handleReset={reset}
            />
          </form>
        </div>
      )}
    </div>
  )
}

export default CancellationCondition
