import styles from '../../../css/search_room_result/becomeHost.module.css'
import { Link } from 'react-router-dom'

const BecomeHost = () => {
  return (
    <>
      <div className={styles.container}>
        <h3>Mettez votre logement sur Gowifmi</h3>
        <p>
          Vous aussi devenez hôte sur Gowifmi ou invitez une personne à proposer son logement et
          gagnez de l'argent lorsqu'elle aura publié son annonce et honoré son premier séjour.
        </p>
        <div className={styles.block_link}>
          <Link to='/host/homes'>Ajouter un logement</Link>
          <Link to='/sponsorship/home'>Parrainez un hôte</Link>
        </div>
      </div>
    </>
  )
}

export default BecomeHost
