import { useRef } from 'react'
import styles from '../../../../css/traveler/inbox/signals.module.css'
import ModalSignal from '../../../signals/users'

const SignalerUser = ({ userSignal }) => {
  const signal = useRef()
  const Handlesignal = () => {
    signal.current.OpenModal()
  }
  return (
    <>
      <div className={styles.signal}>
        <button onClick={Handlesignal}>
          <svg
            viewBox='0 0 32 32'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            role='presentation'
            focusable='false'
          >
            <path d='M28 6H17V4a2 2 0 0 0-2-2H3v28h2V18h10v2a2 2 0 0 0 2 2h11l.115-.006a1 1 0 0 0 .847-1.269L27.039 14l1.923-6.724A1 1 0 0 0 28 6z'></path>
          </svg>
        </button>
      </div>
      <ModalSignal ref={signal} userSignal={userSignal} />
    </>
  )
}

export default SignalerUser
