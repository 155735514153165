import { useState, useRef, useEffect } from 'react'
import styles from '../../../css/modehotes/calendar/listAnnonceCalendar.module.css'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import Filter from '../annonce/defaults/Filter'
import SearchInput from '../../ui/SearchInput'

let timeoutId

const HeaderCalendar = ({ loading, setListeActive, annonce }) => {
  const history = useHistory()
  const location = useLocation()
  const pathname = location.pathname
  const Query = location.search
  const params = new URLSearchParams(Query)
  const text = params.get('query')
  const [search, setSearch] = useState(text || '')
  const [searchUpdated, setSearchUpdated] = useState(false)

  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    if (searchUpdated) {
      timeoutId = setTimeout(() => {
        if (search.length > 0) {
          params.set('query', search)
          history.push(`${pathname}?${params}`)
        } else {
          params.delete('query')
          history.push(`${pathname}?${params}`)
        }
      }, 700)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [search])

  const Allfilter = useRef()
  const Handlfilter = () => {
    Allfilter.current.OpenModal()
  }

  params.delete('page')
  const allFilterSelected = Array.from(params).length

  return (
    <>
      <div className={styles.head}>
        <div className={styles.close_modal}>
          <button onClick={() => setListeActive(false)}>
            <svg
              viewBox='0 0 32 32'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'
              role='presentation'
              focusable='false'
            >
              <path d='m6 6 20 20'></path>
              <path d='m26 6-20 20'></path>
            </svg>
          </button>
        </div>
        <div className={styles.filter}>
          {loading ? (
            <div className={styles.loading}></div>
          ) : (
            <h2>
              {annonce?.total_data} {annonce?.total_data > 1 ? 'annonces' : 'annonce'}
            </h2>
          )}
          <div>
            <button
              className={allFilterSelected > 0 ? styles.active : null}
              title='Filfrer'
              onClick={Handlfilter}
            >
              <svg
                viewBox='0 0 16 16'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
              >
                <path d='M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z'></path>
              </svg>
              <span className={styles.length}>{allFilterSelected}</span>
            </button>
          </div>
        </div>
        <div className={styles.search_block}>
          <SearchInput
            id='search_title'
            placeholder='Rechercher des logements'
            value={search}
            onChange={e => {
              setSearchUpdated(true)
              setSearch(e.target.value)
            }}
            onClick={() => {
              setSearchUpdated(true)
              setSearch('')
            }}
          />
        </div>
      </div>
      <Filter ref={Allfilter} />
    </>
  )
}

export default HeaderCalendar
