import styles from '../../../../../css/modehotes/annonce/details/securiteVoyageur.module.css'
import Checkbox from '../../../../ui/Checkbox'

const HauteurSansProtection = ({ hauteurSansProtection, setHauteurSansProtection }) => {
  return (
    <div className={styles.checkbox_container}>
      <div>
        <label htmlFor='hauteur'>Hauteur sans barrières ni protection</label>
        <p>
          Les voyageurs auront accès à une zone ou à une structure qui atteint une hauteur de plus
          de 1,4 mètre et ne dispose pas de barrières ou d'autres formes de protection. Ex. : un
          balcon, un toit, une terrasse.
        </p>
      </div>
      <div>
        <Checkbox
          id='hauteur'
          value={hauteurSansProtection}
          checked={hauteurSansProtection}
          onChange={() => setHauteurSansProtection(!hauteurSansProtection)}
        />
      </div>
    </div>
  )
}

export default HauteurSansProtection
