import { useState, useRef } from 'react'
import styles from '../../css/details/other_hotel.module.css'
import axios from 'axios'
import { useLocation } from 'react-router'
import { useQuery } from 'react-query'
import LoadingPoint from '../LoadingPoint'
import AnnoncePC from './annonce/AnnoncePc'
import AnnonceMobile from './annonce/AnnonceMobile'

const Annonce = ({ hotel }) => {
  const [prev, setPrev] = useState(false)
  const [next, setNex] = useState(true)

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  const location = useLocation()
  const id = location.pathname.split('/')[2]

  const {
    data: hotels,
    isLoading,
    error,
  } = useQuery(`get-hotel-detail-host-${id}`, () =>
    axios
      .get(`/hotel_user/host/detail/${hotel.user_id?.id}/${hotel?.address?.full_address}`)
      .then(res => {
        return res.data.filter(hotel => hotel._id !== id)
      })
  )

  const viewPort = window.matchMedia('(min-width:600px)')

  if (error) {
    return <p>Les annonces n'arrivent pas à charger veuillez reséyer</p>
  }

  if (!isLoading && hotels.length < 1) return null

  return (
    <div className={styles.section}>
      {isLoading ? (
        <div>
          <LoadingPoint />
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.heading}>
            <h3>
              Annonce{hotels.length > 1 ? 's' : ''} de {hotel.user_id?.first_name} sur le même
              établissement
            </h3>
            <div className={styles.heading_navigation}>
              <div
                ref={navigationPrevRef}
                className={prev ? styles.prev : `${styles.prev} ${styles.disable}`}
              >
                <svg
                  viewBox='0 0 32 32'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'
                  role='presentation'
                  focusable='false'
                  style={{
                    display: 'block',
                    fill: 'none',
                    height: '12px',
                    width: '12px',
                    stroke: 'currentcolor',
                    strokeWidth: '4',
                    overflow: 'visible',
                  }}
                >
                  <g>
                    <path d='m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932'></path>
                  </g>
                </svg>
              </div>
              <div
                ref={navigationNextRef}
                className={next ? styles.next : `${styles.next} ${styles.disable}`}
              >
                <svg
                  viewBox='0 0 32 32'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'
                  role='presentation'
                  focusable='false'
                  style={{
                    display: 'block',
                    fill: 'none',
                    height: '12px',
                    width: '12px',
                    stroke: 'currentcolor',
                    strokeWidth: '4',
                    overflow: 'visible',
                  }}
                >
                  <g>
                    <path d='m12 4 11.2928932 11.2928932c.3905243.3905243.3905243 1.0236893 0 1.4142136l-11.2928932 11.2928932'></path>
                  </g>
                </svg>
              </div>
            </div>
          </div>
          {viewPort.matches ? (
            <AnnoncePC
              hotel={hotels}
              setPrev={setPrev}
              setNex={setNex}
              navigationPrevRef={navigationPrevRef}
              navigationNextRef={navigationNextRef}
            />
          ) : (
            <AnnonceMobile hotel={hotels} />
          )}
        </div>
      )}
    </div>
  )
}

export default Annonce
