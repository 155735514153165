function usePrice(annonce) {
  const numberOfNight = 1

  const pricePerNight = annonce?.prices?.nightly_price || 0
  const cleaningFee = annonce?.prices?.cleaning_fee || 0
  const fraisSejour = annonce?.prices?.stay_fee || 0
  const gowifmiPourcentage = 14.3

  //appliquer la reducion
  const reductionFonction = () => {
    if (numberOfNight < 7) {
      return annonce?.discounts?.nightly || 0
    }
    if (numberOfNight >= 7 && numberOfNight < 28) {
      return annonce?.discounts?.weekly || 0
    } else if (numberOfNight >= 28) {
      return annonce?.discounts?.monthly || 0
    } else {
      return 0
    }
  }
  //le temps qu'est appliquer la reduction
  const reductionTimeFonction = () => {
    if (numberOfNight < 7) {
      return 'Réduction à la nuit'
    }
    if (numberOfNight >= 7 && numberOfNight < 28) {
      return 'Réduction à la semaine'
    } else if (numberOfNight >= 28) {
      return 'Réduction au mois'
    } else {
      return 0
    }
  }

  const reduction = reductionFonction()
  const reductionTime = reductionTimeFonction()

  const totalNuit = pricePerNight * numberOfNight + cleaningFee + fraisSejour

  const totalWithReduction = Math.round(totalNuit * (1 - reduction / 100))
  const promoFee = totalNuit - totalWithReduction

  const totalWithservice = Math.round(totalWithReduction * (1 + gowifmiPourcentage / 100))
  const serviceFee = totalWithservice - totalWithReduction
  const Total = totalWithReduction + serviceFee
  const revenus = Total - serviceFee

  return {
    pricePerNight,
    cleaningFee,
    fraisSejour,
    reductionTime,
    totalWithReduction,
    totalWithservice,
    promoFee,
    serviceFee,
    Total,
    revenus,
    numberOfNight,
  }
}

export default usePrice
