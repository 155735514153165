import { useContext } from 'react'
import styles from '../../../../css/modehotes/annonce/details/detail.module.css'
import { Helmet } from 'react-helmet-async'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { getAnnonce } from '../../../../redux/actions'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { User } from '../../../../context/UserContext'
import ErrorServer from '../../../errors/ErrorServer'
import LoadingPoint from '../../../../components/LoadingPoint'
import ListAnnonce from '../../../../components/hote_mode/annonce/details/defaults/ListAnnonce'
import HeadDetail from '../../../../components/hote_mode/annonce/details/defaults/HeadDetail'
import Navigation from '../../../../components/hote_mode/annonce/details/defaults/Navigation'
import Information from '../../../../components/hote_mode/annonce/details/detailAnnonces/informations/Information'
import Photo from '../../../../components/hote_mode/annonce/details/detailAnnonces/photos/Photo'
import Title from '../../../../components/hote_mode/annonce/details/detailAnnonces/titles/Title'
import Description from '../../../../components/hote_mode/annonce/details/detailAnnonces/descriptions/Description'
import Language from '../../../../components/hote_mode/annonce/details/detailAnnonces/languages/Language'
import Status from '../../../../components/hote_mode/annonce/details/detailAnnonces/statut/Status'
import Amenitie from '../../../../components/hote_mode/annonce/details/detailAnnonces/amenities/Amenitie'
import Address from '../../../../components/hote_mode/annonce/details/detailAnnonces/adresse/Address'
import Maps from '../../../../components/hote_mode/annonce/details/detailAnnonces/maps/Maps'
import PanoramicView from '../../../../components/hote_mode/annonce/details/detailAnnonces/panoramic_views/PanoramicView'
import NeighborhoodDesc from '../../../../components/hote_mode/annonce/details/detailAnnonces/neighborhood_descriptions/NeighborhoodDesc'
import OutOfPlace from '../../../../components/hote_mode/annonce/details/detailAnnonces/out_of_places/OutOfPlace'
import TypeAccomodation from '../../../../components/hote_mode/annonce/details/detailAnnonces/logements/TypeAccomodation'
import RoomDetail from '../../../../components/hote_mode/annonce/details/detailAnnonces/rooms/RoomDetail'
import Traveler from '../../../../components/hote_mode/annonce/details/detailAnnonces/travelers/Traveler'
import CustomUrl from '../../../../components/hote_mode/annonce/details/detailAnnonces/custom_urls/CustomUrl'
import SecurityTraveler from '../../../../components/hote_mode/annonce/details/detailAnnonces/security_travelers/SecurityTraveler'
import HostModeLayout from '../../../../layouts/HostModeLayout'
import { SATATUS_ANNONCE } from '../../../../enums/logement/status.enums'

const DetailAnnonce = () => {
  const user = useContext(User)
  const history = useHistory()
  const location = useLocation()
  const path = location.pathname.split('/')[4]
  const dispatch = useDispatch()
  const annonce = useSelector(state => state.updateAnnonce)

  const { isLoading, error } = useQuery(`hote-get-annonce-details-${path}`, () =>
    axios.get(`/hotel/oneAnnonce/${path}`).then(res => {
      dispatch(getAnnonce(res.data))
      if (res.data.status === SATATUS_ANNONCE.IN_PROGRESS) {
        history.push(`/become-a-host/${res.data.redirect_url}/${res.data._id}`)
      }
      if (res.data.status === SATATUS_ANNONCE.SAVE) {
        history.push(`/verify-listing/${res.data._id}`)
      }
    })
  )

  if (error) {
    return <ErrorServer />
  }
  if (user === null) {
    history.push('/')
  }

  return (
    <HostModeLayout>
      <Helmet>
        <title>Modifier l'annonce : {annonce?.title ?? ''} - Gowifmi</title>
      </Helmet>
      <div className={styles.voyage_container}>
        <ListAnnonce />
        <div className={styles.block_details}>
          <HeadDetail annonce={annonce} />
          <Navigation />
          {isLoading ? (
            <div className={styles.loading}>
              <LoadingPoint />
            </div>
          ) : (
            <div>
              <Information annonce={annonce} />
              <Photo annonce={annonce} />
              <Title annonce={annonce} />
              <Traveler annonce={annonce} />
              <CustomUrl annonce={annonce} />
              <Description annonce={annonce} />
              <Language annonce={annonce} />
              <Status annonce={annonce} />
              <Amenitie annonce={annonce} />
              <Address annonce={annonce} />
              <Maps Adresse annonce={annonce} />
              <PanoramicView annonce={annonce} />
              <NeighborhoodDesc annonce={annonce} />
              <OutOfPlace annonce={annonce} />
              <TypeAccomodation annonce={annonce} />
              <RoomDetail annonce={annonce} />
              <SecurityTraveler annonce={annonce} />
            </div>
          )}
        </div>
      </div>
    </HostModeLayout>
  )
}

export default DetailAnnonce
