import styles from '../../../css/modehotes/navbar/dropdown.module.css'
import Profile from './dropdown_links/Profile'
import Account from './dropdown_links/Account'
import Reservation from './dropdown_links/Reservation'
import Message from './dropdown_links/Message'
import Annonce from './dropdown_links/Annonce'
import AddHome from './dropdown_links/AddHome'
import Help from './dropdown_links/Help'
import LogoutHost from './dropdown_links/LogoutHost'
import SwishTravlerMode from './dropdown_links/SwishTravlerMode'
import Revenue from './dropdown_links/Revenue'
import Sponsor from './dropdown_links/Sponsor'
import Buil from './dropdown_links/Buil'
import Statistic from './dropdown_links/Statistic'
import CalendarParams from './dropdown_links/CalendarParams'
import Today from './dropdown_links/Today'
import CloseButton from '../../ui/CloseButton'

const Dropdown = ({ togle, setTogle, arrayNonlueUser, arrayNonlueHote }) => {
  const windowViewPort = window.matchMedia('(max-width:770px)')

  return (
    <div className={togle ? `${styles.sidenav} ${styles.avtive}` : styles.sidenav}>
      <div className={styles.side_head}>
        <CloseButton onClick={() => setTogle(false)} />
        <h1>Menu</h1>
        <div></div>
      </div>
      <Profile />
      <Account />
      {windowViewPort.matches ? (
        <>
          <Today />
          <Reservation />
          <Message arrayNonlueHote={arrayNonlueHote} />
          <Annonce />
          <CalendarParams />
        </>
      ) : null}
      <Revenue />
      <Sponsor />
      <Buil />
      <Statistic />
      <AddHome />
      <Help />
      <SwishTravlerMode arrayNonlueUser={arrayNonlueUser} />
      <LogoutHost />
    </div>
  )
}

export default Dropdown
