import { useState, useContext, useRef } from 'react'
import styles from '../../../../css/booking/formulaire.module.css'
import { User } from '../../../../context/UserContext'
import LoadingPoint from '../../../LoadingPoint'
import Cancellation from './components/Cancellation'
import ErrorInfos from './components/ErrorInfos'
import InfosUser from './components/InfosUser'
import DateInvalid from './components/errors/DateInvalid'
import useCreateReservation from '../../../../hooks/useCreateReservation'
import ErrorServer from './components/errors/ErrorServer'
import ConfirmLoad from './components/ConfirmLoad'
import RequestNotif from './components/RequestNotif'
import { STATUS_BOOKING } from '../../../../enums/booking/status.enums'
import PolicyTraveler from './components/pay_at_hosts/PolicyTraveler'

const Formulaire = ({
  payOnline,
  hotel,
  numberOfTravelerIsInvalid,
  handleVerifDisponibilite,
  startDateInvalid,
  endDateInvalid,
  confirmBooking,
}) => {
  const user = useContext(User)
  const [message, setMessage] = useState('')
  const [messageError, setMessageError] = useState(false)
  const [telephone, setTelephone] = useState(user ? user.phone_number : '')
  const [phoneError, setPhoneError] = useState(false)
  const [loading, setLoading] = useState(false)

  const policyTravelerRef = useRef()
  const HandlePolicyTraveler = () => {
    policyTravelerRef.current.OpenModal()
  }
  const loadingConfirm = useRef()
  const HandleLoading = params => {
    loadingConfirm.current.OpenModal(params)
  }
  const errorServer = useRef()
  const errorHandle = message => {
    errorServer.current.OpenError(message)
  }

  const { createReservation } = useCreateReservation({
    hotel,
    payOnline,
    HandleLoading,
    errorHandle,
  })

  const handleSubmit = async e => {
    e.preventDefault()
    errorHandle(null)
    const statut = confirmBooking ? STATUS_BOOKING.FUTURE : STATUS_BOOKING.REQUEST
    const reservationId = await createReservation(
      statut,
      telephone,
      message,
      setMessageError,
      setPhoneError,
      setLoading
    )

    if (reservationId) {
      HandleLoading(false)
      window.location.href = `/book/confirmation/${reservationId}`
    }
    setLoading(false)
  }

  return (
    <>
      <form className={styles.container} onSubmit={handleSubmit}>
        <div className={styles.payment_content}>
          <h4>Important</h4>
          <p>
            Avant de finaliser votre réservation en payant chez l'hôte, veuillez prendre en
            considération quelques points essentiels. Notez que les annulations de dernière minute
            peuvent impacter sérieusement l'hôte, perturbant ainsi leur emploi du temps.{' '}
          </p>
          <button type='button' onClick={HandlePolicyTraveler}>
            En savoir plus
          </button>
        </div>
        <div className={styles.recap_form}>
          <div>
            <InfosUser
              payOnline={payOnline}
              message={message}
              setMessage={setMessage}
              telephone={telephone}
              setTelephone={setTelephone}
              messageError={messageError}
              hotel={hotel}
              phoneError={phoneError}
            />
            <div>
              {!confirmBooking && <RequestNotif payOnline={payOnline} />}
              <Cancellation hotel={hotel} />
              {handleVerifDisponibilite() ? (
                <DateInvalid
                  numberOfTravelerIsInvalid={numberOfTravelerIsInvalid}
                  startDateInvalid={startDateInvalid}
                  endDateInvalid={endDateInvalid}
                  hotel={hotel}
                />
              ) : (
                <div>
                  {loading ? (
                    <button
                      type='button'
                      className={`${styles.submit} ${styles.loader_btn}`}
                      disabled
                    >
                      <LoadingPoint />
                    </button>
                  ) : (
                    <div>
                      <ErrorInfos messageError={messageError} phoneError={phoneError} />
                      <button type='submit' className={styles.submit}>
                        {confirmBooking ? 'Confirmer la réservation' : 'Demande de réservation'}
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
      <PolicyTraveler ref={policyTravelerRef} />
      {/*------loading after confirm button------*/}
      <ConfirmLoad ref={loadingConfirm} />
      {/*------erreur server------*/}
      <ErrorServer ref={errorServer} />
    </>
  )
}

export default Formulaire
