const PROFIL_CATEGORY = {
  PHOTOGRAPHER: 'photographer',
  TOURIST_GUIDE: 'tourist_guide',
  LOCAL_CHEF: 'local_chef',
  LOCAL_CRAFTSMAN: 'local_craftsman',
  TRANSLATOR_INTERPRETER: 'translator_interpreter',
  LOCAL_HISTORIAN: 'local_historian',
  FAMILY_TRAVEL_SPECIALIST: 'family_travel_specialist',
  EXCURSION_ORGANIZER: 'excursion_organizer',
  TRAVEL_INFLUENCER: 'travel_influencer',
}

export { PROFIL_CATEGORY }
