import styles from '../../../../css/modehotes/navbar/dropdown_link.module.css'
import { Link } from 'react-router-dom'
import SvgIconRightDirector from '../../../svgs/SvgIconRightDirector'

const Revenue = () => {
  return (
    <Link to='/hosting/revenues' className={styles.block_link}>
      <div className={styles.left_block}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 32 32'
          aria-hidden='true'
          role='presentation'
          focusable='false'
          fill='currentcolor'
        >
          <path d='M25 4a2 2 0 0 1 2 1.85V8h2.04c1.04 0 1.88.82 1.96 1.85V26c0 1.05-.8 1.92-1.81 2H6.96a1.98 1.98 0 0 1-1.95-1.85L5 26v-2H3a2 2 0 0 1-2-1.85V6a2 2 0 0 1 1.85-2H3zm2 18a2 2 0 0 1-1.85 2H7v2h22V10h-2zM25 6H3v16h22zm-3 12a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm-8-8a4 4 0 1 1 0 8 4 4 0 0 1 0-8zm0 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4zM6 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2z'></path>
        </svg>
        <h3>Revenus</h3>
      </div>
      <div className={styles.rigth_block}>
        <SvgIconRightDirector />
      </div>
    </Link>
  )
}

export default Revenue
