const Actives = ({ annonce }) => {
  const { languages } = annonce

  return (
    <>
      {languages.includes('1') && <p>Anglais</p>}
      {languages.includes('2') && <p>Français</p>}
      {languages.includes('3') && <p>Allemand</p>}
      {languages.includes('4') && <p>Japonais</p>}
      {languages.includes('5') && <p>Italien</p>}
      {languages.includes('6') && <p>Russe</p>}
      {languages.includes('7') && <p>Espagnol</p>}
      {languages.includes('8') && <p>Chinois (simplifié)</p>}
      {languages.includes('9') && <p>Arabe</p>}
      {languages.includes('10') && <p>Hindi</p>}
      {languages.includes('11') && <p>Portugais</p>}
      {languages.includes('12') && <p>Turc</p>}
      {languages.includes('13') && <p>Indonésien</p>}
      {languages.includes('14') && <p>Néerlandais</p>}
      {languages.includes('15') && <p>Thaï</p>}
      {languages.includes('16') && <p>Grec</p>}
      {languages.includes('17') && <p>Polonais</p>}
      {languages.includes('18') && <p>Malais</p>}
      {languages.includes('19') && <p>Tagalog</p>}
      {languages.includes('20') && <p>Danois</p>}
      {languages.includes('21') && <p>Suédois</p>}
      {languages.includes('22') && <p>Norvégien</p>}
      {languages.includes('23') && <p>Finnois</p>}
      {languages.includes('24') && <p>Tchèque</p>}
      {languages.includes('25') && <p>Hongrois</p>}
    </>
  )
}

export default Actives
