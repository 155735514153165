import styles from '../../../css/signals/signal.module.css'

const Succes = ({ handleclose }) => {
  return (
    <>
      <div className={styles.m_body}>
        <div>
          <h3>Merci pour vos précieuses remarques </h3>
          <p>
            Votre contribution est d'une valeur inestimable pour nous, car elle nous permet
            d'améliorer constamment notre plateforme pour mieux répondre à vos besoins.
          </p>
        </div>
      </div>
      <div className={styles.button_action}>
        <div></div>
        <button className={styles.next} onClick={handleclose}>
          Ok
        </button>
      </div>
    </>
  )
}

export default Succes
