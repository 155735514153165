import { useState, useEffect } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const Description = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [descrip, setDescrip] = useState('')
  const [sportsLoisirs, setSportsLoisirs] = useState('')
  const [pourEnfants, setPourEnfants] = useState('')

  const { loading, updateData } = useUpdateHotel(setOpen)

  const handleSubmit = e => {
    e.preventDefault()
    const datas = {
      about: {
        presentation: descrip,
        leisure_sports: sportsLoisirs,
        for_children: pourEnfants,
      },
    }
    updateData(datas)
  }
  useEffect(() => {
    setDescrip(annonce.about?.presentation || '')
    setSportsLoisirs(annonce.about?.leisure_sports || '')
    setPourEnfants(annonce.about?.for_children || '')
  }, [annonce])
  const openFuction = () => {
    setDescrip(annonce.about?.presentation || '')
    setSportsLoisirs(annonce.about?.leisure_sports || '')
    setPourEnfants(annonce.about?.for_children || '')
    setOpen(true)
  }
  const reset = () => {
    setDescrip('')
    setSportsLoisirs('')
    setPourEnfants('')
    setOpen(false)
  }
  return (
    <div className={styles.block}>
      {!open ? (
        <Section title='Description du logement' handleOpen={openFuction}>
          <p>{descrip || 'Décrivez votre logement aux voyageurs'}</p>
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title='Description du logement' handleReset={reset}>
            <p>
              Donnez aux voyageurs une idée de ce que serait un séjour chez vous, en leur disant
              notamment pourquoi ils vont adorer votre logement.
            </p>
          </ContenHead>
          <form onSubmit={handleSubmit}>
            <div className={styles.body}>
              <div className={styles.input_block}>
                <h4>Décrivez le logement</h4>
                <p>
                  Veuillez décrire votre logement en détail. Incluez des informations sur la taille,
                  le style, les caractéristiques spéciales et tout ce qui le rend unique.
                </p>
                <textarea
                  value={descrip}
                  maxLength={500}
                  onChange={e => setDescrip(e.target.value)}
                ></textarea>
                <span>{descrip.length}/500</span>
              </div>
              <div className={styles.input_block}>
                <h4>Sports et loisirs</h4>
                <p>
                  Dites-nous quels sports et loisirs sont accessibles à proximité de votre logement.
                  Cela peut inclure des activités comme la randonnée, le vélo, la natation, le golf,
                  les parcs, les restaurants, les clubs de sport, ou tout autre centre d'intérêt.
                  Donnez aux voyageurs un aperçu des possibilités de divertissement dans la région.
                </p>
                <textarea
                  value={sportsLoisirs}
                  maxLength={500}
                  onChange={e => setSportsLoisirs(e.target.value)}
                ></textarea>
                <span>{sportsLoisirs.length}/500</span>
              </div>
              <div className={styles.input_block}>
                <h4>Enfants et lits</h4>
                <p>
                  Décrivez les options de couchage pour les enfants et les équipements que vous
                  mettez à disposition. Incluez des détails sur les lits pour bébés, les lits
                  pliants, les chaises hautes, les jouets, les jeux, ou tout autre équipement
                  destiné aux plus jeunes voyageurs. Cela permettra aux familles de planifier leur
                  séjour en toute confiance.
                </p>
                <textarea
                  value={pourEnfants}
                  maxLength={500}
                  onChange={e => setPourEnfants(e.target.value)}
                ></textarea>
                <span>{pourEnfants.length}/500</span>
              </div>
            </div>
            <BottomForm disabled={descrip.length < 1} loading={loading} handleReset={reset} />
          </form>
        </div>
      )}
    </div>
  )
}

export default Description
