const GodownSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 32 32'
      style={{
        display: 'block',
        height: '16px',
        width: '16px',
        stroke: 'currentColor',
        strokeWidth: '4',
        overflow: 'visible',
        fill: 'none',
      }}
      aria-hidden='true'
      role='presentation'
      focusable='false'
    >
      <path fill='none' d='M28 12 16.7 23.3a1 1 0 0 1-1.4 0L4 12'></path>
    </svg>
  )
}

export default GodownSvg
