import { useState, useEffect } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'

const RoomDetail = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [chambre, setChambre] = useState()
  const [lit, setLit] = useState()
  const [salleBain, setSalleBain] = useState()

  const { loading, updateData } = useUpdateHotel(setOpen)

  const handleSubmit = e => {
    e.preventDefault()
    const datas = {
      details: {
        ...annonce.details,
        number_of_rooms: chambre,
        number_of_beds: lit,
        number_of_bathrooms: salleBain,
      },
    }
    updateData(datas)
  }
  useEffect(() => {
    setChambre(annonce.details?.number_of_rooms)
    setLit(annonce.details?.number_of_beds)
    setSalleBain(annonce.details?.number_of_bathrooms)
  }, [annonce])

  const openFuction = () => {
    setChambre(annonce.details?.number_of_rooms)
    setLit(annonce.details?.number_of_beds)
    setSalleBain(annonce.details?.number_of_bathrooms)
    setOpen(true)
  }
  const reset = () => {
    setChambre()
    setLit()
    setSalleBain()
    setOpen(false)
  }
  //incrementer les chambre
  const incrementeChambre = () => {
    setChambre(c => c + 1)
  }
  //decrementer et les chambre
  const decrementeChambre = () => {
    setChambre(c => c - 1)
  }
  //incrementer les lit et les select
  const incrementeLit = () => {
    setLit(c => c + 1)
  }
  //decrementer et les select
  const decrementeLit = () => {
    setLit(c => c - 1)
  }
  //incrementer les salleBain
  const incrementeSalleBain = () => {
    setSalleBain(c => c + 0.5)
  }
  //decrementer et les salleBain
  const decrementeSalleBain = () => {
    setSalleBain(c => c - 0.5)
  }
  return (
    <div className={styles.block}>
      {!open ? (
        <Section title='Pièces et Espaces' handleOpen={openFuction}>
          <p>Chambres : {annonce.details?.number_of_rooms}</p>
          <p>Lit : {annonce.details?.number_of_beds}</p>
          <p>Salles de bain : {annonce.details?.number_of_bathrooms}</p>
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title='Pièces et Espaces' handleReset={reset}></ContenHead>
          <form onSubmit={handleSubmit}>
            <div className={styles.body}>
              <div className={styles.info_search}>
                <div className={styles.block_flex}>
                  <div>
                    <h3>Chambres</h3>
                  </div>
                  <div className={styles.occup_count}>
                    <button
                      type='button'
                      disabled={chambre === 1 ? true : false}
                      className={chambre === 1 ? styles.disable : null}
                      onClick={decrementeChambre}
                    >
                      <svg
                        className={styles.decr}
                        viewBox='0 0 32 32'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                        role='presentation'
                        focusable='false'
                      >
                        <path d='m2 16h28'></path>
                      </svg>
                    </button>
                    <span>{chambre}</span>
                    <button
                      type='button'
                      disabled={chambre >= 50 ? true : false}
                      className={chambre === 50 ? styles.disable : null}
                      onClick={incrementeChambre}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className={styles.block_flex}>
                  <div>
                    <h3>Lits</h3>
                  </div>
                  <div className={styles.occup_count}>
                    <button
                      type='button'
                      disabled={lit === 1 ? true : false}
                      className={lit === 1 ? styles.disable : null}
                      onClick={decrementeLit}
                    >
                      <svg
                        className={styles.decr}
                        viewBox='0 0 32 32'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                        role='presentation'
                        focusable='false'
                      >
                        <path d='m2 16h28'></path>
                      </svg>
                    </button>
                    <span>{lit}</span>
                    <button
                      type='button'
                      disabled={lit >= 50 ? true : false}
                      className={lit === 50 ? styles.disable : null}
                      onClick={incrementeLit}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className={styles.block_flex}>
                  <div>
                    <h3>Salles de bain</h3>
                  </div>
                  <div className={styles.occup_count}>
                    <button
                      type='button'
                      disabled={salleBain === 0.5 ? true : false}
                      className={salleBain === 0.5 ? styles.disable : null}
                      onClick={decrementeSalleBain}
                    >
                      <svg
                        className={styles.decr}
                        viewBox='0 0 32 32'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                        role='presentation'
                        focusable='false'
                      >
                        <path d='m2 16h28'></path>
                      </svg>
                    </button>
                    <span>{salleBain}</span>
                    <button
                      type='button'
                      disabled={salleBain >= 50 ? true : false}
                      className={salleBain === 50 ? styles.disable : null}
                      onClick={incrementeSalleBain}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <BottomForm loading={loading} handleReset={reset} />
          </form>
        </div>
      )}
    </div>
  )
}

export default RoomDetail
