import styles from '../../css/errors/error404.module.css'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

const Error404 = () => {
  return (
    <>
      <Helmet>
        <title>Page introuvable</title>
      </Helmet>
      <div className={styles.erreur404}>
        <div className={styles.erreur_main}>
          <h1>Erreur 404</h1>
          <h2>Page introuvable!</h2>
          <Link to='/'>RETOURNER A L'ACCEUIL</Link>
        </div>
      </div>
    </>
  )
}

export default Error404
