import styles from '../../../css/modehotes/revenues/revenue.module.css'
import moment from 'moment'
import { capitalizeFirstLetter } from '../../../utils/formateString'

const SelecteDate = ({ selectedDate, setSelectedDate }) => {
  const generateOptions = () => {
    const options = []
    const currentDate = new Date()
    // Remplir les options pour les 11 mois précédents et les 11 mois suivants
    for (let i = -11; i <= 11; i++) {
      const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + i, 1)
      const formattedDate = newDate.toISOString().slice(0, 7) // 'YYYY-MM'
      const formattedDateString = capitalizeFirstLetter(
        moment(formattedDate, 'YYYY-MM').format('MMMM YYYY')
      )

      options.push({
        value: formattedDate,
        label: formattedDateString,
      })
    }

    return options
  }

  const handleSelectChange = event => {
    setSelectedDate(event.target.value)
  }

  return (
    <div className={styles.select_elem}>
      <select value={selectedDate} onChange={handleSelectChange}>
        {generateOptions().map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default SelecteDate
