import styles from '../../../css/becomeHostes/question.module.css'

const Infos = () => {
  return (
    <div>
      <h2>Choses importantes à savoir</h2>
      <div className={styles.infos_container}>
        <div className={styles.infos_section}>
          <div>
            <svg
              className={styles.verify}
              viewBox='0 0 16 16'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'
              role='presentation'
              focusable='false'
            >
              <path d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.159 4.869L6.67 9.355 4.42 7.105 3.289 8.236 6.67 11.62 12.291 6z'></path>
            </svg>
          </div>
          <div>
            <h3>Vérifiez quelques informations avant de publier votre annonce</h3>
            <p>
              Nous vous informerons si votre identité doit être vérifiée ou si vous devez procéder à
              l'enregistrement de votre logement auprès des autorités locales.
            </p>
          </div>
        </div>
        <div className={styles.infos_section}>
          <div>
            <svg className={styles.parameter} aria-label='Options' role='img' viewBox='0 0 24 24'>
              <circle
                cx='12'
                cy='12'
                r='8.635'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
              ></circle>
              <path
                d='M14.232 3.656a1.269 1.269 0 0 1-.796-.66L12.93 2h-1.86l-.505.996a1.269 1.269 0 0 1-.796.66m-.001 16.688a1.269 1.269 0 0 1 .796.66l.505.996h1.862l.505-.996a1.269 1.269 0 0 1 .796-.66M3.656 9.768a1.269 1.269 0 0 1-.66.796L2 11.07v1.862l.996.505a1.269 1.269 0 0 1 .66.796m16.688-.001a1.269 1.269 0 0 1 .66-.796L22 12.93v-1.86l-.996-.505a1.269 1.269 0 0 1-.66-.796M7.678 4.522a1.269 1.269 0 0 1-1.03.096l-1.06-.348L4.27 5.587l.348 1.062a1.269 1.269 0 0 1-.096 1.03m11.8 11.799a1.269 1.269 0 0 1 1.03-.096l1.06.348 1.318-1.317-.348-1.062a1.269 1.269 0 0 1 .096-1.03m-14.956.001a1.269 1.269 0 0 1 .096 1.03l-.348 1.06 1.317 1.318 1.062-.348a1.269 1.269 0 0 1 1.03.096m11.799-11.8a1.269 1.269 0 0 1-.096-1.03l.348-1.06-1.317-1.318-1.062.348a1.269 1.269 0 0 1-1.03-.096'
                stroke='currentColor'
                strokeLinejoin='round'
              ></path>
            </svg>
          </div>
          <div>
            <h3>Adaptez vos paramètres</h3>
            <p>
              Définissez votre règlement intérieur, choisissez des conditions d'annulation et des
              modalités de réservation pour les voyageurs, et plus encore.
            </p>
          </div>
        </div>
        <div className={styles.infos_section}>
          <div>
            <svg
              className={styles.calendar}
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 32 32'
              aria-hidden='true'
              role='presentation'
              focusable='false'
            >
              <path d='M11.67 0v1.67h8.66V0h2v1.67h6a2 2 0 0 1 2 1.85v16.07a2 2 0 0 1-.46 1.28l-.12.13L21 29.75a2 2 0 0 1-1.24.58H6.67a5 5 0 0 1-5-4.78V3.67a2 2 0 0 1 1.85-2h6.15V0zm16.66 11.67H3.67v13.66a3 3 0 0 0 2.82 3h11.18v-5.66a5 5 0 0 1 4.78-5h5.88zm-.08 8h-5.58a3 3 0 0 0-3 2.82v5.76zm-18.58-16h-6v6h24.66v-6h-6v1.66h-2V3.67h-8.66v1.66h-2z'></path>
            </svg>
          </div>
          <div>
            <h3>Configurez votre calendrier</h3>
            <p>
              Choisissez les dates auxquelles votre logement est disponible. L'annonce sera visible
              24 heures après sa publication.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Infos
