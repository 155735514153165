import styles from '../../../../../css/modehotes/reservation/detail.module.css'
import { useHistory } from 'react-router-dom'

const TitleHead = ({ reservation }) => {
  const history = useHistory()

  const handleRedirect = () => {
    if (history.length > 1) {
      history.goBack()
    } else {
      const Url = `/hosting/reservations/all`

      history.push(Url)
    }
  }

  return (
    <div className={styles.header}>
      <div>
        <button onClick={handleRedirect}>
          <svg
            viewBox='0 0 32 32'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            role='presentation'
            focusable='false'
            style={{
              display: 'block',
              height: '16px',
              width: '16px',
              stroke: 'currentcolor',
              strokeWidth: '3',
              fill: 'none',
              overflow: 'visible',
            }}
          >
            <g>
              <path d='m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932'></path>
            </g>
          </svg>
        </button>
      </div>
      <h1>Détails · {reservation?.hotel?.title}</h1>
    </div>
  )
}

export default TitleHead
