import { useState } from 'react'
import styles from '../../../css/logins/login.module.css'
import LoadingPoint from '../../LoadingPoint'
import axios from 'axios'
import { CONNECTION_TYPE } from '../../../enums/user/connectionScreen'

const FooterModal = ({ setActive, categories, getUser }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = () => {
    setIsLoading(true)
    const formData = {
      profile_categories: categories,
    }
    axios
      .patch(`/user/${getUser?.id}`, formData)
      .then(() => {
        setActive(CONNECTION_TYPE.PROFIL_LOCATION)
      })
      .catch(() => {
        alert('error')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <div className={styles.button_action}>
      <button className={styles.retour} onClick={() => setActive(CONNECTION_TYPE.PROFIL_LOCATION)}>
        Passer
      </button>
      {isLoading ? (
        <button className={`${styles.next} ${styles.disabled}`} disabled>
          <LoadingPoint />
        </button>
      ) : (
        <button
          className={categories.length < 1 ? `${styles.next} ${styles.disabled}` : styles.next}
          disabled={categories.length < 1}
          onClick={handleSubmit}
        >
          Suivant
        </button>
      )}
    </div>
  )
}

export default FooterModal
