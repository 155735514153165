import styles from '../../../../../../css/modehotes/annonce/details/reglementInterieur.module.css'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import Checkbox from '../../../../../ui/Checkbox'

const SmokerRegulation = ({ annonce }) => {
  const { updateData } = useUpdateHotel()

  const onchangeFilters = e => {
    if (e.target.checked === true) {
      const datas = {
        regulations: {
          ...annonce.regulations,
          smoking_allowed: true,
        },
      }
      updateData(datas)
    } else {
      const datas = {
        regulations: {
          ...annonce.regulations,
          smoking_allowed: false,
        },
      }
      updateData(datas)
    }
  }
  return (
    <div>
      <div className={styles.checkbox_container}>
        <div className={styles.text}>
          <label htmlFor='fumeur'>Logement fumeur</label>
          {annonce.regulations?.smoking_allowed ? (
            <p>Vous acceptez les fumeurs dans le logement.</p>
          ) : (
            <p>Vous refusez les fumeurs dans le logement.</p>
          )}
        </div>
        <div>
          <Checkbox
            id='fumeur'
            value={true}
            checked={annonce.regulations?.smoking_allowed}
            onChange={onchangeFilters}
          />
        </div>
      </div>
    </div>
  )
}

export default SmokerRegulation
