import { useState, useEffect, useContext } from 'react'
import { User } from '../../context/UserContext'
import { useHistory } from 'react-router-dom'
import globalStyle from '../../css/becomeHostes/globalStyle.module.css'
import Maps from '../../components/become_host/locations/Maps'
import DescriptionText from '../../components/become_host/globals/DescriptionText'
import { Helmet } from 'react-helmet-async'

const Location = () => {
  const [reviewMap, setReviewMap] = useState(false)

  const user = useContext(User)
  const history = useHistory()
  useEffect(() => {
    if (user === null) {
      history.push('/host/homes')
    }
  }, [history, user])
  return (
    <>
      <Helmet>
        <title>Sasissez l'emplacement du logement - Gowifmi</title>
      </Helmet>
      <div className={globalStyle.container}>
        <Maps reviewMap={reviewMap} setReviewMap={setReviewMap} />
        <DescriptionText
          title={reviewMap ? 'Le repère est-il au bon endroit ?' : 'Où est situé votre logement ?'}
          desc={reviewMap && 'Déplacez la carte pour repositionner le repère'}
        />
      </div>
    </>
  )
}

export default Location
