import styles from '../../../css/account/info_perso.module.css'
import LoadingPoint from '../../LoadingPoint'
import { useForm } from 'react-hook-form'
import useUserHook from '../../../hooks/useUserHook'

const LegaleName = ({ active, chageActive }) => {
  const { userHook, loadingUpdate, updateUser, errorUpdate } = useUserHook(chageActive)
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onChange' })
  const onSubmit = data => {
    const first_name = data.first_name
    const name = data.name
    const newPrenom = `${first_name[0].toUpperCase()}${first_name.slice(1)}`
    const newNom = `${name[0].toUpperCase()}${name.slice(1)}`
    const datas = {
      first_name: newPrenom,
      name: newNom,
    }
    updateUser(datas)
  }
  if (errorUpdate) alert('error')
  return (
    <>
      <div
        className={
          active && active !== 1 ? `${styles.form_content} ${styles.disable}` : styles.form_content
        }
      >
        <div className={styles.text}>
          <h3>Nom légal</h3>
          {active === 1 ? (
            <button type='button' onClick={() => chageActive()}>
              Annuler
            </button>
          ) : (
            <button type='button' disabled={active && active !== 1} onClick={() => chageActive(1)}>
              Modifier
            </button>
          )}
        </div>
        <div className={styles.modif_block}>
          {active !== 1 ? (
            <p>
              {userHook.first_name || userHook.name
                ? `${userHook.first_name} ${userHook.name || ''}`
                : 'Information non fournie'}
            </p>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
              <p>
                C'est le nom qui figure sur vos documents légaux, tels que votre permis de conduire,
                carte d'identité ou votre passeport, par exemple.
              </p>
              <div className={styles.input_box}>
                <div>
                  <label htmlFor='first_name'>Prénom/Entreprise</label>
                  <input
                    type='text'
                    id='first_name'
                    {...register('first_name', { value: userHook.first_name, required: true })}
                  />
                  {errors.first_name && (
                    <span className={styles.erreur}>Prénom est obligatoire</span>
                  )}
                </div>
                <div>
                  <label htmlFor='name'>Nom</label>
                  <input
                    type='text'
                    id='name'
                    {...register('name', { value: userHook.name, required: true })}
                  />
                  {errors.name && <span className={styles.erreur}>Nom est obligatoire</span>}
                </div>
              </div>
              <div className={styles.footer_btn_block}>
                {loadingUpdate ? (
                  <button disabled className={styles.loading}>
                    <LoadingPoint />
                  </button>
                ) : (
                  <button type='submit'>Enregistrer</button>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  )
}

export default LegaleName
