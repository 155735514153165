import styles from '../../css/search_room_result/card.module.css'
import { Link } from 'react-router-dom'
import Photo from './components/card/Photo'
import Header from './components/card/Header'
import DetailsHotel from './components/card/DetailsHotel'
import DetailsPrice from './components/card/DetailsPrice'
import Description from './components/card/Description'
import HostProfileAndMore from './components/card/HostProfileAndMore'

const Carte_hotel = ({ item, setChildchildhover }) => {
  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  const enfant = Number(Params.get('children')) || 0
  const adulte = Number(Params.get('adults')) || 1
  const bebe = Number(Params.get('infants')) || 0
  const animal = Number(Params.get('pets')) || 0
  const checkin = Params.get('checkin')
  const checkout = Params.get('checkout')

  const sizeWindow = window.matchMedia('(min-width:743px)')
  const sizeWindowForHoverItem = window.matchMedia('(min-width:1150px)')

  const handleMouseEnter = () => {
    if (sizeWindowForHoverItem.matches) {
      setChildchildhover(item._id)
    }
  }

  const handleMouseLeave = () => {
    if (sizeWindowForHoverItem.matches) {
      setChildchildhover(null)
    }
  }

  return (
    <>
      <div
        className={styles.hotel_activite}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <HostProfileAndMore item={item} />
        <div className={styles.hotel_image}>
          <Photo item={item} />
          <Link
            target={sizeWindow.matches ? '_blank' : null}
            rel='noopener noreferrer'
            to={`/rooms/${item._id}?adults=${adulte}&children=${enfant}&infants=${bebe}&pets=${animal}&checkin=${checkin}&checkout=${checkout}`}
          >
            <div className={styles.hotel_descrip}>
              <div>
                <Header item={item} />
                <Description item={item} />
              </div>
              <div className={styles.container_desc}>
                <DetailsHotel item={item} />
                <DetailsPrice item={item} />
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  )
}

export default Carte_hotel
