const TravelOptions = () => {
  const options = [
    { value: 'Commentaires' },
    { value: "Demande d'aide" },
    { value: "Recherche d'un logement" },
    { value: "Réservation d'un voyage" },
    { value: 'Paiement de mon voyage' },
    { value: 'Gestion de mes réservations' },
    { value: 'Messages' },
    { value: 'Mon compte ou mon profil' },
    { value: 'Tarif' },
    { value: 'Autres' },
  ]
  return (
    <>
      {options.map((item, i) => (
        <option value={item.value} key={i}>
          {item.value}
        </option>
      ))}
    </>
  )
}

export default TravelOptions
