import styles from '../../../../css/modehotes/annonce/listings/pagination.module.css'
import { useHistory } from 'react-router-dom'
import ReactPaginate from 'react-paginate'

const Pagination = ({ data }) => {
  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  const history = useHistory()
  const currentUrl = window.location.pathname

  const handlePageClick = ({ nextSelectedPage }) => {
    if (nextSelectedPage !== undefined) {
      Params.set('page', nextSelectedPage + 1)
      history.push(`${currentUrl}?${Params}`)
    }
  }

  const currentPage = data?.current_page - 1
  const totalPages = data?.total_pages
  const showPrevButton = currentPage > 0
  const showNextButton = currentPage < totalPages - 1

  if (data.total_data <= data.limit) return null

  return (
    <>
      <div>
        <ReactPaginate
          breakLabel={<span>...</span>}
          onClick={handlePageClick}
          pageRangeDisplayed={2}
          initialPage={currentPage}
          pageCount={totalPages}
          previousLabel={
            <span
              disabled={!showPrevButton}
              className={showPrevButton ? `${styles.navigate} ${styles.show}` : styles.navigate}
            >
              <svg
                viewBox='0 0 32 32'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
              >
                <g>
                  <path d='m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932'></path>
                </g>
              </svg>
            </span>
          }
          nextLabel={
            <span
              disabled={!showNextButton}
              className={showNextButton ? `${styles.navigate} ${styles.show}` : styles.navigate}
            >
              <svg
                viewBox='0 0 32 32'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
              >
                <g>
                  <path d='m12 4 11.2928932 11.2928932c.3905243.3905243.3905243 1.0236893 0 1.4142136l-11.2928932 11.2928932'></path>
                </g>
              </svg>
            </span>
          }
          containerClassName={styles.containerClassName}
          breakClassName={styles.breakClassName}
          pageClassName={styles.pageClassName}
          pageLinkClassName={styles.pageLinkClassName}
          activeLinkClassName={styles.activeLinkClassName}
        />
      </div>
    </>
  )
}

export default Pagination
