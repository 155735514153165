import styles from '../../../../../../css/modehotes/reservation/detail.module.css'
import { addCurrencyComplet } from '../../../../../../utils/addCurrency'
import { STATUS_BOOKING } from '../../../../../../enums/booking/status.enums'

const DetailPrice = ({ reservation, calculatedPrices }) => {
  const { totalRefund } = calculatedPrices

  return (
    <div className={styles.content}>
      <h3>Détails du prix</h3>
      <p>
        Total :{' '}
        {addCurrencyComplet(reservation.prices?.total_stay_fee, reservation?.prices?.currency)}
      </p>
      {reservation.status === STATUS_BOOKING.CANCELLED && (
        <>
          <p>
            Total à rembourser : {addCurrencyComplet(totalRefund, reservation.prices?.currency)}
          </p>
        </>
      )}
    </div>
  )
}

export default DetailPrice
