function useTravelerRating(comments) {
  const commentLength = comments?.length
  const totalLength = 5 * commentLength //le nombre des etoils dans chaque avis fois la taille de tout les comments
  const totalRatingList = 4 //le nombre des avis a soumettre

  const totalArrival = comments?.reduce((prev, current) => prev + current.arrival, 0)
  const totalArrivalPourcentage = Math.floor((totalArrival / totalLength) * 100)
  const arrivalOn5 = (totalArrival / commentLength).toFixed(1)

  const totalDeparture = comments?.reduce((prev, current) => prev + current.departure, 0)
  const totalDeparturePourcentage = Math.floor((totalDeparture / totalLength) * 100)
  const departureOn5 = (totalDeparture / commentLength).toFixed(1)

  const totalcleanliness = comments?.reduce((prev, current) => prev + current.cleanliness, 0)
  const totalCleanlinessPourcentage = Math.floor((totalcleanliness / totalLength) * 100)
  const cleanlinessOn5 = (totalcleanliness / commentLength).toFixed(1)

  const totalCommunication = comments?.reduce((prev, current) => prev + current.communication, 0)
  const totalCommunicationPourcentage = Math.floor((totalCommunication / totalLength) * 100)
  const communicationOn5 = (totalCommunication / commentLength).toFixed(1)

  const Total = totalArrival + totalDeparture + totalcleanliness + totalCommunication
  const totalComment = (Total / totalRatingList / commentLength).toFixed(1)

  return {
    commentLength,
    totalArrivalPourcentage,
    arrivalOn5,
    totalDeparturePourcentage,
    departureOn5,
    totalCleanlinessPourcentage,
    cleanlinessOn5,
    totalCommunicationPourcentage,
    communicationOn5,
    totalComment,
  }
}

export default useTravelerRating
