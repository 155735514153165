import styles from '../../../css/becomeHostes/home/section_1.module.css'
import { freeModeMonths, hostServiceFee } from '../../../config/config'
import customer from '../../../assets/become_host/customer.png'

const Section1 = ({ elementRef }) => {
  const Check = () => {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 32 32'
        style={{
          display: 'block',
          height: '24px',
          width: '24px',
          stroke: '#333',
          strokeWidth: '5.333333333333333',
          overflow: 'visible',
          fill: 'none',
        }}
        aria-label='Inclus avec Gowifmi'
        role='img'
        focusable='false'
      >
        <path fill='none' d='m4 16.5 8 8 16-16'></path>
      </svg>
    )
  }
  return (
    <div className={styles.section_1}>
      <div className={styles.block_left}>
        <h3>Votre tranquillité est notre priorité</h3>
        <p>
          Voici ce que nous faisons pour vous aider à accueillir les voyageurs en toute
          tranquillité.
        </p>
        <div className={`${styles.acceuil_voyage} ${styles.valeur}`} ref={elementRef}>
          <div>
            <Check />
          </div>
          <p>
            Gowifmi ne prélève aucun frais de service d'hôte fixe s'élevant à {hostServiceFee} du
            sous-total de la réservation, pendant {freeModeMonths} mois après la mise en ligne de
            votre annonce.{' '}
            <a href='/free-mode-host' target='_blank'>
              En savoir plus
            </a>
          </p>
        </div>
        <div className={styles.acceuil_voyage}>
          <div>
            <Check />
          </div>
          <p>
            Optimisez votre expérience d'hébergement avec Gowifmi ! Nous vous offrons une{' '}
            <strong>flexibilité totale</strong> en vous permettant de choisir entre{' '}
            <strong>encaisser vous-mêmes les paiements</strong> des voyageurs ou de laisser ces
            derniers régler directement sur notre plateforme.
          </p>
        </div>
        <div className={styles.acceuil_voyage}>
          <div>
            <Check />
          </div>
          <p>Un règlement intérieur que les voyageurs doivent accepter</p>
        </div>
        <div className={styles.acceuil_voyage}>
          <div>
            <Check />
          </div>
          <p>Vous signalez le comportement inapproprié d'un voyageur en cas de problème.</p>
        </div>
        <div className={styles.acceuil_voyage}>
          <div>
            <Check />
          </div>
          <p>Vous bénéficiez d'une assistance 24h/24 et 7j/7.</p>
        </div>
      </div>
      <div className={styles.img_droit}>
        <img src={customer} alt='' />
      </div>
    </div>
  )
}

export default Section1
