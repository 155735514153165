import { Helmet } from 'react-helmet-async'
import styles from '../../css/about_us/about.module.css'
import DefaultLayout from '../../layouts/DefaultLayout'

function PrivacyPolicy() {
  return (
    <DefaultLayout footer={true}>
      <Helmet>
        <title>confidentialite | Gowifmi</title>
      </Helmet>
      <div className={styles.main}>
        <div className={styles.pourquoi_text}>
          <h1>Protection des données sur Gowifmi</h1>

          <h2>Politique de confidentialité</h2>
          <div>
            <p>
              La Politique de confidentialité de Gowifmi décrit les droits liés à la protection de
              la vie privée, le genre de données personnelles collectées, ainsi que la manière dont
              elles sont utilisées et diffusées.
            </p>
          </div>

          <h3>Politique de confidentialité</h3>
          <div>
            <p>
              Gowifmi a pour objectif d'établir des liens entre les individus pour favoriser un
              monde plus ouvert et inclusif. En d'autres termes, l'objectif est de créer un
              environnement où chacun peut se sentir chez soi n'importe où. Gowifmi constitue une
              communauté fondée sur la confiance. Pour instaurer une relation de confiance, il est
              essentiel de garantir une transparence totale quant à l'utilisation de vos
              informations personnelles et la protection de votre vie privée.
            </p>
            <p>
              La présente Politique de confidentialité détaille la manière dont Gowifmi LLC, traite
              les informations personnelles liées à votre utilisation de la plateforme Gowifmi. Les
              détails peuvent varier en fonction de votre lieu de résidence et de vos activités sur
              la plateforme Gowifmi.
            </p>
          </div>

          <h4>1. Définitions</h4>
          <div>
            <p>
              Les termes non définis dans cette Politique de confidentialité sont interprétés
              conformément aux définitions énoncées dans nos Conditions générales "" Conditions ".
            </p>
          </div>
          <h4>2. Informations personnelles que nous collectons</h4>
          <h4>2.1 Informations nécessaires pour utiliser la plateforme Gowifmi.</h4>
          <div>
            <p>
              Nous collectons des informations personnelles vous concernant lors de l'utilisation de
              la plateforme Gowifmi. Sans ces informations, il nous serait impossible de vous offrir
              les services demandés. Ces informations comprennent :
            </p>
            <ul>
              <li>
                <strong>Coordonnées, informations de compte et informations de profil.</strong>Vos
                prénom, nom de famille, numéro de téléphone, adresse postale, adresse e-mail, date
                de naissance et photo de profil, en fonction des fonctionnalités que vous utilisez.
              </li>
              <li>
                <strong>Vérification d'identité et informations de paiement.</strong>Des images de
                votre pièce d'identité officielle (conformément à la législation applicable), votre
                numéro de pièce d'identité ou d'autres informations de vérification, un selfie lors
                du processus de vérification d'identité, vos coordonnées bancaires ou les
                informations de votre compte de paiement sont collectées par Gowifmi. Si vous n'êtes
                pas un utilisateur de Gowifmi, nous pourrions recevoir des informations de paiement
                vous concernant, par exemple, lorsqu'un utilisateur Gowifmi utilise votre carte de
                paiement pour effectuer une réservation. Si une copie de votre pièce d'identité nous
                est fournie, nous pouvons numériser, utiliser et stocker les informations contenues
                dans votre pièce d'identité afin de vérifier votre identité et à des fins de
                sécurité.
              </li>
            </ul>
          </div>
          <h4>2.2 Informations que vous choisissez de nous fournir.</h4>
          <div>
            <p>
              Vous pouvez choisir de nous fournir des informations personnelles supplémentaires. Ces
              informations peuvent inclure :
            </p>
            <ul>
              <li>
                <strong>Informations de profil supplémentaires.</strong>Telles que le genre, les
                langues préférées, la ville et une description personnelle, peuvent être fournies.
                Selon les paramètres de votre compte, certaines de ces données font partie de votre
                profil public et seront donc visibles par le public.
              </li>
              <li>
                <strong>Informations sur d'autres personnes.</strong>Par exemple, un moyen de
                paiement appartenant à une autre personne ou des détails sur un co-voyageur. En nous
                fournissant des informations personnelles sur d'autres personnes, vous garantissez
                avoir obtenu l'autorisation nécessaire pour partager ces informations avec Gowifmi
                aux fins décrites dans la présente Politique de confidentialité et que vous avez
                partagé la Politique de confidentialité de Gowifmi avec elles.
              </li>
              <li>
                <strong>Autres informations.</strong>Par exemple, celles que vous fournissez en
                remplissant un formulaire, en ajoutant des détails à votre compte, en participant à
                des sondages, en publiant sur des forums communautaires, en prenant part à des
                promotions, en interagissant avec l'assistance Gowifmi et d'autres membres, en
                important ou en entrant manuellement des contacts depuis votre carnet d'adresses, en
                fournissant votre adresse et/ou votre géolocalisation, ou en partageant votre
                expérience avec nous. Elles peuvent comprendre des informations sur la santé si vous
                choisissez de nous les communiquer.
              </li>
            </ul>
            <h4>
              2.3 Informations collectées automatiquement lors de l'utilisation de la plateforme
              Gowifmi et de nos services de paiement.
            </h4>
            <div>
              <p>
                Lorsque vous utilisez la plateforme Gowifmi et les Services de paiement, des
                informations personnelles et autres informations sont automatiquement collectées.
                Ces informations peuvent inclure :
              </p>
              <ul>
                <li>
                  <strong>Informations de géolocalisation.</strong>géolocalisation. Une localisation
                  précise ou approximative est déterminée à partir de votre adresse IP, du GPS de
                  votre téléphone portable ou d'un autre appareil, ainsi que d'autres informations
                  que vous choisissez de partager avec nous, en fonction des paramètres de votre
                  appareil. Ces données peuvent également être collectées même lorsque vous
                  n'utilisez pas l'application, si vous activez cette option dans vos paramètres ou
                  dans les autorisations de l'appareil.
                </li>
                <li>
                  <strong>Informations d'utilisation.</strong>Les pages ou le contenu que vous
                  consultez, les recherches d'annonces, les réservations que vous avez effectuées,
                  les services supplémentaires que vous avez ajoutés, et les autres actions
                  réalisées sur la plateforme Gowifmi.
                </li>
                <li>
                  <strong>Données de connexion et informations sur l'appareil.</strong>l'appareil.
                  Des informations sur la manière dont vous avez utilisé la plateforme Gowifmi
                  (notamment si vous avez cliqué sur des liens vers des applications tierces),
                  l'adresse IP, les dates et heures d'accès, des informations matérielles et
                  logicielles, des détails sur l'appareil utilisé, des informations concernant les
                  événements de l'appareil, les identifiants uniques, les données de plantage, ainsi
                  que les pages que vous avez visitées avant ou après l'utilisation de la plateforme
                  Gowifmi. Ces informations peuvent être collectées même si vous n'avez pas créé de
                  compte Gowifmi ou si vous n'êtes pas connecté(e).
                </li>
                <li>
                  <strong>
                    Cookies et technologies similaires telles que décrites dans notre Politique
                    relative aux cookies.
                  </strong>
                </li>
                <li>
                  <strong>Informations sur les transactions de paiement.</strong>Le mode de paiement
                  utilisé, la date et l'heure de la transaction, le montant payé, la date
                  d'expiration du mode de paiement, le code postal de facturation, l'adresse e-mail
                  associée à PayPal, les informations IBAN, votre adresse, ainsi que d'autres
                  informations liées à la transaction.
                </li>
              </ul>
            </div>
            <h4>2.4 Informations personnelles collectées auprès de tierces parties.</h4>
            <div>
              <p>
                Nous obtenons des informations personnelles auprès d'autres sources, notamment :
              </p>
              <ul>
                <li>
                  <strong>Services tiers.</strong>Si vous établissez un lien, vous vous connectez ou
                  accédez à la plateforme Gowifmi via un service tiers (comme Google, Facebook ou
                  WeChat), vous sollicitez de ce service l'envoi d'informations telles que votre
                  inscription, votre liste d'amis et votre profil. Cela se fait conformément aux
                  contrôles de confidentialité de ce service ou à l'autorisation que vous accordez
                  dans vos paramètres de confidentialité liés à ce service.
                </li>
                <li>
                  <strong>Renseignements et antécédents.</strong>Pour les Membres résidant aux
                  États-Unis, dans la mesure permise par la législation en vigueur, nous avons la
                  possibilité d'obtenir des informations telles que des extraits de casier
                  judiciaire, des inscriptions au registre des délinquants sexuels, et d'autres
                  informations vous concernant ou relatifs à vos antécédents. Pour les Hôtes en
                  Inde, dans le respect de la législation applicable, des vérifications des
                  antécédents judiciaires peuvent être effectuées. Pour les Membres se trouvant en
                  dehors des États-Unis, dans la mesure autorisée par la législation en vigueur et
                  avec votre consentement le cas échéant, nous sommes en mesure d'obtenir des
                  extraits de casier judiciaire ou des informations sur l'inscription au registre
                  des délinquants sexuels. L'utilisation de vos informations, y compris votre nom
                  complet et votre date de naissance, peut être nécessaire pour obtenir ces
                  documents.
                </li>
                <li>
                  <strong>Invitations pour les produits Entreprise et gestion de compte.</strong>Les
                  organisations utilisant nos produits Entreprise ont la possibilité d'envoyer des
                  informations personnelles afin de faciliter la gestion du compte et d'envoyer des
                  invitations pour l'utilisation des produits Entreprise.
                </li>
                <li>
                  <strong>Parrainages et co-Voyageurs.</strong>Si vous êtes invité sur la plateforme
                  Gowifmi, par exemple, en tant que co-voyageur lors d'un voyage, la personne qui a
                  envoyé l'invitation peut partager des informations personnelles vous concernant,
                  comme votre adresse e-mail ou d'autres coordonnées de contact.
                </li>
                <li>
                  <strong>Assurance voyage.</strong>Si vous effectuez une demande en vertu de notre
                  police assurance voyage, nous recevrons des informations relatives à votre demande
                  dans le but de la traiter, la gérer ou l'évaluer, conformément aux dispositions de
                  la présente Politique de confidentialité.
                </li>
                <li>
                  <strong>Autres sources.</strong>Dans le respect de la législation en vigueur, nous
                  avons la possibilité de recevoir des informations complémentaires vous concernant,
                  telles que des recommandations, des données démographiques, ou des données
                  concourant à la détection de fraudes et de failles de sécurité. Ces informations
                  peuvent provenir de prestataires de services tiers ou de partenaires, et être
                  croisées avec les données que nous détenons déjà à votre sujet. À titre d'exemple,
                  des résultats de vérifications d'antécédents ou des alertes de fraude peuvent nous
                  être transmis par des prestataires de services spécialisés dans la vérification
                  d'identité, dans le cadre de nos initiatives de prévention contre la fraude,
                  d'investigation de sécurité et d'évaluation des risques. Nous pourrions également
                  recevoir des informations sur vous, vos activités sur et en dehors de la
                  plateforme Gowifmi, ainsi que sur vos expériences et interactions avec nos
                  partenaires. Des informations relatives à la santé, y compris, sans s'y limiter,
                  des données concernant des maladies contagieuses, peuvent également nous parvenir.
                </li>
              </ul>
            </div>
            <h4>3. Comment nous utilisons les informations que nous collectons</h4>
            <div>
              <h4>3.1 Fourniture, amélioration et développement de la plateforme Gowifmi.</h4>
              <p>Nous pouvons traiter ces informations pour :</p>
              <ul>
                <li>
                  Vous donnez accès à la plateforme Gowifmi et faciliter les transactions
                  financières
                </li>
                <li>Faciliter la communication avec d'autres utilisateurs</li>
                <li>Traiter vos demandes</li>
                <li>Effectuer des analyses, des dépannages et des recherches</li>
                <li>Fournir une formation aux équipes de support client</li>
                <li>
                  Envoyer des messages, des mises à jour, des alertes de sécurité et des
                  notifications de compte
                </li>
                <li>
                  Gérer, évaluer ou traiter des déclarations de dommages ou des demandes similaires
                </li>
                <li>
                  Personnaliser votre expérience en fonction de vos interactions avec la plateforme
                  Gowifmi, de votre historique de recherche et de réservation, de vos informations
                  et préférences de profil, ainsi que d'autres contenus que vous fournissez
                </li>
                <li>
                  Faciliter l'utilisation de nos produits Entreprise et de nos services
                  d'hébergement.
                </li>
              </ul>
              <p>
                Si vous nous communiquez des renseignements concernant vos contacts tels que vos
                amis ou vos compagnons de voyage, nous avons la possibilité de traiter ces
                informations pour : (i) simplifier vos invitations de parrainage, (ii) partager vos
                données de voyage et faciliter la planification du voyage, (iii) identifier et
                prévenir la fraude, et (iv) faciliter vos demandes ou toute autre finalité que vous
                auriez autorisée.
              </p>
            </div>
          </div>
          <h4>3.2 Créer et maintenir un environnement de confiance sécurisé.</h4>
          <div>
            <p>Nous pouvons traiter ces informations pour :</p>
            <ul>
              <li>
                Détecter et prévenir les fraudes, les spams, les abus, les incidents de sécurité et
                d'autres activités préjudiciables
              </li>
              <li>
                Analyser et lutter contre la discrimination conformément à notre Politique de non-
                discrimination
              </li>
              <li>
                Conduire des enquêtes sur la prévention de la fraude, la sécurité et les évaluations
                des risques
              </li>
              <li>Vérifier ou authentifier les informations que vous avez fournies</li>
              <li>
                Effectuer des vérifications dans des bases de données et d'autres sources
                d'informations, y compris des vérifications des antécédents
              </li>
              <li>
                Respecter nos obligations légales, protéger la santé et le bien-être de nos
                voyageurs, hôtes, employés des hôtes et membres du public
              </li>
              <li>
                Résoudre les litiges avec nos membres, y compris partager des informations avec
                votre ou vos co-hôtes ou des voyageurs supplémentaires concernant les litiges liés à
                votre rôle de co-hôte ou de voyageur supplémentaire
              </li>
              <li>Faire respecter nos accords avec des tiers</li>
              <li>
                Déterminer les conditions requises pour certains types de réservations, tels que la
                réservation instantanée
              </li>
              <li>
                Se conformer à la loi, répondre aux réquisitions, prévenir les dommages et protéger
                nos droits (voir l'article 4.5)
              </li>
              <li>
                Faire respecter nos conditions et nos autres politiques (notamment la Politique de
                non-discrimination)
              </li>
              <li>
                Évaluer vos interactions avec la plateforme Gowifmi ainsi que les informations
                obtenues auprès de tierces parties. Dans un nombre limité de cas, des processus
                automatisés, qui analysent votre compte et vos activités sur la plateforme Gowifmi,
                ainsi que les informations relatives aux activités sur la plateforme Gowifmi et en
                dehors de celle-ci qui peuvent vous être associées, peuvent restreindre ou suspendre
                votre accès à la plateforme Gowifmi si ces processus détectent une activité qui peut
                présenter un risque de sécurité ou un autre type de risque pour Gowifmi, notre
                communauté ou toute tierce partie. Si vous souhaitez contester des décisions basées
                sur des processus automatisés, nous vous prions de bien vouloir nous contacter aux
                coordonnées figurant à la section Coordonnées ci-dessous.
              </li>
            </ul>
          </div>
          <h4>
            3.3 Fournir, personnaliser, évaluer et améliorer nos activités publicitaires et
            marketing. Nous pouvons traiter ces informations pour :
          </h4>
          <div>
            <ul>
              <li>
                Envoyer des messages promotionnels et marketing, de la publicité, ainsi que d'autres
                informations en accord avec vos préférences, incluant la publicité sur les réseaux
                sociaux via les plateformes dédiées.
              </li>
              <li>Adapter, évaluer et améliorer nos annonces publicitaires.</li>
              <li>
                Gérer les récompenses, les programmes de parrainage, les enquêtes, les loteries, les
                concours, et autres activités promotionnelles organisées ou gérées par Gowifmi ou
                ses partenaires commerciaux.
              </li>
              <li>
                Analyser les caractéristiques et les préférences pour vous envoyer des messages
                promotionnels, du marketing, de la publicité, et d'autres informations pertinentes.
              </li>
              <li>Vous invitez à des événements ou à d'autres occasions.</li>
              <li>
                Avec votre consentement, envoyer des messages promotionnels, des informations
                commerciales, de la publicité, et d'autres contenus susceptibles de vous intéresser
                en fonction de vos préférences.
              </li>
            </ul>
          </div>
          <h4>3.4 Analyser et partager vos communications.</h4>
          <div>
            <p>
              Nous avons la possibilité de consulter, examiner ou analyser vos communications sur la
              plateforme Gowifmi, conformément aux raisons énoncées dans la section "Notre
              utilisation des informations que nous collectons" de cette politique. Ces motifs
              incluent la prévention de la fraude, les enquêtes de sécurité, l'évaluation des
              risques, la conformité réglementaire, le développement de produits, la recherche,
              l'analyse, l'application de nos Conditions de service et le support utilisateur.
            </p>
            <p>
              À titre d'exemple, dans le cadre de nos initiatives de lutte contre la fraude, nous
              parcourons et analysons les messages pour masquer les coordonnées et les références à
              d'autres sites web. Sous réserve de la loi applicable, nous examinons également toutes
              les images téléchargées par les utilisateurs sur la plateforme Gowifmi dans les fils
              de discussion, les profils, les annonces et les expériences. Cette analyse vise à
              détecter des activités illégales ou inappropriées, telles que des preuves
              d'exploitation d'enfants, dans le but d'identifier et de signaler les violations de
              contenu aux autorités compétentes.
            </p>
            <p>
              Dans certains cas, nous pouvons également examiner, consulter ou analyser les messages
              pour résoudre des problèmes, améliorer et élargir notre gamme de produits. Nous
              privilégions l'utilisation de méthodes automatisées autant que possible. Cependant, il
              peut arriver que nous devions consulter manuellement les communications, notamment
              dans le cadre d'enquêtes sur la fraude et de support utilisateur, ou encore pour
              évaluer et améliorer la fonctionnalité de ces outils automatisés.
            </p>
            <p>
              Il est important de souligner que nous n'utiliserons pas la consultation, l'examen ou
              l'analyse de vos communications à des fins d'envoi de messages marketing de la part de
              tiers, et nous ne vendrons pas les résultats de ces examens ou analyses. De plus, nous
              pourrions partager vos communications conformément aux dispositions de la section
              "Partage et divulgation".
            </p>
          </div>
          <h4>3.5 Lien vers des comptes tiers.</h4>
          <div>
            <p>
              Vous avez la possibilité de connecter votre compte Gowifmi à certains services tiers,
              tels que les réseaux sociaux. Les contacts présents sur ces services tiers sont
              identifiés comme « amis ». Lorsque vous donnez votre autorisation et activez le
              partage de données en créant ce lien :
            </p>
            <ul>
              <li>
                Certaines des informations provenant des comptes liés peuvent être rendues publiques
                sur votre profil Gowifmi.
              </li>
              <li>
                Vos activités sur la plateforme Gowifmi peuvent être visibles pour vos amis sur
                Gowifmi ou sur le service tiers en question.
              </li>
              <li>
                Un lien vers votre profil public sur ce service tiers peut être intégré à votre
                profil public Gowifmi.
              </li>
              <li>
                Les autres utilisateurs de Gowifmi pourront voir d'éventuels amis en commun ou
                constater que vous êtes ami avec l'un de leurs amis.
              </li>
              <li>
                Les autres utilisateurs Gowifmi peuvent voir les écoles, villes d'origine ou
                d'autres groupes que vous partagez avec eux, tels qu'ils apparaissent sur vos
                services de réseaux sociaux liés.
              </li>
              <li>
                Les informations que vous nous révélez en liant vos comptes peuvent être conservées,
                traitées et transmises à des fins de prévention des fraudes, d'enquêtes de sécurité
                et d'évaluation des risques.
              </li>
              <li>
                La publication et l'affichage des informations que vous fournissez à la plateforme
                Gowifmi via ces liens sont soumises aux paramètres et autorisations que vous
                définissez sur Gowifmi et le service tiers en question.
              </li>
            </ul>
          </div>
          <h4>
            3.6 Fournir des services de paiement. Les informations personnelles sont exploitées afin
            de permettre ou d'autoriser des tiers à utiliser les services de paiement, notamment
            pour :
          </h4>
          <div>
            <ul>
              <li>
                Détecter et prévenir le blanchiment d'argent, la fraude, les abus et les incidents
                de sécurité, tout en réalisant des évaluations des risques.
              </li>
              <li>
                Respecter les obligations légales et de conformité, y compris les réglementations
                liées à la lutte contre le blanchiment d'argent et l'application des sanctions.
              </li>
              <li>Fournir et améliorer les services de paiement.</li>
            </ul>
          </div>
          <h4>4. Partage et divulgation</h4>
          <h4>4.1 Partage avec votre consentement ou selon vos instructions</h4>
          <div>
            <ul>
              <p>
                En accordant votre consentement, vos informations sont partagées conformément aux
                conditions énoncées lors de ce consentement. Cela peut se produire, par exemple,
                lorsque vous autorisez une application ou un site web tiers à accéder à votre compte
                Gowifmi, ou lorsque vous participez à des activités promotionnelles proposées par
                des partenaires de Gowifmi ou des tiers.
              </p>
              <p>
                Dans le respect de la législation en vigueur, nous pouvons utiliser certaines
                informations vous concernant, comme votre adresse e-mail, que nous partageons avec
                des plateformes de réseaux sociaux, dans le but de générer des hôtes potentiels,
                d'attirer du trafic vers Gowifmi, ou encore de promouvoir nos produits et services
                de toute autre manière.
              </p>
            </ul>
          </div>
          <h4>4.2 Partage entre membres</h4>
          <div>
            <p>
              Afin de simplifier les réservations ou d'autres interactions entre les Membres (qui
              peuvent se trouver dans des juridictions offrant des niveaux de protection des données
              variables, ou collaborer avec des prestataires de services situés dans ces
              juridictions), nous sommes susceptibles de partager des informations dans des
              circonstances telles que :
            </p>
            <ul>
              <li>
                Entre Voyageur(s) et Hôte(s) lors des différentes étapes du processus de réservation
                :
              </li>
              <li>
                Lorsqu'une demande de réservation est soumise, des informations telles que le
                profil, le nom, le nom des Voyageurs supplémentaires, l'historique des annulations,
                les commentaires publics, l'âge du Voyageur (sauf interdiction légale), l'issue des
                litiges (si applicable), et d'autres informations que vous choisissez de partager et
                d'envoyer peuvent être partagées.
              </li>
              <li>
                Une fois la réservation confirmée, des détails supplémentaires, tels que la photo de
                profil et le numéro de téléphone, peuvent être partagés pour faciliter la
                coordination du voyage.
              </li>
              <li>
                Si vous, en tant qu'Hôte, confirmez une réservation, certaines informations, comme
                votre profil, votre nom complet, votre numéro de téléphone et l'adresse de votre
                logement, peuvent être partagées avec le Voyageur (et tout Voyageur supplémentaire
                invité, le cas échéant) pour coordonner la réservation.
              </li>
              <li>
                Lors de la communication entre les Membres, des informations telles que votre nom,
                votre photo de profil et le contenu des messages peuvent être utilisées.
              </li>
              <li>Entre Voyageurs :</li>
              <li>
                Lorsque vous, en tant que Voyageur, invitez d'autres Voyageurs à vous accompagner
                sur une réservation, certaines informations telles que votre nom, les dates du
                voyage, le nom de l'Hôte, les détails de l'Annonce, l'adresse du Logement et
                d'autres informations associées peuvent être partagées.
              </li>
              <li>Entre les Hôtes :</li>
              <li>
                En acceptant une invitation à devenir co-Hôte, vous autorisez les autres Membres, en
                tant que co-Hôtes, à accéder et à mettre à jour vos informations, y compris
                certaines données telles que votre nom complet, votre numéro de téléphone, l'adresse
                de votre Logement, votre calendrier, les informations de l'Annonce, les photos de
                l'Annonce et votre adresse e-mail.
              </li>
            </ul>
          </div>
          <h4>
            4.3 Informations que vous publiez dans les Profils, les Annonces et autres informations
            publiques.
          </h4>
          <div>
            <p>
              Vous avez la possibilité de rendre certaines informations accessibles publiquement aux
              autres utilisateurs, notamment :
            </p>
            <ul>
              <li>
                Votre page de profil publique, comprenant votre photo de profil, votre prénom (ou
                initiales le cas échéant), votre description et votre ville.
              </li>
              <li>
                Les pages d'annonces contenant des détails tels que la description approximative ou
                précise du logement ou de l'expérience, la disponibilité, la photo de profil, les
                informations agrégées liées à la demande (comme le nombre de vues de la page sur une
                période donnée), ainsi que toute information supplémentaire que vous choisissez de
                partager.
              </li>
              <li>Les commentaires, évaluations et observations du public.</li>
              <li>
                Le contenu présent dans un forum de discussion ou de la communauté, un blog ou un
                article sur les réseaux sociaux.
              </li>
            </ul>
            <p>
              Nous avons la possibilité d'afficher certaines sections de votre profil public et
              d'autres contenus que vous mettez à disposition du public, tels que les détails de
              l'annonce, sur des applications, plateformes et sites tiers.
            </p>
            <p>
              Les informations que vous partagez publiquement sur la plateforme Gowifmi peuvent être
              indexées par des moteurs de recherche tiers. Dans certains cas, vous avez la
              possibilité de désactiver cette fonctionnalité dans les paramètres de votre compte.
            </p>
            <h4>4.4 Prestataires de services d'hôte</h4>
            <p>
              Les Hôtes ont la possibilité de recourir à des services tiers pour la gestion ou la
              prestation de leurs services, tels que des services de ménage ou de serrurerie.
              Utilisant les fonctionnalités de la plateforme Gowifmi, les Hôtes peuvent partager des
              informations sur le Voyageur, comprenant notamment les détails d'arrivée et de départ,
              le nom et le numéro de téléphone, avec ces prestataires de services tiers.
            </p>
            <h4>
              4.5 Respect de la législation, réponse aux réquisitions des autorités de police et de
              justice, prévention des préjudices et protection de nos droits
            </h4>
            <p>
              Nous pourrions révéler vos informations aux tribunaux, aux autorités policières, aux
              autorités gouvernementales ou publiques, aux autorités fiscales, à des tiers autorisés
              ou à d'autres Membres, dans la mesure où nous sommes tenus ou autorisés à le faire par
              la loi. Ceci s'applique lorsque la divulgation est raisonnablement nécessaire pour :
              (i) respecter nos obligations légales ; (ii) satisfaire une exigence judiciaire
              valide, telle qu'une ordonnance ou une décision de justice, ou pour répondre à des
              réclamations dirigées contre Gowifmi ; (iii) répondre à une demande légale valide dans
              le cadre d'une enquête criminelle liée à une activité illégale ou présumée, ou pour
              faire face à toute autre activité pouvant exposer Gowifmi, vous ou tout autre
              utilisateur, à une responsabilité légale ou réglementaire.
            </p>
            <p>
              Le cas échéant, nous pouvons informer les membres de la présence de demandes légales,
              à moins que : (i) la notification ne soit prohibée par le processus juridique
              lui-même, par une décision de justice que nous recevons, ou par la loi en vigueur ; ou
              (ii) nous estimons que la notification est sans utilité, inefficace, pourrait
              entraîner un risque de blessure ou de préjudice corporel pour un individu ou un
              groupe, ou créerait ou accroîtrait un risque de fraude ou de préjudice envers Gowifmi
              ou nos membres, ou exposerait Gowifmi à une plainte pour obstruction à la justice.
            </p>
            <p>
              Quand la législation en vigueur le requiert ou le permet, nous pourrions partager des
              informations liées aux Hôtes ou aux Voyageurs avec les autorités fiscales ou d'autres
              organismes gouvernementaux, afin que les autorités fiscales puissent évaluer la
              conformité aux obligations fiscales en vigueur. Ces obligations fiscales englobent les
              responsabilités fiscales de Gowifmi concernant ses frais de service, son rôle de
              facilitateur pour les taxes sur l'hébergement et les retenues à la source, ainsi que
              les obligations fiscales individuelles des Hôtes. Les informations qui pourraient être
              divulguées comprennent, mais ne se limitent pas à, les noms des Hôtes et des
              Voyageurs, les adresses des logements, les adresses des Hôtes, le(s) numéro(s)
              d'identification fiscale/commerciale, la date de naissance et/ou les coordonnées, les
              numéros d'identification des parcelles, les informations de versement, les dates et
              montants des transactions, le nombre de nuits réservées ainsi que le nombre de
              Voyageurs, la valeur brute et nette des réservations et les montants des versements,
              les taxes collectées par Gowifmi au nom des Voyageurs et des Hôtes, dans la mesure où
              ces informations sont connues de Gowifmi.
            </p>
            <p>
              Dans les juridictions où Gowifmi encourage ou requiert une procédure d'enregistrement,
              de notification, d'autorisation, d'obtention de licence ou l'obtention d'un numéro
              d'Hôte auprès d'une autorité gouvernementale, nous pourrions partager des détails sur
              les Hôtes participants avec l'autorité compétente. Cela peut se produire au cours du
              processus de demande, lors de la publication de l'annonce, et éventuellement de
              manière périodique par la suite. Les informations pouvant être communiquées incluent
              le nom complet et les coordonnées de l'Hôte, l'adresse du logement, le numéro
              d'identification fiscale, le numéro d'enregistrement, de permis ou de licence, les
              détails de l'annonce, les informations de réservation et le nombre de nuits réservées,
              conformément aux lois applicables.
            </p>
            <h4>4.6 Programmes avec les gestionnaires et propriétaires immobiliers</h4>
            <p>
              Nous avons la possibilité de partager des informations personnelles relatives aux
              Hôtes et aux Voyageurs avec les bailleurs, les sociétés de gestion, les syndics de
              copropriété, les propriétaires de logement ou les experts en gestion locative,
              désignés comme le « Responsable de l'immeuble ». Cela inclut des détails sur les
              réservations et le respect des lois applicables, dans le but de faciliter les
              programmes avec le Responsable de l'immeuble. Par exemple, les informations de
              réservation des Voyageurs, ainsi que leurs coordonnées, peuvent être partagées avec le
              Responsable de l'immeuble du complexe ou de la communauté où réside un Hôte et/ou où
              se situe le logement. Ceci vise à faciliter les services d'hébergement, le respect des
              lois applicables, la sécurité, la facturation et d'autres services connexes.
            </p>
            <h4>4.7 Informations sur l'Hôte fournies aux clients Gowifmi for Work</h4>
            <p>
              En cas de réservation effectuée à des fins professionnelles et (1) par un Voyageur
              affilié à une entreprise et (2) si ladite entreprise est enregistrée sur Gowifmi for
              Work, nous pourrions partager les détails de cette réservation avec l'entreprise. Ces
              informations peuvent inclure le nom de l'Hôte, l'adresse du logement, les dates de
              réservation, les détails de l'annonce, etc., dans la mesure nécessaire à l'exécution
              correcte du contrat entre Gowifmi et l'entreprise, ainsi qu'à la prestation des
              services. À la demande de l'entreprise ou du Voyageur, nous pourrions également
              partager ces informations avec des tiers mandatés par l'entreprise pour fournir des
              services d'assistance.
            </p>
            <h4>4.8 Prestataires de services</h4>
            <p>
              Nous partageons des informations personnelles avec des prestataires de services
              affiliés et non affiliés, y compris leurs prestataires de services, pour nous aider à
              gérer nos activités et répondre à leurs propres exigences de conformité. Cela inclut
              leur assistance dans les domaines suivants : (i) vérification de votre identité ou
              authentification de vos pièces d'identité, (ii) comparaison d'informations avec des
              bases de données publiques, (iii) vérifications des antécédents judiciaires,
              prévention des fraudes, enquêtes de sécurité et évaluations des risques, (iv)
              développement, maintenance et débogage des produits, (v) facilitation de la fourniture
              des services Gowifmi via des plateformes et des outils logiciels tiers (par exemple,
              via l'intégration à nos API), (vi) fourniture d'assistance aux utilisateurs, de
              services publicitaires ou de paiement, (vii) offre de services supplémentaires que
              vous choisissez, (viii) traitement, gestion ou évaluation des déclarations de dommages
              ou des déclarations similaires, ou (ix) consultation, examen et analyse des
              communications sur la plateforme Gowifmi à certaines fins, comme la détection
              d'activités illégales, y compris des preuves d'exploitation d'enfants. Ces
              prestataires sont contractuellement tenus de garantir la protection de vos
              informations personnelles et n'y ont accès que pour effectuer les tâches mentionnées
              ci-dessus. Il est possible que d'autres Membres utilisent des services autres que
              Gowifmi pour traiter vos données, tels que des logiciels de gestion des e-mails ou des
              réservations. Ces services échappent au contrôle de Gowifmi et sont soumis aux lois
              applicables dans le monde entier, avec des niveaux variables de protection des
              données.
            </p>
            <h4>4.9 Cession d'entreprise</h4>
            <p>
              Si Gowifmi entreprend ou participe à une fusion, acquisition, restructuration, cession
              d'actifs, ou se trouve impliqué dans une procédure de faillite ou d'insolvabilité, la
              société sera en droit de vendre, céder ou partager tout ou partie de ses actifs, y
              compris vos informations en lien avec ladite transaction ou en prévision de celle-ci
              (par exemple, dans le cadre de la due diligence). Dans une telle éventualité, nous
              vous informerons avant le transfert de vos informations personnelles, et ces données
              seront régies par une autre Politique de confidentialité.
            </p>
            <h4>4.10 Sociétés affiliées</h4>
            <p>
              Afin de soutenir la fourniture, l'intégration, la promotion et l'amélioration de la
              plateforme Gowifmi, des services de paiement et des services de nos affiliés, nous
              pouvons partager des informations personnelles au sein de notre groupe d'entreprises
              liées par une propriété ou un contrôle en commun. Voici quelques exemples :
            </p>
            <p>
              Partage avec Gowifmi, LLC. Même en dehors des États-Unis, vos informations peuvent
              être partagées avec Gowifmi, LLC, responsable de l'infrastructure technique de la
              plateforme Gowifmi.
            </p>
            <h4>5. Autres informations importantes</h4>
            <h4>5.1 Partenaires et intégrations tiers.</h4>
            <p>
              Certaines parties de Gowifmi peuvent être associées à des services tiers. Gowifmi n'a
              ni la propriété ni le contrôle sur ces tiers. Lorsque vous interagissez avec ces
              services tiers et choisissez de les utiliser, vous fournissez des informations vous
              concernant. Votre utilisation de ces services est régie par les politiques de
              confidentialité de ces fournisseurs, notamment les{' '}
              <a href='https://www.google.com/intl/fr/help/terms_maps/' target='_blanck'>
                Conditions d'utilisation supplémentaires de Google Maps/Google Earth
              </a>
              ,{' '}
              <a href='https://policies.google.com/privacy?hl=fr-CA' target='_blanck'>
                la Politique de confidentialité de Google (consultez ici pour plus d'informations
                sur la manière dont Google
              </a>{' '}
              utilise les informations),{' '}
              <a
                href='https://www.paypal.com/ca/legalhub/privacy-full?locale.x=fr_CA'
                target='_blanck'
              >
                la Déclaration de confidentialité de PayPal
              </a>{' '}
              et la{' '}
              <a
                href='https://stripe.com/fr/privacy
'
                target='_blanck'
              >
                Politique de confidentialité de Stripe
              </a>
              .
            </p>
            <h4>6. Vos droits</h4>
            <p>
              Vous avez le droit d'exercer l'un des droits énoncés dans cette section conformément à
              la législation en vigueur. Nous pourrions vous solliciter pour confirmer votre
              identité avant de procéder à la prise en compte de votre demande.
            </p>
            <h4>6.1 Gestion de vos informations</h4>
            <p>
              Vous avez la possibilité de consulter et de mettre à jour certaines de vos
              informations en accédant aux paramètres de votre compte. Si vous avez associé votre
              compte Gowifmi à un service tiers, comme Facebook ou Google, vous pouvez ajuster vos
              paramètres et vous déconnecter de ce service en accédant aux réglages de votre compte.
              Il vous incombe de maintenir vos informations personnelles à jour.
            </p>
            <h4>6.2 Accès aux données et portabilité</h4>
            <p>
              Dans certaines régions, la législation en vigueur peut vous accorder le droit de
              solliciter des copies de vos données personnelles ou des détails sur notre traitement
              de ces données. Vous pourriez également avoir le droit de demander des copies des
              informations personnelles que vous nous avez fournies, présentées dans un format
              structuré, couramment utilisé et lisible par une machine. De plus, vous pourriez avoir
              la possibilité de demander le transfert de ces informations à un autre prestataire de
              services, lorsque cela est techniquement réalisable.
            </p>
            <h4>6.3 Suppression des données</h4>
            <p>
              Dans certaines régions, vous avez la possibilité de solliciter la suppression de vos
              données personnelles. Il est important de noter que si vous faites une demande de
              suppression de vos informations personnelles, ou si votre compte est suspendu, résilié
              ou fermé de manière volontaire :
            </p>
            <ul>
              <li>
                Nous sommes autorisés à conserver les informations personnelles nécessaires à la
                défense de nos intérêts légitimes, tels que la détection et la prévention de la
                fraude, ainsi que l'amélioration de la sécurité. Par exemple, en cas de suspension
                d'un compte Gowifmi pour des raisons de fraude ou de sécurité, certaines
                informations liées à ce compte peuvent être conservées pour empêcher le titulaire du
                compte de créer un nouveau compte Gowifmi ultérieurement.
              </li>
              <li>
                Nous pouvons également conserver et utiliser vos informations personnelles dans la
                mesure requise pour respecter nos obligations légales. À titre d'exemple, Gowifmi et
                Gowifmi Payments peuvent retenir certaines de vos informations pour répondre aux
                obligations fiscales, aux exigences de reporting légal et aux procédures d'audit.
              </li>
              <li>
                Les informations que vous avez partagées avec d'autres utilisateurs, telles que des
                commentaires ou des publications sur un forum, peuvent rester publiques sur la
                plateforme Gowifmi même après la suppression de votre compte.
              </li>
              <li>
                Bien que nous prenions des mesures pour protéger les informations contre toute perte
                accidentelle ou malveillante, il est possible que des copies résiduelles de vos
                données personnelles ne soient pas immédiatement supprimées de nos systèmes de
                sauvegarde, mais cette conservation est limitée dans le temps.
              </li>
            </ul>
            <h4>7. Sécurité</h4>
            <p>
              Même si aucune organisation ne peut assurer une sécurité absolue, nous appliquons et
              actualisons en permanence nos dispositifs de sécurité administratifs, techniques et
              physiques afin de prévenir l'accès, la perte, la destruction ou la modification non
              autorisés ou illicites de vos informations.
            </p>
            <h4>8. Modifications de la présente politique de confidentialité</h4>
            <p>
              Nous nous réservons le droit de modifier la présente Politique de confidentialité à
              tout moment conformément à la législation en vigueur. En cas de modification, la
              version révisée de la Politique de confidentialité sera publiée, et la « Date de
              dernière mise à jour » au début de celle-ci sera actualisée. Pour des modifications
              substantielles, une notification par e-mail sera envoyée au moins trente (30) jours
              avant la date d'entrée en vigueur de la nouvelle version. Si vous n'acceptez pas les
              nouvelles conditions de la Politique de confidentialité, nous vous encourageons à
              supprimer votre compte. Si votre compte n'est pas supprimé avant la date d'entrée en
              vigueur de la nouvelle Politique de confidentialité, votre accès continu et votre
              utilisation de la plateforme Gowifmi seront alors soumis aux dispositions de la
              nouvelle Politique de confidentialité.
            </p>
            <h4>9. Coordonnées Gowifmi</h4>
            <p>
              Pour toute question ou réclamation concernant la présente Politique de confidentialité
              ou le traitement des informations personnelles par Gowifmi : Veuillez consulter notre
              centre d’aide ou envoyez-nous un e-mail à contact@gowifmi.
            </p>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}
export default PrivacyPolicy
