import styles from '../../../../../../../css/modehotes/annonce/details/bottom_form.module.css'
import LoadingPoint from '../../../../../../LoadingPoint'

const BottomForm = ({ disabled, loading, handleReset, handleSubmit }) => {
  return (
    <div className={styles.footer}>
      <button type='button' onClick={handleReset} className={styles.retour}>
        Annuler
      </button>
      {loading ? (
        <button disabled type='button' className={styles.btn_loading}>
          <LoadingPoint />
        </button>
      ) : (
        <button
          type='submit'
          disabled={disabled}
          className={disabled ? `${styles.next} ${styles.disable}` : styles.next}
          onClick={handleSubmit}
        >
          Enregistrer
        </button>
      )}
    </div>
  )
}

export default BottomForm
