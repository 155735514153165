import styles from '../../../css/details/equipement.module.css'
import CheckAmenitieSvg from './components/CheckAmenitieSvg'

const RoomeAndLinen = ({ hotel }) => {
  const { amenities } = hotel

  return (
    <div className={styles.container_equip}>
      <h4>Chambre et linge</h4>
      {amenities?.includes('6') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Lave-linge (Gratuit) dans le logement</p>
        </div>
      )}
      {amenities?.includes('7') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Sèche-linge (Gratuit) dans le logement</p>
        </div>
      )}
      {amenities?.includes('26') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Cintres</p>
        </div>
      )}
      {amenities?.includes('27') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Draps</p>
        </div>
      )}
      {amenities?.includes('11') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Fer à repasser</p>
        </div>
      )}
      {amenities?.includes('70') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Moustiquaire</p>
        </div>
      )}
      {amenities?.includes('71') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Stores</p>
        </div>
      )}
      {amenities?.includes('72') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Coffre-fort</p>
        </div>
      )}
      {amenities?.includes('28') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Espace de rangement pour les vêtements : armoire, commode, placard et dressing</p>
        </div>
      )}
    </div>
  )
}

export default RoomeAndLinen
