import styles from '../../css/booking/navbar_booking.module.css'

const NavbarResevation = ({ handleRedirect, confirmBooking }) => {
  return (
    <div className={styles.navbar}>
      <div>
        <button onClick={() => handleRedirect()}>
          <svg
            viewBox='0 0 32 32'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            role='presentation'
            focusable='false'
            style={{
              display: 'block',
              height: '16px',
              width: '16px',
              stroke: 'currentcolor',
              strokeWidth: '3',
              fill: 'none',
              overflow: 'visible',
            }}
          >
            <g>
              <path d='m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932'></path>
            </g>
          </svg>
        </button>
      </div>
      <h1>{confirmBooking ? 'Confirmer votre réservation' : 'Demande de réservation'}</h1>
      <div></div>
    </div>
  )
}

export default NavbarResevation
