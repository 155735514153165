import { useState } from 'react'
import globalStyle from '../../../css/becomeHostes/globalStyle.module.css'
import styles from '../../../css/becomeHostes/typeProperty.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import axios from 'axios'
import LoadingPoint from '../../LoadingPoint'
import ErrorServer from '../errorServer/ErrorServer'
import useAddHome from '../../../hooks/useAddHome'
import { SATATUS_ANNONCE } from '../../../enums/logement/status.enums'
import { TYPE_LOGEMENT } from '../../../enums/logement/type_logement.enums'
import HeaderForm from '../globals/HeaderForm'
import { useQuery } from 'react-query'

const Form = () => {
  const [loading, setLoading] = useState(false)
  const [typelogement, setTypelogement] = useState('')
  const [serverError, setServerError] = useState(false)
  const { updateData } = useAddHome(setLoading, setServerError)

  const history = useHistory()
  const location = useLocation()
  const page = location.pathname.split('/')[2]
  const id = location.pathname.split('/')[3]

  const { isLoading, error } = useQuery(`get-hotel-on-creation-${id}}`, () =>
    axios.get(`/hotel/oneAnnonce/${id}`).then(res => {
      setTypelogement(res.data.accommodation_type)
      if (res.data.status !== SATATUS_ANNONCE.IN_PROGRESS) {
        history.push('/hosting')
      }
    })
  )

  const handleSubmit = () => {
    setLoading(true)
    const datas = {
      redirect_url: page,
      accommodation_type: typelogement,
    }
    updateData(datas, '/become-a-host/location')
  }
  const array = [
    {
      name: 'Logement entier',
      value: TYPE_LOGEMENT.ENTIRE_HOME,
      descr:
        'Les voyageurs disposent du logement dans son intégralité. Le logement entier comprend généralement une chambre, une salle de bain et une cuisine.',
    },
    {
      name: 'Chambre privée',
      value: TYPE_LOGEMENT.PRIVATE_ROOM,
      descr:
        "Les voyageurs disposent d'une chambre à coucher qui leur est réservée. Les autres parties du logement peuvent être partagées.",
    },
    {
      name: 'Chambre partagée',
      value: TYPE_LOGEMENT.SHARED_ROOM,
      descr:
        "Les voyageurs dorment dans une chambre ou une partie du logement commune qu'ils peuvent partager avec d'autres.",
    },
  ]

  if (error || serverError) {
    return <ErrorServer />
  }

  return (
    <form onSubmit={handleSubmit} className={globalStyle.left}>
      <HeaderForm step={3} />
      <div className={globalStyle.block_center}>
        <div className={globalStyle.block_form}>
          {isLoading ? (
            <LoadingPoint />
          ) : (
            <div className={globalStyle.annimcontent}>
              <div className={styles.block_checkbox}>
                {array.map((item, i) => (
                  <label key={i}>
                    <input
                      type='radio'
                      value={item.value}
                      checked={typelogement === item.value}
                      onChange={e => setTypelogement(e.target.value)}
                    />
                    <div className={styles.block}>
                      <div className={styles.block_content}>
                        <h2>{item.name}</h2>
                        <p>{item.descr}</p>
                      </div>
                    </div>
                  </label>
                ))}
              </div>
            </div>
          )}{' '}
        </div>
      </div>
      <div className={globalStyle.step_buttons}>
        <button
          type='button'
          onClick={() => history.push(`/become-a-host/type-property-details/${id}`)}
          className={globalStyle.retour}
        >
          Retour
        </button>
        {loading ? (
          <button disabled className={globalStyle.loading}>
            <LoadingPoint />
          </button>
        ) : (
          <button
            type='button'
            onClick={handleSubmit}
            disabled={!typelogement}
            className={
              !typelogement ? `${globalStyle.next} ${globalStyle.disable}` : globalStyle.next
            }
          >
            Suivant
          </button>
        )}
      </div>
    </form>
  )
}

export default Form
