import { useState } from 'react'
import styles from '../../../../../../../../css/modehotes/annonce/details/desactivate.module.css'
import axios from 'axios'
import { useLocation } from 'react-router'
import LoadingPoint from '../../../../../../../LoadingPoint'

const Block2 = ({ setActive, raison1, raison2, setRaison2 }) => {
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const id = location.pathname.split('/')[4]

  const handleSubmit = () => {
    setLoading(true)

    const data = {
      raison_1: raison1,
      message: raison2,
    }

    axios
      .patch(`/hotel/deactivate/${id}`, data)
      .then(() => {
        setActive(3)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        alert('Un problème est sur venu au nuveau de serveur')
      })
  }

  return (
    <>
      <div className={styles.m_body}>
        <div>
          <h3>Pourquoi désactivez-vous cette annonce ?</h3>
          <p>
            En désactivant votre annonce, votre propriété ne sera plus visible sur la plateforme
            Gowifmi et les voyageurs ne pourront plus réserver votre logement. De plus, votre
            annonce ne sera plus affichée dans les résultats de recherche des voyageurs.
          </p>
        </div>
        <div className={styles.message_content}>
          <textarea value={raison2} onChange={e => setRaison2(e.target.value)}></textarea>
        </div>
      </div>
      <div className={styles.button_action}>
        <button className={styles.retour} onClick={() => setActive(1)}>
          Retour
        </button>
        {loading ? (
          <button className={`${styles.next} ${styles.disabled}`} disabled>
            <LoadingPoint />
          </button>
        ) : (
          <button
            className={!raison2 ? `${styles.next} ${styles.disabled}` : styles.next}
            disabled={!raison2}
            onClick={handleSubmit}
          >
            Désactiver
          </button>
        )}
      </div>
    </>
  )
}

export default Block2
