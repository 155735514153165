import styles from '../../../../../css/modehotes/annonce/details/securiteVoyageur.module.css'
import Checkbox from '../../../../ui/Checkbox'

const Camera = ({ cameras, setCameras }) => {
  return (
    <div className={styles.container_equip}>
      <h4>Informations sur le logement</h4>
      <div className={styles.checkbox_container}>
        <div>
          <label htmlFor='cameras'>
            Caméras de surveillance ou appareils d'enregistrement audio
          </label>
          <p>
            Le logement dispose d'une caméra de surveillance ou d'un système d'enregistrement
            capable d'enregistrer ou d'envoyer des vidéos, du son ou des images fixes. Gowifmi exige
            que les hôtes informent les voyageurs de tout système de vidéosurveillance ou autre
            système d'enregistrement installé dans une partie commune, même s'il n'est pas activé ou
            branché. Gowifmi interdit l'utilisation de caméras de surveillance ou de systèmes
            d'enregistrement dans les parties du logement privées, comme les salles de bain, les
            chambres à coucher ou espaces de couchage.
          </p>
        </div>
        <div>
          <Checkbox
            id='cameras'
            value={cameras}
            checked={cameras}
            onChange={() => setCameras(!cameras)}
          />
        </div>
      </div>
    </div>
  )
}

export default Camera
