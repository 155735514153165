import { useState } from 'react'
import globalStyle from '../../../css/becomeHostes/globalStyle.module.css'
import styles from '../../../css/becomeHostes/typeProperty.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import axios from 'axios'
import LoadingPoint from '../../LoadingPoint'
import ErrorServer from '../errorServer/ErrorServer'
import useAddHome from '../../../hooks/useAddHome'
import { SATATUS_ANNONCE } from '../../../enums/logement/status.enums'
import HeaderForm from '../globals/HeaderForm'
import { HOST_STATUS_ACTIVITY } from '../../../enums/logement/host_status_activity.enums'
import { useQuery } from 'react-query'
import { propertyOptions } from './options'

const Form = ({ user }) => {
  const [loading, setLoading] = useState(false)
  const [typeproperty, setTypeproperty] = useState('')

  const [serverError, setServerError] = useState(false)
  const { updateData } = useAddHome(setLoading, setServerError)
  const defaultLangues = ['2']

  const history = useHistory()
  const location = useLocation()
  const page = location.pathname.split('/')[2]
  const id = location.pathname.split('/')[3]
  const Query = window.location.search
  const params = new URLSearchParams(Query)
  const referrerId = params.get('referrer')

  const { isLoading, error } = useQuery(`get-hotel-on-creation-${id}}`, () => {
    if (id) {
      axios.get(`/hotel/oneAnnonce/${id}`).then(res => {
        setTypeproperty(res.data.property_type_group)
        if (res.data.status !== SATATUS_ANNONCE.IN_PROGRESS) {
          history.push('/hosting')
        }
      })
    }
  })

  const handleSubmit = () => {
    setLoading(true)
    const datas = {
      referrer_id: referrerId,
      redirect_url: page,
      property_type_group: typeproperty,
      languages: defaultLangues,
      about: {
        host_activity_status: user?.isCompany_account
          ? HOST_STATUS_ACTIVITY.PROFESSIONAL
          : HOST_STATUS_ACTIVITY.PARTICULAR,
      },
    }
    if (id) {
      updateData(datas, '/become-a-host/type-property-details')
    } else {
      axios
        .post(`/hotel`, datas)
        .then(res => {
          history.push(`/become-a-host/type-property-details/${res.data._id}`)
        })
        .catch(() => {
          setLoading(false)
          setServerError(true)
        })
    }
  }

  if (error || serverError) {
    return <ErrorServer />
  }

  return (
    <div className={globalStyle.left}>
      <HeaderForm step={1} />
      <div className={globalStyle.block_center}>
        <div className={globalStyle.block_form}>
          {isLoading ? (
            <LoadingPoint />
          ) : (
            <div className={globalStyle.annimcontent}>
              <div className={styles.block_checkbox}>
                {propertyOptions.map((item, i) => (
                  <label key={i}>
                    <input
                      type='radio'
                      value={item.value}
                      checked={typeproperty === item.value}
                      onChange={e => setTypeproperty(e.target.value)}
                    />
                    <div className={styles.block}>
                      <div>{item.icon}</div>
                      <div className={styles.block_content}>
                        <h2>{item.name}</h2>
                      </div>
                    </div>
                  </label>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={globalStyle.step_buttons}>
        <button
          type='button'
          onClick={() => history.push(user.isHost ? `/become-a-host` : `/host/homes`)}
          className={globalStyle.retour}
        >
          Retour
        </button>
        {loading ? (
          <button disabled className={globalStyle.loading}>
            <LoadingPoint />
          </button>
        ) : (
          <button
            type='button'
            onClick={handleSubmit}
            disabled={!typeproperty}
            className={
              !typeproperty ? `${globalStyle.next} ${globalStyle.disable}` : globalStyle.next
            }
          >
            Suivant
          </button>
        )}
      </div>
    </div>
  )
}

export default Form
