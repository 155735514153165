import styles from '../../../../../../css/modehotes/annonce/details/information.module.css'
import FreeModeAnnonce from '../../../../default_components/freemode/FreeModeAnnonce'
import Tooltip from '../../../../../ui/Tooltip'
import { freeModeDays } from '../../../../../../config/config'
import { useCalcDiffTwoDate } from '../../../../../../hooks/useCalcDiffTwoDate'

const Information = ({ annonce }) => {
  const { total_days } = useCalcDiffTwoDate(annonce?.gowifmi_publish_date, new Date())

  if (total_days >= freeModeDays) return null

  return (
    <div className={styles.block}>
      <div className={styles.head}>
        <h3>FreeMode</h3>
        <Tooltip title='FreeMode'>
          <a href='/free-mode-host' target='_blank' className={styles.bonne_affaire}>
            <svg
              viewBox='0 0 48 48'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'
              role='presentation'
              focusable='false'
            >
              <g stroke='none'>
                <path
                  d='M24.556 8H9a1 1 0 0 0-.993.883L8 9v15.556a1 1 0 0 0 .206.608l.087.1 17.213 17.213a1 1 0 0 0 1.32.083l.094-.083L42.477 26.92a1 1 0 0 0 .083-1.32l-.083-.094L25.263 8.293a1 1 0 0 0-.575-.284L24.556 8z'
                  fillOpacity='.2'
                ></path>
                <path d='M24.556 4A5 5 0 0 1 27.9 5.282l.192.182 17.213 17.214a5 5 0 0 1 .172 6.89l-.172.18L29.75 45.306a5 5 0 0 1-6.89.172l-.181-.172L5.464 28.092a5 5 0 0 1-1.457-3.271L4 24.556V9a5 5 0 0 1 4.783-4.995L9 4h15.556zm0 2H9a3 3 0 0 0-2.995 2.824L6 9v15.556a3 3 0 0 0 .743 1.977l.136.145L24.092 43.89a3 3 0 0 0 4.099.135l.144-.135L43.89 28.335a3 3 0 0 0 .135-4.1l-.135-.143L26.678 6.879a3 3 0 0 0-1.924-.872L24.556 6zM13 10a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z'></path>
              </g>
            </svg>
          </a>
        </Tooltip>
      </div>
      <div className={styles.main}>
        <FreeModeAnnonce annonce={annonce} />
      </div>
    </div>
  )
}

export default Information
