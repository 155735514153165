import styles from '../../../css/details/equipement.module.css'
import CheckAmenitieSvg from './components/CheckAmenitieSvg'

const Kitchen = ({ hotel }) => {
  const { amenities } = hotel

  return (
    <div className={styles.container_equip}>
      <h4>Cuisine et salle à manger</h4>
      {amenities?.includes('4') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Cuisine : Espace où les voyageurs peuvent cuisiner</p>
        </div>
      )}
      {amenities?.includes('34') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Réfrigérateur</p>
        </div>
      )}
      {amenities?.includes('35') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Four à micro-ondes</p>
        </div>
      )}
      {amenities?.includes('36') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Équipements de cuisine de base : Casseroles et poêles, huile, sel et poivre</p>
        </div>
      )}
      {amenities?.includes('56') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Vaisselle et couverts : Bols, baguettes, assiettes, tasses, etc.</p>
        </div>
      )}
      {amenities?.includes('37') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Congélateur</p>
        </div>
      )}
      {amenities?.includes('38') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Cuisinière à gaz</p>
        </div>
      )}
      {amenities?.includes('41') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Four</p>
        </div>
      )}
      {amenities?.includes('42') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Cafetière : Nespresso</p>
        </div>
      )}
      {amenities?.includes('57') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Verres à vin</p>
        </div>
      )}
      {amenities?.includes('43') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Café</p>
        </div>
      )}
      {amenities?.includes('93') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Plaques de cuisson</p>
        </div>
      )}
      {amenities?.includes('94') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Ustensiles de barbecue</p>
        </div>
      )}
      {amenities?.includes('95') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Machine à pain</p>
        </div>
      )}
      {amenities?.includes('96') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Blender</p>
        </div>
      )}
      {amenities?.includes('97') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Table à manger</p>
        </div>
      )}
      {amenities?.includes('98') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Vaisselle et couverts</p>
        </div>
      )}
      {amenities?.includes('99') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Lave-vaisselle</p>
        </div>
      )}
      {amenities?.includes('100') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Bouilloire électrique</p>
        </div>
      )}
      {amenities?.includes('101') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Mini réfrigérateur</p>
        </div>
      )}
      {amenities?.includes('102') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Cuiseur à riz</p>
        </div>
      )}
      {amenities?.includes('103') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Cuisinière</p>
        </div>
      )}
      {amenities?.includes('104') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Cuisinière à gaz</p>
        </div>
      )}
      {amenities?.includes('105') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Compacteur de déchets</p>
        </div>
      )}
    </div>
  )
}

export default Kitchen
