import styles from '../../../css/details/equipement.module.css'
import CheckAmenitieSvg from './components/CheckAmenitieSvg'

const Exterior = ({ hotel }) => {
  const { amenities } = hotel

  return (
    <div className={styles.container_equip}>
      <h4>Extérieur</h4>
      {amenities?.includes('5') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Bord de mer</p>
        </div>
      )}
      {amenities?.includes('62') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Patio ou balcon</p>
        </div>
      )}
      {amenities?.includes('45') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>
            Jardin Clôture intégrale : Un espace ouvert du logement généralement recouvert d'herbe
          </p>
        </div>
      )}
      {amenities?.includes('46') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Mobilier d'extérieur</p>
        </div>
      )}
      {amenities?.includes('47') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Espace repas en plein air</p>
        </div>
      )}
      {amenities?.includes('48') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Barbecue</p>
        </div>
      )}
      {amenities?.includes('109') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Vélos</p>
        </div>
      )}
      {amenities?.includes('63') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Brasero</p>
        </div>
      )}
      {amenities?.includes('108') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Hamac</p>
        </div>
      )}
      {amenities?.includes('107') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Kayak</p>
        </div>
      )}
      {amenities?.includes('106') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Cuisine extérieure</p>
        </div>
      )}
    </div>
  )
}

export default Exterior
