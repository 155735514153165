import { useEffect, useContext, useState } from 'react'
import styles from '../../../../css/modehotes/reservation/listeReservation.module.css'
import { useHistory, Link } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { User } from '../../../../context/UserContext'
import LoadingPoint from '../../../../components/LoadingPoint'
import ValidSvg from '../../../../components/svgs/ValidSvg'
import Header from '../../../../components/hote_mode/bookings/Header'
import HostModeLayout from '../../../../layouts/HostModeLayout'
import ErrorServer from '../../../errors/ErrorServer'
import { useQuery } from 'react-query'
import { Helmet } from 'react-helmet-async'
import ReservationStatus from '../../../../components/hote_mode/default_components/ReservationStatus'
import Pagination from '../../../../components/ui/Pagination'

const ListAll = () => {
  const [searchParams, setSearchParams] = useState(window.location.search)

  const user = useContext(User)
  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
    if (user === null) {
      history.push('/')
    }
    const unlisten = history.listen(location => {
      setSearchParams(location.search)
    })

    return () => {
      unlisten()
    }
  }, [history, user])

  const fetchData = async () => {
    const { data } = await axios.get(`/host_reservations/all_reservation${searchParams}`)
    return data
  }

  const { data = {}, isLoading, error } = useQuery([`all_reservation`, searchParams], fetchData)

  if (error) return <ErrorServer />

  return (
    <HostModeLayout>
      <Helmet>
        <title>Réservations - Gowifmi</title>
      </Helmet>
      <div className={styles.voyage_container}>
        <Header data={data} />
        {isLoading ? (
          <div className={styles.container_load_liste}>
            <LoadingPoint />
          </div>
        ) : (
          <>
            {data?.totalRecords < 1 ? (
              <div className={styles.no_result}>
                <h2>Aucun résultat trouvé</h2>
                <p>Veuillez essayer de sélectionner différents mots-clés ou filtres.</p>
              </div>
            ) : (
              <div className={styles.container_arry}>
                <table className={styles.customers}>
                  <thead>
                    <tr>
                      <th className={styles.image}>Annonce</th>
                      <th>Date d'arrivée</th>
                      <th>Date de départ</th>
                      <th>Statut</th>
                      <th>Payer en ligne</th>
                      <th>Référence</th>
                      <th>Voyageur</th>
                      <th>Date réservation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.reservations.map(item => (
                      <tr key={item._id}>
                        <td className={styles.image}>
                          <Link to={`/hosting/reservations/details/${item._id}`}>
                            <div>
                              <div className={styles.card}>
                                <img src={item.hotel?.images[0].url} alt='couverture' />
                              </div>
                            </div>
                            <p>{item.hotel?.title}</p>
                          </Link>
                        </td>
                        <td className={styles.rows}>
                          {moment(item.reservation_infos?.checkin).format('ll')}
                        </td>
                        <td className={styles.rows}>
                          {moment(item.reservation_infos?.checkout).format('ll')}
                        </td>
                        <td className={styles.statut}>
                          <ReservationStatus reservation={item} />
                        </td>
                        <td className={styles.rows}>
                          <div className={styles.demande}>
                            <ValidSvg state={item.pay_online} />
                            <span>{item.pay_online ? 'Oui' : 'Non'}</span>
                          </div>
                        </td>
                        <td className={styles.rows}>{item.reservation_infos?.reference}</td>
                        <td className={styles.rows}>{item.user_id?.first_name}</td>
                        <td className={styles.rows}>{moment(item.createdAt).format('ll')}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination data={data} />
              </div>
            )}
          </>
        )}
      </div>
    </HostModeLayout>
  )
}

export default ListAll
