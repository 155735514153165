import styles from '../../../css/traveler/travel/list_reservation.module.css'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import moment from 'moment'
import LoadingPoint from '../../LoadingPoint'
import { STATUS_BOOKING } from '../../../enums/booking/status.enums'
import PaginationTravelerReservation from '../../ui/PaginationTravelerReservation'

const ListReservation = ({ loading, reservation, setPage }) => {
  const location = useLocation()
  const path = location.pathname.split('/')[2]

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.container_load_liste}>
          <LoadingPoint />
        </div>
      ) : (
        <>
          {reservation.total_data < 1 ? (
            <div className={styles.no_result}>
              {path === 'all' ? (
                <>
                  <h2>Vous n'avez pas de réservations</h2>
                  <Link to='/'>Lancer une recherche</Link>
                </>
              ) : (
                <>
                  <h2>Aucun résultat trouvé</h2>
                  <p>Veuillez essayer de sélectionner différents mots-clés ou filtres.</p>
                </>
              )}
            </div>
          ) : (
            <div>
              <div className={styles.wrapper}>
                {reservation?.results.map(item => (
                  <Link
                    to={`/reservations/details/${item._id}`}
                    key={item._id}
                    className={styles.card}
                  >
                    <div className={styles.card_img}>
                      <img src={item.hotel?.images[0].url} alt='couverture' />
                    </div>
                    <div className={styles.card_body}>
                      <h2>{item.hotel?.title}</h2>
                      <div className={styles.card_footer}>
                        <div className={styles.card_date}>
                          {path === 'all' && (
                            <p>
                              Statut · {item.status === STATUS_BOOKING.REQUEST && 'Demande'}
                              {item.status === STATUS_BOOKING.FUTURE && 'À venir'}
                              {item.status === STATUS_BOOKING.IN_PROGRESS && 'En cours'}
                              {item.status === STATUS_BOOKING.COMPLETE && 'Terminée'}
                              {item.status === STATUS_BOOKING.CANCELLED && 'Annulée'}
                            </p>
                          )}
                          <p>
                            {moment(item.reservation_infos?.checkin).format('ll')} -{' '}
                            {moment(item.reservation_infos?.checkout).format('ll')}
                          </p>
                          <p>Référence · {item.reservation_infos?.reference}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
              <PaginationTravelerReservation data={reservation} setPage={setPage} />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default ListReservation
