import styles from '../../../css/details/equipement.module.css'
import CheckAmenitieSvg from './components/CheckAmenitieSvg'

const Parking = ({ hotel }) => {
  const { amenities } = hotel

  return (
    <div className={styles.container_equip}>
      <h4>Parking et installations</h4>
      {amenities?.includes('2') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Parking gratuit sur place</p>
        </div>
      )}
      {amenities?.includes('112') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Parking payant sur place</p>
        </div>
      )}
      {amenities?.includes('50') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Parking gratuit dans la rue</p>
        </div>
      )}
      {amenities?.includes('114') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Parking payant dans la rue</p>
        </div>
      )}
      {amenities?.includes('51') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Piscine Privé</p>
        </div>
      )}
      {amenities?.includes('110') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Ascenseur</p>
        </div>
      )}
      {amenities?.includes('111') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Station de recharge pour véhicules électriques</p>
        </div>
      )}
      {amenities?.includes('15') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Salle de sport</p>
        </div>
      )}
      {amenities?.includes('14') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Jacuzzi</p>
        </div>
      )}
      {amenities?.includes('113') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Stationnement payant à l'extérieur de la propriété</p>
        </div>
      )}
      {amenities?.includes('3') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Piscine</p>
        </div>
      )}
      {amenities?.includes('115') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Salon privé</p>
        </div>
      )}
      {amenities?.includes('116') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Sauna</p>
        </div>
      )}
      {amenities?.includes('117') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Logement de plain-pied</p>
        </div>
      )}
    </div>
  )
}

export default Parking
