import styles from '../../../../css/search_room_result/loadingCardList.module.css'

const LoadingCard = () => {
  const array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p className={styles.skeleton}></p>
        <p className={`${styles.nombre} ${styles.skeleton}`}></p>
      </div>
      {array.map(index => (
        <div key={index} className={styles.list_hotel}>
          <div className={styles.profile_block}>
            <div className={`${styles.profile_image} ${styles.skeleton}`}>{''}</div>
            <div className={`${styles.show_more} ${styles.skeleton}`}>{''}</div>
          </div>
          <div className={styles.card_container}>
            <div className={`${styles.card_image} ${styles.skeleton}`}></div>
            <div className={styles.content_block}>
              <div className={styles.head_content}>
                <div className={styles.head_content_left}>
                  <h4 className={`${styles.hotel_name} ${styles.skeleton}`}>{''}</h4>
                  <h4 className={styles.skeleton}>{''}</h4>
                </div>
                <div className={`${styles.hotel_comment} ${styles.skeleton}`}>{''}</div>
              </div>
              <div className={styles.price_block}>
                <h4 className={styles.skeleton}>{''}</h4>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default LoadingCard
