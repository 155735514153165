import styles from '../../../css/details/asavoir.module.css'
import Modal from '../../ui/Modal'
import CloseButton from '../../ui/CloseButton'
import useModalByUrl from '../../../hooks/useModalByUrl'

const Asavoir = ({ hotel }) => {
  const modalValue = 'REGULATION'
  const { modalState, handleOpenModal, handleCloseModal } = useModalByUrl(modalValue)

  const array = [
    { value: 'clés_remises_par_hôte', descr: "Clés remises par l'hôte" },
    { value: 'personnel_de_immeuble', descr: "Arrivée autonome avec personnel de l'immeuble" },
    { value: 'boîte_à_clé_sécurisée', descr: 'Arrivée autonome avec boîte à clé sécurisée' },
    { value: 'serrure_connectée', descr: 'Arrivée autonome avec serrure connectée' },
    { value: 'serrure_numérique', descr: 'Arrivée autonome avec serrure numérique' },
    {
      value: 'autre',
      descr: 'Les voyageurs peuvent utiliser une autre méthode non énumérée ci-dessus',
    },
  ]
  return (
    <>
      <div className=''>
        <h4>Pour votre information</h4>
        <div className={styles.block_content}>
          <p>
            {hotel.details?.traveler} voyageur{hotel.details?.traveler > 1 ? 's' : ''} maximum
          </p>
        </div>
        <div className={styles.block_content}>
          <p>
            Arrivée : {!hotel.regulations?.check_in_1 ? 'Flexibles' : hotel.regulations?.check_in_1}{' '}
            - {!hotel.regulations?.check_in_2 ? 'Flexibles' : hotel.regulations?.check_in_2}
          </p>
        </div>
        <div className={styles.block_content}>
          <p>
            Départ : {!hotel.regulations?.check_out ? 'Flexibles' : hotel.regulations?.check_out}
          </p>
        </div>
        {array
          .filter(item => item.value === hotel.traveler_information?.arrival_instructions)
          .map(item => (
            <div key={item.value}>
              {item.value !== 'autre' && (
                <div className={styles.block_content}>
                  <p>{item.descr}</p>
                </div>
              )}
            </div>
          ))}
        {!hotel.regulations?.accept_children && (
          <div className={styles.block_content}>
            <p className={styles.no}>Ne convient pas aux enfants</p>
          </div>
        )}
        {!hotel.regulations?.accept_babies && (
          <div className={styles.block_content}>
            <p className={styles.no}>Ne convient pas aux bébés</p>
          </div>
        )}
        {hotel.regulations?.smoking_allowed ? (
          <div className={styles.block_content}>
            <p>Les fumeurs sont acceptés</p>
          </div>
        ) : (
          <div className={styles.block_content}>
            <p>Non fumeur</p>
          </div>
        )}
        {hotel.regulations?.accept_pets ? (
          <div className={styles.block_content}>
            <p>Les animaux sont acceptés</p>
          </div>
        ) : (
          <div className={styles.block_content}>
            <p className={styles.no}>Pas d'animaux</p>
          </div>
        )}
        {!hotel.regulations?.accept_events && (
          <div className={styles.block_content}>
            <p className={styles.no}>Pas de fête ni de soirée</p>
          </div>
        )}

        {hotel.traveler_information?.arrival_instructions_message ||
        hotel.regulations?.accommodation_rules ? (
          <div className={styles.savoir_btn}>
            <button onClick={handleOpenModal}>En savoir plus</button>
          </div>
        ) : null}
      </div>

      {/*----modal message de l'hotel---------*/}
      <Modal isOpen={modalState} onClose={handleCloseModal}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={handleCloseModal} />
            <div></div>
          </div>
          <div className={styles.m_body}>
            {hotel.traveler_information?.arrival_instructions_message && (
              <div>
                <h2>Instructions d'arrivée</h2>
                <div className={styles.infos}>
                  <p>{hotel.traveler_information?.arrival_instructions_message}</p>
                </div>
              </div>
            )}
            {hotel.regulations?.accommodation_rules && (
              <div>
                <h2>Règles supplémentaires</h2>
                <div className={styles.infos}>
                  <p>{hotel.regulations?.accommodation_rules}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Asavoir
