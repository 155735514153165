import { Helmet } from 'react-helmet-async'
import styles from '../../css/about_us/about.module.css'
import DefaultLayout from '../../layouts/DefaultLayout'

function NonDiscriminationPolicy() {
  return (
    <DefaultLayout footer={true}>
      <Helmet>
        <title>Politique de non-discrimination | Gowifmi</title>
      </Helmet>
      <div className={styles.main}>
        <div className={styles.pourquoi_text}>
          <h1>Politique relative à la communauté Gowifmi</h1>

          <h2>Politique de non-discrimination ?</h2>
          <div>
            <p>
              Chez Gowifmi, nous pensons que l'hospitalité est un droit humain. Notre communauté est
              composée de millions de personnes du monde entier qui ont formé leurs propres
              identités uniques et partagé des expériences d'une manière que seule l'économie du
              partage peut faire. Qu'il s'agisse de répertorier des maisons dans les villes d'autres
              personnes ou de réserver des chambres sur tous les continents, nos hôtes sont
              responsabilisés par la communauté que nous avons bâtie.
            </p>
            <p>
              La communauté Gowifmi se consacre à la construction d'un monde où chacun peut se
              sentir accueilli et respecté, quels que soient son origine ou son parcours. Cet
              engagement repose sur deux principes essentiels qui s'appliquent aussi bien aux hôtes
              qu'aux voyageurs de Gowifmi : <strong>l'inclusion et le respect.</strong> Cet
              engagement communautaire de Gowifmi, partagé par tous, sert de plateforme d'accueil
              pour tous les membres de notre communauté, peu importe qui ils sont ou d'où ils
              viennent. Nous reconnaissons que certains pays exigent ou même autorisent
              l'établissement de distinctions fondées sur la nationalité, le sexe, l'état
              matrimonial ou l'orientation sexuelle. Nous ne demandons pas aux hôtes de violer les
              lois nationales ou locales ou d'agir d'une manière qui les exposerait à un risque de
              responsabilité légale. Gowifmi fournira des consignes supplémentaires et adaptera
              cette présente politique de non- discrimination pour refléter ces options dans les
              pays concernés.
            </p>
          </div>
          <div className={styles.list}>
            <ul>
              <li>
                <strong>Inclusion</strong> : Nous accueillons toutes les personnes de tous horizons,
                formes, tailles et religions. Avec nos cœurs et nos esprits ouverts, nous
                accueillons tout le monde avec honnêteté, respect et joie. Rejoindre Gowifmi en tant
                qu'hôte ou voyageur, c'est faire partie d'une communauté qui intègre tous les
                individus. Si vous voulez que votre hébergement soit un très bon exemple, Si les
                hôtes sont tenus de respecter toute législation en vigueur interdisant les
                discriminations sur des critères de race, de religion, d'origine nationale, ou
                d'autres critères spécifiés ci-dessous, nous nous engageons à aller plus loin que le
                simple respect des prescriptions légales.
              </li>
              <li>
                <strong>Respect</strong> : Nous attendons de tous les hôtes et voyageurs de Gowifmi
                qu'ils respectent les lois et coutumes locales. Nous savons que les lois et les
                coutumes sont très différentes d'un endroit à l'autre, nous demandons donc à chacun
                de respecter les valeurs de l'autre, quel que soit l'endroit où il a été élevé. Les
                membres de la communauté Gowifmi apportent une incroyable diversité d'expériences,
                de valeurs et de traditions à notre famille mondiale. Et en réunissant des personnes
                d'horizons différents, nous augmentons la compréhension et l'appréciation des
                caractéristiques communes de tous les humains - brisant les stéréotypes basés sur de
                fausses notions, la désinformation ou de simples malentendus.
              </li>
            </ul>
          </div>
          <h2>
            Quelles sont les conséquences du non-respect de nos principes de non-discrimination par
            un hôte ?
          </h2>
          <div>
            <p>
              Si une annonce contient des formulations qui ne sont pas conformes à la présente
              politique de non-discrimination, nous demanderons à l'hôte de supprimer ces
              formulations et de réaffirmer qu'il comprend, accepte et a l'intention de se conformer
              à la présente politique de non- discrimination et à ses principes sous-jacents.
              Gowifmi peut également prendre des mesures allant jusqu'à suspendre l'accès à la
              plateforme Gowifmi de l'hôte concerné.
            </p>
            <p>
              Si un hôte refuse certains voyageurs selon des critères discriminatoires ou souscrit à
              des critères qui montrent que les motivations de l'hôte contredisent cette politique,
              Gowifmi prendra les mesures appropriées pour assurer le respect de la présente
              politique. Cela peut inclure la suspension de l'accès à la plateforme Gowifmi de
              l'hôte concerné.
            </p>
            <p>
              Pour répondre aux besoins croissants de notre communauté, nous visons à continuer
              d'améliorer nos politiques et nos pratiques afin de les maintenir alignées sur nos
              valeurs. Nous pensons qu'il est essentiel de bien se traiter mutuellement pour
              maintenir la qualité de notre plateforme, en veillant à ce que les voyageurs comme les
              hôtes se sentent bienvenus et respectés dans leurs interactions sur Gowifmi.
            </p>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}
export default NonDiscriminationPolicy
