import styles from '../../../../../css/traveler/travel/detailReservation.module.css'
import { useHistory, Link } from 'react-router-dom'
import StatusSetupIntent from '../payment_error_status/StatusSetupIntent'
import StatusPaymentIntent from '../payment_error_status/StatusPaymentIntent'
import Tooltip from '../../../../ui/Tooltip'

const TitleHead = ({ reservation }) => {
  const history = useHistory()

  const handleRedirect = () => {
    if (history.length > 1) {
      history.goBack()
    } else {
      const Url = `/reservations/all`

      history.push(Url)
    }
  }

  const id = reservation?._id
  const region = reservation?.hotel?.address?.city
  const lat = reservation?.hotel?.location?.coordinates[1]
  const lng = reservation?.hotel?.location?.coordinates[0]

  return (
    <>
      <StatusSetupIntent
        status={reservation?.status}
        payOnline={reservation?.pay_online}
        setupIntentObject={reservation?.setup_intent}
        id={reservation?.hotel?._id}
      />
      <StatusPaymentIntent
        payOnline={reservation?.pay_online}
        paymentIntentObject={reservation?.payment_intent}
        id={reservation?.hotel?._id}
      />
      <div className={styles.header}>
        <div className={styles.header_title}>
          <div>
            <button onClick={handleRedirect}>
              <svg
                viewBox='0 0 32 32'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
                style={{
                  display: 'block',
                  height: '16px',
                  width: '16px',
                  stroke: 'currentcolor',
                  strokeWidth: '3',
                  fill: 'none',
                  overflow: 'visible',
                }}
              >
                <g>
                  <path d='m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932'></path>
                </g>
              </svg>
            </button>
          </div>
          <h1>Détails · {reservation?.hotel?.title}</h1>
        </div>
        <Tooltip title='Vous cherchez un chef cuisinier local ou tout autre profil utile si besoin ?'>
          <div>
            <Link to={`/profiles/search?&reservation=${id}&place=${region}&lat=${lat}&lng=${lng}`}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 32 32'
                aria-hidden='true'
                role='presentation'
                focusable='false'
                style={{
                  display: 'block',
                  fill: 'none',
                  height: '16px',
                  width: '16px',
                  stroke: 'currentcolor',
                  strokeWidth: '4',
                  overflow: 'visible',
                }}
              >
                <path fill='none' d='M13 24a11 11 0 1 0 0-22 11 11 0 0 0 0 22zm8-3 9 9'></path>
              </svg>
            </Link>
          </div>
        </Tooltip>
      </div>
    </>
  )
}

export default TitleHead
