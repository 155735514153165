import { useEffect, useState } from 'react'
import styles from '../../../css/affiliations/revenues/transaction.module.css'
import axios from 'axios'
import { useQuery } from 'react-query'
import { Link, useHistory } from 'react-router-dom'
import LoadingPoint from '../../LoadingPoint'
import moment from 'moment'
import { addCurrencyComplet } from '../../../utils/addCurrency'

const Transaction = ({ selectedDate }) => {
  const [searchParams, setSearchParams] = useState(window.location.search)
  const params = new URLSearchParams(searchParams)

  const history = useHistory()

  useEffect(() => {
    const unlisten = history.listen(location => {
      setSearchParams(location.search)
    })

    return () => {
      unlisten()
    }
  }, [history])

  const fetchData = async () => {
    const { data } = await axios.get(`/affiliation/revenue?date=${selectedDate}&${params}`)
    return data
  }

  const {
    data = {},
    isLoading,
    error,
  } = useQuery([`get-revenue-affiliations`, searchParams, selectedDate], fetchData)

  const yearMonthDate = moment(selectedDate, 'YYYY-MM').format('MMMM YYYY')
  const yearDate = selectedDate.split('-')[0]

  return (
    <div className={styles.container}>
      {isLoading ? (
        <LoadingPoint />
      ) : (
        <div className={styles.flex_wrapper}>
          <div className={styles.wrapper_block}>
            <h2>{addCurrencyComplet(data?.totalMonthly, 'USD')}</h2>
            <p>Revenus {yearMonthDate}</p>
          </div>
          <div className={styles.wrapper_block}>
            <h2>{addCurrencyComplet(data?.totalYear, 'USD')}</h2>
            <p>Revenus pour {yearDate}</p>
          </div>
          <div className={styles.wrapper_block}>
            <h2>997,00 $US</h2>
            <p>Revenus potentiels</p>
          </div>
          {error && <p>Une erreur s'est produite veuillez réessayer</p>}
          <Link to='/sponsorship/home'>Parrainez un hôte</Link>
        </div>
      )}
    </div>
  )
}

export default Transaction
