import styles from '../../css/booking/reservation.module.css'

const Header = ({ user, handleRedirect, confirmBooking }) => {
  return (
    <div className={styles.header}>
      <div>
        <button onClick={handleRedirect}>
          <svg
            viewBox='0 0 32 32'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            role='presentation'
            focusable='false'
            style={{
              display: 'block',
              height: '16px',
              width: '16px',
              stroke: 'currentcolor',
              strokeWidth: '3',
              fill: 'none',
              overflow: 'visible',
            }}
          >
            <g>
              <path d='m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932'></path>
            </g>
          </svg>
        </button>
      </div>
      {confirmBooking ? (
        <div>
          <h2>{user?.first_name && `${user.first_name},`} Confirmer votre réservation</h2>
        </div>
      ) : (
        <div>
          <h2>Demande de réservation</h2>
        </div>
      )}
    </div>
  )
}

export default Header
