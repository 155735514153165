import style from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import Checkbox from '../../../../../ui/Checkbox'
const Options = ({ vuePanoramique, setVuePanoramique }) => {
  const array = [
    { name: 'Vue sur la baie', value: '1' },
    { name: 'Vue sur la plage', value: '2' },
    { name: 'Vue sur le canal', value: '3' },
    { name: 'Vue panoramique sur la ville', value: '4' },
    { name: 'Vue sur la cour', value: '5' },
    { name: 'Vue sur le désert', value: '6' },
    { name: 'Vue sur le jardin', value: '7' },
    { name: 'Vue sur un parcours de golf', value: '8' },
    { name: 'Vue sur le port', value: '9' },
    { name: 'Vue sur le lac', value: '10' },
    { name: 'Vue sur le port de plaisance', value: '11' },
    { name: 'Vue sur la montagne', value: '12' },
    { name: "Vue sur l'océan", value: '13' },
    { name: 'Vue sur le parc', value: '14' },
    { name: 'Vue sur la piscine', value: '15' },
    { name: 'Vue sur le complexe hôtelier', value: '16' },
    { name: 'Vue sur la rivière', value: '17' },
    { name: 'Vue sur la mer', value: '18' },
    { name: 'Vue sur la vallée', value: '19' },
    { name: 'Vue sur le vignoble', value: '20' },
  ]
  const onchangeFilters = e => {
    if (e.target.checked === true) {
      setVuePanoramique([...vuePanoramique, e.target.value])
    } else {
      const newArr = [...vuePanoramique].filter(item => item !== e.target.value)
      setVuePanoramique(newArr)
    }
  }
  return (
    <>
      <div className={style.grid_system}>
        {array.map(item => (
          <div key={item.name} className={style.checkbox_container}>
            <div>
              <Checkbox
                id={item.name}
                value={item.value}
                checked={vuePanoramique.includes(item.value)}
                onChange={onchangeFilters}
              />
            </div>
            <label htmlFor={item.name}>{item.name}</label>
          </div>
        ))}
      </div>
    </>
  )
}

export default Options
