import { useRef } from 'react'
import styles from '../../css/search_profiles/header.module.css'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import AllFilter from './AllFilter'
import Tooltip from '../ui/Tooltip'

const Header = ({ data }) => {
  const location = useLocation()
  const Query = location.search
  const params = new URLSearchParams(Query)
  const reservation = params.get('reservation')
  const hotel = params.get('hotel')
  const place = params.get('place')

  const AllfilterRef = useRef()
  const Handlfilter = () => {
    AllfilterRef.current.OpenModal()
  }

  params.delete('page')
  params.delete('reservation')
  params.delete('hotel')
  params.delete('place')
  params.delete('lat')
  params.delete('lng')
  const allFilterSelected = Array.from(params).length

  const returnToReservation = `/reservations/details/${reservation}`
  const returnToHotel = `/hosting/listings/details/${hotel}`

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header_title}>
          <div>
            <Link to={reservation ? returnToReservation : returnToHotel}>
              <svg
                viewBox='0 0 32 32'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
                style={{
                  display: 'block',
                  height: '16px',
                  width: '16px',
                  stroke: 'currentcolor',
                  strokeWidth: '3',
                  fill: 'none',
                  overflow: 'visible',
                }}
              >
                <g>
                  <path d='m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932'></path>
                </g>
              </svg>
            </Link>
          </div>
          <h3>
            {data?.totalRecords} {data?.totalRecords > 1 ? 'Profils' : 'Profil'} à {place}
          </h3>
        </div>
        <div className={styles.flter_block}>
          <div className={styles.all_filter}>
            <Tooltip title='Filtrer'>
              <button
                className={allFilterSelected > 0 ? styles.active : null}
                onClick={Handlfilter}
              >
                <svg
                  viewBox='0 0 16 16'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'
                  role='presentation'
                  focusable='false'
                >
                  <path d='M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z'></path>
                </svg>
                <span className={styles.length}>{allFilterSelected}</span>
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
      <AllFilter ref={AllfilterRef} />
    </>
  )
}

export default Header
