import styles from '../../../../../css/modehotes/annonce/details/securiteVoyageur.module.css'
import Checkbox from '../../../../ui/Checkbox'

const NoParking = ({ pasStationnement, setPasStationnement }) => {
  return (
    <div className={styles.checkbox_container}>
      <div>
        <label htmlFor='pasStationnement'>Pas d'espace de stationnement sur place</label>
        <p>Ce logement n'a pas de parking réservé aux voyageurs.</p>
      </div>
      <div>
        <Checkbox
          id='pasStationnement'
          value={pasStationnement}
          checked={pasStationnement}
          onChange={() => setPasStationnement(!pasStationnement)}
        />
      </div>
    </div>
  )
}

export default NoParking
