import styles from '../../../../../css/modehotes/annonce/details/equipement.module.css'
import useUpdateHotel from '../../../../../hooks/useUpdateHotel'
import Checkbox from '../../../../ui/Checkbox'

const Security = ({ equipements, setEquipements }) => {
  const { updateData } = useUpdateHotel()

  const array = [
    { name: 'Détecteur de monoxyde de carbone', value: '18' },
    { name: 'Extincteur', value: '59' },
    { name: 'Kit de premiers secours', value: '61' },
    { name: 'Porte de la chambre avec verrou', value: '60' },
    { name: 'Détecteur de fumée', value: '19' },
  ]
  const onchangeFilters = e => {
    if (e.target.checked === true) {
      setEquipements([...equipements, e.target.value])
      const datas = {
        amenities: [...equipements, e.target.value],
      }
      updateData(datas)
    } else {
      const newArr = [...equipements].filter(item => item !== e.target.value)
      setEquipements(newArr)
      const datas = {
        amenities: newArr,
      }
      updateData(datas)
    }
  }
  return (
    <div className={styles.container_equip}>
      <h4>Sécurité à la maison</h4>
      {array.map(item => (
        <div key={item.name} className={styles.checkbox_container}>
          <label htmlFor={item.name}>{item.name}</label>
          <div>
            <Checkbox
              id={item.name}
              value={item.value}
              checked={equipements.includes(item.value)}
              onChange={onchangeFilters}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default Security
