import { useEffect } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'

const CustomHomeUrl = () => {
  const history = useHistory()
  const location = useLocation()
  const path = location.pathname.split('/')[2]
  useEffect(() => {
    axios
      .get(`/hotel_user/lien-personnaliser/${path}`)
      .then(res => {
        history.push(`/rooms/${res.data._id}`)
      })
      .catch(() => {
        alert("Une erreur s'est produite")
      })
  }, [history, path])
  return null
}

export default CustomHomeUrl
