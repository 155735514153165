import { useEffect, useState, useRef } from 'react'
import styles from '../../../css/account/securite.module.css'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router'
import Login from '../../../components/login'
import LoadingPoint from '../../../components/LoadingPoint'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import DefaultLayout from '../../../layouts/DefaultLayout'
import ErrorSvg from '../../../components/svgs/ErrorSvg'

const Securite = () => {
  const [view_2, setView_2] = useState(false)
  const [loadingbutton, setLoadingbutton] = useState(false)
  const [echecConnexion, setEchecConnexion] = useState(false)
  const [reussit, setReussit] = useState(false)
  const location = useLocation()
  const id = location.pathname.split('/')[3]
  const token = location.pathname.split('/')[4]

  const formSchema = yup.object().shape({
    newPassword: yup
      .string()
      .required('Entrer le nouveau mot de passe')
      .min(8, 'Votre mot de passe doit contenir au moins 8 caractères'),
    newPassword_2: yup
      .string()
      .required('Confirmer le mot de passe est requis')
      .oneOf([yup.ref('newPassword')], 'Les mots de passe ne correspondent pas !'),
  })

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onChange', resolver: yupResolver(formSchema) })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])
  const onSubmit = data => {
    setLoadingbutton(true)
    axios
      .patch(`/reset_password/${id}/${token}`, data)
      .then(() => {
        setLoadingbutton(false)
        setEchecConnexion(false)
        setReussit(true)
        reset()
      })
      .catch(err => {
        setEchecConnexion(true)
        setLoadingbutton(false)
      })
  }

  const connexion = useRef()
  const handleConnection = params => {
    connexion.current.OpenModal(params)
  }

  return (
    <DefaultLayout navTopMobile={true}>
      <Helmet>
        <title>Connexion et sécurité - Gowifmi</title>
      </Helmet>
      <div className={styles.seting_container}>
        <h1>Connexion et sécurité</h1>
        <div className={styles.seting_box}>
          <div className={styles.securite_1}>
            <p>Mettre ajour votre mot dépasse</p>
            {echecConnexion && (
              <div className={styles.connexion_echec}>
                <div>
                  <ErrorSvg width={24} height={24} />
                </div>
                <div className={styles.notif}>
                  <p>
                    Le lien n'est plus valide veuillez réessayer la confirmation{' '}
                    <a href='/help/feedback'>Besoin d'aide?</a>
                  </p>
                </div>
              </div>
            )}
            {reussit && (
              <div className={styles.reussit_conexion}>
                <div className={styles.notif}>
                  <h1>Votre mot de passe est mis à jour </h1>
                  <p>Lors de votre nouvelle connexion utiliser le nouveau mot de passe.</p>
                  <button className={styles.connexion} onClick={handleConnection}>
                    Connectez-vous
                  </button>
                </div>
              </div>
            )}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
              <div
                className={
                  errors.newPassword ? `${styles.block_input} ${styles.erreur}` : styles.block_input
                }
              >
                <label htmlFor='nouveau'>Nouveau mot de passe</label>
                <input
                  type={view_2 ? 'text' : 'password'}
                  id='nouveau'
                  autoComplete='new-password'
                  aria-autocomplete='list'
                  {...register('newPassword')}
                />
                {errors.newPassword && (
                  <span className={styles.erreur}>{errors.newPassword.message}</span>
                )}
                {view_2 ? (
                  <ion-icon name='eye-off-outline' onClick={() => setView_2(false)}></ion-icon>
                ) : (
                  <ion-icon name='eye-outline' onClick={() => setView_2(true)}></ion-icon>
                )}
              </div>
              <div
                className={
                  errors.newPassword_2
                    ? `${styles.block_input} ${styles.erreur}`
                    : styles.block_input
                }
              >
                <label htmlFor='confirme'>Confirmer le mot de passe</label>
                <input type='password' id='confirme' {...register('newPassword_2')} />
                {errors.newPassword_2 && (
                  <span className={styles.erreur}>{errors.newPassword_2.message}</span>
                )}
              </div>
              <div className={styles.buttoms}>
                {loadingbutton ? (
                  <button disabled className={styles.loading}>
                    <LoadingPoint />
                  </button>
                ) : (
                  <button type='submit'>Mettre à jour le mot de passe</button>
                )}
              </div>
            </form>
          </div>
        </div>
        <div className={styles.securite_2}>
          <h2>Renforcez la sécurité de votre compte</h2>
          <p>
            Nous travaillons en permanence à renforcer la sécurité de notre communauté. C'est
            pourquoi nous examinons chaque compte pour nous assurer qu'il est aussi sécurisé que
            possible.
          </p>
        </div>
      </div>
      <Login ref={connexion} />
    </DefaultLayout>
  )
}

export default Securite
