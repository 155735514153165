import { useEffect, useState } from 'react'
import styles from '../../css/ui/snackbar.module.css'
import { Portal } from 'react-portal'

let timeout1
let timeout2

const Snackbar = ({ isOpen, onClose, autoHideDuration = 1000, message }) => {
  const [opened, setOpened] = useState(false)

  useEffect(() => {
    if (timeout1) {
      clearTimeout(timeout1)
    }
    if (timeout2) {
      clearTimeout(timeout2)
    }

    if (isOpen) {
      setOpened(true)
      timeout1 = setTimeout(() => {
        onClose()
        timeout2 = setTimeout(() => {
          setOpened(false)
        }, 1000)
      }, autoHideDuration)
    }

    return () => {
      clearTimeout(timeout1)
      clearTimeout(timeout2)
    }
  }, [isOpen])

  if (!opened) return null

  return (
    <Portal>
      <div className={isOpen ? `${styles.modal_Snackbar} ${styles.active}` : styles.modal_Snackbar}>
        <svg
          viewBox='0 0 16 16'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'
          role='presentation'
          focusable='false'
        >
          <path d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.159 4.869L6.67 9.355 4.42 7.105 3.289 8.236 6.67 11.62 12.291 6z'></path>
        </svg>
        <p>{message}</p>
      </div>
    </Portal>
  )
}

export default Snackbar
