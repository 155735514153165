import { useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'

function useAddHome(setLoading, setServerError) {
  const [data, setData] = useState(null)
  const location = useLocation()
  const id = location.pathname.split('/')[3]
  const history = useHistory()

  const updateData = (datas, url) => {
    setLoading(true)
    setServerError(null)
    axios
      .patch(`/hotel/${id}`, datas)
      .then(res => {
        setData(res.data)
        setLoading(false)
        history.push(`${url}/${id}`)
      })
      .catch(err => {
        setServerError(err)
        setLoading(false)
      })
  }

  const updateToSaveStatus = (datas, url) => {
    setLoading(true)
    setServerError(null)
    axios
      .patch(`/hotel/save/${id}`, datas)
      .then(res => {
        setData(res.data)
        setLoading(false)
        history.push(`${url}/${id}`)
      })
      .catch(err => {
        setServerError(err)
        setLoading(false)
      })
  }

  return { data, updateData, updateToSaveStatus }
}

export default useAddHome
