import { useEffect, useContext } from 'react'
import { User } from '../../context/UserContext'
import { useHistory } from 'react-router-dom'
import globalStyle from '../../css/becomeHostes/globalStyle.module.css'
import Card from '../../components/become_host/previews/Card'
import DescriptionText from '../../components/become_host/globals/DescriptionText'
import { Helmet } from 'react-helmet-async'

const Preview = () => {
  const user = useContext(User)
  const history = useHistory()
  useEffect(() => {
    if (user === null) {
      history.push('/host/homes')
    }
  }, [history, user])
  return (
    <>
      <Helmet>
        <title>Vérifier et enregistrer votre annonce - Gowifmi</title>
      </Helmet>
      <div className={globalStyle.container}>
        <Card />
        <DescriptionText
          title='Jetez un œil à votre annonce'
          desc='Une fois que vous avez enregistré votre annonce, nous vous demanderons de confirmer quelques informations avant de pouvoir la publier.'
        />
      </div>
    </>
  )
}

export default Preview
