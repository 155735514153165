import { useState } from 'react'
import styles from '../../../../../../css/modehotes/reservation/detail.module.css'
import axios from 'axios'
import Swal from 'sweetalert2'
import LoadingPoint from '../../../../../LoadingPoint'
import Toast from '../../../../../infos/toast'
import { STATUS_BOOKING } from '../../../../../../enums/booking/status.enums'
import ReservationStatus from '../../../../default_components/ReservationStatus'

const tostMessage = (icon, title) => {
  Toast.fire({
    icon: icon,
    title: title,
  })
}

const StatusReservation = ({ reservation, path, refetch }) => {
  const [loading, setLoading] = useState(false)

  const checkinDate = new Date(reservation.reservation_infos?.checkin).setHours(0, 0, 0, 0)
  const DadeNow = new Date().setHours(0, 0, 0, 0)

  const handleDelete = () => {
    Swal.fire({
      title: 'Supprimer cette demande ?',
      text: 'Une fois la demande supprimée, vous ne pourrez plus la récupérer.',
      showCancelButton: true,
      confirmButtonText: 'Supprimer la demande',
      cancelButtonText: 'Annuler',
      reverseButtons: true,
      customClass: {
        actions: 'actions_class',
        confirmButton: 'confirm_button_class',
        cancelButton: 'cancel_button_class',
      },
    }).then(result => {
      if (result.isConfirmed) {
        setLoading(true)
        axios
          .patch(`/host_reservations/host_delete/${path}`)
          .then(async () => {
            await refetch()
            tostMessage('success', 'La demande de réservation a été supprimée avec succès.')
          })
          .catch(() => tostMessage('error', 'Un problème est survenu au niveau de serveur'))
          .finally(() => {
            setLoading(false)
          })
      }
    })
  }

  const handleAccepte = () => {
    setLoading(true)
    axios
      .patch(`/host_reservations/accept/${path}`)
      .then(async () => {
        await refetch()
        tostMessage('success', 'La demande de réservation a été acceptée avec succès')
      })
      .catch(async err => {
        if (err.response?.data?.message === 'dates_exist') {
          tostMessage(
            'error',
            `Le logement semble déjà réservé aux dates demandées, invité le voyageur à réserver de nouveau`
          )
        } else if (err.response?.data?.message === 'user_canceled') {
          tostMessage('error', `Le voyageur a déjà annulé sa demande de réservation`)
          await refetch()
        } else {
          tostMessage(
            'error',
            "Une erreur s'est produite, Motif : le moyen de paiement du voyageur semble ne pas valide."
          )
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className={styles.content}>
      <h3>Statut de la réservation</h3>
      <ReservationStatus reservation={reservation} />
      <div>
        {reservation?.status === STATUS_BOOKING.REQUEST && checkinDate >= DadeNow ? (
          <div>
            {loading ? (
              <LoadingPoint />
            ) : (
              <div className={styles.btn_block}>
                <button onClick={handleAccepte}>Accepter la réservation</button>
                <button className={styles.delete} onClick={handleDelete}>
                  Supprimer la demande
                </button>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default StatusReservation
