import styles from '../../css/home/section2.module.css'
import { Link } from 'react-router-dom'

const Section1 = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.left}>
          <div>
            <h3>Partagez maintenant, gagnez maintenant.</h3>
            <p>
              Créez une opportunité de gagner de l'argent en partageant votre logement ou en
              invitant une personne à proposer son logement et gagnez de l'argent.
            </p>
            <div className={styles.block_link}>
              <Link to='/host/homes'>Ajouter votre logement</Link>
              <Link to='/sponsorship/home'>Parrainez un hôte</Link>
            </div>
          </div>
        </div>
        <div className={styles.droit}></div>
      </div>
    </>
  )
}

export default Section1
