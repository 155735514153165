import { useState, useEffect } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const Traveler = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [voyageur, setVoyageur] = useState()

  const { loading, updateData } = useUpdateHotel(setOpen)

  const handleSubmit = () => {
    const datas = {
      details: {
        ...annonce.details,
        traveler: voyageur,
      },
    }
    updateData(datas)
  }
  useEffect(() => {
    setVoyageur(annonce.details?.traveler)
  }, [annonce])
  const openFuction = () => {
    setVoyageur(annonce.details?.traveler)
    setOpen(true)
  }
  const reset = () => {
    setVoyageur()
    setOpen(false)
  }
  //incrementer les voyageur
  const incrementeVoyageur = () => {
    setVoyageur(c => c + 1)
  }
  //decrementer
  const decrementeVoyageur = () => {
    setVoyageur(c => c - 1)
  }

  return (
    <div className={styles.block}>
      {!open ? (
        <Section title='Nombre de voyageurs' handleOpen={openFuction}>
          <p>Voyageurs : {annonce.details?.traveler}</p>
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title='Nombre de voyageurs' handleReset={reset}>
            <p>Combien de voyageurs souhaitez-vous accueillir ?</p>
          </ContenHead>
          <div className={styles.body}>
            <div className={styles.info_search}>
              <div className={styles.block_flex}>
                <div>
                  <h3>Voyageurs</h3>
                </div>
                <div className={styles.occup_count}>
                  <button
                    type='button'
                    disabled={voyageur === 1 ? true : false}
                    className={voyageur === 1 ? styles.disable : null}
                    onClick={decrementeVoyageur}
                  >
                    <svg
                      className={styles.decr}
                      viewBox='0 0 32 32'
                      xmlns='http://www.w3.org/2000/svg'
                      aria-hidden='true'
                      role='presentation'
                      focusable='false'
                    >
                      <path d='m2 16h28'></path>
                    </svg>
                  </button>
                  <span>{voyageur}</span>
                  <button
                    type='button'
                    disabled={voyageur >= 30 ? true : false}
                    className={voyageur === 30 ? styles.disable : null}
                    onClick={incrementeVoyageur}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
          <BottomForm handleSubmit={handleSubmit} loading={loading} handleReset={reset} />
        </div>
      )}
    </div>
  )
}

export default Traveler
