import { useState } from 'react'
import styles from '../../../../../../css/traveler/travel/detailReservation.module.css'
import moment from 'moment'
import axios from 'axios'
import Swal from 'sweetalert2'
import LoadingPoint from '../../../../../LoadingPoint'
import Toast from '../../../../../infos/toast'
import { UPDATE_BOOKING } from '../../../../../../enums/booking/bookingUpdate.enums'

const tostMessage = (icon, title) => {
  Toast.fire({
    icon: icon,
    title: title,
  })
}

const StatutModif = ({ reservation, refetch, id }) => {
  const [loading, setLoading] = useState(false)
  const modifications = reservation?.modifications[reservation?.modifications.length - 1]

  const handleCancel = () => {
    Swal.fire({
      title: 'Annuler cette demande de modification ?',
      text: 'Une fois la demande annulée, vous ne pourrez plus la récupérer.',
      showCancelButton: true,
      confirmButtonText: 'Annuler la demande',
      cancelButtonText: 'Retour',
      reverseButtons: true,
      customClass: {
        actions: 'actions_class',
        confirmButton: 'confirm_button_class',
        cancelButton: 'cancel_button_class',
      },
    }).then(result => {
      if (result.isConfirmed) {
        setLoading(true)
        axios
          .patch(`/user_reservations/cancel_request_modif/${id}`)
          .then(async () => {
            tostMessage('success', 'Votre demande de modification est annulée avec succès.')
            await refetch()
          })
          .catch(err => {
            if (err.response?.data?.message === 'demande_invalid') {
              tostMessage(
                'error',
                "Vous ne pouvez pas annuler cette demande, parce que l'hôte a déjà accepté votre demande de modification veuillez actualiser la page."
              )
            } else {
              tostMessage('error', 'Un problème est survenu au niveau de serveur')
            }
          })
          .finally(() => {
            setLoading(false)
          })
      }
    })
  }

  return (
    <>
      <div className={styles.content}>
        <h3>Modification de la réservation</h3>
        {modifications?.modification_status ? (
          <div>
            <h4>Statut de la demande</h4>
            <div className={styles.statut_modif}>
              {modifications?.modification_status === UPDATE_BOOKING.REQUEST && (
                <p className={styles.attente}>Demande</p>
              )}
              {modifications?.modification_status === UPDATE_BOOKING.ACCEPTED && (
                <p className={styles.accepter}>Acceptée</p>
              )}
              {modifications?.modification_status === UPDATE_BOOKING.REJECTED && (
                <p className={styles.echec}>Refusée</p>
              )}
            </div>
            <div>
              <h4>Les dates</h4>
              <p>Date d'arrivée : {moment(modifications?.checkin).format('ll')}</p>
              <p>Date de départ : {moment(modifications?.checkout).format('ll')}</p>
              <p>
                Séjour de : {modifications?.total_nights}{' '}
                {modifications?.total_nights > 1 ? 'nuits' : 'nuit'}
              </p>
            </div>

            {modifications?.modification_status === UPDATE_BOOKING.REQUEST && (
              <div className={styles.statut_modif_btn}>
                {loading ? (
                  <LoadingPoint />
                ) : (
                  <button className={styles.delete} onClick={handleCancel}>
                    Annuler la demande de modification
                  </button>
                )}
              </div>
            )}
            {modifications?.isModification_refunded && (
              <div className={styles.demande}>
                <svg
                  viewBox='0 0 16 16'
                  className={styles.yes}
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'
                  role='presentation'
                  focusable='false'
                >
                  <path d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.159 4.869L6.67 9.355 4.42 7.105 3.289 8.236 6.67 11.62 12.291 6z'></path>
                </svg>
                <span>Rembourser</span>
              </div>
            )}
          </div>
        ) : (
          <div>
            <p>Pas de demande de modification pour le moment</p>
          </div>
        )}
      </div>
    </>
  )
}

export default StatutModif
