import { useState, useEffect } from 'react'
import styles from '../../../css/affiliations/sponsors/list_sponsor.module.css'
import moment from 'moment'
import axios from 'axios'
import { useQuery } from 'react-query'
import { Link, useHistory } from 'react-router-dom'
import LoadingPoint from '../../LoadingPoint'
import Header from './Header'
import Pagination from '../../../components/ui/Pagination'
import { addCurrencyComplet } from '../../../utils/addCurrency'
import { STATUS_STRANSFER } from '../../../enums/booking/transferStatus.enums'

const ListSponsor = () => {
  const [searchParams, setSearchParams] = useState(window.location.search)
  const history = useHistory()

  useEffect(() => {
    const unlisten = history.listen(location => {
      setSearchParams(location.search)
    })

    return () => {
      window.scrollTo(0, 0)
      unlisten()
    }
  }, [history])

  const fetchData = async () => {
    const { data } = await axios.get(`/affiliation${searchParams}`)
    return data
  }

  const {
    data = {},
    isLoading,
    error,
  } = useQuery([`get-all-affiliations`, searchParams], fetchData)

  return (
    <div className={styles.container}>
      <Header data={data} />
      {isLoading ? (
        <LoadingPoint />
      ) : (
        <>
          <div className={styles.flex_container}>
            {data?.affiliations?.map(item => (
              <div key={item._id} className={styles.sponsor_card}>
                <a
                  href={`/profile/${item?.referral?.id}`}
                  target='_blanck'
                  className={styles.wrapper_img_profile}
                >
                  <div>
                    <div className={styles.image_block}>
                      {item?.referral?.picture ? (
                        <img src={item?.referral?.picture} alt='' />
                      ) : (
                        <svg
                          viewBox='0 0 32 32'
                          xmlns='http://www.w3.org/2000/svg'
                          aria-hidden='true'
                          role='presentation'
                          focusable='false'
                        >
                          <path d='m16 .7c-8.437 0-15.3 6.863-15.3 15.3s6.863 15.3 15.3 15.3 15.3-6.863 15.3-15.3-6.863-15.3-15.3-15.3zm0 28c-4.021 0-7.605-1.884-9.933-4.81a12.425 12.425 0 0 1 6.451-4.4 6.507 6.507 0 0 1 -3.018-5.49c0-3.584 2.916-6.5 6.5-6.5s6.5 2.916 6.5 6.5a6.513 6.513 0 0 1 -3.019 5.491 12.42 12.42 0 0 1 6.452 4.4c-2.328 2.925-5.912 4.809-9.933 4.809z'></path>
                        </svg>
                      )}
                    </div>
                  </div>
                  <div className={styles.wrapper_name}>
                    <h2>{item?.referral?.first_name}</h2>
                    <p>Membre depuis {moment(item?.referral?.createdAt).format('LL').slice(2)}</p>
                  </div>
                </a>
                <div>
                  {item?.referral_progression === 25 && (
                    <p className={styles.progress_text}>
                      En attente de la finalisation de l'annonce
                    </p>
                  )}
                  {item?.referral_progression === 50 && (
                    <p className={styles.progress_text}>
                      En attente de la publication de l'annonce
                    </p>
                  )}
                  {item?.referral_progression === 75 && (
                    <p className={styles.progress_text}>En attente de la réservation</p>
                  )}
                  {item?.referral_progression === 100 && (
                    <p className={styles.progress_text}>
                      Félicitations l'hôte vient d'honorer son premier séjour
                    </p>
                  )}
                </div>
                <div className={styles.progress_block}>
                  <div
                    className={
                      item?.referral_progression >= 25
                        ? `${styles.progress_step} ${styles.active}`
                        : styles.progress_step
                    }
                  ></div>
                  <div
                    className={
                      item?.referral_progression >= 50
                        ? `${styles.progress_step} ${styles.active}`
                        : styles.progress_step
                    }
                  ></div>
                  <div
                    className={
                      item?.referral_progression >= 75
                        ? `${styles.progress_step} ${styles.active}`
                        : styles.progress_step
                    }
                  ></div>
                  <div
                    className={
                      item?.referral_progression >= 100
                        ? `${styles.progress_step} ${styles.active}`
                        : styles.progress_step
                    }
                  ></div>
                </div>
                <div className={styles.footer_block}>
                  {item?.referral_progression === 25 && (
                    <p>Encouragez l'hôte à finaliser son annonce</p>
                  )}
                  {item?.referral_progression === 50 && (
                    <p>Cet hôte y est presque, encouragez-le à publier son annonce</p>
                  )}
                  {item?.referral_progression === 75 && (
                    <p>Vous pouvez l'aider à accueillir des voyageurs</p>
                  )}
                  {item?.referral_progression === 100 &&
                  item.transfer_status !== STATUS_STRANSFER.COMPLETE ? (
                    <p>En cours</p>
                  ) : null}
                  {item?.referral_progression === 100 &&
                  item.transfer_status === STATUS_STRANSFER.COMPLETE ? (
                    <p>{addCurrencyComplet(item?.total_fee, item?.currency)}</p>
                  ) : null}
                  <div>
                    <Link
                      to={`/inbox/detail/${item?.referral?.id}?filter=all`}
                      className={styles.send_message}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 32 32'
                        aria-hidden='true'
                        role='presentation'
                        focusable='false'
                        style={{
                          display: 'block',
                          height: '20px',
                          width: '20px',
                          fill: 'currentcolor',
                        }}
                      >
                        <path d='M26 1a5 5 0 0 1 5 4.78v10.9a5 5 0 0 1-4.78 5H26a5 5 0 0 1-4.78 5h-4l-3.72 4.36-3.72-4.36H6a5 5 0 0 1-4.98-4.56L1 21.9 1 21.68V11a5 5 0 0 1 4.78-5H6a5 5 0 0 1 4.78-5H26zm-5 7H6a3 3 0 0 0-3 2.82v10.86a3 3 0 0 0 2.82 3h4.88l2.8 3.28 2.8-3.28H21a3 3 0 0 0 3-2.82V11a3 3 0 0 0-3-3zm-1 10v2H6v-2h14zm6-15H11a3 3 0 0 0-3 2.82V6h13a5 5 0 0 1 5 4.78v8.9a3 3 0 0 0 3-2.82V6a3 3 0 0 0-2.82-3H26zM15 13v2H6v-2h9z'></path>
                      </svg>
                    </Link>
                  </div>
                </div>
                <p className={styles.progress_footer_date}>
                  Commencé le {moment(item.createdAt).format('ll')}
                </p>
              </div>
            ))}
          </div>
          <Pagination data={data} />
          {data?.totalRecords < 1 && (
            <>
              <p>Vous n'avez pas de filleuls pour le moment</p>
              <Link to='/sponsorship/home' className={styles.home_link}>
                Parrainez un hôte
              </Link>
            </>
          )}
        </>
      )}
      {error && <p>Une erreur s'est produite veuillez réessayer</p>}
    </div>
  )
}

export default ListSponsor
