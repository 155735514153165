import React, { useState } from 'react'
import styles from '../../../../css/details/Detail_all_photo.module.css'
import { Portal } from 'react-portal'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Navigation, Pagination } from 'swiper/modules'
import CloseSvg from '../../../svgs/CloseSvg'

const DetailAllPhoto = ({ hotel, setSwiperRef, modalStateItem, handleCloseModalItem }) => {
  const [prev, setPrev] = useState(false)
  const [nex, setNex] = useState(true)
  const [imgIndex, setImgIndex] = useState(1)

  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)

  return (
    <>
      <Portal>
        <div
          className={
            modalStateItem ? `${styles.modal_image_one} ${styles.active}` : styles.modal_image_one
          }
        >
          <div className={styles.container_image_one}>
            <div className={styles.entete}>
              <button type='button' className={styles.close} onClick={handleCloseModalItem}>
                <CloseSvg />
              </button>
              <p>
                {imgIndex} / {hotel.images?.length}
              </p>
              <div></div>
            </div>
            <div className={styles.slider_container_one}>
              <Swiper
                modules={[Navigation, Pagination]}
                onSwiper={setSwiperRef}
                loop={false}
                spaceBetween={0}
                speed={400}
                navigation={{
                  prevEl: navigationPrevRef.current,
                  nextEl: navigationNextRef.current,
                }}
                onBeforeInit={swiper => {
                  swiper.params.navigation.prevEl = navigationPrevRef.current
                  swiper.params.navigation.nextEl = navigationNextRef.current
                }}
                onSlideNextTransitionStart={() => setPrev(true)}
                onReachBeginning={() => setPrev(false)}
                onSlidePrevTransitionStart={() => setNex(true)}
                onReachEnd={() => setNex(false)}
                className={styles.swiper_one_1}
                onSlideChange={swiper => {
                  setImgIndex(swiper.activeIndex + 1)
                }}
              >
                {hotel?.images?.map((img, index) => (
                  <SwiperSlide key={index} className={styles.swiper_image_one}>
                    <div className={styles.block_image}>
                      <img src={img.url} alt='photos liste' />
                      {img.caption && (
                        <div>
                          <p>{img.caption}</p>
                        </div>
                      )}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className={styles.container_buttons}>
              <div
                ref={navigationPrevRef}
                className={prev ? styles.prev : `${styles.prev} ${styles.disable}`}
              >
                <svg
                  viewBox='0 0 32 32'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'
                  role='presentation'
                  focusable='false'
                >
                  <g>
                    <path d='m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932'></path>
                  </g>
                </svg>
              </div>
              <div
                ref={navigationNextRef}
                className={nex ? styles.next : `${styles.next} ${styles.disable}`}
              >
                <svg
                  viewBox='0 0 32 32'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'
                  role='presentation'
                  focusable='false'
                >
                  <g>
                    <path d='m12 4 11.2928932 11.2928932c.3905243.3905243.3905243 1.0236893 0 1.4142136l-11.2928932 11.2928932'></path>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </Portal>
    </>
  )
}

export default DetailAllPhoto
