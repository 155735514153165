import styles from '../../../css/becomeHostes/question.module.css'

const SecurityTraveler = ({
  cameras,
  setCameras,
  armes,
  setArmes,
  animauDangereux,
  setAnimauDangereux,
}) => {
  return (
    <div>
      <h2>Votre logement comprend-il les éléments suivants ?</h2>
      <div>
        <div className={styles.grid_system}>
          <div className={styles.checkox_contener}>
            <label htmlFor='cameras'>Caméras de surveillance</label>
            <div>
              <input
                type='checkbox'
                id='cameras'
                value={cameras}
                checked={cameras}
                onChange={() => setCameras(!cameras)}
              />
            </div>
          </div>
          <div className={styles.checkox_contener}>
            <label htmlFor='armes'>Armes</label>
            <div>
              <input
                type='checkbox'
                id='armes'
                value={armes}
                checked={armes}
                onChange={() => setArmes(!armes)}
              />
            </div>
          </div>
          <div className={styles.checkox_contener}>
            <label htmlFor='animauDangereux'>Animaux dangereux</label>
            <div>
              <input
                type='checkbox'
                id='animauDangereux'
                value={animauDangereux}
                checked={animauDangereux}
                onChange={() => setAnimauDangereux(!animauDangereux)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecurityTraveler
