import { useRef, useContext } from 'react'
import styles from '../../css/details/detail_hotel.module.css'
import { useLocation } from 'react-router'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { Helmet } from 'react-helmet-async'
import { getFavoris } from '../../redux/actions'
import { User } from '../../context/UserContext'
import Availability from '../../components/details_room/Availability'
import LoadingComponent from '../../components/details_room/LoadingComponent'
import Amenitie from '../../components/details_room/Amenitie'
import Hebergement from '../../components/details_room/Hebergement'
import NeighborhoodDescription from '../../components/details_room/NeighborhoodDescription'
import Maps from '../../components/details_room/Maps'
import Annonce from '../../components/details_room/Annonce'
import Review from '../../components/details_room/Review'
import Comment from '../../components/details_room/Comment'
import KnowledgeBase from '../../components/details_room/KnowledgeBase'
import HostProfile from '../../components/details_room/HostProfile'
import Photo from '../../components/details_room/photos/Photo'
import ErrorServer from '../errors/ErrorServer'
import Signaler from '../../components/details_room/Signaler'
import Header from '../../components/details_room/Header'
import Calendar from '../../components/details_room/Calendar'
import DefaultLayout from '../../layouts/DefaultLayout'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorBoundaryFallbackNoAction from '../../components/errors/ErrorBoundaryFallbackNoAction'

function Hotel() {
  const location = useLocation()
  const id = location.pathname.split('/')[2]
  const dispatch = useDispatch()
  const user = useContext(User)
  const userId = user?.id || null

  const origin = window.location.origin
  const pathname = window.location.pathname
  const metaUrl = `${origin}${pathname}`

  const photoRef = useRef(null)
  const amenentieRef = useRef(null)
  const descriptionRef = useRef(null)
  const localisationRef = useRef(null)
  const commentRef = useRef(null)
  const ProfilHoteRef = useRef(null)

  const {
    data = {},
    isLoading,
    error,
  } = useQuery(`get-annonce-details-${id}`, () =>
    axios.get(`/hotel_user/${id}/${userId}`).then(res => {
      return res.data
    })
  )

  const { refetch } = useQuery(`get-favoris`, () => {
    if (user) {
      return axios.get(`/favoris`).then(res => {
        dispatch(getFavoris(res.data))
      })
    }
  })

  const travelerNumber = data?.details?.traveler
  const roomNumber = data?.details?.number_of_rooms
  const bedNumber = data?.details?.number_of_beds
  const bathroomNumber = data?.details?.number_of_bathrooms

  const travelerText = travelerNumber > 1 ? 'voyageurs' : 'voyageur'
  const roomText = roomNumber > 1 ? 'chambres' : 'chambre'
  const bedText = bedNumber > 1 ? 'lits' : 'lit'
  const bathroomText = bathroomNumber > 1 ? 'salles de bain' : 'salle de bain'

  if (error || !id) {
    return <ErrorServer />
  }

  return (
    <DefaultLayout footer={!isLoading}>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <div>
          <Helmet>
            <title>{data?.title || 'Donnez un titre à votre annonce'} - Gowifmi</title>
            <meta
              property='og:title'
              content={data?.title || 'Découvrer ce logement chez Gowifmi'}
            />
            <meta
              property='og:description'
              content={`${travelerNumber} ${travelerText} · ${roomNumber} ${roomText} · ${bedNumber} ${bedText} · ${bathroomNumber} ${bathroomText}`}
            />
            <meta property='og:image' content={`${data?.images[0]?.url}`} />
            <meta property='og:url' content={metaUrl} />
          </Helmet>

          <Header
            getAllFavoris={refetch}
            annonce={data}
            localisationRef={localisationRef}
            commentRef={commentRef}
          />
          <Photo getAllFavoris={refetch} hotel={data} photoRef={photoRef} />
          <main className={styles.destination_detail_block}>
            <Availability
              hotel={data}
              photoRef={photoRef}
              amenentieRef={amenentieRef}
              descriptionRef={descriptionRef}
              localisationRef={localisationRef}
              commentRef={commentRef}
              ProfilHoteRef={ProfilHoteRef}
            />
            <Amenitie hotel={data} amenentieRef={amenentieRef} />
            <Hebergement hotel={data} descriptionRef={descriptionRef} />
            <Calendar hotel={data} />
            <Review hotel={data} />
            <Comment hotel={data} commentRef={commentRef} />
            <ErrorBoundary FallbackComponent={ErrorBoundaryFallbackNoAction}>
              <Maps hotel={data} localisationRef={localisationRef} />
            </ErrorBoundary>
            <Annonce hotel={data} />
            <NeighborhoodDescription hotel={data} />
            <KnowledgeBase hotel={data} />
            <HostProfile hotel={data} ProfilHoteRef={ProfilHoteRef} />
            <Signaler />
          </main>
        </div>
      )}
    </DefaultLayout>
  )
}
export default Hotel
