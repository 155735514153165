const countries = [
  { country: 'Afrique du Sud', countryAbbreviation: 'ZA' },
  { country: 'Afghanistan', countryAbbreviation: 'AF' },
  { country: 'Albanie', countryAbbreviation: 'AL' },
  { country: 'Algérie', countryAbbreviation: 'DZ' },
  { country: 'Allemagne', countryAbbreviation: 'DE' },
  { country: 'Andorre', countryAbbreviation: 'AD' },
  { country: 'Angola', countryAbbreviation: 'AO' },
  { country: 'Antigua-et-Barbuda', countryAbbreviation: 'AG' },
  { country: 'Arabie Saoudite', countryAbbreviation: 'SA' },
  { country: 'Argentine', countryAbbreviation: 'AR' },
  { country: 'Arménie', countryAbbreviation: 'AM' },
  { country: 'Australie', countryAbbreviation: 'AU' },
  { country: 'Autriche', countryAbbreviation: 'AT' },
  { country: 'Azerbaïdjan', countryAbbreviation: 'AZ' },
  { country: 'Bahamas', countryAbbreviation: 'BS' },
  { country: 'Bahreïn', countryAbbreviation: 'BH' },
  { country: 'Bangladesh', countryAbbreviation: 'BD' },
  { country: 'Barbade', countryAbbreviation: 'BB' },
  { country: 'Belgique', countryAbbreviation: 'BE' },
  { country: 'Belize', countryAbbreviation: 'BZ' },
  { country: 'Bénin', countryAbbreviation: 'BJ' },
  { country: 'Bhoutan', countryAbbreviation: 'BT' },
  { country: 'Biélorussie', countryAbbreviation: 'BY' },
  { country: 'Birmanie', countryAbbreviation: 'MM' },
  { country: 'Bolivie', countryAbbreviation: 'BO' },
  { country: 'Bosnie-Herzégovine', countryAbbreviation: 'BA' },
  { country: 'Botswana', countryAbbreviation: 'BW' },
  { country: 'Brésil', countryAbbreviation: 'BR' },
  { country: 'Brunei', countryAbbreviation: 'BN' },
  { country: 'Bulgarie', countryAbbreviation: 'BG' },
  { country: 'Burkina Faso', countryAbbreviation: 'BF' },
  { country: 'Burundi', countryAbbreviation: 'BI' },
  { country: 'Cambodge', countryAbbreviation: 'KH' },
  { country: 'Cameroun', countryAbbreviation: 'CM' },
  { country: 'Canada', countryAbbreviation: 'CA' },
  { country: 'Cap-Vert', countryAbbreviation: 'CV' },
  { country: 'Chili', countryAbbreviation: 'CL' },
  { country: 'Chine', countryAbbreviation: 'CN' },
  { country: 'Chypre', countryAbbreviation: 'CY' },
  { country: 'Colombie', countryAbbreviation: 'CO' },
  { country: 'Comores', countryAbbreviation: 'KM' },
  { country: 'Corée du Nord', countryAbbreviation: 'KP' },
  { country: 'Corée du Sud', countryAbbreviation: 'KR' },
  { country: 'Costa Rica', countryAbbreviation: 'CR' },
  { country: 'Côte d’Ivoire', countryAbbreviation: 'CI' },
  { country: 'Croatie', countryAbbreviation: 'HR' },
  { country: 'Cuba', countryAbbreviation: 'CU' },
  { country: 'Danemark', countryAbbreviation: 'DK' },
  { country: 'Djibouti', countryAbbreviation: 'DJ' },
  { country: 'Dominique', countryAbbreviation: 'DM' },
  { country: 'Égypte', countryAbbreviation: 'EG' },
  { country: 'Émirats arabes unis', countryAbbreviation: 'AE' },
  { country: 'Équateur', countryAbbreviation: 'EC' },
  { country: 'Érythrée', countryAbbreviation: 'ER' },
  { country: 'Espagne', countryAbbreviation: 'ES' },
  { country: 'Eswatini', countryAbbreviation: 'SZ' },
  { country: 'Estonie', countryAbbreviation: 'EE' },
  { country: 'États-Unis', countryAbbreviation: 'US' },
  { country: 'Éthiopie', countryAbbreviation: 'ET' },
  { country: 'Fidji', countryAbbreviation: 'FJ' },
  { country: 'Finlande', countryAbbreviation: 'FI' },
  { country: 'France', countryAbbreviation: 'FR' },
  { country: 'Gabon', countryAbbreviation: 'GA' },
  { country: 'Gambie', countryAbbreviation: 'GM' },
  { country: 'Géorgie', countryAbbreviation: 'GE' },
  { country: 'Ghana', countryAbbreviation: 'GH' },
  { country: 'Grèce', countryAbbreviation: 'GR' },
  { country: 'Grenade', countryAbbreviation: 'GD' },
  { country: 'Guatemala', countryAbbreviation: 'GT' },
  { country: 'Guinée', countryAbbreviation: 'GN' },
  { country: 'Guinée équatoriale', countryAbbreviation: 'GQ' },
  { country: 'Guinée-Bissau', countryAbbreviation: 'GW' },
  { country: 'Guyana', countryAbbreviation: 'GY' },
  { country: 'Haïti', countryAbbreviation: 'HT' },
  { country: 'Honduras', countryAbbreviation: 'HN' },
  { country: 'Hongrie', countryAbbreviation: 'HU' },
  { country: 'Îles Cook', countryAbbreviation: 'CK' },
  { country: 'Îles Marshall', countryAbbreviation: 'MH' },
  { country: 'Inde', countryAbbreviation: 'IN' },
  { country: 'Indonésie', countryAbbreviation: 'ID' },
  { country: 'Irak', countryAbbreviation: 'IQ' },
  { country: 'Iran', countryAbbreviation: 'IR' },
  { country: 'Irlande', countryAbbreviation: 'IE' },
  { country: 'Islande', countryAbbreviation: 'IS' },
  { country: 'Israël', countryAbbreviation: 'IL' },
  { country: 'Italie', countryAbbreviation: 'IT' },
  { country: 'Jamaïque', countryAbbreviation: 'JM' },
  { country: 'Japon', countryAbbreviation: 'JP' },
  { country: 'Jordanie', countryAbbreviation: 'JO' },
  { country: 'Kazakhstan', countryAbbreviation: 'KZ' },
  { country: 'Kenya', countryAbbreviation: 'KE' },
  { country: 'Kirghizistan', countryAbbreviation: 'KG' },
  { country: 'Kiribati', countryAbbreviation: 'KI' },
  { country: 'Koweït', countryAbbreviation: 'KW' },
  { country: 'Laos', countryAbbreviation: 'LA' },
  { country: 'Lesotho', countryAbbreviation: 'LS' },
  { country: 'Lettonie', countryAbbreviation: 'LV' },
  { country: 'Liban', countryAbbreviation: 'LB' },
  { country: 'Liberia', countryAbbreviation: 'LR' },
  { country: 'Libye', countryAbbreviation: 'LY' },
  { country: 'Liechtenstein', countryAbbreviation: 'LI' },
  { country: 'Lituanie', countryAbbreviation: 'LT' },
  { country: 'Luxembourg', countryAbbreviation: 'LU' },
  { country: 'Macédoine', countryAbbreviation: 'MK' },
  { country: 'Madagascar', countryAbbreviation: 'MG' },
  { country: 'Malaisie', countryAbbreviation: 'MY' },
  { country: 'Malawi', countryAbbreviation: 'MW' },
  { country: 'Maldives', countryAbbreviation: 'MV' },
  { country: 'Mali', countryAbbreviation: 'ML' },
  { country: 'Malte', countryAbbreviation: 'MT' },
  { country: 'Maroc', countryAbbreviation: 'MA' },
  { country: 'Maurice', countryAbbreviation: 'MU' },
  { country: 'Mauritanie', countryAbbreviation: 'MR' },
  { country: 'Mexique', countryAbbreviation: 'MX' },
  { country: 'Micronésie', countryAbbreviation: 'FM' },
  { country: 'Moldavie', countryAbbreviation: 'MD' },
  { country: 'Monaco', countryAbbreviation: 'MC' },
  { country: 'Mongolie', countryAbbreviation: 'MN' },
  { country: 'Monténégro', countryAbbreviation: 'ME' },
  { country: 'Mozambique', countryAbbreviation: 'MZ' },
  { country: 'Namibie', countryAbbreviation: 'NA' },
  { country: 'Nauru', countryAbbreviation: 'NR' },
  { country: 'Népal', countryAbbreviation: 'NP' },
  { country: 'Nicaragua', countryAbbreviation: 'NI' },
  { country: 'Niger', countryAbbreviation: 'NE' },
  { country: 'Nigeria', countryAbbreviation: 'NG' },
  { country: 'Niue', countryAbbreviation: 'NU' },
  { country: 'Norvège', countryAbbreviation: 'NO' },
  { country: 'Nouvelle-Zélande', countryAbbreviation: 'NZ' },
  { country: 'Oman', countryAbbreviation: 'OM' },
  { country: 'Ouganda', countryAbbreviation: 'UG' },
  { country: 'Ouzbékistan', countryAbbreviation: 'UZ' },
  { country: 'Pakistan', countryAbbreviation: 'PK' },
  { country: 'Palaos', countryAbbreviation: 'PW' },
  { country: 'Palestine', countryAbbreviation: 'PS' },
  { country: 'Panama', countryAbbreviation: 'PA' },
  { country: 'Papouasie-Nouvelle-Guinée', countryAbbreviation: 'PG' },
  { country: 'Paraguay', countryAbbreviation: 'PY' },
  { country: 'Pays-Bas', countryAbbreviation: 'NL' },
  { country: 'Pérou', countryAbbreviation: 'PE' },
  { country: 'Philippines', countryAbbreviation: 'PH' },
  { country: 'Pologne', countryAbbreviation: 'PL' },
  { country: 'Portugal', countryAbbreviation: 'PT' },
  { country: 'Qatar', countryAbbreviation: 'QA' },
  { country: 'République centrafricaine', countryAbbreviation: 'CF' },
  { country: 'République démocratique du Congo', countryAbbreviation: 'CD' },
  { country: 'République Dominicaine', countryAbbreviation: 'DO' },
  { country: 'République du Congo', countryAbbreviation: 'CG' },
  { country: 'République tchèque', countryAbbreviation: 'CZ' },
  { country: 'Roumanie', countryAbbreviation: 'RO' },
  { country: 'Royaume-Uni', countryAbbreviation: 'GB' },
  { country: 'Russie', countryAbbreviation: 'RU' },
  { country: 'Rwanda', countryAbbreviation: 'RW' },
  { country: 'Saint-Kitts-et-Nevis', countryAbbreviation: 'KN' },
  { country: 'Saint-Vincent-et-les-Grenadines', countryAbbreviation: 'VC' },
  { country: 'Sainte-Lucie', countryAbbreviation: 'LC' },
  { country: 'Saint-Marin', countryAbbreviation: 'SM' },
  { country: 'Salomon', countryAbbreviation: 'SB' },
  { country: 'Salvador', countryAbbreviation: 'SV' },
  { country: 'Samoa', countryAbbreviation: 'WS' },
  { country: 'São Tomé-et-Principe', countryAbbreviation: 'ST' },
  { country: 'Sénégal', countryAbbreviation: 'SN' },
  { country: 'Serbie', countryAbbreviation: 'RS' },
  { country: 'Seychelles', countryAbbreviation: 'SC' },
  { country: 'Sierra Leone', countryAbbreviation: 'SL' },
  { country: 'Singapour', countryAbbreviation: 'SG' },
  { country: 'Slovaquie', countryAbbreviation: 'SK' },
  { country: 'Slovénie', countryAbbreviation: 'SI' },
  { country: 'Somalie', countryAbbreviation: 'SO' },
  { country: 'Soudan', countryAbbreviation: 'SD' },
  { country: 'Soudan du Sud', countryAbbreviation: 'SS' },
  { country: 'Sri Lanka', countryAbbreviation: 'LK' },
  { country: 'Suède', countryAbbreviation: 'SE' },
  { country: 'Suisse', countryAbbreviation: 'CH' },
  { country: 'Suriname', countryAbbreviation: 'SR' },
  { country: 'Syrie', countryAbbreviation: 'SY' },
  { country: 'Tadjikistan', countryAbbreviation: 'TJ' },
  { country: 'Tanzanie', countryAbbreviation: 'TZ' },
  { country: 'Tchad', countryAbbreviation: 'TD' },
  { country: 'Thaïlande', countryAbbreviation: 'TH' },
  { country: 'Timor oriental', countryAbbreviation: 'TL' },
  { country: 'Togo', countryAbbreviation: 'TG' },
  { country: 'Tonga', countryAbbreviation: 'TO' },
  { country: 'Trinité-et-Tobago', countryAbbreviation: 'TT' },
  { country: 'Tunisie', countryAbbreviation: 'TN' },
  { country: 'Turkménistan', countryAbbreviation: 'TM' },
  { country: 'Turquie', countryAbbreviation: 'TR' },
  { country: 'Tuvalu', countryAbbreviation: 'TV' },
  { country: 'Ukraine', countryAbbreviation: 'UA' },
  { country: 'Uruguay', countryAbbreviation: 'UY' },
  { country: 'Vanuatu', countryAbbreviation: 'VU' },
  { country: 'Vatican', countryAbbreviation: 'VA' },
  { country: 'Venezuela', countryAbbreviation: 'VE' },
  { country: 'Viêt Nam', countryAbbreviation: 'VN' },
  { country: 'Yémen', countryAbbreviation: 'YE' },
  { country: 'Zambie', countryAbbreviation: 'ZM' },
  { country: 'Zimbabwe', countryAbbreviation: 'ZW' },
]
const CountryNameOption = () => {
  return (
    <>
      {countries.map((item, index) => (
        <option value={JSON.stringify(item)} key={index}>
          {item.country}
        </option>
      ))}
    </>
  )
}

export { CountryNameOption, countries }
