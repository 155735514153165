import { useContext } from 'react'
import styles from '../../../../css/modehotes/annonce/details/detail.module.css'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import { getAnnonce } from '../../../../redux/actions'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { User } from '../../../../context/UserContext'
import LoadingPoint from '../../../../components/LoadingPoint'
import ListAnnonce from '../../../../components/hote_mode/annonce/details/defaults/ListAnnonce'
import HeadDetail from '../../../../components/hote_mode/annonce/details/defaults/HeadDetail'
import Navigation from '../../../../components/hote_mode/annonce/details/defaults/Navigation'
import CancellationCondition from '../../../../components/hote_mode/annonce/details/terms_and_conditions/cancellations/CancellationCondition'
import InstantBooking from '../../../../components/hote_mode/annonce/details/terms_and_conditions/instant_bookings/InstantBooking'
import ArrivalTime from '../../../../components/hote_mode/annonce/details/terms_and_conditions/arrival_times/ArrivalTime'
import DepartureTime from '../../../../components/hote_mode/annonce/details/terms_and_conditions/departure_times/DepartureTime'
import ChildrenRegulation from '../../../../components/hote_mode/annonce/details/terms_and_conditions/internal_regulations/ChildrenRegulation'
import BabyRegulation from '../../../../components/hote_mode/annonce/details/terms_and_conditions/internal_regulations/BabyRegulation'
import PetRegulation from '../../../../components/hote_mode/annonce/details/terms_and_conditions/internal_regulations/PetRegulation'
import SmokerRegulation from '../../../../components/hote_mode/annonce/details/terms_and_conditions/internal_regulations/SmokerRegulation'
import EventRegulation from '../../../../components/hote_mode/annonce/details/terms_and_conditions/internal_regulations/EventRegulation'
import AdditionalRule from '../../../../components/hote_mode/annonce/details/terms_and_conditions/internal_regulations/AdditionalRule'
import LocalLaw from '../../../../components/hote_mode/annonce/details/terms_and_conditions/local_laws/LocalLaw'
import PayementMode from '../../../../components/hote_mode/annonce/details/terms_and_conditions/payments/PayementMode'
import ErrorServer from '../../../errors/ErrorServer'
import HostModeLayout from '../../../../layouts/HostModeLayout'
import { SATATUS_ANNONCE } from '../../../../enums/logement/status.enums'

const ConditionAndReglement = () => {
  const user = useContext(User)
  const history = useHistory()
  const location = useLocation()
  const path = location.pathname.split('/')[4]
  const dispatch = useDispatch()
  const annonce = useSelector(state => state.updateAnnonce)

  const { isLoading, error } = useQuery(`hote-get-annonce-details-${path}`, () =>
    axios.get(`/hotel/oneAnnonce/${path}`).then(res => {
      dispatch(getAnnonce(res.data))
      if (res.data.status === SATATUS_ANNONCE.IN_PROGRESS) {
        history.push(`/become-a-host/${res.data.redirect_url}/${res.data._id}`)
      }
      if (res.data.status === SATATUS_ANNONCE.SAVE) {
        history.push(`/verify-listing/${res.data._id}`)
      }
    })
  )

  if (error) {
    return <ErrorServer />
  }

  if (user === null) {
    history.push('/')
  }

  return (
    <HostModeLayout>
      <Helmet>
        <title>Modifier l'annonce : {annonce?.title ?? ''} - Gowifmi</title>
      </Helmet>
      <div className={styles.voyage_container}>
        <ListAnnonce />
        <div className={styles.block_details}>
          <HeadDetail annonce={annonce} />
          <Navigation />
          {isLoading ? (
            <div className={styles.loading}>
              <LoadingPoint />
            </div>
          ) : (
            <div>
              <PayementMode annonce={annonce} />
              <InstantBooking annonce={annonce} />
              <CancellationCondition annonce={annonce} />
              <ArrivalTime annonce={annonce} titre='' />
              <DepartureTime annonce={annonce} />
              <AdditionalRule annonce={annonce} />
              <LocalLaw />
              <ChildrenRegulation annonce={annonce} />
              <BabyRegulation annonce={annonce} />
              <PetRegulation annonce={annonce} />
              <SmokerRegulation annonce={annonce} />
              <EventRegulation annonce={annonce} />
            </div>
          )}
        </div>
      </div>
    </HostModeLayout>
  )
}

export default ConditionAndReglement
