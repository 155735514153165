import styles from '../../../../../css/modehotes/annonce/details/equipement.module.css'
import useUpdateHotel from '../../../../../hooks/useUpdateHotel'
import Checkbox from '../../../../ui/Checkbox'

const MuchRequest = ({ equipements, setEquipements }) => {
  const { updateData } = useUpdateHotel()

  const array = [
    { name: 'Piscine', value: '3' },
    { name: 'Jacuzzi', value: '14' },
    { name: 'Patio', value: '62' },
    { name: 'Barbecue', value: '48' },
    { name: 'Brasero', value: '63' },
    { name: 'Billard', value: '64' },
    { name: 'Cheminée', value: '16' },
    { name: 'Appareils de fitness', value: '65' },
    { name: 'Espace repas en plein air', value: '47' },

    { name: 'Wifi', value: '1' },
    { name: 'Télévision', value: '10' },
    { name: 'Cuisine', value: '4' },
    { name: 'Lave-linge', value: '6' },
    { name: 'Parking', value: '2' },
    { name: 'Parking gratuit sur place', value: '112' },
    { name: 'Climatisation', value: '8' },
    { name: 'Espace de travail dédié', value: '13' },
    { name: 'Douche extérieure', value: '58' },

    { name: 'Détecteur de fumée', value: '19' },
    { name: 'Kit de premiers secours', value: '61' },
    { name: 'Détecteur de monoxyde de carbone', value: '18' },
    { name: 'Porte de la chambre avec verrou', value: '60' },
    { name: 'Extincteur', value: '59' },
  ]
  const onchangeFilters = e => {
    if (e.target.checked === true) {
      setEquipements([...equipements, e.target.value])
      const datas = {
        amenities: [...equipements, e.target.value],
      }
      updateData(datas)
    } else {
      const newArr = [...equipements].filter(item => item !== e.target.value)
      setEquipements(newArr)
      const datas = {
        amenities: newArr,
      }
      updateData(datas)
    }
  }
  return (
    <div className={styles.container_equip}>
      <h4>Très demandés</h4>
      {array.map(item => (
        <div key={item.name} className={styles.checkbox_container}>
          <label htmlFor={item.name}>{item.name}</label>
          <div>
            <Checkbox
              id={item.name}
              value={item.value}
              checked={equipements.includes(item.value)}
              onChange={onchangeFilters}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default MuchRequest
