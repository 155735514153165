import { useEffect, useState, useContext } from 'react'
import styles from '../../css/confirmations/confirmation.module.css'
import { useLocation } from 'react-router'
import { useQuery } from 'react-query'
import { User } from '../../context/UserContext'
import axios from 'axios'
import Loading from '../../components/booking/Loading'
import ErrorServer from '../errors/ErrorServer'
import { useStripe, Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { stripeApiKey } from '../../config/config'
import NavConfirm from '../../components/confirmation_booking/NavConfirm'
import Block1 from '../../components/confirmation_booking/Block1'
import Block2 from '../../components/confirmation_booking/Block2'
import Connexion from '../../components/confirmation_booking/Connexion'
import StatusSetupIntent from '../../components/confirmation_booking/StatusSetupIntent'
import DefaultLayout from '../../layouts/DefaultLayout'
import { Helmet } from 'react-helmet-async'

const stripePromise = loadStripe(stripeApiKey)

const Wrapper = props => (
  <Elements stripe={stripePromise}>
    <ConfirmSetup />
  </Elements>
)

const ConfirmSetup = () => {
  const [setupIntentObject, setSetupIntentObject] = useState(null)

  const stripe = useStripe()
  const location = useLocation()
  const bookingID = location.pathname.split('/')[3]
  const user = useContext(User)

  const {
    data = {},
    error,
    isLoading,
  } = useQuery(`get-reservation-${bookingID}`, () =>
    axios.get(`/user_reservations/${bookingID}`).then(res => {
      return res.data
    })
  )
  useEffect(() => {
    window.scrollTo(0, 0)

    if (!stripe) {
      return
    }
    const clientSecret = new URLSearchParams(window.location.search).get(
      'setup_intent_client_secret'
    )
    if (!clientSecret) {
      return
    }
    stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
      setSetupIntentObject(setupIntent)

      if (setupIntent.status === 'requires_action') return

      const data = {
        setupIntent: setupIntent,
      }
      axios
        .patch(`/stripe/confirm-setup/${bookingID}`, data)
        .then(res => {})
        .catch(() => {})
    })
  }, [stripe, bookingID])

  if (error) return <ErrorServer />

  if (!user) return <Connexion />

  return (
    <>
      <Helmet>
        <title>Confirmation de la réservation : {data?.reservation?.hotel?.title ?? ''}</title>
      </Helmet>
      <NavConfirm reservation={data.reservation} />

      {isLoading ? (
        <Loading />
      ) : (
        <DefaultLayout footer={true} mobileBottomNavigate={true}>
          <div className={styles.hotel_confirmation}>
            <StatusSetupIntent
              setupIntentObject={setupIntentObject}
              user={user}
              id={data.reservation?.hotel?._id}
            />
            <div className={styles.recap_block}>
              <Block1 reservation={data.reservation} paymentObject={setupIntentObject} />
              {/*---------block recap et image-----*/}
              <Block2 reservation={data.reservation} />
            </div>
          </div>
        </DefaultLayout>
      )}
    </>
  )
}
export default Wrapper
