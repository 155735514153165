import styles from '../../../../css/details/other_hotel.module.css'
import { Link } from 'react-router-dom'
import CardComment from '../CardComment'
import { TYPE_LOGEMENT } from '../../../../enums/logement/type_logement.enums'
import usePrice from '../../../../hooks/usePrice'

const Slider = ({ item }) => {
  const { reduction, pricePerNightWithoutPromo, pricePerNightWithPromo } = usePrice(item)

  const handleClick = () => {
    window.scrollTo(0, 0)
  }

  return (
    <div className={styles.box_image}>
      <Link to={`/rooms/${item._id}`} className={styles.image} onClick={handleClick}>
        <img src={item.images[0].url} alt='couverture' />
      </Link>
      <Link to={`/rooms/${item._id}`} onClick={handleClick}>
        <div className={styles.recomande_desc}>
          <div>
            <CardComment item={item} />
            <div className={styles.descrip_detail}>
              {item.accommodation_type === TYPE_LOGEMENT.ENTIRE_HOME && (
                <p>
                  Logement entier ·{' '}
                  {item.address?.state || item.address?.city || item.address?.country}
                </p>
              )}
              {item.accommodation_type === TYPE_LOGEMENT.PRIVATE_ROOM && (
                <p>
                  Chambre privée ·{' '}
                  {item.address?.state || item.address?.city || item.address?.country}
                </p>
              )}
              {item.accommodation_type === TYPE_LOGEMENT.SHARED_ROOM && (
                <p>
                  Chambre partagée ·{' '}
                  {item.address?.state || item.address?.city || item.address?.country}
                </p>
              )}
            </div>
            <div className={styles.price_block}>
              {reduction > 0 && <p className={styles.promo}>{pricePerNightWithoutPromo}</p>}
              <div className={styles.price_normal}>
                <p>{pricePerNightWithPromo}</p>
                <span>/ nuit</span>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default Slider
