import { useState, useContext } from 'react'
import styles from '../../../../../css/booking/formulaire.module.css'
import ErrorSvg from '../../../../svgs/ErrorSvg'
import { User } from '../../../../../context/UserContext'
import InputPhoneAndMessage from './infos_user/InputPhoneAndMessage'
import { isValidPhoneNumber } from 'react-phone-number-input'
import ButtonTertiary from '../../../../ui/ButtonTertiary'

const InfosUser = ({
  payOnline,
  message,
  setMessage,
  telephone,
  setTelephone,
  messageError,
  hotel,
  phoneError,
}) => {
  const [modeWrite, setModeWrite] = useState(false)
  const user = useContext(User)

  return (
    <div className={styles.user_infos}>
      <div className={styles.header}>
        <h4>Informations requises pour votre voyage</h4>
        {payOnline ? (
          <>
            {!modeWrite ? (
              <div>
                <div className={styles.required}>
                  {!user?.phone_number || !isValidPhoneNumber(user?.phone_number) ? (
                    <div>
                      <h5>Numéro de téléphone</h5>
                      <p>
                        Ajoutez et confirmez votre numéro de téléphone afin que Gowifmi puisse vous
                        communiquer des informations sur vos voyages.
                      </p>
                    </div>
                  ) : null}
                  <div>
                    <h5>Envoyez un message à l'hôte</h5>
                    <p>
                      Communiquez votre heure d'arrivée et le motif de votre voyage à votre hôte.
                    </p>
                  </div>
                  {phoneError || messageError ? (
                    <div className={styles.error_message}>
                      <div>
                        <ErrorSvg />
                      </div>
                      <span>Ajouter les informations requises.</span>
                    </div>
                  ) : null}
                </div>
                <ButtonTertiary
                  type='button'
                  onClick={() => setModeWrite(true)}
                  className={styles.button}
                >
                  Ajouter
                </ButtonTertiary>
              </div>
            ) : (
              <InputPhoneAndMessage
                message={message}
                setMessage={setMessage}
                telephone={telephone}
                setTelephone={setTelephone}
                messageError={messageError}
                hotel={hotel}
                phoneError={phoneError}
              />
            )}
          </>
        ) : (
          <InputPhoneAndMessage
            message={message}
            setMessage={setMessage}
            telephone={telephone}
            setTelephone={setTelephone}
            messageError={messageError}
            hotel={hotel}
            phoneError={phoneError}
          />
        )}
      </div>
    </div>
  )
}

export default InfosUser
