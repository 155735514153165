import styles from '../../css/errors/error_boundary.module.css'
import BigErrorSvg from '../svgs/BigErrorSvg'
import { useErrorBoundary } from 'react-error-boundary'

const ErrorBoundaryFallback = ({ error }) => {
  const { resetBoundary } = useErrorBoundary()

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div>
          <BigErrorSvg />
        </div>
        <div className={styles.notif_message}>
          <h1>Un problème est survenu, veuillez réessayer</h1>
          <p>{error.message}</p>
          <button onClick={resetBoundary}>Réessayer</button>
        </div>
      </div>
    </div>
  )
}

export default ErrorBoundaryFallback
