import styles from '../../../css/account/profile/commentaire.module.css'
import axios from 'axios'
import moment from 'moment'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router'
import LoadingPoint from '../../LoadingPoint'
import useTravelerRating from '../../../hooks/useTravelerRating'
import StarSvg from '../../svgs/StarSvg'

const HostComment = () => {
  const location = useLocation()
  const path = location.pathname.split('/')[2]

  const {
    data: hostCommenter = [],
    isLoading,
    error,
  } = useQuery(`get-hostComment-${path}`, () =>
    axios.get(`/traveler_comment/all_for_traveler/${path}`).then(res => {
      return res.data
    })
  )

  const { commentLength, totalComment } = useTravelerRating(hostCommenter)

  return (
    <>
      <div className={styles.container}>
        {isLoading ? (
          <LoadingPoint />
        ) : (
          <div className={styles.profil_commentaire}>
            {error && <p>Les commentaires n'arrivent pas à charger veuillez réesseyer</p>}
            {commentLength > 0 ? (
              <div className={styles.block_eval}>
                <StarSvg width={24} height={24} />
                <h3>
                  {totalComment} ({commentLength} Commentaire{commentLength > 1 ? 's' : ''}) de la
                  part des hôtes
                </h3>
              </div>
            ) : (
              <h3>Aucun commentaire (pour l'instant)</h3>
            )}
            <div className={styles.comment_list}>
              {hostCommenter.map(item => (
                <div key={item._id}>
                  <div className={styles.profil_block_img}>
                    <a
                      href={`/profile/${item.host?.id}`}
                      target='_blank'
                      className={styles.image_block}
                      rel='noreferrer'
                    >
                      {item.host?.picture ? (
                        <img src={item.host?.picture} alt='' />
                      ) : (
                        <h2>{item.host?.first_name.slice(0, 1)}</h2>
                      )}
                    </a>
                    <div className={styles.pro_date}>
                      <h4>
                        {item.host?.first_name}, {item.host?.city}
                      </h4>
                      <p>Membre depuis {moment(item.host?.createdAt).format('LL').slice(2)}</p>
                    </div>
                  </div>
                  <div className={styles.message}>
                    <div className={styles.hotel_img}>
                      <h4>{item.hotel?.title}</h4>
                      <div className={styles.hotel_img_card}>
                        <img src={item.hotel?.images[0].url} alt='couverture' />
                      </div>
                    </div>
                    <p>{item.comment}</p>
                    <h5>{moment(item.createdAt).format('LL').slice(2)}</h5>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default HostComment
