import styles from '../../../../css/traveler/inbox/detailMessage.module.css'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import LoadingPoint from '../../../LoadingPoint'
import momentTimeZone from 'moment-timezone'
import axios from 'axios'
import Head from './Head'
import { useWebSocket } from '../../../../hooks/useWebSocket'

const AllMessage = ({ path, user }) => {
  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  const filter = Params.get('filter')

  const userTimezone = momentTimeZone.tz.guess()

  const {
    data: filtermessages,
    isLoading,
    refetch,
  } = useQuery(`get-allmessage-detail-user-${path}-${filter}}`, () =>
    axios.get(`/message/filtermessage?filter=${filter}`).then(res => {
      return res.data
    })
  )

  useWebSocket(() => {
    refetch()
  })

  return (
    <div className={styles.block_grid_1}>
      <Head filter={filter} />
      {isLoading ? (
        <div className={styles.loading_container}>
          <LoadingPoint />
        </div>
      ) : (
        <>
          {filtermessages.length > 0 ? (
            <div className={styles.liste}>
              {filtermessages.map(message => (
                <Link
                  to={`/inbox/detail/${message.host?.id}?filter=${filter}`}
                  key={message._id}
                  className={
                    message.host?.id === path
                      ? `${styles.liste_content} ${styles.active}`
                      : styles.liste_content
                  }
                >
                  <div>
                    <div className={styles.img_block}>
                      {message.host?.picture ? (
                        <img src={message.host?.picture} alt='' />
                      ) : (
                        <h2>{message.host?.first_name?.slice(0, 1)}</h2>
                      )}
                    </div>
                  </div>
                  <div className={styles.profil_text}>
                    <div className={!message.message_box[0].user_read ? styles.non_lue : null}>
                      <h5>
                        {message.host?.first_name} - <span>{message.hotel?.title ?? 'Hôte'}</span>
                      </h5>
                      <div className={styles.last_message}>
                        {message.message_box[0].userId.id === user?.id && <span>Vous:</span>}
                        <p>{message.message_box[0].text}</p>
                      </div>
                    </div>
                    <h6>
                      {momentTimeZone
                        .utc(message.message_box[0].timestamp)
                        .tz(userTimezone)
                        .calendar()}
                    </h6>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <div className={styles.no_result}>
              <p>Aucun resultat</p>
            </div>
          )}
        </>
      )}
    </div>
  )
}
export default AllMessage
