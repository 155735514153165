import styles from '../../../css/becomeHostes/home/section_4.module.css'
import { Link } from 'react-router-dom'
import salon from '../../../assets/become_host/salon.jpg'
import LoadingPoint from '../../LoadingPoint'

const Section4 = ({ user, isLoading, referrer, handleConnection }) => {
  return (
    <div className={styles.section_4}>
      <div className={styles.action_block}>
        <div className={styles.img_block}>
          <img src={salon} alt='' />
        </div>
        <div className={styles.text}>
          <div>
            <h4>Vous aussi devenez hôte sur Gowifmi</h4>
            <p>
              L'inscription est gratuite et peut prendre seulement 10 minutes. Commencez dès
              aujourd'hui !
            </p>
            {user ? (
              <div>
                {isLoading ? (
                  <button type='button'>
                    <LoadingPoint />
                  </button>
                ) : (
                  <Link
                    to={
                      user.isHost
                        ? `/become-a-host`
                        : `/become-a-host/type-property-group${
                            referrer ? `?referrer=${referrer?.id}` : ''
                          }`
                    }
                  >
                    Je deviens hôte
                  </Link>
                )}
              </div>
            ) : (
              <button onClick={handleConnection}>Je deviens hôte</button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section4
