import React, { useState, useContext } from 'react'
import styles from '../../css/add_phone_number/add_phone.module.css'
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import Modal from '../ui/Modal'
import CloseButton from '../ui/CloseButton'
import LoadingPoint from '../LoadingPoint'
import axios from 'axios'
import { User } from '../../context/UserContext'

const AddPhoneNumber = React.forwardRef((props, ref) => {
  const [modal, setModal] = useState(false)
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)
  const user = useContext(User)

  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      setModal(true)
    },
  }))
  const handleSubmit = e => {
    e.preventDefault()
    const data = {
      phone_number: formatPhoneNumberIntl(value),
    }
    setLoading(true)
    axios
      .patch(`/user/${user.id}`, data)
      .then(() => {
        window.location.reload()
      })
      .catch(() => {
        alert('error server')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal isOpen={modal} onClose={() => setModal(false)}>
      <div className={styles.modal_content}>
        <div className={styles.m_head}>
          <CloseButton onClick={() => setModal(false)} />
          <h3>Ajoutez votre numéro de téléphone</h3>
          <div></div>
        </div>
        <form onSubmit={handleSubmit} autoComplete='off' className={styles.m_body}>
          <div className={styles.input_box_phone}>
            <PhoneInput
              international
              placeholder='Numéro de téléphone'
              value={value}
              onChange={setValue}
            />
            {value && !isValidPhoneNumber(value) && (
              <span className={styles.erreur}>Saisissez un numéro de téléphone valide</span>
            )}
          </div>
          <div className={styles.btn_action}>
            {loading ? (
              <button type='button' disabled className={styles.loading}>
                <LoadingPoint />
              </button>
            ) : (
              <button
                type='submit'
                disabled={!value || (value && !isValidPhoneNumber(value))}
                className={!value || (value && !isValidPhoneNumber(value)) ? styles.loading : null}
              >
                Enregistrer
              </button>
            )}
          </div>
        </form>
      </div>
    </Modal>
  )
})

export default AddPhoneNumber
