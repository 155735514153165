import style from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import Checkbox from '../../../../../ui/Checkbox'
const Options = ({ langues, setLangues }) => {
  const array = [
    { name: 'Anglais', value: '1' },
    { name: 'Français', value: '2' },
    { name: 'Allemand', value: '3' },
    { name: 'Japonais', value: '4' },
    { name: 'Italien', value: '5' },
    { name: 'Russe', value: '6' },
    { name: 'Espagnol', value: '7' },
    { name: 'Chinois (simplifié)', value: '8' },
    { name: 'Arabe', value: '9' },
    { name: 'Hindi', value: '10' },
    { name: 'Portugais', value: '11' },
    { name: 'Turc', value: '12' },
    { name: 'Indonésien', value: '13' },
    { name: 'Néerlandais', value: '14' },
    { name: 'Thaï', value: '15' },
    { name: 'Grec', value: '16' },
    { name: 'Polonais', value: '17' },
    { name: 'Malais', value: '18' },
    { name: 'Tagalog', value: '19' },
    { name: 'Danois', value: '20' },
    { name: 'Suédois', value: '21' },
    { name: 'Norvégien', value: '22' },
    { name: 'Finnois', value: '23' },
    { name: 'Tchèque', value: '24' },
    { name: 'Hongrois', value: '25' },
  ]
  const onchangeFilters = e => {
    if (e.target.checked === true) {
      setLangues([...langues, e.target.value])
    } else {
      const newArr = [...langues].filter(item => item !== e.target.value)
      setLangues(newArr)
    }
  }
  return (
    <>
      <div className={style.grid_system}>
        {array.map(item => (
          <div key={item.name} className={style.checkbox_container}>
            <div>
              <Checkbox
                id={item.name}
                value={item.value}
                checked={langues.includes(item.value)}
                onChange={onchangeFilters}
              />
            </div>
            <label htmlFor={item.name}>{item.name}</label>
          </div>
        ))}
      </div>
    </>
  )
}

export default Options
