import { useState } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const InfosWifi = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [nomWifi, setNomWifi] = useState('')
  const [password, setPassword] = useState('')
  const { loading, updateData } = useUpdateHotel(setOpen)

  const handleSubmit = e => {
    e.preventDefault()
    const datas = {
      traveler_information: {
        ...annonce.traveler_information,
        wifi_name: nomWifi,
        wifi_password: password,
      },
    }
    updateData(datas)
  }
  const openFuction = () => {
    setNomWifi(annonce.traveler_information?.wifi_name || '')
    setPassword(annonce.traveler_information?.wifi_password || '')
    setOpen(true)
  }
  const reset = () => {
    setNomWifi('')
    setPassword('')
    setOpen(false)
  }
  return (
    <div className={styles.block}>
      {!open ? (
        <Section title='Informations du wifi' handleOpen={openFuction}>
          <p>Nom : {annonce.traveler_information?.wifi_name || 'Non défini'}</p>
          <p>Mot de passe : {annonce.traveler_information?.wifi_password || 'Non défini'}</p>
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title='Informations du wifi' handleReset={reset}></ContenHead>
          <form onSubmit={handleSubmit}>
            <div className={styles.body}>
              <div className={styles.input_block}>
                <h4>Nom du réseau wifi</h4>
                <input type='text' value={nomWifi} onChange={e => setNomWifi(e.target.value)} />
              </div>
              <div className={styles.input_block}>
                <h4>Mot de passe du wifi</h4>
                <input type='text' value={password} onChange={e => setPassword(e.target.value)} />
              </div>
            </div>
            <BottomForm loading={loading} handleReset={reset} />
          </form>
        </div>
      )}
    </div>
  )
}

export default InfosWifi
