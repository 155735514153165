import styles from '../../../css/affiliations/navigation.module.css'
import { Link } from 'react-router-dom'

const Navigation = ({}) => {
  const path = window.location.pathname.split('/')[2]

  return (
    <>
      <div className={styles.home_nav}>
        <div className={styles.home_logo}>
          <a href='/'>
            <svg
              width='30'
              height='30'
              viewBox='0 0 30 30'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M0 3.94737C0 1.7673 1.7673 0 3.94737 0H26.0526C28.2327 0 30 1.7673 30 3.94737V26.0526C30 28.2327 28.2327 30 26.0526 30H3.94737C1.7673 30 0 28.2327 0 26.0526V3.94737Z'
                fill='black'
              />
              <path
                d='M6.66666 14.9153C6.66666 10.6626 8.08469 7.66129 10.9207 5.91129C12.4165 4.97043 14.2521 4.5 16.4277 4.5C18.5839 4.5 20.6138 4.83873 22.5175 5.51615C22.7161 5.59014 22.8545 5.86556 22.7863 6.06619L22.2146 7.7466C22.1344 7.9825 21.9157 8.11082 21.6725 8.05645C21.4394 7.98119 21.1966 7.91533 20.9441 7.85887C20.711 7.7836 20.4001 7.70834 20.0116 7.63307C19.6231 7.5578 19.2541 7.49194 18.9044 7.43549C18.1274 7.28495 17.3019 7.20969 16.4277 7.20969C14.2521 7.20969 12.6593 7.80242 11.6492 8.98791C10.6585 10.1734 10.1632 12.168 10.1632 14.9718C10.1632 18.1519 10.7362 20.2782 11.8823 21.3508C12.465 21.8965 13.1061 22.2729 13.8054 22.4798C14.5047 22.6868 15.3302 22.7903 16.282 22.7903C16.4812 22.7903 16.693 22.7874 16.9176 22.7817C18.6705 22.7367 20.0116 21.2461 20.0116 19.4927V17.9552C20.0116 17.1085 20.6486 16.3974 21.4901 16.3044C22.4736 16.1958 23.3333 16.9658 23.3333 17.9552V23.856C23.3333 24.1716 23.0845 24.4865 22.7797 24.5685C22.4301 24.6438 22.1095 24.7191 21.8182 24.7944C21.5268 24.8508 21.1189 24.9261 20.5944 25.0202C20.0893 25.1142 19.6037 25.1989 19.1375 25.2742C17.972 25.4247 16.8259 25.5 15.6993 25.5C12.805 25.5 10.5711 24.6062 8.99766 22.8185C7.44366 21.0309 6.66666 18.3965 6.66666 14.9153Z'
                fill='white'
              />
              <path
                d='M22.8333 13.8333C22.8333 14.4777 22.311 15 21.6667 15C21.0223 15 20.5 14.4777 20.5 13.8333C20.5 13.189 21.0223 12.6667 21.6667 12.6667C22.311 12.6667 22.8333 13.189 22.8333 13.8333Z'
                fill='white'
              />
            </svg>
          </a>
        </div>
        <div className={styles.links_block}>
          <Link to='/sponsorship/sponsored' className={path === 'sponsored' ? styles.active : ''}>
            Vos filleuls
          </Link>
          <Link to='/sponsorship/revenues' className={path === 'revenues' ? styles.active : ''}>
            Vos revenus
          </Link>
        </div>
      </div>
    </>
  )
}

export default Navigation
