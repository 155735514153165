import styles from '../../../../../css/modehotes/annonce/details/equipement.module.css'
import useUpdateHotel from '../../../../../hooks/useUpdateHotel'
import Checkbox from '../../../../ui/Checkbox'

const Entertainment = ({ equipements, setEquipements }) => {
  const { updateData } = useUpdateHotel()

  const array = [
    { name: 'Connexion Ethernet', value: '29' },
    {
      name: 'TV HD avec Disney+, HBO Max, Netflix, télévision par câble haut de gamme, abonnement standard au câble',
      value: '30',
    },
    {
      name: "Adapté aux événements, le logement permet d'accueillir un groupe de 25 personnes ou plus",
      value: '55',
    },
    { name: 'Livres et de quoi lire', value: '31' },
    { name: 'Console de jeu', value: '73' },
    { name: 'Piano', value: '74' },
    { name: 'Table à ping pong', value: '75' },
    { name: 'Billard', value: '64' },
    { name: 'Tourne disque', value: '76' },
    { name: 'Système audio', value: '77' },
    { name: 'Télévision', value: '10' },
  ]
  const onchangeFilters = e => {
    if (e.target.checked === true) {
      setEquipements([...equipements, e.target.value])
      const datas = {
        amenities: [...equipements, e.target.value],
      }
      updateData(datas)
    } else {
      const newArr = [...equipements].filter(item => item !== e.target.value)
      setEquipements(newArr)
      const datas = {
        amenities: newArr,
      }
      updateData(datas)
    }
  }
  return (
    <div className={styles.container_equip}>
      <h4>Divertissement</h4>
      {array.map(item => (
        <div key={item.name} className={styles.checkbox_container}>
          <label htmlFor={item.name}>{item.name}</label>
          <div>
            <Checkbox
              id={item.name}
              value={item.value}
              checked={equipements.includes(item.value)}
              onChange={onchangeFilters}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default Entertainment
