import { useState } from 'react'
import styles from '../../../css/account/profile/navigation.module.css'
import TravelerComment from './TravelerComment'
import HostComment from './HostComment'
import MyComment from './MyComment'
import AllAnnonce from './AllAnnonce'

const Navigation = ({ userConnected, getUser }) => {
  const [active, setActive] = useState(1)

  return (
    <>
      <div className={styles.container}>
        <div className={styles.link_reservation}>
          <button
            type='button'
            className={active === 1 ? styles.active : null}
            onClick={() => setActive(1)}
          >
            Commentaires des voyageurs
          </button>
          <button
            type='button'
            className={active === 2 ? styles.active : null}
            onClick={() => setActive(2)}
          >
            Commentaires des hôtes
          </button>
          <button
            type='button'
            className={active === 3 ? styles.active : null}
            onClick={() => setActive(3)}
          >
            Toutes les annonces
          </button>
          {userConnected?.id === getUser?.id && (
            <button
              type='button'
              className={active === 4 ? styles.active : null}
              onClick={() => setActive(4)}
            >
              Commentaires de votre part
            </button>
          )}
        </div>
      </div>
      {active === 1 && <TravelerComment />}
      {active === 2 && <HostComment />}
      {active === 3 && <AllAnnonce />}
      {active === 4 && <MyComment />}
    </>
  )
}

export default Navigation
