import { useState } from 'react'
import globalStyle from '../../../css/becomeHostes/globalStyle.module.css'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { useLocation } from 'react-router'
import LoadingPoint from '../../LoadingPoint'
import Logement from './Logement'
import Maison from './Maison'
import Annexe from './Annexe'
import LogementUnique from './LogementUnique'
import LogementNonResidenciel from './LogementNonResidenciel'
import Hotel from './Hotel'
import ErrorServer from '../errorServer/ErrorServer'
import useAddHome from '../../../hooks/useAddHome'
import { PROPERTY_TYPE_GROUP } from '../../../enums/logement/type_property_group.enums'
import { SATATUS_ANNONCE } from '../../../enums/logement/status.enums'
import HeaderForm from '../globals/HeaderForm'
import { useQuery } from 'react-query'

const Form = () => {
  const [loading, setLoading] = useState(false)
  const [hotel, setHotel] = useState({})
  const [typeproperty, setTypeproperty] = useState('')
  const [serverError, setServerError] = useState(false)
  const { updateData } = useAddHome(setLoading, setServerError)

  const history = useHistory()
  const location = useLocation()
  const page = location.pathname.split('/')[2]
  const id = location.pathname.split('/')[3]

  const { isLoading, error } = useQuery(`get-hotel-on-creation-${id}}`, () =>
    axios.get(`/hotel/oneAnnonce/${id}`).then(res => {
      setHotel(res.data)
      setTypeproperty(res.data.property_type)
      if (res.data.status !== SATATUS_ANNONCE.IN_PROGRESS) {
        history.push('/hosting')
      }
    })
  )

  const handleSubmit = () => {
    setLoading(true)
    const datas = {
      redirect_url: page,
      property_type: typeproperty,
    }
    updateData(datas, '/become-a-host/type-accomodation')
  }

  if (error || serverError) {
    return <ErrorServer />
  }

  return (
    <div className={globalStyle.left}>
      <HeaderForm step={2} />
      <div className={globalStyle.block_center}>
        <div className={globalStyle.block_form}>
          {isLoading ? (
            <LoadingPoint />
          ) : (
            <div className={globalStyle.annimcontent}>
              {hotel.property_type_group === PROPERTY_TYPE_GROUP.APARTMENT && (
                <Logement typeproperty={typeproperty} setTypeproperty={setTypeproperty} />
              )}
              {hotel.property_type_group === PROPERTY_TYPE_GROUP.HOUSE && (
                <Maison typeproperty={typeproperty} setTypeproperty={setTypeproperty} />
              )}
              {hotel.property_type_group === PROPERTY_TYPE_GROUP.HOTEL && (
                <Hotel typeproperty={typeproperty} setTypeproperty={setTypeproperty} />
              )}
              {hotel.property_type_group === PROPERTY_TYPE_GROUP.ANNEX && (
                <Annexe typeproperty={typeproperty} setTypeproperty={setTypeproperty} />
              )}
              {hotel.property_type_group === PROPERTY_TYPE_GROUP.UNIQUE_ACCOMMODATION && (
                <LogementUnique typeproperty={typeproperty} setTypeproperty={setTypeproperty} />
              )}
              {hotel.property_type_group ===
                PROPERTY_TYPE_GROUP.OTHER_NON_RESIDENTIAL_ACCOMMODATION && (
                <LogementNonResidenciel
                  typeproperty={typeproperty}
                  setTypeproperty={setTypeproperty}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className={globalStyle.step_buttons}>
        <button
          type='button'
          onClick={() => history.push(`/become-a-host/type-property-group/${id}`)}
          className={globalStyle.retour}
        >
          Retour
        </button>
        {loading ? (
          <button disabled className={globalStyle.loading}>
            <LoadingPoint />
          </button>
        ) : (
          <button
            type='button'
            onClick={handleSubmit}
            disabled={!typeproperty}
            className={
              !typeproperty ? `${globalStyle.next} ${globalStyle.disable}` : globalStyle.next
            }
          >
            Suivant
          </button>
        )}
      </div>
    </div>
  )
}

export default Form
