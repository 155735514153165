import { useContext } from 'react'
import styles from '../../../css/traveler/inbox/inbox.module.css'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import axios from 'axios'
import momentTimeZone from 'moment-timezone'
import { User } from '../../../context/UserContext'
import LoadingPoint from '../../../components/LoadingPoint'
import Conneted from '../../../components/traveler/inbox/connexion/Conneted'
import Head from '../../../components/traveler/inbox/details/Head'
import DefaultLayout from '../../../layouts/DefaultLayout'
import { Helmet } from 'react-helmet-async'
import { useWebSocket } from '../../../hooks/useWebSocket'

const ListeMessage = () => {
  const user = useContext(User)
  const location = useLocation()
  const Query = location.search
  const Params = new URLSearchParams(Query)
  const filter = Params.get('filter') || 'all'

  const userTimezone = momentTimeZone.tz.guess()

  const {
    data: filtermessages = [],
    isLoading,
    refetch,
  } = useQuery(
    `get-allmessage-user-${filter}}`,
    () => {
      if (user) {
        return axios.get(`/message/filtermessage?filter=${filter}`).then(res => {
          return res.data
        })
      }
    },
    { refetchOnWindowFocus: false }
  )

  useWebSocket(() => {
    refetch()
  })

  return (
    <DefaultLayout mobileBottomNavigate={true}>
      <Helmet>
        <title>Messages - Gowifmi</title>
      </Helmet>
      {user ? (
        <div className={styles.liste_container}>
          <div className={styles.block_grid}>
            <div className={styles.block_grid_1}>
              <h1 className={styles.boite}>Boîte de réception</h1>
              <Head filter={filter} />
              {isLoading ? (
                <div className={styles.loading_container}>
                  <LoadingPoint />
                </div>
              ) : (
                <>
                  {filtermessages.length > 0 ? (
                    <div className={styles.liste}>
                      {filtermessages.map(message => (
                        <Link
                          to={`/inbox/detail/${message.host?.id}?filter=${filter}`}
                          key={message._id}
                          className={styles.liste_content}
                        >
                          <div>
                            <div className={styles.img_block}>
                              {message.host?.picture ? (
                                <img src={message.host?.picture} alt='' />
                              ) : (
                                <h2>{message.host?.first_name?.slice(0, 1)}</h2>
                              )}
                            </div>
                          </div>
                          <div className={styles.profil_text}>
                            <div
                              className={!message.message_box[0].user_read ? styles.non_lue : null}
                            >
                              <h5>
                                {message.host?.first_name} -{' '}
                                <span>{message.hotel?.title ?? 'Hôte'}</span>
                              </h5>
                              <div className={styles.last_message}>
                                {message.message_box[0].userId.id === user?.id && (
                                  <span>Vous:</span>
                                )}
                                <p>{message.message_box[0].text}</p>
                              </div>
                            </div>
                            <h6>
                              {momentTimeZone
                                .utc(message.message_box[0].timestamp)
                                .tz(userTimezone)
                                .calendar()}
                            </h6>
                          </div>
                        </Link>
                      ))}
                    </div>
                  ) : (
                    <div className={styles.no_result}>
                      <p>Aucun résultat</p>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={styles.block_grid_2}>
              <div className={styles.detail_block}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 32 32'
                  aria-hidden='true'
                  role='presentation'
                  focusable='false'
                  style={{ display: 'block', height: '50px', width: '50px', fill: 'currentcolor' }}
                >
                  <path d='M26 1a5 5 0 0 1 5 4.78v10.9a5 5 0 0 1-4.78 5H26a5 5 0 0 1-4.78 5h-4l-3.72 4.36-3.72-4.36H6a5 5 0 0 1-4.98-4.56L1 21.9 1 21.68V11a5 5 0 0 1 4.78-5H6a5 5 0 0 1 4.78-5H26zm-5 7H6a3 3 0 0 0-3 2.82v10.86a3 3 0 0 0 2.82 3h4.88l2.8 3.28 2.8-3.28H21a3 3 0 0 0 3-2.82V11a3 3 0 0 0-3-3zm-1 10v2H6v-2h14zm6-15H11a3 3 0 0 0-3 2.82V6h13a5 5 0 0 1 5 4.78v8.9a3 3 0 0 0 3-2.82V6a3 3 0 0 0-2.82-3H26zM15 13v2H6v-2h9z'></path>
                </svg>
                <h2>Vos messages</h2>
                <p>
                  Envoyez des messages privés pour discuter et partager des informations sur Gowifmi
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Conneted />
      )}
    </DefaultLayout>
  )
}

export default ListeMessage
