import React, { useState } from 'react'
import styles from '../../../../css/traveler/favoris/parameter.module.css'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'
import LoadingPoint from '../../../../components/LoadingPoint'
import Modal from '../../../ui/Modal'
import CloseButton from '../../../ui/CloseButton'

const Parameter = React.forwardRef(({ favoris, refetch }, ref) => {
  const [modal, setModal] = useState(false)
  const [nom, setNom] = useState('')
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      setModal(true)
      setNom(favoris?.name)
    },
  }))

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    const datas = {
      name: nom,
    }
    axios
      .patch(`/favoris/updateName/${favoris?._id}`, datas)
      .then(res => {
        setLoading(false)
        refetch()
        setModal(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const deleteList = () => {
    setModal(false)

    Swal.fire({
      title: 'Supprimer ces favoris',
      text: `Souhaitez-vous vraiment supprimer la liste ${favoris?.name} ? Toutes les annonces qui y sont sauvegardées seront perdues.`,
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimer',
      cancelButtonText: 'Annuler',
      reverseButtons: true,
      customClass: {
        actions: styles.actions_class,
        confirmButton: styles.confirm_button_class,
        cancelButton: styles.cancel_button_class,
      },
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .delete(`/favoris/${favoris?._id}`)
          .then(() => {
            history.push('/wishlists')
          })
          .catch(() => {})
      }
    })
  }

  return (
    <>
      <Modal isOpen={modal} onClose={() => setModal(false)}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={() => setModal(false)} />
            <h3>Paramètres</h3>
            <button onClick={deleteList} className={styles.delete_btn}>
              Supprimer
            </button>
          </div>
          <form onSubmit={handleSubmit} className={styles.m_body}>
            <div>
              <input
                type='text'
                placeholder='Nom'
                value={nom}
                maxLength={50}
                onChange={e => setNom(e.target.value)}
              />
              <span>50 caractères maximum</span>
            </div>
            <div>
              {loading ? (
                <button disabled className={styles.loading}>
                  <LoadingPoint />
                </button>
              ) : (
                <button
                  type='submit'
                  disabled={!nom}
                  className={!nom ? `${styles.send} ${styles.disable}` : styles.send}
                >
                  Enregistrer
                </button>
              )}
            </div>
          </form>
        </div>
      </Modal>
    </>
  )
})

export default Parameter
