export function confirmBooking(hotel) {
  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  const checkin = Params.get('checkin')

  const date_start = new Date(checkin).setHours(0, 0, 0, 0)
  const date_now = new Date().setHours(0, 0, 0, 0)
  const total_nuits = (date_start - date_now) / (1000 * 60 * 60 * 24)
  const nuit = Math.round(total_nuits)

  if (
    hotel?.regulations?.instant_booking &&
    nuit >= hotel?.availability_calendar?.delai_booking_before_checkin
  ) {
    return true
  } else {
    return false
  }
}
