import styles from '../../../../css/modehotes/hosting/today.module.css'
import { Link } from 'react-router-dom'
import moment from 'moment'
import LoadingPoint from '../../../LoadingPoint'
const ListReservation = ({ active, loading, reservation }) => {
  return (
    <>
      {loading ? (
        <div className={styles.container_load_liste}>
          <LoadingPoint />
        </div>
      ) : (
        <>
          {!reservation.length ? (
            <div className={styles.no_result}>
              <h2>Aucun résultat trouvé</h2>
              {active === 'arriver' && <p>Aucun voyageur n'arrive aujourd'hui ou demain.</p>}
              {active === 'depart' && <p>Aucun voyageur ne part aujourd'hui ou demain.</p>}
              {active === 'encours' && <p>Vous n'accueillez aucun voyageur en ce moment.</p>}
              {active === 'demande' && <p>Aucune demande de réservation aujourd'hui.</p>}
            </div>
          ) : (
            <div>
              <div className={styles.wrapper}>
                {reservation.map(item => (
                  <Link
                    to={`/hosting/reservations/details/${item._id}`}
                    key={item._id}
                    className={styles.card}
                  >
                    <div className={styles.card_img}>
                      <img src={item.hotel?.images[0].url} alt='couverture' />
                    </div>
                    <div className={styles.card_body}>
                      <h2>{item.hotel?.title}</h2>
                      <div className={styles.card_footer}>
                        <div className={styles.card_date}>
                          <p>
                            {moment(item.reservation_infos?.checkin).format('ll')} -{' '}
                            {moment(item.reservation_infos?.checkout).format('ll')}
                          </p>
                          <p>Réference · {item.reservation_infos?.reference}</p>
                        </div>
                        <div>
                          <div className={styles.img_block}>
                            <div className={styles.user_profil}>
                              {item.user_id?.picture ? (
                                <img src={item.user_id?.picture} alt='' />
                              ) : (
                                <h3>{item.user_id?.first_name?.slice(0, 1)}</h3>
                              )}
                            </div>
                            {item.user_id?.isIdentityValid && (
                              <div className={styles.identity}>
                                <svg
                                  viewBox='0 0 16 16'
                                  xmlns='http://www.w3.org/2000/svg'
                                  aria-hidden='true'
                                  role='presentation'
                                  focusable='false'
                                  style={{
                                    display: 'block',
                                    fill: 'currentcolor',
                                    height: '100%',
                                    width: '100%',
                                  }}
                                >
                                  <path d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.159 4.869L6.67 9.355 4.42 7.105 3.289 8.236 6.67 11.62 12.291 6z'></path>
                                </svg>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default ListReservation
