import { Helmet } from 'react-helmet-async'
import styles from '../../css/about_us/about.module.css'
import DefaultLayout from '../../layouts/DefaultLayout'

function TermsAndCondition() {
  return (
    <DefaultLayout footer={true}>
      <Helmet>
        <title>Conditions de service | Gowifmi</title>
      </Helmet>

      <div className={styles.main}>
        <div className={styles.pourquoi_text}>
          <h1>Conditions de service</h1>

          <h2>Conditions relatives aux Voyageurs</h2>
          <h3>Recherche et réservation sur Gowifmi</h3>
          <div>
            <p>
              <strong>Recherche</strong>. Lorsque l'on voyage à travers le monde, il peut être
              difficile de savoir quoi faire ou où aller. Afin de rendre vos voyages plus faciles et
              plus efficaces, nous mettons à votre disposition une variété d'outils spécialement
              conçus pour les voyageurs et les touristes. Vous pouvez rechercher des annonces en
              utilisant des critères tels que la destination, les dates de voyage et le nombre de
              voyageurs. Vous pouvez également utiliser des filtres pour affiner les résultats de
              votre recherche. Les résultats de la recherche sont basés sur leur pertinence par
              rapport à votre recherche et d'autres critères.
            </p>
          </div>

          <h2>Contenu</h2>
          <h3>Conditions relatives aux voyageurs</h3>
          <div>
            <ol>
              <li>
                <a href='#vos-responsabilites'>Vos responsabilités</a>
              </li>
              <li>
                <a href='#conditions-hotes'>Conditions relatives aux hôtes</a>
              </li>
              <li>
                <a href='#accueil-de-voyageur'>Accueil de voyageur avec Gowifmi</a>
              </li>
              <li>
                <a href='#gestion-annonce'>Gestion de votre Annonce</a>
              </li>
              <h3>Condition d’application générale</h3>
              <li>
                <a href='#commentaire'>Commentaires</a>
              </li>
              <li>
                <a href='#contenu'>Contenu</a>
              </li>
              <li>
                <a href='#reglement-plateforme'>Règlement de la plateforme Gowifmi</a>
              </li>
              <li>
                <a href='#resiliation'>Résiliation, suspension et autres mesures</a>
              </li>
              <li>
                <a href='#role-gowifmi'>Rôle de Gowifmi</a>
              </li>
              <li>
                <a href='#comptes-membres'>Comptes de membres</a>
              </li>
              <li>
                <a href='#exclusion'>Exclusion de responsabilité</a>
              </li>
              <li>
                <a href='#responsabilite'>Responsabilité</a>
              </li>
            </ol>
          </div>
          <div>
            <h3>Conditions supplémentaires spécifiques aux hôtes professionnels</h3>
            <p>
              <strong>Réservation</strong>. En réservant une annonce, vous acceptez de payer tous
              les frais relatifs à votre réservation incluant le prix du logement fixé dans
              l'annonce, les frais applicables tels que les frais de service de Gowifmi, les frais
              perçus en personne, les taxes et tout autre élément identifié lors du paiement
              (collectivement, le « Prix total »). Une fois vous recevez la confirmation de
              réservation, un contrat est directement formé entre vous et l'hôte.
            </p>
            <p>
              <strong>Réservations d'hébergement</strong>. La réservation d'un hébergement est une
              licence limitée pour entrer, occuper, utiliser la propriété et l'occuper légalement.
              Sans limitation des autres droits prévus dans les présents, l'hôte se réserve le droit
              d'entrer à nouveau dans un hébergement hébergé, ou toute partie de celui-ci, pendant
              votre séjour dans cet hébergement si (i) cela est raisonnablement nécessaire en
              confirmant que toutes les conditions ont été respectées, et/ou (ii) cela est autorisé
              par votre contrat avec l'hôte et/ou (iii) cela est conforme au droit applicable. Si
              vous restez après l'heure de départ, l'hôte a le droit de vous expulser d'une manière
              conforme au droit applicable, y compris en imposant des sanctions raisonnables pour un
              séjour indûment prolongé.
            </p>
            <p>
              <strong>Modifications de réservation</strong>. Les hôtes et les Voyageurs sont tenus
              responsables de toute modification de réservation qu'ils acceptent d'apporter par le
              biais de la plateforme Gowifmi ou demandent au service clients de Gowifmi d'effectuer
              en leur nom
            </p>
            <p>
              (« <strong>Modifications de réservations</strong> »), et acceptent tout paiement,
              montant, frais ou taxes supplémentaires associés à toute modification de réservation.
            </p>
          </div>
          <div>
            <h2 id='vos-responsabilites'>Vos responsabilités.</h2>
            <p>
              Vous êtes responsable de vos propres actes et omissions ainsi que des actes et
              omissions de toute personne que vous invitez à vous rejoindre concernant d'un
              hébergement. Par exemple, vous avez l'obligation de laisser l'hébergement et les biens
              associés à ce dernier dans l'état dans lequel vous l'avez trouvé à votre arrivée, que
              vous assumez la responsabilité de payer tous les montants de déclaration de sinistre
              nécessaires pour couvrir les dommages que vous, votre ou vos voyageurs ou votre ou vos
              animaux de compagnie avez causés à un hébergement, et que vous êtes tenu d'agir avec
              intégrité. Cela signifie que vous devez être honnête, traiter les autres avec respect
              et respecter les lois applicables en vigueur à tout moment.
            </p>
          </div>
          <div>
            <h2 id='conditions-hotes'>Conditions relatives aux hôtes</h2>
            <h3 id='accueil-de-voyageur'>Accueil de voyageurs avec Gowifmi.</h3>
            <p>
              <strong>Hôte</strong>. En tant qu'hôte, vous avez le droit d'utiliser la Plateforme
              Gowifmi et de partager votre hébergement avec notre communauté de voyageurs, et de
              gagner de l'argent ce faisant. Créer une annonce est très facile. Vous contrôlez la
              manière dont vous accueillez les voyageurs, et vous fixez votre prix, votre
              disponibilité et les règles pour chaque annonce.
            </p>
            <p>
              <strong>Contrat avec les Voyageurs</strong>. En tant qu'Hôte, vous fournirez des
              services d'hébergements sur la Plateforme Gowifmi tels que décrits dans cet article.
              Si vous acceptez une demande de réservation ou recevez une confirmation de
              réservation, vous concluez directement un contrat avec le Voyageur et êtes responsable
              de la prestation du Service d'hôte selon les conditions et au prix spécifiés dans
              votre Annonce.
            </p>
          </div>
          <div>
            <h2 id='gestion-annonce'>Gestion de votre annonce.</h2>
            <h3>Création et gestion de votre annonce.</h3>
            <p>
              Avec la plateforme Gowifmi, vous pouvez rapidement et facilement mettre en place une
              Annonce et la gérer. À partir de cette plateforme, vous pouvez créer et modifier des
              annonces grâce à des outils simple d'utilisations. Votre annonce doit inclure des
              informations complètes, précises et pertinentes. Le prix, les autres frais éventuels
              tels que les frais de ménage, les frais de séjour, et tous les règlements ou toutes
              les exigences qui s'appliquent à vos voyageurs ou à votre annonce. En tant que
              propriétaire de l'annonce, il est de votre responsabilité de fournir des informations
              exactes sur vous-même et votre propriété, comme indiqué sur votre page d'annonce. Vous
              êtes tenu de maintenir votre contenu (comme les photos) et la disponibilité de votre
              calendrier à jour à tout moment.
            </p>
          </div>

          <div className={styles.list}>
            <p>
              <strong>Classement dans les résultats de recherche</strong>. Le classement des
              annonces dans les résultats de recherche sur la plateforme Gowifmi dépend de plusieurs
              facteurs, principalement nous citons :
            </p>
            <ul>
              <li>
                Paramètres de recherche indiqués par les voyageurs (par exemple, nombre de
                voyageurs, date et durée du voyage, fourchette de prix)
              </li>
              <li>
                Caractéristiques de l'annonce (par exemple, prix, disponibilité du calendrier,
                nombre et qualité des images, commentaires, type de Service d'hôte, statut de
                l'hôte, ancienneté de l'annonce, popularité moyenne des voyageurs)
              </li>
              <li>
                Expérience de réservation du voyageur (par exemple, l'historique des annulations et
                du recours au service client de l'hôte, la facilité de la réservation)
              </li>
              <li>
                Exigences de l'Hôte (par exemple, nombre de nuits minimum ou maximum, heure limite
                de réservation)
              </li>
              <li>
                Préférences du voyageur (par exemple, voyages précédents, Annonces enregistrées,
                lieu à partir duquel le voyageur effectue sa recherche)
              </li>
            </ul>
            <p>
              Les hôtes peuvent promouvoir leurs annonces dans les résultats de recherche ou
              ailleurs sur la plateforme Gowifmi moyennant des frais supplémentaires.
            </p>
            <p>
              Vos responsabilités. Vous êtes responsable de vos propres actes et omissions et de
              ceux des voyageurs que vous autorisez à participer à la prestation de vos services
              d'hébergement. Vous êtes seul responsable des tarifs, du règlement et des exigences de
              votre annonce. Si vous percevez des frais ou facturez des éléments supplémentaires en
              dehors de la Plateforme Gowifmi, vous serez alors en infraction avec la présente
              politique, et nous prendrons les mesures que nous jugerons convenablement.
              N'encouragez pas les Voyageurs à créer des comptes tiers, à soumettre des
              commentaires, à fournir des coordonnées ou à effectuer d'autres actions en dehors de
              la plateforme Gowifmi.
            </p>
          </div>
          <div>
            <h2>Conditions générales</h2>
            <h3 id='commentaire'>Commentaires.</h3>
            <p>
              Après chaque service d'hôte, les voyageurs et les hôtes ont la possibilité de se
              laisser un commentaire. Votre commentaire doit être exact et ne peut contenir aucun
              langage discriminatoire, offensant, diffamatoire ou autre qui violerait notre
              politique de contenu ou notre politique de commentaire.
            </p>
          </div>
          <div>
            <h2 id='contenu'>Contenu.</h2>
            <p>
              Sachez que certaines parties de la plateforme Gowifmi vous permettent de fournir des
              commentaires, du texte, des photos, de l'audio, de la vidéo, des informations et
              d'autres contenus (" Contenu "). Si vous créez ou publiez du contenu sur la plateforme
              Gowifmi, vous nous accordez une licence non exclusive, mondiale et libre de droits
              pour utiliser, copier, modifier, distribuer et sous-licencier votre contenu comme
              décrit dans cette section. Si le contenu comprend des informations personnelles, alors
              ce contenu ne sera utilisé qu'aux fins énoncées si cette utilisation est conforme aux
              lois applicables en matière de protection des données conformément à notre politique
              de confidentialité. Vous êtes le seul responsable de tout le contenu que vous
              fournissez et garantissez que vous avez le droit de le partager ou que vous êtes
              autorisé à accorder à Gowifmi les droits énoncés dans les présentes conditions. Si
              l'un de vos contenus enfreint ou viole les droits de propriété intellectuelle ou la
              vie privée d'un tiers, vous en êtes responsable. Le contenu doit être conforme à notre
              politique sur le contenu et à notre politique sur la non-discrimination, qui
              interdisent, entre autres, la discrimination, le langage obscène, le harcèlement, sur
              tout type de contenu.
            </p>
          </div>
          <div>
            <p id='reglement-plateforme'>Règles de la plateforme Gowifmi</p>
            <p>
              <strong>Règlement</strong>. Vous devez respecter ces règles et ne devez pas aider ou
              encourager les autres à les enfreindre ou à les contourner.
            </p>
            <ul>
              <li>Agir avec intégrité et traiter les autres avec respect</li>
              <p>
                - Ne pas mentir, représenter faussement quelque chose ou quelqu'un, ou prétendre
                être quelqu'un d'autre.
              </p>
              <p>
                - Faites preuve de politesse et de respect lorsque vous communiquez avec les autres.
              </p>
              <p>
                - Respecter notre politique de non-discrimination et ne pas discriminer ou harceler
                les autres.
              </p>
              <li>
                Ne piratez, ne contournez, ne supprimez ou ne modifiez aucune mesure de sécurité ou
                technologique utilisée pour protéger la plateforme Gowifmi ou le contenu.
              </li>
              <p>
                - Ne pas décompiler, désassembler ou faire de l'ingénierie inverse des logiciels
                utilisés pour fournir la plateforme Gowifmi.
              </p>
              <p>
                - Ne pas entreprendre d'action qui pourrait perturber ou nuire à la performance ou
                au bon fonctionnement de la plateforme Gowifmi.
              </p>
              <li>
                Ne pas utiliser la plateforme Gowifmi d'une manière non expressément autorisée par
                les présentes conditions
              </li>
              <p>
                - Vous ne pouvez utiliser les informations personnelles d'un autre Membre que dans
                la mesure du nécessaire pour faciliter une transaction utilisant la plateforme
                Gowifmi, comme autorisé par les présentes conditions.
              </p>
              <p>
                - Ne pas utiliser la plateforme Gowifmi, nos outils de messagerie ou les
                informations personnelles de nos membres pour envoyer des messages commerciaux sans
                leur consentement explicite.
              </p>
              <p>
                - Veuillez utiliser tout le contenu disponible sur la plateforme Gowifmi uniquement
                si cela est nécessaire pour vous permettre d'utiliser la plateforme Gowifmi en tant
                que Voyageur ou Hôte.
              </p>
              <p>
                - Si vous n'avez pas l'autorisation du propriétaire du contenu, ou si l'utilisation
                du contenu n'est pas autorisée par Gowifmi dans les présentes conditions ou dans un
                accord séparé que vous avez conclu avec Gowifmi, veuillez ne pas l'utiliser.
              </p>
              <p>
                - Afin de minimiser les frais et d'éviter tout problème, il est conseillé de ne pas
                réserver votre séjour via Gowifmi en dehors de la plateforme.
              </p>
              <p>
                - Vous ne devez pas influencer ou encourager un voyageur à ouvrir un compte, à
                laisser un commentaire ou à interagir avec des services tiers de quelque manière que
                ce soit avant, pendant ou après une réservation. Sauf autorisation de Gowifmi.
              </p>
              <p>- Ne pas tenter de manipuler notre algorithme de recherche.</p>
            </ul>
            <p>
              <strong>Signalement d'une infraction</strong>. Si vous estimez qu'un membre, une
              annonce ou un contenu présente un risque imminent de dommage à une personne ou à un
              bien, vous devez immédiatement contacter les autorités locales avant de contacter
              Gowifmi. Dans le cas où vous pensez qu'un membre, une annonce ou un contenu constitue
              une violation de nos standards communautaires, veuillez-nous le signaler en nous
              contactant. Si vous avez signalé un problème aux autorités locales, Gowifmi peut
              demander une copie de ce signalement. Nous n'avons pas à prendre de mesures en réponse
              à un signalement, sauf si la loi nous y oblige.
            </p>
            <p>
              <strong>Notifications du droit d'auteur</strong>. Si vous pensez qu'un contenu de la
              plateforme Gowifmi enfreint le droit d'auteur, veuillez-nous en informer.
            </p>
          </div>
          <div>
            <h2 id='resiliation'>Résiliation, suspension et autres mesures</h2>
            <p>
              <strong>Durée</strong>. Le contrat entre vous et Gowifmi reflété dans les présentes
              conditions reste en vigueur jusqu'à ce que vous ou nous mettions fin au contrat
              conformément aux conditions.
            </p>
            <p>
              <strong>Résiliation</strong>. Vous pouvez résilier ce contrat à tout moment en nous
              envoyant un e-mail ou en supprimant votre compte directement. Si Gowifmi peut annuler
              ce contrat pour quelque raison que ce soit, nous devons vous donner un préavis de 30
              jours par e-mail ou en utilisant toute autre adresse ou coordonnées que vous nous avez
              fournies.
            </p>
            <p>
              <strong>Effet de la résiliation</strong>. Si vous êtes hôte et supprimiez votre compte
              Gowifmi, toutes les réservations confirmées seront automatiquement annulées et vos
              voyageurs recevront un remboursement complet. Lors de la suppression de votre compte
              Gowifmi en tant que voyageur, toutes les réservations confirmées seront
              automatiquement annulées et tout remboursement potentiel sera basé sur les conditions
              d'annulation de l'annonce. En cas de résiliation de ce contrat, vous n'aurez droit à
              aucune restauration de votre compte ou de votre contenu. Si votre accès à plateforme
              Gowifmi ou son utilisation a été limité, ou si ce contrat a été résilié par nous, vous
              ne pouvez pas enregistrer un nouveau compte ou accéder à la Plateforme Gowifmi ou
              utiliser Gowifmi via le compte d'un autre membre.
            </p>
          </div>
          <div>
            <h2>Modification des présentes conditions.</h2>
            <p>
              Si nous apportons des modifications à nos politiques, nous les mettrons à disposition
              sur Gowifmi et vous informerons de toute mise à jour. Nous vous envoyons toujours les
              mises à jour importantes au moins 30 jours avant leur entrée en vigueur. En cas de
              modifications des Conditions générales proposées ici, il vous sera demandé de les
              accepter explicitement. Cet avis vous informera aussi de votre droit de refuser les
              modifications proposées et de votre droit de résilier le contrat à tout moment avant
              la date d'entrée en vigueur des modifications proposées.
            </p>
          </div>
          <div>
            <h2 id='role-gowifmi'>Rôle de Gowifmi.</h2>
            <p>
              Nous offrons le droit d'utiliser une plateforme permettant aux membres de publier,
              proposer et rechercher des services fournis par les hôtes. Lorsque des membres
              effectuent ou acceptent une réservation, ils concluent directement un contrat entre
              eux. Bien que nous fassions tout notre possible pour nous assurer que nos Membres
              vivent une expérience fabuleuse sur Gowifmi, nous ne pouvons pas contrôler et ne
              garantissons pas le comportement des Voyageurs ou des Hôtes. Nous reconnaissons que
              Gowifmi n'a aucune obligation de surveiller le Contenu ou de vérifier les informations
              fournies par nos membres, mais peut vérifier le Contenu, désactiver votre accès, le
              supprimer ou le modifier pour : (i) l'utilisation de la Plateforme Gowifmi (y compris
              en relation avec une fraude prévention, gestion des risques, service à la clientèle et
              enquêtes). , ainsi que pour se conformer aux lois applicables ; (ii) pour vous assurer
              que vous vous conformez aux présentes Conditions d'utilisation ; Si nous supprimons ou
              désactivons le Contenu des Membres, Gowifmi en informera le Membre et motivera cette
              décision, sauf si cette notification (i) empêche ou entrave la découverte d'une fraude
              ou d'autres activités illégales, (ii) compromet les intérêts légitimes des autres
              Membres ou des tiers, ou (iii) viole le droit applicable. Vous avez la possibilité de
              faire appel de cette décision en contactant notre service clients. Tous les Membres
              acceptent de coopérer avec Gowifmi et d'aider Gowifmi à fournir les informations
              requises et à prendre les mesures raisonnables demandées par Gowifmi, dans le cadre de
              toute enquête menée par Gowifmi sur l'utilisation ou l'abus de la Plateforme.
            </p>
          </div>
          <div>
            <h2 id='comptes-membres'>Comptes des membres.</h2>
            <p>
              Pour commencer à utiliser toutes les fonctionnalités de la plateforme Gowifmi, vous
              devez créer un compte. L'inscription n'est admise que pour les personnes physiques et
              morales âgées de 18 ans ou plus. Nous exigeons que vous fournissiez des informations
              exactes et complètes lors de l'inscription, et tenir à jour les informations relatives
              à votre compte. Vous ne pouvez pas créer plus d'un compte ou transférer votre compte à
              une autre personne. Vous êtes seul responsable de la sécurité de votre compte, et il
              est contraire à nos conditions de partager toute information relative à votre compte
              avec un tiers. Si votre compte Gowifmi est compromis, veuillez en informer
              immédiatement Gowifmi. Si vous soupçonnez que vos informations d'identification ont
              été perdues ou volées. Veuillez noter que vous êtes responsable de toutes les
              activités menées avec votre compte Gowifmi, sauf si ces activités n'ont pas été
              autorisées par vous ou si vous n'avez pas agi avec négligence. (En d'autres termes,
              par exemple, en ne signalant pas l'utilisation non autorisée ou la perte de vos
              identifiants). Le cas échéant, nous pouvons, si la loi applicable le permet, mais nous
              n'avons aucune obligation de : (i) demander des informations personnelles, y compris
              votre identité ou vos antécédents criminels, (ii) vérifier votre identité ou vos
              antécédents, (iii) vous comparer à des sources de données ou à des bases de données
              gérées par des tierces parties, (iv) obtenir des rapports des registres locaux des
              délinquants sexuels ou des lois et dossiers équivalents.
            </p>
          </div>
          <div>
            <h2 id='exclusion'>Exclusion de responsabilité.</h2>
            <p>
              Nous ne garantissons pas l'existence, la conduite, la performance, la sécurité, la
              qualité ou l'adéquation de tout voyageur, hôte, service, annonce ou tiers. Étant donné
              que les contrôles de sécurité des utilisateurs sur internet sont difficiles, nous ne
              garantissons pas que les vérifications d'identité ou d'antécédents (le cas échéant)
              effectuées sur le (s) Membre (s) identifieront les erreurs passées ou empêcheront les
              erreurs futures. Toute référence à un Membre "Vérifié" (ou similaire) signifie
              uniquement que le Membre ou Gowifmi a fait l'objet d'un processus de vérification ou
              d'identification approprié, et rien de plus. Nous ne sommes pas responsables des
              pannes causées par des problèmes d'infrastructure internet ou des systèmes de
              télécommunications qui ne sont pas sous notre contrôle et qui peuvent entraîner des
              interruptions d'accessibilité sur la plateforme Gowifmi. Occasionnellement et compte
              tenu des intérêts légitimes des membres (par exemple, via une notification préalable),
              Gowifmi peut limiter la disponibilité de notre plateforme ou de certaines
              fonctionnalités. Cela peut être nécessaire pour maintenir la sécurité, l'intégrité
              et/ou la stabilité du système, ou pour effectuer des opérations de maintenance afin
              d'assurer une fonctionnalité correcte ou d'améliorer la fonctionnalité.
            </p>
          </div>
          <div>
            <h2 id='responsabilite'>Responsabilité.</h2>
            <p>
              En vertu des dispositions légales applicables en cas de faute intentionnelle et grave
              commise par Gowifmi, nos représentants légaux, administrateurs ou mandataires. De
              même, la prise en charge des garanties ou toute autre responsabilité de plein droit ou
              en cas d'atteinte à la vie, à l'intégrité physique ou à la santé. En cas d'erreur de
              notre part, de nos représentants, représentants légaux et administrateurs ou
              mandataires, la responsabilité de Gowifmi pour tout dommage est limitée aux dommages
              typiques pouvant survenir. Les obligations de Gowifmi sont celles énoncées dans le
              contrat, dont vous attendez l'accomplissement. Toute autre responsabilité de Gowifmi
              est exclue.
            </p>
          </div>
          <div>
            <h2>E-mails et SMS.</h2>
            <p>
              Nous communiquerons avec vous par e-mail ou par d'autres informations que vous avez
              fournies pour créer votre compte Gowifmi. Nous pouvons également vous envoyer des e-
              mails promotionnels. Veuillez-vous attendre à recevoir des e-mails supplémentaires
              spécifiques à votre service. Aucun frais ne sera facturé pour ces e-mails
              promotionnels, mais des frais de données de tiers peuvent s'appliquer. Vous pouvez
              gérer tous les e-mails promotionnels que vous recevez en accédant aux paramètres de
              votre compte. Vous ne pourrez pas profiter de certaines promotions si vous désactivez
              certains paramètres de communication ou si vous ne possédez pas de compte Gowifmi.
            </p>
          </div>
          <div>
            <h2>Accès aux données.</h2>
            <p>
              Les utilisateurs professionnels ont accès aux informations personnelles et autres
              données de leur compte Gowifmi, qui sont fournies par l'utilisateur professionnel, ses
              invités ou générées par l'utilisation de la plateforme Gowifmi. Ceux-ci sont
              nécessaires aux hôtes professionnels pour fournir leurs services, ainsi que pour les
              informations démographiques sur les résultats de recherche, les réservations et les
              performances de leurs annonces. Notre politique de confidentialité définit la façon
              dont nous collectons, utilisons et traitons les données personnelles que nous vous
              demandons et comment vous pouvez en avoir l'accès et exercer vos droits en matière de
              protection des données.
            </p>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}
export default TermsAndCondition
