import { useState, useEffect, useContext } from 'react'
import styles from '../../../../css/modehotes/reservation/cancellation.module.css'
import axios from 'axios'
import { useLocation } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import { User } from '../../../../context/UserContext'
import Swal from 'sweetalert2'
import moment from 'moment'
import Toast from '../../../../components/infos/toast'
import LoadingPoint from '../../../../components/LoadingPoint'
import HostModeLayout from '../../../../layouts/HostModeLayout'
import { Helmet } from 'react-helmet-async'

const HostGoCancel = () => {
  const [loading, setLoading] = useState(true)
  const [reservation, setReservation] = useState({})
  const [explication, setExplication] = useState('')

  const location = useLocation()
  const history = useHistory()
  const id = location.pathname.split('/')[4]
  const user = useContext(User)

  useEffect(() => {
    window.scrollTo(0, 0)
    if (user === null) {
      history.push('/')
    }
  }, [])

  useEffect(() => {
    axios
      .get(`/host_reservations/${id}`)
      .then(res => {
        setReservation(res.data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [id])

  const handleSubmit = e => {
    e.preventDefault()
    const data = {
      cancellations: {
        cancel_reason_1: 'canceled_by_host',
        cancel_explanation: explication,
        date: new Date(),
      },
    }
    Swal.fire({
      title: 'Annuler cette réservation ?',
      text: 'Une fois la réservation annulée, vous ne pourrez plus la récupérer.',
      showCancelButton: true,
      confirmButtonText: 'Annuler la réservation',
      cancelButtonText: 'Retour',
      reverseButtons: true,
      customClass: {
        actions: 'actions_class',
        confirmButton: 'confirm_button_class',
        cancelButton: 'cancel_button_class',
      },
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .patch(`/host_reservations/host_cancel/${id}`, data)
          .then(() => {
            Toast.fire({
              icon: 'success',
              title: 'Votre réservation est annulée avec succès.',
            })
            history.push('/hosting/reservations/canceled')
          })
          .catch(() => {
            Toast.fire({
              icon: 'error',
              title: 'Veuillez sélectionner la motif de cette annulation',
            })
          })
      }
    })
  }

  const handleRedirect = () => {
    if (history.length > 1) {
      history.goBack()
    } else {
      const Url = `/hosting/reservations/details/${reservation._id}`

      history.push(Url)
    }
  }

  return (
    <HostModeLayout footer={true}>
      <Helmet>
        <title>Annuler votre réservation - Gowifmi</title>
      </Helmet>
      {loading ? (
        <div className={styles.container_load}>
          <LoadingPoint />
        </div>
      ) : (
        <div className={styles.voyage_container}>
          <div className={styles.header}>
            <div>
              <button onClick={handleRedirect}>
                <svg
                  viewBox='0 0 32 32'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'
                  role='presentation'
                  focusable='false'
                  style={{
                    display: 'block',
                    height: '16px',
                    width: '16px',
                    stroke: 'currentcolor',
                    strokeWidth: '3',
                    fill: 'none',
                    overflow: 'visible',
                  }}
                >
                  <g>
                    <path d='m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932'></path>
                  </g>
                </svg>
              </button>
            </div>
            <h1>
              Annuler la réservation · {reservation?.hotel?.title} ·{' '}
              <span>
                {moment(reservation.reservation_infos?.checkin).format('ll')} -{' '}
                {moment(reservation.reservation_infos?.checkout).format('ll')}
              </span>
            </h1>
          </div>
          <div className={styles.le_pourquoi}>
            <h4>Dites-nous pourquoi vous souhaitez annuler</h4>
            <p>Cette étape est nécessaire pour confirmer votre annulation</p>
            <form onSubmit={handleSubmit}>
              <textarea
                onChange={e => setExplication(e.target.value)}
                placeholder='Expliquez-nous pourquoi vous souhaitez annuler cette réservation'
              ></textarea>

              <div className={styles.buttons}>
                <button
                  type='submit'
                  disabled={!explication}
                  className={!explication ? styles.disabled : ''}
                >
                  Annuler cette réservation
                </button>
                <Link to='/hosting/reservations/all'>Non, je ne souhaite pas annuler</Link>
              </div>
            </form>
          </div>
        </div>
      )}
    </HostModeLayout>
  )
}

export default HostGoCancel
