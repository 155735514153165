import styles from '../../../../../css/modehotes/reservation/detail.module.css'
import { CANCELLATION_POLICY } from '../../../../../enums/logement/cancellation_policy.enums'

const Reglement = ({ reservation }) => {
  return (
    <div className={styles.block}>
      <h2>Conditions et règlement</h2>
      <div className={styles.content}>
        <h3>Conditions d'annulation</h3>
        {reservation.conditions?.cancellation_policy === CANCELLATION_POLICY[0] && (
          <>
            <p>
              Flexibles : Remboursement intégral si l'annulation s'effectue jusqu'à 1 jour avant
              l'arrivée, le voyageur obtiendra un remboursement de 100 % du montant payé, après
              déduction des frais de service.
            </p>
            <br />
            <p>
              Remboursement partiel si l'annulation s'effectue après 24heures précédant la date
              d'arrivée, le voyageur obtiendra le remboursement de toutes les nuits, à l'exception
              de la première. Aucun remboursement de la première nuit ni des frais de service.
            </p>
          </>
        )}
        {reservation.conditions?.cancellation_policy === CANCELLATION_POLICY[1] && (
          <>
            <p>
              Modérées : Remboursement intégral si l'annulation s'effectue jusqu'à 5 jours avant
              l'arrivée vous obtiendrez un remboursement de 100 % du montant payé, après déduction
              des frais de service.
            </p>
            <br />
            <p>
              Remboursement partiel si l'annulation s'effectue après 5 jours précédant votre date
              d'arrivée, vous obtiendrez un remboursement de 50 % du prix de chaque nuit, à
              l'exception de la première. Aucun remboursement de la première nuit ni des frais de
              service.
            </p>
          </>
        )}
        {reservation.conditions?.cancellation_policy === CANCELLATION_POLICY[2] && (
          <p>
            Strictes : Remboursement intégral pour les annulations effectuées dans les 48 heures
            suivant la réservation, si la date d'arrivée intervient dans 14 jours ou plus.
            Remboursement à hauteur de 50 % pour les annulations effectuées au moins 7 jours avant
            la date d'arrivée. Aucun remboursement pour les annulations effectuées dans les 7 jours
            précédant la date d'arrivée.
          </p>
        )}
      </div>
      <div className={styles.content}>
        <h3>Animaux acceptés</h3>
        {reservation.conditions?.pets_allowed ? (
          <p>Vous accepter les animaux dans le logement.</p>
        ) : (
          <p>Vous refusez les animaux dans le logement.</p>
        )}
      </div>
      <div className={styles.content}>
        <h3>Convient aux bébés (moins de 2 ans)</h3>
        {reservation.conditions?.accept_babies ? (
          <p>Le logement convient aux bébés.</p>
        ) : (
          <p>Le logement ne convient pas aux bébés.</p>
        )}
      </div>
      <div className={styles.content}>
        <h3>Convient aux enfants (2-12 ans)</h3>
        {reservation.conditions?.accept_children ? (
          <p>Le logement convient aux enfants.</p>
        ) : (
          <p>Le logement ne convient pas aux enfants.</p>
        )}
      </div>
      <div className={styles.content}>
        <h3>Événements autorisés</h3>
        {reservation.conditions?.event_allowed ? (
          <p>Les événements sont autorisés dans le logement.</p>
        ) : (
          <p>Vous n'autorisez pas d'événements dans le logement.</p>
        )}
      </div>
      <div className={styles.content}>
        <h3>Logement fumeur</h3>
        {reservation.conditions?.smoking_allowed ? (
          <p>Les fumeurs sont autorisés.</p>
        ) : (
          <p>Vous refusez les fumeurs dans le logement.</p>
        )}
      </div>
      <div className={styles.content}>
        <h3>Règles supplémentaires</h3>
        <p>{reservation.conditions?.accommodation_rules}</p>
      </div>
    </div>
  )
}

export default Reglement
