import { useEffect, useRef } from 'react'
import ReactCanvasConfetti from 'react-canvas-confetti'

let myReq

const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
}

function getAnimationSettings(angle, originX) {
  return {
    particleCount: 3,
    angle,
    spread: 100,
    origin: { x: originX },
    // colors: ['#bb0000', '#ffffff'],
  }
}

export default function SchoolPride() {
  const refAnimationInstance = useRef(null)

  useEffect(() => {
    const animationInstance = refAnimationInstance.current

    if (animationInstance) {
      const start = Date.now()

      const frame = () => {
        const elapsed = Date.now() - start

        if (elapsed < 3000) {
          // Run for 3 seconds
          animationInstance(getAnimationSettings(60, 0))
          animationInstance(getAnimationSettings(120, 1))
          myReq = requestAnimationFrame(frame)
        } else {
          cancelAnimationFrame(myReq)
          // animationInstance.reset()
        }
      }

      myReq = requestAnimationFrame(frame)
    }
    return () => cancelAnimationFrame(myReq)
  }, [])
  return (
    <>
      <ReactCanvasConfetti
        refConfetti={getInstance => (refAnimationInstance.current = getInstance)}
        style={canvasStyles}
      />
    </>
  )
}
