import styles from '../../css/svgs/svgStyle.module.css'

const PendingSvg = () => {
  return (
    <svg
      className={styles.pending}
      aria-hidden='true'
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm1-8.577V4a1 1 0 1 0-2 0v4a1 1 0 0 0 .517.876l2.581 1.49a1 1 0 0 0 1-1.732z'
        fillRule='evenodd'
      ></path>
    </svg>
  )
}

export default PendingSvg
