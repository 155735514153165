import styles from '../../../css/searchModal/destination.module.css'

const ClearInputBtn = ({ setCity }) => {
  return (
    <div>
      <button type='button' className={styles.button_content} onClick={() => setCity('')}>
        <svg
          className={styles.close_svg}
          viewBox='0 0 32 32'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'
          role='presentation'
          focusable='false'
        >
          <path d='m6 6 20 20'></path>
          <path d='m26 6-20 20'></path>
        </svg>
      </button>
    </div>
  )
}

export default ClearInputBtn
