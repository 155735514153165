import { PROPERTY_TYPE_GROUP } from '../../../enums/logement/type_property_group.enums'

export const propertyOptions = [
  {
    name: 'Appartement',
    value: PROPERTY_TYPE_GROUP.APARTMENT,
    icon: (
      <svg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
        <rect
          x='6'
          y='4'
          width='20'
          height='24'
          rx='2'
          ry='2'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
        ></rect>
        <line x1='16' y1='4' x2='16' y2='28' stroke='currentColor' strokeWidth='2'></line>
        <rect
          x='8'
          y='6'
          width='4'
          height='4'
          rx='2'
          ry='2'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
        ></rect>
        <rect
          x='20'
          y='6'
          width='4'
          height='4'
          rx='2'
          ry='2'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
        ></rect>
        <rect
          x='8'
          y='12'
          width='4'
          height='4'
          rx='2'
          ry='2'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
        ></rect>
        <rect
          x='20'
          y='12'
          width='4'
          height='4'
          rx='2'
          ry='2'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
        ></rect>
        <rect
          x='8'
          y='18'
          width='4'
          height='4'
          rx='2'
          ry='2'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
        ></rect>
        <rect
          x='20'
          y='18'
          width='4'
          height='4'
          rx='2'
          ry='2'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
        ></rect>
      </svg>
    ),
  },
  {
    name: 'Maison',
    value: PROPERTY_TYPE_GROUP.HOUSE,
    icon: (
      <svg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M4 18 L16 6 L28 18'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinejoin='round'
        ></path>
        <rect
          x='8'
          y='18'
          width='16'
          height='12'
          rx='2'
          ry='2'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
        ></rect>
        <rect
          x='12'
          y='22'
          width='8'
          height='8'
          rx='2'
          ry='2'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
        ></rect>
      </svg>
    ),
  },
  {
    name: 'Hôtel',
    value: PROPERTY_TYPE_GROUP.HOTEL,
    icon: (
      <svg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
        <rect
          x='4'
          y='6'
          width='24'
          height='20'
          rx='2'
          ry='2'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
        ></rect>
        <line x1='4' y1='16' x2='28' y2='16' stroke='currentColor' strokeWidth='2'></line>
        <line x1='12' y1='6' x2='12' y2='26' stroke='currentColor' strokeWidth='2'></line>
        <line x1='20' y1='6' x2='20' y2='26' stroke='currentColor' strokeWidth='2'></line>
        <text x='8' y='24' fontFamily='Arial' fontSize='6' fill='currentColor'>
          HOTEL
        </text>
      </svg>
    ),
  },
  {
    name: 'Annexe',
    value: PROPERTY_TYPE_GROUP.ANNEX,
    icon: (
      <svg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
        <rect
          x='2'
          y='10'
          width='14'
          height='14'
          rx='2'
          ry='2'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
        ></rect>
        <rect
          x='16'
          y='14'
          width='14'
          height='10'
          rx='2'
          ry='2'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
        ></rect>
        <line x1='14' y1='14' x2='16' y2='14' stroke='currentColor' strokeWidth='2'></line>
      </svg>
    ),
  },
  {
    name: 'Logement unique',
    value: PROPERTY_TYPE_GROUP.UNIQUE_ACCOMMODATION,
    icon: (
      <svg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
        <rect
          x='8'
          y='12'
          width='16'
          height='16'
          rx='2'
          ry='2'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
        ></rect>
        <path
          d='M8 12 L16 4 L24 12'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinejoin='round'
        ></path>
      </svg>
    ),
  },
  {
    name: 'Autre logement non résidentiel',
    value: PROPERTY_TYPE_GROUP.OTHER_NON_RESIDENTIAL_ACCOMMODATION,
    icon: (
      <svg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
        <rect
          x='4'
          y='10'
          width='24'
          height='14'
          rx='2'
          ry='2'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
        ></rect>
        <path d='M12 10 L16 6 L20 10' fill='none' stroke='currentColor' strokeWidth='2'></path>
        <line x1='16' y1='10' x2='16' y2='24' stroke='currentColor' strokeWidth='2'></line>
      </svg>
    ),
  },
]
