import styles from '../../../css/details/asavoir.module.css'
import Modal from '../../ui/Modal'
import CloseButton from '../../ui/CloseButton'
import { CANCELLATION_POLICY } from '../../../enums/logement/cancellation_policy.enums'
import useModalByUrl from '../../../hooks/useModalByUrl'

const Cancellation = ({ hotel }) => {
  const modalValue = 'CANCELLATION'
  const { modalState, handleOpenModal, handleCloseModal } = useModalByUrl(modalValue)

  return (
    <>
      <div className=''>
        <h4>Conditions d'annulation</h4>
        {hotel?.regulations?.cancellation_policy === CANCELLATION_POLICY[0] && (
          <p>Remboursement intégral jusqu'à 1 jour avant l'arrivée.</p>
        )}
        {hotel?.regulations?.cancellation_policy === CANCELLATION_POLICY[1] && (
          <p>Remboursement intégral jusqu'à 5 jours avant l'arrivée.</p>
        )}
        {hotel?.regulations?.cancellation_policy === CANCELLATION_POLICY[2] && (
          <p>
            Remboursement intégral pour les annulations effectuées dans les 48 heures suivant la
            réservation, si la date d'arrivée intervient dans 14 jours ou plus. Remboursement à
            hauteur de 50 % pour les annulations effectuées au moins 7 jours avant la date
            d'arrivée. Aucun remboursement pour les annulations effectuées dans les 7 jours
            précédant la date d'arrivée.
          </p>
        )}
        <div className={styles.savoir_btn}>
          <button onClick={handleOpenModal}>En savoir plus</button>
        </div>
      </div>

      {/*----modal message de l'hotel---------*/}
      <Modal isOpen={modalState} onClose={handleCloseModal}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={handleCloseModal} />
            <div></div>
          </div>
          <div className={styles.m_body}>
            <div>
              <h2>Conditions d'annulation</h2>
              <p className={styles.p_head_cancellation}>
                Avant de réserver, assurez-vous que les conditions d'annulation de cet hôte vous
                conviennent. Ces conditions d'annulation, s'appliquent même si vous annulez pour
                cause de maladie.
              </p>
              <div className={styles.infos}>
                {hotel?.regulations?.cancellation_policy === CANCELLATION_POLICY[0] && (
                  <div className={styles.content}>
                    <div>
                      <h3>Remboursement intégral</h3>
                      <p>
                        Si vous annulez jusqu'à 1 jour avant l'arrivée, vous obtiendrez un
                        remboursement de 100 % du montant payé, après déduction des frais de
                        service.
                      </p>
                    </div>
                    <div>
                      <h3>Remboursement partiel</h3>
                      <p>
                        Si vous annulez après 24heures précédant votre date d'arrivée, vous
                        obtiendrez le remboursement de toutes les nuits, à l'exception de la
                        première. Aucun remboursement de la première nuit ni des frais de service.
                      </p>
                    </div>
                  </div>
                )}
                {hotel?.regulations?.cancellation_policy === CANCELLATION_POLICY[1] && (
                  <div className={styles.content}>
                    <div>
                      <h3>Remboursement intégral</h3>
                      <p>
                        Si vous annulez jusqu'à 5 jours avant l'arrivée vous obtiendrez un
                        remboursement de 100 % du montant payé, après déduction des frais de
                        service.
                      </p>
                    </div>
                    <div>
                      <h3>Remboursement partiel</h3>
                      <p>
                        Si vous annulez après 5 jours précédant votre date d'arrivée, vous
                        obtiendrez un remboursement de 50 % du prix de chaque nuit, à l'exception de
                        la première. Aucun remboursement de la première nuit ni des frais de
                        service.
                      </p>
                    </div>
                  </div>
                )}
                {hotel?.regulations?.cancellation_policy === CANCELLATION_POLICY[2] && (
                  <div className={styles.content}>
                    <div>
                      <h3>Remboursement intégral</h3>
                      <p>
                        Un remboursement intégral, après déduction des frais de service pour les
                        annulations effectuées dans les 48 heures suivant la réservation, à
                        condition que la date d'arrivée intervienne dans 14 jours ou plus.
                      </p>
                    </div>
                    <div>
                      <h3>Remboursement partiel</h3>
                      <p>
                        Remboursement à hauteur de 50 % pour les annulations effectuées au moins 7
                        jours avant la date d'arrivée
                      </p>
                    </div>
                    <div>
                      <h3>Aucun remboursement</h3>
                      <p>Cette réservation n'est pas remboursable.</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Cancellation
