import styles from '../../css/ui/search_input.module.css'

const SearchInput = ({ id, placeholder = '', value, onChange, onClick }) => {
  return (
    <div className={styles.container}>
      <label htmlFor={id}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 32 32'
          aria-hidden='true'
          role='presentation'
          focusable='false'
          style={{
            display: 'block',
            fill: 'none',
            height: '16px',
            width: '16px',
            stroke: 'currentcolor',
            strokeWidth: '4',
            overflow: 'visible',
          }}
        >
          <path fill='none' d='M13 24a11 11 0 1 0 0-22 11 11 0 0 0 0 22zm8-3 9 9'></path>
        </svg>
        <input type='search' id={id} placeholder={placeholder} value={value} onChange={onChange} />
        <div className={value.length > 0 ? styles.close_active : styles.close_disable}>
          <button type='button' className={styles.close} onClick={onClick}>
            <svg
              viewBox='0 0 32 32'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'
              role='presentation'
              focusable='false'
            >
              <path d='m6 6 20 20'></path>
              <path d='m26 6-20 20'></path>
            </svg>
          </button>
        </div>
      </label>
    </div>
  )
}

export default SearchInput
