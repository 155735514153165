import styles from '../../css/cookies/cookie_consent.module.css'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import { loadCookiesParams } from '../../utils/loadCookiesParams'

function CookieConsentModal() {
  const cookieName = 'cookieConsent'

  const handleAccept = () => {
    loadCookiesParams()
  }

  const handleDecline = () => {
    Cookies.set(cookieName, 'false', { expires: 1 })
  }

  return (
    <CookieConsent
      cookieName={cookieName}
      expires={365}
      disableStyles={true}
      containerClasses={styles.container}
      contentClasses={styles.txt_content}
      buttonWrapperClasses={styles.btn_wrapper}
      buttonClasses={styles.btn_accept}
      declineButtonClasses={styles.btn_decline}
      buttonText='Ok'
      enableDeclineButton
      flipButtons
      declineButtonText='Continuer sans accepter'
      onAccept={handleAccept}
      onDecline={handleDecline}
    >
      <div className={styles.block}>
        <h1>Votre vie privée</h1>
        <p>
          Pour améliorer votre expérience, nous utilisons des cookies et d'autres technologies
          similaires afin de personnaliser notre contenu, mesurer l'efficacité de nos publicités et
          améliorer leur pertinence. En cliquant sur OK,vous acceptez les conditions énoncées dans
          notre <a href='/cookies-policy'>Politique relative aux cookies</a>.
        </p>
      </div>
    </CookieConsent>
  )
}
export default CookieConsentModal
