import { useEffect, useContext, useRef } from 'react'
import styles from '../../css/account/account.module.css'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'
import { User } from '../../context/UserContext'
import DefaultLayout from '../../layouts/DefaultLayout'
import DisabledAccount from '../../components/accounts/disabled_account'
import AllAccountSetings from '../../components/accounts/AllAccountSetings'
import SettingSvg from '../../components/svgs/SettingSvg'

const AccountSetings = () => {
  const user = useContext(User)
  const history = useHistory()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const disabledAccountRef = useRef()
  const HandleDisabled = () => {
    disabledAccountRef.current.OpenModal()
  }

  if (user === null) {
    history.push('/')
    return null
  }

  return (
    <DefaultLayout footer={true} mobileBottomNavigate={true}>
      <Helmet>
        <title>Paramètre du compte - Gowifmi</title>
      </Helmet>

      <div className={styles.account_container}>
        <div className={styles.account_header}>
          <h1>Compte</h1>
          <p>
            {user.first_name && <strong>{user.first_name},</strong>} {user.email}
          </p>
        </div>
        <div className={styles.content}>
          <div>
            <AllAccountSetings />
          </div>
          <div className={styles.rigth_block}>
            <SettingSvg />
          </div>
        </div>
        <div className={styles.desactive}>
          <div>
            <p>Besoin de désactiver votre compte ?</p>
            <button type='button' onClick={HandleDisabled}>
              M'en occuper maintenant
            </button>
          </div>
        </div>
      </div>
      <DisabledAccount ref={disabledAccountRef} />
    </DefaultLayout>
  )
}

export default AccountSetings
