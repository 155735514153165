import { useEffect, useContext } from 'react'
import globalStyle from '../../css/becomeHostes/globalStyle.module.css'
import { User } from '../../context/UserContext'
import { useHistory } from 'react-router-dom'
import Form from '../../components/become_host/type_property_groups/Form'
import DescriptionText from '../../components/become_host/globals/DescriptionText'
import { Helmet } from 'react-helmet-async'

const TypeProperty = () => {
  const user = useContext(User)
  const history = useHistory()
  useEffect(() => {
    if (user === null) {
      history.push('/host/homes')
    }
  }, [history, user])
  return (
    <>
      <Helmet>
        <title>Choisissez un type de logement spécifique - Gowifmi</title>
      </Helmet>
      <div className={globalStyle.container}>
        <Form user={user} />
        <DescriptionText title='Quel type de logement allez-vous proposer ?' desc='' />
      </div>
    </>
  )
}

export default TypeProperty
