import styles from '../../../../../css/modehotes/annonce/details/securiteVoyageur.module.css'
import Checkbox from '../../../../ui/Checkbox'

const CompanionAnimal = ({ animauxCompagnie, setAnimauxCompagnie }) => {
  return (
    <div className={styles.checkbox_container}>
      <div>
        <label htmlFor='animauxCompagnie'>Présence d'animaux de compagnie sur place</label>
        <p>
          Les voyageurs doivent s'attendre à croiser des animaux de compagnie (et à recevoir un peu
          d'amour de leur part) pendant leur séjour.
        </p>
      </div>
      <div>
        <Checkbox
          id='animauxCompagnie'
          value={animauxCompagnie}
          checked={animauxCompagnie}
          onChange={() => setAnimauxCompagnie(!animauxCompagnie)}
        />
      </div>
    </div>
  )
}

export default CompanionAnimal
