import styles from '../../css/details/loading.module.css'

const LoadingComponent = () => {
  return (
    <>
      <div className={styles.container_loading}>
        <div className={styles.detail_block_1}>
          <div className={styles.header}>
            <div className={`${styles.search_box}`}></div>
            <div className={styles.print_block}>
              <div className={`${styles.partage} ${styles.skeleton}`}></div>
              <div className={`${styles.imprimer} ${styles.skeleton}`}></div>
            </div>
          </div>
          <div className={`${styles.links} ${styles.skeleton}`}></div>
          <div className={`${styles.links_2} ${styles.skeleton}`}></div>
        </div>

        <div className={`${styles.block_loading_image} ${styles.block_image_hotel}`}>
          <div className={`${styles.box1} ${styles.skeleton}`}></div>
          <div className={`${styles.box2} ${styles.skeleton}`}></div>
          <div className={`${styles.box3} ${styles.skeleton}`}></div>
          <div className={`${styles.box4} ${styles.skeleton}`}></div>
          <div className={`${styles.box5} ${styles.skeleton}`}></div>
        </div>

        <div className={styles.avaibility}>
          <div className={`${styles.price} ${styles.skeleton}`}></div>
          <div className={`${styles.search} ${styles.skeleton}`}></div>
          <div className={`${styles.btn} ${styles.skeleton}`}></div>
        </div>

        <div className={`${styles.destination_detail_block} ${styles.loading}`}>
          <div className={`${styles.hotel_type} ${styles.skeleton}`}></div>
          <div className={`${styles.hotel_nom} ${styles.skeleton}`}></div>
          <div className={`${styles.hotel_type} ${styles.none} ${styles.skeleton}`}></div>
          <div className={`${styles.hotel_nom} ${styles.none} ${styles.skeleton}`}></div>
          {/*---------pour mobile----*/}
          <div className={styles.mobile}>
            <div className={`${styles.bar} ${styles.skeleton}`}></div>
            <div className={`${styles.commente} ${styles.skeleton}`}></div>
            <div className={`${styles.text} ${styles.skeleton}`}></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoadingComponent
