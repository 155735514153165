import styles from '../../../../css/search_room_result/filtre_2.module.css'
import { useSearchParams } from '../../../../context/SearchParamContext'
import { TYPE_LOGEMENT } from '../../../../enums/logement/type_logement.enums'
import Checkbox from '../../../ui/Checkbox'

const TypeAccomodation = () => {
  const array = [
    { name: 'Logement entier', value: TYPE_LOGEMENT.ENTIRE_HOME },
    { name: 'Chambre privée', value: TYPE_LOGEMENT.PRIVATE_ROOM },
    { name: 'Chambre partagée', value: TYPE_LOGEMENT.SHARED_ROOM },
  ]
  const { params, updateParams } = useSearchParams()
  const newParams = new URLSearchParams(params.toString())

  URLSearchParams.prototype.remove = function (key, value) {
    const entries = this.getAll(key)
    const newEntries = entries.filter(entry => entry !== value)
    this.delete(key)
    newEntries.forEach(newEntry => this.append(key, newEntry))
  }

  const onchangeFilters = e => {
    if (!e.target.checked === true) {
      newParams.delete('page')
      newParams.remove('accommodation_type', e.target.value)
    } else {
      newParams.delete('page')
      newParams.append('accommodation_type', e.target.value)
    }
    updateParams(newParams)
  }

  return (
    <div className={styles.block_section_filter}>
      <h3>Type de logement</h3>
      <div className={styles.grid_system}>
        {array.map(item => (
          <div key={item.name} className={styles.checkbox_container}>
            <div>
              <Checkbox
                id={item.name}
                value={item.value}
                checked={params.getAll('accommodation_type').includes(item.value)}
                onChange={onchangeFilters}
              />
            </div>
            <label htmlFor={item.name}>{item.name}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TypeAccomodation
