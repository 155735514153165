import { useEffect, useState, useContext } from 'react'
import styles from '../../../css/account/securite.module.css'
import { Helmet } from 'react-helmet-async'
import { useHistory, Link } from 'react-router-dom'
import { User } from '../../../context/UserContext'
import LoadingPoint from '../../../components/LoadingPoint'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import DefaultLayout from '../../../layouts/DefaultLayout'
import AllAccountSetings from '../../../components/accounts/AllAccountSetings'
import ErrorSvg from '../../../components/svgs/ErrorSvg'

const SecurityAccount = () => {
  const [loadingbutton, setLoadingbutton] = useState(false)
  const [echecConnexion, setEchecConnexion] = useState(false)
  const [reussit, setReussit] = useState(false)
  const user = useContext(User)
  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
    if (user === null) {
      history.push('/')
    }
  }, [])

  const formSchema = yup.object().shape({
    password: yup.string().required("Veuillez saisir l'ancien mot de passe"),
    newPassword: yup
      .string()
      .required('Entrer le nouveau mot de passe')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
        'Le mot de passe doit comporter au moins 8 caractères et 1 caractère Spécial, 1 Majuscule, 1 Chiffre et 1 Minuscule'
      ),
    newPassword_2: yup
      .string()
      .required('Confirmer le mot de passe est requis')
      .oneOf([yup.ref('newPassword')], 'Les mots de passe ne correspondent pas !'),
  })

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onChange', resolver: yupResolver(formSchema) })

  const onSubmit = data => {
    setLoadingbutton(true)
    axios
      .patch(`/user/update_passeword/` + user.id, data)
      .then(() => {
        setLoadingbutton(false)
        setEchecConnexion(false)
        setReussit(true)
        reset()
      })
      .catch(() => {
        setEchecConnexion(true)
        setLoadingbutton(false)
      })
  }

  return (
    <DefaultLayout footer={true} navTopMobile={true}>
      <Helmet>
        <title>Connexion et sécurité - Gowifmi</title>
      </Helmet>
      <div className={styles.seting_container}>
        <div className={styles.seting_box}>
          <div className={styles.AllAccount_setings}>
            <AllAccountSetings />
          </div>
          <div className={styles.separate}> </div>
          <div className={styles.securite_1}>
            <div>
              <h1>Connexion et sécurité</h1>
            </div>
            <p>Mettre à jour votre mot de passe</p>
            {echecConnexion && (
              <div className={styles.connexion_echec}>
                <div>
                  <ErrorSvg width={24} height={24} />
                </div>
                <div className={styles.notif}>
                  <h1>Ancien mot de passe est incorrecte</h1>
                  <p>
                    Veuillez réessayer <a href='/help/feedback'>Besoin d'aide?</a>
                  </p>
                </div>
              </div>
            )}
            {reussit && (
              <div className={styles.reussit_conexion}>
                <div className={styles.notif}>
                  <h1>Votre mot de passe est mis à jour </h1>
                  <p>Lors de votre nouvelle connexion utiliser le nouveau mot de passe.</p>
                </div>
              </div>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                className={
                  errors.password ? `${styles.block_input} ${styles.erreur}` : styles.block_input
                }
              >
                <label htmlFor='actuel'>Mot de passe actuel</label>
                <input type='password' id='actuel' autoComplete='none' {...register('password')} />
                {errors.password && (
                  <span className={styles.erreur}>{errors.password.message}</span>
                )}
                <div className={styles.link_new}>
                  <Link to='/forgot_password'>Besoin d'un nouveau mot de passe ?</Link>
                </div>
              </div>
              <div
                className={
                  errors.newPassword ? `${styles.block_input} ${styles.erreur}` : styles.block_input
                }
              >
                <label htmlFor='nouveau'>Nouveau mot de passe</label>
                <input
                  type='password'
                  id='nouveau'
                  autoComplete='new-password'
                  aria-autocomplete='list'
                  spellCheck='false'
                  {...register('newPassword')}
                />
                {errors.newPassword && (
                  <span className={styles.erreur}>{errors.newPassword.message}</span>
                )}
              </div>
              <div
                className={
                  errors.newPassword_2
                    ? `${styles.block_input} ${styles.erreur}`
                    : styles.block_input
                }
              >
                <label htmlFor='confirme'>Confirmer le mot de passe</label>
                <input
                  type='password'
                  autoComplete='off'
                  id='confirme'
                  {...register('newPassword_2')}
                />
                {errors.newPassword_2 && (
                  <span className={styles.erreur}>{errors.newPassword_2.message}</span>
                )}
              </div>
              <input
                type='text'
                autoComplete='on'
                value=''
                style={{ display: 'none', opacity: 0, position: 'absolute', left: '-100000px' }}
                readOnly={true}
              />
              <div className={styles.buttoms}>
                {loadingbutton ? (
                  <button disabled className={styles.loading}>
                    <LoadingPoint />
                  </button>
                ) : (
                  <button type='submit'>Mettre à jour le mot de passe</button>
                )}
              </div>
            </form>
          </div>
        </div>
        <div className={styles.securite_2}>
          <h2>Renforcez la sécurité de votre compte</h2>
          <p>
            Pour changer votre mot de passe, vérifiez votre mot de passe actuel, puis créez un
            nouveau mot de passe que vous n’utilisez pas ailleurs. Modifiez votre mot de passe
            chaque fois que vous pensez qu’il a pu être compromis.
          </p>
        </div>
      </div>
    </DefaultLayout>
  )
}

export default SecurityAccount
