import { useContext, useState } from 'react'
import styles from '../../../css/account/disabledAccount.module.css'
import Swal from 'sweetalert2'
import axios from 'axios'
import { User } from '../../../context/UserContext'
import LoadingPoint from '../../LoadingPoint'

const Block2 = ({ setActive, raison1, raison2, setRaison2 }) => {
  const [loading, setLoading] = useState(false)
  const user = useContext(User)

  const handleSubmit = () => {
    setLoading(true)

    const data = {
      isDisabled_reason_1: raison1,
      isDisabled_reason_2: raison2,
    }

    axios
      .patch(`/user/disable_account/${user?.id}`, data)
      .then(() => {
        setLoading(false)
        window.location.reload()
      })
      .catch(() => {
        setLoading(false)
        Swal.fire('Warning', 'Un problème est sur venu au nuveau de serveur', 'warning')
      })
  }

  return (
    <>
      <div className={styles.m_body}>
        <div>
          <h3>Voulez-vous désactiver le compte ?</h3>
          <p>{user.email}</p>
          <div className={styles.notif}>
            <i className='fas fa-exclamation-circle'></i>
            <p>Le profil et les annonces associées à ce compte ne seront plus visibles.</p>
          </div>
          <div className={styles.notif}>
            <i className='fas fa-exclamation-circle'></i>
            <p>
              Vous ne pourrez plus accéder aux informations du compte ni aux réservations passées.
            </p>
          </div>
        </div>

        <div className={styles.message_content}>
          <label htmlFor='message'>Ajouter un message</label>
          <textarea
            id='message'
            value={raison2}
            onChange={e => setRaison2(e.target.value)}
          ></textarea>
        </div>
      </div>
      <div className={styles.button_action}>
        <button className={styles.retour} onClick={() => setActive(1)}>
          Retour
        </button>
        {loading ? (
          <button className={`${styles.next} ${styles.disabled}`} disabled>
            <LoadingPoint />
          </button>
        ) : (
          <button className={styles.next} onClick={handleSubmit}>
            Désactiver le compte
          </button>
        )}
      </div>
    </>
  )
}

export default Block2
