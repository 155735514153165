import { Helmet } from 'react-helmet-async'
import styles from '../../css/about_us/about.module.css'
import DefaultLayout from '../../layouts/DefaultLayout'

function LegalInformation() {
  return (
    <DefaultLayout footer={true}>
      <Helmet>
        <title>Mentions légales</title>
      </Helmet>

      <div className={styles.main}>
        <div className={styles.pourquoi_text}>
          <h2>Mentions légales</h2>
          <p>
            La société Gowifmi est immatriculée à Albuquerque, aux États-Unis, elle fournit un
            service de réservation d’hébergements en ligne.
          </p>
          <p>
            <strong>Éditeur du site web :</strong> Gowifmi LLC, Limited Liability Company
          </p>
          <p>
            <strong>Siège social :</strong> 2201 MENAUL BLVD NE STE A Albuquerque NM, 87107
            États-Unis
          </p>
          <p>
            <strong>Numéro d’identification d’entreprise :</strong> 7473885 (Bureau du secrétaire
            d'État du New Mexico, aux États-Unis.)
          </p>
          <p>
            <strong>Numéro d’identification fiscale :</strong> 99-0478121
          </p>
          <p>
            Pour toute question concernant Gowifmi, le service (c'est-à-dire le service de
            réservation d'hébergements en ligne) et le site internet, ou si vous souhaitez envoyer
            ou signifier des documents, correspondances, notifications ou d'autres communications
            liées à Gowifmi, le service ou le site internet, ou pour les demandes de presse,
            veuillez contacter directement Gowifmi.
          </p>
          <p>
            <strong>Adresse mail :</strong>{' '}
            <a href='mailto:contact@gowifmi.com'>contact@gowifmi.com</a>
          </p>
          <p>
            <strong>Assistance Gowifmi :</strong>{' '}
            <a href='/help/feedback' target='_blanck'>
              https://gowifmi.com/help/feedback
            </a>
          </p>
          <p>
            Gowifmi LLC agit en qualité d'intermédiaire technique et ne participe pas au choix des
            contenus mis en ligne par ses utilisateurs. Plus d'informations sont disponibles dans
            nos conditions générales.
          </p>
        </div>
      </div>
    </DefaultLayout>
  )
}
export default LegalInformation
