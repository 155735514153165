import styles from '../../../../../css/modehotes/annonce/filtres/filtre.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import { SATATUS_ANNONCE } from '../../../../../enums/logement/status.enums'
import Checkbox from '../../../../ui/Checkbox'

const Statut = () => {
  const array = [
    { name: 'Publiée', value: SATATUS_ANNONCE.PUBLISH },
    { name: 'Masquée', value: SATATUS_ANNONCE.HIDE },
    { name: 'En cours', value: SATATUS_ANNONCE.IN_PROGRESS },
    { name: 'Enregistrée', value: SATATUS_ANNONCE.SAVE },
  ]
  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  URLSearchParams.prototype.remove = function (key, value) {
    const entries = this.getAll(key)
    const newEntries = entries.filter(entry => entry !== value)
    this.delete(key)
    newEntries.forEach(newEntry => this.append(key, newEntry))
  }
  const history = useHistory()
  const location = useLocation()
  const path = location.pathname
  const onchangeFilters = e => {
    if (e.target.checked === true) {
      Params.append('status', e.target.value)
      history.push(`${path}?${Params}`)
    } else {
      Params.remove('status', e.target.value)
      history.push(`${path}?${Params}`)
    }
  }
  return (
    <div className={styles.block_section_filter}>
      <h3>Statut de l'annonce</h3>
      <div className={styles.grid_system}>
        {array.map(item => (
          <div key={item.name} className={styles.checkbox_container}>
            <div>
              <Checkbox
                id={item.name}
                value={item.value}
                checked={Params.getAll('status').includes(item.value)}
                onChange={onchangeFilters}
              />
            </div>
            <label htmlFor={item.name}>{item.name}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Statut
