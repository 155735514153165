import { Helmet } from 'react-helmet-async'
import styles from '../../css/about_us/about.module.css'
import DefaultLayout from '../../layouts/DefaultLayout'

function PolicyPaymentForTraveler() {
  return (
    <DefaultLayout footer={true}>
      <Helmet>
        <title>Politique de paiement sur place chez l'hôte</title>
      </Helmet>

      <div className={styles.main}>
        <div className={styles.pourquoi_text}>
          <h2>Politique de paiement sur place chez l'hôte</h2>
          <h3>Introduction</h3>
          <p>
            Nous engageons à offrir des options de réservation flexibles et adaptées à vos besoins.
            Nous comprenons que chaque voyageur a des préférences différentes en matière de
            paiement. Pour ceux qui préfèrent effectuer leurs transactions en personne, nous offrons
            l'option de paiement sur place chez l'hôte. Cette politique détaille les conditions et
            les procédures liées à cette option afin d'assurer une expérience fluide et agréable
            pour les voyageurs et de protéger les intérêts des hôtes.
          </p>

          <div>
            <h2>Conditions de paiement sur place</h2>
            <h3>Réservation provisoire</h3>
            <p>
              <strong>Réservation provisoire :</strong> Votre réservation est considérée comme
              provisoire jusqu'à ce que le paiement soit effectué chez l'hôte à votre arrivée.
            </p>
            <p>
              <strong>Confirmation de réservation :</strong> Vous recevrez une confirmation de
              réservation provisoire avec tous les détails de votre séjour. La réservation sera
              définitivement confirmée une fois le paiement effectué chez l'hôte.
            </p>
          </div>

          <div>
            <h3>Acompte</h3>
            <p>
              <strong>Demande d'acompte :</strong> L'acompte n'est pas obligatoire, mais certains
              hôtes peuvent choisir de demander un acompte pour bloquer les dates demandées. Cela
              permet de sécuriser votre réservation et d'assurer à l'hôte que vous respecterez votre
              engagement.
            </p>
            <p>
              <strong>Montant de l'acompte :</strong> Le montant de l'acompte est fixé par l'hôte et
              peut varier selon les conditions spécifiques de chaque hébergement.
            </p>
            <p>
              <strong>Mode de paiement de l'acompte :</strong> L'acompte peut être payé via virement
              bancaire, carte de crédit, mobile money ou tout autre moyen de paiement sécurisé
              convenu avec l'hôte.
            </p>
            <p>
              <strong>Confirmation de l'acompte :</strong> Une fois l'acompte reçu, l'hôte enverra
              une confirmation de réservation définitive.
            </p>
          </div>

          <div>
            <h3>Paiement à l'arrivée</h3>
            <p>
              <strong>Paiement du solde :</strong> Le solde restant de la réservation doit être payé
              directement à l'hôte à votre arrivée. Les modes de paiement acceptés peuvent varier et
              doivent être confirmés avec l'hôte à l'avance.
            </p>
            <p>
              <strong>Réception du paiement :</strong> À la réception du paiement, l'hôte fournira
              une preuve de paiement et les clés du logement.
            </p>
          </div>

          <div>
            <h2>Politique d'annulation et de Non-présentation</h2>
            <h3>Annulations de Dernière Minute</h3>
            <p>
              <strong>Impact sur l'hôte :</strong> Les annulations de dernière minute peuvent
              perturber sérieusement le calendrier de l'hôte, entraînant une perte potentielle de
              revenus.
            </p>
            <p>
              <strong>Pénalités :</strong> En cas d'annulation de dernière minute (moins de 48
              heures avant l'arrivée), l'acompte versé, s'il y en a un, peut ne pas être remboursé.
              Cette politique varie selon les conditions spécifiques de chaque hôte. Il est
              recommandé de vérifier les conditions d'annulation lors de la réservation.
            </p>
          </div>

          <div>
            <h3>Non-présentation</h3>
            <p>
              <strong>Perturbation du Calendrier :</strong> La non-présentation sans préavis impacte
              négativement l'hôte en laissant une période de réservation inutilisée.
            </p>
            <p>
              <strong>Pénalités pour non-présentation :</strong> En cas de non-présentation,
              l'acompte versé, s'il y en a un, ne sera pas remboursé, et l'hôte peut demander le
              paiement complet de la réservation, selon les termes convenus.
            </p>
          </div>

          <div>
            <h3>La sécurité du voyageur</h3>
            <p>
              Pour assurer votre sécurité et protéger vos informations personnelles, toutes les
              interactions entre voyageurs et hôtes doivent se faire via la messagerie de Gowifmi.
            </p>

            <p>
              <strong>Interdiction de partage de données personnelles :</strong>Ne partagez en aucun
              cas vos informations personnelles comme les informations bancaires ou toute autre
              information sensible avec l'hôte en exception du paiement d’un acompte et cela doit se
              faire sur une plateforme sécurisée et connue de tous.
            </p>
            <p>
              <strong>Signaler les demandes inappropriées :</strong> Si un hôte vous demande de
              communiquer en dehors de Gowifmi, contactez immédiatement notre service client.
            </p>
            <p>
              <strong>Traçabilité des conversations :</strong> En utilisant la messagerie Gowifmi,
              nous pouvons retracer l'historique des communications en cas de litige. Gowifmi ne
              surveille pas les messages entre hôtes et voyageurs, mais en cas de litige, une
              vérification peut être effectuée pour résoudre le problème.
            </p>
          </div>

          <div>
            <h3>Exemple de cas</h3>
            <p>
              Monsieur Dupont réserve un chalet pour un séjour de 5 jours sur Gowifmi. Il choisit
              l'option de paiement sur place chez l'hôte et contacte l'hôte pour finaliser les
              détails.
            </p>
            <ol>
              <li>
                <strong>Acompte :</strong> L'hôte ne demande pas d'acompte pour cette réservation.
              </li>
              <li>
                <strong>Confirmation de réservation :</strong> Monsieur Dupont reçoit une
                confirmation de réservation provisoire avec tous les détails de son séjour.
              </li>
              <li>
                <strong>Paiement à l'arrivée :</strong> À son arrivée, Monsieur Dupont paie le
                montant total de la réservation de 500$ en espèces.
              </li>
              <li>
                <strong>Annulation de dernière minute :</strong>Si Monsieur Dupont avait annulé sa
                réservation 24 heures avant son arrivée, il n'aurait eu aucune pénalité car aucun
                acompte n'avait été versé.
              </li>
              <li>
                <strong>Non-présentation :</strong> Si Monsieur Dupont ne s'était pas présenté sans
                avertir l'hôte, ce dernier aurait le droit de demander le paiement complet de 500$
                selon les termes convenus.
              </li>
            </ol>
          </div>

          <div>
            <h3>Conclusion</h3>
            <p>
              Nous espérons que cette politique de paiement sur place clarifie les conditions et les
              procédures liées à votre réservation. Chez Gowifmi, nous nous engageons à offrir une
              expérience agréable et sans stress tant pour les voyageurs que pour les hôtes.
            </p>

            <p>
              Pour toute question ou besoin d'assistance, n'hésitez pas à contacter notre service
              client.
            </p>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}
export default PolicyPaymentForTraveler
