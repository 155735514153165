import { useState, useEffect } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const Title = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [titre, setTitre] = useState('')
  const [avantage, setAvantage] = useState('')
  const { loading, updateData } = useUpdateHotel(setOpen)

  const handleSubmit = e => {
    e.preventDefault()
    const datas = {
      title: titre,
      advantage_marketing: avantage,
    }
    updateData(datas)
  }
  useEffect(() => {
    setTitre(annonce.title || '')
    setAvantage(annonce.advantage_marketing || '')
  }, [annonce])
  const openFuction = () => {
    setTitre(annonce.title || '')
    setAvantage(annonce.advantage_marketing || '')
    setOpen(true)
  }
  const reset = () => {
    setTitre('')
    setAvantage('')
    setOpen(false)
  }
  return (
    <div className={styles.block}>
      {!open ? (
        <Section title="Titre de l'annonce" handleOpen={openFuction}>
          <p>{annonce.title}</p>
          <p>{annonce.advantage_marketing}</p>
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title="Titre de l'annonce" handleReset={reset}>
            <p>
              Le titre de votre annonce doit mettre en valeur ce qui fait la particularité de votre
              logement.
            </p>
          </ContenHead>
          <form onSubmit={handleSubmit}>
            <div className={styles.body}>
              <div className={styles.input_block}>
                <input
                  type='text'
                  value={titre}
                  maxLength={50}
                  onChange={e => setTitre(e.target.value)}
                />
                <span>{titre.length}/50</span>
              </div>
              <div className={styles.input_block}>
                <h4>Attirer les voyageurs</h4>
                <p>
                  Pourquoi un voyageur devrait-il choisir de séjourner dans votre établissement ?
                </p>
                <input
                  type='text'
                  value={avantage}
                  maxLength={80}
                  onChange={e => setAvantage(e.target.value)}
                />
                <span>{avantage.length}/80</span>
              </div>
            </div>
            <BottomForm disabled={titre.length < 1} loading={loading} handleReset={reset} />
          </form>
        </div>
      )}
    </div>
  )
}

export default Title
