import { useEffect, useState, useContext } from 'react'
import styles from '../../../../css/modehotes/inbox/listemessageHote.module.css'
import { useHistory, Link } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { User } from '../../../../context/UserContext'
import LoadingPoint from '../../../../components/LoadingPoint'
import HostModeLayout from '../../../../layouts/HostModeLayout'
import { Helmet } from 'react-helmet-async'
import SideNavigation from '../../../../components/hote_mode/inboxs/SideNavigation'

const ListArchiveMessageHost = () => {
  const [loading, setLoading] = useState(true)
  const [filtermessages, setFiltermessages] = useState([])
  const [modalLeftbar, setModalLeftbar] = useState(false)

  const user = useContext(User)
  const history = useHistory()
  useEffect(() => {
    window.scrollTo(0, 0)
    if (user === null) {
      history.push('/')
    }
    getAllmessagesFilter()
  }, [history, user])

  const getAllmessagesFilter = () => {
    axios
      .get(`/hotemessage/filtermessage?filter=hidden`)
      .then(res => {
        setFiltermessages(res.data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }
  return (
    <HostModeLayout>
      <Helmet>
        <title>Messages archivés - Gowifmi</title>
      </Helmet>
      <div className={styles.liste_container}>
        <div className={styles.block_grid}>
          <SideNavigation modalLeftbar={modalLeftbar} setModalLeftbar={setModalLeftbar} />
          <div className={styles.block_grid_2}>
            <div className={styles.head}>
              <div className={styles.left}>
                <button type='button' onClick={() => setModalLeftbar(true)}>
                  <svg
                    viewBox='0 0 32 32'
                    xmlns='http://www.w3.org/2000/svg'
                    aria-hidden='true'
                    role='presentation'
                    focusable='false'
                    style={{
                      display: 'block',
                      fill: 'none',
                      height: '16px',
                      width: '16px',
                      stroke: 'currentColor',
                      strokeWidth: '3',
                      overflow: 'visible',
                    }}
                  >
                    <g fill='none'>
                      <path d='M2 16h28M2 24h28M2 8h28'></path>
                    </g>
                  </svg>
                </button>
                <h2>Messages archivés</h2>
              </div>
            </div>
            {loading ? (
              <div className={styles.loading_container}>
                <LoadingPoint />
              </div>
            ) : (
              <>
                {filtermessages.length > 0 ? (
                  <div className={styles.liste}>
                    {filtermessages.map(message => (
                      <Link
                        to={`/hosting/inbox/detail/${message._id}?filter=hidden`}
                        key={message._id}
                        className={styles.liste_content}
                      >
                        <div>
                          <div className={styles.img_block}>
                            {message.user?.picture ? (
                              <img src={message.user?.picture} alt='' />
                            ) : (
                              <h2>{message.user?.first_name?.slice(0, 1)}</h2>
                            )}
                          </div>
                        </div>
                        <div className={styles.profil_text}>
                          <div
                            className={!message.message_box[0].host_read ? styles.non_lue : null}
                          >
                            <h5>
                              {message.host?.first_name} - <span>{message.hotel?.title}</span>
                            </h5>
                            <div className={styles.last_message}>
                              {message.message_box[0].userId?.id === user?.id && (
                                <span>Vous :</span>
                              )}
                              <p>{message.message_box[0].text}</p>
                            </div>
                            {message.message_box[0].host_read ? (
                              <span className={styles.statut}>Lue</span>
                            ) : (
                              <span className={styles.statut}>Non lue</span>
                            )}
                          </div>
                          <h6>{moment(message.message_box[0].timestamp).calendar()}</h6>
                        </div>
                      </Link>
                    ))}
                  </div>
                ) : (
                  <div className={styles.no_result}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 32 32'
                      aria-hidden='true'
                      role='presentation'
                      focusable='false'
                      style={{
                        display: 'block',
                        height: '24px',
                        width: '24px',
                        fill: 'currentcolor',
                      }}
                    >
                      <path d='M26 1a5 5 0 0 1 5 4.78v10.9a5 5 0 0 1-4.78 5H26a5 5 0 0 1-4.78 5h-4l-3.72 4.36-3.72-4.36H6a5 5 0 0 1-4.98-4.56L1 21.9 1 21.68V11a5 5 0 0 1 4.78-5H6a5 5 0 0 1 4.78-5H26zm-5 7H6a3 3 0 0 0-3 2.82v10.86a3 3 0 0 0 2.82 3h4.88l2.8 3.28 2.8-3.28H21a3 3 0 0 0 3-2.82V11a3 3 0 0 0-3-3zm-1 10v2H6v-2h14zm6-15H11a3 3 0 0 0-3 2.82V6h13a5 5 0 0 1 5 4.78v8.9a3 3 0 0 0 3-2.82V6a3 3 0 0 0-2.82-3H26zM15 13v2H6v-2h9z'></path>
                    </svg>
                    <h3>Aucun message</h3>
                    <p>Il n'y a aucun message dans le dossier Messages archivés.</p>
                    <Link to='/hosting/inbox/all'>Accéder a tous les messages</Link>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </HostModeLayout>
  )
}

export default ListArchiveMessageHost
