import { useEffect, useContext } from 'react'
import { User } from '../../context/UserContext'
import { useHistory } from 'react-router-dom'
import globalStyle from '../../css/becomeHostes/globalStyle.module.css'
import Form from '../../components/become_host/titles/Form'
import DescriptionText from '../../components/become_host/globals/DescriptionText'
import { Helmet } from 'react-helmet-async'

const Title = () => {
  const user = useContext(User)
  const history = useHistory()
  useEffect(() => {
    if (user === null) {
      history.push('/host/homes')
    }
  }, [history, user])
  return (
    <>
      <Helmet>
        <title>Donner un titre à l'annonce - Gowifmi</title>
      </Helmet>
      <div className={globalStyle.container}>
        <Form />
        <DescriptionText
          title='Donnez un nom à votre logement'
          desc='Vous pourrez toujours le modifier plus tard, tout comme les autres informations fournies'
        />
      </div>
    </>
  )
}

export default Title
