const CheckAmenitieSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 32 32'
      aria-hidden='true'
      role='presentation'
      focusable='false'
      style={{
        display: 'block',
        fill: 'none',
        height: '20px',
        width: '20px',
        stroke: 'currentcolor',
        strokeWidth: '2.66667',
        overflow: 'visible',
      }}
    >
      <path fill='none' d='m4 16.5 8 8 16-16'></path>
    </svg>
  )
}

export default CheckAmenitieSvg
