import { useState, useContext, useEffect } from 'react'
import styles from '../../../../css/booking/formulaire.module.css'
import { User } from '../../../../context/UserContext'
import { useQuery } from 'react-query'
import LoadingPoint from '../../../LoadingPoint'
import moment from 'moment'
import Cancellation from './components/Cancellation'
import ErrorInfos from './components/ErrorInfos'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import InfosUser from './components/InfosUser'
import DateInvalid from './components/errors/DateInvalid'
import { appearance } from './components/appearance'
import CheckoutForm from './payments/CheckoutForm'
import axios from 'axios'
import { stripeApiKey } from '../../../../config/config'

const CreatePaymentIntent = ({
  payOnline,
  hotel,
  numberOfTravelerIsInvalid,
  handleVerifDisponibilite,
  startDateInvalid,
  endDateInvalid,
}) => {
  const user = useContext(User)
  const [stripePromise, setStripePromise] = useState('')
  const [clientSecret, setClientSecret] = useState('')
  const [paymentIntent_id, setPaymentIntent_id] = useState('')
  const [message, setMessage] = useState('')
  const [messageError, setMessageError] = useState(false)
  const [telephone, setTelephone] = useState(user ? user.phone_number : '')
  const [phoneError, setPhoneError] = useState(false)
  const [loading, setLoading] = useState(false)

  const hotel_id = window.location.pathname.split('/')[2]
  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  const checkin = Params.get('checkin')
  const checkout = Params.get('checkout')

  useEffect(() => {
    const stripePromiseLoad = loadStripe(stripeApiKey)
    setStripePromise(stripePromiseLoad)
  }, [clientSecret])

  const data = {
    hotel_id: hotel_id,
    current_date: moment(new Date()).format('YYYY-MM-DD'),
    checkin: moment(checkin).format('YYYY-MM-DD'),
    checkout: moment(checkout).format('YYYY-MM-DD'),
  }
  useQuery(`create-payment-intent-direct`, () =>
    axios.post(`/stripe/create-payment-intent`, data).then(res => {
      setClientSecret(res.data.clientSecret)
      setPaymentIntent_id(res.data.paymentIntent_id)
    })
  )

  const options = {
    clientSecret,
    appearance,
  }

  return (
    <div className={styles.recap_form}>
      {clientSecret ? (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            hotel={hotel}
            payOnline={payOnline}
            paymentIntent_id={paymentIntent_id}
            setLoading={setLoading}
            telephone={telephone}
            message={message}
            setMessageError={setMessageError}
            setPhoneError={setPhoneError}
          >
            <div>
              <InfosUser
                payOnline={payOnline}
                message={message}
                setMessage={setMessage}
                telephone={telephone}
                setTelephone={setTelephone}
                messageError={messageError}
                hotel={hotel}
                phoneError={phoneError}
              />
              <div>
                <Cancellation hotel={hotel} />
                {handleVerifDisponibilite() ? (
                  <DateInvalid
                    numberOfTravelerIsInvalid={numberOfTravelerIsInvalid}
                    startDateInvalid={startDateInvalid}
                    endDateInvalid={endDateInvalid}
                    hotel={hotel}
                  />
                ) : (
                  <div>
                    {loading ? (
                      <button
                        type='button'
                        className={`${styles.submit} ${styles.loader_btn}`}
                        disabled
                      >
                        <LoadingPoint />
                      </button>
                    ) : (
                      <div>
                        <ErrorInfos messageError={messageError} phoneError={phoneError} />
                        <button type='submit' className={styles.submit}>
                          Confirmer et payer
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </CheckoutForm>
        </Elements>
      ) : (
        <div>
          <LoadingPoint />
        </div>
      )}
    </div>
  )
}

export default CreatePaymentIntent
