import styles from '../../../../../../css/modehotes/annonce/details/reglementInterieur.module.css'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import Checkbox from '../../../../../ui/Checkbox'

const PetRegulation = ({ annonce }) => {
  const { updateData } = useUpdateHotel()

  const onchangeFilters = e => {
    if (e.target.checked === true) {
      const datas = {
        regulations: {
          ...annonce.regulations,
          accept_pets: true,
        },
      }
      updateData(datas)
    } else {
      const datas = {
        regulations: {
          ...annonce.regulations,
          accept_pets: false,
        },
      }
      updateData(datas)
    }
  }
  return (
    <div>
      <div className={styles.checkbox_container}>
        <div className={styles.text}>
          <label htmlFor='Animaux'>Animaux acceptés</label>
          {annonce.regulations?.accept_pets ? (
            <p>Vous acceptez les animaux dans le logement.</p>
          ) : (
            <p>Vous refusez les animaux dans le logement.</p>
          )}
        </div>
        <div>
          <Checkbox
            id='Animaux'
            value={true}
            checked={annonce.regulations?.accept_pets}
            onChange={onchangeFilters}
          />
        </div>
      </div>
    </div>
  )
}

export default PetRegulation
