import { useEffect, useState, useContext } from 'react'
import styles from '../../../css/account/securite.module.css'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'
import { User } from '../../../context/UserContext'
import LoadingPoint from '../../../components/LoadingPoint'
import axios from 'axios'
import DefaultLayout from '../../../layouts/DefaultLayout'
import AllAccountSetings from '../../../components/accounts/AllAccountSetings'
import SvgIconRightDirector from '../../../components/svgs/SvgIconRightDirector'

const Versement = () => {
  const [loading, setLoading] = useState(true)
  const [accountLink, setAccountLink] = useState(null)
  const [loginLink, setLoginLink] = useState(null)

  const user = useContext(User)
  const history = useHistory()
  useEffect(() => {
    getAccoutLink()
    getLoginLink()
  }, [])
  const getAccoutLink = () => {
    axios
      .post(`/stripe_connect_account/createStripeaccountLink`)
      .then(res => {
        setAccountLink(res.data)
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }
  const getLoginLink = () => {
    axios
      .post(`/stripe_connect_account/createStripeLogin`)
      .then(res => {
        setLoginLink(res.data)
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  if (user === null) {
    history.push('/')
  }

  return (
    <DefaultLayout footer={true} navTopMobile={true}>
      <Helmet>
        <title>Gérez vos modes de versement Historique des transactions Gowifmi</title>
      </Helmet>
      <div className={styles.seting_container}>
        <div className={styles.seting_box}>
          <div className={styles.AllAccount_setings}>
            <AllAccountSetings />
          </div>
          <div className={styles.separate}> </div>
          <div className={styles.securite_1}>
            <div>
              <h1>Vos modes de versement et Historique des transactions</h1>
            </div>
            {loading ? (
              <div className=''>
                <LoadingPoint />
              </div>
            ) : (
              <div className={styles.seting_content}>
                {loginLink ? (
                  <div>
                    <p>Historique des transactions</p>
                    <p>Faites le suivi de tous vos historiques des transactions.</p>
                    <div className={styles.link_versement}>
                      <a href={loginLink?.url} target='_blank' rel='noreferrer'>
                        <p>Accéder à mon historique des transactions</p>
                        <SvgIconRightDirector />
                      </a>
                    </div>
                  </div>
                ) : (
                  <div>
                    <p>Configurer les versements</p>
                    <p>
                      Ajoutez au moins un mode de versement pour nous indiquer où envoyer votre
                      argent.
                    </p>
                    <div className={styles.link_versement}>
                      <p>
                        Nous vous redirigerons ensuite vers le site de notre partenaire Stripe, pour
                        la saisie de vos coordonnées bancaires.
                      </p>
                      <a href={accountLink?.url} target='_blank' rel='noreferrer'>
                        <div>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 16 16'
                            aria-hidden='true'
                            role='presentation'
                            focusable='false'
                            style={{
                              display: 'block',
                              height: '16px',
                              width: '16px',
                              fill: 'currentcolor',
                            }}
                          >
                            <path d='M10.25 4a2.25 2.25 0 0 0-4.495-.154L5.75 4v2h-1.5V4a3.75 3.75 0 0 1 7.495-.2l.005.2v2H13a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h7.25V4z'></path>
                          </svg>
                        </div>
                        <p>Continuer vers Stripe</p>
                        <SvgIconRightDirector />
                      </a>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className={styles.seting_box_2}>
          <h2>Besoin d'aide ?</h2>
          <p>
            Obtenez des réponses à vos questions sur la Configuration des moyens de versements et
            l'historique de vos transactions, contactez notre{' '}
            <a href='/help/feedback'>Service d'assistance</a>.
          </p>
        </div>
      </div>
    </DefaultLayout>
  )
}

export default Versement
