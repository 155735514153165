import styles from '../../../css/account/is_company_status.module.css'
import LoadingPoint from '../../LoadingPoint'
import useUserHook from '../../../hooks/useUserHook'
import Checkbox from '../../ui/Checkbox'

const IsCompanyStatus = () => {
  const { userHook, loadingUpdate, updateUser, errorUpdate } = useUserHook()
  const isCompanyAccount = userHook?.isCompany_account ?? false

  const handleSubmit = e => {
    const datas = {
      isCompany_account: e.target.checked,
    }
    updateUser(datas)
  }

  if (errorUpdate) alert('error')

  return (
    <div className={styles.checkbox_container}>
      <div>
        <label htmlFor='account_type'>
          <p> Compte entreprise</p>
          <span>Cochez cette case si vous avez créé ce compte au nom de votre entreprise.</span>
        </label>
      </div>
      <div>
        {loadingUpdate ? (
          <LoadingPoint />
        ) : (
          <Checkbox
            id='account_type'
            value={isCompanyAccount}
            checked={isCompanyAccount}
            onChange={e => handleSubmit(e)}
          />
        )}
      </div>
    </div>
  )
}

export default IsCompanyStatus
