import styles from '../../../../../../../css/modehotes/reservation/reservation_status.module.css'
import { REFUND_SATATUS } from '../../../../../../../enums/booking/refundStatus.enums'
import CheckSvg from '../../../../../../svgs/CheckSvg'
import PendingSvg from '../../../../../../svgs/PendingSvg'
import PayementStatus from '../../../../../default_components/PaymentStatus'

const RefundPayment = ({ refund }) => {
  return (
    <div>
      <h3>Remboursement</h3>
      <PayementStatus state={refund?.status} />
      {refund?.status === REFUND_SATATUS.COMPLETE && (
        <>
          <div className={styles.block}>
            <p className={styles.complet}>
              <span>Réussi</span> <CheckSvg />
            </p>
          </div>
          <p>
            <strong>Message</strong> : Le remboursement est effectué avec succès.
          </p>
        </>
      )}
      {refund?.status === REFUND_SATATUS.IN_PROGRESS && (
        <>
          <div className={styles.block}>
            <p className={styles.traitement}>
              <span>Traitement</span> <PendingSvg />
            </p>
          </div>
          <p>
            <strong>Message</strong> : Le remboursement est en cours de traitement. <br />
            Nous vous enverron l'etat du voyage par cette adresse e-mail
          </p>
        </>
      )}
      {refund?.status === REFUND_SATATUS.REJECTED && (
        <>
          <div className={styles.block}>
            <p className={styles.try}>
              <span>Réessayer</span> <PendingSvg />
            </p>
          </div>
          <p>
            <strong>Message</strong> : Le remboursement n'a pas abouti. <br />
            veuillez réessayer
          </p>
        </>
      )}
    </div>
  )
}

export default RefundPayment
