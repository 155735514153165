import styles from '../../../../css/search_room_result/filtre_2.module.css'
import { PROPERTY_TYPE_GROUP } from '../../../../enums/logement/type_property_group.enums'
import { useSearchParams } from '../../../../context/SearchParamContext'
import Checkbox from '../../../ui/Checkbox'

const TypeProperty = () => {
  const array = [
    { name: 'Hôtel', value: PROPERTY_TYPE_GROUP.HOTEL },
    { name: 'Maison', value: PROPERTY_TYPE_GROUP.HOUSE },
    { name: 'Appartement', value: PROPERTY_TYPE_GROUP.APARTMENT },
    { name: 'Guest house', value: PROPERTY_TYPE_GROUP.GUEST_HOUSE },
    { name: 'Annexe', value: PROPERTY_TYPE_GROUP.ANNEX },
    { name: 'Logement unique', value: PROPERTY_TYPE_GROUP.OTHER_NON_RESIDENTIAL_ACCOMMODATION },
  ]

  const { params, updateParams } = useSearchParams()
  const newParams = new URLSearchParams(params.toString())

  URLSearchParams.prototype.remove = function (key, value) {
    const entries = this.getAll(key)
    const newEntries = entries.filter(entry => entry !== value)
    this.delete(key)
    newEntries.forEach(newEntry => this.append(key, newEntry))
  }

  const onchangeFilters = e => {
    if (!e.target.checked === true) {
      newParams.delete('page')
      newParams.remove('property_type_group', e.target.value)
    } else {
      newParams.delete('page')
      newParams.append('property_type_group', e.target.value)
    }
    updateParams(newParams)
  }

  return (
    <div className={styles.block_section_filter}>
      <h3>Type de propriété</h3>
      <div className={styles.grid_system}>
        {array.map(item => (
          <div key={item.name} className={styles.checkbox_container}>
            <div>
              <Checkbox
                id={item.name}
                value={item.value}
                checked={params.getAll('property_type_group').includes(item.value)}
                onChange={onchangeFilters}
              />
            </div>
            <label htmlFor={item.name}>{item.name}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TypeProperty
