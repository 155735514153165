import React, { useState, useEffect } from 'react'
import styles from '../../../css/details/verifs/voyageur.module.css'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { addDays } from 'date-fns'
import Modal from '../../ui/Modal'
import CloseButton from '../../ui/CloseButton'
import useModalByUrl from '../../../hooks/useModalByUrl'
import { DecrementCounter, IncrementCounter } from '../../svgs/TravelerCounter'

const TravelerModal = React.forwardRef(({ hotel }, ref) => {
  const location = useLocation()
  const id = location.pathname.split('/')[2]
  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  const adulte_search = parseInt(Params.get('adults'))
  const enfant_search = parseInt(Params.get('children'))
  const bebe_search = parseInt(Params.get('infants'))
  const animal_search = parseInt(Params.get('pets'))
  const [adulte, setAdulte] = useState(adulte_search || 1)
  const [enfant, setEnfant] = useState(enfant_search || 0)
  const [bebe, setBebe] = useState(bebe_search || 0)
  const [animal, setAnimal] = useState(animal_search || 0)
  const checkin = new Date(Params.get('checkin')) || new Date()
  const checkout = new Date(Params.get('checkout')) || addDays(new Date(), 1)

  const history = useHistory()

  const modalValue = 'TRAVELER'
  const { modalState, handleOpenModal, handleCloseModal } = useModalByUrl(modalValue)

  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      handleOpenModal()
    },
  }))

  useEffect(() => {
    return history.listen(location => {
      const Query = location.search
      const Params = new URLSearchParams(Query)
      const adulte_search = parseInt(Params.get('adults'))
      const enfant_search = parseInt(Params.get('children'))
      const bebe_search = parseInt(Params.get('infants'))
      const animal_search = parseInt(Params.get('pets'))

      setAdulte(adulte_search || 1)
      setEnfant(enfant_search || 0)
      setBebe(bebe_search || 0)
      setAnimal(animal_search || 0)
    })
  }, [])

  const limiteMax = hotel.details?.traveler
  const incrementeAdulte = () => {
    setAdulte(c => c + 1)
  }
  const decrementeAdulte = () => {
    setAdulte(c => c - 1)
  }
  const incrementeEnfant = () => {
    setEnfant(c => c + 1)
  }
  const decrementeEnfant = () => {
    setEnfant(c => c - 1)
  }
  const incrementeBebe = () => {
    setBebe(c => c + 1)
  }
  const decrementeBebe = () => {
    setBebe(c => c - 1)
  }
  const incrementeAnimal = () => {
    setAnimal(c => c + 1)
  }
  const decrementeAnimal = () => {
    setAnimal(c => c - 1)
  }

  const resercher = () => {
    history.replace(
      `/rooms/${id}?children=${enfant}&adults=${adulte}&infants=${bebe}&pets=${animal}&checkin=${moment(
        checkin
      ).format('YYYY-MM-DD')}&checkout=${moment(checkout).format('YYYY-MM-DD')}`
    )
  }

  return (
    <>
      <Modal isOpen={modalState} onClose={handleCloseModal}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={handleCloseModal} />
            <div></div>
          </div>
          <div className={styles.m_body}>
            <p className={styles.notice}>
              La capacité d'accueil de ce logement est limitée à {limiteMax}{' '}
              {limiteMax > 1 ? 'voyageurs' : 'voyageur'}, sans compter les bébés.
            </p>
            <div className={styles.block_flex}>
              <div>
                <h3>Adultes</h3>
              </div>
              <div className={styles.occup_count}>
                <button
                  type='button'
                  disabled={adulte === 1 ? true : false}
                  className={adulte === 1 ? styles.disable : null}
                  onClick={decrementeAdulte}
                >
                  <DecrementCounter />
                </button>
                <span>{adulte}</span>
                <button
                  type='button'
                  disabled={adulte >= limiteMax - enfant}
                  className={adulte >= limiteMax - enfant ? styles.disable : null}
                  onClick={incrementeAdulte}
                >
                  <IncrementCounter />
                </button>
              </div>
            </div>
            <div className={styles.block_flex}>
              <div>
                <h3>Enfants</h3>
                <p>De 2 à 12 ans</p>
              </div>
              <div className={styles.occup_count}>
                <button
                  type='button'
                  disabled={enfant === 0 ? true : false}
                  className={enfant === 0 ? styles.disable : null}
                  onClick={decrementeEnfant}
                >
                  <DecrementCounter />
                </button>
                <span>{enfant}</span>
                <button
                  type='button'
                  disabled={
                    enfant >= limiteMax - adulte ||
                    enfant === 10 ||
                    !hotel.regulations?.accept_children
                  }
                  className={
                    enfant >= limiteMax - adulte ||
                    enfant === 10 ||
                    !hotel.regulations?.accept_children
                      ? styles.disable
                      : null
                  }
                  onClick={incrementeEnfant}
                >
                  <IncrementCounter />
                </button>
              </div>
            </div>
            <div className={styles.block_flex}>
              <div>
                <h3>Bébés</h3>
                <p>- de 2 ans</p>
              </div>
              <div className={styles.occup_count}>
                <button
                  type='button'
                  disabled={bebe === 0 ? true : false}
                  className={bebe === 0 ? styles.disable : null}
                  onClick={decrementeBebe}
                >
                  <DecrementCounter />
                </button>
                <span>{bebe}</span>
                <button
                  type='button'
                  disabled={bebe >= 5 || !hotel.regulations?.accept_babies ? true : false}
                  className={
                    bebe === 5 || !hotel.regulations?.accept_babies ? styles.disable : null
                  }
                  onClick={incrementeBebe}
                >
                  <IncrementCounter />
                </button>
              </div>
            </div>
            <div className={styles.block_flex}>
              <div>
                <h3>Animaux domestiques</h3>
              </div>
              <div className={styles.occup_count}>
                <button
                  type='button'
                  disabled={animal === 0 ? true : false}
                  className={animal === 0 ? styles.disable : null}
                  onClick={decrementeAnimal}
                >
                  <DecrementCounter />
                </button>
                <span>{animal}</span>
                <button
                  type='button'
                  disabled={animal >= 5 || !hotel.regulations?.accept_pets ? true : false}
                  className={
                    animal === 5 || !hotel.regulations?.accept_pets ? styles.disable : null
                  }
                  onClick={incrementeAnimal}
                >
                  <IncrementCounter />
                </button>
              </div>
            </div>
          </div>
          <div className={styles.btn_action}>
            <button onClick={handleCloseModal}>Fermer</button>
            <button onClick={resercher}>Appliquer</button>
          </div>
        </div>
      </Modal>
    </>
  )
})
export default TravelerModal
