import { createContext, useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const SearchParamContext = createContext()

export const ParamProvider = ({ children }) => {
  const [params, setParams] = useState(new URLSearchParams(window.location.search))
  const history = useHistory()

  useEffect(() => {
    return history.listen(location => {
      const Query = location.search
      const params = new URLSearchParams(Query)
      setParams(params)
    })
  }, [])

  const updateParams = newParams => {
    history.push(`/homes/search?${newParams}`)
  }

  const removeParams = newParams => {
    history.push(`/homes/search?${newParams}`)
  }

  return (
    <SearchParamContext.Provider value={{ params, updateParams, removeParams }}>
      {children}
    </SearchParamContext.Provider>
  )
}

export const useSearchParams = () => useContext(SearchParamContext)
