import styles from '../../../../../css/modehotes/annonce/details/equipement.module.css'
import useUpdateHotel from '../../../../../hooks/useUpdateHotel'
import Checkbox from '../../../../ui/Checkbox'

const Parking = ({ equipements, setEquipements }) => {
  const { updateData } = useUpdateHotel()

  const array = [
    { name: 'Ascenseur', value: '110' },
    { name: 'Station de recharge pour véhicules électriques', value: '111' },
    { name: 'Parking gratuit sur place', value: '2' },
    { name: 'Parking payant sur place', value: '112' },
    { name: 'Parking gratuit dans la rue', value: '50' },
    { name: 'Parking payant dans la rue', value: '114' },
    { name: 'Salle de sport', value: '15' },
    { name: 'Jacuzzi', value: '14' },
    { name: "Stationnement payant à l'extérieur de la propriété", value: '113' },
    { name: 'Piscine', value: '3' },
    { name: 'Salon privé', value: '115' },
    { name: 'Sauna', value: '116' },
    { name: 'Logement de plain-pied', value: '117' },
  ]
  const onchangeFilters = e => {
    if (e.target.checked === true) {
      setEquipements([...equipements, e.target.value])
      const datas = {
        amenities: [...equipements, e.target.value],
      }
      updateData(datas)
    } else {
      const newArr = [...equipements].filter(item => item !== e.target.value)
      setEquipements(newArr)
      const datas = {
        amenities: newArr,
      }
      updateData(datas)
    }
  }
  return (
    <div className={styles.container_equip}>
      <h4>Parking et installations</h4>
      {array.map(item => (
        <div key={item.name} className={styles.checkbox_container}>
          <label htmlFor={item.name}>{item.name}</label>
          <div>
            <Checkbox
              id={item.name}
              value={item.value}
              checked={equipements.includes(item.value)}
              onChange={onchangeFilters}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default Parking
