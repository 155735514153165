import { Helmet } from 'react-helmet-async'
import styles from '../../css/about_us/about.module.css'
import DefaultLayout from '../../layouts/DefaultLayout'

function PolicyPaymentForHost() {
  return (
    <DefaultLayout footer={true}>
      <Helmet>
        <title>Politique de paiement sur place pour les hôtes</title>
      </Helmet>

      <div className={styles.main}>
        <div className={styles.pourquoi_text}>
          <h2>Politique de paiement sur place pour les hôtes</h2>
          <h3>Introduction</h3>
          <p>
            Nous offrons une flexibilité maximale pour satisfaire les besoins variés de nos
            utilisateurs. Nous comprenons que certains voyageurs préfèrent ne pas effectuer de
            paiements en ligne pour diverses raisons. Pour aider les hôtes à attirer un plus large
            éventail de clients potentiels, nous proposons l'option de paiement sur place. Cette
            politique explique pourquoi cette option est bénéfique et les procédures associées.
          </p>

          <div>
            <h3>Pourquoi proposer le paiement sur place ?</h3>
            <ol>
              <li>
                <strong>Accès limité aux services bancaires :</strong> Dans de nombreux pays, en
                particulier en Afrique, Asie, Amérique du sud où le taux de bancarisation est
                faible, de nombreux voyageurs n'ont pas accès aux cartes de crédit ou de débit. Ils
                préfèrent utiliser des modes de paiement locaux comme les paiements en espèces ou
                via des services de paiement mobile.
              </li>
              <li>
                <strong>Stratégie locale :</strong> Cette approche innovante vise à promouvoir le
                tourisme local en facilitant les transactions et en intégrant les spécificités
                culturelles et économiques de chaque pays. En permettant des paiements adaptés aux
                habitudes locales, Gowifmi encourage une expérience plus authentique et accessible,
                tout en soutenant les économies locales et en renforçant les liens entre les
                voyageurs et les communautés d'accueil.
              </li>
            </ol>
          </div>

          <div>
            <h2>Conditions de paiement sur place</h2>
            <h3>Acompte</h3>
            <p>
              <strong>Demande d'acompte :</strong> Vous pouvez choisir de demander un acompte pour
              bloquer les dates demandées. Cette pratique est optionnelle mais peut aider à
              sécuriser la réservation.
            </p>
            <p>
              <strong>Montant de l'acompte :</strong> L'acompte est à votre discrétion et peut
              varier selon vos conditions spécifiques.
            </p>
            <p>
              <strong>Mode de paiement de l'acompte :</strong> Les acomptes peuvent être payés via
              virement bancaire, carte de crédit, ou tout autre moyen de paiement sécurisé convenu
              avec le voyageur.
            </p>
          </div>

          <div>
            <h3>Paiement à l'arrivée</h3>
            <p>
              <strong>Paiement du solde :</strong> Le solde restant de la réservation doit être payé
              directement par le voyageur à son arrivée. Il est important de préciser à l'avance les
              modes de paiement acceptés (espèces, cartes, mobile money etc.).
            </p>
            <p>
              <strong>Réception du paiement :</strong> Fournissez une preuve de paiement et remettez
              les clés du logement une fois le paiement reçu.
            </p>
          </div>

          <div>
            <h3>Interactions via la plateforme Gowifmi</h3>
            <p>
              <strong>Sécurité et traçabilité :</strong> Pour garantir la sécurité des informations
              et la traçabilité des interactions, toutes les communications doivent se faire via la
              messagerie de Gowifmi.
            </p>
            <p>
              <strong>Non-partage d'informations personnelles :</strong> Ne demandez pas aux
              voyageurs de partager leurs informations personnelles comme les informations bancaires
              ou autres informations sensibles.
            </p>
            <p>
              <strong>Gestion des litiges :</strong> En cas de litige, les historiques de
              conversation sur Gowifmi permettront de résoudre les problèmes efficacement. Gowifmi
              ne surveille pas les messages, mais peut vérifier les échanges en cas de besoin.
            </p>
            <p>
              <strong>Appels téléphoniques en cas d'urgence :</strong> Les appels téléphoniques
              peuvent être autorisés en cas d'urgence, par exemple si l'hôte n'est pas en ligne sur
              Gowifmi et que le voyageur doit se rendre au logement.
            </p>
          </div>

          <div>
            <h3>Exemple de cas</h3>
            <p>
              Madame Martin, un hôte qui donne aux voyageurs la possibilité de réserver son logement
              en payant en ligne sur Gowifmi ou chez elle directement, reçoit une réservation de
              Monsieur Leclerc pour un séjour de 4 jours dans son appartement via Gowifmi. Monsieur
              Leclerc choisit l'option de paiement sur place.
            </p>
            <ol>
              <li>
                <strong>Acompte :</strong> Madame Martin décide de ne pas demander d'acompte pour
                cette réservation.
              </li>
              <li>
                <strong>Confirmation de réservation :</strong> Gowifmi envoie une confirmation de
                réservation à Monsieur Leclerc avec tous les détails du séjour.
              </li>
              <li>
                <strong>Paiement à l'Arrivée :</strong> À l'arrivée, Monsieur Leclerc paie le
                montant total de 400$ en espèces. Madame Martin lui remet une preuve de paiement et
                les clés de l'appartement.
              </li>
              <li>
                <strong>Annulation de dernière minute :</strong> Si Monsieur Leclerc avait annulé sa
                réservation 24 heures avant l'arrivée, il n'aurait eu aucune pénalité car aucun
                acompte n'avait été versé.
              </li>
              <li>
                <strong>Non-présentation :</strong> Si Monsieur Leclerc ne s'était pas présenté sans
                avertir Madame Martin, elle aurait pu demander le paiement complet de 400$ selon les
                termes convenus.
              </li>
            </ol>
          </div>

          <div>
            <h3>Conclusion</h3>
            <p>
              La politique de paiement sur place permet aux hôtes d'attirer un plus large éventail
              de clients, y compris ceux qui préfèrent ou qui ont des difficultés de payer en ligne.
            </p>
            <p>
              En assurant que toutes les interactions se déroulent sur la plateforme Gowifmi, nous
              garantissons la sécurité et la traçabilité nécessaires pour résoudre les litiges. Pour
              toute question ou assistance supplémentaire, n'hésitez pas à contacter Gowifmi.
            </p>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}
export default PolicyPaymentForHost
