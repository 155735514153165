import { useEffect, useContext } from 'react'
import styles from '../../../css/account/profile.module.css'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router'
import { User } from '../../../context/UserContext'
import { useQuery } from 'react-query'
import axios from 'axios'
import LoadingPoint from '../../../components/LoadingPoint'
import Annonces from '../../../components/accounts/profile/Annonces'
import DefaultLayout from '../../../layouts/DefaultLayout'
import SignalerUser from '../../../components/accounts/profile/SignalerUser'
import ProfileDescription from '../../../components/accounts/profile/ProfileDescription'
import ErrorServer from '../../errors/ErrorServer'
import CoverImage from '../../../components/accounts/profile/CoverImage'
import Navigation from '../../../components/accounts/profile/Navigation'

const Profile = () => {
  const userConnected = useContext(User)
  const location = useLocation()
  const path = location.pathname.split('/')[2]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const {
    data: getUser = {},
    isLoading,
    error,
    refetch,
  } = useQuery(`get-user-profil-${path}`, () =>
    axios.get(`/user/${path}`).then(res => {
      return res.data
    })
  )

  if (error || !path) {
    return <ErrorServer />
  }

  return (
    <DefaultLayout footer={true} mobileBottomNavigate={true}>
      <Helmet>
        <title>
          {path === userConnected?.id
            ? (userConnected?.first_name ?? '')
            : (getUser?.first_name ?? '')}{' '}
          : profil Gowifmi
        </title>
      </Helmet>
      {isLoading ? (
        <div className={styles.container_load}>
          <LoadingPoint />
        </div>
      ) : (
        <>
          <div className={styles.profil_container}>
            <div className={styles.profil_box}>
              <CoverImage userConnected={userConnected} getUser={getUser} />
              <ProfileDescription
                userConnected={userConnected}
                getUser={getUser}
                refetch={refetch}
              />
              <div className={styles.profil_infos}>
                <Annonces getUser={getUser} />
                <Navigation userConnected={userConnected} getUser={getUser} />
              </div>
            </div>
            {path !== userConnected?.id && <SignalerUser userSignal={getUser?.id} />}
          </div>
        </>
      )}
    </DefaultLayout>
  )
}

export default Profile
