import styles from '../../../css/navigations/sideBar.module.css'
import { Link } from 'react-router-dom'
import Tooltip from './Tooltip'

const Search = ({ path }) => {
  return (
    <>
      <Link
        to='/'
        className={path === 'homes' || path === '' || path === 'rooms' ? styles.active : null}
      >
        <div className={styles.icon_content}>
          <svg
            className={styles.search}
            viewBox='0 0 32 32'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            role='presentation'
            focusable='false'
          >
            <g fill='none'>
              <path d='m13 24c6.0751322 0 11-4.9248678 11-11 0-6.07513225-4.9248678-11-11-11-6.07513225 0-11 4.92486775-11 11 0 6.0751322 4.92486775 11 11 11zm8-3 9 9'></path>
            </g>
          </svg>
        </div>
        <p>Rechercher</p>
        <Tooltip title='Rechercher' />
      </Link>
    </>
  )
}

export default Search
