import { useState } from 'react'
import globalStyle from '../../../css/becomeHostes/globalStyle.module.css'
import styles from '../../../css/becomeHostes/equipement.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import axios from 'axios'
import LoadingPoint from '../../../components/LoadingPoint'
import ErrorServer from '../errorServer/ErrorServer'
import useAddHome from '../../../hooks/useAddHome'
import { SATATUS_ANNONCE } from '../../../enums/logement/status.enums'
import HeaderForm from '../globals/HeaderForm'
import { useQuery } from 'react-query'

const Form = () => {
  const [loading, setLoading] = useState(false)
  const [equipement, setEquipement] = useState([])
  const [serverError, setServerError] = useState(false)
  const { updateData } = useAddHome(setLoading, setServerError)

  const history = useHistory()
  const location = useLocation()
  const page = location.pathname.split('/')[2]
  const id = location.pathname.split('/')[3]

  const { isLoading, error } = useQuery(`get-hotel-on-creation-${id}}`, () =>
    axios.get(`/hotel/oneAnnonce/${id}`).then(res => {
      setEquipement(res.data.amenities)
      if (res.data.status !== SATATUS_ANNONCE.IN_PROGRESS) {
        history.push('/hosting')
      }
    })
  )

  const handleSubmit = () => {
    setLoading(true)
    const datas = {
      redirect_url: page,
      amenities: equipement,
    }
    updateData(datas, '/become-a-host/photos')
  }

  const array = [
    { name: 'Piscine', value: '3' },
    { name: 'Jacuzzi', value: '14' },
    { name: 'Patio', value: '62' },
    { name: 'Barbecue', value: '48' },
    { name: 'Brasero', value: '63' },
    { name: 'Billard', value: '64' },
    { name: 'Cheminée', value: '16' },
    { name: 'Appareils de fitness', value: '65' },
    { name: 'Espace repas en plein air', value: '47' },

    { name: 'Wifi', value: '1' },
    { name: 'Télévision', value: '10' },
    { name: 'Cuisine', value: '4' },
    { name: 'Lave-linge', value: '6' },
    { name: 'Parking gratuit sur place', value: '2' },
    { name: 'Parking payant sur place', value: '112' },
    { name: 'Climatisation', value: '8' },
    { name: 'Espace de travail dédié', value: '13' },
    { name: 'Douche extérieure', value: '58' },

    { name: 'Détecteur de fumée', value: '19' },
    { name: 'Kit de premiers secours', value: '61' },
    { name: 'Détecteur de monoxyde de carbone', value: '18' },
    { name: 'Porte de la chambre avec verrou', value: '60' },
    { name: 'Extincteur', value: '59' },
  ]

  const handleChange = e => {
    if (!e.target.checked) {
      const newArray = equipement.filter(item => item !== e.target.value)
      setEquipement(newArray)
    } else {
      setEquipement([...equipement, e.target.value])
    }
  }

  if (error || serverError) {
    return <ErrorServer />
  }

  return (
    <div className={globalStyle.left}>
      <HeaderForm step={6} />
      <div className={styles.block_center}>
        <div className={styles.block_form}>
          {isLoading ? (
            <LoadingPoint />
          ) : (
            <div className={globalStyle.annimcontent}>
              <div className={styles.block_checkbox}>
                {array.map((item, i) => (
                  <label key={i}>
                    <input
                      type='checkbox'
                      value={item.value}
                      checked={equipement.includes(item.value)}
                      onChange={handleChange}
                    />
                    <div className={styles.block}>
                      <h2>{item.name}</h2>
                    </div>
                  </label>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={globalStyle.step_buttons}>
        <button
          type='button'
          onClick={() => history.push(`/become-a-host/traveler-infos/${id}`)}
          className={globalStyle.retour}
        >
          Retour
        </button>
        {loading ? (
          <button disabled className={globalStyle.loading}>
            <LoadingPoint />
          </button>
        ) : (
          <button type='button' onClick={handleSubmit} className={globalStyle.next}>
            Suivant
          </button>
        )}
      </div>
    </div>
  )
}

export default Form
