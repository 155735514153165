import styles from '../../css/ui/radion_input.module.css'

const RadioInput = ({ id, value, disabled, checked, onChange }) => {
  return (
    <div className={styles.container}>
      <input
        id={id}
        type='radio'
        value={value}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
    </div>
  )
}

export default RadioInput
