import styles from '../../css/ui/check_box.module.css'

const Checkbox = ({ id, value, checked, onChange }) => {
  return (
    <div className={styles.container}>
      <input id={id} type='checkbox' value={value} checked={checked} onChange={onChange} />
    </div>
  )
}

export default Checkbox
