import { useRef } from 'react'
import styles from '../../../../css/search_room_result/card.module.css'
import FavoriteResult from './FavoriteResult'
import { useSelector } from 'react-redux'

const FavoriteCard = ({ item }) => {
  const favoris = useSelector(state => state.getFavorisReducer)
  const filteredArray = favoris
    ?.filter(el => el.lists.some(lists => lists.hotel?._id === item._id))
    .map(el => {
      let n = Object.assign({}, el, {
        lists: el.lists.filter(lists => lists.hotel?._id === item._id),
      })
      return n
    })
  const favoritreRefRef = useRef(null)
  const handleAdd = () => {
    favoritreRefRef.current.openModal()
  }
  const handleRemove = () => {
    favoritreRefRef.current.removeFavorite(filteredArray[0]._id)
  }

  return (
    <>
      {filteredArray.length > 0 ? (
        <button type='button' className={styles.use_fav_button} onClick={handleRemove}>
          <svg
            viewBox='0 0 32 32'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            role='presentation'
            focusable='false'
            style={{
              display: 'block',
              height: '24px',
              width: '24px',
              strokeWidth: '2',
              overflow: 'visible',
              fill: '#ff385c',
              stroke: '#fff',
            }}
          >
            <path d='m16 28c7-4.733 14-10 14-17 0-1.792-.683-3.583-2.05-4.95-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05l-2.051 2.051-2.05-2.051c-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05-1.367 1.367-2.051 3.158-2.051 4.95 0 7 7 12.267 14 17z'></path>
          </svg>
        </button>
      ) : (
        <button type='button' className={styles.use_fav_button} onClick={handleAdd}>
          <svg
            viewBox='0 0 32 32'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            role='presentation'
            focusable='false'
            style={{
              display: 'block',
              height: '24px',
              width: '24px',
              strokeWidth: '2',
              overflow: 'visible',
              fill: ' rgba(0, 0, 0, 0.5)',
              stroke: '#fff',
            }}
          >
            <path d='m16 28c7-4.733 14-10 14-17 0-1.792-.683-3.583-2.05-4.95-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05l-2.051 2.051-2.05-2.051c-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05-1.367 1.367-2.051 3.158-2.051 4.95 0 7 7 12.267 14 17z'></path>
          </svg>
        </button>
      )}
      <FavoriteResult ref={favoritreRefRef} item={item} />
    </>
  )
}

export default FavoriteCard
