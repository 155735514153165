import { useState, useContext } from 'react'
import styles from '../../../css/logins/content.module.css'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Refresh } from '../../../context/UserContext'
import LoadingPoint from '../../../components/LoadingPoint'
import AuthReseaux from '../reseaux/AuthReseaux'
import ErrorSvg from '../../svgs/ErrorSvg'
import { CONNECTION_TYPE } from '../../../enums/user/connectionScreen'

const Connexion = ({ changeState, setGetUser }) => {
  const [view, setView] = useState(false)
  const [loading, setLoading] = useState(false)
  const [echecConnexion, setEchecConnexion] = useState(false)
  const refresh = useContext(Refresh)

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onChange' })

  const onSubmit = data => {
    setLoading(true)
    setEchecConnexion(false)

    const datas = {
      ...data,
    }
    axios
      .post(`/auth/login-site`, datas)
      .then(async res => {
        localStorage.setItem('jwt', res.data.jwt.token)
        axios.defaults.headers['authorization'] = 'Bearer ' + res.data.jwt.token
        await refresh()
        setEchecConnexion(false)
        window.location.reload()
      })
      .catch(() => {
        setLoading(false)
        setEchecConnexion(true)
      })
  }

  return (
    <div>
      <div className={styles.head}>
        <h4> Bienvenue sur Gowifmi</h4>
      </div>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.connexion_form}>
            {echecConnexion && (
              <div className={styles.connexion_echec}>
                <div>
                  <ErrorSvg width={24} height={24} />
                </div>
                <div className={styles.notif}>
                  <h1>L’e-mail ou le mot de passe est incorrect</h1>
                  <p>Veuillez réessayer</p>
                </div>
              </div>
            )}
            <div className={errors.email && styles.erreur}>
              <label htmlFor='email'>E-mail</label>
              <input
                type='email'
                id='email'
                placeholder='ex: john@gmail.com'
                {...register('email', { required: true })}
              />
              {errors.email && <span className={styles.erreur}>Email est obligatoire</span>}
            </div>
            <div
              className={errors.password ? `${styles.password} ${styles.erreur}` : styles.password}
            >
              <label htmlFor='password'>Mot de Passe</label>
              <input
                type={view ? 'text' : 'password'}
                id='password'
                {...register('password', { required: true })}
              />
              {errors.password && (
                <span className={styles.erreur}>Mot de passe est obligatoire</span>
              )}
              {view ? (
                <ion-icon name='eye-off-outline' onClick={() => setView(false)}></ion-icon>
              ) : (
                <ion-icon name='eye-outline' onClick={() => setView(true)}></ion-icon>
              )}
            </div>
          </div>
          <span
            className={styles.forgot}
            onClick={() => changeState(CONNECTION_TYPE.FORGOT_PASSWORD)}
          >
            Mot de passe oublié ?
          </span>
          <div className={styles.btn_submit}>
            {loading ? (
              <button type='button' className={styles.disable} disabled>
                <LoadingPoint />
              </button>
            ) : (
              <button type='submit'>Se connecter</button>
            )}
            <p className={styles.creer}>
              Pas encore de compte ?{' '}
              <span onClick={() => changeState(CONNECTION_TYPE.REGISTRATION)}>Créer un compte</span>
            </p>
          </div>
        </form>
        <AuthReseaux changeState={changeState} setGetUser={setGetUser} />
      </div>
    </div>
  )
}

export default Connexion
