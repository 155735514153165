import React, { useState } from 'react'
import styles from '../../css/search_room_result/filtre_2.module.css'
import { useSelector } from 'react-redux'
import TypeProperty from './components/filtres/TypeProperty'
import Room from './components/filtres/Room'
import FilterPrice from './components/filtres/FilterPrice'
import Amenitie from './components/filtres/Amenitie'
import OptionReservation from './components/filtres/OptionReservation'
import HotelLanguage from './components/filtres/HotelLanguage'
import TypeAccomodation from './components/filtres/TypeAccomodation'
import ModalScrollable from '../ui/ModalScrollable'
import CloseButton from '../ui/CloseButton'
import { useSearchParams } from '../../context/SearchParamContext'

const FilterModal = React.forwardRef(({}, ref) => {
  const [modal, setModal] = useState(false)

  const { params, removeParams } = useSearchParams()

  const minPrice = 9
  const maxPrice = 1000
  const min = parseInt(params.get('min_price')) || minPrice
  const max = parseInt(params.get('max_price')) || maxPrice

  const [priceValue, setPriceValue] = useState([min, max])
  const gethotelsLength = useSelector(state => state.totalHotelreducer)
  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      setModal(true)
    },
  }))

  const ville = params.get('place')
  const checkin = params.get('checkin')
  const checkout = params.get('checkout')
  const adulte = params.get('adults')
  const enfant = params.get('children')
  const bebe = params.get('infants')

  const deleteAllFilters = () => {
    setPriceValue([minPrice, maxPrice])
    removeParams(
      `&place=${ville}&children=${enfant}&adults=${adulte}&infants=${bebe}&checkin=${checkin}&checkout=${checkout}`
    )
  }
  return (
    <>
      <ModalScrollable isOpen={modal} onClose={() => setModal(false)}>
        <div className={modal ? `${styles.modal_content} ${styles.active}` : styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={() => setModal(false)} />
            <h3>Filtres</h3>
            <div></div>
          </div>
          <div className={styles.m_body}>
            {/*--------filter by price--------*/}
            <FilterPrice
              priceValue={priceValue}
              setPriceValue={setPriceValue}
              minPrice={minPrice}
              maxPrice={maxPrice}
            />
            {/*--------filter by Type de logement--------*/}
            <TypeAccomodation />
            {/*--------filter by Chambres----lits et salles de bains------*/}
            <Room />
            {/*--------filter by TypeProperty--------*/}
            <TypeProperty />
            {/*--------filter by Amenitie--------*/}
            <Amenitie />
            {/*--------filter OptionReservation--------*/}
            <OptionReservation />
            {/*--------filter by HotelLanguage--------*/}
            <HotelLanguage />
          </div>
          <div className={styles.m_footer}>
            <button type='button' className={styles.effacher} onClick={deleteAllFilters}>
              Tout effacer
            </button>
            <button type='button' className={styles.appliquer} onClick={() => setModal(false)}>
              {gethotelsLength > 0
                ? `Afficher ${gethotelsLength} résultat${gethotelsLength > 1 ? 's' : ''}`
                : 'Aucun hébergement disponible'}
            </button>
          </div>
        </div>
      </ModalScrollable>
    </>
  )
})

export default FilterModal
