import styles from '../../../css/details/equipement.module.css'
import CheckAmenitieSvg from './components/CheckAmenitieSvg'

const PanoramicView = ({ hotel }) => {
  const { details } = hotel
  return (
    <div className={styles.container_equip}>
      <h4>Vues panoramiques</h4>
      {details?.panoramic_views.includes('1') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Vue sur la baie</p>
        </div>
      )}
      {details?.panoramic_views.includes('2') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Vue sur la plage</p>
        </div>
      )}
      {details?.panoramic_views.includes('3') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Vue sur le canal</p>
        </div>
      )}
      {details?.panoramic_views.includes('4') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Vue panoramique sur la ville</p>
        </div>
      )}
      {details?.panoramic_views.includes('5') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Vue sur la cour</p>
        </div>
      )}
      {details?.panoramic_views.includes('6') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Vue sur le désert</p>
        </div>
      )}
      {details?.panoramic_views.includes('7') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Vue sur le jardin</p>
        </div>
      )}
      {details?.panoramic_views.includes('8') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Vue sur un parcours de golf</p>
        </div>
      )}
      {details?.panoramic_views.includes('9') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Vue sur le port</p>
        </div>
      )}
      {details?.panoramic_views.includes('10') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Vue sur le lac</p>
        </div>
      )}
      {details?.panoramic_views.includes('11') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Vue sur le port de plaisance</p>
        </div>
      )}
      {details?.panoramic_views.includes('12') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Vue sur la montagne</p>
        </div>
      )}
      {details?.panoramic_views.includes('13') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Vue sur l'océan</p>
        </div>
      )}
      {details?.panoramic_views.includes('14') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Vue sur le parc</p>
        </div>
      )}
      {details?.panoramic_views.includes('15') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Vue sur la piscine</p>
        </div>
      )}
      {details?.panoramic_views.includes('16') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Vue sur le complexe hôtelier</p>
        </div>
      )}
      {details?.panoramic_views.includes('17') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Vue sur la rivière</p>
        </div>
      )}
      {details?.panoramic_views.includes('18') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Vue sur la mer</p>
        </div>
      )}
      {details?.panoramic_views.includes('19') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Vue sur la vallée</p>
        </div>
      )}
      {details?.panoramic_views.includes('20') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Vue sur le vignoble</p>
        </div>
      )}
    </div>
  )
}

export default PanoramicView
