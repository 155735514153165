import { useEffect, useContext } from 'react'
import globalStyle from '../../css/becomeHostes/globalStyle.module.css'
import { User } from '../../context/UserContext'
import { useHistory } from 'react-router-dom'
import Form from '../../components/become_host/photo/Form'
import DescriptionText from '../../components/become_host/globals/DescriptionText'
import { Helmet } from 'react-helmet-async'

const Photo = () => {
  const user = useContext(User)
  const history = useHistory()
  useEffect(() => {
    if (user === null) {
      history.push('/host/homes')
    }
  }, [history, user])
  return (
    <>
      <Helmet>
        <title>Télécharger les photos de votre logement - Gowifmi</title>
      </Helmet>
      <div className={globalStyle.container}>
        <Form />
        <DescriptionText
          title="Montrez-leur ce qu'ils ne devraient pas rater"
          desc='Vous pouvez à tout moment modifier vos photos et les réorganiser par glisser-déposer'
        />
      </div>
    </>
  )
}

export default Photo
