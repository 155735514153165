import { useRef, useContext } from 'react'
import styles from '../../../css/affiliations/home/header.module.css'
import salon from '../../../assets/become_host/salon.jpg'
import { Link } from 'react-router-dom'
import { User } from '../../../context/UserContext'
import Login from '../../../components/login'
import ShareLink from '../../../components/share_links/ShareLink'

const Header = ({}) => {
  const user = useContext(User)

  const hostname = window.location.hostname
  const userLink = `https://${hostname}/host/homes/${user?.id}`

  const connexionRef = useRef()
  const handleConnection = params => {
    connexionRef.current.OpenModal(params)
  }

  const sharRef = useRef()
  const handleSharRef = () => {
    sharRef.current.openModal()
  }

  const url = userLink
  const title = 'Partager votre lien de parrainage'
  const quote = 'Découvrer Gowifmi pour la location de votre logement'

  return (
    <>
      <div className={styles.container}>
        <div className={styles.hero_section}>
          <div>
            <div className={styles.title_section}>
              <h1>Gagnez 35% de notre commission sur chaque nouvel hôte que vous parrainez</h1>
              <p>
                Invitez une personne à proposer son logement et gagnez de l'argent lorsqu'elle aura
                publié son annonce et honoré son premier séjour.
              </p>
              <Link to='/affiliation-policy'>
                Consulter les conditions et les critères du programme
              </Link>
            </div>
            <div className={styles.action_block}>
              {user ? (
                <div className={styles.action_block_content}>
                  <p className={styles.action_block_link}>{userLink}</p>
                  <button type='button' onClick={handleSharRef}>
                    Partager le lien
                  </button>
                </div>
              ) : (
                <button type='button' className={styles.connexion_btn} onClick={handleConnection}>
                  Connectez-vous
                </button>
              )}
            </div>
          </div>

          <div className={styles.img_block}>
            <img src={salon} alt='' />
          </div>
        </div>
      </div>
      <ShareLink ref={sharRef} url={url} title={title} quote={quote} />
      <Login ref={connexionRef} />
    </>
  )
}

export default Header
