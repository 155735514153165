import globalStyle from '../../../css/becomeHostes/globalStyle.module.css'
import styles from '../../../css/becomeHostes/intro.module.css'
import { Link } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import LoadingPoint from '../../LoadingPoint'
import ErrorServer from '../errorServer/ErrorServer'
import { SATATUS_ANNONCE } from '../../../enums/logement/status.enums'
import HeaderForm from '../globals/HeaderForm'
import { useQuery } from 'react-query'

const Form = () => {
  const {
    data = [],
    isLoading,
    error,
  } = useQuery(`hosting-annonce}`, () =>
    axios.get(`/hotel/today`).then(res => {
      return res.data
    })
  )

  if (error) {
    return <ErrorServer />
  }

  return (
    <div className={globalStyle.left}>
      <HeaderForm />
      <div className={styles.block_center}>
        <div className={styles.block_form}>
          {isLoading ? (
            <LoadingPoint />
          ) : (
            <div className={globalStyle.annimcontent}>
              {data.length > 0 && (
                <div>
                  <h1>{data.length > 1 ? 'Terminez vos annonces' : 'Terminez votre annonce'}</h1>
                  {data.map(item => (
                    <Link
                      to={
                        item.status === SATATUS_ANNONCE.SAVE
                          ? `/verify-listing/${item._id}`
                          : `/become-a-host/${item.redirect_url}/${item._id}`
                      }
                      key={item._id}
                      className={styles.annonce}
                    >
                      <div>
                        <div className={styles.card}>
                          {item.images[0]?.url ? (
                            <img src={item.images[0]?.url} alt='' />
                          ) : (
                            <svg
                              viewBox='0 0 16 16'
                              xmlns='http://www.w3.org/2000/svg'
                              aria-hidden='true'
                              role='presentation'
                              focusable='false'
                            >
                              <path d='M8.602 1.147l.093.08 7.153 6.914-.696.718L14 7.745V14.5a.5.5 0 0 1-.41.492L13.5 15H10V9.5a.5.5 0 0 0-.41-.492L9.5 9h-3a.5.5 0 0 0-.492.41L6 9.5V15H2.5a.5.5 0 0 1-.492-.41L2 14.5V7.745L.847 8.86l-.696-.718 7.153-6.915a1 1 0 0 1 1.297-.08z'></path>
                            </svg>
                          )}
                        </div>
                      </div>
                      <div>
                        <p>
                          {item.status === SATATUS_ANNONCE.SAVE ? 'Publiez' : 'Terminez'} votre
                          annonce {item.title && `(${item.title})`} qui a été créée le{' '}
                          {moment(item.createdAt).format('LL')}
                        </p>
                      </div>
                    </Link>
                  ))}
                </div>
              )}

              <div className={styles.create}>
                <h2>Créez une nouvelle annonce</h2>
                <Link to={`/become-a-host/type-property-group`}>
                  <div>
                    <svg
                      viewBox='0 0 32 32'
                      xmlns='http://www.w3.org/2000/svg'
                      aria-hidden='true'
                      role='presentation'
                      focusable='false'
                    >
                      <path d='m2 16h28m-14-14v28'></path>
                    </svg>
                  </div>
                  <div>
                    <p>Créez une nouvelle annonce</p>
                  </div>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Form
