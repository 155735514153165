import styles from '../../../../../../css/modehotes/reservation/detail.module.css'
import { addCurrencyComplet } from '../../../../../../utils/addCurrency'
import FreeModeReservation from '../../../../default_components/freemode/FreeModeReservation'
import { STATUS_BOOKING } from '../../../../../../enums/booking/status.enums'

const DetailPrice = ({ reservation, calculatedPrices }) => {
  const {
    totalStayBeforRefund,
    totalRefund,
    totalGowifmiServiceFeeAfterRefund,
    revenusAferRefundAdnDeductionHostFee,
    totalGowifmiServiceFee,
    revenusAferDeductionHostFee,
  } = calculatedPrices

  return (
    <div className={styles.content}>
      <h3>Détails du prix</h3>
      <p>
        Total du séjour : {addCurrencyComplet(totalStayBeforRefund, reservation?.prices?.currency)}
      </p>
      {reservation.status === STATUS_BOOKING.CANCELLED ? (
        <>
          <p>
            Total remboursé au voyageur :{' '}
            {addCurrencyComplet(totalRefund, reservation.prices?.currency)}
          </p>
          <p>
            Total des frais de service Gowifmi :{' '}
            {addCurrencyComplet(totalGowifmiServiceFeeAfterRefund, reservation.prices?.currency)}
          </p>
          <p>
            Vos revenus :{' '}
            {addCurrencyComplet(revenusAferRefundAdnDeductionHostFee, reservation.prices?.currency)}
          </p>
        </>
      ) : (
        <>
          <p>
            Total frais de service Gowifmi :{' '}
            {addCurrencyComplet(totalGowifmiServiceFee, reservation.prices?.currency)}
          </p>
          <p>
            Vos revenus :{' '}
            {addCurrencyComplet(revenusAferDeductionHostFee, reservation.prices?.currency)}
          </p>
        </>
      )}
      {reservation?.free_mode && <FreeModeReservation />}
    </div>
  )
}

export default DetailPrice
