import { useState, useContext } from 'react'
import styleGlobal from '../../../../css/modehotes/annonce/details/detail.module.css'
import styles from '../../../../css/modehotes/annonce/details/photo.module.css'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { getAnnonce } from '../../../../redux/actions'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { User } from '../../../../context/UserContext'
import LoadingPoint from '../../../../components/LoadingPoint'
import ListAnnonce from '../../../../components/hote_mode/annonce/details/defaults/ListAnnonce'
import HeadDetail from '../../../../components/hote_mode/annonce/details/defaults/HeadDetail'
import HeadPage from '../../../../components/hote_mode/annonce/details/defaults/HeadPage'
import Upload from '../../../../components/hote_mode/annonce/details/photos/Upload'
import ErrorServer from '../../../errors/ErrorServer'
import HostModeLayout from '../../../../layouts/HostModeLayout'

const DetailAnnonce = () => {
  const [images, setImages] = useState([])
  const user = useContext(User)
  const history = useHistory()
  const location = useLocation()
  const path = location.pathname.split('/')[4]
  const dispatch = useDispatch()
  const annonce = useSelector(state => state.updateAnnonce)

  const { isLoading, error, refetch } = useQuery(`hote-get-annonce-details-${path}`, () =>
    axios.get(`/hotel/oneAnnonce/${path}`).then(res => {
      dispatch(getAnnonce(res.data))
      setImages(res.data.images)
    })
  )
  if (error) {
    return <ErrorServer />
  }
  if (user === null) {
    history.push('/')
  }
  return (
    <HostModeLayout>
      <Helmet>
        <title>Modifier l'annonce : {annonce?.title ?? ''} - Gowifmi</title>
      </Helmet>
      <div className={styleGlobal.voyage_container}>
        <ListAnnonce />
        <div className={styleGlobal.block_details}>
          <HeadDetail annonce={annonce} />
          {isLoading ? (
            <div className={styleGlobal.loading}>
              <LoadingPoint />
            </div>
          ) : (
            <div>
              <div className={styles.page}>
                <HeadPage annonce={annonce} />
                <Upload path={path} images={images} setImages={setImages} refetch={refetch} />
              </div>
            </div>
          )}
        </div>
      </div>
    </HostModeLayout>
  )
}

export default DetailAnnonce
