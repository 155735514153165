import React, { useState } from 'react'
import styles from '../../../../../../css/booking/politiqueHote.module.css'
import Modal from '../../../../../ui/Modal'
import CloseButton from '../../../../../ui/CloseButton'

const PolicyTraveler = React.forwardRef((props, ref) => {
  const [modal, setModal] = useState(false)

  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      setModal(true)
    },
  }))

  return (
    <>
      <Modal isOpen={modal} onClose={() => setModal(false)}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={() => setModal(false)} />
            <div></div>
          </div>
          <div className={styles.m_body}>
            <div className={styles.asavoir}>
              <h2>Important : payez chez l'hôte en toute conscience</h2>
              <p>
                Avant de finaliser votre réservation en payant chez l'hôte, veuillez prendre en
                considération quelques points essentiels.
              </p>
              <p>
                Notez que les annulations de dernière minute ou les non-présentations peuvent
                sérieusement impacter l'hôte, perturbant ainsi leur calendrier. Certains hôtes
                peuvent vous demander un acompte pour bloquer les dates demandées.
              </p>
              <p>
                Si vous ressentez le moindre inconfort après la réservation, n'hésitez pas à{' '}
                <a href='/help/feedback' target='_blank' rel='noreferrer'>
                  nous contacter
                </a>{' '}
                directement. De plus, assurez-vous de bien comprendre la conversion du montant total
                affiché lors de la réservation, car il peut différer une fois chez l'hôte en raison
                des échanges de devises.{' '}
                <a href='/payement-policy-for-traveler' target='_blank' rel='noreferrer'>
                  En savoir plus
                </a>
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
})
export default PolicyTraveler
