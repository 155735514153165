import { useState } from 'react'
import styles from '../../../css/becomeHostes/location.module.css'
import Geocode from 'react-geocode'
import { googleGeocodingApiKey } from '../../../config/config'
import { CountryNameOption } from '../../country_options/CountryName'

const ErrorSvg = () => {
  return (
    <svg
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='presentation'
      focusable='false'
    >
      <g>
        <path d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm0 10.2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm.8-6.6H7.2v5.2h1.6z'></path>
      </g>
    </svg>
  )
}

const Formulaire = ({
  setManuelAdress,
  fullAddress,
  appartement,
  ville,
  etat,
  codePostal,
  pays,
  setFullAddress,
  setAppartement,
  setVille,
  setEtat,
  setCodePostal,
  setPays,
  countryAbbreviation,
  setCountryAbbreviation,
  setReviewMap,
  setCoords,
}) => {
  const [errorfullAddress, setErrorfullAddress] = useState(false)
  const [errorVille, setErrorVille] = useState(false)
  const [errorPays, setErrorPays] = useState(false)
  const [errorNoResult, setErrorNoResult] = useState(false)

  Geocode.setApiKey(googleGeocodingApiKey)
  // Geocode.setLanguage("fr");
  // Geocode.setLocationType("ROOFTOP");
  const onSubmit = e => {
    e.preventDefault()
    setErrorfullAddress(false)
    setErrorVille(false)
    setErrorPays(false)
    setErrorNoResult(false)
    if (fullAddress && ville && pays) {
      getLatLngFuction(fullAddress)
    } else {
      if (!fullAddress) setErrorfullAddress(true)
      if (!ville) setErrorVille(true)
      if (!pays) setErrorPays(true)
    }
  }
  const getLatLngFuction = address => {
    Geocode.fromAddress(address).then(
      res => {
        const { lat, lng } = res.results[0].geometry.location
        setCoords({ lat: lat, lng: lng })
        setReviewMap(true)
      },
      () => {
        setErrorNoResult(true)
        setReviewMap(false)
      }
    )
  }

  const handleCountryChange = event => {
    const selectedCountryInfo = JSON.parse(event.target.value)
    setPays(selectedCountryInfo.country)
    setCountryAbbreviation(selectedCountryInfo.countryAbbreviation)
  }

  return (
    <div className={styles.form_container}>
      <div className={styles.form_block}>
        <div className={styles.form_head}>
          <button onClick={() => setManuelAdress(false)}>
            <svg
              viewBox='0 0 32 32'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'
              role='presentation'
              focusable='false'
            >
              <g>
                <path d='m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932'></path>
              </g>
            </svg>
          </button>
          <div>
            <h2>Confirmez votre adresse</h2>
          </div>
        </div>
        <form onSubmit={onSubmit} autoComplete='off'>
          <div className={errorfullAddress ? styles.erreur_input : ''}>
            <label htmlFor='full_address'>Adresse complète</label>
            <input
              id='full_address'
              type='text'
              value={fullAddress}
              onChange={e => setFullAddress(e.target.value)}
            />
            {errorfullAddress && (
              <div className={styles.error}>
                <ErrorSvg />
                <p>Pour continuer, veuillez renseigner l'adresse complète.</p>
              </div>
            )}
          </div>
          <div>
            <label htmlFor='appartement'>Appartement</label>
            <input
              id='appartement'
              type='text'
              placeholder='Appartement, bâtiment, ect. (facultatif)'
              value={appartement}
              onChange={e => setAppartement(e.target.value)}
            />
          </div>
          <div className={errorVille ? styles.erreur_input : ''}>
            <label htmlFor='ville'>Ville</label>
            <input
              id='ville'
              type='text'
              placeholder='La ville'
              value={ville}
              onChange={e => setVille(e.target.value)}
            />
            {errorVille && (
              <div className={styles.error}>
                <ErrorSvg />
                <p>Pour continuer, veuillez ajouter la ville.</p>
              </div>
            )}
          </div>
          <div>
            <label htmlFor='etat'>État</label>
            <input
              id='etat'
              type='text'
              placeholder='État (facultatif)'
              value={etat}
              onChange={e => setEtat(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor='code_postal'>Code postal</label>
            <input
              id='code_postal'
              type='text'
              placeholder='Code postal (facultatif)'
              value={codePostal}
              onChange={e => setCodePostal(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor='pays'>Pays/région</label>
            <select
              id='pays'
              onChange={handleCountryChange}
              value={JSON.stringify({
                country: pays,
                countryAbbreviation: countryAbbreviation,
              })}
            >
              <option defaultValue='' selected>
                Sélectionner le pays
              </option>
              <CountryNameOption />
            </select>
            {errorPays && (
              <div className={styles.error}>
                <ErrorSvg />
                <p>Pour continuer, veuillez Sélectionner le pays.</p>
              </div>
            )}
          </div>
          {errorNoResult && (
            <div className={styles.error}>
              <svg
                viewBox='0 0 16 16'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
              >
                <g>
                  <path d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm0 10.2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm.8-6.6H7.2v5.2h1.6z'></path>
                </g>
              </svg>
              <p>Nous ne pouvons pas localiser cette adresse. Est-elle correcte ?</p>
            </div>
          )}
          <div className={styles.valid}>
            <button type='submit' className={styles.submit}>
              Parfait
            </button>
            {errorNoResult && (
              <button type='button' onClick={() => setReviewMap(true)} className={styles.accepte}>
                Oui, mon adresse est correcte
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}

export default Formulaire
