import styles from '../../../css/navigations/sideBar.module.css'
import { Link } from 'react-router-dom'
import Tooltip from './Tooltip'

const Account = ({ user, path }) => {
  if (user === null) return null
  return (
    <Link to='/account-settings' className={path === 'account-settings' ? styles.active : null}>
      <div className={styles.icon_content}>
        <svg className={styles.account} aria-label='Options' role='img' viewBox='0 0 24 24'>
          <circle
            cx='12'
            cy='12'
            r='8.635'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></circle>
          <path
            d='M14.232 3.656a1.269 1.269 0 0 1-.796-.66L12.93 2h-1.86l-.505.996a1.269 1.269 0 0 1-.796.66m-.001 16.688a1.269 1.269 0 0 1 .796.66l.505.996h1.862l.505-.996a1.269 1.269 0 0 1 .796-.66M3.656 9.768a1.269 1.269 0 0 1-.66.796L2 11.07v1.862l.996.505a1.269 1.269 0 0 1 .66.796m16.688-.001a1.269 1.269 0 0 1 .66-.796L22 12.93v-1.86l-.996-.505a1.269 1.269 0 0 1-.66-.796M7.678 4.522a1.269 1.269 0 0 1-1.03.096l-1.06-.348L4.27 5.587l.348 1.062a1.269 1.269 0 0 1-.096 1.03m11.8 11.799a1.269 1.269 0 0 1 1.03-.096l1.06.348 1.318-1.317-.348-1.062a1.269 1.269 0 0 1 .096-1.03m-14.956.001a1.269 1.269 0 0 1 .096 1.03l-.348 1.06 1.317 1.318 1.062-.348a1.269 1.269 0 0 1 1.03.096m11.799-11.8a1.269 1.269 0 0 1-.096-1.03l.348-1.06-1.317-1.318-1.062.348a1.269 1.269 0 0 1-1.03-.096'
            stroke='currentColor'
            strokeLinejoin='round'
          ></path>
        </svg>
      </div>
      <p>Compte</p>
      <Tooltip title='Compte' />
    </Link>
  )
}

export default Account
