import React, { useState } from 'react'
import styles from '../../../../../../../css/traveler/travel/modifs/date.module.css'
import { useLocation } from 'react-router'
import axios from 'axios'
import moment from 'moment'
import LoadingPoint from '../../../../../../LoadingPoint'
import usePrice from '../../../../../../../hooks/usePrice'
import CheckinCalendrier from './CheckinCalendrier'
import CheckoutCalendrier from './CheckoutCalendrier'
import Toast from '../../../../../../infos/toast'
import { addCurrencyComplet } from '../../../../../../../utils/addCurrency'
import Modal from '../../../../../../ui/Modal'
import CloseButton from '../../../../../../ui/CloseButton'
import { STATUS_MESSAGE } from '../../../../../../../enums/message/status.enums'

const DateModal = React.forwardRef(({ reservation, refetch }, ref) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [modal, setModal] = useState(false)
  const checkin = reservation.reservation_infos?.checkin
  const checkout = reservation.reservation_infos?.checkout

  const [startDate, setStartDate] = useState(new Date(checkin))
  const [endDate, setEndDate] = useState(new Date(checkout))

  const day_start = new Date(startDate).setHours(0, 0, 0, 0)
  const day_end = new Date(endDate).setHours(0, 0, 0, 0)
  const total_days = (day_end - day_start) / (1000 * 60 * 60 * 24)
  const numberOfNightModif = Math.round(total_days)

  const location = useLocation()
  const id = location.pathname.split('/')[3]

  const {
    pricePerNight,
    totalPricePerNight,
    reduction,
    fullPromoFee,
    fullTravelerServiceFee,
    fullTotalWithservice,
    fullCleaningFee,
    cleaningFeeORG,
    totalWithserviceRounded,
    promoFeeRounded,
    totalBeforeReduction,
    serviceFeeRounded,
  } = usePrice(reservation.hotel, numberOfNightModif)
  const newPricePay = totalWithserviceRounded - reservation.prices?.total_stay_fee

  //delete a - simbole in price
  const priceText = newPricePay?.toString()
  const TotalRembourser = Number(priceText?.slice(1, priceText.length))

  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      setModal(true)
      setStartDate(new Date(checkin))
      setEndDate(new Date(checkout))
    },
  }))

  const closeModal = () => {
    setStartDate(new Date(checkin))
    setEndDate(new Date(checkout))
    setMessage('')
    setModal(false)
  }

  const minNuit = reservation.hotel?.stay_duration?.minimum
  const maxNuit = reservation.hotel?.stay_duration?.maximum

  const handleModif = () => {
    setLoading(true)
    const dateNow = Date.now()
    const data = {
      status: STATUS_MESSAGE.MODIFICATION_REQUEST,
      text: message,
      total_nights: numberOfNightModif,
      promotion_percentage: reduction,
      promotion_fee: promoFeeRounded,
      service_fee: serviceFeeRounded,
      total_stay_fee: totalWithserviceRounded,
      new_price_to_pay: Math.round(newPricePay),
      total_initial: reservation.prices.total_stay_fee,
      total_stay_fee_before_reduction: totalBeforeReduction,
      nightly_price: reservation.hotel?.prices.nightly_price,
      cleaning_fee: reservation.hotel?.prices.cleaning_fee,
      security_deposit: reservation.hotel?.prices.security_deposit,
      additional_guest_fee: reservation.hotel?.prices.additional_guest_fee,
      stay_fee: reservation.hotel?.prices.stay_fee,
      currency: reservation.hotel.prices.currency,
      checkin: moment(startDate).format('YYYY-MM-DD'),
      checkout: moment(endDate).format('YYYY-MM-DD'),
      createdAt: moment(dateNow),
    }
    axios
      .patch(
        `/message/demandeModifBooking/${reservation?.hotel?._id}/${reservation?.host_id}/${id}`,
        data
      )
      .then(() => {
        Toast.fire({
          icon: 'success',
          title: "Votre demande de modification a été envoyée avec succès à l'hôte.",
        })
        refetch()
      })
      .catch(() => {
        Toast.fire({ icon: 'error', title: 'Un problème est survenu au nuveau de serveur' })
      })
      .finally(() => {
        setMessage('')
        setModal(false)
        setLoading(false)
      })
  }

  return (
    <>
      <Modal isOpen={modal} onClose={closeModal}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={() => setModal(false)} />
            <h3>Modifier les dates</h3>
            <div></div>
          </div>
          <div className={styles.m_body}>
            <div className={styles.infos}>
              <div className={styles.max_and_min_container}>
                <p>
                  Durée minimale du séjour · {minNuit} nuit{minNuit > 1 && 's'}
                </p>
                <p>
                  Durée maximale du séjour · {maxNuit} nuit{maxNuit > 1 && 's'}
                </p>
              </div>
            </div>
            <div className={styles.verif_block_detail}>
              <div className={styles.dates_content}>
                <CheckinCalendrier
                  hotel={reservation?.hotel}
                  setStartDate={setStartDate}
                  startDate={startDate}
                />
                <CheckoutCalendrier
                  hotel={reservation?.hotel}
                  setEndDate={setEndDate}
                  endDate={endDate}
                />
              </div>
              <div className={styles.message}>
                <label htmlFor='message'>
                  Communiquez votre heure d'arrivée et le motif de cette modification à votre hôte
                </label>
                <textarea
                  id='message'
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                ></textarea>
              </div>
              {Math.round(newPricePay) !== 0 && (
                <>
                  <div className={styles.content_price}>
                    <h3>Détails du prix</h3>
                    <div className={styles.price_block}>
                      <p>
                        {pricePerNight} x {numberOfNightModif}{' '}
                        {numberOfNightModif > 1 ? 'nuits' : 'nuit'}
                      </p>
                      <strong>{totalPricePerNight}</strong>
                    </div>
                    {reduction > 0 && (
                      <div className={styles.price_block}>
                        <p>Réduction</p>
                        <strong className={styles.reduce_price}>-{fullPromoFee}</strong>
                      </div>
                    )}
                    {cleaningFeeORG > 0 && (
                      <div className={styles.price_block}>
                        <p>Frais de ménage</p>
                        <strong>{fullCleaningFee}</strong>
                      </div>
                    )}
                    {newPricePay > 0 && (
                      <div className={styles.price_block}>
                        <p>Frais de service</p>
                        <strong>{fullTravelerServiceFee}</strong>
                      </div>
                    )}
                    <div className={styles.price_block}>
                      <h4>Total</h4>
                      <strong>{fullTotalWithservice}</strong>
                    </div>
                  </div>
                  <div className={styles.newpayment}>
                    {Math.round(newPricePay) > 0 ? (
                      <p>
                        Vous payez :
                        <strong>
                          {addCurrencyComplet(newPricePay, reservation?.prices?.currency)}
                        </strong>
                        sur le prix Total initial de
                        <strong>
                          {addCurrencyComplet(
                            reservation.prices?.total_stay_fee,
                            reservation?.prices?.currency
                          )}
                        </strong>
                      </p>
                    ) : (
                      <p>
                        Nous vous remboursons :
                        <strong>
                          {addCurrencyComplet(TotalRembourser, reservation?.prices?.currency)}
                        </strong>
                        sur le prix Total initial de la réservation qui est
                        <strong>
                          {addCurrencyComplet(
                            reservation.prices?.total_stay_fee,
                            reservation?.prices?.currency
                          )}
                        </strong>
                      </p>
                    )}
                  </div>
                </>
              )}
              <div className={styles.submit_btn}>
                {loading ? (
                  <button disabled className={styles.loading}>
                    <LoadingPoint />
                  </button>
                ) : (
                  <button
                    className={
                      message.trim().length === 0 || total_days < 1 ? styles.disable : null
                    }
                    disabled={message.trim().length === 0 || total_days < 1}
                    type='button'
                    onClick={handleModif}
                  >
                    Demander la modification
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
})
export default DateModal
