import { useRef } from 'react'
import styles from '../../../css/traveler/favoris/connexion.module.css'
import Login from '../../../components/login'

const Connexion = () => {
  const connexion = useRef()
  const handleConnection = params => {
    connexion.current.OpenModal(params)
  }
  return (
    <>
      <title>Connexion/Inscription</title>
      <div className={styles.connexion}>
        <h2>Connectez-vous pour consulter vos favoris</h2>
        <p>
          Vous pouvez créer, consulter ou modifier les favoris en vous connectant à votre compte.
        </p>
        <button onClick={handleConnection}>Connectez-vous</button>
      </div>
      <Login ref={connexion} />
    </>
  )
}

export default Connexion
