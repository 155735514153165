import React, { useState, useRef } from 'react'
import styles from '../../css/logins/login.module.css'
import ContentLogin from './contents/ContentLogin'
import Modal from '../ui/Modal'
import CloseButton from '../ui/CloseButton'
import FooterCategory from './contents/FooterCategory'
import { CONNECTION_TYPE } from '../../enums/user/connectionScreen'
import FooterLocation from './contents/FooterLocation'

const Login = React.forwardRef((props, ref) => {
  const [getUser, setGetUser] = useState(null)
  const [modal, setModal] = useState(false)
  const [active, setActive] = useState(CONNECTION_TYPE.CONNECTION)
  const [categories, setCategories] = useState([])
  const [fullAddress, setFullAddress] = useState('')
  const [appartement, setAppartement] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [country, setCountry] = useState('')
  const [countryAbbreviation, setCountryAbbreviation] = useState('')
  const [coords, setCoords] = useState({})
  const [isValid, setIsValid] = useState(false)

  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      setModal(true)
    },
  }))

  const handleClose = () => {
    if (active === CONNECTION_TYPE.PROFIL_CATEGORY || active === CONNECTION_TYPE.PROFIL_LOCATION) {
      window.location.reload()
    }
    setModal(false)
  }

  const bodyRef = useRef(null)
  const scrollToTop = () => {
    const domNode = bodyRef.current
    if (domNode) {
      domNode.scrollTop = 0
    }
  }

  return (
    <>
      <Modal isOpen={modal} onClose={handleClose}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={handleClose} />
            <h3>Connexion ou inscription</h3>
            <div></div>
          </div>
          <div className={styles.m_body} ref={bodyRef}>
            <ContentLogin
              active={active}
              setActive={setActive}
              setGetUser={setGetUser}
              scrollToTop={scrollToTop}
              categories={categories}
              setCategories={setCategories}
              fullAddress={fullAddress}
              setFullAddress={setFullAddress}
              appartement={appartement}
              setAppartement={setAppartement}
              city={city}
              setCity={setCity}
              state={state}
              setState={setState}
              postalCode={postalCode}
              setPostalCode={setPostalCode}
              country={country}
              setCountry={setCountry}
              setCountryAbbreviation={setCountryAbbreviation}
              setCoords={setCoords}
              setIsValid={setIsValid}
            />
          </div>
          {active === CONNECTION_TYPE.PROFIL_CATEGORY && (
            <FooterCategory setActive={setActive} categories={categories} getUser={getUser} />
          )}
          {active === CONNECTION_TYPE.PROFIL_LOCATION && (
            <FooterLocation
              setActive={setActive}
              fullAddress={fullAddress}
              appartement={appartement}
              city={city}
              state={state}
              countryAbbreviation={countryAbbreviation}
              postalCode={postalCode}
              country={country}
              coords={coords}
              isValid={isValid}
              getUser={getUser}
              handleClose={handleClose}
            />
          )}
        </div>
      </Modal>
    </>
  )
})

export default Login
