import styles from '../../../css/details/equipement.module.css'
import CheckAmenitieSvg from './components/CheckAmenitieSvg'

const Bathroom = ({ hotel }) => {
  const { amenities } = hotel

  return (
    <div className={styles.container_equip}>
      <h4>Salle de bain</h4>
      {amenities?.includes('12') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Sèche-cheveux</p>
        </div>
      )}
      {amenities?.includes('22') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Produits de nettoyage</p>
        </div>
      )}
      {amenities?.includes('23') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Savon pour le corps</p>
        </div>
      )}
      {amenities?.includes('24') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Bidet</p>
        </div>
      )}
      {amenities?.includes('25') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Eau chaude</p>
        </div>
      )}
      {amenities?.includes('66') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Baignoire</p>
        </div>
      )}
      {amenities?.includes('58') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Douche extérieure</p>
        </div>
      )}
      {amenities?.includes('67') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Shampooing</p>
        </div>
      )}
      {amenities?.includes('69') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Gel douche</p>
        </div>
      )}
    </div>
  )
}

export default Bathroom
