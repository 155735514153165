import styles from '../../../../../../css/modehotes/annonce/details/photo.module.css'
import { useEffect, useRef } from 'react'
import usePhoto from '../../../../../../hooks/usePhoto'

const DropdownMenu = ({ photo, path, images, setImages, HandleModalUpdated }) => {
  const { open, setOpen, deleteImage, handleChangeTocoverImage, handleGoForward, handleGoBack } =
    usePhoto(path, images, setImages)

  const contenttogle = useRef(null)
  useEffect(() => {
    const handleClickOutside = event => {
      if (contenttogle.current && !contenttogle.current.contains(event.target)) {
        setOpen(null)
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return (
    <div>
      <button
        type='button'
        className={open === photo._id ? `${styles.first} ${styles.active}` : styles.first}
        onClick={() => setOpen(photo._id)}
      >
        <svg
          className={styles.tree_points}
          viewBox='0 0 16 16'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'
          role='presentation'
          focusable='false'
          fill='currentcolor'
        >
          <path d='M3 6a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm5 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm5 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4z'></path>
        </svg>
        {open === photo._id && (
          <div className={styles.dropdown_menu} ref={contenttogle}>
            <div className={styles.option}>
              <span
                role='button'
                tabIndex={0}
                onClick={() => HandleModalUpdated(photo._id, photo.url, photo.caption)}
              >
                Modifier
              </span>
              {images.findIndex(el => el._id === photo._id) !== 0 && (
                <span
                  role='button'
                  tabIndex={0}
                  onClick={() => handleChangeTocoverImage(photo._id)}
                >
                  Choisir comme photo de couverture
                </span>
              )}
              {images.findIndex(el => el._id === photo._id) !== 0 && (
                <span role='button' tabIndex={0} onClick={() => handleGoBack(photo._id)}>
                  Déplacer vers le haut
                </span>
              )}
              {images.findIndex(el => el._id === photo._id) !== images.length - 1 && (
                <span role='button' tabIndex={0} onClick={() => handleGoForward(photo._id)}>
                  Déplacer vers le bas
                </span>
              )}
              {images.length > 1 && (
                <span role='button' tabIndex={0} onClick={() => deleteImage(photo)}>
                  Supprimer
                </span>
              )}
            </div>
          </div>
        )}
      </button>
    </div>
  )
}

export default DropdownMenu
