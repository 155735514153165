import { useEffect, useState } from 'react'
import styles from '../../../../../css/modehotes/annonce/details/listAnnonce.module.css'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import momentTimeZone from 'moment-timezone'
import HeaderList from './components/list_annonce/HeaderList'
import LoadingList from './components/list_annonce/LoadingList'
import { SATATUS_ANNONCE } from '../../../../../enums/logement/status.enums'
import { useQuery } from 'react-query'
import Pagination from '../../defaults/Pagination'

const ListAnnonce = () => {
  const [searchParams, setSearchParams] = useState(window.location.search)

  const history = useHistory()
  const path = window.location.pathname.split('/')[4]

  const userTimezone = momentTimeZone.tz.guess()

  useEffect(() => {
    const unlisten = history.listen(location => {
      setSearchParams(location.search)
    })

    return () => {
      unlisten()
    }
  }, [history])

  const fetchData = async () => {
    const { data } = await axios.get(`/hotel${searchParams}`)
    return data
  }

  const { data = [], isLoading } = useQuery([`get_all_hotels`, searchParams], fetchData)

  const handleDetail = (status, redirect_url, id) => {
    if (status === SATATUS_ANNONCE.IN_PROGRESS) {
      history.push(`/become-a-host/${redirect_url}/${id}`)
    } else if (status === SATATUS_ANNONCE.SAVE) {
      history.push(`/verify-listing/${id}`)
    } else {
      history.push(`/hosting/listings/details/${id}${searchParams}`)
    }
  }

  return (
    <>
      <div className={styles.annonce_list}>
        <HeaderList loading={isLoading} annonce={data} />
        {isLoading ? (
          <LoadingList />
        ) : (
          <div className={styles.content_list}>
            <div className={styles.content_list_block}>
              {data?.results?.map(e => (
                <button
                  key={e._id}
                  onClick={() => handleDetail(e.status, e.redirect_url, e._id)}
                  className={e._id === path ? styles.active : null}
                >
                  <div>
                    <div className={styles.card}>
                      {e.images[0]?.url ? (
                        <img src={e.images[0]?.url} alt='' />
                      ) : (
                        <svg
                          viewBox='0 0 16 16'
                          xmlns='http://www.w3.org/2000/svg'
                          aria-hidden='true'
                          role='presentation'
                          focusable='false'
                        >
                          <path d='M8.602 1.147l.093.08 7.153 6.914-.696.718L14 7.745V14.5a.5.5 0 0 1-.41.492L13.5 15H10V9.5a.5.5 0 0 0-.41-.492L9.5 9h-3a.5.5 0 0 0-.492.41L6 9.5V15H2.5a.5.5 0 0 1-.492-.41L2 14.5V7.745L.847 8.86l-.696-.718 7.153-6.915a1 1 0 0 1 1.297-.08z'></path>
                        </svg>
                      )}
                    </div>
                  </div>
                  <div>
                    <h3>{e.title || 'Donnez un titre à votre annonce'}</h3>
                    <p>Modifié : {momentTimeZone.utc(e.updatedAt).tz(userTimezone).calendar()}</p>
                  </div>
                </button>
              ))}
            </div>
            <Pagination data={data} />
          </div>
        )}
      </div>
    </>
  )
}

export default ListAnnonce
