export async function loadCookiesParams() {
  // Load Google Analytics script dynamically
  const script = document.createElement('script')
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-MZWND6JRF7'
  script.async = true
  document.head.appendChild(script)

  script.onload = () => {
    // Initialize and configure Google Analytics after the script has loaded
    window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments)
    }
    gtag('js', new Date())
    gtag('config', 'G-MZWND6JRF7')
  }

  // Remove the script element after it has been loaded
  script.parentNode.removeChild(script)

  // You can also add additional logic or analytics events here
}
