import { useState, useMemo } from 'react'
import globalStyle from '../../../css/becomeHostes/globalStyle.module.css'
import styles from '../../../css/becomeHostes/photos.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import { useQuery } from 'react-query'
import axios from 'axios'
import LoadingPoint from '../../../components/LoadingPoint'
import ErrorServer from '../errorServer/ErrorServer'
import useAddHome from '../../../hooks/useAddHome'
import usePhoto from '../../../hooks/usePhoto'
import DropzoneMain from './DropzoneMain'
import DropzoneList from './DropzoneList'
import AddPhoto from './lists/AddPhoto'
import SortablePhoto from './lists/SortablePhoto'
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable'
import { PhotoDrag } from './lists/PhotoDrag'
import PhotoIncopatible from './lists/PhotoIncopatible'

const Form = () => {
  const [loading, setLoading] = useState(false)
  const [images, setImages] = useState([])
  const [serverError, setServerError] = useState(false)
  const { updateData } = useAddHome(setLoading, setServerError)

  const history = useHistory()
  const location = useLocation()
  const page = location.pathname.split('/')[2]
  const path = location.pathname.split('/')[3]

  const { isLoading, error, refetch } = useQuery(`get-hotel-on-creation-${path}`, () =>
    axios.get(`/hotel/oneAnnonce/${path}`).then(res => {
      setImages(res.data.images)
    })
  )

  const handleSubmit = () => {
    setLoading(true)
    const datas = {
      redirect_url: page,
    }
    updateData(datas, '/become-a-host/title')
  }

  const {
    activeUrl,
    handleDragStart,
    handleDragEnd,
    handleDragCancel,
    handleUploadInput,
    imagesNotCompatible,
    setImagesNotCompatible,
    loadingUpload,
  } = usePhoto(path, images, setImages)

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    })
  )

  const itemIds = useMemo(() => images.map(item => item._id), [images])

  if (error || serverError) {
    return <ErrorServer />
  }

  return (
    <>
      <div className={globalStyle.left}>
        <AddPhoto images={images} handleUploadInput={handleUploadInput} />
        <div className={styles.block_center}>
          <div className={styles.block_form}>
            {loadingUpload && (
              <div className={styles.loading_upload}>
                <LoadingPoint />
              </div>
            )}
            {isLoading ? (
              <LoadingPoint />
            ) : (
              <div className={globalStyle.annimcontent}>
                {images.length < 1 && imagesNotCompatible.length < 1 ? (
                  <DropzoneMain handleUploadInput={handleUploadInput} />
                ) : (
                  <div className={styles.photos}>
                    <div className={styles.couverture}>
                      <div className={styles.info}>
                        <h1>Photo de couverture</h1>
                      </div>
                      <img src={images[0]?.url ?? imagesNotCompatible[0]} alt='couverture' />
                    </div>
                    <DndContext
                      sensors={sensors}
                      collisionDetection={closestCenter}
                      onDragEnd={handleDragEnd}
                      onDragStart={handleDragStart}
                      onDragCancel={handleDragCancel}
                    >
                      <SortableContext items={itemIds} strategy={rectSortingStrategy}>
                        <div className={styles.lists}>
                          {images.map((photo, index) => (
                            <SortablePhoto
                              key={photo._id}
                              index={index}
                              photo={photo}
                              path={path}
                              images={images}
                              setImages={setImages}
                              refetch={refetch}
                            />
                          ))}
                          <PhotoIncopatible
                            imagesNotCompatible={imagesNotCompatible}
                            setImagesNotCompatible={setImagesNotCompatible}
                          />
                          <DropzoneList handleUploadInput={handleUploadInput} />
                        </div>
                      </SortableContext>
                      <DragOverlay adjustScale={true}>
                        {activeUrl ? <PhotoDrag url={activeUrl} /> : null}
                      </DragOverlay>
                    </DndContext>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className={globalStyle.step_buttons}>
          <button
            type='button'
            onClick={() => history.push(`/become-a-host/amenities/${path}`)}
            className={globalStyle.retour}
          >
            Retour
          </button>
          {loading ? (
            <button disabled className={globalStyle.loading}>
              <LoadingPoint />
            </button>
          ) : (
            <button
              type='button'
              onClick={handleSubmit}
              disabled={images.length < 5}
              className={
                images.length < 5 ? `${globalStyle.next} ${globalStyle.disable}` : globalStyle.next
              }
            >
              Suivant
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export default Form
