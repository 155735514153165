import React, { useState, useRef, useContext } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { getFavoris } from '../../../../redux/actions'
import { User } from '../../../../context/UserContext'
import Login from '../../../login'
import ViewList from '../../../add_to_favoris/ViewList'
import CreateList from '../../../add_to_favoris/CreateList'
import NotifMessage from '../../../add_to_favoris/NotifMessage'
import Modal from '../../../ui/Modal'

const FavoriteResult = React.forwardRef(({ item }, ref) => {
  const [modal, setModal] = useState(false)
  const [createList, setCreateList] = useState(false)
  const [pushfavori, setPushFavori] = useState(false)
  const [supfavori, setsupFavori] = useState(false)
  const dispatch = useDispatch()
  const user = useContext(User)

  const handlegetAllFavoris = () => {
    dispatch(getFavoris())
  }

  React.useImperativeHandle(ref, () => ({
    openModal: () => {
      handleModal()
    },
    removeFavorite: favoriteListId => {
      handleDeleteToliste(favoriteListId)
    },
  }))

  //handleModal
  const handleModal = () => {
    if (user === null) {
      handleConnection('connexion')
    } else {
      setCreateList(false)
      setModal(true)
    }
  }

  //supprimer aux favori liste
  const handleDeleteToliste = favoriteListId => {
    axios
      .put(`/favoris/delete/${favoriteListId}/${item._id}`)
      .then(() => {
        setModal(false)
        handlegetAllFavoris()
      })
      .catch(() => {})
    setsupFavori(true)
    setPushFavori(false)
    setTimeout(() => {
      setsupFavori(false)
    }, 3000)
  }
  //connexion
  const connexion = useRef()
  const handleConnection = params => {
    connexion.current.OpenModal(params)
  }

  return (
    <>
      <Modal isOpen={modal} onClose={() => setModal(false)} useStoragescrollbarWidth={true}>
        <div>
          {!createList ? (
            <ViewList
              annonce={item}
              setModal={setModal}
              setCreateList={setCreateList}
              setPushFavori={setPushFavori}
              setsupFavori={setsupFavori}
              getAllFavoris={handlegetAllFavoris}
            />
          ) : (
            <CreateList
              annonce={item}
              setModal={setModal}
              setCreateList={setCreateList}
              setPushFavori={setPushFavori}
              setsupFavori={setsupFavori}
              getAllFavoris={handlegetAllFavoris}
            />
          )}
        </div>
      </Modal>

      <NotifMessage pushfavori={pushfavori} supfavori={supfavori} />
      <Login ref={connexion} />
    </>
  )
})

export default FavoriteResult
