import axios from 'axios'
import { totalHotel } from '../../redux/actions'
import Geocode from 'react-geocode'
import { googleGeocodingApiKey } from '../../config/config'

function getSearchHotel(setLoading, setHotel, setCoords, dispatch, user) {
  Geocode.setApiKey(googleGeocodingApiKey)
  Geocode.setLocationType('ROOFTOP')

  const getHotels = (query, search_lat, search_lng, lat, lng) => {
    axios
      .get(
        `/hotel_user/search${query}${user ? `&user=${user?.id}` : ''}${
          !search_lat && !search_lng ? `&lng=${lng} &lat=${lat}` : ''
        }`
      )
      .then(res => {
        setHotel(res.data)
        dispatch(totalHotel(res.data?.total_hotels))
      })
      .catch(() => {
        setHotel([])
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getCoords = (address, query, search_lat, search_lng) => {
    setLoading(true)
    if (search_lat && search_lng) {
      getHotels(query, search_lat, search_lng, '0', '0')
    } else {
      Geocode.fromAddress(address)
        .then(res => {
          const { lat, lng } = res.results[0].geometry.location
          setCoords({ lat: lat, lng: lng })
          getHotels(query, search_lat, search_lng, lat, lng)
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }

  return { getCoords }
}
export default getSearchHotel
