import styles from '../../../../css/search_room_result/card.module.css'

const DetailsHotel = ({ item }) => {
  return (
    <div className={styles.descrip_detail}>
      <p>
        {item.details?.traveler} {item.details?.traveler > 1 ? 'voyageurs' : 'voyageur'}
      </p>
      <span></span>
      <p>
        {item.details?.number_of_rooms} {item.details?.number_of_rooms > 1 ? 'chambres' : 'chambre'}
      </p>
      <span></span>
      <p>
        {item.details?.number_of_beds} {item.details?.number_of_beds > 1 ? 'lits' : 'lit'}
      </p>
      <span></span>
      <p className={styles.end}>
        {item.details?.number_of_bathrooms}{' '}
        {item.details?.number_of_bathrooms > 1 ? 'salles de bain' : 'salle de bain'}
      </p>
    </div>
  )
}

export default DetailsHotel
