import { useMemo } from 'react'
import styles from '../../../../../css/modehotes/annonce/details/photo.module.css'
import LoadingPoint from '../../../../LoadingPoint'
import DropzoneMain from './DropzoneMain'
import DropzoneList from './DropzoneList'
import AddPhoto from './lists/AddPhoto'
import usePhoto from '../../../../../hooks/usePhoto'
import SortablePhoto from './lists/SortablePhoto'
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable'
import { PhotoDrag } from './lists/PhotoDrag'
import PhotoIncopatible from './lists/PhotoIncopatible'

const Upload = ({ path, images, setImages, refetch }) => {
  const {
    activeUrl,
    handleDragStart,
    handleDragEnd,
    handleDragCancel,
    handleUploadInput,
    imagesNotCompatible,
    setImagesNotCompatible,
    loadingUpload,
  } = usePhoto(path, images, setImages)

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    })
  )
  const itemIds = useMemo(() => images.map(item => item._id), [images])

  return (
    <>
      <AddPhoto images={images} handleUploadInput={handleUploadInput} />
      <div className={styles.block_center}>
        <div className={styles.block_form}>
          {loadingUpload && (
            <div className={styles.loading_upload}>
              <LoadingPoint />
            </div>
          )}

          <div>
            {images.length < 1 && imagesNotCompatible.length < 1 ? (
              <DropzoneMain handleUploadInput={handleUploadInput} />
            ) : (
              <div className={styles.photos}>
                <div className={styles.couverture}>
                  <div className={styles.info}>
                    <h1>Photo de couverture</h1>
                  </div>
                  <img src={images[0]?.url ?? imagesNotCompatible[0]} alt='couverture' />
                </div>
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                  onDragStart={handleDragStart}
                  onDragCancel={handleDragCancel}
                >
                  <SortableContext items={itemIds} strategy={rectSortingStrategy}>
                    <div className={styles.lists}>
                      {images.map((photo, index) => (
                        <SortablePhoto
                          key={photo._id}
                          index={index}
                          photo={photo}
                          path={path}
                          images={images}
                          setImages={setImages}
                          refetch={refetch}
                        />
                      ))}
                      <PhotoIncopatible
                        imagesNotCompatible={imagesNotCompatible}
                        setImagesNotCompatible={setImagesNotCompatible}
                      />
                      <DropzoneList handleUploadInput={handleUploadInput} />
                    </div>
                  </SortableContext>
                  <DragOverlay adjustScale={true}>
                    {activeUrl ? <PhotoDrag url={activeUrl} /> : null}
                  </DragOverlay>
                </DndContext>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Upload
