import styles from '../../../css/logins/profil_category.module.css'
import { categoryOptions } from '../../accounts/profile_categories/options'

const ProfilCategory = ({ categories, setCategories }) => {
  const handleChange = e => {
    if (!e.target.checked) {
      const newArray = categories.filter(item => item !== e.target.value)
      setCategories(newArray)
    } else {
      setCategories([...categories, e.target.value])
    }
  }

  return (
    <div className={styles.container}>
      <h2>Sélectionnez les catégories qui vous correspondent le mieux</h2>
      <div className={styles.block_checkbox}>
        {categoryOptions.map((item, i) => (
          <label key={i}>
            <input
              type='checkbox'
              value={item.value}
              checked={categories.includes(item.value)}
              onChange={handleChange}
            />
            <div className={styles.block}>
              <div>{item.icon}</div>
              <div className={styles.block_content}>
                <h3>{item.name}</h3>
                <p>{item.description}</p>
              </div>
            </div>
          </label>
        ))}
      </div>
    </div>
  )
}

export default ProfilCategory
