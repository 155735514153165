import { useEffect, useRef, useContext } from 'react'
import styles from '../../css/becomeHostes/home.module.css'
import { User } from '../../context/UserContext'
import Login from '../../components/login'
import Footer from '../../components/navigations/Footer'
import Header from '../../components/become_host/homes/Header'
import Section1 from '../../components/become_host/homes/Section1'
import Section2 from '../../components/become_host/homes/Section2'
import Section3 from '../../components/become_host/homes/Section3'
import Section4 from '../../components/become_host/homes/Section4'
import SectionStep from '../../components/become_host/homes/SectionStep'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router'
import { useQuery } from 'react-query'
import axios from 'axios'

const BecomeHost = () => {
  const user = useContext(User)
  const elementRef = useRef(null)

  const connexion = useRef()
  const handleConnection = params => {
    connexion.current.OpenModal(params)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const location = useLocation()
  const sponsorId = location.pathname.split('/')[3]

  const { data: sponsorUser = null, isLoading } = useQuery(
    `get-sponsor-profil-${sponsorId}`,
    () => {
      if (sponsorId) {
        return axios.get(`/affiliation/user_referrer/${sponsorId}`).then(res => {
          return res.data
        })
      }
    }
  )

  return (
    <>
      <Helmet>
        <title>Partagez votre logement sur Gowifmi</title>
      </Helmet>
      <Header
        referrer={sponsorUser}
        isLoading={isLoading}
        handleConnection={handleConnection}
        user={user}
        elementRef={elementRef}
      />
      {/*---------main-------*/}
      <div className={styles.main_home}>
        <SectionStep />
        <Section1 elementRef={elementRef} />
        <Section2
          handleConnection={handleConnection}
          user={user}
          referrer={sponsorUser}
          isLoading={isLoading}
        />
        <Section3 />
      </div>
      <Section4
        handleConnection={handleConnection}
        user={user}
        referrer={sponsorUser}
        isLoading={isLoading}
      />

      <Login ref={connexion} />
      <Footer />
    </>
  )
}

export default BecomeHost
