import styles from '../../css/confirmations/block_2.module.css'
import useHotelRating from '../../hooks/useHotelRating'
import moment from 'moment'
import { addCurrencyComplet } from '../../utils/addCurrency'
import StarSvg from '../svgs/StarSvg'

const Block2 = ({ reservation }) => {
  const { commentLength, totalComment } = useHotelRating(reservation?.hotel)
  const details = reservation?.reservation_infos

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.photo_block}>
          <div className={styles.photo}>
            <img src={reservation?.hotel.images[0]?.url} alt='' />
          </div>
        </div>
        <div className={styles.content}>
          <h4>{reservation?.hotel.title}</h4>
          {commentLength > 0 ? (
            <div className={styles.avis}>
              <StarSvg width={12} height={12} />
              <p>{totalComment}</p>
              <span>
                ({commentLength} Commentaire{commentLength > 1 ? 's' : ''})
              </span>
            </div>
          ) : (
            <div className={styles.avis}>
              <StarSvg width={12} height={12} />
              <span>Nouveau</span>
            </div>
          )}
          <p>
            {moment(details?.checkin).format('ll')} – {moment(details?.checkout).format('ll')}
          </p>
          <p>
            {addCurrencyComplet(reservation?.prices?.total_stay_fee, reservation?.prices?.currency)}
          </p>
        </div>
      </div>
    </div>
  )
}
export default Block2
