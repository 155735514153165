import { useRef } from 'react'
import styles from '../../css/navigations/navSearch.module.css'
import FilterInline from '../search_room_result/FilterInline'
import ModalSearch from '../research_modal/ModalSearch'
import moment from 'moment'
import { useSearchParams } from '../../context/SearchParamContext'

function Navbar() {
  const { params } = useSearchParams()

  const region_search = params.get('place')
  const adulte_search = params.get('adults')
  const enfant_search = params.get('children')
  const bebe = params.get('infants')
  const animal = params.get('pets')
  const checkin_search = params.get('checkin')
  const checkout_search = params.get('checkout')
  const searchMap = params.get('search_by_map')
  const region = region_search
  const voyageur = parseInt(adulte_search) + parseInt(enfant_search)
  const checkin = moment(checkin_search).format('ll')
  const checkout = moment(new Date(checkout_search)).format('ll')
  const ChechinCorect = Array.from(checkin).splice(0, 7)

  const search = useRef()
  const openSearch = () => {
    search.current.OpenModal()
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.result_nav}>
          <div className={styles.button_searchResult} onClick={() => openSearch()}>
            <div className={styles.block_racap}>
              {searchMap ? (
                <h3 className={`${styles.colone} ${styles.city}`}>Zone géographique de la carte</h3>
              ) : (
                <>
                  {region ? (
                    <h3 className={`${styles.colone} ${styles.city}`}>{region?.split(',')[0]}</h3>
                  ) : (
                    <h3 className={`${styles.colone} ${styles.city}`}>Ajouter une destination</h3>
                  )}
                </>
              )}
              <div className={styles.date_voyageuer}>
                {checkin_search && checkout_search ? (
                  <h3 className={`${styles.colone} ${styles.date}`}>
                    {ChechinCorect} – {checkout}
                  </h3>
                ) : (
                  <p className={`${styles.colone} ${styles.date}`}>Ajouter des dates</p>
                )}
                {voyageur ? (
                  <h3 className={styles.capacite}>
                    {voyageur} {voyageur > 1 ? 'voyageurs' : 'voyageur'}
                    {bebe > 0 && `, ${bebe} ${bebe > 1 ? 'Bébés' : 'Bébé'}`}{' '}
                    {animal > 0 && `, ${animal} ${animal > 1 ? 'Animaux' : 'Animal'}`}
                  </h3>
                ) : (
                  <p className={styles.capacite}>Ajouter des voyageurs</p>
                )}
              </div>
            </div>
            <div className={styles.icon_search}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 32 32'
                aria-hidden='true'
                role='presentation'
                focusable='false'
                style={{
                  display: 'block',
                  fill: 'none',
                  stroke: 'currentcolor',
                  overflow: 'visible',
                }}
              >
                <path fill='none' d='M13 24a11 11 0 1 0 0-22 11 11 0 0 0 0 22zm8-3 9 9'></path>
              </svg>
            </div>
          </div>
        </div>
        {/*--------Filter_1 populaire--------*/}
        <FilterInline />
      </div>
      <ModalSearch ref={search} />
    </>
  )
}
export default Navbar
