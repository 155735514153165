import styles from '../../../css/details/asavoir.module.css'
import Modal from '../../ui/Modal'
import CloseButton from '../../ui/CloseButton'
import useModalByUrl from '../../../hooks/useModalByUrl'

const ErrorSvg = () => {
  return (
    <>
      <svg
        viewBox='0 0 16 16'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'
        role='presentation'
        focusable='false'
        style={{
          display: 'block',
          height: '14px',
          width: '14px',
          fill: '#333',
        }}
      >
        <g>
          <path d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm0 10.2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm.8-6.6H7.2v5.2h1.6z'></path>
        </g>
      </svg>
    </>
  )
}

const HealthAndSecurity = ({ hotel }) => {
  const modalValue = 'SECURITY'
  const { modalState, handleOpenModal, handleCloseModal } = useModalByUrl(modalValue)
  return (
    <>
      <div className=''>
        <h4>Santé et sécurité</h4>
        {hotel.about?.enhanced_cleaning && (
          <div className={styles.block_content}>
            <p>L'hôte s'engage à appliquer le processus de nettoyage renforcé de Gowifmi.</p>
          </div>
        )}
        {hotel.amenities?.includes('18') ? (
          <div className={styles.block_content}>
            <p>Détecteur de monoxyde de carbone</p>
          </div>
        ) : (
          <div className={styles.block_content}>
            <p className={styles.no}>Aucun détecteur de monoxyde de carbone</p>
          </div>
        )}
        {hotel.amenities?.includes('19') ? (
          <div className={styles.block_content}>
            <p>Détecteur de fumée</p>
          </div>
        ) : (
          <div className={styles.block_content}>
            <p className={styles.no}>Aucun détecteur de fumée</p>
          </div>
        )}
        <div className={styles.savoir_btn}>
          <button onClick={handleOpenModal}>En savoir plus</button>
        </div>
      </div>

      {/*----modal message de l'hotel---------*/}
      <Modal isOpen={modalState} onClose={handleCloseModal}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={handleCloseModal} />
            <div></div>
          </div>
          <div className={styles.m_body}>
            <div>
              <h2>Santé et sécurité</h2>
              {hotel.amenities?.includes('18') ? (
                <div className={styles.infos}>
                  <p>Détecteur de monoxyde de carbone</p>
                </div>
              ) : (
                <div className={styles.infos}>
                  <div>
                    <ErrorSvg />
                  </div>
                  <p className={styles.no}>Aucun détecteur de monoxyde de carbone</p>
                </div>
              )}

              {hotel.amenities?.includes('19') ? (
                <div className={styles.infos}>
                  <p>Détecteur de fumée</p>
                </div>
              ) : (
                <div className={styles.infos}>
                  <div>
                    <ErrorSvg />
                  </div>
                  <p className={styles.no}>Aucun détecteur de fumée</p>
                </div>
              )}

              {hotel.traveler_safety?.pool_jacuzzi_unfenced_unlocked && (
                <div className={styles.infos}>
                  <div>
                    <ErrorSvg />
                  </div>
                  <p>Piscine/jacuzzi sans clôture ni verrou</p>
                </div>
              )}
              {hotel.traveler_safety?.lake_river_other_water_body_nearby && (
                <div className={styles.infos}>
                  <div>
                    <ErrorSvg />
                  </div>
                  <p>Lac, rivière ou autre étendue d'eau à proximité</p>
                </div>
              )}
              {hotel.traveler_safety?.height_unbarriered_unprotected && (
                <div className={styles.infos}>
                  <div>
                    <ErrorSvg />
                  </div>
                  <p>Zones en hauteur sans barrières ni protections</p>
                </div>
              )}
              {hotel.traveler_safety?.playground_climbing_structure && (
                <div className={styles.infos}>
                  <div>
                    <ErrorSvg />
                  </div>
                  <p>Aire de jeux ou structure d'escalade</p>
                </div>
              )}
              {hotel.traveler_safety?.dangerous_animals && (
                <div className={styles.infos}>
                  <div>
                    <ErrorSvg />
                  </div>
                  <p>Présence d'animal potentiellement dangereux</p>
                </div>
              )}
              {hotel.traveler_safety?.cameras && (
                <div className={styles.infos}>
                  <div>
                    <ErrorSvg />
                  </div>
                  <p>
                    Caméras de surveillance ou appareils d'enregistrement audio sur la propriété
                  </p>
                </div>
              )}
              {hotel.traveler_safety?.weapon && (
                <div className={styles.infos}>
                  <div>
                    <ErrorSvg />
                  </div>
                  <p>Armes sur la propriété</p>
                </div>
              )}
            </div>
            {hotel.traveler_safety?.pets_on_property ||
            hotel.traveler_safety?.no_on_site_parking ||
            hotel.traveler_safety?.dwelling_includes_stairs_or_staircases ||
            hotel.traveler_safety?.some_spaces_shared ||
            hotel.traveler_safety?.applicable_equipment_restrictions ? (
              <div>
                <h2>À savoir également</h2>
                {hotel.traveler_safety?.pets_on_property && (
                  <div className={styles.infos}>
                    <div>
                      <ErrorSvg />
                    </div>
                    <p>Présence d'animaux de compagnie sur place</p>
                  </div>
                )}
                {hotel.traveler_safety?.no_on_site_parking && (
                  <div className={styles.infos}>
                    <div>
                      <ErrorSvg />
                    </div>
                    <p>Pas d'espace de stationnement sur place</p>
                  </div>
                )}
                {hotel.traveler_safety?.nuisances_sonores_possibles && (
                  <div className={styles.infos}>
                    <div>
                      <ErrorSvg />
                    </div>
                    <p>Nuisances sonores possibles</p>
                  </div>
                )}
                {hotel.traveler_safety?.dwelling_includes_stairs_or_staircases && (
                  <div className={styles.infos}>
                    <div>
                      <ErrorSvg />
                    </div>
                    <p>Le logement comprend des marches ou escaliers</p>
                  </div>
                )}
                {hotel.traveler_safety?.some_spaces_shared && (
                  <div className={styles.infos}>
                    <div>
                      <ErrorSvg />
                    </div>
                    <p>Certains espaces sont partagés</p>
                  </div>
                )}
                {hotel.traveler_safety?.applicable_equipment_restrictions && (
                  <div className={styles.infos}>
                    <div>
                      <ErrorSvg />
                    </div>
                    <p>Restrictions applicables aux équipements</p>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default HealthAndSecurity
