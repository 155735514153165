import React, { useState, useContext } from 'react'
import { User } from '../../../../../../context/UserContext'
import BigErrorSvg from '../../../../../../components/svgs/BigErrorSvg'
import styles from '../../../../../../css/booking/errorServer.module.css'
import ConfirmEmail from '../../../../../infos/ConfirmEmail.info'

const ErrorServer = React.forwardRef((props, ref) => {
  const [message, setMessage] = useState(null)
  const user = useContext(User)

  React.useImperativeHandle(ref, () => ({
    OpenError: message => {
      setMessage(message)
    },
  }))
  return (
    <>
      {message && (
        <div>
          <div className={styles.container}>
            <div className={styles.content}>
              <div className={styles.notif_message}>
                <div>
                  <BigErrorSvg />
                </div>
                <div>
                  <h1>Un problème est survenu, veuillez réessayer</h1>
                  <p>{message}</p>
                </div>
              </div>
              <div>
                <button className={styles.icon} onClick={() => setMessage(null)}>
                  <i className='fa-solid fa-xmark'></i>
                </button>
              </div>
            </div>
          </div>
          {user && !user.isEmailVerified ? <ConfirmEmail /> : null}
        </div>
      )}
    </>
  )
})

export default ErrorServer
