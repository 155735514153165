import React, { useState } from 'react'
import styles from '../../../css/account/disabledAccount.module.css'
import Block1 from './Block1'
import Block2 from './Block2'
import Modal from '../../ui/Modal'
import CloseButton from '../../ui/CloseButton'

const Index = React.forwardRef(({}, ref) => {
  const [modal, setModal] = useState(false)
  const [active, setActive] = useState(1)
  const [raison1, setRaison1] = useState('')
  const [raison2, setRaison2] = useState('')

  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      setModal(true)
      setActive(1)
      setRaison1('')
      setRaison2('')
    },
  }))

  const handleclose = () => {
    setModal(false)
  }

  return (
    <>
      <Modal isOpen={modal} onClose={handleclose}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={() => setModal(false)} />
            <div></div>
          </div>
          {active === 1 && (
            <Block1
              handleclose={handleclose}
              setActive={setActive}
              raison1={raison1}
              setRaison1={setRaison1}
            />
          )}
          {active === 2 && (
            <Block2
              setActive={setActive}
              raison1={raison1}
              raison2={raison2}
              setRaison2={setRaison2}
            />
          )}
        </div>
      </Modal>
    </>
  )
})

export default Index
