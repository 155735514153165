import React, { useState } from 'react'
import styles from '../../../../css/traveler/travel/commentaire.module.css'
import axios from 'axios'
import LoadingPoint from '../../../LoadingPoint'
import Modal from '../../../ui/Modal'
import Toast from '../../../infos/toast'
import CloseButton from '../../../ui/CloseButton'
import Rating from './Rating'

const CommentModal = React.forwardRef(({ reservation, refetch }, ref) => {
  const [selectproprete, setSelectproprete] = useState(0)
  const [selectcommunication, setSelectcommunication] = useState(0)
  const [selectequipements, setSelectequipements] = useState(0)
  const [selectarrive, setSelectArrive] = useState(0)
  const [selectemplacement, setSelectemplacement] = useState(0)
  const [selectqualite_prix, setSelectqualite_prix] = useState(0)
  const [commentaire, setCommentaire] = useState('')
  const [modal, setModal] = useState(false)
  const [loadingbutton, setLoadingbutton] = useState(false)

  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      setModal(true)
    },
  }))

  const onSubmit = Event => {
    Event.preventDefault()
    setLoadingbutton(true)
    const data = {
      arrival: selectarrive,
      cleanliness: selectproprete,
      communication: selectcommunication,
      amenities: selectequipements,
      location: selectemplacement,
      value_for_money: selectqualite_prix,
      comment: commentaire,
    }
    axios
      .post(
        `/hotel_comment/addcomment/${reservation.hotel?._id}/${reservation.host_id}/${reservation._id}`,
        data
      )
      .then(async () => {
        setSelectproprete(0)
        setSelectcommunication(0)
        setSelectequipements(0)
        setSelectArrive(0)
        setSelectemplacement(0)
        setSelectqualite_prix(0)
        setCommentaire('')
        Toast.fire({ icon: 'success', text: 'Merci de partager votre avis' })
        await refetch()
      })
      .catch(() => {
        setLoadingbutton(false)
      })
      .finally(() => {
        setLoadingbutton(false)
        setModal(false)
      })
  }
  return (
    <>
      <Modal isOpen={modal} onClose={() => setModal(false)}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={() => setModal(false)} />
            <div></div>
          </div>

          <div className={styles.m_body}>
            <p className={styles.notice}>Qu'avez-vous pensé de la qualité de ce logement</p>
            <div className={styles.block_img}>
              <img src={reservation.hotel?.images[0].url} alt='couverture' />
              <h2>{reservation.hotel?.title}</h2>
            </div>
            <form onSubmit={onSubmit}>
              <div className={styles.container_avis}>
                <div className={styles.block_avis}>
                  <p>Propreté </p>
                  <Rating state={selectproprete} setState={setSelectproprete} />
                </div>
                <div className={styles.block_avis}>
                  <p>Communication </p>
                  <Rating state={selectcommunication} setState={setSelectcommunication} />
                </div>
                <div className={styles.block_avis}>
                  <p>Équipements </p>
                  <Rating state={selectequipements} setState={setSelectequipements} />
                </div>
                <div className={styles.block_avis}>
                  <p>Arrivée </p>
                  <Rating state={selectarrive} setState={setSelectArrive} />
                </div>
                <div className={styles.block_avis}>
                  <p>Emplacement </p>
                  <Rating state={selectemplacement} setState={setSelectemplacement} />
                </div>
                <div className={styles.block_avis}>
                  <p>Qualité-prix </p>
                  <Rating state={selectqualite_prix} setState={setSelectqualite_prix} />
                </div>
              </div>
              <div className={styles.form_content}>
                <label htmlFor='message'>Laissez un commentaire</label>
                <textarea
                  id='message'
                  maxLength={1000}
                  onChange={e => setCommentaire(e.target.value)}
                ></textarea>
                <p>{commentaire.length}/1000 caractères maximum</p>
                <div className={styles.button_block}>
                  {loadingbutton ? (
                    <button type='button' disabled className={styles.loading}>
                      <LoadingPoint />
                    </button>
                  ) : (
                    <button
                      type='submit'
                      disabled={commentaire.trim().length === 0}
                      className={commentaire.trim().length === 0 ? null : styles.active}
                    >
                      Envoyer vos avis
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  )
})
export default CommentModal
