import styles from '../../../css/becomeHostes/errorServer.module.css'

const ErrorServer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.block}>
        <svg
          viewBox='0 0 16 16'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'
          role='presentation'
          focusable='false'
        >
          <g>
            <path d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm0 10.2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm.8-6.6H7.2v5.2h1.6z'></path>
          </g>
        </svg>
        <p>Un problème est survenu. Merci de réessayer.</p>
      </div>
    </div>
  )
}
export default ErrorServer
