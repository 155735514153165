import { useState } from 'react'
import globalStyle from '../../../css/becomeHostes/globalStyle.module.css'
import styles from '../../../css/becomeHostes/tarif.module.css'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import LoadingPoint from '../../LoadingPoint'
import ErrorServer from '../errorServer/ErrorServer'
import useAddHome from '../../../hooks/useAddHome'
import { SATATUS_ANNONCE } from '../../../enums/logement/status.enums'
import HeaderForm from '../globals/HeaderForm'
import { DecrementCounter, IncrementCounter } from '../../svgs/TravelerCounter'
import { useQuery } from 'react-query'

const Form = () => {
  const [loading, setLoading] = useState(false)
  const [tarif, setTarif] = useState()
  const [serverError, setServerError] = useState(false)
  const { updateData } = useAddHome(setLoading, setServerError)

  const minmum = 10
  const maximum = 9454
  const history = useHistory()
  const location = useLocation()
  const page = location.pathname.split('/')[2]
  const id = location.pathname.split('/')[3]

  const { isLoading, error } = useQuery(`get-hotel-on-creation-${id}}`, () =>
    axios.get(`/hotel/oneAnnonce/${id}`).then(res => {
      setTarif(res.data.prices?.nightly_price)
      if (res.data.status !== SATATUS_ANNONCE.IN_PROGRESS) {
        history.push('/hosting')
      }
    })
  )

  const handleSubmit = () => {
    setLoading(true)
    const datas = {
      redirect_url: page,
      prices: {
        nightly_price: tarif,
        currency: 'USD',
      },
    }
    updateData(datas, '/become-a-host/question')
  }

  const incrementeTarif = () => {
    setTarif(t => t + 5)
  }

  const decrementeTarif = () => {
    setTarif(t => t - 5)
  }

  if (error || serverError) {
    return <ErrorServer />
  }

  return (
    <div className={globalStyle.left}>
      <HeaderForm step={10} />
      <div className={globalStyle.block_center}>
        <div className={globalStyle.block_form}>
          {isLoading ? (
            <LoadingPoint />
          ) : (
            <div className={globalStyle.annimcontent}>
              <div className={styles.block_flex}>
                <div className={styles.occup_count}>
                  <div>
                    <button
                      type='button'
                      disabled={tarif <= minmum ? true : false}
                      className={tarif <= minmum ? styles.disable : null}
                      onClick={decrementeTarif}
                    >
                      <DecrementCounter />
                    </button>
                  </div>
                  <div
                    className={
                      tarif < minmum || tarif > maximum
                        ? `${styles.inputs} ${styles.error}`
                        : styles.inputs
                    }
                  >
                    <input
                      type='tel'
                      autoComplete='off'
                      placeholder='00$'
                      value={tarif}
                      onChange={event => {
                        const valueFormated = event.target.value.replace(/\D/g, '')
                        setTarif(valueFormated)
                      }}
                    />
                  </div>
                  <div>
                    <button
                      type='button'
                      disabled={tarif >= maximum ? true : false}
                      className={tarif >= maximum ? styles.disable : null}
                      onClick={incrementeTarif}
                    >
                      <IncrementCounter />
                    </button>
                  </div>
                </div>
              </div>
              <div className={styles.price}>
                <h2>{tarif >= minmum && `${tarif}$`} par nuit</h2>
                {tarif < minmum || tarif > maximum ? (
                  <p>
                    Veuillez saisir un prix de base compris entre {minmum} $ et {maximum} $.
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={globalStyle.step_buttons}>
        <button
          type='button'
          onClick={() => history.push(`/become-a-host/description/${id}`)}
          className={globalStyle.retour}
        >
          Retour
        </button>
        {loading ? (
          <button disabled className={globalStyle.loading}>
            <LoadingPoint />
          </button>
        ) : (
          <button
            type='button'
            onClick={handleSubmit}
            disabled={tarif < minmum || tarif > maximum}
            className={
              tarif < minmum || tarif > maximum
                ? `${globalStyle.next} ${globalStyle.disable}`
                : globalStyle.next
            }
          >
            Suivant
          </button>
        )}
      </div>
    </div>
  )
}

export default Form
