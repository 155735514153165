import styles from '../../css/confirmations/statut.module.css'
import { STATUS_BOOKING } from '../../enums/booking/status.enums'

const StatusReservation = ({ user, reservation }) => {
  return (
    <div className={styles.statut}>
      <div className={styles.succeeded}>
        <div>
          <i className='far fa-check-circle'></i>
        </div>
        {reservation.status === STATUS_BOOKING.REQUEST ? (
          <h2>
            {user && <strong>{user.first_name},</strong>} votre demande de réservation est envoyée à
            l'hôte avec succès
          </h2>
        ) : (
          <h2>
            {user && <strong>{user.first_name},</strong>} votre réservation est confirmée avec
            succès
          </h2>
        )}
      </div>
    </div>
  )
}
export default StatusReservation
