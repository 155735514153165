import { useState, useEffect, useCallback, useContext } from 'react'
import styles from '../../css/navigations/bottomBar.module.css'
import { useLocation } from 'react-router'
import { User } from '../../context/UserContext'
import useNotification from '../../hooks/useNotification'
import Search from './bottom_bar/Search'
// import Travel from './bottom_bar/Travel'
import Favorite from './bottom_bar/Favorite'
import BecomHost from './bottom_bar/BecomHost'
import Message from './bottom_bar/Message'
import Connexion from './bottom_bar/Connexion'
import Profile from './bottom_bar/Profile'

function BottomNav() {
  const [y, setY] = useState(window.scrollY)
  const [disable, setDisable] = useState(false)
  const { arrayNonlueUser, totalLengthHote } = useNotification()

  const user = useContext(User)
  const location = useLocation()
  const path = location.pathname.split('/')[1]

  const handleNavigation = useCallback(
    e => {
      const window = e.currentTarget
      if (window.scrollY > 50) {
        if (
          y < window.scrollY ||
          window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 50
        ) {
          setDisable(true)
        } else if (y > window.scrollY) {
          setDisable(false)
        }
      } else {
        setDisable(false)
      }
      setY(window.scrollY)
    },
    [y]
  )

  useEffect(() => {
    setY(window.scrollY)
    window.addEventListener('scroll', handleNavigation)

    return () => {
      window.removeEventListener('scroll', handleNavigation)
    }
  }, [handleNavigation])

  return (
    <>
      <div className={disable ? `${styles.container} ${styles.disable}` : styles.container}>
        <div className={styles.block}>
          <Search path={path} />
          {/* {user && <Travel path={path} />} */}
          <Favorite path={path} />
          <BecomHost path={path} />
          <Message path={path} arrayNonlueUser={arrayNonlueUser} />
          {user === null ? (
            <Connexion path={path} />
          ) : (
            <Profile path={path} user={user} totalLengthHote={totalLengthHote} />
          )}
        </div>
      </div>
    </>
  )
}

export default BottomNav
