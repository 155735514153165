import axios from 'axios'

function useUpdateReservation() {
  const updateReservation = (id, error) => {
    const data = {
      booking_processing_error: {
        code: error.code,
        message: error.message,
        type: error.type,
      },
    }
    axios
      .patch(`/user_reservations/update_process/${id}`, data)
      .then(res => {})
      .catch(() => {})
  }

  return { updateReservation }
}
export default useUpdateReservation
