import styles from '../../../../../css/modehotes/annonce/details/equipement.module.css'
import useUpdateHotel from '../../../../../hooks/useUpdateHotel'
import Checkbox from '../../../../ui/Checkbox'

const Family = ({ equipements, setEquipements }) => {
  const { updateData } = useUpdateHotel()

  const array = [
    { name: 'Baignoire pour bébés', value: '78' },
    { name: 'Babyphone', value: '79' },
    { name: 'Barrières de sécurité pour bébé', value: '80' },
    { name: 'Recommandations de baby-sitters', value: '33' },
    { name: 'Jeux de plateau', value: '81' },
    { name: 'Table à manger', value: '82' },
    { name: 'Livres et jouets pour enfants', value: '83' },
    { name: 'Vaisselle pour enfants', value: '84' },
    { name: 'Lit pour bébé', value: '85' },
    { name: 'Pare-feu pour la cheminée', value: '86' },
    { name: 'Chaise haute', value: '87' },
    { name: 'Caches-prises', value: '88' },
    { name: 'Lit parapluie', value: '89' },
    { name: 'Protections sur les coins de tables', value: '90' },
    { name: 'Protections sur les fenêtres', value: '32' },
  ]
  const onchangeFilters = e => {
    if (e.target.checked === true) {
      setEquipements([...equipements, e.target.value])
      const datas = {
        amenities: [...equipements, e.target.value],
      }
      updateData(datas)
    } else {
      const newArr = [...equipements].filter(item => item !== e.target.value)
      setEquipements(newArr)
      const datas = {
        amenities: newArr,
      }
      updateData(datas)
    }
  }
  return (
    <div className={styles.container_equip}>
      <h4>Famille</h4>
      {array.map(item => (
        <div key={item.name} className={styles.checkbox_container}>
          <label htmlFor={item.name}>{item.name}</label>
          <div>
            <Checkbox
              id={item.name}
              value={item.value}
              checked={equipements.includes(item.value)}
              onChange={onchangeFilters}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default Family
