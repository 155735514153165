const Category = () => {
  const array = [
    {
      name: "Maison d'hôtes",
      value: 'maison_hote',
      descr: 'Un bâtiment séparé de la maison principale.',
    },
    {
      name: 'Suite',
      value: 'suite',
      descr:
        "Un logement avec une entrée privée à l'intérieur ou attenante à une autre structure plus grande.",
    },
    {
      name: 'Gîte à la ferme',
      value: 'gite_a_la_ferme',
      descr:
        'Un appartement à aménagement ouvert ou en résidence, qui peut comporter des murets intérieurs.',
    },
    {
      name: 'Logement de vacances',
      value: 'logement_de_vacance',
      descr:
        'Une location meublée avec une cuisine et une salle de bain pouvant offrir certains services aux voyageurs, comme un service de réception.',
    },
  ]
  return (
    <>
      {array.map(item => (
        <option value={item.value} key={item.value}>
          {item.name}{' '}
        </option>
      ))}
    </>
  )
}

export default Category
