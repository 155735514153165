import styles from '../../../../css/becomeHostes/photos.module.css'

const PhotoIncopatible = ({ imagesNotCompatible, setImagesNotCompatible }) => {
  const handleRemovePhoto = id => {
    setImagesNotCompatible(prevImages => prevImages.filter(photo => photo !== id))
  }
  return (
    <>
      {imagesNotCompatible.map(photo => (
        <div key={photo} className={styles.item_list}>
          <div className={`${styles.item_img} ${styles.incopatible}`}>
            <button type='button' className={styles.first} onClick={() => handleRemovePhoto(photo)}>
              <svg
                className={styles.remove_incompatible}
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 16 16'
                aria-hidden='true'
                role='presentation'
                focusable='false'
                fill='currentcolor'
              >
                <path d='M10 .38c.31 0 .57.23.62.53V2.5H14v2h-1V14a1 1 0 0 1-.88 1H4a1 1 0 0 1-1-.88V4.5H2v-2h3.38V1c0-.31.23-.57.53-.62H10zM6.12 4.5H4.88v9h1.25v-9zm2.5 0H7.38v9h1.25v-9zm2.5 0H9.88v9h1.24v-9zM9.38 1.62H6.62v.88h2.75v-.87z'></path>
              </svg>
            </button>
            <img src={photo} alt='' className={styles.incopatible} />
          </div>
          <div className={styles.message_error}>
            <p>Le fichier est trop petit. Importez une photo d'au moins 50KB.</p>
          </div>
        </div>
      ))}
    </>
  )
}
export default PhotoIncopatible
