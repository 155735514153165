import styles from '../../../../../css/search_room_result/filtre_2.module.css'
import { useSearchParams } from '../../../../../context/SearchParamContext'

function Bed() {
  const NumberLit = ['1', '2', '3', '4', '5', '6', '7', '8']
  const { params, updateParams } = useSearchParams()
  const newParams = new URLSearchParams(params.toString())

  const handleLit = e => {
    newParams.delete('page')
    newParams.set('number_of_beds', e.target.value)
    updateParams(newParams)
  }
  const AllLit = () => {
    newParams.delete('page')
    newParams.delete('number_of_beds')
    updateParams(newParams)
  }

  return (
    <div>
      <p>Lits</p>
      <div className={styles.radio_container}>
        <label>
          <input checked={!params.get('number_of_beds')} type='radio' onChange={AllLit} />
          <span>Tout</span>
        </label>
        {NumberLit.map(value => (
          <label key={value}>
            <input
              type='radio'
              value={value}
              checked={params.get('number_of_beds') === value}
              onChange={handleLit}
            />
            <span>
              {value}
              {value >= 8 && '+'}
            </span>
          </label>
        ))}
      </div>
    </div>
  )
}

export default Bed
