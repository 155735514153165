import { useState } from 'react'
import styles from '../../../css/modehotes/statistics/description.module.css'
import CloseButton from '../../ui/CloseButton'
import Modal from '../../ui/Modal'

const Description = ({ title, description }) => {
  const [modal, setModal] = useState(false)

  return (
    <div className={styles.container}>
      <div className={styles.container_title}>
        <button type='button' onClick={() => setModal(true)}>
          <svg
            viewBox='0 0 24 24'
            role='presentation'
            aria-hidden='true'
            focusable='false'
            style={{
              display: 'block',
              height: '16px',
              width: '16px',
              fill: 'rgb(72, 72, 72)',
            }}
          >
            <path
              d='m12 0c-6.63 0-12 5.37-12 12s5.37 12 12 12 12-5.37 12-12-5.37-12-12-12zm0 23c-6.07 0-11-4.92-11-11s4.93-11 11-11 11 4.93 11 11-4.93 11-11 11zm4.75-14c0 1.8-.82 2.93-2.35 3.89-.23.14-1 .59-1.14.67-.4.25-.51.38-.51.44v2a .75.75 0 0 1 -1.5 0v-2c0-.74.42-1.22 1.22-1.72.17-.11.94-.55 1.14-.67 1.13-.71 1.64-1.41 1.64-2.61a3.25 3.25 0 0 0 -6.5 0 .75.75 0 0 1 -1.5 0 4.75 4.75 0 0 1 9.5 0zm-3.75 10a1 1 0 1 1 -2 0 1 1 0 0 1 2 0z'
              fillRule='evenodd'
            ></path>
          </svg>
        </button>
      </div>

      <Modal isOpen={modal} onClose={() => setModal(false)}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={() => setModal(false)} />
            <h3>{title}</h3>
            <div></div>
          </div>
          <div className={styles.m_body}>
            <p>{description}</p>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Description
