import styles from '../../../../css/account/profile/about.module.css'
import ParagrapheTronque from '../../../ui/ParagrapheTronque'
import Verification from '../profile_descr/Verification'

const About = ({ userConnected, getUser, lengthMax }) => {
  return (
    <div>
      {!getUser?.isIdentityValid && getUser?.id === userConnected?.id ? (
        <Verification getUser={getUser} />
      ) : null}
      <div className={styles.about_me_desc}>
        {getUser?.about_me && <ParagrapheTronque text={getUser?.about_me} lengthMax={lengthMax} />}
      </div>
    </div>
  )
}

export default About
