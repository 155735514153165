import { useState, useRef } from 'react'
import styles from '../../../../../css/booking/annulation.module.css'
import HostPolicy from './politiques/HostPolicy'
import ReplacementPolicy from './politiques/ReplacementPolicy'
import DomagePolicy from './politiques/DomagePolicy'
import { confirmBooking } from '../../../../../utils/booking'
import Modal from '../../../../ui/Modal'
import CloseButton from '../../../../ui/CloseButton'
import { CANCELLATION_POLICY } from '../../../../../enums/logement/cancellation_policy.enums'

const Cancellation = ({ hotel }) => {
  const [modal, setModal] = useState(false)

  const politiqueRef = useRef()
  const HandlePolitiqueHote = () => {
    politiqueRef.current.OpenModal()
  }
  const gowifmiPolitique = useRef()
  const HandlePolitiqueHoteGowifmi = () => {
    gowifmiPolitique.current.OpenModal()
  }
  const gowifmiPolitiqueDommage = useRef()
  const HandlePolitiqueHoteGowifmiDommage = () => {
    gowifmiPolitiqueDommage.current.OpenModal()
  }

  return (
    <>
      {/*---------condition-----*/}
      <div className={styles.condition}>
        <div className={styles.asavoir_block}>
          <h4>Conditions d'annulation</h4>
          <div>
            {hotel?.regulations?.cancellation_policy === CANCELLATION_POLICY[0] && (
              <p>Remboursement intégral jusqu'à 1 jour avant l'arrivée.</p>
            )}
            {hotel?.regulations?.cancellation_policy === CANCELLATION_POLICY[1] && (
              <p>Remboursement intégral jusqu'à 5 jours avant l'arrivée.</p>
            )}
            {hotel?.regulations?.cancellation_policy === CANCELLATION_POLICY[2] && (
              <p>
                Remboursement intégral pour les annulations effectuées dans les 48 heures suivant la
                réservation, si la date d'arrivée intervient dans 14 jours ou plus. Remboursement à
                hauteur de 50 % pour les annulations effectuées au moins 7 jours avant la date
                d'arrivée. Aucun remboursement pour les annulations effectuées dans les 7 jours
                précédant la date d'arrivée.
              </p>
            )}
            <div className={styles.savoir_btn}>
              <button type='button' onClick={() => setModal(true)}>
                En savoir plus
              </button>
            </div>
          </div>
        </div>
        {/*---------politique-----*/}
        <p className={styles.politique}>
          En cliquant sur le bouton ci-dessous, j'accepte les conditions spécifiques de cette
          réservation,{' '}
          <span role='button' tabIndex='0' onClick={HandlePolitiqueHote}>
            Règlement intérieur de l'hôte
          </span>
          ,{' '}
          <span role='button' tabIndex='0' onClick={HandlePolitiqueHoteGowifmi}>
            Politique Gowifmi de remboursement et de remplacement d'hébergement
          </span>{' '}
          et je donne mon accord pour que Gowifmi{' '}
          <span role='button' tabIndex='0' onClick={HandlePolitiqueHoteGowifmiDommage}>
            débite mon mode de paiement
          </span>{' '}
          si je suis responsable de dommages.{' '}
          {!confirmBooking(hotel) &&
            "Je m'engage à payer le montant total affiché si l'hôte accepte ma demande de réservation."}
        </p>
      </div>
      {/*----modal---------*/}
      <Modal isOpen={modal} onClose={() => setModal(false)}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={() => setModal(false)} />
            <div></div>
          </div>
          <div className={styles.m_body}>
            <div>
              <h2>Conditions d'annulation</h2>
              <p>
                Avant de réserver, assurez-vous que les conditions d'annulation de cet hôte vous
                conviennent. Ces conditions d'annulation, s'appliquent même si vous annulez pour
                cause de maladie.
              </p>
              <div className={styles.infos}>
                {hotel?.regulations?.cancellation_policy === CANCELLATION_POLICY[0] && (
                  <div className={styles.content}>
                    <div>
                      <h3>Remboursement intégral</h3>
                      <p>
                        Si vous annulez jusqu'à 1 jour avant l'arrivée vous obtiendrez un
                        remboursement de 100 % du montant payé, après déduction des frais de
                        service.
                      </p>
                    </div>
                    <div>
                      <h3>Remboursement partiel</h3>
                      <p>
                        Si vous annulez après 24heures précédant votre date d'arrivée vous
                        obtiendrez le remboursement de toutes les nuits, à l'exception de la
                        première. Aucun remboursement de la première nuit ni des frais de service.
                      </p>
                    </div>
                  </div>
                )}
                {hotel?.regulations?.cancellation_policy === CANCELLATION_POLICY[1] && (
                  <div className={styles.content}>
                    <div>
                      <h3>Remboursement intégral</h3>
                      <p>
                        Si vous annulez jusqu'à 5 jours avant l'arrivée, vous obtiendrez un
                        remboursement de 100 % du montant payé, après déduction des frais de
                        service.
                      </p>
                    </div>
                    <div>
                      <h3>Remboursement partiel</h3>
                      <p>
                        Si vous annulez après 5 jours précédant votre date d'arrivée vous obtiendrez
                        un remboursement de 50 % du prix de chaque nuit, à l'exception de la
                        première. Aucun remboursement de la première nuit ni des frais de service.
                      </p>
                    </div>
                  </div>
                )}
                {hotel?.regulations?.cancellation_policy === CANCELLATION_POLICY[2] && (
                  <div className={styles.content}>
                    <div>
                      <h3>Remboursement intégral</h3>
                      <p>
                        Un remboursement intégral, après déduction des frais de service pour les
                        annulations effectuées dans les 48 heures suivant la réservation, à
                        condition que la date d'arrivée intervienne dans 14 jours ou plus.
                      </p>
                    </div>
                    <div>
                      <h3>Remboursement partiel</h3>
                      <p>
                        Remboursement à hauteur de 50 % pour les annulations effectuées au moins 7
                        jours avant la date d'arrivée
                      </p>
                    </div>
                    <div>
                      <h3>Aucun remboursement</h3>
                      <p>Cette réservation n'est pas remboursable.</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/*------ Règlement intérieur------*/}
      <HostPolicy ref={politiqueRef} hotel={hotel} />
      {/*------ Règlement intérieur------*/}
      <ReplacementPolicy ref={gowifmiPolitique} />
      {/*------ Règlement intérieur------*/}
      <DomagePolicy ref={gowifmiPolitiqueDommage} />
    </>
  )
}

export default Cancellation
