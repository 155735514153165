import styles from '../../../../css/traveler/travel/cancellation_policy.module.css'
import Modal from '../../../ui/Modal'
import CloseButton from '../../../ui/CloseButton'
import { CANCELLATION_POLICY } from '../../../../enums/logement/cancellation_policy.enums'

const PolicyModal = ({ cancellation_policy, modal, setModal }) => {
  return (
    <>
      <Modal isOpen={modal} onClose={() => setModal(false)}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={() => setModal(false)} />
            <div></div>
          </div>
          <div className={styles.m_body}>
            <div>
              <h2>Conditions d'annulation</h2>
              <div className={styles.infos}>
                {cancellation_policy === CANCELLATION_POLICY[0] && (
                  <div className={styles.content}>
                    <div>
                      <h3>Remboursement intégral</h3>
                      <p>
                        Si vous annulez jusqu'à 1 jour avant l'arrivée, vous obtiendrez un
                        remboursement de 100 % du montant payé, après déduction des frais de
                        service.
                      </p>
                    </div>
                    <div>
                      <h3>Remboursement partiel</h3>
                      <p>
                        Si vous annulez après 24heures précédant votre date d'arrivée vous
                        obtiendrez le remboursement de toutes les nuits, à l'exception de la
                        première. Aucun remboursement de la première nuit ni des frais de service.
                      </p>
                    </div>
                  </div>
                )}
                {cancellation_policy === CANCELLATION_POLICY[1] && (
                  <div className={styles.content}>
                    <div>
                      <h3>Remboursement intégral</h3>
                      <p>
                        Si vous annulez jusqu'à 5 jours avant l'arrivée vous obtiendrez un
                        remboursement de 100 % du montant payé, après déduction des frais de
                        service.
                      </p>
                    </div>
                    <div>
                      <h3>Remboursement partiel</h3>
                      <p>
                        Si vous annulez après 5 jours précédant votre date d'arrivée vous obtiendrez
                        un remboursement de 50 % du prix de chaque nuit, à l'exception de la
                        première. Aucun remboursement de la première nuit ni des frais de service.
                      </p>
                    </div>
                  </div>
                )}
                {cancellation_policy === CANCELLATION_POLICY[2] && (
                  <div className={styles.content}>
                    <div>
                      <h3>Remboursement partiel</h3>
                      <p>
                        Remboursement intégral pour les annulations effectuées dans les 48 heures
                        suivant la réservation, si la date d'arrivée intervient dans 14 jours ou
                        plus. Remboursement à hauteur de 50 % pour les annulations effectuées au
                        moins 7 jours avant la date d'arrivée. Aucun remboursement pour les
                        annulations effectuées dans les 7 jours précédant la date d'arrivée.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
export default PolicyModal
