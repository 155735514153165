import { useRef } from 'react'
import styles from '../../../css/becomeHostes/verifyListing.module.css'
import SvgIconRightDirector from '../../svgs/SvgIconRightDirector'
import AddPhoneNumber from '../../add_phone_number_user/AddPhoneNumber'
import VeriffButton from '../../accounts/verify_profiles/VeriffButton'
import ErrorSvg from '../../svgs/ErrorSvg'

function CheckSgv() {
  return (
    <svg
      className={styles.checkSgv}
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='presentation'
      focusable='false'
    >
      <path d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.159 4.869L6.67 9.355 4.42 7.105 3.289 8.236 6.67 11.62 12.291 6z'></path>
    </svg>
  )
}

const Information = ({ user }) => {
  const addnumberRef = useRef()
  const handleAddNumber = () => {
    addnumberRef.current.OpenModal()
  }
  return (
    <>
      <div className={styles.block_1}>
        <div className={styles.head}>
          <h1>Éléments essentiels à vérifier</h1>
          <p>
            Avant de publier votre annonce, nous avons besoin de confirmer certaines informations à
            votre sujet.
          </p>
        </div>
        <div>
          <div className={styles.link_block}>
            <div>
              <h2>Créez votre annonce</h2>
              <div className={styles.status}>
                <CheckSgv />
                <h3>Terminé</h3>
              </div>
            </div>
          </div>
          {user?.phone_number ? (
            <div className={styles.link_block}>
              <div>
                <h2>Confirmez votre numéro de téléphone</h2>
                <p>
                  Nous vous appellerons ou vous enverrons un SMS pour confirmer votre numéro. Les
                  tarifs SMS standards s'appliquent.
                </p>
                <div className={styles.status}>
                  <CheckSgv />
                  <h3>Terminé</h3>
                </div>
              </div>
            </div>
          ) : (
            <button type='button' className={styles.link_block} onClick={handleAddNumber}>
              <div>
                <h2>Confirmez votre numéro de téléphone</h2>
                <p>
                  Nous vous appellerons ou vous enverrons un SMS pour confirmer votre numéro. Les
                  tarifs SMS standards s'appliquent.
                </p>
                <div className={styles.status}>
                  <ErrorSvg />
                  <h3 className={styles.error_text}>Obligatoire</h3>
                </div>
              </div>
            </button>
          )}
          {user?.identitySubmitted ? (
            <div className={styles.link_block}>
              <div>
                <h2>Confirmez votre identité</h2>
                <p>
                  C'est un moyen facile d'aider les voyageurs à réserver votre logement avec
                  assurance.
                </p>
                <div className={styles.status}>
                  <CheckSgv />
                  <h3>Terminé</h3>
                </div>
              </div>
            </div>
          ) : (
            <VeriffButton className={styles.link_block}>
              <div>
                <h2>Confirmez votre identité</h2>
                <p>
                  C'est un moyen simple d'aider les voyageurs à réserver votre logement en toute
                  confiance.
                </p>
                <div className={styles.status}>
                  <ErrorSvg />
                  <h3 className={styles.error_text}>Obligatoire</h3>
                </div>
              </div>
              <SvgIconRightDirector />
            </VeriffButton>
          )}
        </div>
      </div>
      <AddPhoneNumber ref={addnumberRef} />
    </>
  )
}

export default Information
