import { useEffect, useContext } from 'react'
import { User } from '../../context/UserContext'
import { useHistory } from 'react-router-dom'
import globalStyle from '../../css/becomeHostes/globalStyle.module.css'
import Form from '../../components/become_host/questions/Form'
import DescriptionText from '../../components/become_host/globals/DescriptionText'
import { Helmet } from 'react-helmet-async'

const Question = () => {
  const user = useContext(User)
  const history = useHistory()
  useEffect(() => {
    if (user === null) {
      history.push('/host/homes')
    }
  }, [history, user])
  return (
    <>
      <Helmet>
        <title>Répondre aux dernière questions - Gowifmi</title>
      </Helmet>
      <div className={globalStyle.container}>
        <Form />
        <DescriptionText title='Plus que quelques questions…' desc='' />
      </div>
    </>
  )
}

export default Question
