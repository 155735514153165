import styles from '../../css/svgs/svgStyle.module.css'

const ValidSvg = ({ state }) => {
  return (
    <>
      <svg
        viewBox='0 0 16 16'
        className={state ? styles.yes : styles.no}
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'
        role='presentation'
        focusable='false'
      >
        <path d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.159 4.869L6.67 9.355 4.42 7.105 3.289 8.236 6.67 11.62 12.291 6z'></path>
      </svg>
    </>
  )
}

export default ValidSvg
