import { useState, useContext, useEffect } from 'react'
import styles from '../../../../css/booking/formulaire.module.css'
import { User } from '../../../../context/UserContext'
import { useQuery } from 'react-query'
import LoadingPoint from '../../../LoadingPoint'
import Cancellation from './components/Cancellation'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import InfosUser from './components/InfosUser'
import DateInvalid from './components/errors/DateInvalid'
import { appearance } from './components/appearance'
import SetupForm from './payments/SetupForm'
import axios from 'axios'
import { stripeApiKey } from '../../../../config/config'
import RequestNotif from './components/RequestNotif'

const CreateSetupIntent = ({
  payOnline,
  hotel,
  numberOfTravelerIsInvalid,
  handleVerifDisponibilite,
  startDateInvalid,
  endDateInvalid,
}) => {
  const user = useContext(User)
  const [stripePromise, setStripePromise] = useState('')
  const [clientSecret, setClientSecret] = useState('')
  const [setupIntent_id, setSetupIntent_id] = useState('')
  const [message, setMessage] = useState('')
  const [messageError, setMessageError] = useState(false)
  const [telephone, setTelephone] = useState(user ? user.phone_number : '')
  const [phoneError, setPhoneError] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const stripePromiseLoad = loadStripe(stripeApiKey)
    setStripePromise(stripePromiseLoad)
  }, [clientSecret])

  useQuery(`create-setup-intent`, () =>
    axios.post(`/stripe/create-setup-intent`).then(res => {
      setClientSecret(res.data.clientSecret)
      setSetupIntent_id(res.data.setupIntent_id)
    })
  )

  const options = {
    clientSecret,
    appearance,
  }

  return (
    <div className={styles.recap_form}>
      {clientSecret ? (
        <Elements options={options} stripe={stripePromise}>
          <SetupForm
            hotel={hotel}
            payOnline={payOnline}
            setupIntent_id={setupIntent_id}
            setLoading={setLoading}
            telephone={telephone}
            message={message}
            setMessageError={setMessageError}
            setPhoneError={setPhoneError}
          >
            <div>
              <InfosUser
                payOnline={payOnline}
                message={message}
                setMessage={setMessage}
                telephone={telephone}
                setTelephone={setTelephone}
                messageError={messageError}
                hotel={hotel}
                phoneError={phoneError}
              />
              <div>
                <RequestNotif payOnline={payOnline} />
                <Cancellation hotel={hotel} />
                {handleVerifDisponibilite() ? (
                  <DateInvalid
                    numberOfTravelerIsInvalid={numberOfTravelerIsInvalid}
                    startDateInvalid={startDateInvalid}
                    endDateInvalid={endDateInvalid}
                    hotel={hotel}
                  />
                ) : (
                  <div>
                    {loading ? (
                      <button
                        type='button'
                        className={`${styles.submit} ${styles.loader_btn}`}
                        disabled
                      >
                        <LoadingPoint />
                      </button>
                    ) : (
                      <div>
                        <button type='submit' className={styles.submit}>
                          Demande de réservation
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </SetupForm>
        </Elements>
      ) : (
        <div>
          <LoadingPoint />
        </div>
      )}
    </div>
  )
}

export default CreateSetupIntent
