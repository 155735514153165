import { useRef } from 'react'
import styles from '../../../../../../css/traveler/travel/detailReservation.module.css'
import VoyageurModal from './modifDisponibilites/VoyageurModal'
import { STATUS_BOOKING } from '../../../../../../enums/booking/status.enums'

const Voyageur = ({ reservation, id, refetch }) => {
  const modifierVoyageur = useRef()
  const modifVoyageurFunction = () => {
    modifierVoyageur.current.OpenModal()
  }

  return (
    <>
      <div className={styles.content}>
        <div className={styles.modification}>
          <h3>Les voyageurs</h3>
          <div>
            {reservation.status === STATUS_BOOKING.FUTURE ||
            reservation.status === STATUS_BOOKING.IN_PROGRESS ? (
              <button onClick={modifVoyageurFunction}>Modifier</button>
            ) : null}
          </div>
        </div>
        <p>Adultes : {reservation.reservation_infos?.total_adults}</p>
        <p>Enfants : {reservation.reservation_infos?.total_children}</p>
        <p>Bébés : {reservation.reservation_infos?.total_babies}</p>
        <p>Animaux : {reservation.reservation_infos?.total_pets}</p>
      </div>
      <VoyageurModal ref={modifierVoyageur} id={id} reservation={reservation} refetch={refetch} />
    </>
  )
}

export default Voyageur
