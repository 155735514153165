import styles from '../../../css/details/bottomBar.module.css'
import { Link } from 'react-router-dom'

const BottomBar = ({
  numberOfTravelerIsInvalid,
  handleVerifDisponibilite,
  VerifDateFunction,
  voyageurFunction,
  handleDetail,
  reduction,
  pricePerNightWithoutPromo,
  pricePerNightWithPromo,
  id,
  checkin,
  checkout,
  bebe,
  animal,
  adulte,
  enfant,
}) => {
  return (
    <div>
      {handleVerifDisponibilite() ? (
        <div className={styles.bottom_bar}>
          <div className={styles.price_normal}>
            <h3>{pricePerNightWithoutPromo}</h3>
            <span>/ nuit</span>
          </div>
          {numberOfTravelerIsInvalid ? (
            <button className={styles.nav_button} onClick={voyageurFunction}>
              Modifier les voyageurs
            </button>
          ) : (
            <button className={styles.nav_button} onClick={VerifDateFunction}>
              Vérifier la disponibilité
            </button>
          )}
        </div>
      ) : (
        <div className={styles.bottom_bar}>
          <div className={styles.price_book}>
            {reduction > 0 && <p className={styles.promo}>{pricePerNightWithoutPromo}</p>}
            <div className={styles.head}>
              <button onClick={handleDetail}>{pricePerNightWithPromo}</button>
              <span>/ nuit</span>
            </div>
          </div>
          <Link
            to={`/book/${id}?adults=${adulte}&children=${enfant}&infants=${bebe}&pets=${animal}&checkin=${checkin}&checkout=${checkout}`}
          >
            Réserver
          </Link>
        </div>
      )}
    </div>
  )
}

export default BottomBar
