import styles from '../../css/ui/closeButton.module.css'
import CloseSvg from '../svgs/CloseSvg'

const CloseButton = ({ onClick }) => {
  return (
    <button type='button' className={styles.close_btn} onClick={onClick}>
      <CloseSvg />
    </button>
  )
}

export default CloseButton
