import styles from '../../../css/signals/signal.module.css'

const Block1 = ({ handleclose, setActive, raison1, setRaison1 }) => {
  const raisons = [
    { value: '1', desciption: 'Elle est inexacte ou incorrecte' },
    { value: '2', desciption: "Ce n'est pas un véritable logement" },
    { value: '3', desciption: "C'est une arnaque" },
    { value: '4', desciption: 'Le contenu est choquant' },
    { value: 'other', desciption: 'Autre chose' },
  ]

  return (
    <>
      <div className={styles.m_body}>
        <div>
          <h3>Pourquoi signalez-vous cette annonce ?</h3>
          <p>Ces informations ne seront pas communiquées à l'hôte.</p>
        </div>
        <div className={styles.grid_system}>
          {raisons.map((item, i) => (
            <label htmlFor={item.value} className={styles.radio_container} key={i}>
              <p>{item.desciption}</p>
              <div>
                <input
                  type='radio'
                  id={item.value}
                  value={item.value}
                  checked={raison1 === item.value}
                  onChange={e => setRaison1(e.target.value)}
                />
              </div>
            </label>
          ))}
        </div>
      </div>
      <div className={styles.button_action}>
        <button className={styles.retour} onClick={handleclose}>
          Annuler
        </button>
        <button
          className={!raison1 ? `${styles.next} ${styles.disabled}` : styles.next}
          disabled={!raison1}
          onClick={() => setActive(2)}
        >
          Suivant
        </button>
      </div>
    </>
  )
}

export default Block1
