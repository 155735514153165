import styles from '../../../css/account/navbarMobile/navbarMobile.module.css'

const NavbarMobile = () => {
  return (
    <div className={styles.block}>
      <button onClick={() => window.history.back()}>
        <svg
          viewBox='0 0 32 32'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'
          role='presentation'
          focusable='false'
        >
          <g>
            <path d='m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932'></path>
          </g>
        </svg>
      </button>
    </div>
  )
}

export default NavbarMobile
