import { useContext } from 'react'
import styles from '../../../../css/modehotes/annonce/details/detail.module.css'
import { Helmet } from 'react-helmet-async'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { getAnnonce } from '../../../../redux/actions'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { User } from '../../../../context/UserContext'
import LoadingPoint from '../../../../components/LoadingPoint'
import ListAnnonce from '../../../../components/hote_mode/annonce/details/defaults/ListAnnonce'
import HeadDetail from '../../../../components/hote_mode/annonce/details/defaults/HeadDetail'
import Navigation from '../../../../components/hote_mode/annonce/details/defaults/Navigation'
import HeureArriver from '../../../../components/hote_mode/annonce/details/terms_and_conditions/arrival_times/ArrivalTime'
import HeureDepart from '../../../../components/hote_mode/annonce/details/terms_and_conditions/departure_times/DepartureTime'
import Arrival from '../../../../components/hote_mode/annonce/details/traveler_informations/arrival_instructions/Arrival'
import InfosWifi from '../../../../components/hote_mode/annonce/details/traveler_informations/infosWifi/InfosWifi'
import Itinerary from '../../../../components/hote_mode/annonce/details/traveler_informations/itinerarys/Itinerary'
import Address from '../../../../components/hote_mode/annonce/details/detailAnnonces/adresse/Address'
import TravelManual from '../../../../components/hote_mode/annonce/details/traveler_informations/travel_manuals/TravelManual'
import ErrorServer from '../../../errors/ErrorServer'
import HostModeLayout from '../../../../layouts/HostModeLayout'
import { SATATUS_ANNONCE } from '../../../../enums/logement/status.enums'

const TravelerInformationEdit = () => {
  const user = useContext(User)
  const history = useHistory()
  const location = useLocation()
  const path = location.pathname.split('/')[4]
  const dispatch = useDispatch()
  const annonce = useSelector(state => state.updateAnnonce)

  const { isLoading, error } = useQuery(`hote-get-annonce-details-${path}`, () =>
    axios.get(`/hotel/oneAnnonce/${path}`).then(res => {
      dispatch(getAnnonce(res.data))
      if (res.data.status === SATATUS_ANNONCE.IN_PROGRESS) {
        history.push(`/become-a-host/${res.data.redirect_url}/${res.data._id}`)
      }
      if (res.data.status === SATATUS_ANNONCE.SAVE) {
        history.push(`/verify-listing/${res.data._id}`)
      }
    })
  )

  if (error) {
    return <ErrorServer />
  }

  if (user === null) {
    history.push('/')
  }
  return (
    <HostModeLayout>
      <Helmet>
        <title>Modifier l'annonce : {annonce?.title ?? ''} - Gowifmi</title>
      </Helmet>
      <div className={styles.voyage_container}>
        <ListAnnonce />
        <div className={styles.block_details}>
          <HeadDetail annonce={annonce} />
          <Navigation />
          {isLoading ? (
            <div className={styles.loading}>
              <LoadingPoint />
            </div>
          ) : (
            <div>
              <HeureArriver annonce={annonce} titre='Informations avant la réservation' />
              <HeureDepart annonce={annonce} />
              <Itinerary annonce={annonce} />
              <Address annonce={annonce} />
              <TravelManual annonce={annonce} />
              <Arrival annonce={annonce} />
              <InfosWifi annonce={annonce} />
            </div>
          )}
        </div>
      </div>
    </HostModeLayout>
  )
}

export default TravelerInformationEdit
