import styles from '../../../../../css/modehotes/annonce/details/securiteVoyageur.module.css'
import Checkbox from '../../../../ui/Checkbox'

const NoiseNuisance = ({ nuisancesSonores, setNuisancesSonores }) => {
  return (
    <div className={styles.checkbox_container}>
      <div>
        <label htmlFor='nuisancesSonores'>Nuisances sonores possibles</label>
        <p>
          Il se peut que des nuisances sonores surviennent pendant le séjour des voyageurs. Par
          exemple : circulation, construction, entreprises à proximité.
        </p>
      </div>
      <div>
        <Checkbox
          id='nuisancesSonores'
          value={nuisancesSonores}
          checked={nuisancesSonores}
          onChange={() => setNuisancesSonores(!nuisancesSonores)}
        />
      </div>
    </div>
  )
}

export default NoiseNuisance
