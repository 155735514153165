import styles from '../../../css/details/other_hotel.module.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Navigation, Pagination } from 'swiper/modules'
import Slider from './pc/Slider'

const AnnoncePC = ({ hotel, setPrev, setNex, navigationPrevRef, navigationNextRef }) => {
  return (
    <Swiper
      modules={[Navigation, Pagination]}
      speed={500}
      breakpoints={{
        59: {
          slidesPerView: 1.5,
          spaceBetween: 15,
        },
        660: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        950: {
          slidesPerView: 3,
          spaceBetween: 15,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 15,
        },
      }}
      navigation={{
        prevEl: navigationPrevRef.current,
        nextEl: navigationNextRef.current,
      }}
      onBeforeInit={swiper => {
        swiper.params.navigation.prevEl = navigationPrevRef.current
        swiper.params.navigation.nextEl = navigationNextRef.current
      }}
      onSlideNextTransitionStart={() => setPrev(true)}
      onReachBeginning={() => setPrev(false)}
      onSlidePrevTransitionStart={() => setNex(true)}
      onReachEnd={() => setNex(false)}
      className={styles.mySwiper_block}
    >
      {hotel.map(item => (
        <SwiperSlide key={item._id}>
          <Slider item={item} />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default AnnoncePC
