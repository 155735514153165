import { useState, useEffect } from 'react'
import styles from '../../css/search_profiles/home.module.css'
import { Helmet } from 'react-helmet-async'
import DefaultLayout from '../../layouts/DefaultLayout'
import axios from 'axios'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import Header from '../../components/search_profiles/Header'
import ListProfil from '../../components/search_profiles/ListProfil'

const SearchProfil = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [searchParams, setSearchParams] = useState(window.location.search)
  const history = useHistory()
  const Params = new URLSearchParams(searchParams)
  const region = Params.get('place')

  useEffect(() => {
    const unlisten = history.listen(location => {
      setSearchParams(location.search)
    })

    return () => {
      window.scrollTo(0, 0)
      unlisten()
    }
  }, [history])

  const fetchData = async () => {
    const { data } = await axios.get(`/user/search${searchParams}`)
    return data
  }

  const { data = {}, isLoading, error } = useQuery([`search-profil`, searchParams], fetchData)

  return (
    <DefaultLayout footer={true} mobileBottomNavigate={true}>
      <Helmet>
        <title>Gowifmi · {region || 'Ajoutez une destination'} · Profils</title>
      </Helmet>
      <div className={styles.main_home}>
        <Header data={data} />
        <ListProfil data={data} isLoading={isLoading} error={error} />
      </div>
    </DefaultLayout>
  )
}

export default SearchProfil
