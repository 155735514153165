import { useEffect } from 'react'
import styles from '../../css/helps/feedback.module.css'
import DefaultLayout from '../../layouts/DefaultLayout'
import { Helmet } from 'react-helmet-async'

function Confirmation() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <DefaultLayout footer={true}>
      <Helmet>
        <title>Confirmation | Gowifmi</title>
      </Helmet>
      <div className={styles.confirmation}>
        <h1>Votre message a été pris en compte</h1>
        <p>
          Merci de nous avoir fait part de vos idées. Nous vous contacterons le plus tôt possible.
        </p>
      </div>
    </DefaultLayout>
  )
}
export default Confirmation
