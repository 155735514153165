import styles from '../../../../css/modehotes/facturation/invoice.module.css'
import PendingSvg from '../../../svgs/PendingSvg'
import CheckSvg from '../../../svgs/CheckSvg'

const RegisterBill = ({ data }) => {
  return (
    <div className={styles.block_item}>
      <h3>Facture</h3>
      {data.paid ? (
        <div className={styles.status_block}>
          <div className={styles.complet}>
            <span>Réglé</span> <CheckSvg />
          </div>
        </div>
      ) : (
        <div className={styles.status_block}>
          <div className={styles.incomplet}>
            <span>En attente</span> <PendingSvg />
          </div>
        </div>
      )}
      <div>
        <p>
          Pour {data.reservations.length} réservation{data.reservations.length > 1 ? 's' : ''}
        </p>
        {!data.paid && (
          <p>
            <a href={`${data.invoiceUrl}`} target='_blanck'>
              Payer la facture
            </a>
          </p>
        )}
        <p>
          <a href={`${data.paymentUrl}`}>Télécharger la facture</a>
        </p>
      </div>
    </div>
  )
}

export default RegisterBill
