import { useRef } from 'react'
import styles from '../../../css/booking/head.module.css'
import usePrice from '../../../hooks/usePrice'
import TravelerModal from '../verif_availability/TravelerModal'
import CalendarModal from '../verif_availability/CalendarModal'
import ButtonTertiary from '../../ui/ButtonTertiary'

const Head = ({ hotel, voyageur, bebe, animal, checkin, checkout }) => {
  const { reduction } = usePrice(hotel)

  const voyageurRef = useRef()
  const VerifVoyageurFunction = () => {
    voyageurRef.current.OpenModal()
  }
  const dateRef = useRef()
  const VerifDateFunction = () => {
    dateRef.current.OpenModal()
  }

  return (
    <div>
      {reduction > 0 && (
        <div className={styles.reduction_content}>
          <div className={styles.bonne_affaire}>
            <div className={styles.affaire_text}>
              <h4>Bonne affaire.</h4>
              <p>
                À vos dates, le tarif est inférieur de {reduction} % au tarif moyen par nuit au
                cours des 3 derniers mois.
              </p>
            </div>
            <div className={styles.affaire_img}>
              <svg
                viewBox='0 0 48 48'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
              >
                <g stroke='none'>
                  <path
                    d='M24.556 8H9a1 1 0 0 0-.993.883L8 9v15.556a1 1 0 0 0 .206.608l.087.1 17.213 17.213a1 1 0 0 0 1.32.083l.094-.083L42.477 26.92a1 1 0 0 0 .083-1.32l-.083-.094L25.263 8.293a1 1 0 0 0-.575-.284L24.556 8z'
                    fillOpacity='.2'
                  ></path>
                  <path d='M24.556 4A5 5 0 0 1 27.9 5.282l.192.182 17.213 17.214a5 5 0 0 1 .172 6.89l-.172.18L29.75 45.306a5 5 0 0 1-6.89.172l-.181-.172L5.464 28.092a5 5 0 0 1-1.457-3.271L4 24.556V9a5 5 0 0 1 4.783-4.995L9 4h15.556zm0 2H9a3 3 0 0 0-2.995 2.824L6 9v15.556a3 3 0 0 0 .743 1.977l.136.145L24.092 43.89a3 3 0 0 0 4.099.135l.144-.135L43.89 28.335a3 3 0 0 0 .135-4.1l-.135-.143L26.678 6.879a3 3 0 0 0-1.924-.872L24.556 6zM13 10a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z'></path>
                </g>
              </svg>
            </div>
          </div>
        </div>
      )}
      <div>
        <div className={styles.block}>
          <div className={styles.modifier_date}>
            <div>
              <h5>Dates</h5>
              <p>
                {checkin} – {checkout}
              </p>
            </div>
            <ButtonTertiary type='button' onClick={VerifDateFunction} className={styles.button}>
              Modifier
            </ButtonTertiary>
          </div>
          <div className={styles.modifier_date}>
            <div>
              <h5>Voyageurs</h5>
              <p>
                {voyageur} {voyageur > 1 ? 'Voyageurs' : 'Voyageur'}
                {bebe > 0 && `, ${bebe} ${bebe > 1 ? 'Bébés' : 'Bébé'}`}{' '}
                {animal > 0 && `, ${animal} ${animal > 1 ? 'Animaux' : 'Animal'}`}
              </p>
            </div>
            <ButtonTertiary type='button' onClick={VerifVoyageurFunction} className={styles.button}>
              Modifier
            </ButtonTertiary>
          </div>
        </div>
      </div>
      <TravelerModal ref={voyageurRef} hotel={hotel} />
      <CalendarModal ref={dateRef} hotel={hotel} />
    </div>
  )
}

export default Head
