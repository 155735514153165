import styles from '../../../../css/modehotes/inbox/detailMessageHote.module.css'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import axios from 'axios'
import momentTimeZone from 'moment-timezone'
import LoadingPoint from '../../../LoadingPoint'
import { useWebSocket } from '../../../../hooks/useWebSocket'

const Allmessage = ({ filter, path, user }) => {
  const userTimezone = momentTimeZone.tz.guess()
  const {
    data: filtermessages = [],
    isLoading,
    refetch,
  } = useQuery(`get-allmessage-detail-hote-${path}}`, () =>
    axios.get(`/hotemessage/filtermessage?filter=${filter}`).then(res => {
      return res.data
    })
  )

  useWebSocket(() => {
    refetch()
  })

  return (
    <div className={styles.liste_block}>
      {isLoading ? (
        <div className={styles.loading_container}>
          <LoadingPoint />
        </div>
      ) : (
        <>
          {filtermessages.length > 0 ? (
            <div className={styles.liste}>
              {filtermessages.map(message => (
                <Link
                  to={`/hosting/inbox/detail/${message._id}?filter=${filter}`}
                  key={message._id}
                  className={
                    message._id === path
                      ? `${styles.liste_content} ${styles.active}`
                      : styles.liste_content
                  }
                >
                  <div>
                    <div className={styles.img_block}>
                      {message.user?.picture ? (
                        <img src={message.user?.picture} alt='' />
                      ) : (
                        <h2>{message.user?.first_name?.slice(0, 1)}</h2>
                      )}
                    </div>
                  </div>
                  <div className={styles.profil_text}>
                    <div className={!message.message_box[0].host_read ? styles.non_lue : null}>
                      <h5>
                        {message.user?.first_name} - <span>{message.hotel?.title ?? 'Hôte'}</span>
                      </h5>
                      <div className={styles.last_message}>
                        {message.message_box[0].userId?.id === user?.id && <span>Vous:</span>}
                        <p>{message.message_box[0].text}</p>
                      </div>
                      {message.message_box[0].host_read ? (
                        <span className={styles.statut}>Lu</span>
                      ) : (
                        <span className={styles.statut}>Non lu</span>
                      )}
                    </div>
                    <h6>
                      {momentTimeZone
                        .utc(message.message_box[0].timestamp)
                        .tz(userTimezone)
                        .calendar()}
                    </h6>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <div className={styles.no_result}>
              <p>Aucun resultat</p>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default Allmessage
