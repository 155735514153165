import React, { useState, useEffect } from 'react'
import styles from '../../../css/details/verifs/date.module.css'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import Calendar from 'react-calendar'
import { addDays } from 'date-fns'
import 'react-calendar/dist/Calendar.css'
import useDisponibilite from '../../../hooks/useDisponibilite'
import ErrorSvg from '../../svgs/ErrorSvg'
import Modal from '../../ui/Modal'
import CloseButton from '../../ui/CloseButton'
import useModalByUrl from '../../../hooks/useModalByUrl'

const CalendarModal = React.forwardRef(({ hotel }, ref) => {
  const location = useLocation()
  const id = location.pathname.split('/')[2]
  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  const adulte = Params.get('adults') || 1
  const enfant = Params.get('children') || 0
  const bebe = Params.get('infants') || 0
  const animal = Params.get('pets') || 0
  const checkin = new Date(Params.get('checkin')) || new Date()
  const checkout = new Date(Params.get('checkout')) || addDays(new Date(), 1)
  const [dateRange, setDateRange] = useState([checkin, checkout])

  const modalValue = 'CALENDAR_MODAL'
  const { modalState, handleOpenModal, handleCloseModal } = useModalByUrl(modalValue)

  const history = useHistory()
  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      handleOpenModal()
    },
  }))

  useEffect(() => {
    window.scrollTo(0, 0)
    return history.listen(location => {
      const Query = location.search
      const Params = new URLSearchParams(Query)
      const checkin = new Date(Params.get('checkin')) || new Date()
      const checkout = new Date(Params.get('checkout')) || addDays(new Date(), 1)
      setDateRange([checkin, checkout])
    })
  }, [])

  const [startDate, endDate] = dateRange
  const date_start = new Date(startDate).setHours(0, 0, 0, 0)
  const date_end = new Date(endDate).setHours(0, 0, 0, 0)
  const total_days = (date_end - date_start) / (1000 * 60 * 60 * 24)
  const nuit = Math.round(total_days)

  const { dateIsinvalid, dateIsinvalidBloquer, startDateInvalid, endDateInvalid } =
    useDisponibilite(hotel)

  const Maxdate = moment(new Date())
    .add(hotel.availability_calendar?.availability_range, 'month')
    .format('YYYY-MM-DD')

  const minNuit = hotel.stay_duration?.minimum
  const maxNuit = hotel.stay_duration?.maximum
  const resercher = value => {
    setDateRange(value)
    history.replace(
      `/rooms/${id}?children=${enfant}&adults=${adulte}&infants=${bebe}&pets=${animal}&checkin=${moment(
        value[0]
      ).format('YYYY-MM-DD')}&checkout=${moment(value[1]).format('YYYY-MM-DD')}`
    )
  }

  return (
    <>
      <Modal isOpen={modalState} onClose={handleCloseModal}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={handleCloseModal} />
            <div></div>
          </div>
          <div className={styles.m_body}>
            <div className={styles.dateContent}>
              {nuit < 1 ? (
                <div>
                  <h4>Sélectionnez les dates</h4>
                </div>
              ) : (
                <div>
                  <h4>
                    {nuit} {nuit > 1 ? 'nuits' : 'nuit'} à {hotel?.address?.city}
                  </h4>
                  <div className={styles.calendar_head}>
                    <div className={styles.gauch}>
                      <p>
                        {moment(startDate).format('ll')} – {moment(endDate).format('ll')}
                      </p>
                      {dateIsinvalid || dateIsinvalidBloquer ? (
                        <div className={styles.block_error}>
                          <div>
                            <ErrorSvg />
                          </div>
                          <p>Vous ne pouvez pas sélectionner ces dates</p>
                        </div>
                      ) : null}
                      {startDateInvalid ? (
                        <div className={styles.block_error}>
                          <div>
                            <ErrorSvg />
                          </div>
                          <p>Jour d'arrivée non autorisé</p>
                        </div>
                      ) : null}
                      {endDateInvalid ? (
                        <div className={styles.block_error}>
                          <div>
                            <ErrorSvg />
                          </div>
                          <p>Jour de départ non autorisé</p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.droit}>
                <p>
                  Durée minimale du séjour · {minNuit} nuit{minNuit > 1 && 's'}
                </p>
                <p>
                  Durée maximale du séjour · {maxNuit} nuit{maxNuit > 1 && 's'}
                </p>
              </div>
            </div>
            <Calendar
              onChange={value => resercher(value)}
              value={dateRange}
              minDate={new Date()}
              maxDate={new Date(Maxdate)}
              selectRange={true}
              showNeighboringMonth={false}
              view='month'
              tileDisabled={({ date }) =>
                hotel.reservation_dates?.includes(moment(date).format('YYYY-MM-DD'))
              }
              tileClassName={styles.tileClassName}
            />
          </div>
          <div className={styles.btn_action}>
            <button onClick={handleCloseModal}>Fermer</button>
            <button onClick={handleCloseModal}>Appliquer</button>
          </div>
        </div>
      </Modal>
    </>
  )
})
export default CalendarModal
