import styles from '../../../css/affiliations/home/section_1.module.css'
import customer from '../../../assets/become_host/gift1.png'

const Section1 = ({}) => {
  return (
    <div className={styles.container}>
      <h3>Comment obtenir du crédit pour mes parrainages?</h3>

      <div className={styles.flex_layout}>
        <div className={styles.img_container}>
          <div className={styles.img_block}>
            <img src={customer} alt='' />
          </div>
        </div>
        <div className={styles.text_block}>
          <p>
            Tous les utilisateurs doivent être connectés à leur compte Gowifmi pour générer leur
            lien de parrainage
          </p>
          <div className={styles.acceuil_voyage}>
            <p>
              Afin que nous puissions associer un parrainage à vous, la personne parrainée devra
              créer une annonce pour un logement en utilisant le lien unique que vous lui aurez
              envoyé
            </p>
          </div>
          <div className={styles.acceuil_voyage}>
            <p>
              Pour recevoir vos paiements,{' '}
              <a href='/account-settings/versement' target='_blanck'>
                Ajouter un mode de versement
              </a>{' '}
              dans la section Paiements et versements de votre compte. Le mode de versement que vous
              sélectionnez s'appliquera à tous les futurs versements jusqu'à ce que vous le modifiez
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section1
