import axios from 'axios'

export const GET_USER = 'GET_USER'
export const USER_EXISTE = 'USER_EXISTE'
// export const UPDATE_USER = 'UPDATE_USER'
export const GET_FAVORIS = 'GET_FAVORIS'
export const UPLOAD_PROFIL = 'UPLOAD_PROFIL'
export const UPLOAD_COVER_IMAGE = 'UPLOAD_COVER_IMAGE'
export const SEARCH_MAPS = 'SEARCH_MAPS'
export const TOTAL_HOTEL = 'TOTAL_HOTEL'
export const GET_ANNONCE = 'GET_ANNONCE'
export const UPDATE_ANNONCE = 'UPDATE_ANNONCE'
//****get de l'utilisateur connecter */
export function getuser(data) {
  return {
    type: GET_USER,
    payload: data,
  }
}
//****get de l'utilisateur connecter */
export function userExiste(data) {
  return {
    type: USER_EXISTE,
    payload: data,
  }
}

//****upload image de profil de user*/
export function uploadProfile(data) {
  return dispatch => {
    dispatch({ type: UPLOAD_PROFIL, payload: data })
  }
}
//****upload image de profil de user*/
export function uploadCoverImage(data) {
  return dispatch => {
    dispatch({ type: UPLOAD_COVER_IMAGE, payload: data })
  }
}
//****rafraichir le componente listeHotel apres avoir appliquer un filter */
export function search_by_map(data) {
  return dispatch => {
    dispatch({
      type: SEARCH_MAPS,
      payload: { status: data.status, scroll_to_top: data.scrollToTop },
    })
  }
}
//****get tout les etablissement apres la recherch et filter*/
export function totalHotel(data) {
  return dispatch => {
    dispatch({ type: TOTAL_HOTEL, payload: data })
  }
}
//****get les annonces de l'hote connecter */
export function getAnnonce(data) {
  return {
    type: GET_ANNONCE,
    payload: data,
  }
}
//****getFavoris
export function getFavoris() {
  return dispatch => {
    return axios
      .get(`/favoris`)
      .then(res => {
        dispatch({ type: GET_FAVORIS, payload: res.data })
      })
      .catch(() => {})
  }
  // return {
  //   type: GET_FAVORIS,
  //   payload: data,
  // }
}
