import styles from '../../../../css/search_room_result/filtre_2.module.css'
import { useSearchParams } from '../../../../context/SearchParamContext'
import Checkbox from '../../../ui/Checkbox'

const OptionReservation = () => {
  const array = [
    { name: 'Payer sur Gowifmi', value: 'pay_online' },
    { name: "Payer chez l'hôte", value: 'pay_at_host' },
    { name: 'Réservation instantanée', value: 'instant_booking' },
    { name: 'Annulation gratuite', value: 'cancellation_policy' },
    { name: 'Arrivée autonome', value: 'arrival_flexible' },
  ]

  const { params, updateParams } = useSearchParams()
  const newParams = new URLSearchParams(params.toString())

  const onchangeFilters = e => {
    if (!e.target.checked === true) {
      newParams.delete('page')
      newParams.delete(e.target.value)
    } else {
      newParams.delete('page')
      newParams.append(e.target.value, 'true')
    }
    updateParams(newParams)
  }
  return (
    <div className={styles.block_section_filter}>
      <h3>Options de réservation</h3>
      <div className={styles.grid_system}>
        {array.map(item => (
          <div key={item.name} className={styles.checkbox_container}>
            <div>
              <Checkbox
                id={item.name}
                value={item.value}
                checked={params.has(item.value)}
                onChange={onchangeFilters}
              />
            </div>
            <label htmlFor={item.name}>{item.name}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default OptionReservation
