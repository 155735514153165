import { useState, useEffect } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import Formulaire from './components/Formulaire'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const Address = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [fullAddress, setFullAddress] = useState('')
  const [appartement, setAppartement] = useState('')
  const [ville, setVille] = useState('')
  const [etat, setEtat] = useState('')
  const [codePostal, setCodePostal] = useState('')
  const [pays, setPays] = useState('')
  const [countryAbbreviation, setCountryAbbreviation] = useState('')
  const [coords, setCoords] = useState(null)

  const gowifmiPublished = annonce.gowifmi_publish_date

  const { loading, updateData } = useUpdateHotel(setOpen)

  const handleSubmit = e => {
    e.preventDefault()
    const datas = {
      location: {
        coordinates: [coords.lng, coords.lat],
      },
      address: {
        full_address: fullAddress,
        apartment_name: appartement,
        city: ville,
        state: etat,
        postal_code: codePostal,
        country: pays,
        country_code: countryAbbreviation,
      },
    }
    if (!gowifmiPublished) {
      updateData(datas)
    }
    return
  }
  useEffect(() => {
    setFullAddress(annonce.address?.full_address || '')
    setAppartement(annonce.address?.apartment_name)
    setVille(annonce.address?.city)
    setEtat(annonce.address?.state)
    setCodePostal(annonce.address?.postal_code)
    setPays(annonce.address?.country)
    setCountryAbbreviation(annonce.address?.country_abbreviation)
    setCoords({ lat: annonce.location?.coordinates[1], lng: annonce.location?.coordinates[0] })
  }, [annonce])
  const openFuction = () => {
    setFullAddress(annonce.address?.full_address || '')
    setAppartement(annonce.address?.apartment_name)
    setVille(annonce.address?.city)
    setEtat(annonce.address?.state)
    setCodePostal(annonce.address?.postal_code)
    setPays(annonce.address?.country)
    setCountryAbbreviation(annonce.address?.country_abbreviation)
    setCoords({ lat: annonce.location?.coordinates[1], lng: annonce.location?.coordinates[0] })
    setOpen(true)
  }
  const reset = () => {
    setFullAddress('')
    setAppartement('')
    setVille('')
    setEtat('')
    setPays('')
    setCountryAbbreviation('')
    setCodePostal('')
    setCoords(null)
    setOpen(false)
  }
  return (
    <div className={styles.block}>
      {!open ? (
        <Section title='Adresse' handleOpen={openFuction}>
          <p>{annonce.address?.full_address}</p>
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title='Adresse' handleReset={reset}></ContenHead>
          <form onSubmit={handleSubmit}>
            <div className={styles.body}>
              <Formulaire
                gowifmiPublished={annonce.gowifmi_publish_date}
                appartement={appartement}
                setAppartement={setAppartement}
                fullAddress={fullAddress}
                setFullAddress={setFullAddress}
                ville={ville}
                setVille={setVille}
                etat={etat}
                setEtat={setEtat}
                codePostal={codePostal}
                setCodePostal={setCodePostal}
                pays={pays}
                setPays={setPays}
                setCountryAbbreviation={setCountryAbbreviation}
                setCoords={setCoords}
              />
            </div>
            <BottomForm
              disabled={fullAddress.length < 1 || ville.length < 1 || gowifmiPublished}
              loading={loading}
              handleReset={reset}
            />
          </form>
        </div>
      )}
    </div>
  )
}

export default Address
