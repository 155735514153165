import { useState } from 'react'
import styles from '../../../../../css/modehotes/annonce/filtres/filtre.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import ServiceDeBase from './equipements/Service'
import Caracteristique from './equipements/Caracteristique'
import Securite from './equipements/Securite'
import Checkbox from '../../../../ui/Checkbox'

const Equipement = () => {
  const [voirPlus, setVoirPlus] = useState(false)

  const array = [
    { name: 'Bord de mer', value: '5' },
    { name: 'Wifi', value: '1' },
    { name: 'Parking gratuit sur place', value: '2' },
    { name: 'Lave-linge', value: '6' },
    { name: 'Piscine', value: '3' },
    { name: 'Cuisine', value: '4' },
  ]
  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  URLSearchParams.prototype.remove = function (key, value) {
    const entries = this.getAll(key)
    const newEntries = entries.filter(entry => entry !== value)
    this.delete(key)
    newEntries.forEach(newEntry => this.append(key, newEntry))
  }
  const history = useHistory()
  const location = useLocation()
  const path = location.pathname
  const onchangeFilters = e => {
    if (e.target.checked === true) {
      Params.append('amenities', e.target.value)
      history.push(`${path}?${Params}`)
    } else {
      Params.remove('amenities', e.target.value)
      history.push(`${path}?${Params}`)
    }
  }
  return (
    <div className={styles.block_section_filter}>
      <h3>Équipements</h3>
      <div className={styles.equipement}>
        <div>
          <p>Équipements les plus recherchés</p>
          <div className={styles.grid_system}>
            {array.map(item => (
              <div key={item.name} className={styles.checkbox_container}>
                <div>
                  <Checkbox
                    id={item.name}
                    value={item.value}
                    checked={Params.getAll('amenities').includes(item.value)}
                    onChange={onchangeFilters}
                  />
                </div>
                <label htmlFor={item.name}>{item.name}</label>
              </div>
            ))}
          </div>
        </div>
        {voirPlus && (
          <>
            <ServiceDeBase />
            <Caracteristique />
            <Securite />
          </>
        )}
        <div>
          <button onClick={() => setVoirPlus(!voirPlus)}>
            {voirPlus ? 'Afficher moins' : 'Afficher plus'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Equipement
