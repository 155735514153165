import { useEffect, useState, useContext } from 'react'
import styles from '../../../css/modehotes/facturation/invoice.module.css'
import axios from 'axios'
import moment from 'moment'
import { User } from '../../../context/UserContext'
import LoadingPoint from '../../../components/LoadingPoint'
import { useInfiniteQuery } from 'react-query'
import { useInView } from 'react-intersection-observer'
import SelectDate from '../../../components/hote_mode/statistics/SelectDate'
import RegisterBill from './invoices/RegisterBill'

const Invoice = () => {
  const [isFetching, setIsFetching] = useState(false)
  const [selectedDate, setSelectedDate] = useState(() => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1 // Les mois sont indexés de 0 à 11, donc ajoutez 1
    return `${year}-${month.toString().padStart(2, '0')}` // 'YYYY-MM'
  })

  const yearMonthDate = moment(selectedDate, 'YYYY-MM').format('MMMM YYYY')

  const user = useContext(User)

  const fetchHistory = ({ pageParam = 1 }) =>
    axios
      .get(`/bill/invoice_generate?host_id=${user?.id}&page=${pageParam}&date=${selectedDate}`)
      .then(res => res.data)

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    ['get-invoices', selectedDate],
    fetchHistory,
    {
      getNextPageParam: (lastPage, pages) => {
        const morePagesExist = lastPage.invoices.length === 10
        if (!morePagesExist) return false
        return pages.length + 1
      },
    }
  )

  const { ref, inView } = useInView({
    threshold: 1.0,
  })

  useEffect(() => {
    if (inView && hasNextPage && !isFetching && !isFetchingNextPage) {
      setIsFetching(true)
      fetchNextPage().finally(() => setIsFetching(false))
    }
  }, [inView, hasNextPage, isFetchingNextPage])

  return (
    <>
      <SelectDate selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
      {isLoading ? (
        <div>
          <LoadingPoint />
        </div>
      ) : (
        <>
          {data?.pages[0]?.invoices.length > 0 ? (
            <>
              {data.pages.map((page, index) => (
                <div key={index} className={styles.container}>
                  {page.invoices.map(item => (
                    <RegisterBill key={item._id} data={item} />
                  ))}
                </div>
              ))}
              <div ref={ref} style={{ height: 1 }} />
              {isFetchingNextPage && <LoadingPoint />}
            </>
          ) : (
            <p>Vous n'avez pas de factures a payé pour {yearMonthDate}</p>
          )}
        </>
      )}
    </>
  )
}

export default Invoice
