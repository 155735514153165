import styles from '../../../css/details/equipement.module.css'
import CheckAmenitieSvg from './components/CheckAmenitieSvg'

const Entertainment = ({ hotel }) => {
  const { amenities } = hotel

  return (
    <div className={styles.container_equip}>
      <h4>Divertissement</h4>
      {amenities?.includes('29') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Connexion Ethernet</p>
        </div>
      )}
      {amenities?.includes('30') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>
            TV HD avec Disney+, HBO Max, Netflix, télévision par câble haut de gamme, abonnement
            standard au câble
          </p>
        </div>
      )}
      {amenities?.includes('55') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>
            Adapté aux événements, le logement permet d'accueillir un groupe de 25 personnes ou plus
          </p>
        </div>
      )}
      {amenities?.includes('31') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Livres et de quoi lire</p>
        </div>
      )}
      {amenities?.includes('73') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Console de jeu</p>
        </div>
      )}
      {amenities?.includes('74') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Piano</p>
        </div>
      )}
      {amenities?.includes('75') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Table à ping pong</p>
        </div>
      )}
      {amenities?.includes('64') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Billard</p>
        </div>
      )}
      {amenities?.includes('76') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Tourne disque</p>
        </div>
      )}
      {amenities?.includes('77') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Système audio</p>
        </div>
      )}
      {amenities?.includes('10') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Télévision</p>
        </div>
      )}
      {amenities?.includes('65') && (
        <div className={styles.liste}>
          <CheckAmenitieSvg />
          <p>Appareils de fitness</p>
        </div>
      )}
    </div>
  )
}

export default Entertainment
