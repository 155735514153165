import { useEffect, useContext } from 'react'
import globalStyle from '../../css/becomeHostes/globalStyle.module.css'
import { User } from '../../context/UserContext'
import { useHistory } from 'react-router-dom'
import Form from '../../components/become_host/type_property_details/Form'
import DescriptionText from '../../components/become_host/globals/DescriptionText'
import { Helmet } from 'react-helmet-async'

const TypePropertyDetail = () => {
  const user = useContext(User)
  const history = useHistory()
  useEffect(() => {
    if (user === null) {
      history.push('/host/homes')
    }
  }, [history, user])
  return (
    <>
      <Helmet>
        <title>Choisissez un type d'hébergement proposé - Gowifmi</title>
      </Helmet>
      <div className={globalStyle.container}>
        <Form />
        <DescriptionText
          title='Parmi les propositions suivantes, laquelle décrit le mieux votre logement ?'
          desc=''
        />
      </div>
    </>
  )
}

export default TypePropertyDetail
