const STATUS_MESSAGE = {
  REQUEST_INFO: 'request_info',
  RESERVATION_REQUEST: 'reservation_request',
  DIRECT_RESERVATION: 'direct_reservation',
  MODIFICATION_REQUEST: 'modification_request',
  REQUEST_INFO_FROM_PROFILE: 'request_info_from_profile',
  UNREAD: false,
  READ: true,
}
export { STATUS_MESSAGE }
