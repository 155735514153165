import styles from '../../../../css/modehotes/hosting/resource_host.module.css'
import { Link } from 'react-router-dom'
import { Crisp } from 'crisp-sdk-web'

const ResourceHost = () => {
  const handleChat = () => {
    Crisp.chat.open()
  }

  return (
    <div className={styles.container}>
      <h3>Guides et support</h3>
      <div className={styles.wrapper}>
        <Link to='/free-mode-host' target='_blank' className={styles.button_action}>
          <div>
            <svg
              viewBox='0 0 48 48'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'
              role='presentation'
              focusable='false'
              fill='currentcolor'
              stroke='currentcolor'
            >
              <g stroke='none'>
                <path
                  d='M24.556 8H9a1 1 0 0 0-.993.883L8 9v15.556a1 1 0 0 0 .206.608l.087.1 17.213 17.213a1 1 0 0 0 1.32.083l.094-.083L42.477 26.92a1 1 0 0 0 .083-1.32l-.083-.094L25.263 8.293a1 1 0 0 0-.575-.284L24.556 8z'
                  fillOpacity='.2'
                ></path>
                <path d='M24.556 4A5 5 0 0 1 27.9 5.282l.192.182 17.213 17.214a5 5 0 0 1 .172 6.89l-.172.18L29.75 45.306a5 5 0 0 1-6.89.172l-.181-.172L5.464 28.092a5 5 0 0 1-1.457-3.271L4 24.556V9a5 5 0 0 1 4.783-4.995L9 4h15.556zm0 2H9a3 3 0 0 0-2.995 2.824L6 9v15.556a3 3 0 0 0 .743 1.977l.136.145L24.092 43.89a3 3 0 0 0 4.099.135l.144-.135L43.89 28.335a3 3 0 0 0 .135-4.1l-.135-.143L26.678 6.879a3 3 0 0 0-1.924-.872L24.556 6zM13 10a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z'></path>
              </g>
            </svg>
          </div>
          <div>
            <h4>FreeMode</h4>
            <p>
              Boostez vos revenus sur chaque réservation grâce à FreeMode, Gowifmi ne prélève aucun
              frais de service d'hôte fixe s'élevant à 3 % du sous-total de la réservation
            </p>
          </div>
        </Link>
        <Link to='/how-you-get-paid-for-hosting' target='_blank' className={styles.button_action}>
          <div>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 32 32'
              aria-hidden='true'
              role='presentation'
              focusable='false'
              fill='currentcolor'
            >
              <path d='M25 4a2 2 0 0 1 2 1.85V8h2.04c1.04 0 1.88.82 1.96 1.85V26c0 1.05-.8 1.92-1.81 2H6.96a1.98 1.98 0 0 1-1.95-1.85L5 26v-2H3a2 2 0 0 1-2-1.85V6a2 2 0 0 1 1.85-2H3zm2 18a2 2 0 0 1-1.85 2H7v2h22V10h-2zM25 6H3v16h22zm-3 12a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm-8-8a4 4 0 1 1 0 8 4 4 0 0 1 0-8zm0 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4zM6 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2z'></path>
            </svg>
          </div>
          <div>
            <h4>Recevoir des paiements</h4>
            <p>
              C'est vous qui choisissez comment vous souhaitez recevoir les revenus de votre
              activité d'hôte
            </p>
          </div>
        </Link>
        <button type='button' className={styles.button_action} onClick={handleChat}>
          <div>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 32 32'
              aria-hidden='true'
              role='presentation'
              focusable='false'
            >
              <path d='M16 1a11 11 0 0 1 10.6 8.03 6 6 0 0 1-.34 11.96 11.01 11.01 0 0 1-7.53 6.7l.2-.05a3 3 0 1 1-.26-2.01 9 9 0 0 0 6.33-8.6V12a9 9 0 0 0-18-.27V21H6a6 6 0 0 1-.6-11.97A11 11 0 0 1 16 1zm0 25a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm.75-20.4c.69 0 1.25.55 1.25 1.24V11h2.04c.2 0 .38.04.56.13l.12.07a1.25 1.25 0 0 1 .37 1.73l-5.8 8.9a1.25 1.25 0 0 1-2.29-.67V17h-2.04a1.25 1.25 0 0 1-.56-.13l-.12-.07a1.25 1.25 0 0 1-.37-1.73l5.8-8.9a1.25 1.25 0 0 1 1.04-.58zM5 11.12l-.15.04a4 4 0 0 0 0 7.66l.15.04v-7.74zm22 0v7.74l.15-.04a4 4 0 0 0 0-7.66l-.15-.04zM16 9.37 12.34 15H15v3.63L18.66 13H16V9.37z'></path>
            </svg>
          </div>
          <div>
            <h4>Contactez l'assistance spécialisée</h4>
            <p>
              En tant que nouvel hôte, vous avez accès en un clic à une équipe d'assistance
              spécialement formée.
            </p>
          </div>
        </button>
      </div>
    </div>
  )
}

export default ResourceHost
