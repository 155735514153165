import styles from '../../../../../css/booking/stripe_infos.module.css'
import StripeSvg from '../../../../svgs/StripeSvg'

const StripeInfos = () => {
  return (
    <>
      <div>
        <div className={styles.block}>
          <p>Vos paiements sont traités en toute sécurité par Stripe.</p>
          <a
            target='_blank'
            rel='noopener noreferrer'
            className={styles.redirect_link}
            href='https://stripe.com'
          >
            Alimenté par
            <span>
              <StripeSvg />
            </span>
          </a>
        </div>
      </div>
    </>
  )
}

export default StripeInfos
