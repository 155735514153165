import { useState } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const AdditionalRule = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [regle, setRegle] = useState('')
  const { loading, updateData } = useUpdateHotel(setOpen)

  const handleSubmit = () => {
    const datas = {
      regulations: {
        ...annonce.regulations,
        accommodation_rules: regle,
      },
    }
    updateData(datas)
  }
  const openFuction = () => {
    setRegle(annonce.regulations?.accommodation_rules)
    setOpen(true)
  }
  const reset = () => {
    setRegle()
    setOpen(false)
  }
  return (
    <div className={styles.block}>
      {!open ? (
        <Section title='Règles supplémentaires' handleOpen={openFuction}>
          <p>{annonce.regulations?.accommodation_rules || 'Non défini'}</p>
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title='Règles supplémentaires' handleReset={reset}>
            <p>
              Vous pouvez inclure des critères relatifs à la sécurité, ainsi que des règles
              régissant les espaces partagés et la vie au sein de la communauté.
            </p>
          </ContenHead>
          <div className={styles.body}>
            <div className={styles.input_block}>
              <textarea value={regle} onChange={e => setRegle(e.target.value)}></textarea>
            </div>
          </div>
          <BottomForm
            disabled={regle === annonce.regulations?.accommodation_rules}
            loading={loading}
            handleReset={reset}
            handleSubmit={handleSubmit}
          />
        </div>
      )}
    </div>
  )
}

export default AdditionalRule
