import styles from '../../../css/modehotes/inbox/side_navigation.module.css'
import { Link } from 'react-router-dom'
import CloseButton from '../../ui/CloseButton'

const SideNavigation = ({ filter, modalLeftbar, setModalLeftbar }) => {
  const path = window.location.pathname.split('/')[3]

  return (
    <div className={modalLeftbar ? `${styles.block_grid_1} ${styles.active}` : styles.block_grid_1}>
      <div className={styles.close_modal}>
        <CloseButton onClick={() => setModalLeftbar(false)} />
      </div>
      <div className={styles.navigation}>
        <h1 className={styles.boite}>Boîte de réception</h1>
      </div>
      <div className={styles.left_bar}>
        <Link
          to='/hosting/inbox/all'
          className={path === 'all' || filter === 'all' ? styles.active : ''}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 32 32'
            aria-hidden='true'
            role='presentation'
            focusable='false'
            style={{ display: 'block', height: '24px', width: '24px', fill: 'currentcolor' }}
          >
            <path d='M26 1a5 5 0 0 1 5 4.78v10.9a5 5 0 0 1-4.78 5H26a5 5 0 0 1-4.78 5h-4l-3.72 4.36-3.72-4.36H6a5 5 0 0 1-4.98-4.56L1 21.9 1 21.68V11a5 5 0 0 1 4.78-5H6a5 5 0 0 1 4.78-5H26zm-5 7H6a3 3 0 0 0-3 2.82v10.86a3 3 0 0 0 2.82 3h4.88l2.8 3.28 2.8-3.28H21a3 3 0 0 0 3-2.82V11a3 3 0 0 0-3-3zm-1 10v2H6v-2h14zm6-15H11a3 3 0 0 0-3 2.82V6h13a5 5 0 0 1 5 4.78v8.9a3 3 0 0 0 3-2.82V6a3 3 0 0 0-2.82-3H26zM15 13v2H6v-2h9z'></path>
          </svg>
          Tous les messages
        </Link>
        <Link
          to='/hosting/inbox/assistance'
          className={path === 'assistance' || filter === null ? styles.active : ''}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 32 32'
            aria-hidden='true'
            role='presentation'
            focusable='false'
            style={{ display: 'block', height: '24px', width: '24px', fill: 'currentcolor' }}
          >
            <path d='M16 1a15 15 0 1 0 15 15A15.02 15.02 0 0 0 16 1zm0 28a13 13 0 1 1 13-13 13.01 13.01 0 0 1-13 13zm1.5-5.5A1.5 1.5 0 1 1 16 22a1.5 1.5 0 0 1 1.5 1.5zM21.58 12c0 2.53-1.7 4.58-4.57 5.59L17 20h-2l.01-3.91.74-.2c1.15-.32 3.83-1.32 3.83-3.89a3.19 3.19 0 0 0-3.45-3.17 3.68 3.68 0 0 0-3.53 2.72L10.67 11a5.66 5.66 0 0 1 5.46-4.17A5.2 5.2 0 0 1 21.58 12z'></path>
          </svg>
          Assistance Gowifmi
        </Link>
        <Link
          to='/hosting/inbox/hidden'
          className={path === 'hidden' || filter === 'hidden' ? styles.active : ''}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 32 32'
            aria-hidden='true'
            role='presentation'
            focusable='false'
            style={{
              display: 'block',
              fill: 'none',
              height: '24px',
              width: '24px',
              stroke: 'currentcolor',
              strokeWidth: '2',
              overflow: 'visible',
            }}
          >
            <g fill='none'>
              <path d='m26.26 3.6 2.58 6.02a2 2 0 0 1 .16.8V28a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V10.41a2 2 0 0 1 .16-.79l2.58-6.01A1 1 0 0 1 6.66 3h18.68a1 1 0 0 1 .92.6zM4 11h24'></path>
              <path d='m8 16 7.3 7.3a1 1 0 0 0 1.4 0L24 16'></path>
            </g>
          </svg>
          Messages archivés
        </Link>
      </div>
    </div>
  )
}

export default SideNavigation
