import styles from '../../css/booking/loaging.module.css'
import LoadingPoint from '../../components/LoadingPoint'
import { Portal } from 'react-portal'

const Loading = () => {
  return (
    <Portal>
      <div className={styles.container}>
        <LoadingPoint />
      </div>
    </Portal>
  )
}

export default Loading
