import styles from '../../../../../css/modehotes/annonce/details/detail_head.module.css'
import Statut from './components/header_details/Statut'
import ResInstantane from './components/header_details/ResInstantane'
import Preview from './components/header_details/Preview'
import SearchProfile from './components/header_details/SearchProfile'
import Calendar from './components/header_details/Calendar'
import { Link } from 'react-router-dom'

const Head = ({ annonce }) => {
  return (
    <div className={styles.container}>
      <div>
        <div className={styles.header_title}>
          <div>
            <Link to={`/hosting/listings`}>
              <svg
                viewBox='0 0 32 32'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
                style={{
                  display: 'block',
                  height: '16px',
                  width: '16px',
                  stroke: 'currentcolor',
                  strokeWidth: '3',
                  fill: 'none',
                  overflow: 'visible',
                }}
              >
                <g>
                  <path d='m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932'></path>
                </g>
              </svg>
            </Link>
          </div>
          <h1>{annonce.title}</h1>
        </div>
        <div className={styles.actions}>
          <Statut annonce={annonce} />
          <ResInstantane annonce={annonce} />
          <Calendar annonce={annonce} />
          <Preview annonce={annonce} />
        </div>
      </div>
      <div className={styles.search_block}>
        <SearchProfile annonce={annonce} />
      </div>
    </div>
  )
}

export default Head
