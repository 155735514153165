import React, { useState } from 'react'
import styles from '../../../../css/modehotes/annonce/filtres/filtre.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import Chambre from './filtres/Chambre'
import Equipement from './filtres/Equipement'
import OptionReservation from './filtres/OptionReservation'
import Statut from './filtres/Statut'
import Modal from '../../../ui/Modal'
import CloseButton from '../../../ui/CloseButton'

const Filter = React.forwardRef(({}, ref) => {
  const [modal, setModal] = useState(false)
  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      setModal(true)
    },
  }))
  const history = useHistory()
  const location = useLocation()
  const page = location.pathname
  const deleteAllFilters = () => {
    history.push(`${page}`)
    setModal(false)
  }
  return (
    <>
      <Modal isOpen={modal} onClose={() => setModal(false)}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={() => setModal(false)} />
            <h3>Filtres</h3>
            <div></div>
          </div>
          <div className={styles.m_body}>
            {/*--------filter by Type de Statut--------*/}
            <Statut />
            {/*--------filter by Chambres----lits et salles de bains------*/}
            <Chambre />
            {/*--------filter by Equipement--------*/}
            <Equipement />
            {/*--------filter OptionReservation--------*/}
            <OptionReservation />
          </div>
          <div className={styles.m_footer}>
            <button type='button' className={styles.effacher} onClick={deleteAllFilters}>
              Tout effacer
            </button>

            <button type='button' className={styles.appliquer} onClick={() => setModal(false)}>
              Afficher les résultats
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
})

export default Filter
