import { useState } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import Options1 from './Options1'
import Options2 from './Options2'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const ArrivalTime = ({ annonce, titre }) => {
  const [open, setOpen] = useState(false)
  const [checkIn_1, setCheck_in_1] = useState('')
  const [checkIn_2, setCheck_in_2] = useState('')
  const { loading, updateData } = useUpdateHotel(setOpen)

  const handleSubmit = e => {
    e.preventDefault()
    const datas = {
      regulations: {
        ...annonce.regulations,
        check_in_1: checkIn_1,
        check_in_2: checkIn_2,
      },
    }
    updateData(datas)
  }
  const openFuction = () => {
    setCheck_in_1(annonce.regulations?.check_in_1)
    setCheck_in_2(annonce.regulations?.check_in_2)
    setOpen(true)
  }
  const reset = () => {
    setCheck_in_1()
    setCheck_in_2()
    setOpen(false)
  }
  return (
    <div className={styles.block}>
      {titre && <h2>{titre}</h2>}
      {!open ? (
        <Section title='Plage horaire pour les arrivées' handleOpen={openFuction}>
          <p>
            Heure du début :{' '}
            {annonce.regulations?.check_in_1 === '' ? 'Flexibles' : annonce.regulations?.check_in_1}
          </p>
          <p>
            Heure de fin :{' '}
            {annonce.regulations?.check_in_2 === '' ? 'Flexibles' : annonce.regulations?.check_in_2}
          </p>
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title='Plage horaire pour les arrivées' handleReset={reset}></ContenHead>
          <form onSubmit={handleSubmit}>
            <div className={styles.body}>
              <div className={styles.input_block}>
                <div className={styles.select_block}>
                  <label htmlFor='checkIn_1'>Sélectionnez l'heure du début</label>
                  <select
                    id='checkIn_1'
                    defaultValue={checkIn_1}
                    onChange={e => setCheck_in_1(e.target.value)}
                  >
                    <Options1 />
                  </select>
                </div>
                <div className={styles.select_block}>
                  <label htmlFor='checkIn_2'>Sélectionnez l'heure de fin</label>
                  <select
                    id='checkIn_2'
                    defaultValue={checkIn_2}
                    onChange={e => setCheck_in_2(e.target.value)}
                  >
                    <Options2 />
                  </select>
                </div>
              </div>
            </div>
            <BottomForm loading={loading} handleReset={reset} />
          </form>
        </div>
      )}
    </div>
  )
}

export default ArrivalTime
