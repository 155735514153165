import { useState } from 'react'
import styles from '../../../css/account/confirmEmail.module.css'
import { useLocation } from 'react-router'
import axios from 'axios'
import { useQuery } from 'react-query'
import LoadingPoint from '../../../components/LoadingPoint'
import DefaultLayout from '../../../layouts/DefaultLayout'
import { Helmet } from 'react-helmet-async'

const ConfirmEmail = () => {
  const [loadingBtn, setLoadingBtn] = useState(false)
  const location = useLocation()
  const token = location.pathname.split('/')[3]

  const { isLoading, error } = useQuery(`confirmEmail`, () =>
    axios.patch(`/user/confirm_email/${token}`).then(res => {
      return
    })
  )

  const sendEmail = () => {
    setLoadingBtn(true)
    axios
      .patch(`/user/send_verif_email`)
      .then(() => {
        alert(
          `Veuillez consulter votre boîte de réception, un e-mail de confirmation doit s’y trouver. Cliquez sur le button pour confirmer votre adresse e-mail.`
        )
      })
      .catch(error => {
        if (error.response?.data?.emailVerfified) {
          alert('Cette adresse e-mail est déjà vérifiée actualiser la page.')
        } else {
          alert('Error server')
        }
      })
      .finally(() => {
        setLoadingBtn(false)
      })
  }

  return (
    <DefaultLayout footer={true} navTopMobile={true} mobileBottomNavigate={true}>
      <Helmet>
        <title>Vérification e-mail - Gowifmi</title>
      </Helmet>
      <div className={styles.container}>
        {isLoading ? (
          <LoadingPoint />
        ) : (
          <div>
            {!error ? (
              <div className={styles.succes}>
                <div>
                  <svg
                    className={styles.message}
                    viewBox='0 0 32 32'
                    xmlns='http://www.w3.org/2000/svg'
                    aria-hidden='true'
                    role='presentation'
                    focusable='false'
                  >
                    <path d='m26 2c2.6887547 0 4.8818181 2.12230671 4.9953805 4.78311038l.0046195.21688962v14c0 2.6887547-2.1223067 4.8818181-4.7831104 4.9953805l-.2168896.0046195h-5.845l-4.1548949 5.0792674-4.1561051-5.0792674h-5.844c-2.61406707 0-4.75959878-2.0060384-4.98113724-4.5626572l-.01424325-.2204532-.00461951-.2168896v-14c0-2.6887547 2.12230671-4.88181811 4.78311038-4.99538049l.21688962-.00461951zm0 2h-20c-1.59768088 0-2.90366088 1.24891996-2.99490731 2.82372721l-.00509269.17627279v14c0 1.5976809 1.24891996 2.9036609 2.82372721 2.9949073l.17627279.0050927h6.7918643l3.2071357 3.92 3.2080542-3.92h6.7929458c1.5976809 0 2.9036609-1.24892 2.9949073-2.8237272l.0050927-.1762728v-14c0-1.59768088-1.24892-2.90366088-2.8237272-2.99490731z'></path>
                  </svg>
                </div>
                <p>Merci ! votre adresse e-mail est confirmée avec succès</p>
              </div>
            ) : (
              <div className={styles.error_server}>
                <p>Le lien n'est plus valide veuillez réessayer la confirmation</p>
                {loadingBtn ? (
                  <button disabled>
                    <LoadingPoint />
                  </button>
                ) : (
                  <button onClick={sendEmail}>Envoyer le lien de confirmation</button>
                )}
                <a href='/help/feedback'>Besoin d'aide?</a>
              </div>
            )}
          </div>
        )}
      </div>
    </DefaultLayout>
  )
}

export default ConfirmEmail
