import { useState } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import Options from './Options'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const PreparationTime = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [tempsPreparation, setTempsPreparation] = useState(Number())
  const { loading, updateData } = useUpdateHotel(setOpen)

  const delai = annonce.availability_calendar?.preparation_time
  const handleSubmit = () => {
    const datas = {
      availability_calendar: {
        ...annonce.availability_calendar,
        preparation_time: Number(tempsPreparation),
      },
    }
    updateData(datas)
  }
  const openFuction = () => {
    setTempsPreparation(annonce.availability_calendar?.preparation_time)
    setOpen(true)
  }
  const reset = () => {
    setTempsPreparation()
    setOpen(false)
  }
  return (
    <div className={styles.block}>
      {!open ? (
        <Section title='Temps de préparation' handleOpen={openFuction}>
          <p>
            {delai > 0
              ? `Bloquer ${delai} ${delai > 1 ? 'nuits' : 'nuit'} avant et après chaque réservation`
              : 'Aucun'}
          </p>
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title='Temps de préparation' handleReset={reset}>
            {Number(tempsPreparation) === 0 && (
              <p>Les nuits avant et après chaque réservation sont disponibles.</p>
            )}
            {Number(tempsPreparation) === 1 && (
              <p>Les nuits avant et après chaque réservation sont bloquées.</p>
            )}
            {Number(tempsPreparation) === 2 && (
              <p>Deux nuits avant et après chaque réservation sont bloquées.</p>
            )}
          </ContenHead>
          <div className={styles.body}>
            <div className={styles.input_block}>
              <select
                defaultValue={tempsPreparation}
                onChange={e => setTempsPreparation(e.target.value)}
              >
                <Options />
              </select>
            </div>
          </div>
          <BottomForm
            handleSubmit={handleSubmit}
            disabled={tempsPreparation === annonce.availability_calendar?.preparation_time}
            loading={loading}
            handleReset={reset}
          />
        </div>
      )}
    </div>
  )
}

export default PreparationTime
