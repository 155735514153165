import { useEffect, useState } from 'react'
import styles from '../../../css/details/navbarScroll.module.css'
import { Link } from 'react-router-dom'

const NavBarScroll = ({
  avaibilityRef,
  numberOfTravelerIsInvalid,
  handleVerifDisponibilite,
  VerifDateFunction,
  voyageurFunction,
  handleDetail,
  reduction,
  pricePerNightWithoutPromo,
  pricePerNightWithPromo,
  totalWithservice,
  id,
  checkin,
  checkout,
  bebe,
  animal,
  adulte,
  enfant,
  photoRef,
  amenentieRef,
  descriptionRef,
  localisationRef,
  commentRef,
}) => {
  const [navbar, setNavbar] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  //fonction de scroll pour le nav fixe
  const handleScroll = () => {
    if (
      avaibilityRef.current &&
      window.scrollY > avaibilityRef.current.offsetTop + avaibilityRef.current.offsetHeight
    ) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  const handleButtonClick = elementRef => {
    if (elementRef.current) {
      window.scrollTo({
        top: elementRef.current.offsetTop - 70,
        behavior: 'smooth',
      })
    }
  }

  return (
    <div className={navbar ? `${styles.nav_scroll} ${styles.active}` : styles.nav_scroll}>
      <div className={styles.nav_content}>
        <div className={styles.nav_liens}>
          <button onClick={() => handleButtonClick(photoRef)}>Photos</button>
          <button onClick={() => handleButtonClick(amenentieRef)}>Équipements</button>
          <button onClick={() => handleButtonClick(descriptionRef)}>Hébergement</button>
          <button onClick={() => handleButtonClick(localisationRef)}>Emplacement</button>
          <button onClick={() => handleButtonClick(commentRef)}>Avis</button>
        </div>
        {handleVerifDisponibilite() ? (
          <div className={styles.btn_block}>
            {numberOfTravelerIsInvalid ? (
              <button className={styles.nav_button} onClick={voyageurFunction}>
                Modifier le nombre de voyageurs
              </button>
            ) : (
              <button className={styles.nav_button} onClick={VerifDateFunction}>
                Vérifier la disponibilité
              </button>
            )}
          </div>
        ) : (
          <div className={styles.btn_block}>
            <div className={styles.head}>
              <div className={styles.default_price}>
                {reduction > 0 && <p className={styles.promo}>{pricePerNightWithoutPromo}</p>}
                <h3>{pricePerNightWithPromo}</h3>
                <span>/ nuit</span>
              </div>
              <div>
                <button onClick={handleDetail}>{totalWithservice}</button>
                <span>Total</span>
              </div>
            </div>
            <Link
              to={`/book/${id}?adults=${adulte}&children=${enfant}&infants=${bebe}&pets=${animal}&checkin=${checkin}&checkout=${checkout}`}
              className={styles.nav_button}
            >
              Réserver
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default NavBarScroll
