import styles from '../../../css/logins/profil_category.module.css'
import FormTemplate from '../../accounts/info_perso/locations/FormTemplate'

const Location = ({
  fullAddress,
  setFullAddress,
  appartement,
  setAppartement,
  city,
  setCity,
  state,
  setState,
  postalCode,
  setPostalCode,
  country,
  setCountry,
  setCountryAbbreviation,
  setCoords,
  setIsValid,
}) => {
  return (
    <div className={styles.container}>
      <p>
        Veuillez fournir une adresse fixe pour recevoir du courrier et vous situer géographiquement
        pour vos prestations sur Gowifmi
      </p>
      <FormTemplate
        fullAddress={fullAddress}
        setFullAddress={setFullAddress}
        appartement={appartement}
        setAppartement={setAppartement}
        city={city}
        setCity={setCity}
        state={state}
        setState={setState}
        postalCode={postalCode}
        setPostalCode={setPostalCode}
        country={country}
        setCountry={setCountry}
        setCountryAbbreviation={setCountryAbbreviation}
        setCoords={setCoords}
        setIsValid={setIsValid}
      />
    </div>
  )
}

export default Location
