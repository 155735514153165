import React from 'react'
import ErrorSvg from '../../../../../svgs/ErrorSvg'
import styles from '../../../../../../css/booking/addphone.module.css'
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'

const SearchNavbar = React.forwardRef(({ valuePhone, setTelephone, phoneError }, ref) => {
  const handleChange = value => {
    const result = formatPhoneNumberIntl(value)
    setTelephone(result)
  }

  return (
    <>
      <div className={styles.add_number}>
        <div className={styles.verif_block_detail}>
          <div
            className={
              valuePhone && !isValidPhoneNumber(valuePhone)
                ? `${styles.input_groupe_phone} ${styles.erreur_input}`
                : styles.input_groupe_phone
            }
          >
            <PhoneInput
              international
              placeholder='Numéro de téléphone'
              value={valuePhone}
              onChange={handleChange}
            />
            {valuePhone && !isValidPhoneNumber(valuePhone) ? (
              <div className={styles.error_message}>
                <div>
                  <ErrorSvg />
                </div>
                <span>Saisissez un numéro de téléphone valide</span>
              </div>
            ) : null}

            {phoneError && !valuePhone ? (
              <div className={styles.error_message}>
                <div>
                  <ErrorSvg />
                </div>
                <span>Ajoutez votre numéro de téléphone pour continuer.</span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
})
export default SearchNavbar
