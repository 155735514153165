export function isPagePath(path) {
  if (path === 'profiles') {
    return true
  }
  if (path === 'homes') {
    return true
  }
  if (path === 'book') {
    return true
  }
  if (path === 'sponsorship') {
    return true
  }
  return false
}
