import { Link } from 'react-router-dom'
import styles from '../../../../../../css/modehotes/annonce/details/photo.module.css'
import SvgIconRightDirector from '../../../../../svgs/SvgIconRightDirector'

const Photo = ({ annonce }) => {
  const Query = window.location.search

  return (
    <div className={styles.block}>
      <div className={styles.head}>
        <h3>
          {annonce.images?.length} {annonce.images?.length > 1 ? 'Photos' : 'Photo'}
        </h3>
        <Link to={`/hosting/listings/details/${annonce._id}/photos${Query}`}>
          <span>Modifier</span>
          <SvgIconRightDirector />
        </Link>
      </div>
      <div className={styles.main}>
        {annonce.images?.map((item, i) => (
          <div className={styles.img_block} key={i}>
            <img src={item.url} alt='' />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Photo
