import { useState } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const Itinerary = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [itineraire, setItineraire] = useState('')
  const { loading, updateData } = useUpdateHotel(setOpen)

  const handleSubmit = e => {
    e.preventDefault()
    const datas = {
      traveler_information: {
        ...annonce.traveler_information,
        itinerary: itineraire,
      },
    }
    updateData(datas)
  }
  const openFuction = () => {
    setItineraire(annonce.traveler_information?.itinerary)
    setOpen(true)
  }
  const reset = () => {
    setItineraire()
    setOpen(false)
  }
  return (
    <div className={styles.block}>
      <h2>Informations après la réservation</h2>
      {!open ? (
        <Section title='Itinéraire' handleOpen={openFuction}>
          <p>{annonce.traveler_information?.itinerary || 'Non défini'}</p>
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title='Itinéraire' handleReset={reset}></ContenHead>
          <form onSubmit={handleSubmit}>
            <div className={styles.body}>
              <div className={styles.input_block}>
                <textarea
                  value={itineraire}
                  onChange={e => setItineraire(e.target.value)}
                ></textarea>
              </div>
            </div>
            <BottomForm
              disabled={itineraire === annonce.traveler_information?.itinerary}
              loading={loading}
              handleReset={reset}
            />
          </form>
        </div>
      )}
    </div>
  )
}

export default Itinerary
