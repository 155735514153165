import React, { useState } from 'react'
import styles from '../../../../../../css/booking/politiqueHote.module.css'
import Modal from '../../../../../ui/Modal'
import CloseButton from '../../../../../ui/CloseButton'
import { CANCELLATION_POLICY } from '../../../../../../enums/logement/cancellation_policy.enums'

const HostPolicy = React.forwardRef(({ hotel }, ref) => {
  const [modal, setModal] = useState(false)

  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      setModal(true)
    },
  }))
  const limiteMax = hotel?.details?.traveler

  return (
    <>
      <Modal isOpen={modal} onClose={() => setModal(false)}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={() => setModal(false)} />
            <div></div>
          </div>
          <div className={styles.m_body}>
            <div className={styles.asavoir}>
              <h2>Pour votre information</h2>
              <div className={styles.block}>
                <p>
                  {limiteMax} {limiteMax > 1 ? 'voyageurs' : 'voyageur'} maximum
                </p>
              </div>
              <div className={styles.block}>
                <p>
                  Arrivée :{' '}
                  {!hotel?.regulations?.check_in_1
                    ? CANCELLATION_POLICY[0]
                    : hotel?.regulations?.check_in_1}{' '}
                  -{' '}
                  {!hotel?.regulations?.check_in_2
                    ? CANCELLATION_POLICY[0]
                    : hotel?.regulations?.check_in_2}
                </p>
              </div>
              <div className={styles.block}>
                <p>
                  Départ :{' '}
                  {!hotel?.regulations?.check_out
                    ? CANCELLATION_POLICY[0]
                    : hotel?.regulations?.check_out}
                </p>
              </div>
              {!hotel?.regulations?.accept_children && (
                <div className={styles.block}>
                  <p className={styles.no}>Ne convient pas aux enfants</p>
                </div>
              )}
              {!hotel?.regulations?.accept_babies && (
                <div className={styles.block}>
                  <p className={styles.no}>Ne convient pas aux bébés</p>
                </div>
              )}
              {!hotel?.regulations?.smoking_allowed && (
                <div className={styles.block}>
                  <p>Non fumeur</p>
                </div>
              )}
              {!hotel?.regulations?.accept_pets && (
                <div className={styles.block}>
                  <p className={styles.no}>Pas d'animaux</p>
                </div>
              )}
              {!hotel?.regulations?.accept_events && (
                <div className={styles.block}>
                  <p className={styles.no}>Pas de fête ni de soirée</p>
                </div>
              )}
            </div>
            {hotel?.traveler_information?.arrival_instructions_message && (
              <div>
                <h2>Instructions d'arrivée</h2>
                <div className={styles.infos}>
                  <p>{hotel?.traveler_information?.arrival_instructions_message}</p>
                </div>
              </div>
            )}
            {hotel?.regulations?.accommodation_rules && (
              <div>
                <h2>Règles supplémentaires</h2>
                <div className={styles.infos}>
                  <p>{hotel?.regulations?.accommodation_rules}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
})
export default HostPolicy
