import styles from '../../../../../../../../css/modehotes/annonce/details/desactivate.module.css'

const Block1 = ({ handleclose, setActive, raison1, setRaison1 }) => {
  const raisons = [
    {
      value: '1',
      desciption:
        "J'ai besoin de temps pour effectuer des travaux de rénovation ou de réparation dans ma propriété",
    },
    {
      value: '2',
      desciption:
        'Ma propriété ne sera pas disponible pour la location pendant un certain temps en raison de circonstances personnelles',
    },
    {
      value: '3',
      desciption:
        'Je souhaite suspendre temporairement mes activités de location pour des raisons personnelles ou professionnelles',
    },
    {
      value: '4',
      desciption:
        "Je prends une pause dans la location de ma propriété pour des raisons liées à ma stratégie commerciale ou à l'évolution du marché",
    },
    {
      value: '5',
      desciption:
        'Je dois résoudre des problèmes de conformité avec les réglementations locales ou les politiques de Gowifmi',
    },
    { value: 'other', desciption: 'Autre chose' },
  ]

  return (
    <>
      <div className={styles.m_body}>
        <div>
          <h3>Pourquoi désactivez-vous cette annonce ?</h3>
          <p>
            Merci de choisir une option parmi les suivantes qui décrit le mieux votre situation.
          </p>
        </div>
        <div className={styles.grid_system}>
          {raisons.map((item, i) => (
            <label htmlFor={item.value} className={styles.radio_container} key={i}>
              <p>{item.desciption}</p>
              <div>
                <input
                  type='radio'
                  id={item.value}
                  value={item.value}
                  checked={raison1 === item.value}
                  onChange={e => setRaison1(e.target.value)}
                />
              </div>
            </label>
          ))}
        </div>
      </div>
      <div className={styles.button_action}>
        <button className={styles.retour} onClick={handleclose}>
          Annuler
        </button>
        <button
          className={!raison1 ? `${styles.next} ${styles.disabled}` : styles.next}
          disabled={!raison1}
          onClick={() => setActive(2)}
        >
          Suivant
        </button>
      </div>
    </>
  )
}

export default Block1
