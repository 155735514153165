import { useState } from 'react'
import styles from '../../../css/signals/signal.module.css'
import Swal from 'sweetalert2'
import axios from 'axios'
import LoadingPoint from '../../LoadingPoint'

const Block2 = ({ setActive, raison1, raison2, setRaison2, userSignal }) => {
  const [loading, setLoading] = useState(false)
  const handleSubmit = () => {
    setLoading(true)
    const data = {
      raison_1: raison1,
      message: raison2,
      user_signal: userSignal,
    }

    axios
      .post(`/signalerUser`, data)
      .then(() => {
        setLoading(false)
        setActive(3)
      })
      .catch(err => {
        setLoading(false)
        Swal.fire('Warning', 'Un problème est sur venu au nuveau de serveur', 'warning')
      })
  }

  return (
    <>
      <div className={styles.m_body}>
        <div>
          <h3>Pourquoi signalez-vous cet utilisateur ?</h3>
          <p>Ces informations ne seront partagées qu'avec Gowifmi.</p>
        </div>
        <div className={styles.message_content}>
          <textarea value={raison2} onChange={e => setRaison2(e.target.value)}></textarea>
        </div>
      </div>
      <div className={styles.button_action}>
        <button className={styles.retour} onClick={() => setActive(1)}>
          Retour
        </button>
        {loading ? (
          <button className={`${styles.next} ${styles.disabled}`} disabled>
            <LoadingPoint />
          </button>
        ) : (
          <button
            className={!raison2 ? `${styles.next} ${styles.disabled}` : styles.next}
            disabled={!raison2}
            onClick={handleSubmit}
          >
            Envoyer
          </button>
        )}
      </div>
    </>
  )
}

export default Block2
