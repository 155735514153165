import { useEffect, useRef, useContext } from 'react'
import styles from '../../../../css/traveler/inbox/detailMessage.module.css'
import { useLocation } from 'react-router'
import { useQuery } from 'react-query'
import axios from 'axios'
import { User } from '../../../../context/UserContext'
import LoadingPoint from '../../../../components/LoadingPoint'
import Conneted from '../../../../components/traveler/inbox/connexion/Conneted'
import AllMessage from '../../../../components/traveler/inbox/details/AllMessage'
import Archive from '../../../../components/traveler/inbox/details/Archive'
import TypeMessage from '../../../../components/traveler/inbox/details/TypeMessage'
import SignalerUser from '../../../../components/traveler/inbox/details/SignalerUser'
import DefaultLayout from '../../../../layouts/DefaultLayout'
import WriteMessageInput from '../../../../components/traveler/inbox/details/WriteMessageInput'
import { Helmet } from 'react-helmet-async'
import { useWebSocket } from '../../../../hooks/useWebSocket'
import NewMessage from '../../../../components/traveler/inbox/details/NewMessage'
import momentTimeZone from 'moment-timezone'

const DetailMessage = () => {
  const user = useContext(User)
  const location = useLocation()
  const path = location.pathname.split('/')[3]

  const userTimezone = momentTimeZone.tz.guess()

  const { refetch: updateConversation } = useQuery(
    `updateStatut-user-${path}}`,
    () =>
      axios.patch(`/message/updateStatut/${path}`).then(res => {
        return res.data
      }),
    { refetchOnWindowFocus: false }
  )

  const {
    refetch,
    error,
    data: conversation = null,
    isLoading,
  } = useQuery(
    `get-conversation-user-${path}}`,
    () =>
      axios.get(`/message/${path}`).then(res => {
        return res.data
      }),
    { refetchOnWindowFocus: false }
  )

  useWebSocket(() => {
    refetch()
    updateConversation()
  })

  const chatParent = useRef(null)
  useEffect(() => {
    const domNode = chatParent.current
    if (domNode) {
      domNode.scrollTop = domNode.scrollHeight
    }
  })

  const annonce_id = conversation?.hotel?._id

  return (
    <DefaultLayout>
      <Helmet>
        <title>Messages - Gowifmi</title>
      </Helmet>
      {user ? (
        <div className={styles.liste_container}>
          <div className={styles.block_grid}>
            <AllMessage path={path} user={user} />
            <div className={styles.block_grid_2_container}>
              {isLoading ? (
                <div className={styles.loading_container}>
                  <LoadingPoint />
                </div>
              ) : (
                <>
                  {error ? (
                    <div className={styles.no_result}>
                      <p>Une erreur s'est produite veuillez réessayer</p>
                    </div>
                  ) : (
                    <>
                      {conversation ? (
                        <div className={styles.block_grid_2}>
                          <Archive conversation={conversation} />
                          <div className={styles.detail_block} ref={chatParent}>
                            <div className={styles.message}>
                              {[...(conversation?.message_box || [])]?.reverse().map(item => (
                                <div key={item._id} className={styles.detail}>
                                  <TypeMessage
                                    item={item}
                                    adulte={item.adults}
                                    enfant={item.children}
                                    bebe={item.babies}
                                    animaux={item.pets}
                                    checkin={item.arrival}
                                    checkout={item.departure}
                                    annonce_id={annonce_id}
                                  />
                                  <div className={styles.message_block}>
                                    <div>
                                      <a
                                        href={`/profile/${item.userId.id}`}
                                        target='_blank'
                                        className={styles.card}
                                        rel='noreferrer'
                                      >
                                        {item.userId?.picture ? (
                                          <img src={item.userId?.picture} alt='' />
                                        ) : (
                                          <h2>{item.userId?.first_name?.slice(0, 1)}</h2>
                                        )}
                                      </a>
                                    </div>
                                    <div className={styles.message_text}>
                                      <div>
                                        <h4>
                                          {item.userId?.id === user?.id
                                            ? 'Moi'
                                            : item.userId?.first_name}
                                          <span>
                                            {momentTimeZone(item.timestamp)
                                              .tz(userTimezone)
                                              .format('HH:mm')}
                                          </span>
                                        </h4>
                                        <p>{item.text}</p>
                                      </div>
                                      {item.userId?.id === conversation?.host?.id && (
                                        <SignalerUser userSignal={item.userId?.id} />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <WriteMessageInput refetch={refetch} conversationId={conversation._id} />
                        </div>
                      ) : (
                        <NewMessage refetch={refetch} path={path} />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Conneted />
      )}
    </DefaultLayout>
  )
}

export default DetailMessage
