import styles from '../../css/details/maps.module.css'

const NeighborhoodDescription = ({ hotel }) => {
  return (
    <>
      {hotel.traveler_information.neighborhood_description && (
        <div className={`${styles.description} ${styles.section}`}>
          <h3>Description du quartier</h3>
          <div className={styles.content}>
            <p>{hotel.traveler_information.neighborhood_description}</p>
          </div>
        </div>
      )}
    </>
  )
}

export default NeighborhoodDescription
