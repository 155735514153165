export function addCurrency(price = 0, currency) {
  //j'ajoute le currrency automatique le jour ou le site va supporter le multi currency
  return price.toLocaleString(undefined, {
    style: 'currency',
    currency: 'usd',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })
}

export function addCurrencyComplet(price = 0, currency) {
  return price.toLocaleString(undefined, {
    style: 'currency',
    currency: 'usd',
    maximumFractionDigits: 2,
  })
}

// export function formatPrice(price = 1245.52, currency) {
//   const formatted = new Intl.NumberFormat('en-US', {
//     style: 'currency',
//     currency: 'USD',
//   }).format(price)

//   return formatted //1,245.52
// }
