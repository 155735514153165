import { useState } from 'react'
import styleFav from '../../../css/add_to_favoris/favoris.module.css'
import AddRemoveDetailRoom from '../../add_to_favoris/AddRemoveDetailRoom'
import ViewList from '../../add_to_favoris/ViewList'
import CreateList from '../../add_to_favoris/CreateList'
import NotifMessage from '../../add_to_favoris/NotifMessage'
import Modal from '../../ui/Modal'

const Favoritedetail = ({ getAllFavoris, annonce }) => {
  const [modal, setModal] = useState(false)
  const [createList, setCreateList] = useState(false)
  const [pushfavori, setPushFavori] = useState(false)
  const [supfavori, setsupFavori] = useState(false)

  return (
    <>
      <AddRemoveDetailRoom
        annonce={annonce}
        setModal={setModal}
        setCreateList={setCreateList}
        setPushFavori={setPushFavori}
        setsupFavori={setsupFavori}
        getAllFavoris={getAllFavoris}
      />
      {/*----modal message de l'hotel---------*/}
      <Modal isOpen={modal} onClose={() => setModal(false)}>
        <div className={styleFav.modal_fav}>
          {!createList ? (
            <ViewList
              annonce={annonce}
              setModal={setModal}
              setCreateList={setCreateList}
              setPushFavori={setPushFavori}
              setsupFavori={setsupFavori}
              getAllFavoris={getAllFavoris}
            />
          ) : (
            <CreateList
              annonce={annonce}
              setModal={setModal}
              setCreateList={setCreateList}
              setPushFavori={setPushFavori}
              setsupFavori={setsupFavori}
              getAllFavoris={getAllFavoris}
            />
          )}
        </div>
      </Modal>
      {/***----modal de add and delete to favori */}
      <NotifMessage pushfavori={pushfavori} supfavori={supfavori} />
    </>
  )
}

export default Favoritedetail
