import { useRef } from 'react'
import styles from '../../../../css/account/profile/action_button_block.module.css'
import { Link } from 'react-router-dom'
import ShareLink from '../../../share_links/ShareLink'

const ActionButtonBlock = ({ userConnected, getUser, modifProfil, setModifProfil }) => {
  const sharRef = useRef()
  const handleSharRef = () => {
    sharRef.current.openModal()
  }
  const hostname = window.location.hostname

  const url = `https://${hostname}/profile/${getUser?.id}`
  const title = 'Partager le profil'
  const quote = 'Découvrer Gowifmi pour la location de votre logement'

  return (
    <>
      <div className={styles.container}>
        {getUser?.id === userConnected?.id && (
          <button
            type='button'
            className={styles.button}
            onClick={() => setModifProfil(!modifProfil)}
          >
            <span>Modifier</span>
          </button>
        )}
        {getUser?.id !== userConnected?.id && getUser?.isIdentityValid && (
          <>
            {getUser?.isHost || getUser?.profile_categories.length > 0 ? (
              <Link to={`/inbox/detail/${getUser?.id}?filter=all`} className={styles.button}>
                <span>Contacter</span>
              </Link>
            ) : null}
          </>
        )}
        <button type='button' className={styles.button} onClick={handleSharRef}>
          <span>Partager le profil</span>
        </button>
      </div>
      <ShareLink ref={sharRef} url={url} title={title} quote={quote} />
    </>
  )
}

export default ActionButtonBlock
