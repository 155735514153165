import { useState, useContext } from 'react'
import styles from '../../../../css/traveler/inbox/new_message.module.css'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { SocketContext } from '../../../../context/SocketContext'
import LoadingPoint from '../../../LoadingPoint'
import axios from 'axios'
import { STATUS_MESSAGE } from '../../../../enums/message/status.enums'
import Category from '../../../accounts/profile/profile_descr/Category'

const NewMessage = ({ path, refetch }) => {
  const [text, setText] = useState('')
  const [isLoadingSend, setIsLoadingSend] = useState(false)
  const { socket } = useContext(SocketContext)

  const {
    data: getUser = {},
    isLoading,
    error,
  } = useQuery(`get-user-profil-${path}`, () =>
    axios.get(`/user/${path}`).then(res => {
      return res.data
    })
  )

  const handlemessage = () => {
    setIsLoadingSend(true)
    const datas = {
      text: text,
      status: STATUS_MESSAGE.REQUEST_INFO_FROM_PROFILE,
    }
    axios
      .post(`/message/create_by_profile/${path}`, datas)
      .then(res => {
        setText('')
        refetch()
        socket.emit('sendMessage', {
          receiverId: res.data.host,
          data: res.data,
        })
      })
      .catch(() => {})
      .finally(() => setIsLoadingSend(false))
  }

  const handleKeyDown = e => {
    e.target.style.height = 'inherit'
    e.target.style.height = `${e.target.scrollHeight}px`
  }

  return (
    <div className={styles.container}>
      {isLoading ? (
        <div className={styles.loading_container}>
          <LoadingPoint />
        </div>
      ) : (
        <>
          {error ? (
            <div className={styles.no_result}>
              <p>Une erreur s'est produite veuillez réessayer</p>
            </div>
          ) : (
            <div className={styles.container_content}>
              <div className={styles.wrapper_profile}>
                <Link to={`/profile/${path}`} className={styles.image_block}>
                  {getUser?.picture ? (
                    <img src={getUser?.picture} alt='' />
                  ) : (
                    <svg
                      viewBox='0 0 32 32'
                      xmlns='http://www.w3.org/2000/svg'
                      aria-hidden='true'
                      role='presentation'
                      focusable='false'
                    >
                      <path d='m16 .7c-8.437 0-15.3 6.863-15.3 15.3s6.863 15.3 15.3 15.3 15.3-6.863 15.3-15.3-6.863-15.3-15.3-15.3zm0 28c-4.021 0-7.605-1.884-9.933-4.81a12.425 12.425 0 0 1 6.451-4.4 6.507 6.507 0 0 1 -3.018-5.49c0-3.584 2.916-6.5 6.5-6.5s6.5 2.916 6.5 6.5a6.513 6.513 0 0 1 -3.019 5.491 12.42 12.42 0 0 1 6.452 4.4c-2.328 2.925-5.912 4.809-9.933 4.809z'></path>
                    </svg>
                  )}
                </Link>
                <h2>{getUser?.first_name}</h2>
                <Category getUser={getUser} />
                <p className={styles.guideline}>
                  Vous pouvez envoyer un message. Assurez-vous de suivre nos Community Guidelines et
                  de faire preuve de respect quand vous envoyez des messages pour la première fois.
                </p>
              </div>
              <div className={styles.send_message}>
                <div className={styles.input_block}>
                  <textarea
                    type='text'
                    value={text}
                    onChange={e => setText(e.target.value)}
                    onKeyUp={handleKeyDown}
                    placeholder='Saisissez un message'
                  ></textarea>
                  {text.length > 0 && (
                    <button onClick={handlemessage} disabled={isLoadingSend}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        style={{
                          display: 'block',
                          width: '32',
                          height: '32',
                          fill: 'none',
                        }}
                        viewBox='0 0 32 32'
                      >
                        <path
                          fill='currentColor'
                          fill-rule='evenodd'
                          d='M15.192 8.906a1.143 1.143 0 0 1 1.616 0l5.143 5.143a1.143 1.143 0 0 1-1.616 1.616l-3.192-3.192v9.813a1.143 1.143 0 0 1-2.286 0v-9.813l-3.192 3.192a1.143 1.143 0 1 1-1.616-1.616z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default NewMessage
