import styles from '../../../../css/traveler/inbox/detailMessage.module.css'
import moment from 'moment'
import momentTimeZone from 'moment-timezone'
import { STATUS_MESSAGE } from '../../../../enums/message/status.enums'

const TypeMessage = ({ item, adulte, enfant, bebe, animaux, checkin, checkout, annonce_id }) => {
  const voyageur = adulte + enfant
  const linkHome = `/rooms/${annonce_id}?adults=${adulte}&children=${enfant}&infants=${bebe}&pets=${animaux}&checkin=${moment(
    checkin
  ).format('YYYY-MM-DD')}&checkout=${moment(checkout).format('YYYY-MM-DD')}`

  const userTimezone = momentTimeZone.tz.guess()

  return (
    <>
      {item.status === STATUS_MESSAGE.REQUEST_INFO_FROM_PROFILE && (
        <div className={styles.rappel}>
          <h4>{momentTimeZone.utc(item?.timestamp).tz(userTimezone).format('ll')}</h4>
          <p>Votre message a été envoyé.</p>
        </div>
      )}

      {item.status === STATUS_MESSAGE.REQUEST_INFO && (
        <div className={styles.rappel}>
          <h4>{momentTimeZone.utc(item?.timestamp).tz(userTimezone).format('ll')}</h4>
          <p>
            {`Votre demande d'information pour ${voyageur}
                    voyageur${voyageur > 1 ? 's' : ''}, ${bebe > 0 ? `${bebe} bébés` : ''}, ${
                      animaux > 0 ? `${animaux} animaux` : ''
                    }
             le ${moment(checkin).format('ll')} au ${moment(checkout).format(
               'll'
             )}. a été envoyée.`}
            <a href={linkHome} target='_blank' rel='noreferrer'>
              Afficher l'annonce
            </a>
          </p>
        </div>
      )}

      {item.status === STATUS_MESSAGE.RESERVATION_REQUEST && (
        <div className={styles.rappel}>
          <h4>{momentTimeZone.utc(item?.timestamp).tz(userTimezone).format('ll')}</h4>
          <p>
            {`Votre demande de réservation pour ${voyageur}
                    voyageur${voyageur > 1 ? 's' : ''}, ${bebe > 0 ? `${bebe} bébés` : ''}, ${
                      animaux > 0 ? `${animaux} animaux` : ''
                    }
             le ${moment(checkin).format('ll')} au ${moment(checkout).format(
               'll'
             )}. a été envoyée.`}
            <a href={linkHome} target='_blank' rel='noreferrer'>
              Afficher l'annonce
            </a>
          </p>
        </div>
      )}

      {item.status === STATUS_MESSAGE.DIRECT_RESERVATION && (
        <div className={styles.rappel}>
          <h4>{momentTimeZone.utc(item?.timestamp).tz(userTimezone).format('ll')}</h4>
          <p>
            {`Votre réservation pour ${voyageur}
                    voyageur${voyageur > 1 ? 's' : ''}, ${bebe > 0 ? `${bebe} bébés` : ''}, ${
                      animaux > 0 ? `${animaux} animaux` : ''
                    }
             le ${moment(checkin).format('ll')} au ${moment(checkout).format(
               'll'
             )}. a été confirmée.`}
            <a href={`/reservations/details/${item?.reservation}`} target='_blank' rel='noreferrer'>
              Afficher la réservation
            </a>
          </p>
        </div>
      )}

      {item.status === STATUS_MESSAGE.MODIFICATION_REQUEST && (
        <div className={styles.rappel}>
          <h4>{momentTimeZone.utc(item?.timestamp).tz(userTimezone).format('ll')}</h4>
          <p>
            {`Votre demande de modification de dates pour le ${moment(checkin).format(
              'll'
            )} au ${moment(checkout).format('ll')}. a été envoyée.`}
            <a href={`/reservations/details/${item?.reservation}`} target='_blank' rel='noreferrer'>
              Afficher la réservation
            </a>
          </p>
        </div>
      )}
    </>
  )
}

export default TypeMessage
