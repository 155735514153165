import styles from '../../../css/details/message.module.css'

const Succes = ({ handleCloseModal }) => {
  return (
    <>
      <div className={styles.succes_message}>
        <div>
          <h3>Message envoyé</h3>
          <p>Vous devriez recevoir une réponse de l'hôte sous 24 heures. </p>
        </div>
      </div>
      <div className={styles.button_action}>
        <button className={styles.next} onClick={handleCloseModal}>
          Ok
        </button>
      </div>
    </>
  )
}

export default Succes
