import { memo, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { OverlayView, OverlayViewF } from '@react-google-maps/api'
import usePrice from '../../../../hooks/usePrice'
import styles from '../../../../css/search_room_result/maps.module.css'

const FavoriteIcon = memo(() => (
  <svg
    viewBox='0 0 32 32'
    xmlns='http://www.w3.org/2000/svg'
    aria-hidden='true'
    role='presentation'
    focusable='false'
  >
    <path d='m16 28c7-4.733 14-10 14-17 0-1.792-.683-3.583-2.05-4.95-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05l-2.051 2.051-2.05-2.051c-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05-1.367 1.367-2.051 3.158-2.051 4.95 0 7 7 12.267 14 17z'></path>
  </svg>
))

const CardSmile = memo(
  ({ item, childhover, childClicked, setChildClicked, listVisited, setListVisited }) => {
    const favoris = useSelector(state => state.getFavorisReducer)
    const { pricePerNightWithoutPromo } = usePrice(item)

    const filteredArray = useMemo(
      () =>
        favoris
          ?.filter(el => el.lists.some(lists => lists.hotel?._id === item?._id))
          .map(el => {
            let n = Object.assign({}, el, {
              lists: el.lists.filter(lists => lists.hotel?._id === item?._id),
            })
            return n
          }),
      [favoris, item]
    )

    const handleClick = useCallback(
      event => {
        event.stopPropagation()
        setChildClicked(item)
        setListVisited(prev => [...prev, item._id])
      },
      [item, setChildClicked, setListVisited]
    )

    const isVisited = listVisited.includes(item._id)
    const isActive = childClicked?._id === item._id
    const hasFavorites = filteredArray.length > 0

    return (
      <OverlayViewF
        position={{ lat: item.location?.coordinates[1], lng: item.location?.coordinates[0] }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <div className={childhover === item._id ? `${styles.hoverActive}` : ''}>
          <div className={isVisited ? `${styles.annonce_visited}` : ''}>
            <button
              onClick={handleClick}
              className={isActive ? `${styles.anonce} ${styles.active}` : styles.anonce}
            >
              {pricePerNightWithoutPromo}
              {hasFavorites && <FavoriteIcon />}
            </button>
          </div>
        </div>
      </OverlayViewF>
    )
  }
)

export default CardSmile
