const PROPERTY_TYPE_GROUP = {
  HOTEL: 'hotel',
  HOUSE: 'maison',
  APARTMENT: 'appartement',
  ANNEX: 'annexe',
  UNIQUE_ACCOMMODATION: 'logement_unique',
  GUEST_HOUSE: 'guest_house',
  OTHER_NON_RESIDENTIAL_ACCOMMODATION: 'autre_logement_non_residentiel',
}
export { PROPERTY_TYPE_GROUP }
