import { useRef, useState } from 'react'
import styles from '../../../../css/traveler/favoris/favoriListe.module.css'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import Swal from 'sweetalert2'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Navigation, Pagination } from 'swiper/modules'
import useHotelRating from '../../../../hooks/useHotelRating'
import usePrice from '../../../../hooks/usePrice'
import { TYPE_LOGEMENT } from '../../../../enums/logement/type_logement.enums'
import { HOST_STATUS_ACTIVITY } from '../../../../enums/logement/host_status_activity.enums'
import StarSvg from '../../../svgs/StarSvg'
import HostProfileAndMore from '../../../search_room_result/components/card/HostProfileAndMore'

const CardList = ({ item, favoris, user, refetch, setChildchildhover, setChildClicked }) => {
  const [prev, setPrev] = useState(false)
  const [nex, setNex] = useState(true)
  const { commentLength, totalComment } = useHotelRating(item)
  const { pricePerNightWithoutPromo } = usePrice(item)
  const location = useLocation()
  const id = location.pathname.split('/')[2]

  const tailWindow = window.matchMedia('(min-width:743px)')

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  const handleDeleteAnnonceInlist = () => {
    if (user?.id === favoris?.user_id) {
      Swal.fire({
        title: 'Supprimer cette annonce ?',
        text: "Une fois l'annonce supprimée de la liste, vous ne pourrez plus la récupérer.",
        showCancelButton: true,
        confirmButtonText: "Supprimer l'annonce",
        cancelButtonText: 'Annuler',
        reverseButtons: true,
        customClass: {
          actions: styles.actions_class,
          confirmButton: styles.confirm_button_class,
          cancelButton: styles.cancel_button_class,
        },
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .put(`/favoris/delete/${id}/${item._id}`)
            .then(res => {
              setChildClicked(null)
              refetch()
            })
            .catch(() => {})
        }
      })
    }
  }

  return (
    <>
      <div
        className={styles.hotel_activite}
        onMouseEnter={() => setChildchildhover(item._id)}
        onMouseLeave={() => setChildchildhover(null)}
      >
        <div className={styles.hotel_image}>
          <HostProfileAndMore item={item} handleDeleteAnnonceInlist={handleDeleteAnnonceInlist} />
          <div className={styles.container}>
            <Swiper
              modules={[Navigation, Pagination]}
              pagination={{
                dynamicBullets: true,
              }}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onBeforeInit={swiper => {
                swiper.params.navigation.prevEl = navigationPrevRef.current
                swiper.params.navigation.nextEl = navigationNextRef.current
              }}
              onSlideNextTransitionStart={() => setPrev(true)}
              onReachBeginning={() => setPrev(false)}
              onSlidePrevTransitionStart={() => setNex(true)}
              onReachEnd={() => setNex(false)}
              className={styles.block_photo_result}
            >
              {item.images?.map((photo, i) => (
                <SwiperSlide key={i}>
                  <Link
                    target={tailWindow.matches ? '_blank' : null}
                    rel='noopener noreferrer'
                    to={`/rooms/${item._id}`}
                  >
                    <div className={styles.imgslider}>
                      <img src={photo.url} alt='photos' />
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
              <div
                ref={navigationPrevRef}
                className={prev ? styles.prev : `${styles.prev} ${styles.disable}`}
              >
                <svg
                  viewBox='0 0 32 32'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'
                  role='presentation'
                  focusable='false'
                >
                  <g>
                    <path d='m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932'></path>
                  </g>
                </svg>
              </div>
              <div
                ref={navigationNextRef}
                className={nex ? styles.next : `${styles.next} ${styles.disable}`}
              >
                <svg
                  viewBox='0 0 32 32'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'
                  role='presentation'
                  focusable='false'
                >
                  <g>
                    <path d='m12 4 11.2928932 11.2928932c.3905243.3905243.3905243 1.0236893 0 1.4142136l-11.2928932 11.2928932'></path>
                  </g>
                </svg>
              </div>
            </Swiper>
            {item.superhote && <span className={styles.infos}>superhôte</span>}
            {user?.id === favoris?.user_id && (
              <svg
                onClick={handleDeleteAnnonceInlist}
                className={styles.fav_svg}
                viewBox='0 0 32 32'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
              >
                <path d='m16 28c7-4.733 14-10 14-17 0-1.792-.683-3.583-2.05-4.95-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05l-2.051 2.051-2.05-2.051c-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05-1.367 1.367-2.051 3.158-2.051 4.95 0 7 7 12.267 14 17z'></path>
              </svg>
            )}
          </div>
          <Link
            target={tailWindow.matches ? '_blank' : null}
            rel='noopener noreferrer'
            to={`/rooms/${item._id}`}
          >
            <div className={styles.hotel_descrip}>
              <div>
                <div className={styles.hotel_header}>
                  <div className={styles.type}>
                    {item.accommodation_type === TYPE_LOGEMENT.ENTIRE_HOME && (
                      <h3>
                        Logement entier :{' '}
                        {item.about?.host_activity_status === HOST_STATUS_ACTIVITY.PARTICULAR
                          ? 'particulier'
                          : 'professionnel'}
                      </h3>
                    )}
                    {item.accommodation_type === TYPE_LOGEMENT.PRIVATE_ROOM && (
                      <h3>
                        Chambre privée :{' '}
                        {item.about?.host_activity_status === HOST_STATUS_ACTIVITY.PARTICULAR
                          ? 'particulier'
                          : 'professionnel'}
                      </h3>
                    )}
                    {item.accommodation_type === TYPE_LOGEMENT.SHARED_ROOM && (
                      <h3>
                        Chambre partagée :{' '}
                        {item.about?.host_activity_status === HOST_STATUS_ACTIVITY.PARTICULAR
                          ? 'particulier'
                          : 'professionnel'}
                      </h3>
                    )}
                  </div>
                  <div className={styles.hotel_evaluation}>
                    {commentLength > 0 ? (
                      <div className={styles.block_eval}>
                        <StarSvg width={12} height={12} />
                        <p>
                          {totalComment} ({commentLength} Commentaire
                          {commentLength > 1 ? 's' : ''})
                        </p>
                      </div>
                    ) : (
                      <div className={styles.block_eval}>
                        <StarSvg width={12} height={12} />
                        <p>Nouveau</p>
                      </div>
                    )}
                  </div>
                </div>
                <h4 className={styles.name}>{item.title}</h4>
                <p className={styles.text_descrip}>{item.advantage_marketing}</p>
              </div>
              <div className={styles.container_desc}>
                <div className={styles.descrip_detail}>
                  <p>
                    {item.details?.traveler} {item.details?.traveler > 1 ? 'voyageurs' : 'voyageur'}
                  </p>
                  <span></span>
                  <p>
                    {item.details?.number_of_rooms}{' '}
                    {item.details?.number_of_rooms > 1 ? 'chambres' : 'chambre'}
                  </p>
                  <span></span>
                  <p>
                    {item.details?.number_of_beds}{' '}
                    {item.details?.number_of_beds > 1 ? 'lits' : 'lit'}
                  </p>
                  <span></span>
                  <p className={styles.end}>
                    {item.details?.number_of_bathrooms}{' '}
                    {item.details?.number_of_bathrooms > 1 ? 'salles de bain' : 'salle de bain'}
                  </p>
                </div>
                <div className={styles.descrip_droite}>
                  <div className={styles.prix_bloc}>
                    <p className={styles.descrip_prix}>
                      {pricePerNightWithoutPromo} <span>/ nuit</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  )
}

export default CardList
