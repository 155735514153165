import style from '../../../../css/modehotes/freemode/freemode.module.css'

const FreeMode = () => {
  return (
    <div className={style.delay_free}>
      <h2>FreeMode</h2>
    </div>
  )
}

export default FreeMode
