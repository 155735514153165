import styles from '../../../css/becomeHostes/home/section_2.module.css'
import { Link } from 'react-router-dom'
import women from '../../../assets/become_host/women.png'
import LoadingPoint from '../../LoadingPoint'

const Section2 = ({ user, isLoading, referrer, handleConnection }) => {
  return (
    <div className={styles.section_2}>
      <h3>Accueillez les voyageurs comme bon vous semble, peu importe où vous vous trouvez</h3>
      <div className={styles.action_block}>
        <div className={styles.text}>
          <div>
            <h4>Mettez votre logement sur Gowifmi et gagnez</h4>
            <p>
              Un accompagnement personnalisé par un membre de Gowifmi qui vous guide de la première
              question à votre première réservation, que ce soit par téléphone, chat ou appel vidéo.
            </p>
            {user ? (
              <div>
                {isLoading ? (
                  <button type='button'>
                    <LoadingPoint />
                  </button>
                ) : (
                  <Link
                    to={
                      user.isHost
                        ? `/become-a-host`
                        : `/become-a-host/type-property-group${
                            referrer ? `?referrer=${referrer?.id}` : ''
                          }`
                    }
                  >
                    Commencer
                  </Link>
                )}
              </div>
            ) : (
              <button onClick={handleConnection}>En savoir plus</button>
            )}
          </div>
        </div>
        <div className={styles.img_content}>
          <img src={women} alt='' />
        </div>
      </div>
    </div>
  )
}

export default Section2
