import styles from '../../../../../../../css/modehotes/annonce/details/content_head.module.css'
import CloseButton from '../../../../../../ui/CloseButton'

const ContenHead = ({ title, handleReset, children }) => {
  return (
    <div className={styles.c_head}>
      <div className={styles.descr}>
        <h4>{title}</h4>
        <CloseButton onClick={handleReset} />
      </div>
      {children}
    </div>
  )
}

export default ContenHead
