import styles from '../../../../css/modehotes/navbar/dropdown_link.module.css'
import { Link } from 'react-router-dom'
import SvgIconRightDirector from '../../../svgs/SvgIconRightDirector'

const Annonce = () => {
  return (
    <Link to='/hosting/listings' className={styles.block_link}>
      <div className={styles.left_block}>
        <svg
          className={styles.host_mode}
          aria-label='Mode hote'
          role='img'
          viewBox='0 0 24 24'
          strokeWidth='2'
        >
          <path
            d='M9.005 16.545a2.997 2.997 0 0 1 2.997-2.997A2.997 2.997 0 0 1 15 16.545V22h7V11.543L12 2 2 11.543V22h7.005Z'
            strokeLinejoin='round'
          ></path>
        </svg>
        <h3>Annonces</h3>
      </div>
      <div className={styles.rigth_block}>
        <SvgIconRightDirector />
      </div>
    </Link>
  )
}

export default Annonce
