import React, { useState, useEffect, useRef } from 'react'
import styles from '../../css/details/detail_price.module.css'
import usePrice from '../../hooks/usePrice'
import { confirmBooking } from '../../utils/booking'
import Modal from '../ui/Modal'
import CloseButton from '../ui/CloseButton'
import useModalByUrl from '../../hooks/useModalByUrl'
import CloseSvg from '../svgs/CloseSvg'

const DetailPrice = React.forwardRef(({ hotel }, ref) => {
  const [menage, setMenage] = useState(false)
  const [promo, setPromo] = useState(false)
  const [service, setService] = useState(false)

  const modalValue = 'DETAIL_PRICE'
  const { modalState, handleOpenModal, handleCloseModal } = useModalByUrl(modalValue)

  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      handleOpenModal()
    },
  }))
  const {
    pricePerNight,
    totalPricePerNight,
    cleaningFee,
    cleaningFeeORG,
    reduction,
    reductionTime,
    totalWithservice,
    promoFee,
    travelerServiceFee,
    numberOfNight,
  } = usePrice(hotel)

  //fonction de detection lorsqu'on click en dehor du composant
  const contentMenage = useRef(null)
  const contentPromo = useRef(null)
  const contentService = useRef(null)
  useEffect(() => {
    const handleClickOutside = event => {
      if (contentMenage.current && !contentMenage.current.contains(event.target)) {
        setMenage(false)
      }
      if (contentPromo.current && !contentPromo.current.contains(event.target)) {
        setPromo(false)
      }
      if (contentService.current && !contentService.current.contains(event.target)) {
        setService(false)
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return (
    <>
      {/*----modal apropos de l'hotel---------*/}
      <Modal isOpen={modalState} onClose={handleCloseModal}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={handleCloseModal} />
            <h3>Décompte du prix</h3>
            <div></div>
          </div>
          <div className={styles.m_body}>
            {confirmBooking(hotel) ? null : (
              <h4>Aucun montant ne vous sera débité pour le moment</h4>
            )}
            <div className={styles.detail_price}>
              <div className={styles.details}>
                <button className={styles.left}>
                  <p className={styles.price}>
                    {pricePerNight} x {numberOfNight} {numberOfNight > 1 ? 'nuits' : 'nuit'}
                  </p>
                </button>
                <p>{totalPricePerNight}</p>
              </div>
              {cleaningFeeORG > 0 && (
                <div className={styles.details} ref={contentMenage}>
                  <button className={styles.left}>
                    <p onClick={() => setMenage(!menage)}>Frais de ménage</p>
                  </button>
                  <p>{cleaningFee}</p>
                  <div className={menage ? `${styles.descript} ${styles.active}` : styles.descript}>
                    <p>
                      Frais facturés une seule fois par l'hôte pour couvrir les dépenses de
                      nettoyage de son logement.
                    </p>
                    <div>
                      <span onClick={() => setMenage(false)} className={styles.close}>
                        <CloseSvg />
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {reduction > 0 && (
                <div className={styles.details} ref={contentPromo}>
                  <button className={styles.left}>
                    <p onClick={() => setPromo(!promo)}>{reductionTime}</p>
                  </button>
                  <p className={styles.promo}>-{promoFee}</p>
                  <div className={promo ? `${styles.descript} ${styles.active}` : styles.descript}>
                    <p>À vos dates, le tarif est inférieur de {reduction} %</p>
                    <div>
                      <span onClick={() => setPromo(false)} className={styles.close}>
                        <CloseSvg />
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.details} ref={contentService}>
                <button className={styles.left}>
                  <p onClick={() => setService(!service)}>Frais de service</p>
                </button>
                <p>{travelerServiceFee}</p>
                <div className={service ? `${styles.descript} ${styles.active}` : styles.descript}>
                  <p>
                    Ces frais participent au fonctionnement de notre plate-forme et nous permettent
                    d'offrir des services comme l'assistance 24h/24 pendant votre voyage.
                  </p>
                  <div>
                    <span onClick={() => setService(false)} className={styles.close}>
                      <CloseSvg />
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.total}>
                <p>Total</p>
                <p>{totalWithservice}</p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
})

export default DetailPrice
