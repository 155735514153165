import { useState } from 'react'
import styles from '../../../css/account/profile/cover_image.module.css'
import LoadingPoint from '../../LoadingPoint'
import { useDispatch, useSelector } from 'react-redux'
import { uploadCoverImage } from '../../../redux/actions'
import axios from 'axios'

const CoverImage = ({ userConnected, getUser }) => {
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const userReducestore = useSelector(state => state.userReducer)

  const handlePicture = async e => {
    e.preventDefault()
    setLoading(true)
    const file = e.target.files[0]
    const data = new FormData()
    data.append('name', userConnected?.name)
    data.append('user_Id', userConnected?.id)
    data.append('photo', file)

    axios
      .post(`/user/upload_cover_image`, data)
      .then(res => {
        dispatch(uploadCoverImage(res.data.file))
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  if (!getUser?.cover_picture && getUser?.id !== userConnected?.id) return null

  return (
    <div>
      <div className={styles.wrapper_img_profile}>
        <div className={styles.image}>
          {getUser?.id === userConnected?.id ? (
            <>
              {userReducestore?.cover_picture && (
                <img src={userReducestore?.cover_picture} alt='' />
              )}
            </>
          ) : (
            <>{getUser?.cover_picture && <img src={getUser?.cover_picture} alt='' />}</>
          )}
        </div>

        {getUser?.id === userConnected?.id && (
          <form onSubmit={handlePicture} className={styles.btn_update}>
            <input
              type='file'
              id='photo_cover'
              accept='.jpg, .jpeg, .png'
              disabled={loading}
              onChange={handlePicture}
            />

            <label htmlFor='photo_cover'>
              {loading ? (
                <LoadingPoint />
              ) : (
                <>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 32 32'
                    aria-hidden='true'
                    role='presentation'
                    focusable='false'
                  >
                    <path d='M17.59 2a2.01 2.01 0 0 1 1.41.59L21.41 5H25a5 5 0 0 1 5 5v14a5 5 0 0 1-5 5H7a5 5 0 0 1-5-5V10a5 5 0 0 1 5-5h3.62L13 2.6a2.01 2.01 0 0 1 1.42-.6zM16 8.5a8.51 8.51 0 0 0 0 17 8.51 8.51 0 0 0 0-17zm0 3a5.5 5.5 0 1 1 0 11 5.5 5.5 0 0 1 0-11zm-10-3a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z'></path>
                  </svg>
                </>
              )}
            </label>
          </form>
        )}
      </div>
    </div>
  )
}

export default CoverImage
