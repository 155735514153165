import { createContext, useContext, useEffect, useState } from 'react'
import { io } from 'socket.io-client'
import { User } from './UserContext'
import { baseURL } from '../config/config'

export const SocketContext = createContext()

export const SocketContextProvider = ({ children }) => {
  const currentUser = useContext(User)
  const [socket, setSocket] = useState(null)

  useEffect(() => {
    const newSocket = io(baseURL)
    setSocket(newSocket)

    newSocket.on('connect', () => {
      if (currentUser) {
        newSocket.emit('newUser', currentUser.id)
      }
    })

    return () => {
      newSocket.disconnect()
    }
  }, [])

  return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>
}
