import { useState } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const FeeCleaning = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [fraisMenage, setFraisMenage] = useState()
  const { loading, updateData } = useUpdateHotel(setOpen)

  const minmum = 5
  const maximum = 595
  const handleSubmit = e => {
    e.preventDefault()
    const datas = {
      prices: {
        ...annonce.prices,
        cleaning_fee: fraisMenage,
      },
    }
    updateData(datas)
  }
  const openFuction = () => {
    setFraisMenage(annonce.prices?.cleaning_fee)
    setOpen(true)
  }
  const reset = () => {
    setFraisMenage()
    setOpen(false)
  }
  const handleChange = event => {
    const result = event.target.value.replace(/\D/g, '')
    setFraisMenage(result)
  }
  return (
    <div className={styles.block}>
      {!open ? (
        <Section title='Frais de ménage' handleOpen={openFuction}>
          <p>
            {annonce.prices?.cleaning_fee
              ? `${annonce.prices?.cleaning_fee}$`
              : "Aucun frais de ménage n'est facturé"}
          </p>
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title='Frais de ménage' handleReset={reset}>
            <p>Fixez des frais de ménage pour couvrir le nettoyage après chaque séjour.</p>
          </ContenHead>
          <form onSubmit={handleSubmit}>
            <div className={styles.body}>
              <div
                className={
                  fraisMenage < minmum || fraisMenage > maximum
                    ? `${styles.input_block} ${styles.error}`
                    : styles.input_block
                }
              >
                <input type='tel' value={fraisMenage} onChange={handleChange} />
                <div className={styles.price}>
                  <h5>{fraisMenage && fraisMenage} $</h5>
                  {fraisMenage < minmum || fraisMenage > maximum ? (
                    <p>
                      Les frais de ménage doivent être compris entre {minmum} $ et {maximum} $.
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <BottomForm
              disabled={fraisMenage < minmum || fraisMenage > maximum}
              loading={loading}
              handleReset={reset}
            />
          </form>
        </div>
      )}
    </div>
  )
}

export default FeeCleaning
