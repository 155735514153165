import { Link } from 'react-router-dom'
import styles from '../../../../../../css/modehotes/annonce/details/equipement.module.css'
import SvgIconRightDirector from '../../../../../svgs/SvgIconRightDirector'

const Amenitie = ({ annonce }) => {
  const Query = window.location.search

  return (
    <div className={styles.block}>
      <div>
        <div className={styles.head}>
          <h3>Équipements</h3>
          <Link to={`/hosting/listings/details/${annonce._id}/amenities${Query}`}>
            <span>Modifier</span>
            <SvgIconRightDirector />
          </Link>
        </div>
        <div className={styles.main}>
          <p>
            Vous avez séléctionné <strong>{annonce.amenities?.length}</strong>{' '}
            {annonce.amenities?.length > 1 ? 'Équipements' : 'Équipement'} au total
          </p>
        </div>
      </div>
    </div>
  )
}

export default Amenitie
