import { useHistory } from 'react-router-dom'

function useModalByUrl(modalValue) {
  const history = useHistory()
  const id = window.location.pathname.split('/')[2]
  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  const modalState = Params.get('modal') === modalValue

  const handleOpenModal = () => {
    Params.append('modal', modalValue)
    history.push(`/rooms/${id}?${Params}`)
  }

  const handleCloseModal = () => {
    if (history.length > 1) {
      history.goBack()
    } else {
      Params.delete('modal', modalValue)
      history.push(`/rooms/${id}?${Params}`)
    }
  }

  return { modalState, handleOpenModal, handleCloseModal }
}

export default useModalByUrl
