import styles from '../../../../../css/modehotes/annonce/details/securiteVoyageur.module.css'
import Checkbox from '../../../../ui/Checkbox'

const AmenitieRestruction = ({
  restrictionsApplicablesEquipements,
  setRestrictionsApplicablesEquipements,
}) => {
  return (
    <div className={styles.checkbox_container}>
      <div>
        <label htmlFor='restrictionsApplicablesEquipements'>
          Restrictions applicables aux équipements
        </label>
        <p>
          Les voyageurs doivent s'attendre à ce que certains équipements de base ne soient pas
          disponibles. Par exemple : wifi, eau courante, douche intérieure.
        </p>
      </div>
      <div>
        <Checkbox
          id='restrictionsApplicablesEquipements'
          value={restrictionsApplicablesEquipements}
          checked={restrictionsApplicablesEquipements}
          onChange={() =>
            setRestrictionsApplicablesEquipements(!restrictionsApplicablesEquipements)
          }
        />
      </div>
    </div>
  )
}

export default AmenitieRestruction
