import style from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import Checkbox from '../../../../../ui/Checkbox'
const Options = ({ joursDepartNonAutorisers, setJoursDepartNonAutorisers }) => {
  const array = [
    { name: 'Dimanche', value: '0' },
    { name: 'Lundi', value: '1' },
    { name: 'Mardi', value: '2' },
    { name: 'Mercredi', value: '3' },
    { name: 'Jeudi', value: '4' },
    { name: 'vendredi', value: '5' },
    { name: 'Samedi', value: '6' },
  ]
  const onchangeFilters = e => {
    if (e.target.checked === true) {
      setJoursDepartNonAutorisers([...joursDepartNonAutorisers, e.target.value])
    } else {
      const newArr = [...joursDepartNonAutorisers].filter(item => item !== e.target.value)
      setJoursDepartNonAutorisers(newArr)
    }
  }
  return (
    <>
      <div className={style.grid_system}>
        {array.map(item => (
          <div key={item.name} className={style.checkbox_container}>
            <div>
              <Checkbox
                id={item.name}
                value={item.value}
                checked={joursDepartNonAutorisers.includes(item.value)}
                onChange={onchangeFilters}
              />
            </div>
            <label htmlFor={item.name}>{item.name}</label>
          </div>
        ))}
      </div>
    </>
  )
}

export default Options
