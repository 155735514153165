import styles from '../../../../../../css/modehotes/annonce/details/reglementInterieur.module.css'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import Checkbox from '../../../../../ui/Checkbox'

const BabyRegulation = ({ annonce }) => {
  const { updateData } = useUpdateHotel()

  const onchangeFilters = e => {
    if (e.target.checked === true) {
      const datas = {
        regulations: {
          ...annonce.regulations,
          accept_babies: true,
        },
      }
      updateData(datas)
    } else {
      const datas = {
        regulations: {
          ...annonce.regulations,
          accept_babies: false,
        },
      }
      updateData(datas)
    }
  }
  return (
    <div>
      <div className={styles.checkbox_container}>
        <div className={styles.text}>
          <label htmlFor='bébés'>Convient aux bébés (moins de 2 ans)</label>
          {annonce.regulations?.accept_babies ? (
            <p>Votre logement convient aux bébés.</p>
          ) : (
            <p>Votre logement ne convient pas aux bébés.</p>
          )}
        </div>
        <div>
          <Checkbox
            id='bébés'
            value={true}
            checked={annonce.regulations?.accept_babies}
            onChange={onchangeFilters}
          />
        </div>
      </div>
    </div>
  )
}

export default BabyRegulation
