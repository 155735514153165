import styles from '../../css/searchModal/voyageur.module.css'
import { useEffect, useRef } from 'react'
import CloseBtnSearch from '../ui/CloseBtnSearch'
import UsersSvg from '../svgs/UsersSvg'
import { DecrementCounter, IncrementCounter } from '../svgs/TravelerCounter'

const Traveler = ({
  voyageurModal,
  setVoyageurModal,
  adulte,
  setAdulte,
  enfant,
  setEnfant,
  bebe,
  setBebe,
  animal,
  setAnimal,
  handleSearch,
}) => {
  const voyageur = adulte + enfant

  const contentVoyageur = useRef(null)
  useEffect(() => {
    const handleClickOutside = event => {
      if (contentVoyageur.current && !contentVoyageur.current.contains(event.target)) {
        setVoyageurModal(false)
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])
  const incrementeAdulte = () => {
    setAdulte(c => c + 1)
  }
  const decrementeAdulte = () => {
    setAdulte(c => c - 1)
  }
  const incrementeEnfant = () => {
    setEnfant(c => c + 1)
  }
  const decrementeEnfant = () => {
    setEnfant(c => c - 1)
  }
  const incrementeBebe = () => {
    setBebe(c => c + 1)
  }
  const decrementeBebe = () => {
    setBebe(c => c - 1)
  }
  const incrementeAnimal = () => {
    setAnimal(c => c + 1)
  }
  const decrementeAnimal = () => {
    setAnimal(c => c - 1)
  }

  return (
    <div
      className={voyageurModal ? `${styles.voyageur} ${styles.active}` : styles.voyageur}
      ref={contentVoyageur}
    >
      <div className={styles.infos} onClick={() => setVoyageurModal(!voyageurModal)}>
        <div className={styles.infos_icon}>
          <UsersSvg />
        </div>
        <p>
          {voyageur} {voyageur > 1 ? 'Voyageurs' : 'Voyageur'}
          {bebe > 0 && `, ${bebe} ${bebe > 1 ? 'Bébés' : 'Bébé'}`}{' '}
          {animal > 0 && `, ${animal} ${animal > 1 ? 'Animaux' : 'Animal'}`}
        </p>
      </div>
      {/*--------section information a entrer------------*/}
      <div className={styles.infoModal}>
        <CloseBtnSearch onClick={() => setVoyageurModal(false)} />
        <div className={styles.info_search}>
          <h2>Qui vient ?</h2>
          <div className={styles.m_body}>
            <div className={styles.block_flex}>
              <div>
                <h3>Adultes</h3>
              </div>
              <div className={styles.occup_count}>
                <button
                  type='button'
                  disabled={adulte === 1 ? true : false}
                  className={adulte === 1 ? styles.disable : null}
                  onClick={decrementeAdulte}
                >
                  <DecrementCounter />
                </button>
                <span>{adulte}</span>
                <button
                  type='button'
                  disabled={adulte >= 30 ? true : false}
                  className={adulte === 30 ? styles.disable : null}
                  onClick={incrementeAdulte}
                >
                  <IncrementCounter />
                </button>
              </div>
            </div>
            <div className={styles.block_flex}>
              <div>
                <h3>Enfants</h3>
                <p>De 2 à 12 ans</p>
              </div>
              <div className={styles.occup_count}>
                <button
                  type='button'
                  disabled={enfant === 0 ? true : false}
                  className={enfant === 0 ? styles.disable : null}
                  onClick={decrementeEnfant}
                >
                  <DecrementCounter />
                </button>
                <span>{enfant}</span>
                <button
                  type='button'
                  disabled={enfant >= 10 ? true : false}
                  className={enfant === 10 ? styles.disable : null}
                  onClick={incrementeEnfant}
                >
                  <IncrementCounter />
                </button>
              </div>
            </div>
            <div className={styles.block_flex}>
              <div>
                <h3>Bébés</h3>
                <p>- de 2 ans</p>
              </div>
              <div className={styles.occup_count}>
                <button
                  type='button'
                  disabled={bebe === 0 ? true : false}
                  className={bebe === 0 ? styles.disable : null}
                  onClick={decrementeBebe}
                >
                  <DecrementCounter />
                </button>
                <span>{bebe}</span>
                <button
                  type='button'
                  disabled={bebe >= 5 ? true : false}
                  className={bebe === 5 ? styles.disable : null}
                  onClick={incrementeBebe}
                >
                  <IncrementCounter />
                </button>
              </div>
            </div>
            <div className={styles.block_flex}>
              <div>
                <h3>Animaux domestiques</h3>
              </div>
              <div className={styles.occup_count}>
                <button
                  type='button'
                  disabled={animal === 0 ? true : false}
                  className={animal === 0 ? styles.disable : null}
                  onClick={decrementeAnimal}
                >
                  <DecrementCounter />
                </button>
                <span>{animal}</span>
                <button
                  type='button'
                  disabled={animal >= 5 ? true : false}
                  className={animal === 5 ? styles.disable : null}
                  onClick={incrementeAnimal}
                >
                  <IncrementCounter />
                </button>
              </div>
            </div>
          </div>
          <div className={styles.m_footer}>
            <button type='button' onClick={() => setVoyageurModal(false)}>
              Passer
            </button>
            <button
              type='button'
              onClick={() => {
                setVoyageurModal(false)
                handleSearch()
              }}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 32 32'
                aria-hidden='true'
                role='presentation'
                focusable='false'
                style={{
                  display: 'block',
                  fill: 'none',
                  height: '16px',
                  width: '16px',
                  stroke: 'currentcolor',
                  strokeWidth: '4',
                  overflow: 'visible',
                }}
              >
                <path fill='none' d='M13 24a11 11 0 1 0 0-22 11 11 0 0 0 0 22zm8-3 9 9'></path>
              </svg>
              <span>Rechercher</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Traveler
