import styles from '../../../../css/search_room_result/card.module.css'

const Description = ({ item }) => {
  return (
    <div>
      <h4 className={styles.name}>{item.title}</h4>
      {item.advantage_marketing && (
        <p className={styles.text_descrip}>{item.advantage_marketing}</p>
      )}
      <div className={styles.equipement}>
        {item.amenities.includes('1') && <span>Wifi</span>}
        {item.amenities.includes('8') && <span>Climatisation</span>}
        {item.amenities.includes('17') && <span>Petit déjeuner</span>}
      </div>
    </div>
  )
}

export default Description
