import moment from 'moment'
import { SATATUS_ANNONCE } from '../enums/logement/status.enums'

function useDisponibilite(hotel, checkinDetail, checkoutDetail) {
  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  const checkin = checkinDetail ? checkinDetail : Params.get('checkin')
  const checkout = checkoutDetail ? checkoutDetail : Params.get('checkout')
  const adulte = parseInt(Params.get('adults'))
  const enfant = parseInt(Params.get('children'))

  const date_start = new Date(checkin).setHours(0, 0, 0, 0)
  const date_end = new Date(checkout).setHours(0, 0, 0, 0)

  const total_days = (date_end - date_start) / (1000 * 60 * 60 * 24)
  const nuit = Math.round(total_days)

  const today = new Date()
  const Datenow = today.setHours(0, 0, 0, 0)
  const arriver = new Date(checkin).getDay()
  const depart = new Date(checkout).getDay()

  const voyageur = adulte + enfant || 1
  const maxTraveler = hotel?.details?.traveler
  const numberOfTravelerIsInvalid = voyageur > maxTraveler ? true : false

  const invalidDate = () => {
    if (
      date_start >= Datenow &&
      date_end > date_start &&
      hotel?.gowifmi_published &&
      hotel?.status === SATATUS_ANNONCE.PUBLISH &&
      nuit >= hotel?.stay_duration?.minimum &&
      nuit <= hotel?.stay_duration?.maximum
    ) {
      return false
    } else {
      return true
    }
  }

  const datesBloquer = () => {
    let dateArray = []

    let currentDate = moment(checkin)
    let stopDate = moment(checkout)
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
      currentDate = moment(currentDate).add(1, 'days')
    }

    for (const element of dateArray) {
      if (hotel?.reservation_dates?.includes(element)) {
        return true
      }
    }
    return false
  }

  const setStartDate = () => {
    if (hotel?.availability_calendar?.prohibited_checkin_day.includes(arriver.toString())) {
      return true
    } else {
      return false
    }
  }

  const setEndDate = () => {
    if (hotel?.availability_calendar?.prohibited_checkout_day.includes(depart.toString())) {
      return true
    } else {
      return false
    }
  }

  const dateIsinvalid = invalidDate()
  const dateIsinvalidBloquer = datesBloquer()
  const startDateInvalid = setStartDate()
  const endDateInvalid = setEndDate()

  const handleVerifDisponibilite = () => {
    if (
      voyageur > maxTraveler ||
      dateIsinvalid ||
      dateIsinvalidBloquer ||
      startDateInvalid ||
      endDateInvalid
    ) {
      return true
    } else {
      return false
    }
  }

  return {
    numberOfTravelerIsInvalid,
    dateIsinvalid,
    dateIsinvalidBloquer,
    startDateInvalid,
    endDateInvalid,
    handleVerifDisponibilite,
  }
}

export default useDisponibilite
