import styles from '../../../css/search_room_result/notFound.module.css'
import BecomeHost from './BecomeHost'

const NotFound = ({ dateIsinvalid, hotel }) => {
  return (
    <>
      <div className={styles.nombre_and_tri}>
        {dateIsinvalid ? (
          <div>
            <div className={styles.array_vide}>
              <h3>Aucun correspondance exacte</h3>
              <h4>Aucun résultat</h4>
              <p>
                Pour obtenir plus de résultats, essayez de modifier votre recherche en changeant vos
                dates
              </p>
            </div>
            <BecomeHost />
          </div>
        ) : (
          <div>
            {hotel?.total_hotels > 0 ? (
              <div className={styles.array_result}>
                <h3>
                  {hotel?.total_hotels}{' '}
                  {hotel?.total_hotels > 1 ? 'logements disponibles' : ' logement disponible'}
                </h3>
                <p>Classement par ordre de pertinence</p>
              </div>
            ) : (
              <div>
                <div className={styles.array_vide}>
                  <h3>Aucun logement disponible</h3>
                  <h4>Aucun résultat</h4>
                  <p>
                    Essayez de modifier vos critères de recherche en changeant vos dates, en
                    supprimant des filtres.
                  </p>
                </div>
                <BecomeHost />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default NotFound
