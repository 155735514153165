import styles from '../../css/svgs/svgStyle.module.css'

const CloseSvg = () => {
  return (
    <svg
      className={styles.close_svg}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 32 32'
      aria-hidden='true'
      role='presentation'
      focusable='false'
    >
      <path d='m6 6 20 20M26 6 6 26'></path>
    </svg>
  )
}

export default CloseSvg
