import styles from '../../../../css/modehotes/navbar/dropdown_link.module.css'
import { Link } from 'react-router-dom'
import SvgIconRightDirector from '../../../svgs/SvgIconRightDirector'

const Sponsor = () => {
  return (
    <Link to='/sponsorship/home' className={styles.block_link}>
      <div className={styles.left_block}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 32 32'
          aria-hidden='true'
          role='presentation'
          focusable='false'
        >
          <path d='M28 2a2 2 0 0 1 2 1.85V28a2 2 0 0 1-1.85 2H4a2 2 0 0 1-2-1.85V4a2 2 0 0 1 1.85-2H4zM13.59 17H4v11h11v-9.59l-4.3 4.3-1.4-1.42zM28 17h-9.59l4.3 4.3-1.42 1.4L17 18.42V28h11zM15 4H4v11h3.54a4 4 0 0 1 6.28-4.84c.29.28.68.85 1.18 1.74zm6 7c-.53 0-.98.17-1.42.6-.21.2-.63.87-1.22 1.98l-.25.47-.5.95H21a2 2 0 0 0 1.98-1.7l.01-.15L23 13a2 2 0 0 0-2-2zm7-7H17v7.9c.5-.89.89-1.46 1.18-1.74A4 4 0 0 1 24.46 15H28zm-17 7a2 2 0 0 0-2 2v.15A2 2 0 0 0 11 15h3.38l-.49-.95-.36-.69c-.54-.98-.91-1.58-1.1-1.76-.45-.43-.9-.6-1.43-.6z'></path>
        </svg>
        <h3>Parrainez un hôte</h3>
      </div>
      <div className={styles.rigth_block}>
        <SvgIconRightDirector />
      </div>
    </Link>
  )
}

export default Sponsor
