import styles from '../../../../../../css/modehotes/reservation/detail.module.css'
import RefundPayment from './payments/RefundPayment'
import { addCurrencyComplet } from '../../../../../../utils/addCurrency'

const RefundTotal = ({ reservation }) => {
  if (!reservation.isReservation_refunded) return null
  return (
    <>
      <div className={styles.content}>
        <RefundPayment refund={reservation.refund_total} />
        <p>
          Total remboursé :{' '}
          {addCurrencyComplet(
            reservation.refund_total?.refund_amount,
            reservation.prices?.currency
          )}
        </p>
      </div>
    </>
  )
}

export default RefundTotal
