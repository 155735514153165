import styles from '../../../../../css/traveler/travel/detailReservation.module.css'
import { STATUS_BOOKING } from '../../../../../enums/booking/status.enums'

const InfoVoyageur = ({ reservation }) => {
  const array = [
    {
      name: "Clés remises par l'hôte",
      value: 'clés_remises_par_hôte',
      descr: 'Un hôte ou co-hôte remettra les clés aux voyageurs en personne',
    },
    {
      name: "Personnel de l'immeuble",
      value: 'personnel_de_immeuble',
      descr: "Une personne est sur place 24h/24 pour permettre l'accès aux voyageurs.",
    },
    {
      name: 'Boîte à clé sécurisée',
      value: 'boîte_à_clé_sécurisée',
      descr:
        "La clé est conservée dans un petit boîtier que les voyageurs peuvent ouvrir à l'aide d'un code.",
    },
    {
      name: 'Serrure connectée',
      value: 'serrure_connectée',
      descr:
        "Les voyageurs peuvent ouvrir la porte à l'aide d'une serrure numérique ou d'une application mobile.",
    },
    {
      name: 'Serrure numérique',
      value: 'serrure_numérique',
      descr: "Les voyageurs peuvent ouvrir la porte à l'aide d'un code.",
    },
    {
      name: 'Autre',
      value: 'autre',
      descr: 'Les voyageurs peuvent utiliser une autre méthode non énumérée ci-dessus',
    },
  ]
  return (
    <div>
      {reservation.status !== STATUS_BOOKING.CANCELLED && reservation.host_accept_reservation ? (
        <div className={styles.block}>
          <h2>Informations pour les voyageurs</h2>
          <div className={styles.content}>
            <h3>Itinéraire</h3>
            <p>{reservation.hotel?.traveler_information?.itinerary || 'Non défini'}</p>
          </div>
          <div className={styles.content}>
            <h3>Adresse</h3>
            <p>{reservation.hotel?.address?.street}</p>
          </div>
          <div className={styles.content}>
            <h3>Manuel du voyageur</h3>
            <p>{reservation.hotel?.traveler_information?.traveler_manual || 'Non défini'}</p>
          </div>
          <div className={styles.content}>
            <h3>Instructions d'arrivée</h3>
            {array
              .filter(
                item => item.value === reservation.hotel?.traveler_information?.arrival_instructions
              )
              .map(item => (
                <p key={item.value}>
                  {item.name} : {item.descr}
                </p>
              ))}
            <p>{reservation.hotel?.traveler_information?.arrival_instructions_message}</p>
          </div>
          <div className={styles.content}>
            <h3>Informations du wifi</h3>
            <p>
              Nom du réseau wifi :{' '}
              {reservation.hotel?.traveler_information?.wifi_name || 'Non défini'}
            </p>
            <p>
              Mot de passe wifi :{' '}
              {reservation.hotel?.traveler_information?.wifi_password || 'Non défini'}
            </p>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default InfoVoyageur
