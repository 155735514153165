import { useEffect, useState, useContext } from 'react'
import styles from '../../../../css/traveler/inbox/notification.module.css'
import { useHistory, Link } from 'react-router-dom'
import { User } from '../../../../context/UserContext'
import LoadingPoint from '../../../../components/LoadingPoint'
import Head from '../../../../components/traveler/inbox/details/Head'
import DefaultLayout from '../../../../layouts/DefaultLayout'
import { Helmet } from 'react-helmet-async'

const IconGowifmi = () => {
  return (
    <svg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25Z'
        fill='black'
      />
      <path
        d='M8.47949 24.8589C8.47949 17.7711 10.8429 12.7688 15.5696 9.85215C18.0625 8.28405 21.1219 7.5 24.7479 7.5C28.3416 7.5 31.7248 8.06455 34.8975 9.19358C35.2285 9.3169 35.4593 9.77594 35.3455 10.1103L34.3928 12.911C34.259 13.3042 33.8945 13.518 33.4892 13.4274C33.1007 13.302 32.696 13.1922 32.2751 13.0981C31.8866 12.9727 31.3686 12.8472 30.7211 12.7218C30.0736 12.5963 29.4585 12.4866 28.8758 12.3925C27.5808 12.1416 26.2048 12.0161 24.7479 12.0161C21.1219 12.0161 18.4672 13.004 16.7837 14.9798C15.1326 16.9556 14.307 20.28 14.307 24.953C14.307 30.2532 15.2621 33.7971 17.1722 35.5847C18.1434 36.4942 19.2118 37.1214 20.3773 37.4664C21.5428 37.8114 22.9188 37.9839 24.5051 37.9839C24.837 37.9839 25.1901 37.9791 25.5645 37.9695C28.4858 37.8945 30.7211 35.4102 30.7211 32.4879V29.9254C30.7211 28.5142 31.7827 27.329 33.1853 27.174C34.8244 26.993 36.2573 28.2763 36.2573 29.9254V39.7599C36.2573 40.286 35.8425 40.8109 35.3346 40.9476C34.7518 41.073 34.2176 41.1985 33.732 41.3239C33.2464 41.418 32.5665 41.5434 31.6924 41.7003C30.8506 41.8571 30.0413 41.9982 29.2643 42.1237C27.3218 42.3746 25.4116 42.5 23.5339 42.5C18.71 42.5 14.9869 41.0103 12.3645 38.0309C9.77449 35.0515 8.47949 30.6608 8.47949 24.8589Z'
        fill='white'
      />
      <path
        d='M35.424 23.0555C35.424 24.1294 34.5535 25 33.4796 25C32.4057 25 31.5352 24.1294 31.5352 23.0555C31.5352 21.9816 32.4057 21.1111 33.4796 21.1111C34.5535 21.1111 35.424 21.9816 35.424 23.0555Z'
        fill='white'
      />
    </svg>
  )
}

const ListNotification = () => {
  const [loading, setLoading] = useState(false)

  const user = useContext(User)
  const history = useHistory()
  useEffect(() => {
    window.scrollTo(0, 0)
    if (user === null) {
      history.push('/')
    }
    setLoading(false)
  }, [history, user])
  const filter = 'all'

  if (loading) {
    return (
      <div className={styles.container_load}>
        <LoadingPoint />
      </div>
    )
  }
  return (
    <DefaultLayout>
      <Helmet>
        <title>Notifications - Gowifmi</title>
      </Helmet>
      <div className={styles.liste_container}>
        <div className={styles.block_grid}>
          <div className={styles.block_grid_1}>
            <h1 className={styles.boite}>Boîte de réception</h1>
            <Head filter={filter} />
            <div className={styles.liste}>
              {!user?.work_email && (
                <Link to='/account-settings/gowifmi-for-work' className={styles.liste_content}>
                  <div>
                    <div className={styles.img_block}>
                      <IconGowifmi />
                    </div>
                  </div>
                  <div className={styles.profil_text}>
                    <h5>Assistance Gowifmi</h5>
                    <p>
                      Ajoutez votre adresse e-mail professionnelle pour bénéficier d'avantages
                      supplémentaires pour vos voyages d'affaires, tels que des notes de frais
                      simplifiées
                    </p>
                  </div>
                </Link>
              )}
              {!user?.isEmailVerified && (
                <Link to='/account-settings/info-personal#email' className={styles.liste_content}>
                  <div>
                    <div className={styles.img_block}>
                      <IconGowifmi />
                    </div>
                  </div>
                  <div className={styles.profil_text}>
                    <h5>Assistance Gowifmi</h5>
                    <p>
                      Veuillez confirmer votre adresse e-mail en cliquant sur le lien que nous
                      venons de vous envoyer par e-mail. Si vous ne le retrouvez pas, vous pouvez
                      demander un nouvel e-mail de confirmation ou modifier votre adresse e-mail.
                    </p>
                  </div>
                </Link>
              )}
            </div>
          </div>
          <div className={styles.block_grid_2}>
            <div className={styles.head}></div>
            <div className={styles.detail}></div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}

export default ListNotification
