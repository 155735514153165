import styles from '../../../css/account/profile/annonce/all_annonce.module.css'
import axios from 'axios'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import LoadingPoint from '../../LoadingPoint'
import CardComment from './annonce/CardComment'
import { TYPE_LOGEMENT } from '../../../enums/logement/type_logement.enums'

const AllAnnonce = () => {
  const location = useLocation()
  const path = location.pathname.split('/')[2]

  const {
    data: hotels,
    isLoading,
    error,
  } = useQuery(`get-hotel-host-${path}`, () =>
    axios.get(`/hotel_user/host/${path}`).then(res => {
      return res.data
    })
  )

  return (
    <div className={styles.container}>
      {isLoading ? (
        <LoadingPoint />
      ) : (
        <div className={styles.container_wrapper}>
          {error && <p>Les annonces n'arrivent pas à charger veuillez réesseyer</p>}
          {hotels.length === 0 && <h3>Aucune annonce (pour l'instant)</h3>}
          <div className={styles.wrapper_list}>
            {hotels.map(item => (
              <div key={item._id} className={styles.box_image}>
                <div>
                  <Link to={`/rooms/${item._id}`} className={styles.image}>
                    <img src={item.images[0]?.url} alt='couverture' />
                  </Link>
                </div>
                <Link to={`/rooms/${item._id}`}>
                  <div className={styles.block_content}>
                    <div>
                      <CardComment item={item} />
                      <div className={styles.descrip_detail}>
                        {item.accommodation_type === TYPE_LOGEMENT.ENTIRE_HOME && (
                          <p>
                            Logement entier ·{' '}
                            {item.address?.state || item.address?.city || item.address?.country}
                          </p>
                        )}
                        {item.accommodation_type === TYPE_LOGEMENT.PRIVATE_ROOM && (
                          <p>
                            Chambre privée ·{' '}
                            {item.address?.state || item.address?.city || item.address?.country}
                          </p>
                        )}
                        {item.accommodation_type === TYPE_LOGEMENT.SHARED_ROOM && (
                          <p>
                            Chambre partagée ·{' '}
                            {item.address?.state || item.address?.city || item.address?.country}
                          </p>
                        )}
                      </div>
                      <h5>{item.title}</h5>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default AllAnnonce
