import { Helmet } from 'react-helmet-async'
import styles from '../../css/about_us/about.module.css'
import DefaultLayout from '../../layouts/DefaultLayout'

function SiteFunctionality() {
  return (
    <DefaultLayout footer={true}>
      <Helmet>
        <title>Fonctionnement du site | Gowifmi</title>
      </Helmet>
      <div className={styles.main}>
        <div className={styles.pourquoi_text}>
          <h1>Fonctionnement du site et classement des annonces</h1>

          <h2>Qu'est-ce que Gowifmi ?</h2>
          <div>
            <p>
              La plateforme Gowifmi est une place de marché en ligne qui permet aux utilisateurs
              inscrits <strong>(les, « membres »)</strong> et à certains prestataires de proposer
              des services (les membres et prestataires qui proposent des services sont des{' '}
              <strong>« Hôtes »)</strong>, en publiant ces services sur la plateforme Gowifmi{' '}
              <strong>(les « Annonces »)</strong>, ils peuvent directement communiquer avec d'autres
              membres qui souhaitent réserver ces services (les membres qui utilisent ces services
              sont les <strong>« Voyageurs »</strong>). Les membres sont classés comme ceux qui
              publient <strong>[des annonces]</strong> sur la plateforme Gowifmi et ceux qui
              utilisent la plateforme pour réserver ces services. Les prestataires de services sont
              ceux qui proposent <strong>(les « Hébergements »)</strong> dans un but précis soit
              pour les vacances, soit pour tout autre usage.
            </p>
            <p>Vous devrez créer un compte avant de pouvoir publier une annonce.</p>
          </div>

          <h2>Qui peut devenir hôte sur Gowifmi (Logements) ?</h2>
          <div>
            <p>
              Pratiquement tout utilisateur enregistré (membre) sur la plateforme Gowifmi peut
              devenir hôte et mettre son logement en ligne. L'inscription et la mise en ligne de
              votre logement sont gratuites. Que vous soyez professionnel ou non-professionnel, cela
              vous prendra juste quelques minutes. La diversité des logements présente sur la
              plateforme sont tout comme les hôtes qui les publient.
            </p>
            <p>
              Pour proposer des séjours agréables et adaptés aux voyageurs, nous demandons à tous
              les hôtes de respecter 4 critères de base :
            </p>
          </div>
          <div className={styles.list}>
            <ul>
              <li>
                <strong>Faire preuve de réactivité</strong> : Assurez un taux de réponse élevé en
                répondant aux demandes des clients dans les 24 premières heures.
              </li>
              <li>
                <strong>Accepter les demandes de réservation</strong> : montrez aux voyageurs qu'ils
                sont les bienvenus en acceptant les demandes de réservation lorsque votre logement
                est disponible.
              </li>
              <li>
                <strong>Éviter les annulations</strong> : les annulations ne sont pas anodines et
                nous demandons à tous les hôtes d'éviter d'annuler les réservations des voyageurs
                pour ne pas compromettre leurs projets de voyage.
              </li>
            </ul>
            <p>
              Veuillez noter que nous vous encourageons vivement à fournir les{' '}
              <strong>équipements essentiels</strong> : Fer à repasser, sèche-linge, sèche-cheveux,
              papier toilette, savon et au moins des draps.
            </p>
          </div>

          <h2>Est-ce que Gowifmi peut désactiver mon annonce ou mon compte ?</h2>
          <div>
            <p>
              Gowifmi peut supprimer, suspendre ou désactiver votre compte, conformément à ses
              conditions générales.
            </p>
          </div>
          <div className=''>
            <h3>Gowifmi peut supprimer ou désactiver l’accès à tout contenu des Membres</h3>
            <div>
              <p>
                Si un membre enfreint les présentes conditions générales ou toute loi, ou est
                considéré par Gowifmi comme enfreignant toute autre condition ou disposition,
                Gowifmi a le droit de suspendre ou annuler les privilèges d'inscription du membre
                via la plateforme Gowifmi ou autrement.
              </p>
            </div>
          </div>
          <div className=''>
            <h3>Votre compte peut être désactivé lors d'un examen des comptes Gowifmi</h3>
            <div>
              <p>
                Ces examens font partie des efforts visant à aider à authentifier les valeurs de la
                communauté Gowifmi (tranquillité, sécurité et respect) ainsi qu'à créer une
                plateforme sûre et fiable pour tous les utilisateurs. Votre compte peut être
                suspendu ou supprimé s'il s'avère qu'il enfreint les normes de la communauté.
              </p>
            </div>
          </div>

          <h2>
            Qu'est-ce qui influence le classement de mon annonce dans les résultats de recherche ?
          </h2>
          <div>
            <p>
              L'objectif de l’algorithme du classement des annonces sur Gowifmi est simple : mettre
              les bons logements devant les bons voyageurs pour leur séjour et les hôtes à trouver
              des bons voyageurs qui conviennent à leurs logements.
            </p>
          </div>
          <div className={styles.list}>
            <ul>
              <li>
                <strong>Besoins des voyageurs</strong> : Nous prenons en considération des facteurs
                liés aux voyageurs, tels que le lieu à partir duquel ils effectuent la recherche,
                l'historique de leurs voyages, les annonces ajoutées à leur liste de souhaits ou
                celles sur lesquelles ils ont cliqué, et bien plus encore.
              </li>
              <li>
                <strong>Détails de l’annonce</strong> : Nous prenons en compte divers facteurs tels
                que le nombre de commentaires 5 étoiles, le prix, l'emplacement du logement, la
                disponibilité et la rapidité avec laquelle l'hôte répond aux demandes et bien
                d'autres.
              </li>
              <li>
                <strong>Détails du voyage</strong> : En tenant compte du nombre de voyageurs, de la
                durée du voyage, de l'imminence du voyage, des tarifs et d'autres facteurs, nous
                prendrons tout ce qui est nécessaire pour vous assurer d'obtenir ce que vous voulez.
              </li>
            </ul>
            <p>
              Nous améliorons continuellement notre système afin de permettre aux clients et aux
              voyageurs d'obtenir les meilleurs résultats possibles sur notre plateforme ; Ces
              facteurs peuvent inclure des changements, veuillez donc consulter la page dédiée à
              notre centre d'aide si vous souhaitez en savoir plus.
            </p>
            <p>
              À l'heure actuelle, les hôtes ne sont pas en mesure de payer Gowifmi pour un meilleur
              classement de leur annonce dans les résultats de recherche.
            </p>
          </div>
          <h2>Frais de service</h2>
          <div>
            <p>
              Vous êtes exempté de frais d'inscription et de publication de votre logement sur
              Gowifmi, c'est un service gratuit..
            </p>
            <p>
              Gowifmi applique généralement des frais de service fixes de 3 % du sous-total de la
              réservation pour l'hôte et 14 % pour les voyageurs. Pour plus d'informations sur notre{' '}
              <a href='/host-service-fee' target='_blanck'>
                politique en matière de frais de service
              </a>
              , veuillez consulter notre page dédiée.
            </p>
            <p>
              Mais ces conditions peuvent donc faire l’objet de modifications dans les jours à
              venir. Si vous souhaitez en savoir plus, nous vous recommandons de consulter la page
              dédiée de notre centre d’aide.
            </p>
            <h3>Quand suis-je débité de ma réservation ?</h3>
            <p>
              Pour les paiements en ligne, dans le cas des réservations instantanées, le débit se
              fait automatiquement. Pour les demandes de réservation, le débit intervient dès que
              l'hôte accepte votre réservation.
            </p>
            <p> Pour les paiements sur place, vous serez débité dès votre arrivée chez l'hôte.</p>
            <h3>Demande d'assistance à Gowifmi</h3>
            <p>
              Si vous n'arrivez pas à vous mettre d'accord, Gowifmi peut prendre une décision 72
              heures après l'ouverture de votre demande. Nous ferons de notre mieux pour prendre une
              décision finale.
            </p>
            <p>
              Lorsque vous faites intervenir Gowifmi, notre équipe est prévenue et un membre dédié
              prend en charge votre dossier. Il examine les informations que vous aurez fournies,
              vous et votre hôte ou voyageur, avant de prendre sa décision finale. Dans certains
              cas, il se peut que nous devions vous contacter pour rassembler des informations
              supplémentaires avant de pouvoir prendre notre décision.
            </p>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}
export default SiteFunctionality
