import styles from '../../css/ui/select_date.module.css'
import moment from 'moment'
import { capitalizeFirstLetter } from '../../utils/formateString'

const SelectDate = ({ selectedDate, setSelectedDate }) => {
  const generateOptions = () => {
    const options = []
    const startDate = new Date(2020, 9, 1)
    let endDate = new Date() // currente date
    endDate.setMonth(endDate.getMonth() + 1)

    let currentDate = startDate

    while (currentDate < endDate) {
      const formattedDate = currentDate.toISOString().slice(0, 7)
      const formattedDateString = capitalizeFirstLetter(
        moment(formattedDate, 'YYYY-MM').format('MMMM YYYY')
      )

      options.push({
        value: formattedDate,
        label: formattedDateString,
      })

      currentDate.setMonth(currentDate.getMonth() + 1)
    }

    return options
  }

  const handleSelectChange = event => {
    setSelectedDate(event.target.value)
  }

  return (
    <div className={styles.select_elem}>
      <select value={selectedDate} onChange={handleSelectChange}>
        {generateOptions().map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default SelectDate
