import { useState } from 'react'
import styles from '../../../../css/traveler/travel/cancellation.module.css'
import { Motif, TrouveUnAutreHebergement, Raison, Surquelsite } from './TableauMotif'
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import axios from 'axios'
import Toast from '../../../infos/toast'

const WhyCancellation = ({ id }) => {
  const [chekedradio, setChekedradio] = useState('')
  const [autreEtablissement, setautreEtablissement] = useState('')
  const [dansgowifmi, setDansgowifmi] = useState('')
  const [androit, setAndroit] = useState('')
  const [explication, setExplication] = useState('')

  const history = useHistory()

  const handleSubmit = e => {
    e.preventDefault()
    const data = {
      cancellations: {
        cancel_reason_1: chekedradio,
        cancel_reason_2: autreEtablissement,
        cancel_reason_3: dansgowifmi,
        cancel_reason_4: androit,
        cancel_explanation: explication,
        date: new Date(),
      },
    }
    if (chekedradio) {
      Swal.fire({
        title: 'Annuler cette réservation ?',
        text: 'Une fois la réservation annulée, vous ne pourrez plus la récupérer.',
        showCancelButton: true,
        confirmButtonText: 'Annuler la réservation',
        cancelButtonText: 'Retour',
        reverseButtons: true,
        customClass: {
          actions: 'actions_class',
          confirmButton: 'confirm_button_class',
          cancelButton: 'cancel_button_class',
        },
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .patch(`/user_reservations/user_cancel/${id}`, data)
            .then(() => {
              Toast.fire({
                icon: 'success',
                title: 'Votre réservation est annulée avec succès.',
              })
              history.push('/reservations/canceled')
            })
            .catch(err => {
              Toast.fire({
                icon: 'error',
                title: 'Error server contact us',
              })
            })
        }
      })
    } else {
      Toast.fire({ icon: 'warning', title: 'Veuillez sélectionner la motif de cette annulation' })
    }
  }

  return (
    <div className={styles.le_pourquoi}>
      <h4>Dites-nous pourquoi vous souhaitez annuler</h4>
      <p>Cette étape est nécessaire pour confirmer votre annulation</p>
      <form onSubmit={handleSubmit}>
        {Motif.map((item, i) => (
          <div className={styles.radios_button} key={i}>
            <input
              type='radio'
              id={item.value}
              defaultValue={item.value}
              checked={item.value === chekedradio}
              onChange={e => setChekedradio(e.target.value)}
            />
            <label htmlFor={item.value}>{item.value}</label>
          </div>
        ))}
        {chekedradio === "J'ai trouvé un autre hébergement" ? (
          <select className={styles.first} onChange={e => setautreEtablissement(e.target.value)}>
            <option selected disabled>
              Quelle autre option avez-vous trouvé ?
            </option>
            <TrouveUnAutreHebergement />
          </select>
        ) : null}
        {chekedradio === "J'ai trouvé un autre hébergement" &&
        autreEtablissement === "J'ai trouvé un hébergement dans un autre établissement" ? (
          <select onChange={e => setDansgowifmi(e.target.value)}>
            <option selected disabled>
              Pourquoi avez-vous choisi cet autre établissement ?
            </option>
            <Raison />
          </select>
        ) : null}
        {chekedradio === "J'ai trouvé un autre hébergement" &&
        autreEtablissement === "J'ai trouvé un hébergement dans un autre établissement" &&
        dansgowifmi ? (
          <select onChange={e => setAndroit(e.target.value)}>
            <option selected disabled>
              Où avez-vous trouvé cette autre option ?
            </option>
            <Surquelsite />
          </select>
        ) : null}
        {chekedradio === 'Aucune des propositions ci-dessus' ||
        (chekedradio === "J'ai trouvé un autre hébergement" && autreEtablissement === 'Autre') ? (
          <textarea
            onChange={e => setExplication(e.target.value)}
            placeholder='Expliquez-nous pourquoi vous souhaitez annuler votre séjour'
          ></textarea>
        ) : null}
        <div className={styles.buttons}>
          <button type='submit'>Annuler cette réservation</button>
          <Link to='/reservations/upcoming'>Non, je ne souhaite pas annuler</Link>
        </div>
      </form>
    </div>
  )
}

export default WhyCancellation
