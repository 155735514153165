import styles from '../css/layouts/all_layout.module.css'
import { useLocation } from 'react-router'
import BottomNav from '../components/navigations/BottomNav'
import Footer from '../components/navigations/Footer'
import SideBar from '../components/navigations/SideBar'
import { isPagePath } from '../utils/PagePath'
import NavbarMobile from '../components/accounts/navbar_mobile/NavbarMobile'

function DefaultLayout({ footer, mobileBottomNavigate, navTopMobile, children }) {
  const location = useLocation()
  const path = location.pathname.split('/')[1]

  const sizeViewBottomNav = window.matchMedia('(max-width:743px)')

  return (
    <div>
      {!sizeViewBottomNav.matches && <SideBar />}
      <div className={isPagePath(path) ? `${styles.main} ${styles.hidded}` : styles.main}>
        {navTopMobile && <NavbarMobile />}
        {children}
        {footer && <Footer />}
        {mobileBottomNavigate && sizeViewBottomNav.matches ? <BottomNav /> : null}
      </div>
    </div>
  )
}
export default DefaultLayout
