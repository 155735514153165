import styles from '../../../../css/modehotes/navbar/dropdown_link.module.css'
import { useContext } from 'react'
import { Logout } from '../../../../context/UserContext'

const LogoutHost = () => {
  const logout = useContext(Logout)

  return (
    <button onClick={logout} className={styles.block_link}>
      <div className={styles.left_block}>
        <svg
          className={styles.logout_svg}
          enableBackground='new 0 0 24 24'
          viewBox='0 0 24 24'
          focusable='false'
        >
          <path d='M20 3v18H8v-1h11V4H8V3h12zm-8.9 12.1.7.7 4.4-4.4L11.8 7l-.7.7 3.1 3.1H3v1h11.3l-3.2 3.3z'></path>
        </svg>
        <h3>Déconnexion</h3>
      </div>
    </button>
  )
}

export default LogoutHost
