import styles from '../../../css/becomeHostes/home/section_steps.module.css'

const SectionStep = () => {
  return (
    <div className={styles.container}>
      <h3>L'accueil des voyageurs en 3 étapes</h3>
      <div className={styles.container_block}>
        <div className={styles.block_content}>
          <h4>1</h4>
          <div>
            <h5>Publiez votre annonce</h5>
            <p>
              Que vous offriez une chambre partagée ou une résidence secondaire, partagez votre
              logement sans aucun frais d'inscription.
            </p>
          </div>
        </div>
        <div className={styles.block_content}>
          <h4>2</h4>
          <div>
            <h5>Vous fixez vos conditions</h5>
            <p>
              Choisissez votre planning, vos tarifs ainsi que vos critères de réservation. Nous
              sommes là pour vous aider si besoin.
            </p>
          </div>
        </div>
        <div className={styles.block_content}>
          <h4>3</h4>
          <div>
            <h5>Accueillez les voyageurs</h5>
            <p>
              Une fois votre annonce publiée, les voyageurs pourront vous contacter. Vous pouvez
              également leur poser des questions.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionStep
