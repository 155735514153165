import styles from '../../../../css/traveler/favoris/maps.module.css'
import usePrice from '../../../../hooks/usePrice'
import { OverlayView, OverlayViewF } from '@react-google-maps/api'

const CardSmile = ({ item, childhover, childClicked, setChildClicked }) => {
  const { pricePerNightWithoutPromo } = usePrice(item)

  const handleCard = event => {
    event.stopPropagation()
    setChildClicked(item)
  }

  return (
    <OverlayViewF
      position={{ lat: item.location?.coordinates[1], lng: item.location?.coordinates[0] }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <div className={childhover === item._id ? `${styles.hoverActive}` : ''}>
        <button
          onClick={handleCard}
          className={
            childClicked?._id === item._id ? `${styles.anonce} ${styles.active}` : styles.anonce
          }
        >
          {pricePerNightWithoutPromo}
          <svg
            viewBox='0 0 32 32'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            role='presentation'
            focusable='false'
          >
            <path d='m16 28c7-4.733 14-10 14-17 0-1.792-.683-3.583-2.05-4.95-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05l-2.051 2.051-2.05-2.051c-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05-1.367 1.367-2.051 3.158-2.051 4.95 0 7 7 12.267 14 17z'></path>
          </svg>
        </button>
      </div>
    </OverlayViewF>
  )
}

export default CardSmile
