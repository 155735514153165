import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { useEffect } from 'react'
import { User, Logout, Refresh } from './context/UserContext'
import { SocketContextProvider } from './context/SocketContext'
import useAuth from './hooks/useAuth'
import { baseURL } from './config/config'
import 'moment/locale/fr'
import axios from 'axios'
import AppLayout from './layouts/AppLayout'
import LoadingUser from './pages/user_auth/LoadingUser'
import Error404 from './pages/errors/Page404'
import Home from './pages/Home'
import CustomHomeUrl from './pages/personal_link_room/CustomHomeUrl'
import HomeSponsor from './pages/affiliations/Home'
import RevenueSponsor from './pages/affiliations/Revenue'
import Sponsored from './pages/affiliations/Sponsored'
import BecomeHost from './pages/become_host/BecomeHost'
import Intro from './pages/become_host/Intro'
import TypeProperty from './pages/become_host/TypeProperty'
import TypePropertyDetail from './pages/become_host/TypePropertyDetail'
import TypeAccomodation from './pages/become_host/TypeAccomodation'
import Location from './pages/become_host/Location'
import TravelerInformation from './pages/become_host/TravelerInformation'
import Amenitie from './pages/become_host/Amenitie'
import Photo from './pages/become_host/Photo'
import Title from './pages/become_host/Title'
import Description from './pages/become_host/Description'
import Price from './pages/become_host/Price'
import Question from './pages/become_host/Question'
import Preview from './pages/become_host/Preview'
import Celebration from './pages/become_host/Celebration'
import VerifyListing from './pages/become_host/VerifyListing'
import PrivacyPolicy from './pages/about_us/PrivacyPolicy'
import AffiliationPolicy from './pages/about_us/AffiliationPolicy'
import PolicyPaymentForHost from './pages/about_us/PolicyPaymentForHost'
import PolicyPaymentForTraveler from './pages/about_us/PolicyPaymentForTraveler'
import LegalInformation from './pages/about_us/LegalInformation'
import SiteFunctionality from './pages/about_us/SiteFunctionality'
import ReplacementPolicy from './pages/about_us/ReplacementPolicy'
import VerifyIdentityPolicy from './pages/about_us/VerifyIdentityPolicy'
import TermsAndCondition from './pages/about_us/TermsAndCondition'
import CookiePolicy from './pages/about_us/CookiePolicy'
import NonDiscriminationPolicy from './pages/about_us/NonDiscriminationPolicy'
import ServiceFeeHost from './pages/about_us/resource_host/ServiceFeeHost'
import FreeModeHostPage from './pages/about_us/resource_host/FreeModeHostPage'
import GetPayementHost from './pages/about_us/resource_host/GetPayementHost'
import Feedback from './pages/help/Feedback'
import DetailHotel from './pages/detail_room/DetailHotel'
import Book from './pages/booking/Book'
import ConfirmationReservation from './pages/confirmation_booking/ConfirmationReservation'
import ConfirmPaymentReservation from './pages/confirmation_booking/ConfirmPayment'
import ConfirmSetupReservation from './pages/confirmation_booking/ConfirmSetup'
import Thankyou from './pages/help/Confirmation'
import SearchProfile from './pages/search_profile/SearchProfile'
import SearchResult from './pages/search_room_result/SearchResult'
import LoginPage from './pages/account/parameters/LoginPage'
import ForgotPasswordPage from './pages/account/parameters/ForgotPasswordPage'
import AccountMobile from './pages/account/AccountMobile'
import AccountSetings from './pages/account/AccountSetings'
import Profile from './pages/account/parameters/Profile'
import InfoPerso from './pages/account/parameters/InfoPerso'
import SecurityAccount from './pages/account/parameters/SecurityAccount'
import ConfirmEmail from './pages/account/parameters/ConfirmEmail'
import ResetPassword from './pages/account/parameters/ResetPassword'
import WorkTravel from './pages/account/parameters/WorkTravel'
import Versement from './pages/account/parameters/Versement'
import Inbox from './pages/traveler/inbox/Inbox'
import DetailMessage from './pages/traveler/inbox/details/DetailMessage'
import ListNotification from './pages/traveler/inbox/notifications/ListNotification'
import Travel from './pages/traveler/travel/Travel'
import DetailReservation from './pages/traveler/travel/details/DetailReservation'
import ReservationDetailAnnonce from './pages/traveler/travel/details/DetailAnnonce'
import ReservationDetailReglementation from './pages/traveler/travel/details/Reglementation'
import ReservationDetailToKnow from './pages/traveler/travel/details/ToKnow'
import ListCompleted from './pages/traveler/travel/completed/ListCompleted'
import ListCancel from './pages/traveler/travel/canceled/ListCancel'
import TravelerGoCancel from './pages/traveler/travel/cancel/TravelerGoCancel'
import ListInProgress from './pages/traveler/travel/inprogress/ListInProgress'
import ListAll from './pages/traveler/travel/all/ListAll'
import AllFavorite from './pages/traveler/favorites/AllFavorite'
import ListFavoriteById from './pages/traveler/favorites/ListFavoriteById'
import Hosting from './pages/hote_mode/Hosting'
import HostingListRequestReservation from './pages/hote_mode/reservations/request/ListeRequest'
import HostingListUpcomingReservation from './pages/hote_mode/reservations/upcoming/ListUpcoming'
import HostingListInprogressReservation from './pages/hote_mode/reservations/inprogress/ListInProgress'
import HostingListCompletedReservation from './pages/hote_mode/reservations/completed/ListCompleted'
import HostingListCancelReservation from './pages/hote_mode/reservations/canceled/ListCancel'
import HostingCancelReservation from './pages/hote_mode/reservations/canceled/HostGoCancel'
import HostingListAllReservation from './pages/hote_mode/reservations/all/ListAll'
import HostingDetailReservation from './pages/hote_mode/reservations/details/DetailReservation'
import HostingReservationDetailHotel from './pages/hote_mode/reservations/details/DetailHotel'
import HostingReservationDetailReglementation from './pages/hote_mode/reservations/details/Reglementation'
import HostingReservationDetailTraveler from './pages/hote_mode/reservations/details/TravelerDetail'
import ListAllmessageHost from './pages/hote_mode/inboxHote/allMessage/ListAllmessageHost'
import ListAssistanceHost from './pages/hote_mode/inboxHote/assistance/ListAssistanceHost'
import ListArchiveMessageHost from './pages/hote_mode/inboxHote/archive/ListArchiveMessageHost'
import DetailAllmessage from './pages/hote_mode/inboxHote/allMessage/DetailAllmessage'
import Listing from './pages/hote_mode/annonces/Listing'
import Calendar from './pages/hote_mode/calendar/CalendarMain'
import AllBill from './pages/hote_mode/factures/AllBill'
import PayBill from './pages/hote_mode/factures/PayBill'
import UnpayBill from './pages/hote_mode/factures/UnpayBill'
import BillReport from './pages/hote_mode/factures/BillReport'
import Revenue from './pages/hote_mode/revenues/Revenue'
import ViewListing from './pages/hote_mode/statistics/ViewListing'
import ReservationListing from './pages/hote_mode/statistics/ReservationListing'
import CommentListing from './pages/hote_mode/statistics/CommentListing'
import DetailAnnonce from './pages/hote_mode/annonces/details/DetailAnnonce'
import PriceAndDisponibilite from './pages/hote_mode/annonces/details/PriceAndDisponibilite'
import ConditionAndReglement from './pages/hote_mode/annonces/details/ConditionAndReglement'
import TravelerInformationEdit from './pages/hote_mode/annonces/details/TravelerInformationEdit'
import DetailAnnoncePhoto from './pages/hote_mode/annonces/details/Photo'
import DetailAnnonceAmenetie from './pages/hote_mode/annonces/details/Amenitie'
import DetailAnnonceSecurityTraveler from './pages/hote_mode/annonces/details/SecurityTraveler'
import { getCookieConsentValue } from 'react-cookie-consent'
import { loadCookiesParams } from './utils/loadCookiesParams'

// import { loadGoogleMaps } from './utils/loadGoogleMaps'

const App = () => {
  const { loadingUser, user, refreshUser, logout } = useAuth()

  useEffect(() => {
    axios.defaults.baseURL = baseURL
    refreshUser()
    if (getCookieConsentValue('cookieConsent') === 'true') {
      loadCookiesParams()
    }
    // loadGoogleMaps()
  }, [])

  if (loadingUser) return <LoadingUser />

  return (
    <User.Provider value={user}>
      <Logout.Provider value={logout}>
        <Refresh.Provider value={refreshUser}>
          <SocketContextProvider>
            <Router>
              <AppLayout>
                <Switch>
                  <Route path='/' exact>
                    <Home />
                  </Route>
                  <Route path='/profiles/search'>
                    <SearchProfile />
                  </Route>
                  <Route path='/homes/search'>
                    <SearchResult />
                  </Route>
                  <Route path='/h/:id'>
                    <CustomHomeUrl />
                  </Route>
                  <Route path='/rooms/:id'>
                    <DetailHotel />
                  </Route>
                  <Route path='/book/:id' exact>
                    <Book />
                  </Route>
                  <Route path='/book/confirmation/:id'>
                    <ConfirmationReservation />
                  </Route>
                  <Route path='/book/confirmation-payment/:id'>
                    <ConfirmPaymentReservation />
                  </Route>
                  <Route path='/book/confirmation-setup/:id'>
                    <ConfirmSetupReservation />
                  </Route>
                  <Route path='/help/confirmation'>
                    <Thankyou />
                  </Route>
                  <Route path='/sponsorship/home'>
                    <HomeSponsor />
                  </Route>
                  <Route path='/sponsorship/revenues'>
                    <RevenueSponsor />
                  </Route>
                  <Route path='/sponsorship/sponsored'>
                    <Sponsored />
                  </Route>
                  <Route path='/host/homes'>
                    <BecomeHost />
                  </Route>
                  <Route path='/become-a-host' exact>
                    <Intro />
                  </Route>
                  <Route path='/become-a-host/type-property-group/'>
                    <TypeProperty />
                  </Route>
                  <Route path='/become-a-host/type-property-details/:id'>
                    <TypePropertyDetail />
                  </Route>
                  <Route path='/become-a-host/type-accomodation/:id'>
                    <TypeAccomodation />
                  </Route>
                  <Route path='/become-a-host/location/:id'>
                    <Location />
                  </Route>
                  <Route path='/become-a-host/traveler-infos/:id'>
                    <TravelerInformation />
                  </Route>
                  <Route path='/become-a-host/amenities/:id'>
                    <Amenitie />
                  </Route>
                  <Route path='/become-a-host/photos/:id'>
                    <Photo />
                  </Route>
                  <Route path='/become-a-host/title/:id'>
                    <Title />
                  </Route>
                  <Route path='/become-a-host/description/:id'>
                    <Description />
                  </Route>
                  <Route path='/become-a-host/price/:id'>
                    <Price />
                  </Route>
                  <Route path='/become-a-host/question/:id'>
                    <Question />
                  </Route>
                  <Route path='/become-a-host/preview/:id'>
                    <Preview />
                  </Route>
                  <Route path='/become-a-host/publish-celebration/:id'>
                    <Celebration />
                  </Route>
                  <Route path='/verify-listing/:id'>
                    <VerifyListing />
                  </Route>
                  <Route path='/privacy-policy'>
                    <PrivacyPolicy />
                  </Route>
                  <Route path='/affiliation-policy'>
                    <AffiliationPolicy />
                  </Route>
                  <Route path='/payement-policy-for-host'>
                    <PolicyPaymentForHost />
                  </Route>
                  <Route path='/payement-policy-for-traveler'>
                    <PolicyPaymentForTraveler />
                  </Route>
                  <Route path='/site-functionality'>
                    <SiteFunctionality />
                  </Route>
                  <Route path='/legal-informations'>
                    <LegalInformation />
                  </Route>
                  <Route path='/replacement-gowifmi-policy'>
                    <ReplacementPolicy />
                  </Route>
                  <Route path='/verif-identity-policy'>
                    <VerifyIdentityPolicy />
                  </Route>
                  <Route path='/terms-and-condition'>
                    <TermsAndCondition />
                  </Route>
                  <Route path='/cookies-policy'>
                    <CookiePolicy />
                  </Route>
                  <Route path='/non-discrimination-policy'>
                    <NonDiscriminationPolicy />
                  </Route>
                  <Route path='/host-service-fee'>
                    <ServiceFeeHost />
                  </Route>
                  <Route path='/free-mode-host'>
                    <FreeModeHostPage />
                  </Route>
                  <Route path='/how-you-get-paid-for-hosting'>
                    <GetPayementHost />
                  </Route>
                  <Route path='/help/feedback'>
                    <Feedback />
                  </Route>
                  <Route path='/inbox' exact>
                    <Inbox />
                  </Route>
                  <Route path='/notifications'>
                    <ListNotification />
                  </Route>
                  <Route path='/inbox/detail'>
                    <DetailMessage />
                  </Route>
                  <Route path='/reservations/upcoming'>
                    <Travel />
                  </Route>
                  <Route path='/reservations/inprogress'>
                    <ListInProgress />
                  </Route>
                  <Route path='/reservations/details/:id' exact>
                    <DetailReservation />
                  </Route>
                  <Route path='/reservations/details/:id/annonce'>
                    <ReservationDetailAnnonce />
                  </Route>
                  <Route path='/reservations/details/:id/policies-and-rules'>
                    <ReservationDetailReglementation />
                  </Route>
                  <Route path='/reservations/details/:id/info-for-guests'>
                    <ReservationDetailToKnow />
                  </Route>
                  <Route path='/reservations/cancellation'>
                    <TravelerGoCancel />
                  </Route>
                  <Route path='/reservations/completed'>
                    <ListCompleted />
                  </Route>
                  <Route path='/reservations/canceled'>
                    <ListCancel />
                  </Route>
                  <Route path='/reservations/all'>
                    <ListAll />
                  </Route>
                  <Route path='/wishlists' exact>
                    <AllFavorite />
                  </Route>
                  <Route path='/wishlists/:id'>
                    <ListFavoriteById />
                  </Route>
                  <Route path='/profile/:id'>
                    <Profile />
                  </Route>
                  <Route path='/account-settings/info-personal'>
                    <InfoPerso />
                  </Route>
                  <Route path='/account-settings/login-and-security'>
                    <SecurityAccount />
                  </Route>
                  <Route path='/account-settings/confirm-email/:token'>
                    <ConfirmEmail />
                  </Route>
                  <Route path='/account-settings/ResetPassword'>
                    <ResetPassword />
                  </Route>
                  <Route path='/account-settings/gowifmi-for-work'>
                    <WorkTravel />
                  </Route>
                  <Route path='/account-settings/versement'>
                    <Versement />
                  </Route>
                  <Route path='/account-settings' exact>
                    <AccountSetings />
                  </Route>
                  <Route path='/connexion' exact>
                    <LoginPage />
                  </Route>
                  <Route path='/forgot_password' exact>
                    <ForgotPasswordPage />
                  </Route>
                  <Route path='/account' exact>
                    <AccountMobile />
                  </Route>
                  <Route path='/hosting' exact>
                    <Hosting />
                  </Route>
                  <Route path='/hosting/calendar/'>
                    <Calendar />
                  </Route>
                  <Route path='/hosting/invoice/all'>
                    <AllBill />
                  </Route>
                  <Route path='/hosting/invoice/pay'>
                    <PayBill />
                  </Route>
                  <Route path='/hosting/invoice/unpay'>
                    <UnpayBill />
                  </Route>
                  <Route path='/hosting/infos/earnings'>
                    <BillReport />
                  </Route>
                  <Route path='/hosting/revenues'>
                    <Revenue />
                  </Route>
                  <Route path='/hosting/statistics/views'>
                    <ViewListing />
                  </Route>
                  <Route path='/hosting/statistics/reservation'>
                    <ReservationListing />
                  </Route>
                  <Route path='/hosting/statistics/evaluation-comments'>
                    <CommentListing />
                  </Route>
                  <Route path='/hosting/reservations/request'>
                    <HostingListRequestReservation />
                  </Route>
                  <Route path='/hosting/reservations/upcoming'>
                    <HostingListUpcomingReservation />
                  </Route>
                  <Route path='/hosting/reservations/inprogress'>
                    <HostingListInprogressReservation />
                  </Route>
                  <Route path='/hosting/reservations/completed'>
                    <HostingListCompletedReservation />
                  </Route>
                  <Route path='/hosting/reservations/canceled'>
                    <HostingListCancelReservation />
                  </Route>
                  <Route path='/hosting/reservations/cancellation'>
                    <HostingCancelReservation />
                  </Route>
                  <Route path='/hosting/reservations/all'>
                    <HostingListAllReservation />
                  </Route>
                  <Route path='/hosting/reservations/details/:id' exact>
                    <HostingDetailReservation />
                  </Route>
                  <Route path='/hosting/reservations/details/:id/annonce'>
                    <HostingReservationDetailHotel />
                  </Route>
                  <Route path='/hosting/reservations/details/:id/policies-and-rules'>
                    <HostingReservationDetailReglementation />
                  </Route>
                  <Route path='/hosting/reservations/details/:id/traveler'>
                    <HostingReservationDetailTraveler />
                  </Route>
                  <Route path='/hosting/listings' exact>
                    <Listing />
                  </Route>
                  <Route path='/hosting/listings/details/:id' exact>
                    <DetailAnnonce />
                  </Route>
                  <Route path='/hosting/listings/details/:id/photos'>
                    <DetailAnnoncePhoto />
                  </Route>
                  <Route path='/hosting/listings/details/:id/amenities'>
                    <DetailAnnonceAmenetie />
                  </Route>
                  <Route path='/hosting/listings/details/:id/pricing-and-availability'>
                    <PriceAndDisponibilite />
                  </Route>
                  <Route path='/hosting/listings/details/:id/policies-and-rules'>
                    <ConditionAndReglement />
                  </Route>
                  <Route path='/hosting/listings/details/:id/info-for-guests'>
                    <TravelerInformationEdit />
                  </Route>
                  <Route path='/hosting/listings/details/:id/safety-info'>
                    <DetailAnnonceSecurityTraveler />
                  </Route>
                  <Route path='/hosting/inbox/all' exact>
                    <ListAllmessageHost />
                  </Route>
                  <Route path='/hosting/inbox/assistance'>
                    <ListAssistanceHost />
                  </Route>
                  <Route path='/hosting/inbox/hidden'>
                    <ListArchiveMessageHost />
                  </Route>
                  <Route path='/hosting/inbox/detail'>
                    <DetailAllmessage />
                  </Route>
                  <Route>
                    <Error404 />
                  </Route>
                </Switch>
              </AppLayout>
            </Router>
          </SocketContextProvider>
        </Refresh.Provider>
      </Logout.Provider>
    </User.Provider>
  )
}

export default App
