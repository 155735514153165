import styles from '../../../../css/search_room_result/maps.module.css'
import { Portal } from 'react-portal'
import { useDispatch } from 'react-redux'
import { search_by_map } from '../../../../redux/actions'

const ButtonHandleMaps = ({ childClicked, openMap, setOpenMap }) => {
  const dispatch = useDispatch()
  const handleMap = () => {
    if (openMap) {
      window.scrollTo(0, 0)
      dispatch(search_by_map({ scrollToTop: false }))
      setOpenMap(false)
    } else {
      dispatch(search_by_map({ scrollToTop: true }))
      setOpenMap(true)
    }
  }

  return (
    <Portal>
      <button
        className={childClicked ? `${styles.buttonCarte} ${styles.disable}` : styles.buttonCarte}
        onClick={handleMap}
      >
        {openMap ? (
          <>
            <span>Liste</span>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 16 16'
              aria-hidden='true'
              role='presentation'
              focusable='false'
              style={{ display: 'block', height: '16px', width: '16px', fill: 'currentColor' }}
            >
              <path
                fillRule='evenodd'
                d='M2.5 11.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zM15 12v2H6v-2h9zM2.5 6.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zM15 7v2H6V7h9zM2.5 1.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zM15 2v2H6V2h9z'
              ></path>
            </svg>
          </>
        ) : (
          <>
            <span>Carte</span>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 32 32'
              aria-hidden='true'
              role='presentation'
              focusable='false'
              style={{ display: 'block', height: '16px', width: '16px', fill: 'currentColor' }}
            >
              <path d='M31.25 3.75a2.29 2.29 0 0 0-1.01-1.44A2.29 2.29 0 0 0 28.5 2L21 3.67l-10-2L2.5 3.56A2.29 2.29 0 0 0 .7 5.8v21.95a2.28 2.28 0 0 0 1.06 1.94A2.29 2.29 0 0 0 3.5 30L11 28.33l10 2 8.49-1.89a2.29 2.29 0 0 0 1.8-2.24V4.25a2.3 2.3 0 0 0-.06-.5zM12.5 25.98l-1.51-.3L9.5 26H9.5V4.66l1.51-.33 1.49.3v21.34zm10 1.36-1.51.33-1.49-.3V6.02l1.51.3L22.5 6h.01v21.34z'></path>
            </svg>
          </>
        )}
      </button>
    </Portal>
  )
}

export default ButtonHandleMaps
