/* eslint-disable prettier/prettier */
export const appearance = {
  theme: 'stripe',
  variables: {
    colorPrimary: '#222222',
    colorBackground: '#ffffff',
    colorText: '#222222',
    colorDanger: '#c13515',
    fontFamily: 'Ideal Sans, system-ui, sans-serif',
    spacingUnit: '2px',
    borderRadius: '8px',
  },
  rules: {
    '.Label': {
      fontSize: '16px',
      color: '#222222',
    },

    '.Input': {
      padding: '15px 15px',
      margin: '5px 0',
      outline: 'none',
      border: 'none',
      boxShadow: 'rgb(176 176 176) 0px 0px 0px 1px inset',
    },

    '.Input:focus': {
      boxShadow: 'rgb(34 34 34) 0px 0px 0px 2px inset',
    },

    '.Tab': {
      marginBottom: '10px',
      outline: 'none',
      border: 'none',
      boxShadow: 'rgb(176 176 176) 0px 0px 0px 1px inset',
    },

    '.Tab:hover': {
      color: 'var(--colorText)',
    },

    '.Tab:focus': {
      boxShadow: 'rgb(34 34 34) 0px 0px 0px 2px inset',
    },

    '.Tab--selected': {
      boxShadow: 'rgb(34 34 34) 0px 0px 0px 2px inset',
    },

    '.Tab--selected:focus': {
      boxShadow: 'rgb(34 34 34) 0px 0px 0px 2px inset',
    },

    '.Input--invalid': {
      boxShadow: 'var(--colorDanger) 0px 0px 0px 2px inset',
    },
  },
}
