import { useState, useEffect } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const OutOfPlace = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [seDeplace, setSeDeplace] = useState('')
  const { loading, updateData } = useUpdateHotel(setOpen)

  const handleSubmit = e => {
    e.preventDefault()
    const datas = {
      traveler_information: {
        ...annonce.traveler_information,
        getting_around: seDeplace,
      },
    }
    updateData(datas)
  }
  useEffect(() => {
    setSeDeplace(annonce.traveler_information?.getting_around || '')
  }, [annonce])
  const openFuction = () => {
    setSeDeplace(annonce.traveler_information?.getting_around || '')
    setOpen(true)
  }
  const reset = () => {
    setSeDeplace()
    setOpen(false)
  }
  return (
    <div className={styles.block}>
      {!open ? (
        <Section title='Se déplacer' handleOpen={openFuction}>
          <p>{annonce.traveler_information?.getting_around || 'Non défini'}</p>
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title='Se déplacer' handleReset={reset}>
            <p>
              Expliquez aux voyageurs comment se déplacer dans le quartier et n'oubliez pas vos
              astuces pour le stationnement.
            </p>
          </ContenHead>
          <form onSubmit={handleSubmit}>
            <div className={styles.body}>
              <div className={styles.input_block}>
                <textarea
                  value={seDeplace}
                  maxLength={500}
                  onChange={e => setSeDeplace(e.target.value)}
                ></textarea>
                <span>{seDeplace.length}/500</span>
              </div>
            </div>
            <BottomForm
              disabled={seDeplace === annonce.traveler_information?.getting_around}
              loading={loading}
              handleReset={reset}
            />
          </form>
        </div>
      )}
    </div>
  )
}

export default OutOfPlace
