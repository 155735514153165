import styles from '../../css/details/hebergement.module.css'
import HotelLanguage from './HotelLanguage'
import Modal from '../ui/Modal'
import CloseButton from '../ui/CloseButton'
import useModalByUrl from '../../hooks/useModalByUrl'

const Hebergement = ({ hotel, descriptionRef }) => {
  const modalValue = 'DESCRIPTIONS'
  const { modalState, handleOpenModal, handleCloseModal } = useModalByUrl(modalValue)

  return (
    <>
      <div ref={descriptionRef} className={`${styles.hebergement} ${styles.section}`}>
        <h3>À propos de ce logement</h3>
        <div className={styles.dispositif}>
          {hotel.about?.presentation && (
            <div className=''>
              <h4>Présentation</h4>
              <p>{hotel.about?.presentation}</p>
              <button onClick={handleOpenModal}>En savoir plus</button>
            </div>
          )}
          {hotel.about?.for_children && (
            <div className={styles.apropos}>
              <h4>Enfants et lits</h4>
              <p>{hotel.about?.for_children}</p>
              <button onClick={handleOpenModal}>En savoir plus</button>
            </div>
          )}
        </div>
      </div>
      {/*----modal about de l'hotel---------*/}
      <Modal isOpen={modalState} onClose={handleCloseModal}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={handleCloseModal} />
            <div></div>
          </div>
          <div className={styles.m_body}>
            <h3>À propos de ce logement</h3>
            {hotel.about?.presentation && (
              <div className=''>
                <h4>Présentation</h4>
                <p>{hotel.about?.presentation}</p>
              </div>
            )}
            {hotel.about?.leisure_sports && (
              <div className=''>
                <h4>Sports et loisirs</h4>
                <p>{hotel.about?.leisure_sports}</p>
              </div>
            )}
            {hotel.about?.for_children && (
              <div className=''>
                <h4>Enfants et lits</h4>
                <p>{hotel.about?.for_children}</p>
              </div>
            )}
            {hotel.languages?.length > 0 && (
              <div className=''>
                <h4>Langues parlées</h4>
                <HotelLanguage hotel={hotel} />
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Hebergement
