import styles from '../../../css/becomeHostes/typeProperty.module.css'

const Category = ({ typeproperty, setTypeproperty }) => {
  const array = [
    {
      name: 'Auberge de jeunesse',
      value: 'auberge_de_jeunesse',
      descr:
        "Une entreprise du secteur de l'hébergement qui propose des lits en dortoirs partagés et des chambres privées.",
    },
    {
      name: 'Gîte',
      value: 'gite',
      descr:
        'Une entreprise qui propose des séjours près de milieux naturels, notamment en forêt ou en montagne.',
    },
    {
      name: 'Résidence de tourisme',
      value: 'residence_de_tourisme',
      descr:
        'Un appartement avec des services hôteliers proposés par une entreprise de gestion professionnelle.',
    },
    {
      name: 'Kezhan',
      value: 'kezhan',
      descr: 'Un hébergement de caractère local avec des équipements sophistiqués situé en Chine.',
    },
  ]
  return (
    <div className={styles.block_checkbox}>
      {array.map((item, i) => (
        <label key={i}>
          <input
            type='radio'
            value={item.value}
            checked={typeproperty === item.value}
            onChange={e => setTypeproperty(e.target.value)}
          />
          <div className={styles.block}>
            <div className={styles.block_content}>
              <h2>{item.name}</h2>
              <p>{item.descr}</p>
            </div>
          </div>
        </label>
      ))}
    </div>
  )
}

export default Category
