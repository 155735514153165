import { useEffect } from 'react'
import styles from '../../css/affiliations/home.module.css'
import Header from '../../components/affiliations/homes/Header'
import Section1 from '../../components/affiliations/homes/Section1'
import QuestionSection from '../../components/affiliations/homes/QuestionSection'
import SectionStep from '../../components/affiliations/homes/SectionStep'
import { Helmet } from 'react-helmet-async'
import Navigation from '../../components/affiliations/global_components/Navigation'
import DefaultLayout from '../../layouts/DefaultLayout'

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <DefaultLayout footer={true} mobileBottomNavigate={true}>
      <Helmet>
        <title>
          Parrainez un hôte et gagnez un pourcentage sur sa première réservation | Gowifmi
        </title>
      </Helmet>
      <Navigation />
      {/*---------main-------*/}
      <div className={styles.main_home}>
        <Header />
        <SectionStep />
        <Section1 />
        <QuestionSection />
      </div>
    </DefaultLayout>
  )
}

export default Home
