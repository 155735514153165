import { useEffect, useState, useContext } from 'react'
import styles from '../../../../css/modehotes/inbox/listemessageHote.module.css'
import { useHistory, Link } from 'react-router-dom'
import { User } from '../../../../context/UserContext'
import LoadingPoint from '../../../../components/LoadingPoint'
import HostModeLayout from '../../../../layouts/HostModeLayout'
import { Helmet } from 'react-helmet-async'
import SideNavigation from '../../../../components/hote_mode/inboxs/SideNavigation'

const ListAssistanceHost = () => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [result, setresult] = useState(false)

  const user = useContext(User)
  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
    if (user === null) {
      history.push('/')
    }
    setLoading(false)
    setresult(true)
  }, [history, user])

  if (loading) {
    return (
      <div className={styles.container_load}>
        <LoadingPoint />
      </div>
    )
  }
  return (
    <HostModeLayout>
      <Helmet>
        <title>Assistance Gowifmi - Gowifmi</title>
      </Helmet>

      <div className={styles.liste_container}>
        <div className={styles.block_grid}>
          <SideNavigation modalLeftbar={modal} setModalLeftbar={setModal} />
          <div className={styles.block_grid_2}>
            <div className={styles.head}>
              <div className={styles.left}>
                <button type='button' onClick={() => setModal(true)}>
                  <svg
                    viewBox='0 0 32 32'
                    xmlns='http://www.w3.org/2000/svg'
                    aria-hidden='true'
                    role='presentation'
                    focusable='false'
                    style={{
                      display: 'block',
                      fill: 'none',
                      height: '16px',
                      width: '16px',
                      stroke: 'currentColor',
                      strokeWidth: '3',
                      overflow: 'visible',
                    }}
                  >
                    <g fill='none'>
                      <path d='M2 16h28M2 24h28M2 8h28'></path>
                    </g>
                  </svg>
                </button>
                <h2>Assistance Gowifmi</h2>
              </div>
            </div>
            {result ? (
              <div className={styles.no_result}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 32 32'
                  aria-hidden='true'
                  role='presentation'
                  focusable='false'
                  style={{ display: 'block', height: '24px', width: '24px', fill: 'currentcolor' }}
                >
                  <path d='M26 1a5 5 0 0 1 5 4.78v10.9a5 5 0 0 1-4.78 5H26a5 5 0 0 1-4.78 5h-4l-3.72 4.36-3.72-4.36H6a5 5 0 0 1-4.98-4.56L1 21.9 1 21.68V11a5 5 0 0 1 4.78-5H6a5 5 0 0 1 4.78-5H26zm-5 7H6a3 3 0 0 0-3 2.82v10.86a3 3 0 0 0 2.82 3h4.88l2.8 3.28 2.8-3.28H21a3 3 0 0 0 3-2.82V11a3 3 0 0 0-3-3zm-1 10v2H6v-2h14zm6-15H11a3 3 0 0 0-3 2.82V6h13a5 5 0 0 1 5 4.78v8.9a3 3 0 0 0 3-2.82V6a3 3 0 0 0-2.82-3H26zM15 13v2H6v-2h9z'></path>
                </svg>
                <h3>Aucun nouveau message</h3>
                <p>Si vous recherchez un message, consultez les archives.</p>
                <Link to='/hosting/inbox/all'>Accéder à tous les messages</Link>
              </div>
            ) : (
              <div className={styles.liste}>
                <Link to='' className={styles.liste_content}>
                  <div>
                    <div className={styles.img_block}>
                      <img src='/embleme.png' alt='' />
                    </div>
                  </div>
                  <div className={styles.profil_text}>
                    <h5>
                      Assistance Gowifmi - <span>logement</span>
                    </h5>
                    <p>
                      Hi Raphael. Are you interested in renting the house for these dates? Kind
                      regards, Geoff
                    </p>
                  </div>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </HostModeLayout>
  )
}

export default ListAssistanceHost
